/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="21" height="21" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="4" fill="#F5F6FA" />
    <path
      d="M23.2057 10.1916L21.33 8.14535C20.7449 7.50705 19.7959 7.50705 19.2108 8.14535L17.4462 10.0704L21.4411 14.4285L23.2057 12.5034C23.7909 11.8651 23.7909 10.8303 23.2057 10.1916ZM15.5043 9.49274C15.0168 8.961 14.2267 8.961 13.7393 9.49274L10.0327 13.5366C9.83767 13.7494 9.83767 14.0942 10.0327 14.3067L10.7389 15.077C10.9339 15.2898 11.25 15.2898 11.4451 15.077L14.6224 11.6108L15.3286 12.3809L10.5991 17.54C8.9609 19.3271 7.92886 21.6691 7.67264 24.1809L7.6717 24.1888C7.61865 24.7089 8.02151 25.1488 8.49833 25.0912C10.8038 24.8135 12.9538 23.6873 14.594 21.8981L20.7349 15.1989L18.152 12.3812L15.5043 9.49274Z"
      fill="#2F2C88"
    />
  </svg>
);

const ProjectCopywritingIcon = (props) => <Icon component={svg} {...props} />;

export default ProjectCopywritingIcon;
