// this component will be rendered in wix proposal editor
import { useState, useEffect } from 'react';
import { WixPricing, WixPaymentSchedule } from '.';
import { DEFAULT_CURRENCY, CURRENCY_SYMBOL } from 'constants/currency';
import { getPricingTable, getPaymentSchedule, formatLocale } from './wix-pricing-helper';
import useWindowDimensions from 'hooks/useWindowDimensions';

export default function WixPriceMiles({ blockProps }) {
  const { isMobile } = useWindowDimensions();

  const [contentType, setContentType] = useState('');

  const { configText } = blockProps;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const wixPricing = blockProps.prop?.wixPricing || {};
  const fontSize = Number.parseInt(blockProps.prop.draft.bodyFont?.fontSize || 20);
  const isLargeFont = fontSize > 20;

  const locale = formatLocale(wixPricing);

  const [pricing, setPricing] = useState({
    lineItems: [],
    summaryItems: [],
    total: '',
  });
  const [currency, setCurrency] = useState('');
  const [currencyCode, setCurrencyCode] = useState('');
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [notes, setNotes] = useState('');
  const [shortenDigits, setShortenDigits] = useState(false);

  const getContentType = () => {
    const [_contentType] = Object.keys(JSON.parse(blockProps.texcontent));

    if (_contentType === 'deliverables' || _contentType === 'pricing') {
      setContentType('pricing');
    } else if (_contentType === 'milestones') {
      setContentType('milestones');
    }
  };

  const onEdit = () => {
    try {
      window?.proposalApi?.edit(window.proposalId || window.wixTemplateId);
    } catch (error) {
      console.error('error edit', error);
    }
  };

  useEffect(() => {
    setCurrency(CURRENCY_SYMBOL[wixPricing?.currencyCode || DEFAULT_CURRENCY]);
    setCurrencyCode(wixPricing?.currencyCode || DEFAULT_CURRENCY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wixPricing?.currencyCode]);

  useEffect(() => {
    if (contentType !== 'pricing') {
      return;
    }

    const { lineItems, summaryItems, total, shouldShortenDigits } = getPricingTable(
      wixPricing,
      currency,
      configText,
      fontSize,
      currencyCode,
      locale
    );

    setShortenDigits(shouldShortenDigits || isMobile || fontSize > 26);

    setPricing({ lineItems, summaryItems, total });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentType, currency, wixPricing, fontSize]);

  useEffect(() => {
    if (contentType === 'milestones' && wixPricing?.paymentSettings) {
      const { paymentTerms, notes } = getPaymentSchedule(
        wixPricing.paymentSettings,
        currency,
        wixPricing?.calculatedTotals?.priceSummary?.total || 0,
        configText,
        currencyCode,
        locale
      );
      setPaymentTerms(paymentTerms);
      setNotes(notes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentType, currency, wixPricing?.paymentSettings]);

  useEffect(() => {
    getContentType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockProps?.prop, blockProps?.texcontent]);

  return (
    <div className={`payment-component-wrapper ${isLargeFont ? 'mobile-screen large-font' : ''}`}>
      {contentType === 'milestones' && (
        <WixPaymentSchedule paymentTerms={paymentTerms} notes={notes} configText={configText} />
      )}
      {contentType === 'pricing' && (
        <WixPricing
          {...pricing}
          currency={currency}
          configText={configText}
          shorten={shortenDigits}
          isLargeFont={isLargeFont}
          currencyCode={currencyCode}
          locale={locale}
        />
      )}
      <div className="rich-editor-components-action-buttons">
        <div className="group">
          <button onClick={onEdit}>Edit</button>
        </div>
      </div>
    </div>
  );
}
