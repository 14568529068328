import { EditorState, Modifier, SelectionState } from 'draft-js';

const removeTeXBlock = ({ editorState, blockKey }) => {
  // when more than 1 items are deleted
  // implemented in TestimonialComponent
  if (Array.isArray(blockKey)) {
    blockKey.forEach((blockKey) => {
      const content = editorState.getCurrentContent();
      const block = content.getBlockForKey(blockKey);

      if (block) {
        const selection = editorState.getSelection().merge({
          anchorKey: blockKey,
          focusKey: blockKey,
          anchorOffset: 0,
          focusOffset: block.getLength(),
        });

        const newContentState = Modifier.removeRange(content, selection, 'backward');
        editorState = EditorState.push(editorState, newContentState, 'remove-range');
      }
    });

    return editorState;
  }

  const content = editorState.getCurrentContent();
  const block = content.getBlockForKey(blockKey);
  if (block) {
    const targetRange = new SelectionState({
      anchorKey: blockKey,
      anchorOffset: 0,
      focusKey: blockKey,
      focusOffset: block.getLength(),
    });

    const withoutTeX = Modifier.removeRange(content, targetRange, 'backward');
    const resetBlock = Modifier.setBlockType(
      withoutTeX,
      withoutTeX.getSelectionAfter(),
      'unstyled'
    );

    const newState = EditorState.push(editorState, resetBlock, 'remove-range');
    return EditorState.forceSelection(newState, resetBlock.getSelectionAfter());
  }
  return editorState;
};

export default removeTeXBlock;
