const Visibility = [
  {
    text: 'Visible',
    value: 'block',
  },
  {
    text: 'Hidden',
    value: 'none',
  },
];

export default Visibility;
