import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import { userVar } from 'graphql/cache';
import { USER_UPDATE } from 'graphql/mutations/userMutations';

import './ReviewWidget.scss';

const ReviewWidget = ({ reviewWidget, wixReview }) => {
  const [notNow, setNotNow] = useState(false);

  const [saveUser] = useMutation(USER_UPDATE, {
    async onCompleted({ updateUser }) {
      userVar(updateUser);
    },
  });

  const disableWidget = (e, option) => {
    e.preventDefault();
    if (option === 1 && wixReview) {
      option = 0;
      window.open(`https://www.wix.com/app-market/add-review/${process.env.REACT_APP_WIX_APP_ID}`);
    } else if (option === 1) {
      option = 0;
      window.open('https://reviews.capterra.com/new/184953', '_blank');
    }

    saveUser({
      variables: {
        user: {
          id: userVar()._id,
          reviewWidget: option,
        },
      }
    });
  };

  return (
    <div
      className={`custom-review-widget ${reviewWidget !== 1 ? 'custom-review-widget-hide' : ''}`}>
      <div className="custom-review-widget-header">
        <h4>Do you have a minute</h4>
        <i className="fa fa-close" onClick={(e) => disableWidget(e, 0)} />
      </div>

      <p>
        Hi there, it seems like <strong>Prospero</strong> is bringing you some value, and that's
        pretty awesome! Do you think so too? If you do, can you please rate Prospero on{' '}
        {wixReview ? 'Wix' : 'Capterra'}? It'll only take {wixReview ? 1 : 2} minutes of your time,
        and will really help us spread the word.
      </p>

      {!notNow ? (
        <div className="custom-review-widget-buttons">
          <a className="confirm-button" onClick={(e) => disableWidget(e, 1)}>
            I'd love to help
          </a>
          <a className="cancel-button" onClick={() => setNotNow(true)}>
            Not Now
          </a>
        </div>
      ) : (
        <div className="custom-review-widget-buttons">
          <a className="confirm-button" onClick={(e) => disableWidget(e, 3)}>
            Remind me in 3 days
          </a>
          <a className="confirm-button" onClick={(e) => disableWidget(e, 10)}>
            Remind me in 10 days
          </a>
          <a className="cancel-button" onClick={(e) => disableWidget(e, 0)}>
            Don't Remind Me
          </a>
        </div>
      )}
    </div>
  );
};

export default ReviewWidget;
