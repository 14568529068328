/* eslint-disable */
import React, { useState, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Tablist } from '../../../modules';
import helpers from 'helpers/proposal';
import Loader from 'components/Loader';

const DeliverableInformation = React.forwardRef(
  ({ prop, config, handleState, user, save, saveUser, ptype, wixProductsAndServices }, ref) => {
    const [delivs, setDelivs] = useState([]);
    const [delivsRegex, setDelivsRegex] = useState('');
    const [propDelivs, setPropDelivs] = useState({});
    const [chosendelivs, setChosendelivs] = useState('');

    useEffect(() => {
      // deliverables
      const userConfig = user.config;

      let tempDelivs = [];
      const userDelivs = userConfig && userConfig.delivs ? userConfig.delivs : '';

      if (ptype) {
        _.each(ptype?.split(' '), (pt) => {
          tempDelivs = _.union(
            tempDelivs,
            config[`${pt} delivs`]
              ? config[`${pt} delivs`]?.split('\n')
              : config['design delivs']?.split('\n')
          );
        });
      }

      const { otherptype } = user;

      if (!ptype && otherptype) {
        tempDelivs = _.union(tempDelivs, config['other delivs'].split('\n') || []);
      }

      tempDelivs = _.compact(tempDelivs);

      tempDelivs = tempDelivs.map((deliv) => {
        return { name: deliv, editable: false };
      });

      if (userDelivs) {
        tempDelivs = _.union(tempDelivs, userDelivs || []);
      }

      if (delivsRegex) {
        const searchRegex = new RegExp(
          delivsRegex.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'),
          'i'
        );
        tempDelivs = tempDelivs.filter((deliv) => searchRegex.test(deliv.name));
      }

      const chosen =
        propDelivs && Object.keys(propDelivs).length !== 0
          ? propDelivs
          : prop.deliverables
          ? prop.deliverables.chosen
          : helpers.getDefaultOptions({ user, prelist: tempDelivs, prop, config }) || {};

      if (Object.keys(wixProductsAndServices).length) {
        let services = [];
        let products = [];
        let totalServices = 0;
        let totalProducts = 0;

        if (prop.wixConnectedInstance) {
          const connectedWixServices = wixProductsAndServices?.services.find(
            (service) => service.instanceId === prop.wixConnectedInstance
          );
          const connectedWixProducts = wixProductsAndServices?.products.find(
            (product) => product.instanceId === prop.wixConnectedInstance
          );

          services = connectedWixServices.fields?.map((service) => ({
            ...service,
            editable: false,
          }));
          totalServices = connectedWixServices.totalItems;

          products = connectedWixProducts.fields?.map((product) => ({
            ...product,
            editable: false,
          }));
          totalProducts = connectedWixProducts.totalItems;
        } else {
          wixProductsAndServices?.services.forEach(
            (wixServices) =>
              (services = wixServices.fields?.map((service) => ({
                ...service,
                editable: false,
              })))
          );
          totalServices = wixProductsAndServices?.services[0]?.totalItems;

          wixProductsAndServices?.products.forEach(
            (wixProducts) =>
              (products = wixProducts.fields?.map((product) => ({
                ...product,
                editable: false,
              })))
          );
          totalProducts = wixProductsAndServices?.products[0]?.totalItems;
        }

        tempDelivs = [
          {
            name: 'wixServices',
            wixServices: services,
            totalItems: totalServices,
          },
          {
            name: 'wixProducts',
            wixProducts: products,
            totalItems: totalProducts,
          },
          {
            name: 'defaultServices',
            defaultServices: tempDelivs,
          },
        ];
      }

      setDelivs(tempDelivs);
      setChosendelivs(chosen);
    }, [ptype, prop, propDelivs, delivsRegex, wixProductsAndServices]);

    useImperativeHandle(
      ref,
      () => ({
        resetChosen: () => {
          const newData = {
            'OPTION 1': { total: '', list: [] },
          };
          setPropDelivs(newData);
          // save in db
          save({ deliverables: newData }, true);
        },
      }),
      [setPropDelivs]
    );

    if (!delivs.length && !chosendelivs) {
      return <Loader className="wizard-loader" />;
    }

    return (
      <Tablist
        tabType="delivs"
        currAndDisc
        price={prop.pricing && prop.pricing.strategy !== 'value'}
        tabs={prop.pricing && prop.pricing.strategy === 'value'}
        currency={prop.curr || prop.currency || '$'}
        config={config}
        prop={prop}
        save={save}
        saveUser={saveUser}
        prelist={[...delivs]}
        handleState={handleState}
        propsChosen={chosendelivs ? _.merge({}, chosendelivs) : chosendelivs}
        setDelivsRegex={setDelivsRegex}
        onChange={(chosen, orderSave) => {
          if (!prop.deliverables || !_.isEqual(prop.deliverables.chosen, chosen)) {
            setPropDelivs('');
            setChosendelivs(chosen);
          } else if (orderSave) {
            setPropDelivs(chosen);
          }
          save({ deliverables: { chosen } }, true);
        }}
        isWizard
      />
    );
  }
);

DeliverableInformation.defaultProps = {
  ptype: '',
  curr: '$',
};

DeliverableInformation.propTypes = {
  prop: PropTypes.instanceOf(Object).isRequired,
  handleState: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  curr: PropTypes.string,
  config: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  ptype: PropTypes.string,
  wixProductsAndServices: PropTypes.instanceOf(Object),
};

export default DeliverableInformation;
