import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="5.5" y="6" width="8" height="1" rx="0.5" transform="rotate(90 5.5 6)" fill="#05034D" />
    <path d="M7 12L5 14L3 12" stroke="#05034D" strokeLinecap="round" strokeLinejoin="round" />
    <rect
      width="8"
      height="1"
      rx="0.5"
      transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 11.5 10)"
      fill="#05034D"
    />
    <path d="M13 4L11 2L9 4" stroke="#05034D" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const ReOrderIcon = (props) => <Icon component={svg} {...props} />;

export default ReOrderIcon;
