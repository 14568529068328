import React from 'react';
import PropTypes from 'prop-types';

import TeXHTMLString from './TeXHTMLString';

const TeXHTML = (props) => {
  const { content, configText } = props;

  return (
    <div className="texblockbodywrap">
      <TeXHTMLString content={content} configText={configText} props={props} />
    </div>
  );
};

TeXHTML.propTypes = {
  content: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  configText: PropTypes.instanceOf(Object).isRequired,
};

export default TeXHTML;
