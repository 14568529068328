import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="48" height="48" viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M4 6C4 3.79086 5.79086 2 8 2H40C42.2091 2 44 3.79086 44 6V38C44 40.2091 42.2091 42 40 42H8C5.79086 42 4 40.2091 4 38V6Z"
        fill="white"
      />
      <path
        d="M4 6C4 3.79086 5.79086 2 8 2H40C42.2091 2 44 3.79086 44 6V38C44 40.2091 42.2091 42 40 42H8C5.79086 42 4 40.2091 4 38V6Z"
        fill="url(#paint0_linear)"
      />
      <line
        x1="13"
        y1="22"
        x2="35"
        y2="22"
        stroke="#7876C1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 3"
      />
      <path
        d="M13 12V16C13 16.5523 13.4477 17 14 17H34C34.5523 17 35 16.5523 35 16V12"
        stroke="#C7BCE4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35 32L35 28C35 27.4477 34.5523 27 34 27L14 27C13.4477 27 13 27.4477 13 28L13 32"
        stroke="#C7BCE4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="48"
        height="48"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.72549 0 0 0 0 0.721569 0 0 0 0 0.839216 0 0 0 0.2 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="24"
        y1="2"
        x2="24"
        y2="42"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F5F6FA" />
      </linearGradient>
    </defs>
  </svg>
);

const DividerIcon = (props) => <Icon component={svg} {...props} />;

export default DividerIcon;
