import React, { useState } from 'react';
import { Col, Row, Tabs, message } from 'antd';
import { useQuery, useMutation } from '@apollo/client';

import RemoveIcon from 'components/Icons/RemoveIcon';
import IconButton from 'components/IconButton';
import Loader from 'components/Loader';
import UploadContentLibraryItem from 'components/UploadContentLibraryItem';
import ConfirmModal from 'components/ConfirmModal';

import { FETCH_ADMIN_COVERS } from 'graphql/queries/adminQueries';
import { DELETE_ADMIN_COVER } from 'graphql/mutations/adminMutations';

import './Gallery.scss';

const { TabPane } = Tabs;
const tabs = [
  {
    key: 'top',
    value: 'Top Covers',
  },
  {
    key: 'full-page',
    value: 'Full Page Cover',
  },
];

const Gallery = () => {
  const [covers, setCovers] = useState([]);

  const [selectedTab, setSelectedTab] = useState('top');
  const [selectedCoverId, setSelectedCoverId] = useState('');
  const [showUploadCover, showOrHideUploadCover] = useState(false);
  const [confirmModal, showConfirmModal] = useState(false);

  const { loading: isFetchingAdminCovers } = useQuery(FETCH_ADMIN_COVERS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ fetchAdminCovers }) => setCovers(fetchAdminCovers),
  });

  const [deleteAdminCover] = useMutation(DELETE_ADMIN_COVER, {
    onCompleted: () => {
      setCovers(covers.filter((cover) => cover._id !== selectedCoverId));
      setSelectedCoverId('');
      message.success('Cover deleted successfully');
    },
    onError: () => message.error(`Failed to delete cover`),
  });

  const handleCoverUpload = () => {
    showOrHideUploadCover(true);
  };

  const deleteCover = () => {
    showConfirmModal(false);
    deleteAdminCover({
      variables: {
        coverId: selectedCoverId,
      },
    });
  };

  const onDeteleClick = (id) => {
    showConfirmModal(true);
    setSelectedCoverId(id);
  };

  const renderCovers = () => {
    let updatedCovers;

    if (selectedTab === 'full-page') {
      updatedCovers = covers.filter((cover) => cover.type === 'full-page');
    } else {
      updatedCovers = covers.filter((cover) => cover.type !== 'full-page');
    }
    return (
      <Row gutter={16}>
        {updatedCovers.map((cover) => {
          return (
            <Col xs={24} sm={24} md={6} lg={6} key={cover.img}>
              <div className="image-container">
                <RemoveIcon onClick={() => onDeteleClick(cover._id)} />
                <img
                  src={cover.img}
                  alt={cover.alt}
                  style={selectedTab === 'full-page' ? { height: '400px' } : {}}
                />
              </div>
            </Col>
          );
        })}
      </Row>
    );
  };

  if (isFetchingAdminCovers) return <Loader />;

  return (
    <div className="gallery-content">
      <Row justify="end">
        <IconButton text="Upload Cover" onButtonClick={handleCoverUpload} />
      </Row>
      <Row>
        <Tabs onChange={(key) => setSelectedTab(key)} defaultActiveKey={selectedTab}>
          {tabs.map((item) => (
            <TabPane tab={item.value} key={item.key}>
              {renderCovers()}
            </TabPane>
          ))}
        </Tabs>
      </Row>
      <UploadContentLibraryItem
        type="covers"
        showUploadCover={showUploadCover}
        cancelUploadModal={() => showOrHideUploadCover(false)}
        selectedCoverType={selectedTab}
        covers={covers}
      />
      <ConfirmModal
        title="Delete Cover?"
        body="This action cannot be undone"
        visible={confirmModal}
        cancelText="CANCEL"
        confirmText="YES, DELETE"
        onCancel={() => showConfirmModal(false)}
        onConfirm={deleteCover}
      />
    </div>
  );
};

export default Gallery;
