import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9998 6.33332H11.3332V3.99999C11.3332 2.15999 9.83984 0.666656 7.99984 0.666656C6.15984 0.666656 4.6665 2.15999 4.6665 3.99999H5.99984C5.99984 2.89332 6.89317 1.99999 7.99984 1.99999C9.1065 1.99999 9.99984 2.89332 9.99984 3.99999V6.33332H3.99984C3.2665 6.33332 2.6665 6.93332 2.6665 7.66666V13.3333C2.6665 14.0667 3.2665 14.6667 3.99984 14.6667H11.9998C12.7332 14.6667 13.3332 14.0667 13.3332 13.3333V7.66666C13.3332 6.93332 12.7332 6.33332 11.9998 6.33332ZM11.9998 13.3333H3.99984V7.66666H11.9998V13.3333Z"
      fill="#534B7A"
    />
    <circle cx="8" cy="10" r="1" fill="#534B7A" />
    <path
      d="M8 10.2627V10.008H8.02811V10.1343H8.03083L8.13512 10.008H8.17185L8.07436 10.1229L8.17185 10.2627H8.13785L8.05713 10.1443L8.02811 10.1801V10.2627H8Z"
      fill="white"
    />
    <path
      d="M8.21552 10.2627V10.0716H8.24228V10.2627H8.21552ZM8.22913 10.0398C8.22391 10.0398 8.21942 10.0379 8.21564 10.034C8.21193 10.0301 8.21008 10.0254 8.21008 10.0199C8.21008 10.0144 8.21193 10.0097 8.21564 10.0058C8.21942 10.0019 8.22391 10 8.22913 10C8.23434 10 8.2388 10.0019 8.2425 10.0058C8.24628 10.0097 8.24817 10.0144 8.24817 10.0199C8.24817 10.0254 8.24628 10.0301 8.2425 10.034C8.2388 10.0379 8.23434 10.0398 8.22913 10.0398Z"
      fill="white"
    />
    <path
      d="M8.29128 10.2627V10.0716H8.31712V10.1005H8.31894C8.32211 10.091 8.32785 10.0834 8.33617 10.0775C8.34448 10.0716 8.35385 10.0687 8.36428 10.0687C8.36625 10.0687 8.3687 10.0687 8.37165 10.0688C8.3746 10.0689 8.37683 10.069 8.37834 10.0692V10.099C8.37743 10.0988 8.37535 10.0984 8.3721 10.0979C8.36893 10.0973 8.36557 10.097 8.36201 10.097C8.35355 10.097 8.34599 10.099 8.33934 10.1029C8.33277 10.1067 8.32755 10.112 8.3237 10.1188C8.31992 10.1255 8.31803 10.1332 8.31803 10.1418V10.2627H8.29128Z"
      fill="white"
    />
    <path
      d="M8.47549 10.2667C8.45977 10.2667 8.44597 10.2626 8.43411 10.2544C8.42232 10.2461 8.4131 10.2347 8.40645 10.2199C8.39988 10.2051 8.39659 10.1879 8.39659 10.1682C8.39659 10.1483 8.39988 10.1309 8.40645 10.116C8.4131 10.1012 8.42232 10.0897 8.43411 10.0815C8.44597 10.0733 8.45977 10.0692 8.47549 10.0692C8.49121 10.0692 8.50496 10.0733 8.51675 10.0815C8.52861 10.0897 8.53783 10.1012 8.54441 10.116C8.55106 10.1309 8.55438 10.1483 8.55438 10.1682C8.55438 10.1879 8.55106 10.2051 8.54441 10.2199C8.53783 10.2347 8.52861 10.2461 8.51675 10.2544C8.50496 10.2626 8.49121 10.2667 8.47549 10.2667ZM8.47549 10.2403C8.48743 10.2403 8.49725 10.2369 8.50496 10.2302C8.51267 10.2235 8.51837 10.2147 8.52208 10.2037C8.52578 10.1928 8.52763 10.1809 8.52763 10.1682C8.52763 10.1554 8.52578 10.1435 8.52208 10.1325C8.51837 10.1214 8.51267 10.1125 8.50496 10.1057C8.49725 10.0989 8.48743 10.0955 8.47549 10.0955C8.46355 10.0955 8.45372 10.0989 8.44601 10.1057C8.4383 10.1125 8.4326 10.1214 8.4289 10.1325C8.42519 10.1435 8.42334 10.1554 8.42334 10.1682C8.42334 10.1809 8.42519 10.1928 8.4289 10.2037C8.4326 10.2147 8.4383 10.2235 8.44601 10.2302C8.45372 10.2369 8.46355 10.2403 8.47549 10.2403Z"
      fill="white"
    />
    <path
      d="M8.62197 10.1478V10.2627H8.59522V10.0716H8.62107V10.1015H8.62334C8.62742 10.0918 8.63361 10.084 8.64193 10.0781C8.65024 10.0721 8.66097 10.0692 8.67412 10.0692C8.68591 10.0692 8.69623 10.0718 8.70507 10.0771C8.71391 10.0823 8.72079 10.0903 8.7257 10.101C8.73061 10.1116 8.73307 10.125 8.73307 10.1413V10.2627H8.70631V10.1433C8.70631 10.1283 8.70276 10.1166 8.69566 10.1082C8.68855 10.0998 8.67881 10.0955 8.66641 10.0955C8.65787 10.0955 8.65024 10.0976 8.64351 10.1016C8.63686 10.1057 8.63161 10.1116 8.62776 10.1194C8.6239 10.1272 8.62197 10.1367 8.62197 10.1478Z"
      fill="white"
    />
    <path
      d="M8.81732 10.008L8.81505 10.191H8.78875L8.78649 10.008H8.81732ZM8.8019 10.2647C8.79631 10.2647 8.79151 10.2625 8.78751 10.2581C8.7835 10.2537 8.7815 10.2484 8.7815 10.2423C8.7815 10.2362 8.7835 10.2309 8.78751 10.2265C8.79151 10.2221 8.79631 10.2199 8.8019 10.2199C8.8075 10.2199 8.8123 10.2221 8.8163 10.2265C8.82031 10.2309 8.82231 10.2362 8.82231 10.2423C8.82231 10.2464 8.82136 10.2501 8.81948 10.2535C8.81766 10.2569 8.81521 10.2596 8.81211 10.2617C8.80908 10.2637 8.80568 10.2647 8.8019 10.2647Z"
      fill="white"
    />
    <path
      d="M8.90617 10.008L8.9039 10.191H8.8776L8.87533 10.008H8.90617ZM8.89075 10.2647C8.88516 10.2647 8.88036 10.2625 8.87635 10.2581C8.87235 10.2537 8.87035 10.2484 8.87035 10.2423C8.87035 10.2362 8.87235 10.2309 8.87635 10.2265C8.88036 10.2221 8.88516 10.2199 8.89075 10.2199C8.89634 10.2199 8.90114 10.2221 8.90515 10.2265C8.90915 10.2309 8.91115 10.2362 8.91115 10.2423C8.91115 10.2464 8.91021 10.2501 8.90832 10.2535C8.90651 10.2569 8.90405 10.2596 8.90095 10.2617C8.89793 10.2637 8.89453 10.2647 8.89075 10.2647Z"
      fill="white"
    />
    <path
      d="M8.99501 10.008L8.99274 10.191H8.96645L8.96418 10.008H8.99501ZM8.9796 10.2647C8.974 10.2647 8.9692 10.2625 8.9652 10.2581C8.96119 10.2537 8.95919 10.2484 8.95919 10.2423C8.95919 10.2362 8.96119 10.2309 8.9652 10.2265C8.9692 10.2221 8.974 10.2199 8.9796 10.2199C8.98519 10.2199 8.98999 10.2221 8.99399 10.2265C8.998 10.2309 9 10.2362 9 10.2423C9 10.2464 8.99906 10.2501 8.99717 10.2535C8.99535 10.2569 8.9929 10.2596 8.9898 10.2617C8.98677 10.2637 8.98337 10.2647 8.9796 10.2647Z"
      fill="white"
    />
  </svg>
);

const OpenLockIcon = (props) => <Icon component={svg} {...props} />;

export default OpenLockIcon;
