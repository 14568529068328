import { gql } from '@apollo/client';

export const DISCONNECT_FRESHBOOKS = gql`
  mutation disconnectFreshbooksMutation {
    disconnectFreshbooks
  }
`;

export const CREATE_FRESHBOOKS_INVOICE = gql`
  mutation createFreshbooksInvoiceMutation($invoiceInfo: InvoiceInfoType!) {
    createFreshbooksInvoice(invoiceInfo: $invoiceInfo)
  }
`;

export const CREATE_QUICKBOOKS_AUTH_URL = gql`
  mutation createQuickBooksAuthUrlMutation($height: Float!, $width: Float!) {
    createQuickBooksAuthUrl(height: $height, width: $width)
  }
`;

export const CREATE_XERO_INVOICE = gql`
  mutation createXeroInvoiceMutation($invoiceInfo: InvoiceInfoType!) {
    createXeroInvoice(invoiceInfo: $invoiceInfo)
  }
`;

export const DISCONNECT_QUICKBOOKS_BY_REALM_ID = gql`
  mutation disconnectQuickBooksByRealmIdMutation($realmId: String!) {
    disconnectQuickBooksByRealmId(realmId: $realmId)
  }
`;

export const DISCONNECT_QUICKBOOKS = gql`
  mutation disconnectQuickBooksMutation {
    disconnectQuickBooks
  }
`;

export const CREATE_QUICKBOOKS_INVOICE = gql`
  mutation createQuickBooksInvoiceMutation($invoiceInfo: InvoiceInfoType!) {
    createQuickBooksInvoice(invoiceInfo: $invoiceInfo)
  }
`;

export const CREATE_XERO_AUTH_URL = gql`
  mutation createXeroAuthUrlMutation($height: Float!, $width: Float!) {
    createXeroAuthUrl(height: $height, width: $width)
  }
`;

export const DISCONNECT_XERO = gql`
  mutation disconnectXeroMutation($connectionIds: [String!]!) {
    disconnectXero(connectionIds: $connectionIds)
  }
`;

export const DISCONNECT_MONDAY = gql`
  mutation disconnectMondayMutation {
    disconnectMonday
  }
`;

export const DISCONNECT_INTEGROMAT = gql`
  mutation handleIntegromatMutation($status: String!) {
    handleIntegromat(status: $status) {
      integromatHooks {
        enabled
      }
    }
  }
`;

export const ENABLE_INTEGROMAT = gql`
  mutation handleIntegromatMutation($status: String!) {
    handleIntegromat(status: $status) {
      integromatHooks {
        enabled
      }
    }
  }
`;

export const DISCONNECT_ZAPIER = gql`
  mutation disconnectZapsMutation {
    disconnectZaps
  }
`;

export const CREATE_STRIPE_CONNECTED_ACCOUNT = gql`
  mutation createStripeConnectedAccountMutation($code: String!) {
    createStripeConnectedAccount(code: $code)
  }
`;

export const UPDATE_STRIPE_CONNECTED_ACCOUNT = gql`
  mutation updateStripeConnectedAccountMutation {
    updateStripeConnectedAccount
  }
`;

export const DISCONNECT_STRIPE_CONNECTED_ACCOUNT = gql`
  mutation disconnectStripeConnectedAccountMutation {
    disconnectStripeConnectedAccount
  }
`;

export const CREATE_CLIENT_STRIPE_PAYMENT_SESSION = gql`
  mutation createClientStripePaymentSessionMutation(
    $amount: String!
    $currency: String!
    $proposalLabel: String!
    $proposalId: String!
  ) {
    createClientStripePaymentSession(
      amount: $amount
      currency: $currency
      proposalLabel: $proposalLabel
      proposalId: $proposalId
    )
  }
`;

export const UPDATE_SCRIPT = gql`
  mutation updateScriptMutation($scripts: [String!]!) {
    updateScript(scripts: $scripts)
  }
`;

export const UPDATE_CHATWAY_SCRIPT = gql`
  mutation updateChatwayScriptMutation($scripts: [String!]!) {
    updateChatwayScript(scripts: $scripts)
  }
`;

export const UPDATE_SLACK_NOTIFICATIONS = gql`
  mutation updateSlackNotifications($notifications: [SlackNotificationType!]!) {
    updateSlackNotifications(notifications: $notifications)
  }
`;

export const DISCONNECT_SLACK = gql`
  mutation disconnectSlackMutation {
    disconnectSlack
  }
`;

export const CONNECT_WIX = gql`
  mutation connectWixMutation($wixCode: String, $wixInstanceId: String) {
    connectWix(wixCode: $wixCode, wixInstanceId: $wixInstanceId) {
      access_token
    }
  }
`;

export const CONNECT_NEW_WIX_USER = gql`
  mutation connectNewWixUserMutation($wixCode: String, $wixInstanceId: String) {
    connectNewWixUser(wixCode: $wixCode, wixInstanceId: $wixInstanceId)
  }
`;

export const DISCONNECT_WIX = gql`
  mutation disconnectWixMutation($instanceId: String!) {
    disconnectWix(instanceId: $instanceId)
  }
`;

export const CREATE_MORNING_INVOICE_APPROVAL_REQUEST = gql`
  mutation morningInvoiceUserApprovalRequestMutation($email: String!) {
    morningInvoiceUserApprovalRequest(email: $email)
  }
`;

export const DISCONNECT_MORNING_INVOICE = gql`
  mutation disconnectMorningInvoiceMutation {
    disconnectMorningInvoice
  }
`;

export const CREATE_MORNING_INVOICE = gql`
  mutation createMorningInvoiceMutation($invoiceInfo: InvoiceInfoType!) {
    createMorningInvoice(invoiceInfo: $invoiceInfo)
  }
`;
