/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="41" height="33" viewBox="0 0 41 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.125 0.8125H5.125C3.79935 0.813918 2.52841 1.34116 1.59103 2.27853C0.653658 3.21591 0.126418 4.48685 0.125 5.8125V27.2411C0.126418 28.5667 0.653658 29.8377 1.59103 30.775C2.52841 31.7124 3.79935 32.2397 5.125 32.2411H35.125C36.4506 32.2397 37.7216 31.7124 38.659 30.775C39.5963 29.8377 40.1236 28.5667 40.125 27.2411V5.8125C40.1236 4.48685 39.5963 3.21591 38.659 2.27853C37.7216 1.34116 36.4506 0.813918 35.125 0.8125ZM33.8589 9.08304L21.0018 19.083C20.7511 19.2779 20.4426 19.3837 20.125 19.3837C19.8074 19.3837 19.4989 19.2779 19.2482 19.083L6.39107 9.08304C6.24001 8.96896 6.11313 8.826 6.0178 8.66246C5.92247 8.49892 5.86059 8.31807 5.83576 8.13041C5.81093 7.94275 5.82364 7.75203 5.87316 7.56933C5.92267 7.38662 6.00801 7.21558 6.1242 7.06614C6.24039 6.91671 6.38512 6.79185 6.54998 6.69883C6.71484 6.60581 6.89655 6.54648 7.08454 6.52429C7.27253 6.5021 7.46305 6.5175 7.64504 6.56958C7.82703 6.62167 7.99685 6.7094 8.14464 6.82768L20.125 16.1455L32.1054 6.82768C32.4049 6.60145 32.7815 6.50223 33.1537 6.55147C33.5258 6.60071 33.8636 6.79446 34.0941 7.09082C34.3245 7.38718 34.429 7.76233 34.385 8.13514C34.341 8.50796 34.152 8.84846 33.8589 9.08304Z"
      fill="#CD5A4A"
    />
  </svg>
);

const MailIcon = (props) => <Icon component={svg} {...props} />;

export default MailIcon;
