import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <g opacity="0.7">
      <path
        d="M5.88924 20.5962C5.65591 20.5962 5.42124 20.5279 5.18524 20.3912C4.94924 20.2545 4.77724 20.0889 4.66924 19.8942L2.55424 16.1922C2.44624 15.9975 2.38924 15.7645 2.38324 15.4932C2.37724 15.2219 2.43391 14.9889 2.55324 14.7942L8.67024 4.29819C8.79024 4.10286 8.96424 3.93719 9.19224 3.80119C9.41958 3.66452 9.64958 3.59619 9.88224 3.59619H14.1122C14.3449 3.59619 14.5749 3.66452 14.8022 3.80119C15.0296 3.93719 15.2036 4.10286 15.3242 4.29819L19.2972 11.1182C19.1059 11.0949 18.9152 11.0825 18.7252 11.0812C18.5346 11.0799 18.3412 11.0932 18.1452 11.1212L14.3462 4.59619H9.64624L3.29624 15.4962L5.64624 19.5962H13.5372C13.6192 19.7875 13.7112 19.9632 13.8132 20.1232C13.9152 20.2832 14.0359 20.4409 14.1752 20.5962H5.88924ZM7.53524 16.5962L7.09824 15.8212L11.5002 8.15419H12.4922L15.0362 12.5772C14.9069 12.6932 14.7822 12.8175 14.6622 12.9502C14.5422 13.0835 14.4292 13.2162 14.3232 13.3482L11.9962 9.29619L8.38124 15.5962H13.1922C13.1396 15.7589 13.1002 15.9205 13.0742 16.0812C13.0476 16.2412 13.0279 16.4129 13.0152 16.5962H7.53524ZM18.4962 20.5962V17.5962H15.4962V16.5962H18.4962V13.5962H19.4962V16.5962H22.4962V17.5962H19.4962V20.5962H18.4962Z"
        fill="black"
      />
    </g>
  </svg>
);

const DriveLogo = (props) => <Icon component={svg} {...props} />;

export default DriveLogo;
