/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="20" height="20" viewBox="0 0 623 628" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.7576 217.962V92.8633C16.7576 25.2126 98.5875 -8.62533 146.367 39.2681L268.093 161.286C315.882 209.19 281.954 290.962 214.289 290.962H89.7576C49.4408 290.962 16.7576 258.279 16.7576 217.962Z"
      fill="#18534E"
    />
    <path
      d="M606.242 217.962V92.8633C606.242 25.2126 524.412 -8.62533 476.633 39.2681L354.907 161.286C307.118 209.19 341.046 290.962 408.711 290.962H533.242C573.559 290.962 606.242 258.279 606.242 217.962Z"
      fill="#18534E"
    />
    <path
      d="M16.7576 408.999C16.7576 367.577 50.3362 333.999 91.7576 333.999H215.573C256.995 333.999 290.573 367.577 290.573 408.999V535.9C290.573 577.322 256.995 610.9 215.573 610.9H91.7576C50.3362 610.9 16.7576 577.322 16.7576 535.9V408.999Z"
      fill="#18534E"
    />
    <path
      d="M332.427 408.999C332.427 367.577 366.005 333.999 407.427 333.999H531.242C572.664 333.999 606.242 367.577 606.242 408.999V535.9C606.242 577.322 572.664 610.9 531.242 610.9H407.427C366.005 610.9 332.427 577.322 332.427 535.9V408.999Z"
      fill="#18534E"
    />
  </svg>
);

const MorningInvoiceIcon = (props) => <Icon component={svg} {...props} />;

export default MorningInvoiceIcon;
