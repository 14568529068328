import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Layout, Steps } from 'antd';

const { Sider } = Layout;
const { Step } = Steps;

const Sidebar = ({ onChange, current }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <Sider
      width={200}
      className={isCollapsed ? 'sidebar-wizard-collapsed' : 'sidebar-wizard'}
      breakpoint="sm"
      collapsedWidth="50"
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
        setIsCollapsed(collapsed);
      }}>
      <Steps current={current} onChange={onChange} direction="vertical">
        <Step title={!isCollapsed && 'CLIENTS'} />
        <Step title={!isCollapsed && 'PROJECT'} />
        <Step title={!isCollapsed && 'YOU'} />
        <Step title={!isCollapsed && 'DELIVERABLES'} />
        <Step title={!isCollapsed && 'MILESTONES'} />
      </Steps>
    </Sider>
  );
};

Sidebar.propTypes = {
  onChange: PropTypes.func.isRequired,
  current: PropTypes.number.isRequired,
};

export default Sidebar;
