import React, { useState, useEffect } from 'react';
import { Modal, Button, Divider, message } from 'antd';
import PropTypes from 'prop-types';

import Popconfirm from 'components/PopConfirm';
import CloseIcon from 'components/Icons/CloseIcon';
import FileCopier from 'components/FileCopier';
import Uploader from 'components/Uploader';
import { SelectImages, GalleryImageEditor, ContentLibrary } from './components';
import { resizeImageForS3 } from 'utils/uploadToS3';
import { SectionAddIcon } from 'components/Icons';

import './GalleryEditModal.scss';

const DEFAULT_STATE = { images: [], config: {} };

const GalleryEditModal = ({
  visible,
  onCancel,
  onConfirm,
  galleryEdit,
  prop,
  savingRichEditorModal,
  isWix,
}) => {
  const [upload, uploadStatus] = Uploader();
  const [copy, copying] = FileCopier();
  const [showProgress, toggleShowProgress] = useState(false);
  const [imageName, setImageName] = useState('');
  const [selectImage, setSelectImage] = useState(!galleryEdit?.v.length ? true : false);
  const [removeItems, setRemoveItems] = useState(false);
  const [uploadingImages, setUploadingImages] = useState([]);
  const [libraryModal, toggleLibraryModal] = useState(false);
  const [moreButtons, setMoreButtons] = useState(false);

  const [selectedItems, setSeletedItem] = useState({});

  const [showPropModal, setShowPropModal] = useState(visible);
  const [showPromptModal, setShowPromptModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [temporary, setTemporary] = useState({ ...DEFAULT_STATE });

  useEffect(() => {
    if (!visible) {
      setTemporary({ ...DEFAULT_STATE });
    } else {
      setTemporary({
        images: [...galleryEdit?.v],
        config: { ...galleryEdit?.config },
      });
    }
  }, [visible, galleryEdit]);

  const handleKeyCommand = (e) => {
    if (e.key === 'Escape') {
      setShowModal(true);
      const isNotCombinedKey = !(e.ctrlKey || e.altKey || e.shiftKey);
      if (isNotCombinedKey) {
        makeEscapeModal(true);
      }
    }
  };

  const makeEscapeModal = (showModal) => {
    setShowPromptModal(showModal);
    setShowModal(showModal);
  };

  useEffect(() => {
    if (!showPromptModal) {
      window.addEventListener('keydown', handleKeyCommand, true);
    } else {
      window.removeEventListener('keydown', handleKeyCommand, true);
    }
    setShowPromptModal(!showPromptModal); // eslint-disable-next-line
  }, []);

  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = function () {
        reject(new Error('Failed to load image'));
      };
      img.src = url;
    });
  };

  const dropImage = (files) => {
    toggleShowProgress(true);

    const stateCopy = [...temporary.images];

    if (temporary?.tempImages?.length) {
      temporary.tempImages.forEach((item) => {
        const isExist = stateCopy.find((s) => s.i === item.i);
        if (!isExist) {
          stateCopy.push(item);
        }
      });
    }

    const maxId = stateCopy.reduce((acc, currentObj) => {
      const currentId = parseInt(currentObj.i || 0);
      return currentId > acc ? currentId : acc;
    }, 0);

    const size = { width: 500, height: 500 };

    let newImages = [];

    files.forEach(async (file, fileIndex) => {
      setImageName(
        files.length > 1
          ? `Uploading image ${fileIndex} of ${files.length}`
          : `Uploading ${file.name}`
      );

      try {
        const reSizedImage = await resizeImageForS3({
          file: file,
          path: 'sections',
          size,
        });

        const { url } = await upload(reSizedImage, `props/${prop._id}/section`);
        const { width, height } = await loadImage(url);

        newImages.push(url);
        stateCopy.push({
          i: `${maxId + 1 + fileIndex}`,
          w: width,
          h: height,
          image: url,
        });

        // for last image
        if (fileIndex === files.length - 1) {
          setUploadingImages((prev) => [...prev, ...newImages]);
          setTemporary((prev) => ({ ...prev, tempImages: stateCopy }));
        }
      } catch (err) {
        message.error(err.message);
        setImageName('');
        toggleShowProgress(false);
      }
      toggleShowProgress(false);
    });
  };

  const onLayoutChange = (newItems) => {
    setRemoveItems(true);
    setTemporary((prev) => ({ ...prev, images: [...newItems] }));
  };

  // when image gallery config changes
  const onConfigChange = (newConfig) => {
    setTemporary((prev) => ({ ...prev, config: newConfig }));
  };

  const onAddFromLib = async (images) => {
    const stateCopy = temporary.images;
    let maxId = stateCopy.reduce((acc, currentObj) => {
      const currentId = parseInt(currentObj.i || 0);
      return currentId > acc ? currentId : acc;
    }, 0);

    for (const image of images) {
      maxId = maxId + 1;

      stateCopy.push({
        i: `${maxId}`,
        w: image?.width || 5,
        h: image?.height || 4,
        image: await copy({ fromUrl: image.src, prefix: `props/${prop._id}` }),
      });
    }
    toggleLibraryModal(false);
    setSeletedItem({});
    setTemporary((prev) => ({ ...prev, images: stateCopy }));
  };

  const handleSave = () => {
    if (selectImage) {
      setSelectImage(false);
      setUploadingImages([]);
      setTemporary((prev) => {
        const newData = { ...prev, images: prev.tempImages };
        delete newData.tempImages;
        return newData;
      });
    } else if (libraryModal) {
      onAddFromLib(Object.values(selectedItems));
    } else {
      setTemporary({ ...DEFAULT_STATE });
      onConfirm(temporary);
    }
  };

  const addFromLib = () => {
    // store the state so that come back to select image modal
    if (selectImage === true) {
      setSelectImage(0);
    }
    toggleLibraryModal(true);
  };

  const modalProps = {
    visible: showPropModal,
    wrapClassName: 'simple-info-modal rich-editor-gallery-edit',
    closeIcon: <CloseIcon />,
    onCancel: () => setShowModal(true),
    cancelButtonProps: { disabled: savingRichEditorModal },
    maskClosable: !savingRichEditorModal,
    closable: !savingRichEditorModal,
    onOk: onConfirm,
  };

  const modalTitle = (title) => (
    <div className="rich-editor-price-edit-header">
      <h3 className="title">{title}</h3>
      <Divider />
    </div>
  );

  const modalFooter = ({ onCancel, disabled, btnText = 'ADD TO GALLERY' }) => [
    <Button key="cancel" className="button cancel" type="primary" onClick={onCancel}>
      CANCEL
    </Button>,
    <Button
      key="confirm"
      className="button confirm"
      type="primary"
      onClick={handleSave}
      disabled={disabled}
      loading={savingRichEditorModal}>
      {btnText}
    </Button>,
  ];

  const exitModal = showModal && (
    <Popconfirm
      visible={showModal}
      title={'Do you want to exit?'}
      maskClosable={!savingRichEditorModal}
      closable={false}
      cancelText={'Continue'}
      confirmText={'Yes, Exit'}
      onCancel={() => {
        setShowModal(false);
        setShowPropModal(true);
      }}
      onConfirm={onCancel}
      footer={null}
    />
  );

  // library modal
  if (libraryModal) {
    return (
      <>
        <Modal
          {...modalProps}
          wrapClassName={`${modalProps.wrapClassName} select-from-library library-modal`}
          title={modalTitle('Library')}
          footer={modalFooter({
            onCancel: () => {
              toggleLibraryModal(false);
              // should go back to select image modal
              if (selectImage === 0) {
                setSelectImage(true);
              }
            },
            disabled: !Object.values(selectedItems)?.length,
          })}>
          <ContentLibrary
            selectedItems={selectedItems}
            setSeletedItem={setSeletedItem}
            copying={copying}
          />
        </Modal>
        {exitModal}
      </>
    );
  }

  // upload image modal
  if (selectImage) {
    return (
      <>
        <Modal
          {...modalProps}
          wrapClassName={`${modalProps.wrapClassName} select-box`}
          title={modalTitle('Select Images')}
          footer={modalFooter({
            onCancel: () => {
              setSelectImage(false);
              setTemporary((prev) => {
                const newData = { ...prev };
                delete newData.tempImages;
                return newData;
              });
              setUploadingImages([]);

              // close gallery edit modal
              if (!galleryEdit?.v.length && !removeItems) {
                onCancel();
              }
            },
            disabled: !uploadingImages.length,
          })}>
          <SelectImages
            dropImage={dropImage}
            uploadStatus={uploadStatus}
            showProgress={showProgress}
            imageName={imageName}
            setSelectImage={setSelectImage}
            addFromLib={addFromLib}
            uploadingImages={uploadingImages}
            // images={images}
            {...temporary}
            hideLibraryButton={isWix}
          />
        </Modal>
        {exitModal}
      </>
    );
  }

  // gallery modal
  return (
    <>
      <Modal
        {...modalProps}
        wrapClassName={`${modalProps.wrapClassName} gallery-box`}
        title={modalTitle('Edit Gallery')}
        footer={[
          <div
            className={`more-buttons ${moreButtons ? 'active' : 'inactive'}`}
            onClick={() => setMoreButtons(!moreButtons)}>
            <SectionAddIcon className="plus-icon" />
            <div className="buttons">
              <Button className="button cancel" type="primary" onClick={() => setSelectImage(true)}>
                Upload Images
              </Button>
              {!isWix && (
                <Button className="button cancel" type="primary" onClick={addFromLib}>
                  Add From Library
                </Button>
              )}
            </div>
          </div>,

          <div className="main-buttons">
            {modalFooter({
              onCancel: () => {
                onCancel();
              },
              disabled: savingRichEditorModal,
              btnText: savingRichEditorModal ? 'SAVING' : 'SAVE',
            })}
          </div>,
        ]}>
        <GalleryImageEditor
          {...temporary}
          onLayoutChange={onLayoutChange}
          onConfigChange={onConfigChange}
        />
      </Modal>
      {exitModal}
    </>
  );
};

GalleryEditModal.defaultProps = {
  savingRichEditorModal: false,
};

GalleryEditModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  galleryEdit: PropTypes.instanceOf(Object).isRequired,
  savingRichEditorModal: PropTypes.bool,
  isWix: PropTypes.bool,
};

export default GalleryEditModal;
