import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import CloseIcon from 'components/Icons/CloseIcon';

import useLocalStorage from 'hooks/useLocalStorage';
import './TermsReviewMessage.scss';

function TermsReviewMessage() {
  const [visible, setVisible] = useState(false);
  const [visibleStorage, setVisibleStorage] = useLocalStorage('terms-reviewed', {});

  const onClose = () => {
    setVisible(false);
    setVisibleStorage({ reviewed: ['all'] });

    // REMARK : if we want to enable it per proposal make changes here
    // setVisibleStorage({reviewed:[...visibleStorage?.reviewed, proposalId]});
  };

  useEffect(() => {
    const isReviewed = visibleStorage?.reviewed?.length;
    // REMARK : if we want to enable it per proposal
    // const isReviewed = visibleStorage?.reviewed && visibleStorage.reviewed.includes(proposalId);
    setVisible(!isReviewed);
  }, [visibleStorage]);

  if (!visible) {
    return null;
  }

  return (
    <div className="toast-review-message">
      <CloseIcon className="close" onClick={onClose} />
      <h4>Review suggested content</h4>
      <p>
        This template contains suggested placeholder text. Make sure to read and customize if needed
        before sending.
      </p>

      <Button size="middle" type="button" className="cancel" onClick={onClose}>
        Got it
      </Button>
    </div>
  );
}

export default TermsReviewMessage;
