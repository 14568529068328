/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8.33333" cy="3.8" r="3.8" fill="#C7BCE4" />
    <path
      d="M8.33334 6.33325C4.50381 6.33325 1.33583 9.6377 0.80998 13.9359C0.725028 14.6303 1.30044 15.1999 2 15.1999H14.6667C15.3662 15.1999 15.9416 14.6303 15.8567 13.9359C15.3308 9.6377 12.1629 6.33325 8.33334 6.33325Z"
      fill="#C7BCE4"
    />
    <circle cx="13.4" cy="7.60005" r="3.8" fill="#5D5AB5" />
    <path
      d="M13.4 10.1333C9.57047 10.1333 6.40248 13.4377 5.87663 17.7359C5.79168 18.4303 6.36709 19 7.06665 19H19.7333C20.4329 19 21.0083 18.4303 20.9233 17.7359C20.3975 13.4377 17.2295 10.1333 13.4 10.1333Z"
      fill="#5D5AB5"
    />
  </svg>
);

const UsersIcon = (props) => <Icon component={svg} {...props} />;

export default UsersIcon;
