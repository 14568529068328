/* eslint-disable */
import moment from 'moment';

import utils from 'utils/utils';
import getRaw from './getRaw';

const getHeader = ({ proposal, user, configText, wixLogo }) => {
  let logo = proposal.logo ? utils.S3Image('logos/', `${proposal.logo}`) : null;
  logo = wixLogo
    ? wixLogo
    : user && user?.branding?.companyLogo
    ? user?.branding?.companyLogo
    : logo;

  // header data from originalState
  let headerData = proposal?.draft?.header?.originalState;

  // cover/default
  let cover = utils.S3Image('application/covers/', `0${Math.floor(Math.random() * 9) + 1}.jpg`);
  cover = user && user.defaultCover ? user.defaultCover : cover;

  const headerConfig = proposal?.draft?.titleFont;
  const clientName = proposal?.client?.contact || 'Prospect Name';
  const pname = proposal?.project?.name;
  const { name } = proposal;
  const by = configText('header by');
  const contact = configText('header contact');
  const date = moment(new Date()).format(
    user?.profile?.dateFormat || configText('date format') || 'MM/DD/YYYY'
  );
  const email = proposal?.email || 'MYEMAIL@GMAIL.COM';
  const subject = proposal?.client?.subject || '';
  const title = `${pname}`;
  const subtitle = subject
    ? subject
    : `${configText('header prepared for', true)}<br>${clientName}<br>${date}`;

  if (!headerData) {
    headerData = {
      by,
      rawby: getRaw(by),
      contact,
      rawcontact: getRaw(contact),
      title,
      rawtitle: getRaw(title),
      subtitle,
      rawsubtitle: getRaw(subtitle),
      name,
      rawname: getRaw(name),
      email,
      rawemail: getRaw(email),
      headerConfig,
    };
  }

  return {
    by,
    rawby: getRaw(by),
    contact,
    rawcontact: getRaw(contact),
    title,
    rawtitle: getRaw(title),
    subtitle,
    rawsubtitle: getRaw(subtitle),
    name,
    rawname: getRaw(name),
    email,
    rawemail: getRaw(email),
    logo,
    cover,
    color: 'rgba(0,0,0,0.4)',
    headerConfig,
    originalState: headerData,
  };
};

export default getHeader;
