import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="56" height="56" viewBox="0 -3 56 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M34.953 21.022C31.6901 21.022 28.7742 22.7132 28.7742 25.8379C28.7742 29.4213 33.9456 29.6688 33.9456 31.469C33.9456 32.227 33.077 32.9055 31.5934 32.9055C29.4878 32.9055 27.9141 31.9574 27.9141 31.9574L27.2407 35.1106C27.2407 35.1106 29.0536 35.9114 31.4605 35.9114C35.0279 35.9114 37.835 34.1371 37.835 30.959C37.835 27.1725 32.642 26.9324 32.642 25.2615C32.642 24.6677 33.3551 24.0171 34.8345 24.0171C36.5037 24.0171 37.8655 24.7066 37.8655 24.7066L38.5245 21.6612C38.5245 21.6612 37.0427 21.022 34.953 21.022ZM5.10049 21.2518L5.02148 21.7115C5.02148 21.7115 6.3942 21.9627 7.63055 22.4639C9.22245 23.0385 9.33585 23.3731 9.60396 24.4121L12.5255 35.6744H16.4418L22.4751 21.2518H18.5678L14.691 31.0578L13.109 22.7458C12.964 21.7945 12.2291 21.2518 11.3296 21.2518H5.10049ZM24.0463 21.2518L20.9811 35.6744H24.7071L27.7615 21.2518H24.0463ZM44.8272 21.2518C43.9288 21.2518 43.4527 21.7328 43.1034 22.5734L37.6447 35.6744H41.552L42.3079 33.4909H47.0682L47.5279 35.6744H50.9755L47.9678 21.2518H44.8272ZM45.3354 25.1484L46.4936 30.5604H43.3907L45.3354 25.1484Z"
        fill="url(#paint0_linear)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="5.00434"
        y1="26.1098"
        x2="50.9784"
        y2="26.1098"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#20225F" />
        <stop offset="0.2" stopColor="#1A1F61" />
        <stop offset="0.410233" stopColor="#172272" />
        <stop offset="0.595197" stopColor="#152682" />
        <stop offset="0.802083" stopColor="#12288E" />
        <stop offset="1" stopColor="#0E2C9A" />
      </linearGradient>
      <clipPath id="clip0">
        <rect width="46" height="14.9354" fill="white" transform="translate(5 21)" />
      </clipPath>
    </defs>
  </svg>
);

const VisaIcon = (props) => <Icon component={svg} {...props} />;

export default VisaIcon;
