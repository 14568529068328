import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.5 2.5H10.5V11C10.5 11.8284 9.82843 12.5 9 12.5H3C2.17157 12.5 1.5 11.8284 1.5 11V2.5Z"
      stroke="#d9d9d9"
    />
    <path
      d="M8.5 2.5L3.5 2.5L3.5 2C3.5 1.17157 4.17157 0.5 5 0.5L7 0.5C7.82843 0.5 8.5 1.17157 8.5 2L8.5 2.5Z"
      stroke="#d9d9d9"
    />
    <path d="M0.5 2.5V3V2.5ZM0.5 2.5L0.5 2L0.5 2.5Z" stroke="#d9d9d9" strokeWidth="2" />
    <rect
      x="4.25"
      y="5.25"
      width="0.5"
      height="4.5"
      rx="0.25"
      fill="#d9d9d9"
      stroke="#d9d9d9"
      strokeWidth="0.5"
    />
    <rect
      x="7.25"
      y="5.25"
      width="0.5"
      height="4.5"
      rx="0.25"
      fill="#d9d9d9"
      stroke="#d9d9d9"
      strokeWidth="0.5"
    />
  </svg>
);

const DeleteIconPrimary = (props) => <Icon component={svg} {...props} />;

export default DeleteIconPrimary;
