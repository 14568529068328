import { useImperativeHandle, useState, forwardRef } from 'react';
import { useQuery } from '@apollo/client';
import { Col, message, Row } from 'antd';
import PropTypes from 'prop-types';

import helpers from 'helpers';
import ConfirmModal from 'components/ConfirmModal';
import { useLazyQuery } from '@apollo/client';
import { PublishHeaderSection } from 'pages/Proposal/components/Publish/components/PublishContent/components';
import { GET_ALL_SECTIONS_LIBRARIES } from 'graphql/queries/contentLibraryQueries';

import './Headers.scss';

const Headers = forwardRef(({ saveProposal, dragStart, dragEnd, selectedFolder }, ref) => {
  const [changeHeaderModal, showChangeHeaderModal] = useState(false);
  const [itemId, setItemId] = useState(null);

  const { data, refetch: refetchSections } = useQuery(GET_ALL_SECTIONS_LIBRARIES, {
    fetchPolicy: 'network-only',
    variables: {
      type: 'sections',
      key: selectedFolder,
    },
  });

  useImperativeHandle(ref, () => ({
    async reloadItems() {
      await refetchSections({
        variables: {
          type: 'sections',
          key: selectedFolder,
        },
      });
    },
  }));

  const filteredItems =
    data?.fetchContentLibrarySectionsItems?.sections?.filter((item) => item.isHeader) || [];

  const [fetchHeader] = useLazyQuery(GET_ALL_SECTIONS_LIBRARIES, {
    fetchPolicy: 'network-only',
    variables: {
      type: 'sections',
      key: 'isHeader',
    },
    onCompleted: (data) => {
      if (
        data &&
        data.fetchContentLibrarySectionsItems &&
        data.fetchContentLibrarySectionsItems?.sections?.length > 0
      ) {
        [data] = data.fetchContentLibrarySectionsItems?.sections?.filter(
          (item) => item._id === itemId
        );
        const headerData = JSON.parse(JSON.stringify(data));
        delete headerData.auid;
        delete headerData.uid;
        delete headerData.libraryTitle;
        delete headerData.starred;
        saveProposal({
          'draft.header': headerData,
        });
        showChangeHeaderModal(false);
        localStorage.setItem('headerChanged', true);
      } else {
        message.error('No header found in the library');
      }
    },
    onError: (error) => {
      message.error('Error fetching header from library');
    },
  });

  const updateHeaderClick = (header_id) => {
    setItemId(header_id);
    showChangeHeaderModal(true);
  };

  return (
    <>
      <Row gutter={[15, 24]} className="library-sections-container">
        {filteredItems.map((item, itemIndex) => (
          <Col
            span={24}
            // draggable
            // onDragStart={(event) => dragStart(event, item)}
            // onDragEnd={dragEnd}
            className="cursor-pointer"
            id={`proposal-header-${item._id}`}
            data-section={JSON.stringify(item)}
            key={itemIndex + item._id}
            onClick={() => updateHeaderClick(item._id)}>
            {item.thumbnail ? (
              <div
                className={`header-section thumbnail ${
                  item ? `language-${item?.language?.toLowerCase() || 'english'}` : ''
                }`}
                style={{ backgroundImage: `url("${helpers.formatS3Link(item?.thumbnail)}")` }}
              />
            ) : (
              <div className="header-section" style={{ flexDirection: 'column' }}>
                <PublishHeaderSection
                  proposal={{ draft: { item } }}
                  name="item"
                  isPreview={true}
                  configText={(x) => x}
                  ispdf={false}
                />
              </div>
            )}
            <Row className={`section-title ${item.language === 'hebrew' ? 'hebrew' : ''}`}>
              <span>{item.libraryTitle}</span>
            </Row>
          </Col>
        ))}
      </Row>
      {changeHeaderModal && (
        <ConfirmModal
          title="Are You Sure?"
          body="This will replace the current header"
          visible={changeHeaderModal}
          cancelText="CANCEL"
          promptType={'update'}
          confirmText="YES, UPDATE"
          onCancel={() => showChangeHeaderModal(false)}
          onConfirm={() => fetchHeader()}
        />
      )}
    </>
  );
});

Headers.propTypes = {
  dragStart: PropTypes.func.isRequired,
  dragEnd: PropTypes.func.isRequired,
};

export default Headers;
