import _ from 'lodash';
import getRaw from './getRaw';

const getWhyMe2 = ({ prop, configText }) => {
  if (!prop.superPowers || _.keys(prop.superPowers).length === 0) {
    return {};
  }
  const title = configText('section whyme title');

  return {
    title,
    rawtitle: getRaw(title),
    subrawtitle: getRaw(''),
    raw: {
      entityMap: {
        0: {
          type: 'TOKEN',
          mutability: 'IMMUTABLE',
          data: {
            texcontent: JSON.stringify({ superPowers: prop.superPowers }),
          },
        },
      },
      blocks: [
        {
          key: 'as2e8',
          text: configText(`whyme ent`, true),
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: 'as2e9',
          text: '',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: '7de23',
          text: ' ',
          type: 'atomic',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [{ offset: 0, length: 1, key: 0 }],
          data: {},
        },
        {
          key: 'as2f7',
          text: '',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ],
    },
  };
};

export default getWhyMe2;