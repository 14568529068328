/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7961 7.69276C21.1402 8.07352 21.1402 8.65294 20.7961 9.0337L14.144 16.3949C13.5302 17.0742 12.402 16.6399 12.402 15.7244V11.8685C6.61137 11.9911 2.99582 14.2312 1.1512 16.1639C0.786911 16.5455 -0.0353222 16.2699 0.0307465 15.7464C1.07789 7.44981 8.26916 5.05277 12.402 4.87426V1.00201C12.402 0.0865236 13.5302 -0.347693 14.144 0.33154L20.7961 7.69276Z"
      fill="#05034D"
    />
  </svg>
);

const RedoIcon = (props) => <Icon component={svg} {...props} />;

export default RedoIcon;
