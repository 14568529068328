/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 20C15.5229 20 20 15.5229 20 10C20 4.47711 15.5229 0 10 0C4.47711 0 0 4.47711 0 10C0 15.5229 4.47711 20 10 20Z"
      fill="#2CA01C"
    />
    <path
      d="M2.77539 9.99953C2.77539 12.1473 4.5165 13.8884 6.66428 13.8884H7.21984V12.444H6.66428C5.31628 12.444 4.21984 11.3475 4.21984 9.99953C4.21984 8.65175 5.31628 7.55508 6.66428 7.55508H7.99917V15.1106C7.99917 15.4937 8.15135 15.8611 8.42224 16.132C8.69312 16.4029 9.06052 16.5551 9.44361 16.5551V6.11064H6.66428C4.5165 6.11064 2.77539 7.85197 2.77539 9.99953ZM13.3332 6.11131H12.7776V7.55575H13.3332C14.6807 7.55575 15.7776 8.65242 15.7776 10.0002C15.7776 11.348 14.6807 12.4446 13.3332 12.4446H11.9976V4.88908C11.9976 4.50599 11.8454 4.13859 11.5745 3.86771C11.3037 3.59682 10.9363 3.44464 10.5532 3.44464V13.8891H13.3332C15.4807 13.8891 17.2221 12.148 17.2221 10.0002C17.2221 7.85242 15.4807 6.11131 13.3332 6.11131Z"
      fill="white"
    />
  </svg>
);

const QuickbookIcon = (props) => <Icon component={svg} {...props} />;

export default QuickbookIcon;
