import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, useHistory } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';

import PropsRoute from './PropsRoute';
import utils from 'utils/utils';
import { isLoggedInVar, userVar } from 'graphql/cache';
import { LOCKED_PAGES } from 'constants/index';

const PrivateRoute = ({ component, ...rest }) => {
  const isLogged = useReactiveVar(isLoggedInVar);
  const user = useReactiveVar(userVar);
  const history = useHistory();
  const pathName = history.location.pathname;

  if (pathName.includes('/proposal-editor') || pathName.includes('/template-editor')) {
    return <PropsRoute component={component} {...rest} />;
  }

  const isLocked = user?.paymentStatus?.canLock;
  const redirectUrl = pathName !== '/login' && !user ? `${pathName}${history.location.search}` : '';
  const redirectPath = pathName !== '/login' ? `/login?redirect_url=${redirectUrl}` : '/login';
  const wixInstanceId = utils.getQueryStringValue('instanceId');
  const wixToken = utils.getQueryStringValue('token');

  if (wixInstanceId) {
    utils.createCookie('instanceId', wixInstanceId);
  }

  sessionStorage.setItem('redirect-url', redirectUrl);

  return (
    <>
      {isLogged && !isLocked ? (
        <PropsRoute component={component} {...rest} />
      ) : isLogged && isLocked && LOCKED_PAGES.indexOf(rest.path) !== -1 ? (
        <Redirect to="/dashboard" />
      ) : (isLogged && isLocked) || wixToken || (!user && wixInstanceId) ? (
        <PropsRoute component={component} {...rest} />
      ) : (
        <Redirect to={redirectPath} />
      )}
    </>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default PrivateRoute;
