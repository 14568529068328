import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="8.5"
      y="3"
      width="10"
      height="1"
      rx="0.5"
      transform="rotate(90 8.5 3)"
      fill="#CBCED8"
    />
    <path d="M12 10L8 13L4 10" stroke="#CBCED8" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const ArrowDownDisabled = (props) => <Icon component={svg} {...props} />;

export default ArrowDownDisabled;
