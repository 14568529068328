import React, { useEffect, useState } from 'react';
import { ContentState, EditorState } from 'draft-js';
import PropTypes from 'prop-types';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';

import { TeXHTML } from '../TeX';

const PriceMilesComponent = ({ block, blockProps }) => {
  const [, setTeXContent] = useState('');
  const _getValue = () => {
    const contentState = ContentState.createFromBlockArray([block]);
    const ent = block.getEntityAt(0);
    const entity = contentState.getEntity(ent);

    const {
      data: { texcontent },
    } = entity;
    return texcontent;
  };

  const _finishEdit = () => blockProps.onFinishEdit(block.getKey());

  const handleFocus = () => {
    blockProps.handleEditComponent(true);
  };

  const handleBlur = () => {
    blockProps.handleEditComponent(false);
  };

  const _save = (v) => {
    const entityKey = block.getEntityAt(0);
    if (entityKey) {
      const contentState = blockProps.editorState.getCurrentContent();
      const contentStateWithEntity = contentState.mergeEntityData(entityKey, {
        texcontent: v,
      });

      EditorState.set(blockProps.editorState, {
        currentContent: contentStateWithEntity,
      });
      setTeXContent(v);
      _finishEdit();
      handleBlur();
    }
  };

  const _onClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    blockProps.onEdit({ v: _getValue(), save: _save, cancel: (x) => x });
  };

  const _remove = () => {
    blockProps?.onRemove(block.getKey());
  };

  useEffect(() => {
    if (blockProps.isEditingModal) return;

    const v = _getValue();
    if (v === -42) {
      blockProps.onEdit({
        v: _getValue(),
        save: _save,
        cancel: _remove,
      });
    } else if (v === -43) {
      blockProps.onEdit({
        v: _getValue(),
        save: _save,
        cancel: _remove,
      });
    }
  });

  // const texContent = _getValue();
  const texContent = blockProps.texcontent;

  const getComponentType = (beautify = true) => {
    const [contentType] = Object.keys(JSON.parse(texContent));
    if (!beautify) {
      return contentType;
    }

    if (contentType === 'deliverables') {
      return 'Price Table';
    } else if (contentType === 'milestones') {
      return 'Milestones Table';
    } else if (contentType === 'superPowers') {
      return 'Why Me Table';
    }
  };

  return (
    <ComponentWrapper
      showDrag
      showActionButtons
      showDuplicate
      showEdit
      showDelete
      isDraggable
      sectionName={blockProps.sectionName}
      setDraggingElement={blockProps.setDraggingElement}
      blockKey={block.getKey()}
      duplicate={() => blockProps.handleDuplicateElement(texContent, null, block.getKey())}
      remove={_remove}
      onEdit={_onClick}
      onFocus={handleFocus}
      onBlur={handleBlur}
      componentType={getComponentType()}
      handleEditComponent={blockProps.handleEditComponent}
      setDropDisabled={blockProps.setDropDisabled}>
      <div className={`pricing-milestone-container ${getComponentType(false)}`} onClick={_onClick}>
        <TeXHTML
          content={texContent}
          configText={blockProps.configText}
          prop={blockProps.prop}
          proposalTextCell={blockProps.proposalTextCell}
        />
      </div>
    </ComponentWrapper>
  );
};

PriceMilesComponent.propTypes = {
  block: PropTypes.instanceOf(Object).isRequired,
  blockProps: PropTypes.instanceOf(Object).isRequired,
};

export default PriceMilesComponent;
