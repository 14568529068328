/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" width="12" height="2" rx="1" fill="#9E9EB7" />
    <rect x="4" y="5" width="12" height="2" rx="1" fill="#9E9EB7" />
    <rect x="4" y="10" width="12" height="2" rx="1" fill="#9E9EB7" />
    <circle cx="1" cy="1" r="1" fill="#9E9EB7" />
    <circle cx="1" cy="6" r="1" fill="#9E9EB7" />
    <circle cx="1" cy="11" r="1" fill="#9E9EB7" />
  </svg>
);

const ListIcon = (props) => <Icon component={svg} {...props} />;

export default ListIcon;
