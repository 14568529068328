import React, { useState } from 'react';
import { ContentState } from 'draft-js';
import { Button } from 'antd';
import PropTypes from 'prop-types';

import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';
import RenderGallery from './RenderGallery';

const GalleryComponent = ({ block, blockProps }) => {
  const [updating, setUpdating] = useState(false);
  const contentState = ContentState.createFromBlockArray([block]);

  const getGalleryValue = () => {
    const ent = block.getEntityAt(0);
    const entity = contentState.getEntity(ent);
    const { data, config } = entity.data;
    return {
      images: data,
      config,
    };
  };

  const onRemove = () => blockProps && blockProps.onRemove(block.getKey());

  const finishEdit = () => blockProps.onFinishEdit(block.getKey());

  const saveGallery = (v) => {
    v.data = v.data.map((item) => ({
      i: item.i || item.id,
      w: item.w || item.width,
      h: item.h || item.height,
      image: item.image || item.src,
    }));

    setUpdating(true);
    const entityKey = block.getEntityAt(0);
    if (entityKey) {
      contentState.replaceEntityData(entityKey, { ...v });
      blockProps.update(block);
      finishEdit();
      setTimeout(() => setUpdating(false), 500);
    }
  };

  const onEdit = () => {
    const { images, config } = getGalleryValue();
    blockProps.onEdit({ v: images, config, save: saveGallery, cancel: (x) => x });
  };

  const handleBlur = () => blockProps.handleEditComponent(false);

  const { images, config } = getGalleryValue();

  return (
    <ComponentWrapper
      showDrag
      showActionButtons
      showEdit={images.length === 0 ? false : true}
      showDelete
      isDraggable
      sectionName={blockProps.sectionName}
      setDraggingElement={blockProps.setDraggingElement}
      blockKey={block.getKey()}
      remove={onRemove}
      onEdit={onEdit}
      onBlur={handleBlur}
      componentType={'Gallery'}
      handleEditComponent={blockProps.handleEditComponent}
      setDropDisabled={blockProps.setDropDisabled}>
      <div className="gallery-container">
        <div className={`gallery-image-container ${!images.length ? 'upload' : ''}`}>
          {!updating && !!images.length && (
            <RenderGallery images={images} config={config} saveGallery={saveGallery} />
          )}
          {!updating && !images.length && (
            <div className="gallery-uploader">
              <div>Image Gallery</div>
              <Button onClick={onEdit}>SELECT IMAGES</Button>
            </div>
          )}
        </div>
      </div>
    </ComponentWrapper>
  );
};

GalleryComponent.propTypes = {
  block: PropTypes.instanceOf(Object).isRequired,
  blockProps: PropTypes.instanceOf(Object).isRequired,
};

export default GalleryComponent;
