import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Space } from 'antd';

import './LoginFailed.scss';

const LoginFailed = ({ diffMins, setLoginFailed }) => {
  return (
    <Modal className="login-failed-modal" centered visible footer={null}>
      <h3 className="title">Login Failed</h3>
      <div className="divider" />

      <p className="body">{`Account login temporarily lock due to multiple failed login attempts, please wait ${diffMins} minutes to try again.`}</p>

      <div className="button-wrapper">
        <Space>
          <Button className="button confirm" type="primary" onClick={() => setLoginFailed(false)}>
            Close
          </Button>
        </Space>
      </div>
    </Modal>
  );
};

LoginFailed.propTypes = {
  diffMins: PropTypes.number.isRequired,
  setLoginFailed: PropTypes.func.isRequired,
};

export default LoginFailed;
