/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7.49998" cy="3.74998" r="3.74998" fill="#C7BCE4" />
    <path
      d="M7.49995 6.25C3.84307 6.25 0.797468 9.30342 0.1341 13.3442C-0.0146453 14.2502 0.744327 14.9999 1.6625 14.9999H13.3374C14.2556 14.9999 15.0146 14.2502 14.8658 13.3442C14.2024 9.30342 11.1568 6.25 7.49995 6.25Z"
      fill="#C7BCE4"
    />
    <circle cx="12.5" cy="7.49998" r="3.74998" fill="#5D5AB5" />
    <path
      d="M12.5 9.99992C8.84307 9.99992 5.79747 13.0533 5.1341 17.0941C4.98535 18.0001 5.74433 18.7499 6.6625 18.7499H18.3374C19.2556 18.7499 20.0146 18.0001 19.8658 17.0941C19.2024 13.0533 16.1568 9.99992 12.5 9.99992Z"
      fill="#5D5AB5"
    />
  </svg>
);

const AddTrialUsers = (props) => <Icon component={svg} {...props} />;

export default AddTrialUsers;
