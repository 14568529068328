import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="17" height="2" rx="1" fill="#9E9EB7" />
    <rect y="5" width="9" height="2" rx="1" fill="#9E9EB7" />
    <rect y="10" width="11" height="2" rx="1" fill="#9E9EB7" />
  </svg>
);

const LeftAlignIcon = (props) => <Icon component={svg} {...props} />;

export default LeftAlignIcon;
