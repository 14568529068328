/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="#7876C1">
    <path d="M10.0002 3.75C5.8335 3.75 2.27516 6.34167 0.833496 10C2.27516 13.6583 5.8335 16.25 10.0002 16.25C14.1668 16.25 17.7252 13.6583 19.1668 10C17.7252 6.34167 14.1668 3.75 10.0002 3.75ZM10.0002 14.1667C7.70016 14.1667 5.8335 12.3 5.8335 10C5.8335 7.7 7.70016 5.83333 10.0002 5.83333C12.3002 5.83333 14.1668 7.7 14.1668 10C14.1668 12.3 12.3002 14.1667 10.0002 14.1667Z" />
    <path d="M7.5 10C7.5 8.61667 8.61667 7.5 10 7.5C11.3833 7.5 12.5 8.61667 12.5 10C12.5 11.3833 11.3833 12.5 10 12.5C8.61667 12.5 7.5 11.3833 7.5 10Z" />
  </svg>
);

const EyeIcon2 = (props) => <Icon component={svg} {...props} />;

export default EyeIcon2;
