import PropTypes from 'prop-types';
import { colors } from 'constants/tags_color';
import './TagBadge.scss';

const TagBadge = ({ className, type = 'border', color = '#7876c1', name }) => {
  // border with 30% opacity
  const style = { borderColor: `${color}30`, color };

  if (type === 'fill') {
    // get the text color
    const colorMap = new Map(colors.map((c) => [c.color, c.textColor]));
    style.background = color;
    style.color = colorMap.get(color) || color;
  }

  return (
    <span className={`tag-button ${className || ''}`} style={style}>
      {name}
    </span>
  );
};

TagBadge.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string,
  name: PropTypes.string,
};

export default TagBadge;
