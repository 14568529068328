/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
    <path
      d="M0.703125 1.60221C0.703125 0.717335 1.42046 0 2.30534 0H9.001H12.4343C12.8897 0 13.3236 0.193783 13.6275 0.532916L15.6393 2.77778L16.9804 4.57355C17.1872 4.85041 17.2989 5.1867 17.2989 5.53225V10V18.3978C17.2989 19.2827 16.5815 20 15.6967 20H2.30534C1.42046 20 0.703125 19.2827 0.703125 18.3978V1.60221Z"
      fill="#DC1D00"
    />
    <path
      d="M14.3157 12.0521C14.3094 11.978 14.2403 11.0828 12.6199 11.1199C11.0057 11.1569 10.61 11.2557 10.61 11.2557C10.61 11.2557 9.40408 10.0518 8.96442 9.11961C8.96442 9.11961 9.49829 7.58236 9.47317 6.61926C9.44805 5.65617 9.21565 5.10054 8.46195 5.10671C7.70825 5.11288 7.60147 5.76112 7.69568 6.72422C7.78362 7.58853 8.24212 8.60719 8.24212 8.60719C8.24212 8.60719 7.89667 9.66907 7.43189 10.7309C6.97339 11.7928 6.65934 12.3485 6.65934 12.3485C6.65934 12.3485 5.10169 12.8609 4.42964 13.4782C3.75759 14.0956 3.48123 14.571 3.83296 15.0464C4.14072 15.4538 5.20847 15.5464 6.16944 14.3117C7.12413 13.0769 7.55751 12.3052 7.55751 12.3052C7.55751 12.3052 9.02095 11.9101 9.47317 11.8052C9.92539 11.7002 10.4781 11.6138 10.4781 11.6138C10.4781 11.6138 11.8096 12.935 13.0972 12.8856C14.3848 12.8362 14.322 12.1262 14.3157 12.0521ZM4.16584 14.8365C3.36817 14.3673 5.84283 12.9164 6.29505 12.867C6.29505 12.867 5.00748 15.3303 4.16584 14.8365ZM7.97204 6.37849C7.97204 5.61295 8.22328 5.40922 8.41798 5.40922C8.61269 5.40922 8.83252 5.50183 8.8388 6.16241C8.84508 6.823 8.41798 8.11947 8.41798 8.11947C8.27352 7.96513 7.97204 7.13785 7.97204 6.37849ZM9.00839 11.6076C8.20444 11.799 7.80246 12.0027 7.80246 12.0027C7.80246 12.0027 7.80246 12.0027 8.12907 11.2804C8.45567 10.5581 8.79484 9.57029 8.79484 9.57029C9.24706 10.4037 10.1515 11.3854 10.1515 11.3854C10.1515 11.3854 9.81234 11.4101 9.00839 11.6076ZM10.9366 11.5397C10.9366 11.5397 13.5494 11.0767 13.5494 11.9533C13.5494 12.8238 11.929 12.4658 10.9366 11.5397Z"
      fill="white"
    />
  </svg>
);

const PublishedDownloadIcon = (props) => <Icon component={svg} {...props} />;

export default PublishedDownloadIcon;
