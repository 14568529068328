import React, { useState } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Checkbox, Row, Col } from 'antd';
import PropTypes from 'prop-types';

import DragIcon from 'components/Icons/DragIcon';
import DescriptionIcon from 'components/Icons/DescriptionIcon';
import DeleteIcon from 'components/Icons/DeleteIcon';
import DiscountIcon from 'components/Icons/DiscountIcon';
import Input from 'components/Input';
import InputNumber from 'components/InputNumber';
import EditableSelect from 'components/EditableSelect';
import helpers from 'helpers';
import utils from 'utils/utils';

const dropdownMenu = (
  x,
  showenDescription,
  showHideDescription,
  showenDiscount,
  showHideDiscount,
  removeItem,
  price
) => {
  return (
    <Menu className="tablist-options-dropdown-menu">
      {showenDescription.indexOf(x.id || x.name) === -1 && !x.description && (
        <Menu.Item
          key="1"
          icon={<DescriptionIcon />}
          onClick={() => showHideDescription(x, 'push')}>
          Add Description
        </Menu.Item>
      )}

      {(showenDescription.indexOf(x.id || x.name) !== -1 || x.description) && (
        <Menu.Item key="2" icon={<DescriptionIcon />} onClick={() => showHideDescription(x, 'pop')}>
          Remove Description
        </Menu.Item>
      )}

      {price && showenDiscount.indexOf(x.id || x.name) === -1 && !x.discount && (
        <Menu.Item key="3" icon={<DiscountIcon />} onClick={() => showHideDiscount(x, 'push')}>
          Add Discount
        </Menu.Item>
      )}

      {((price && showenDiscount.indexOf(x.id || x.name) !== -1) || x.discount) && (
        <Menu.Item key="4" icon={<DiscountIcon />} onClick={() => showHideDiscount(x, 'pop')}>
          Remove Discount
        </Menu.Item>
      )}

      <Menu.Item key="5" icon={<DeleteIcon />} onClick={removeItem(x)}>
        Delete
      </Menu.Item>
    </Menu>
  );
};

const Price = ({
  displayType,
  key,
  price,
  listIndex,
  valuePayemntType,
  x,
  propCurr,
  quantity,
  selectable,
  handleTotalChange,
  editName,
  configText,
  editQuantity,
  editPrice,
  editItemDiscount,
  editDescription,
  showenDescription,
  showHideDescription,
  showenDiscount,
  showHideDiscount,
  handleOptionsDropdown,
  removeItem,
  discountValue,
  language,
}) => {
  const [quantities, setQuantities] = useState({
    items: x.items,
    quantity: x.quantity,
    years: x.years,
  });

  // const presentPrice = x.currentPrice || x.price;
  // const [priceValue, setPriceValue] = useState(presentPrice); //helpers.commafi({ prop, x: x.price, curr: propCurr })
  // const [currentPriceValue] = useState(Number.isNaN(x.currentPrice) ? 0 : x.currentPrice);

  // let getStatePrice = (x) => {
  //   console.log('x', x)
  //   if (x.discount && (currentPriceValue !== undefined || currentPriceValue !== '')) {
  //     if (x.discount.value >= 100 && x.discount.unit === '%') {
  //       console.log('yello', currentPriceValue);
  //       const _currentPriceValue = parseFloat(currentPriceValue);
  //       if (Number.isNaN(_currentPriceValue) && x.currentPrice !== '') {
  //         setPriceValue(Number.isNaN(x.currentPrice) ? 0 : x.currentPrice);
  //       } else {
  //         console.log('4', _currentPriceValue);
  //         setPriceValue(Number.isNaN(_currentPriceValue) ? 0 : _currentPriceValue);
  //       }
  //     }
  //   }
  // };

  // useEffect(() => {
  //   getStatePrice(x);
  //   //eslint-disable-next-line
  // }, [x]);

  const handlePriceValue = (event) => {
    // setPriceValue(event.target.value);
    // passing number
    editPrice(x)(event.target.value);
  };

  const handleQuantityChange = (value, type = 'items') => {
    // type = items | quantity | years
    value = utils.onlyNumber(value);

    // converting '1.' or '1.0' to number will result into 1,
    // thus it will prevent entering 1.05
    if (typeof value === 'string' && !value.endsWith('.') && !value.endsWith('.0')) {
      value = Number(value) || '';
    }
    setQuantities((prev) => ({ ...prev, [type]: value }));
    editQuantity(value, x, type);
  };

  const showDiscount = price && (showenDiscount.indexOf(x.id || x.name) !== -1 || x.discount);
  const recurringTypes = ['Hourly', 'Monthly', 'Annually'];

  return (
    <div className="tablist-sortlist-container" key={key}>
      <div className="handle">
        <DragIcon />
        <DragIcon />
      </div>
      <div key={(x.id || x.name) + listIndex} className="tablist-sortlist-content">
        <Row className="noselect tablist-input-container">
          <Col flex="auto" className="item-name-wrap">
            {selectable && (
              <Checkbox
                checked={x.checked}
                onChange={(e) => handleTotalChange(e, x)}
                disabled={selectable === 'locked'}
              />
            )}
            <Input
              type="textarea"
              autoSize={{ minRows: 1, maxRows: 2 }}
              containerclass="tablist-input"
              value={x.name}
              onChange={editName(x)}
            />
          </Col>

          {/* eslint-disable */}
          <Col
            className={`price-inputs inputs-${displayType} ${quantity ? 'inputs-quantity' : ''} 
            ${recurringTypes.includes(valuePayemntType) ? 'inputs-recurring' : ''}`}>
            {quantity &&
            ((x.type === 'Hourly' && price) || (valuePayemntType === 'Hourly' && !price)) ? (
              <InputNumber
                min={0}
                value={quantities.quantity}
                containerclass="tablist-input tablist-quantity quantity"
                placeholder={configText('No of Hours') || 'No. of Hours'}
                onChange={(e) => handleQuantityChange(e.target.value, 'quantity')}
                onHandleChange={(value) => handleQuantityChange(value, 'quantity')}
              />
            ) : null}

            {quantity &&
            ((x.type === 'Monthly' && price) || (valuePayemntType === 'Monthly' && !price)) ? (
              <InputNumber
                min={0}
                value={quantities.quantity}
                containerclass="tablist-input tablist-quantity quantity"
                placeholder={configText('No of Months') || 'No. of Months'}
                onChange={(e) => handleQuantityChange(e.target.value, 'quantity')}
                onHandleChange={(value) => handleQuantityChange(value, 'quantity')}
              />
            ) : null}

            {quantity &&
            ((x.type === 'Annually' && price) || (valuePayemntType === 'Annually' && !price)) ? (
              <InputNumber
                min={0}
                containerclass="tablist-input tablist-quantity quantity"
                value={quantities.years}
                placeholder={configText('No of Years') || 'No. of Years'}
                onChange={(e) => handleQuantityChange(e.target.value, 'years')}
                onHandleChange={(value) => handleQuantityChange(value, 'years')}
              />
            ) : null}
            {/* eslint-enable */}

            {quantity ? (
              <InputNumber
                min={0}
                containerclass="tablist-input tablist-items items"
                value={quantities.items}
                placeholder={configText('No of Items') || 'No. of Items'}
                onChange={(e) => handleQuantityChange(e.target.value, 'items')}
                onHandleChange={(value) => handleQuantityChange(value, 'items')}
              />
            ) : null}

            {displayType === 'table' && (
              <div className="price">
                <div className="currency">{helpers.shorten(propCurr, 3, '...')}</div>
                <div>
                  {price ? (
                    <Input
                      containerclass="tablist-input tablist-price"
                      value={
                        typeof x.currentPrice === 'undefined' ? x?.price || 0 : x?.currentPrice
                      }
                      onChange={handlePriceValue}
                      placeholder="Price"
                    />
                  ) : null}

                  {showDiscount && (
                    <Input
                      containerclass="tablist-discount"
                      value={discountValue}
                      onChange={(e) => editItemDiscount(e, x, 'value')}
                      placeholder={configText('discount text') || 'Discount'}
                      // prefixtext={<span className="hidden-currency">{helpers.shorten(propCurr, 3, '...')}</span>}
                      suffixtext={
                        <>
                          <span
                            className={
                              x?.discount?.unit && x?.discount?.unit !== '%' ? 'suffix-active' : ''
                            }
                            onClick={() =>
                              !x?.discount?.unit || x?.discount?.unit === '%'
                                ? editItemDiscount(null, x, 'unit', 'value')
                                : {}
                            }>
                            {propCurr}
                          </span>
                          <span
                            className={
                              !x?.discount?.unit || x?.discount?.unit === '%' ? 'suffix-active' : ''
                            }
                            onClick={() =>
                              x?.discount?.unit !== '%'
                                ? editItemDiscount(null, x, 'unit', '%')
                                : {}
                            }>
                            %
                          </span>
                        </>
                      }
                    />
                  )}
                </div>
              </div>
            )}

            <div className="price-options">
              {price ? (
                <EditableSelect
                  items={['One-Time', 'Monthly', 'Annually', 'Hourly']}
                  selectClassName="price-options-dropdown"
                  inputClassName="price-options-input"
                  placeholder="Payment Type"
                  x={x}
                  handleOptionsDropdown={handleOptionsDropdown}
                  configText={configText}
                  dropdownClassName={
                    language === 'hebrew' || language === 'arabic' ? 'wizard-language-rtl' : ''
                  }
                />
              ) : null}

              <Dropdown
                overlay={() => {
                  return dropdownMenu(
                    x,
                    showenDescription,
                    showHideDescription,
                    showenDiscount,
                    showHideDiscount,
                    removeItem,
                    price
                  );
                }}
                trigger={['click']}
                placement="bottomRight">
                <MoreOutlined
                  className="tablist-input-options"
                  onClick={(e) => e.preventDefault()}
                />
              </Dropdown>

              {showDiscount && (
                <DeleteIcon
                  className="input-discount-delete"
                  onClick={() => showHideDiscount(x, 'pop')}
                />
              )}
            </div>
          </Col>
        </Row>

        {(showenDescription.indexOf(x.id || x.name) !== -1 || x.description) && (
          <Input
            containerclass="tablist-input-description"
            type="textarea"
            autoSize={{ minRows: 1 }}
            placeholder="Enter Description"
            value={x.description}
            onChange={editDescription(x)}
            allowClear
          />
        )}
      </div>
    </div>
  );
};

Price.defaultProps = {
  displayType: 'table',
  selectable: '',
  quantity: false,
  valuePayemntType: false,
  removeItem: () => {},
  showHideDiscount: () => {},
  editItemDiscount: () => {},
  showenDiscount: [],
  language: '',
};

Price.propTypes = {
  displayType: PropTypes.string,
  currentPrice: PropTypes.string,
  price: PropTypes.bool.isRequired,
  x: PropTypes.instanceOf(Object).isRequired,
  propCurr: PropTypes.string.isRequired,
  listIndex: PropTypes.string.isRequired,
  handleTotalChange: PropTypes.func.isRequired,
  editName: PropTypes.func.isRequired,
  configText: PropTypes.instanceOf(Object).isRequired,
  editQuantity: PropTypes.func.isRequired,
  editPrice: PropTypes.func.isRequired,
  editDescription: PropTypes.func.isRequired,
  editItemDiscount: PropTypes.func,
  showenDescription: PropTypes.instanceOf(Array).isRequired,
  showHideDescription: PropTypes.func.isRequired,
  showenDiscount: PropTypes.instanceOf(Array),
  showHideDiscount: PropTypes.func,
  handleOptionsDropdown: PropTypes.func.isRequired,
  selectable: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  quantity: PropTypes.bool,
  removeItem: PropTypes.func,
  valuePayemntType: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  language: PropTypes.string,
};

export default Price;
