import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

import CloseIcon from 'components/Icons/CloseIcon';
import utils from 'utils/utils';

import './TestSignProposalModal.scss';

const TestSignProposalModal = ({
  signing,
  proposal,
  onCancel,
  onConfirm,
  configText,
  btnText,
  dateFormat,
  clientType,
}) => {
  const defaultBtnText = configText('sign proposal button text');
  const section = proposal.draft.signature;
  const signatureSection = clientType === 'main' ? section : section?.additionalSignature;
  const sigdata = section
    ? {
        myname: section.rawmyname ? stateToHTML(convertFromRaw(section.rawmyname)) : section.myname,
        name: signatureSection?.rawname
          ? stateToHTML(convertFromRaw(signatureSection.rawname))
          : signatureSection?.name || '',
      }
    : {
        name: proposal.client && proposal.client.contact,
        myname: proposal.name,
      };

  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = sigdata.name;
  let proposalClientName = tempDiv.textContent;

  if (dateFormat) {
    proposalClientName = utils.changeVariable(
      proposal?.draft?.variables,
      proposalClientName,
      dateFormat
    );
  }

  return (
    <Modal
      className={`simple-info-modal test-sign-proposal-modal language-${
        proposal?.language?.toLowerCase() || 'english'
      }`}
      centered
      visible
      footer={null}
      closeIcon={<CloseIcon className="close-icon" />}
      onCancel={onCancel}>
      <h3 className="title">
        {configText('are you really title', false, { client_name: proposalClientName })}
      </h3>
      <div className="divider" />
      <div className="body">
        <p>{configText('are you really subtitle')}</p>
      </div>

      <div className="button-wrapper">
        <Button className="button cancel" type="primary" onClick={onCancel}>
          {configText('are you really no button')}
        </Button>
        <Button className="button confirm" onClick={onConfirm} loading={signing}>
          {utils.getSignText(
            configText('are you really yes button'),
            btnText !== defaultBtnText,
            proposal?.language?.toLowerCase()
          )}
        </Button>
      </div>
    </Modal>
  );
};

TestSignProposalModal.defaultProps = {
  signing: false,
  dateFormat: '',
};

TestSignProposalModal.propTypes = {
  signing: PropTypes.bool,
  dateFormat: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  configText: PropTypes.func.isRequired,
  proposal: PropTypes.instanceOf(Object).isRequired,
  clientType: PropTypes.string,
};

export default TestSignProposalModal;
