import React from 'react';
import PropTypes from 'prop-types';

import { Row, Table } from 'antd';

const ReferralTable = ({ monthlyAffiliates, yearlyAffiliates }) => {
  let monthlyCost = 0;
  let yearlyCost = 0;

  monthlyAffiliates.forEach((affiliate) => {
    monthlyCost += affiliate.amount;
  });

  yearlyAffiliates.forEach((affiliate) => {
    yearlyCost += affiliate.amount;
  });

  const columns = [
    {
      title: 'Plans',
      dataIndex: 'plans',
      key: 'plans',
    },
    {
      title: 'Referral (Commissions)',
      dataIndex: 'referral',
      key: 'referral',
    },
  ];
  const data = [
    {
      key: '1',
      plans: 'Monthly',
      referral: `${monthlyAffiliates.length}($${monthlyCost})`,
    },
    {
      key: '2',
      plans: 'Yearly',
      referral: `${yearlyAffiliates.length}($${yearlyCost})`,
    },
  ];

  return (
    <div className="affiliate-main-content table-block">
      <Row className="heading">
        <h3>Total referrals and commissions</h3>
      </Row>

      <Table
        className="affiliate-table"
        rowClassName={(_record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
        pagination={false}
        columns={columns}
        dataSource={data}
      />
    </div>
  );
};

ReferralTable.defaultProps = {
  monthlyAffiliates: [],
  yearlyAffiliates: [],
};

ReferralTable.propTypes = {
  monthlyAffiliates: PropTypes.arrayOf(PropTypes.any),
  yearlyAffiliates: PropTypes.arrayOf(PropTypes.any),
};

export default ReferralTable;
