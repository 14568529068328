import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useMutation } from '@apollo/client';
import { message } from 'antd';
// import { useIntercom } from 'react-use-intercom';

import Loader from 'components/Loader/Loader';
import utils from 'utils/utils';
// import {  isLoggedInVar } from 'graphql/cache';
import { CONNECT_NEW_WIX_USER } from 'graphql/mutations/integrationMutation';
// import { updateIntercomUser } from 'helpers/users';

const WixAppWindow = () => {
  // const { trackEvent, update } = useIntercom();

  if (utils.getQueryStringValue('close')) {
    window.close();
  }

  const [connectNewWixUser] = useMutation(CONNECT_NEW_WIX_USER, {
    skip: !window.location.href,
    onCompleted: (data) => {
      const { connectNewWixUser } = data;

      if (connectNewWixUser) {
        // window.ga('send', 'event', 'registeration', 'sign up to trial', 'freetrial');
        // window.ga('send', 'pageview', '/event/sign-up');
        // window.gtag('event', 'conversion', { send_to: 'AW-848665564/735ACKCqsJEDENy31pQD' });
        // window.localStorage.setItem('prospero-token', token);
        // window.localStorage.setItem('prospero-user-id', user._id);

        // trackEvent('sign-up');
        // updateIntercomUser(user, update);

        // isLoggedInVar(true);
        console.log(connectNewWixUser, '=-=-=connectNewWixUser');
        window.open(
          `https://www.wix.com/installer/close-window?access_token=${connectNewWixUser}`,
          '_self'
        );
      }
    },
    onError: (err) => {
      console.log(err);
      message.error('Wix Connect Failed');
    },
  });

  useEffect(() => {
    const wixToken = utils.getQueryStringValue('token');
    const wixCode = utils.getQueryStringValue('code');
    const wixInstanceId = utils.getQueryStringValue('instanceId');
    const wixState = utils.getQueryStringValue('state');

    if (wixToken) {
      const wixNewState = sessionStorage.getItem('wix-state') || uuidv4();
      sessionStorage.setItem('wix-state', wixNewState);

      window.location.href = `https://www.wix.com/installer/install?token=${wixToken}&appId=${process.env.REACT_APP_WIX_APP_APP_ID}&redirectUrl=${process.env.REACT_APP_WIX_APP_REDIRECT_URI}&state=${wixNewState}`;
    } else if (wixCode && wixInstanceId && wixState === sessionStorage.getItem('wix-state')) {
      connectNewWixUser({
        variables: {
          wixCode,
          wixInstanceId,
        },
      });
    } else {
      const wixNewState = sessionStorage.getItem('wix-state') || uuidv4();
      sessionStorage.setItem('wix-state', wixNewState);

      window.open(
        `https://www.wix.com/installer/install?appId=${process.env.REACT_APP_WIX_APP_APP_ID}&state=${wixNewState}&redirectUrl=${process.env.REACT_APP_WIX_APP_REDIRECT_URI}`,
        '_self'
      );
    }
  }, [connectNewWixUser]);

  return <Loader />;
};

export default WixAppWindow;
