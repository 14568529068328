import React, { useState } from 'react';
import { Space, Table, Button, Input } from 'antd';
import { useQuery } from '@apollo/client';
import moment from 'moment';

import { FETCH_ADMIN_TRANSACTIONS } from 'graphql/queries/adminQueries';

const TransactionDetails = () => {
  const [adminTransactions, setAdminTransactions] = useState([]);
  const [adminTransactionsCount, setAdminTransactionsCount] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);

  const transactionColumn = [
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Kind',
      dataIndex: 'kind',
      key: 'kind',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'User Details',
      dataIndex: 'uid',
      key: 'uid',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Txn id',
      dataIndex: 'txnId',
      key: 'txnId',
    },
    {
      dataIndex: 'transData',
      key: 'transData',
    },
  ];

  const updateAdminTransactions = (transactions) => {
    setAdminTransactions(
      transactions.map((transaction) => ({
        _id: transaction._id,
        createdAt: moment(transaction.time).format('DD/MM/YYYY hh:mm:ss'),
        type: transaction.trans.txn_type || transaction.type || '?',
        uid: transaction.uid,
        amount: transaction.trans.mc_gross,
        txnId: transaction.trans.txn_id,
        kind: transaction.trans.item_number || transaction.trans.product_name,
        transData: transaction.trans,
      }))
    );
  };

  const {
    loading: isFetchingAdminTransactions,
    refetch,
    fetchMore,
  } = useQuery(FETCH_ADMIN_TRANSACTIONS, {
    variables: {
      queryString: searchText,
      limit: limit,
      offset: offset,
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const {
        fetchAdminTransactions: { transactions, transactionsCount },
      } = res;

      updateAdminTransactions(transactions);

      if (offset === 0) {
        setAdminTransactionsCount(transactionsCount);
      }
    },
  });

  const handleReset = () => {
    setSearchText('');
  };

  return (
    <div>
      {/* <GeneralStats /> */}
      <div className="admin-table-wrapper">
        <div className="table-action">
          <h3>All Transactions</h3>
          <div>
            <div />
            <Space>
              <Input.Search
                width="200"
                placeholder="Email/Uid"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onSearch={() => refetch()}
              />
              <Button type="primary" onClick={handleReset}>
                Reset
              </Button>
            </Space>
          </div>
        </div>
        <Table
          loading={isFetchingAdminTransactions}
          rowClassName={(record) =>
            record.transData &&
            parseFloat(record.transData.mc_gross) >= 1 &&
            record.transData.type !== 'pdt' &&
            ((record.transData.txn_type === 'web_accept' &&
              record.transData.payment_status === 'Completed') ||
              record.transData.txn_type === 'recurring_payment')
              ? 'table-row-dark'
              : 'table-row-light'
          }
          dataSource={adminTransactions}
          columns={transactionColumn}
          rowKey={'_id'}
          pagination={{
            total: adminTransactionsCount,
            defaultPageSize: limit,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30'],
            onChange: async (page, size) => {
              setLimit(size);
              setOffset(page);

              let fetchMoreResult = await fetchMore({
                variables: {
                  queryString: searchText,
                  limit: size,
                  offset: page - 1,
                },
              });
              const newAdminTransactions = fetchMoreResult?.fetchAdminTransactions?.proposals || [];
              const updatedAdminTransactions = [...newAdminTransactions];
              updateAdminTransactions(updatedAdminTransactions);
            },
          }}
        />
      </div>
    </div>
  );
};

export default TransactionDetails;
