/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { Layout, Row, Col, Tooltip } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { USER_DETAILS } from 'graphql/queries/userQueries';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { getUserId, userVar } from 'graphql/cache';
import _ from 'lodash';

import Button from 'components/Button';
import PageHeader from 'components/PageHeader';
import Sidebar from './Sidebar/Sidebar';
import ArrowRight from 'components/Icons/ArrowRight';
import ResetIcon from 'components/Icons/ResetIcon';
import UndoIcon from 'components/Icons/UndoIcon';
import RedoIcon from 'components/Icons/RedoIcon';
import Loader from 'components/Loader';
import DraftContext from 'context/DraftContext';
import ProposalHeaderBar from '../ProposalHeaderBar';
import utils from '../Draft/utils';

import { PROPOSAL_SUBSCRIPTION_TOPIC } from 'constants/index';

import {
  YouInformation,
  DeliverableInformation,
  MilesInformation,
  ClientInformation,
  ProjectInformation,
} from './Steps';
import {
  defaultHebrewTitleSettings,
  defaultHebrewBodySettings,
  defaultHebrewSubTitleSettings,
} from 'pages/Proposal/constants/constants';

import helpers, { getCompletion } from 'helpers/proposal';

import './Wizard.scss';
import { useProposalContext } from 'context/proposal';

const { Content } = Layout;

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

let savingWizardProposal = {
  [0]: false,
  [1]: false,
  [2]: false,
  [3]: false,
  [4]: false,
};
let saveCalled = false;

const Wizard = ({
  prop,
  saveUser,
  config,
  wixProductsAndServices,
  wixSiteProperties,
  setWixSiteProperties,
  fetchWixProductsAndServicesAndInfo,
  fetchMoreWixProductsAndServicesLoading,
  fetchMoreWixProductsAndServices,
  history,
  match: { params },
}) => {
  if (prop && prop?.importedProposal) {
    const proposalId = params.pid;
    history.push(`/d/${proposalId}`);
  }
  const { saveTemplateProposal: saveProposal, updateProposalPreHandler: updateProposal } =
    useProposalContext();
  const user = useReactiveVar(userVar);
  const [proposal, setProposal] = useState(prop);
  const [cState, setCState] = useState({});
  const [step, setStep] = useState(0);
  const [ptype, setPtype] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [undoableWizardStack, setUndoableWizardStack] = useState({
    [0]: {
      past: [],
      present: prop.client ? JSON.parse(JSON.stringify({ client: prop.client })) : [],
      future: [],
    },
    [1]: {
      past: [],
      present: prop.project ? JSON.parse(JSON.stringify({ project: prop.project })) : [],
      future: [],
    },
    [2]: {
      past: [],
      present: JSON.parse(JSON.stringify({ superPowers: prop.superPowers })),
      future: [],
    },
    [3]: {
      past: [],
      present: prop.deliverables
        ? JSON.parse(JSON.stringify({ deliverables: prop.deliverables }))
        : [],
      future: [],
    },
    [4]: {
      past: [],
      present: JSON.parse(JSON.stringify({ milestones: prop.milestones })),
      future: [],
    },
  });

  const [loadUser, { loading: loadingUser, data }] = useLazyQuery(USER_DETAILS, {
    variables: { id: getUserId() },
    skip: !getUserId() || user,
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const { fetchUser } = res;
      userVar({ ...user, ...fetchUser });
    },
  });

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  const defaultFamily = user?.profile?.defaultProposalFont?.trim() || '"Poppins", sans-serif';
  const defaultFont = user?.profile?.defaultProposalFont?.trim() || 'Poppins';
  const defaultBodySettings = {
    title: defaultFont,
    family: defaultFamily,
    weight: 400,
    weights: [400, 700],
    color1: 'white',
    color2: '#4a4a4a',
    color3: 'black',
    fontSize: '20px',
  };

  const defaultTitleSettings = {
    title: defaultFont,
    family: defaultFamily,
    weight: 400,
    weights: [400, 700],
    color1: 'white',
    color2: '#d2d2d2',
    color3: 'black',
    fontSize: '36px',
  };
  const defaultSubTitleSettings = {
    title: defaultFont,
    family: defaultFamily,
    weight: 400,
    weights: [400, 700],
    color1: 'gray',
    fontSize: '18px',
  };

  const clientRef = useRef();
  const projectRef = useRef();
  const delivsRef = useRef();
  const youRef = useRef();
  const milesRef = useRef();

  useEffect(() => {
    if (window.location.pathname.indexOf('/w/') > -1) {
      document.title = 'Prospero - Proposal';
    }
  });

  useEffect(() => {
    const cantSave = Object.keys(savingWizardProposal).some((key) => {
      if (savingWizardProposal[key]) {
        return true;
      }
    });

    if (!cantSave && cState === 'edit' && !saveCalled) {
      saveCalled = true;
      let { client } = proposal;

      client['text'] = null;
      savingWizardProposal = { ...savingWizardProposal, ...{ [0]: true } };
      setTimeout(function () {
        saveProposal(
          {
            from: 'wizard',
            state: 'edit',
            proptext: null,
            draft: {
              header: utils.getHeader({
                proposal: prop,
                user,
                configText,
                wixLogo: wixSiteProperties?.logo,
              }),
              overview: utils.getOverview({ prop, configText }),
              whyme2: utils.getWhyMe({ prop, configText }),
              pricing2: utils.getPricing({ prop, configText }),
              milestones2: utils.getMilestones({ prop, configText }),
              terms: utils.getTerms({ prop, configText, user }),
              whatsnext: utils.getWhatsNext({ configText }),
              signature: utils.getSignature({
                prop,
                user,
                configText,
              }),
              sectionorder: helpers.defaultSectionOrder,
              bodyFont:
                prop?.language?.toLowerCase() === 'hebrew' ? defaultHebrewBodySettings : defaultBodySettings,
              titleFont:
                prop?.language?.toLowerCase() === 'hebrew' ? defaultHebrewTitleSettings : defaultTitleSettings,
              subTitleFont:
                prop?.language?.toLowerCase() === 'hebrew'
                  ? defaultHebrewSubTitleSettings
                  : defaultSubTitleSettings,
            },
            signature: null,
            client: client,
          },
          () => {
            const proposalInfo = {
              id: prop._id,
              _id: prop._id,
              pid: prop.pid,
              uid: prop.uid,
              edited: new Date(),
              editEvent: prop.editEvent,
            };

            updateProposal(
              {
                variables: {
                  topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${
                    prop?.channel || prop?.auid || prop?.uid
                  }`,
                  updateType: 'created',
                  proposal: proposalInfo,
                },
              },
              {
                ...prop,
                ...proposalInfo,
              }
            );
            setIsLoading(false);
            history.push({ pathname: `/d/${params.pid}` });
            saveCalled = false;
          }
        );
      }, 1000);
    }
  }, [savingWizardProposal, cState]);

  const canMoveToState = (s) => {
    const { clientcomplete, projectcomplete, youcomplete, pricingcomplete, delivcomplete } =
      getCompletion({ proposal });

    if (s === 'project' && !clientcomplete) {
      return false;
    }

    if (s === 'you' && (!projectcomplete || !clientcomplete)) {
      return false;
    }

    if (s === 'deliverables' && (!youcomplete || !projectcomplete || !clientcomplete)) {
      return false;
    }

    if (
      s === 'milestones' &&
      (!delivcomplete || !pricingcomplete || !projectcomplete || !clientcomplete)
    ) {
      return false;
    }

    return true;
  };

  const saveWizardProposal = (proposalValue, stateStep, savedCallback = () => {}) => {
    proposalValue.from = 'wizard';
    saveProposal(proposalValue, savedCallback(stateStep));

    const rest = {};

    for (const [key, value] of Object.entries(proposalValue)) {
      if (!key.includes('draft')) {
        rest[key] = value;
      }
    }

    setProposal({ ...prop, ...proposal, ...rest });
  };

  const setPropState = (s, stateStep) => {
    return (e) => {
      e && e.preventDefault();

      let { state: cs } = proposal;

      if (!cs) {
        cs = 'client';
      }

      if (!canMoveToState(s)) {
        return;
      }

      window.ga('send', 'event', 'prop-state', 'sect-' + s, window.location.href);
      window.ga('send', 'pageview', '/event/wizard-section-' + s);

      if (s !== 'edit') {
        savingWizardProposal = { ...savingWizardProposal, ...{ [stateStep]: true } };

        const proposalValue = {
          state: s,
        };

        // moved to optional state - update flag
        if (s === 'you') {
          proposalValue['sawyou'] = true;
        } else if (s === 'milestones') {
          proposalValue['sawmilestones'] = true;
        }

        saveWizardProposal(
          proposalValue,
          stateStep,
          (newStateStep) => () =>
            (savingWizardProposal = { ...savingWizardProposal, ...{ [newStateStep]: false } })
        );
      }
    };
  };

  const handleStep = (currentState) => {
    if (!canMoveToState(currentState)) return;

    if (currentState === 'edit') {
      setIsLoading(true);
    }

    setCState(currentState);
    let newStep = 0;

    switch (currentState) {
      case 'client':
        history.push({ pathname: `/w/${params.pid}`, search: '?step=1' });
        newStep = 0;
        return;
      case 'project':
        history.push({ pathname: `/w/${params.pid}`, search: '?step=2' });
        newStep = 1;
        break;
      case 'you':
        history.push({ pathname: `/w/${params.pid}`, search: '?step=3' });
        newStep = 2;
        break;
      case 'deliverables':
        history.push({ pathname: `/w/${params.pid}`, search: '?step=4' });
        newStep = 3;
        break;
      case 'milestones':
        history.push({ pathname: `/w/${params.pid}`, search: '?step=5' });
        newStep = 4;
        break;
      default:
        setStep(0);
    }

    setPropState(currentState, newStep)();
    setStep(newStep);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const queryParamsStep = Number(urlParams.get('step'));
    if (typeof queryParamsStep === 'number' && !isNaN(queryParamsStep))
      setStep(queryParamsStep ? queryParamsStep - 1 : 0);
  }, [window.location.search]);

  const handleState = () => {};

  const handleWizardUndoRedo = (type, proposalInfo, tempUndoableWizardStack, wizardStep) => {
    const { past, present, future } = tempUndoableWizardStack[wizardStep];

    switch (type) {
      case 'UNDO':
        const previous = past[past.length - 1];
        const newPast = past.slice(0, past.length - 1);
        previous.from = 'wizard';
        saveProposal(previous);
        delete previous.from;
        setProposal({ ...prop, ...JSON.parse(JSON.stringify({ ...previous })) });
        setUndoableWizardStack(
          Object.assign(
            { ...tempUndoableWizardStack },
            {
              [wizardStep]: {
                past: newPast,
                present: previous,
                future: [present, ...future],
              },
            }
          )
        );

        break;
      case 'REDO':
        const [next] = future;
        const newFuture = future.slice(1);
        next.from = 'wizard';
        saveProposal(next);
        delete next.from;
        setProposal({
          ...prop,
          ...JSON.parse(JSON.stringify({ ...next })),
        });
        setUndoableWizardStack(
          Object.assign(
            { ...tempUndoableWizardStack },
            {
              [wizardStep]: {
                past: [...past, present],
                present: next,
                future: newFuture,
              },
            }
          )
        );

        break;
      default:
        const newPresent = JSON.parse(JSON.stringify({ ...proposalInfo }));

        if (JSON.stringify(present) === JSON.stringify(newPresent)) {
          return setUndoableWizardStack(tempUndoableWizardStack);
        }
        setUndoableWizardStack(
          Object.assign(
            { ...tempUndoableWizardStack },
            {
              [wizardStep]: {
                past: Array.isArray(present) ? [...past, ...present] : [...past, ...[present]],
                present: newPresent,
                future: [],
              },
            }
          )
        );

        break;
    }
  };

  const saveWizardProposalCallback = (savedStep) => {
    return (rest, skipUndoRedo = false, saveCallback = () => {}) => {
      !skipUndoRedo && handleWizardUndoRedo(null, rest, undoableWizardStack, step);
      savingWizardProposal = { ...savingWizardProposal, ...{ [savedStep]: false } };
      saveCallback();
    };
  };

  const save = (
    proposalValue,
    immediate,
    skipUndoRedo = false,
    saveCallback = () => {},
    stateStep = 0
  ) => {
    savingWizardProposal = { ...savingWizardProposal, ...{ [stateStep]: true } };

    const name = Object.keys(proposalValue).join('_');
    const handlename = `wizardsaving_${name}`;
    const types = Object.keys(proposalValue);

    window.clearTimeout(window[handlename]);

    if (types.includes('pricing.strategy')) {
      proposalValue['pricing'] = { strategy: proposalValue['pricing.strategy'] };
      delete proposalValue['pricing.strategy'];
    }

    const rest = {};
    for (const [key, value] of Object.entries(proposalValue)) {
      if (!key.includes('draft')) {
        rest[key] = value;
      }
    }

    if (immediate) {
      saveWizardProposal(proposalValue, stateStep, (newStateStep) =>
        saveWizardProposalCallback(newStateStep)(rest, skipUndoRedo, saveCallback)
      );
    } else {
      window[handlename] = setTimeout(() => {
        saveWizardProposal(proposalValue, stateStep, (newStateStep) =>
          saveWizardProposalCallback(newStateStep)(rest, skipUndoRedo, saveCallback)
        );
      }, 3000);
    }

    setProposal({ ...proposal, ...rest });
  };

  const configText = (x, notmarked, disableTrim) => {
    return helpers.configText({
      x,
      notmarked,
      config,
      prop: proposal,
      user: user,
      vars: {},
      disableTrim,
    });
  };

  useEffect(() => {
    const {
      clientcomplete,
      projectcomplete,
      youcomplete,
      delivcomplete,
      milestonescomplete,
      draftCompleted,
    } = getCompletion({ proposal });
    const allcomplete = delivcomplete && milestonescomplete;
    if (proposal.state === 'published' || proposal.state === 'approved') {
      if (history.length) {
        history.replace('/dashboard');
      } else {
        history.replace('/dashboard');
      }
      return;
    } else {
      if (draftCompleted) {
        return history.push({ pathname: `/d/${params.pid}` });
      }
      if (allcomplete) {
        if (history.length) {
          history.replace('/dashboard');
        } else {
          history.replace('/dashboard');
        }
        return;
      }
    }
    document.body.classList.add('wizard-body');
    history.replace({ pathname: `/w/${params.pid}`, query: {} });

    let cstate = proposal.state || 'client';
    let defaultPType = helpers.getProjectType(user, proposal);

    if (cstate === 'edit') {
      if (!clientcomplete) {
        cstate = 'client';
      } else if (!projectcomplete) {
        cstate = 'project';
      } else if (!youcomplete) {
        cstate = 'you';
      } else if (!delivcomplete) {
        cstate = 'deliverables';
      } else if (!milestonescomplete) {
        cstate = 'milestones';
      }
    }
    setCState(cstate);
    handleStep(cstate);

    setPtype(defaultPType);

    if (!prop?.client && !prop?.project) {
      setIsLoading(true);

      const mrmsclient = _.random(1)
        ? configText('mr client', true)
        : configText('ms client', true);
      const client = { contact: mrmsclient };

      const { otherptype } = user;

      let ptypearr = [];
      if (user.ptype) {
        ptypearr = user.ptype.split(' ');
      }

      if (otherptype) {
        ptypearr.push(otherptype);
        ptypearr = ptypearr.filter((x) => x !== 'other');
      }

      ptypearr = ptypearr.filter((x) => !!x.trim());

      const ptypeSentence = (arr) => {
        arr = arr.map((x) =>
          capitalizeFirstLetter(
            configText(`${x === 'other' || x === 'none' ? `project type ${x}` : x}`, true)
          )
        );
        if (arr.length === 1) {
          return arr[0];
        }
        const last = arr.pop();
        const and = ` ${configText('and word with spaces', true)}`;
        return arr.join(', ') + and + last;
      };

      const newPType = ptypeSentence(ptypearr, prop?.language?.toLowerCase());

      const project = prop.project || {
        name: configText('the new x project template', true).replace('[ptype]', newPType),
        desc: null,
        goals: null,
      };

      const lastType = _.last(defaultPType.split(' ')) || 'other';

      const confStr = (k) => config[`${lastType} ${k}`] || config[`design ${k}`];

      if (!project.desc && project.desc !== '') {
        project.desc = confStr('desc').replace(
          /\[company_name\]/gim,
          (prop.client && prop.client.name) ||
            (prop.client && prop.client.contact) ||
            configText('the company', true)
        );
      }
      if (!project.goals && project.goals !== '') {
        project.goals = confStr('goals', true);
      }

      if (!project.type) {
        project.type = user.ptype;
        project.othertype = user.otherptype;
      }

      save({ client, project }, true, false, () => {
        setIsLoading(false);
      });
    }

    return () => document.body.classList.remove('wizard-body');
  }, []);

  const stateComplete = (s) => {
    const { clientcomplete, projectcomplete, youcomplete, delivcomplete, milestonescomplete } =
      getCompletion({ proposal });

    if (s === 'client' && clientcomplete) {
      return true;
    }

    if (s === 'project' && projectcomplete) {
      return true;
    }

    if (s === 'you' && youcomplete) {
      return true;
    }

    if (s === 'deliverables' && delivcomplete) {
      return true;
    }

    if (s === 'milestones' && milestonescomplete) {
      return true;
    }

    if (s === 'all' && delivcomplete && milestonescomplete) {
      return true;
    }

    return false;
  };

  const handleContinue = () => {
    switch (cState) {
      case 'client':
        return handleStep('project');
      case 'project':
        return handleStep('you');
      case 'you':
        return handleStep('deliverables');
      case 'deliverables':
        return handleStep('milestones');
      case 'milestones':
        return handleStep('edit');
      default:
        handleStep('client');
    }
  };

  const handleStepChange = (value) => {
    switch (value) {
      case 0:
        return handleStep('client');
      case 1:
        return handleStep('project');
      case 2:
        return handleStep('you');
      case 3:
        return handleStep('deliverables');
      case 4:
        return handleStep('milestones');
      default:
        return handleStep('client');
    }
  };

  const renderWizardBody = () => {
    const componentProps = {
      prop: proposal,
      config,
      save,
      user,
      configText,
      handleState,
      saveUser,
      setSaveDisabled,
    };

    switch (step) {
      case 0:
        return (
          <ClientInformation
            ref={clientRef}
            fetchWixProductsAndServicesAndInfo={fetchWixProductsAndServicesAndInfo}
            setWixSiteProperties={setWixSiteProperties}
            {...componentProps}
          />
        );
      case 1:
        return <ProjectInformation ref={projectRef} {...componentProps} />;
      case 2:
        return (
          <YouInformation ref={youRef} wixSiteProperties={wixSiteProperties} {...componentProps} />
        );
      case 3:
        return (
          <DeliverableInformation
            ref={delivsRef}
            wixProductsAndServices={wixProductsAndServices}
            ptype={ptype}
            {...componentProps}
          />
        );
      case 4:
        return <MilesInformation ref={milesRef} {...componentProps} />;
      default:
        return <Loader />;
    }
  };

  const renderPageTitle = () => {
    let pageTitle = '';
    let pageLabel = '';

    if (step === 0) {
      pageTitle = 'Prospect Info';
    } else if (step === 1) {
      pageTitle = 'Project Info';
    } else if (step === 2) {
      pageTitle = 'Why Work With you?';
      pageLabel =
        "If your client is comparing your offer with others, it's a good idea to demonstrate your strengths.";
    } else if (step === 3) {
      pageTitle = 'Deliverables';
      pageLabel = 'Add the assets you are required to produce, and the price of the offer.';
    } else if (step === 4) {
      pageTitle = 'Milestones';
      pageLabel =
        "It's a good idea to add milestones that will set a timeline for the project and payments.";
    }

    return (
      <PageHeader
        pageTitle={
          <>
            <span>{pageTitle}</span>
            {wixSiteProperties?.name && (
              <span className="page-title-info">
                {' '}
                {wixSiteProperties.name}{' '}
                {wixSiteProperties.domain ? '(' + wixSiteProperties.domain + ')' : ''}{' '}
              </span>
            )}
          </>
        }
        pageLabel={pageLabel}
        showButton={false}
      />
    );
  };

  const handleReset = () => {
    switch (step) {
      case 0:
        return clientRef?.current?.resetChosen();
      case 1:
        return projectRef?.current?.resetChosen();
      case 2:
        return youRef?.current?.resetChosen();
      case 3:
        return delivsRef?.current?.resetChosen();
      case 4:
        return milesRef?.current?.resetChosen();
      default:
    }
  };

  const allComplete = stateComplete('all');

  if (isLoading || loadingUser) {
    return <Loader />;
  }

  return (
    <Layout className="wizard-main">
      <Layout.Header className="wizard-header">
        <ProposalHeaderBar
          mode="wizard"
          buttons={
            <div className="undo-redo-buttons">
              <Button
                size="large"
                className="wizard-reset"
                icon={<ResetIcon />}
                onClick={handleReset}
              />

              <Button
                size="large"
                className="wizard-undo"
                icon={<UndoIcon />}
                onClick={() =>
                  undoableWizardStack[step]?.past?.length &&
                  handleWizardUndoRedo('UNDO', null, undoableWizardStack, step)
                }
                style={undoableWizardStack[step]?.past?.length ? {} : { opacity: '0.2' }}
              />

              <Button
                size="large"
                className="wizard-redo"
                icon={<RedoIcon />}
                onClick={() =>
                  undoableWizardStack[step]?.future?.length &&
                  handleWizardUndoRedo('REDO', null, undoableWizardStack, step)
                }
                style={undoableWizardStack[step]?.future?.length ? {} : { opacity: '0.2' }}
              />
            </div>
          }
        />
      </Layout.Header>
      {renderPageTitle()}
      <Content className="wizard-content">
        <Layout className="site-layout-background">
          <Content>
            <Row
              className={`wizard-content-container ${
                proposal?.language?.toLowerCase() === 'hebrew' ||
                proposal?.language?.toLowerCase() === 'arabic'
                  ? 'wizard-language-rtl'
                  : ''
              }`}>
              <DraftContext.Provider
                value={{
                  fetchMoreWixProductsAndServices,
                  fetchMoreWixProductsAndServicesLoading,
                }}>
                {renderWizardBody()}
              </DraftContext.Provider>
            </Row>

            <Col className="wizard-content-options">
              <Row className="more-buttons">
                <Button
                  type="primary"
                  size="large"
                  className="wizard-reset"
                  text="CLEAR"
                  icon={<ResetIcon />}
                  onClick={handleReset}
                />
                <div className="undo-redo">
                  <Tooltip title="Undo">
                    <UndoIcon
                      style={undoableWizardStack[step]?.past?.length ? {} : { opacity: '0.2' }}
                      onClick={() =>
                        undoableWizardStack[step]?.past?.length &&
                        handleWizardUndoRedo('UNDO', null, undoableWizardStack, step)
                      }
                    />
                  </Tooltip>
                  <Tooltip title="Redo">
                    <RedoIcon
                      style={undoableWizardStack[step]?.future?.length ? {} : { opacity: '0.2' }}
                      onClick={() =>
                        undoableWizardStack[step]?.future?.length &&
                        handleWizardUndoRedo('REDO', null, undoableWizardStack, step)
                      }
                    />
                  </Tooltip>
                </div>
              </Row>
              <Row className={`main-buttons step-${step}`}>
                <Button
                  type="default"
                  size="large"
                  className="wizard-quit"
                  text={step > 0 ? 'BACK' : 'QUIT'}
                  onClick={() =>
                    step > 0 ? handleStepChange(step - 1) : history.push('/dashboard')
                  }
                />

                <Button
                  type="primary"
                  size="large"
                  loading={saveDisabled}
                  className={`wizard-continue ${
                    (!stateComplete(cState) || saveDisabled) && 'wizard-continue-disabled'
                  }`}
                  text={
                    allComplete && step === 4 ? (
                      <span className="create-proposal">CREATE PROPOSAL</span>
                    ) : (
                      'CONTINUE'
                    )
                  }
                  icon={
                    allComplete && step === 4 ? (
                      <div className="wizard-create-button">
                        <CheckOutlined />
                      </div>
                    ) : (
                      <ArrowRight />
                    )
                  }
                  onClick={() => handleContinue()}
                />
              </Row>
            </Col>
          </Content>

          <Sidebar onChange={handleStepChange} current={step} />
        </Layout>
      </Content>
    </Layout>
  );
};

Wizard.defaultProps = {
  fetchMoreWixProductsAndServicesLoading: false,
};

Wizard.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  config: PropTypes.instanceOf(Object).isRequired,
  prop: PropTypes.instanceOf(Object).isRequired,
  wixProductsAndServices: PropTypes.instanceOf(Object).isRequired,
  fetchWixProductsAndServicesAndInfo: PropTypes.func.isRequired,
  setWixSiteProperties: PropTypes.func.isRequired,
  fetchMoreWixProductsAndServicesLoading: PropTypes.bool,
  fetchMoreWixProductsAndServices: PropTypes.func.isRequired,
};

export default Wizard;
