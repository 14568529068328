import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 8.5L7 13.5M7 13.5L2 8.5M7 13.5L7 1"
      stroke="#05034D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect y="16" width="14" height="2" rx="1" fill="#05034D" />
  </svg>
);

const DownloadFlatIcon = (props) => <Icon component={svg} {...props} />;

export default DownloadFlatIcon;
