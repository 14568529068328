import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg className="line-height-icon" width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="7" y="1" width="11" height="2" rx="1" fill="#9E9EB7" />
    <rect x="4" y="1" width="12" height="2" rx="1" transform="rotate(90 4 1)" fill="#9E9EB7" />
    <rect x="7" y="6" width="11" height="2" rx="1" fill="#9E9EB7" />
    <rect x="7" y="11" width="11" height="2" rx="1" fill="#9E9EB7" />
    <path
      className="line-spacing-path"
      d="M5 11L3 13L1 11"
      stroke="#9E9EB7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="line-spacing-path"
      d="M1 3L3 1L5 3"
      stroke="#9E9EB7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const LineSpacingIcon = (props) => <Icon component={svg} {...props} />;

export default LineSpacingIcon;
