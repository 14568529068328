import {
  fontListEnglish,
  fontListHebrew,
  fontWeights,
  fontSizes,
} from 'pages/Proposal/constants/constants';
import { loadProposalFont } from 'helpers/proposal';
import { fontLoadedVar } from 'graphql/cache';

const fontDefaultFamilies = {};
const fontDefaultWeights = {};
const fontDefaultSizes = {};
let fontLoaded = [];

fontListEnglish.forEach((font) => {
  fontDefaultFamilies[font.family] = {
    style: {
      fontFamily: font.family,
    },
  };
});

fontListHebrew.forEach((font) => {
  fontDefaultFamilies[font.family] = {
    style: {
      fontFamily: font.family,
    },
  };
});

fontWeights.forEach((weight) => {
  fontDefaultWeights[weight] = {
    style: {
      fontWeight: weight,
    },
  };
});

fontSizes.forEach((size) => {
  fontDefaultSizes[size] = {
    style: {
      fontSize: size,
    },
  };
});

const blockStyle = ({ language }) => {
  return {
    inlineStyles: {
      ...fontDefaultFamilies,
      ...fontDefaultWeights,
      ...fontDefaultSizes,
    },
    blockStyleFn: (block) => {
      const blockType = block.getType();
      if (
        blockType?.match(/editor-align-/) ||
        blockType?.match(/editor-direction-/) ||
        blockType?.match(/line-height__/) ||
        blockType?.match(/intent-left-/) ||
        blockType?.match(/intent-right-/)
      ) {
        return {
          element: 'p',
          attributes: {
            class: blockType,
          },
        };
      }
      if (block.getData().get('className')) {
        return {
          attributes: {
            class: `RichEditor-${block.getData().get('className')}`,
          },
        };
      }
    },
    inlineStyleFn: (styles) => {
      const fontFamilies = Object.keys(fontDefaultFamilies || {});
      const fontToBeLoad = [];
      const obj = {
        element: 'span',
        style: {},
      };

      // find font, color, background etc
      styles.forEach((value) => {
        const styleValue = value?.toString();

        // find font
        if (!fontLoadedVar()) {
          if (fontFamilies.includes(styleValue) && !fontLoaded.includes(styleValue)) {
            fontToBeLoad.push(styleValue);
            fontLoaded.push(styleValue);
          }
        }

        // find color
        if (styleValue?.startsWith('rgba')) {
          obj.style.color = styleValue;
        }
        // find background color
        else if (styleValue?.startsWith('bg-')) {
          obj.style.backgroundColor = styleValue.substring(3);
        }
      });

      if (fontToBeLoad.length && !fontLoadedVar()) {
        const prelist = [...fontListEnglish, ...fontListHebrew].filter((fontList) =>
          fontToBeLoad.includes(fontList.family)
        );

        loadProposalFont(prelist, language);
      }

      return obj;
    },
    entityStyleFn: (entity) => {
      const entityType = entity.get('type').toLowerCase();
      if (entityType === 'image') {
        return {};
      }
    },
  };
};

export default blockStyle;
