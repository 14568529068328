import { InMemoryCache, makeVar } from '@apollo/client';

import { findAllProposalStatus } from 'helpers/proposal';

// Initializes to true if localStorage includes a 'token' key,
// false otherwise
export const isLoggedInVar = makeVar(!!localStorage.getItem('prospero-token'));
export const userVar = makeVar(null);
export const fromAppVar = makeVar(null);
export const appSessionTokenVar = makeVar(null);
export const mondayBoardIdVar = makeVar(null);
export const userProfileVar = makeVar(null);
export const proposalsVar = makeVar([]);
export const proposalsStatsVar = makeVar({ approvedCount: 0, acceptanceRate: 0 });
export const proposalsCountVar = makeVar(0);
export const proposalsImportVar = makeVar([]);
export const proposalsClientVar = makeVar([]);
export const teamMembersVar = makeVar([]);
export const adminVar = makeVar(null);
export const adminProfileVar = makeVar(null);
export const fontLoadedVar = makeVar(false);
export const templatesVar = makeVar([]);
export const templatesCountVar = makeVar(0);
export const sectionsVar = makeVar([]);
export const sectionsCountVar = makeVar(0);
export const featureFlagsVar = makeVar(null);

export const cache = new InMemoryCache({
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn: {
          read() {
            return isLoggedInVar();
          },
        },
        user: {
          read() {
            return userVar();
          },
        },
        fetchUser: {
          keyArgs: ['_id'],
          merge(existing = {}, incoming) {
            let data = {};
            if (incoming) {
              data = { ...existing, ...incoming };
            } else {
              data = existing;
            }
            return data;
          },
        },
        userProfile: {
          read() {
            return userProfileVar();
          },
        },
        proposals: {
          read() {
            return proposalsVar([]);
          },
        },
        fetchActivities: {
          keyFields: ['_id'],
        },
        fetchProposals: {
          keyArgs: ['_id'],
          merge(
            existing = {
              proposalsCount: 0,
              proposalsStats: [{ approvedCount: 0, notDraftCount: 0 }],
              proposals: [],
            },
            incoming,
            { args }
          ) {
            let data = undefined;
            if (args?.limit) {
              // limit check added to know if the query is from dashboard
              if (args.offset === 0) {
                if (existing.proposals.length === 0 || incoming.proposals.length) {
                  data = incoming;
                } else {
                  data = existing;
                }

                let proposalsStatsKey = '';
                incoming
                  ? Object.keys(incoming).forEach((key) => {
                      /proposalsStats/.test(key) ? (proposalsStatsKey = key) : '';
                    })
                  : null;
                if (proposalsStatsKey && incoming[proposalsStatsKey]?.length)
                  proposalsStatsVar(findAllProposalStatus(incoming[proposalsStatsKey][0]));

                proposalsCountVar(incoming?.proposalsCount);
              } else {
                data = {
                  proposalsCount: incoming.proposalsCount,
                  proposalsStats: incoming.proposalsStats,
                  proposals: [...existing.proposals, ...incoming.proposals],
                };
              }
              proposalsVar(data?.proposals);
            } else {
              data = { ...existing, ...incoming };
            }

            return data;
          },
        },
      },
    },
  },
});

export const getUserId = () =>
  (adminVar() && window.localStorage.getItem('proxy-user-id')) ||
  window.localStorage.getItem('prospero-user-id');
