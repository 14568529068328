import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="48" height="48" viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="22" r="14" stroke="#C7BCE4" strokeWidth="2" />
    <path
      d="M24.029 29C23.1006 29 22.2753 28.8833 21.5532 28.65C20.8311 28.4167 20.1541 28.0213 19.5222 27.4639C19.1741 27.1528 19 26.8093 19 26.4333C19 26.1352 19.1096 25.8759 19.3288 25.6556C19.548 25.4222 19.8124 25.3056 20.1219 25.3056C20.3669 25.3056 20.5861 25.3833 20.7795 25.5389C21.2953 25.9667 21.7982 26.2778 22.2882 26.4722C22.7782 26.6667 23.3585 26.7639 24.029 26.7639C24.7511 26.7639 25.3701 26.6019 25.8859 26.2778C26.4146 25.9537 26.6789 25.5519 26.6789 25.0722C26.6789 24.4889 26.421 24.0352 25.9052 23.7111C25.3894 23.3741 24.5706 23.1213 23.4487 22.9528C20.6248 22.538 19.2128 21.2157 19.2128 18.9861C19.2128 18.1694 19.4255 17.463 19.8511 16.8667C20.2766 16.2574 20.8569 15.7972 21.5919 15.4861C22.3269 15.162 23.1457 15 24.0484 15C24.8607 15 25.6215 15.1231 26.3308 15.3694C27.0529 15.6157 27.6525 15.9398 28.1296 16.3417C28.5035 16.6398 28.6905 16.9833 28.6905 17.3722C28.6905 17.6704 28.5809 17.9361 28.3617 18.1694C28.1425 18.3898 27.8846 18.5 27.588 18.5C27.3946 18.5 27.2205 18.4417 27.0658 18.325C26.7305 18.0528 26.2598 17.8065 25.6538 17.5861C25.0606 17.3528 24.5255 17.2361 24.0484 17.2361C23.236 17.2361 22.6041 17.3917 22.1528 17.7028C21.7144 18.0009 21.4952 18.3963 21.4952 18.8889C21.4952 19.4463 21.7208 19.8676 22.1721 20.1528C22.6364 20.438 23.3585 20.6713 24.3385 20.8528C25.4475 21.0472 26.3308 21.3 26.9884 21.6111C27.6589 21.9093 28.1618 22.3241 28.4971 22.8556C28.8324 23.3741 29 24.0676 29 24.9361C29 25.7528 28.7679 26.4722 28.3037 27.0944C27.8524 27.7037 27.2463 28.1769 26.4855 28.5139C25.7247 28.838 24.9059 29 24.029 29Z"
      fill="#7876C1"
    />
    <path
      d="M23.1992 13.8C23.1992 13.3582 23.5574 13 23.9992 13C24.441 13 24.7992 13.3582 24.7992 13.8V15.963H23.1992V13.8Z"
      fill="#7876C1"
    />
    <path
      d="M24.8008 30.163C24.8008 30.6048 24.4426 30.963 24.0008 30.963C23.559 30.963 23.2008 30.6048 23.2008 30.163L23.2008 28L24.8008 28L24.8008 30.163Z"
      fill="#7876C1"
    />
  </svg>
);

const PriceTableIcon = (props) => <Icon component={svg} {...props} />;

export default PriceTableIcon;
