const PriceSeparator = [
  {
    text: '. (DOT)',
    value: '.',
  },
  {
    text: ', (COMMA)',
    value: ',',
  },
  {
    text: 'SPACE',
    value: 'space',
  },
  {
    text: 'NONE',
    value: 'none',
  },
];

export default PriceSeparator;
