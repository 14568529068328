import React, { useEffect } from 'react';
import { message } from 'antd';
import { useLazyQuery } from '@apollo/client';

import { CONNECT_QUICKBOOKS } from 'graphql/queries/integrationQueries';
import { getUserId } from 'graphql/cache';
import Loader from 'components/Loader/Loader';

const QuickbooksWindow = ({ history }) => {
  window.close(); // only works if window is opened via script (window.open)

  const [connectQuickbook] = useLazyQuery(CONNECT_QUICKBOOKS, {
    variables: { url: window.location.href },
    skip: !getUserId() || !window.location.href,
    fetchPolicy: 'network-only',
    onCompleted: () => {
      sessionStorage.removeItem('quickBookConnected');
      message.success('QuickBooks Connected Successfully');
      history.push('/integration?quickbooks=connected');
    },
    onError: () => {
      sessionStorage.removeItem('quickBookConnected');
      message.error('QuickBooks Connect Failed');
      setTimeout(() => history.push('/integration?quickbooks=failed'), 1000);
    },
  });

  useEffect(() => {
    if (sessionStorage.getItem('quickBookConnected') !== 'true') {
      sessionStorage.setItem('quickBookConnected', 'true');
      connectQuickbook();
    }
  }, [connectQuickbook]);

  useEffect(() => {
    return sessionStorage.removeItem('quickBookConnected');
  });

  return <Loader />;
};

export default QuickbooksWindow;
