import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

import CloseIcon from 'components/Icons/CloseIcon';

import './UserSelectionModal.scss';
import { ChatwayBrandIcon } from 'components/Icons';
import images from 'constants/images';

const UserSelectionModal = ({ visible, onNewUser, onExistingUser, onCancel }) => {
  return (
    <>
      {visible && (
        <Modal
          visible={true}
          centered
          footer={null}
          closeIcon={<CloseIcon className="close-icon" />}
          className="user-selection-modal"
          width="762px"
          zIndex={1009}
          onCancel={onCancel}>
          <div className="modal-container">
            <ChatwayBrandIcon className="chatway-icon" />
            <h1>Connect To Chatway</h1>
            <div className="color-divider" />
            <p className="sub-heading">
              Improve live chat communication and unlock customer satisfaction with Chatway
            </p>
            <div className="type-selector">
              <div className="box step" onClick={onNewUser}>
                <h2>New User</h2>
                <img src={images.NEW_USER} alt="new-user" />
                <p className="explainer">
                  <b>Create a new</b> Chatway account for free
                </p>
              </div>
              <div className="box step" onClick={onExistingUser}>
                <h2>Existing User</h2>
                <img src={images.EXISTING_USER} alt="new-user" />
                <p className="explainer">
                  I <b>already have</b> a Chatway account
                </p>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

UserSelectionModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onNewUser: PropTypes.func,
  OnExistingUser: PropTypes.func,
  createNewProposal: PropTypes.func.isRequired,
};

export default UserSelectionModal;
