import React, { useState, useMemo, useContext, useEffect, useRef } from 'react';
import _ from 'lodash';
import { Col, Row, Slider, Radio, Button } from 'antd';
import { GALLERY_CONFIG } from 'constants/index';
import ChromeBox from 'components/ChromeBox';

const SettingsContext = React.createContext(null);

export function useSettings() {
  const context = useContext(SettingsContext);
  if (!context) throw new Error('useSettings must be used within a SettingsContext');
  return context;
}

export default function Settings({
  isOpen,
  images,
  config,
  onConfigChange,
  children,
  onLayoutChange,
  setOpenSettings,
}) {
  const [configLocal, setConfigLocal] = useState({ ...GALLERY_CONFIG });

  const settings = useMemo(() => ({ ...configLocal }), [configLocal]);

  // prevent multiple change in short span of time
  const debounceConfigChange = useRef(
    _.debounce((newConfig) => {
      onConfigChange(newConfig);
    }, 300)
  ).current;

  useEffect(() => {
    if (config) {
      setConfigLocal((p) => ({ ...p, ...config }));
    }
  }, [config]);

  const onChange = (key, value) => {
    setConfigLocal((p) => {
      const newConfig = { ...p, [key]: value };

      debounceConfigChange(newConfig);

      return newConfig;
    });
  };

  const onRandomize = () => {
    let array = images;
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }

    onLayoutChange(array);
  };

  const onReset = () => {
    setConfigLocal({ ...GALLERY_CONFIG });
    onConfigChange({ ...GALLERY_CONFIG });
  };

  const inputOptions = {
    config: configLocal,
    onChange,
  };

  return (
    <SettingsContext.Provider value={settings}>
      {children}

      <div className={`gallery-settings ${isOpen ? 'active' : 'in-active'}`}>
        <div className="settings-body">
          <div>
            <Radio.Group
              options={[
                { label: 'Columns', value: 'columns' },
                { label: 'Rows', value: 'rows' },
                { label: 'Masonry', value: 'masonry' },
              ]}
              value={configLocal.layout}
              onChange={(e) => onChange('layout', e.target.value)}
              optionType="button"
              buttonStyle="solid"
              className="layout-radio"
            />
          </div>

          <div className="group">
            <label className="strong">Layout</label>
            <Row gutter={[24, 24]}>
              <Col xs={24} xl={12}>
                {configLocal.layout === 'rows' ? (
                  <SliderControl
                    name="Row height"
                    min={50}
                    max={500}
                    // step={5}
                    keyName="rowHeight"
                    {...inputOptions}
                  />
                ) : (
                  <SliderControl
                    name="Columns"
                    min={1}
                    max={10}
                    keyName="columns"
                    {...inputOptions}
                  />
                )}
              </Col>

              <Col xs={24} xl={12}>
                <SliderControl name="Spacing" keyName="spacing" {...inputOptions} />
              </Col>

              <Col xs={24} xl={12}>
                <SliderControl name="Roundness" keyName="borderRadius" {...inputOptions} />
              </Col>

              <Col xs={24} xl={12}>
                <SliderControl name="Padding" keyName="padding" {...inputOptions} />
              </Col>
            </Row>
          </div>

          <div className="group shadow">
            <label className="strong">Shadow</label>
            <div className="tools">
              <SliderControl name="Blur" keyName="showdowBlur" {...inputOptions} />
              <SliderControl name="Spread" keyName="showdowSpread" {...inputOptions} />

              <div className="  gallery-color-picker">
                <label>Color</label>
                <ChromeBox
                  color={configLocal.shadowColor}
                  onChange={(value) => onChange('shadowColor', value)}
                  disableAlpha={false}
                />
              </div>
            </div>
          </div>

          <div className="group ">
            <Col xs={24} className="gallery-color-picker">
              <label>Background color</label>
              <ChromeBox
                color={configLocal.backgroundColor}
                onChange={(value) => onChange('backgroundColor', value)}
                disableAlpha={true}
              />
            </Col>
          </div>

          <div className="more-btns">
            <Button onClick={onRandomize} size="small" className="shuffle">
              Shuffle
            </Button>
            <Button onClick={onReset} size="small" className="reset">
              Reset
            </Button>
          </div>
        </div>

        <div className="settings-footer">
          <Button className="button close" type="primary" onClick={() => setOpenSettings(false)}>
            Close
          </Button>
        </div>
      </div>
    </SettingsContext.Provider>
  );
}

const SliderControl = ({
  name,
  keyName,
  onChange,
  config,
  min = 0,
  max = 50,
  step = 1,
  disabled,
}) => {
  return (
    <div className="tool">
      <label>{name}</label>
      <Slider
        min={min}
        max={max}
        step={step}
        disabled={disabled}
        value={config[keyName]}
        onChange={(value) => onChange(keyName, value)}
      />
    </div>
  );
};
