import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg viewBox="0 -6 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="11" y="11" width="12" height="6" rx="1" fill="#C7BCE4" />
    <rect x="11" y="19" width="12" height="6" rx="1" fill="#C7BCE4" />
    <rect x="11" y="27" width="12" height="6" rx="1" fill="#C7BCE4" />
    <rect x="25" y="11" width="12" height="6" rx="1" fill="#7876C1" />
    <rect x="25" y="19" width="12" height="6" rx="1" fill="#C7BCE4" />
    <rect x="25" y="27" width="12" height="6" rx="1" fill="#C7BCE4" />
  </svg>
);

const AddFromLibraryIcon = (props) => <Icon component={svg} {...props} />;

export default AddFromLibraryIcon;
