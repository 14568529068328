import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ElementsView from './ElementsView/ElementsView';
import VariablesView from './VariablesView/VariablesView';

const ElementsMenu = ({
  handleToolbarElements,
  dragStart,
  dragEnd,
  showAttachmentModal,
  saveProposal,
  openVariablesList,
  setOpenVariablesList,
  variables,
  scrollingTo,
  setScrollingTo,
  language,
  isSection,
  wixEditor,
  templateWixEditor,
  wixPricing,
}) => {
  const [showVariables, setShowVariables] = useState(false);

  useEffect(() => {
    setShowVariables(openVariablesList);
  }, [openVariablesList]);

  return (
    <>
      <ElementsView
        handleToolbarElements={handleToolbarElements}
        dragStart={dragStart}
        dragEnd={dragEnd}
        showAttachmentModal={showAttachmentModal}
        saveProposal={saveProposal}
        openVariablesList={openVariablesList}
        setOpenVariablesList={setOpenVariablesList}
        showVariables={showVariables}
        setScrollingTo={setScrollingTo}
        setShowVariables={setShowVariables}
        wixEditor={wixEditor}
        templateWixEditor={templateWixEditor}
      />
      <VariablesView
        saveProposal={saveProposal}
        variables={variables}
        openVariablesList={openVariablesList}
        showVariables={showVariables}
        setOpenVariablesList={setOpenVariablesList}
        scrollingTo={scrollingTo}
        setShowVariables={setShowVariables}
        setScrollingTo={setScrollingTo}
        language={language}
        isSection={isSection}
        wixPricing={wixPricing}
        wixEditor={wixEditor}
        templateWixEditor={templateWixEditor}
      />
    </>
  );
};

ElementsMenu.propTypes = {
  handleToolbarElements: PropTypes.func.isRequired,
  dragStart: PropTypes.func.isRequired,
  dragEnd: PropTypes.func.isRequired,
  showAttachmentModal: PropTypes.func.isRequired,
  wixEditor: PropTypes.bool,
  templateWixEditor: PropTypes.bool,
  wixPricing: PropTypes.object,
};

export default ElementsMenu;
