import React, { useState, useCallback, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { CSVLink } from 'react-csv';
import moment from 'moment';

import { FETCH_ADMIN_WIX_USERS_REV } from 'graphql/queries/adminQueries';
import { WixRevTable, WixRevHeader, WixGeneralStats } from './components';

const headers = [
  { label: 'App ID', key: 'appId' },
  { label: 'Instance ID', key: 'instanceId' },
  { label: 'Transaction Date', key: 'transactionDate' },
  { label: 'Product Name', key: 'productName' },
  { label: 'Gross Revenue, Without Sales Tax (USD)', key: 'totalPayment' },
  { label: 'Net App Revenue (USD)', key: 'rev' },
  { label: 'How did you calculate your Net App Revenue', key: 'calculateYourNetAppRevenue' },
  { label: 'Revenue Share Calculation', key: 'revenueShareCalculation' },
  { label: 'Owed (USD)', key: 'owed' },
];

const WixRev = () => {
  const initialFilters = {
    endDate: moment().subtract(1, 'months').endOf('month').toISOString(),
    startDate: moment().subtract(1, 'months').startOf('month').toISOString(),
  };

  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [generalStats, setGeneralStats] = useState([]);

  const downloadRef = useRef(null);

  const updateAdminUsers = (adminUsers) => {
    setUsers(
      adminUsers.map((user) => ({
        signUpDate: moment(user.signUpDate).format('DD/MM/YYYY'),
        email: user?.emails?.map((email) => email.address).join() ?? '',
        uid: user._id,
        appId: user.appId,
        instanceId: user.instanceId,
        totalPayment: user.paymentInfo.totalPayment || 0,
        rev: user.paymentInfo.rev || 0,
        owed: user.paymentInfo.owed,
        firstPaymentDate: user.paymentInfo.firstPaymentDate
          ? moment(user.paymentInfo.firstPaymentDate).format('DD/MM/YYYY')
          : '-',
        paying: user.paymentInfo.paying,
        productName: user.paymentInfo.productName,
        transactionDate: user.paymentInfo.transactionDate ? moment(user.paymentInfo.transactionDate).format('DD-MM-YYYY') : '-',
        calculateYourNetAppRevenue: 'Gross Revenue * 0.97',
        revenueShareCalculation: '20% of net app revenue'
      }))
    );
  };

  const {
    loading: isFetchingAdminWixUsersRev,
    refetch,
    fetchMore,
  } = useQuery(FETCH_ADMIN_WIX_USERS_REV, {
    variables: {
      userCondition: {
        queryDates: {
          startDate: filters.startDate,
          endDate: filters.endDate,
        },
      }
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const {
        fetchAdminWixUsersRev: { wixUsers, generalStats },
      } = res;
      setGeneralStats([{ ...generalStats, key: 'stats' }]);
      updateAdminUsers(wixUsers);
    },
  });

  const handleFilters = useCallback(
    (type, value) => {
      if (type === 'date') {
        [filters.startDate, filters.endDate] = value;
      } else {
        filters[type] = value;
      }
      setFilters({ ...filters });
    },
    [setFilters, filters]
  );

  const handleReset = () => {
    setFilters({ ...initialFilters });
    refetch();
  };

  const downloadCSV = () => {
    setTimeout(() => {
      downloadRef.current?.link?.click();
    });
  };

  return (
    <>
      <WixGeneralStats generalStats={generalStats} startDate={moment(filters?.startDate).format('DD/MM/YYYY')} endDate={moment(filters?.endDate).format('DD/MM/YYYY')} />
      <div className="admin-table-wrapper">
        <WixRevHeader
          refetch={refetch}
          filters={filters}
          handleFilters={handleFilters}
          handleReset={handleReset}
          downloadCSV={downloadCSV}
        />
        <WixRevTable
          isFetchingAdminUsers={isFetchingAdminWixUsersRev}
          users={users}
          usersCount={generalStats[0]?.usersCount || 0}
          fetchMore={fetchMore}
          filters={filters}
          updateAdminUsers={updateAdminUsers}
        />
      </div>
      <CSVLink ref={downloadRef} data={users.filter(user => user.totalPayment)} headers={headers} filename={`Prospero-Wix-${moment(filters?.startDate).format('DD-MM-YYYY')}_${moment(filters?.endDate).format('DD-MM-YYYY')}.csv`} />
    </>
  );
};

export default WixRev;
