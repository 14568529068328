/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="48" height="40" viewBox="0 0 48 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="7" y="15" width="34" height="3" rx="1" fill="#C7BCE4" />
    <rect x="7" y="19" width="34" height="3" rx="1" fill="#C7BCE4" />
  </svg>
);

const TitleNoTitle = (props) => <Icon component={svg} {...props} />;

export default TitleNoTitle;
