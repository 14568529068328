import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.0101 6.31793L12.1816 3.4895L3.69636 11.9748L6.52479 14.8032L15.0101 6.31793Z"
      fill="#005AFF"
    />
    <path
      d="M2.98828 12.6819L5.81671 15.5103L5.3237 16.0033C5.1841 16.1429 5.0063 16.2381 4.81271 16.2768L3.04494 16.6304C2.34522 16.7703 1.7283 16.1534 1.86824 15.4537L2.2218 13.6859C2.26051 13.4923 2.35567 13.3145 2.49527 13.1749L2.98828 12.6819Z"
      fill="#005AFF"
    />
    <path
      d="M14.3027 1.36824C14.6932 0.977715 15.3263 0.977715 15.7169 1.36824L17.1311 2.78245C17.5216 3.17298 17.5216 3.80614 17.1311 4.19667L15.7169 5.61088L12.8884 2.78245L14.3027 1.36824Z"
      fill="#005AFF"
    />
  </svg>
);

const PencilIcon = (props) => <Icon component={svg} {...props} />;

export default PencilIcon;
