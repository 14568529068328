import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Badge, message, Empty } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { getFilteredCoversOrMedia } from 'helpers/contentlibrary';
import { GET_ALL_COVERS_LIBRARIES } from 'graphql/queries/contentLibraryQueries';
import {
  UPDATE_CONTENT_LIBRARY,
  CONTENT_LIBRARY_COVERS_DELETE,
} from 'graphql/mutations/contentLibraryMutations';
import { ContentLoader } from '../ContentLoader/ContentLoader';

import ConfirmModal from 'components/ConfirmModal';
import ContentLibraryTree from '../ContentLibraryTree/ContentLibraryTree';
import CoverOrMediaCard from '../CoverOrMediaCard/CoverOrMediaCard';

const CoverTab = forwardRef(
  ({ filterItems, user, searchValue, onFolderChange, sortValue, isLocked }, ref) => {
    const [covers, setCovers] = useState([]);
    const [selectedCoverId, setSelectedCoverId] = useState('');
    const [selectedFolderKey, changeSelectedFolderKey] = useState('');
    const [confirmModal, showConfirmModal] = useState(false);
    const [selectedCoverEditValue, setSelectedCoverEditValue] = useState('');
    const [selectedCoverStarred, setSelectedCoverStarred] = useState('');

    const { key: selectedCoverType } = filterItems.find((item) => item.checked) || 'top';

    const { loading: isFetchingCovers, refetch: refetchCovers } = useQuery(
      GET_ALL_COVERS_LIBRARIES,
      {
        notifyOnNetworkStatusChange: true,
        variables: {
          type: selectedCoverType,
        },
        onCompleted: ({ fetchContentLibraryCoversItems }) => {
          setCovers(fetchContentLibraryCoversItems.slice());
        },
      }
    );

    const [updateContentItem] = useMutation(UPDATE_CONTENT_LIBRARY, {
      async onCompleted() {
        setCovers(
          covers.map((cover) =>
            cover._id === selectedCoverId
              ? selectedCoverStarred !== ''
                ? { ...cover, starred: [selectedCoverStarred.toString()] }
                : { ...cover, name: selectedCoverEditValue ? selectedCoverEditValue : cover.name }
              : cover
          )
        );
        setSelectedCoverId('');
        setSelectedCoverEditValue('');
        setSelectedCoverStarred('');
      },
    });

    const [deleteCover] = useMutation(CONTENT_LIBRARY_COVERS_DELETE, {
      onCompleted() {
        let tempCovers = covers.filter((cover) => cover._id !== selectedCoverId);
        setCovers(tempCovers);
        setSelectedCoverId('');
        message.success('Cover deleted successfully');
        showConfirmModal(false);
      },
      onError() {
        message.error('Cover deletion failed');
        showConfirmModal(false);
      },
    });

    useImperativeHandle(ref, () => ({
      async reloadItems() {
        await refetchCovers();
      },
    }));

    const onSelect = (selectedKeys) => {
      changeSelectedFolderKey(selectedKeys[0]);
      onFolderChange(selectedKeys[0]);
    };

    useEffect(() => {
      refetchCovers({
        type: selectedCoverType,
        key: selectedFolderKey,
      });
    }, [refetchCovers, selectedFolderKey, selectedCoverType]);

    const filteredCovers = getFilteredCoversOrMedia({
      covers,
      filterItems,
      sortValue,
      selectedCoverType,
      searchValue,
    });

    localStorage.setItem('selectedFolderKey', selectedFolderKey);

    const { starred: starredItems, all: allItems } = filteredCovers;
    const isCoversEmpty = !(filteredCovers.starred.length || filteredCovers.all.length);

    const starOrUnstar = ({ starred, coverId }) => {
      updateContentItem({
        variables: {
          id: coverId,
          type: 'covers',
          item: { starred },
        },
      });
    };

    const saveCoverName = ({ coverId }) => {
      updateContentItem({
        variables: {
          id: coverId,
          type: 'covers',
          item: { name: selectedCoverEditValue },
        },
      });
    };

    const confirmDeleteCover = () => {
      deleteCover({
        variables: {
          _id: selectedCoverId,
          folderKey: selectedFolderKey,
        },
      });
    };

    const onClickMenuItem = async ({ actionType, _id: coverId, starred, name, title, index }) => {
      setSelectedCoverId(coverId);
      if (actionType === 'delete') {
        showConfirmModal(true);
      } else if (actionType === 'rename') {
        setSelectedCoverEditValue(name || title);
      } else if (actionType === 'save') {
        saveCoverName({ coverId });
      } else if (actionType === 'starOrUnstar') {
        setSelectedCoverStarred(starred);
        starOrUnstar({ starred, coverId });
      } else {
        message.warning('Coming soon 😊');
      }
    };

    return (
      <div className="content-library-item">
        <ContentLibraryTree
          onSelect={onSelect}
          type="covers"
          onUpdate={() =>
            refetchCovers({
              type: selectedCoverType,
              key: selectedFolderKey,
            })
          }
          userId={user && user._id}
          userRole={user?.roles && user?.roles[0]}
          isLocked={isLocked}
        />

        <div className="split-d2">
          {isFetchingCovers ? (
            <ContentLoader />
          ) : (
            <>
              {starredItems.length ? (
                <div className="stared-header">
                  MY STARRED
                  {selectedCoverType === 'top-cover' ? ' TOP COVERS' : ' FULL PAGE COVERS'}
                  <Badge className="star-badge" count={starredItems.length} />
                </div>
              ) : null}

              <div
                className={`scroll-wrapper ${
                  selectedCoverType === 'full-page' && 'full-page-covers'
                }`}>
                <React.Fragment>
                  {isCoversEmpty && (
                    <div className="item-row empty-wrapper">
                      <Empty
                        className="empty-view"
                        description="Looks like you have no cover files yet"
                      />
                    </div>
                  )}
                  <div className="item-row covers-card">
                    {starredItems.map((item, itemIndex) => {
                      return (
                        <CoverOrMediaCard
                          onDragStart={(ev) => {
                            ev.dataTransfer.setData('data', JSON.stringify(item));
                          }}
                          type="covers"
                          item={item}
                          key={item._id}
                          index={itemIndex}
                          onClickMenuItem={onClickMenuItem}
                          userId={user && user._id}
                          userRole={user?.roles && user?.roles[0]}
                          itemEditable={selectedCoverId === item._id}
                          setEditValue={setSelectedCoverEditValue}
                          editValue={selectedCoverEditValue}
                        />
                      );
                    })}
                  </div>

                  {starredItems.length ? <div className="divider-line" /> : null}

                  <div className="item-row covers-card">
                    {allItems.map((item, itemIndex) => {
                      return (
                        <CoverOrMediaCard
                          onDragStart={(ev) => {
                            ev.dataTransfer.setData('data', JSON.stringify(item));
                          }}
                          item={item}
                          type="covers"
                          key={item._id}
                          index={itemIndex}
                          userId={user && user._id}
                          userRole={user?.roles && user?.roles[0]}
                          onClickMenuItem={onClickMenuItem}
                          itemEditable={selectedCoverId === item._id}
                          setEditValue={setSelectedCoverEditValue}
                          editValue={selectedCoverEditValue}
                        />
                      );
                    })}
                  </div>
                </React.Fragment>
              </div>
            </>
          )}
        </div>
        {confirmModal && (
          <ConfirmModal
            title="Delete Cover?"
            body="This action cannot be undone"
            visible={confirmModal}
            cancelText="CANCEL"
            confirmText="YES, DELETE"
            onCancel={() => showConfirmModal(false)}
            onConfirm={confirmDeleteCover}
          />
        )}
      </div>
    );
  }
);

CoverTab.defaultProps = {
  searchValue: '',
  isLocked: false,
};

CoverTab.propTypes = {
  filterItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  onFolderChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  sortValue: PropTypes.string.isRequired,
  isLocked: PropTypes.bool,
};

export default CoverTab;
