/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="44" height="30" viewBox="0 0 44 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.93958 0C2.60108 0 2.2804 0.0569805 1.95972 0.189935L8.07047 6.91364L14.2525 13.7513L14.3594 13.9032L14.5375 14.0932L14.7157 14.2831L15.072 14.701L20.3811 20.494C20.4701 20.551 20.7196 20.7979 20.9333 20.9119C21.1828 21.0448 21.4678 21.1778 21.7529 21.1778C22.0557 21.1968 22.3764 21.1018 22.6615 20.9498C22.8752 20.8359 22.9643 20.6839 23.2137 20.494L29.3601 13.7323L35.5778 6.91364L41.5638 0.341884C41.1719 0.113962 40.7621 0 40.2989 0H2.93958ZM1.06894 0.816721C0.409759 1.48149 0 2.46916 0 3.58977V25.6412C0 26.5529 0.267235 27.3696 0.730441 27.9964L1.58559 27.1227L7.9814 20.494L13.6468 14.644L13.5399 14.492L7.32222 7.67338L1.10457 0.835713L1.06894 0.816721ZM42.3833 1.02565L36.326 7.69237L30.144 14.511L30.0371 14.625L35.9341 20.7219L42.3299 27.3506L42.7218 27.7305C43.0603 27.1417 43.2563 26.42 43.2563 25.6412V3.58977C43.2385 2.58312 42.9178 1.69042 42.3833 1.02565ZM14.3594 15.4607L8.71183 21.3107L2.29821 27.9394L1.4787 28.7752C1.90627 29.0601 2.38729 29.25 2.92176 29.25H40.2811C40.9225 29.25 41.4926 28.9841 41.9736 28.5852L41.5638 28.1484L35.1502 21.5196L29.2532 15.4607L23.9442 21.2917C23.6591 21.5007 23.4632 21.7286 23.1781 21.8615C22.7327 22.0894 22.2339 22.2794 21.735 22.2604C21.2362 22.2604 20.7552 22.0515 20.292 21.8045C20.0604 21.6906 19.9535 21.5766 19.6863 21.3297L14.3594 15.4607Z"
      fill="#4177BA"
    />
  </svg>
);

const PublishedMailIcon = (props) => <Icon component={svg} {...props} />;

export default PublishedMailIcon;
