import { convertFromHTML, convertToRaw, ContentState, genKey } from "draft-js";

const getRaw = (content) => {

  if (!content) {
    return {
      "blocks": [
        {
          "key": genKey(),
          "text": "",
          "type": "unstyled",
          "depth": 0,
          "inlineStyleRanges": [],
          "entityRanges": [],
          "data": {}
        }
      ],
      "entityMap": {}
    }
  }

  const blocksFromHTML = convertFromHTML(`<div>${content}</div>`);
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  );

  return convertToRaw(contentState);
};

export default getRaw;