import React from 'react';
import PropTypes from 'prop-types';

import AddCodeIntegrationModal from './AddCodeIntegrationModal/AddCodeIntegrationModal';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';

import './CodeIntegrationModals.scss';

const CodeIntegrationModals = ({
  showAddModal,
  setShowAddModal,
  updateCodeIntegration,
  integrationData,
  showDeleteModalIndex,
  setShowDeleteModalIndex,
}) => {
  return (
    <>
      {showAddModal && (
        <AddCodeIntegrationModal
          visible={showAddModal}
          onCancel={() => {
            setShowAddModal(false);
          }}
          onConfirm={(script) => {
            const tempIntegrationData = [...integrationData];
            tempIntegrationData.push(script);
            updateCodeIntegration(tempIntegrationData);
            setShowAddModal(false);
          }}
        />
      )}

      {showDeleteModalIndex !== -1 && (
        <ConfirmModal
          zIndex={1009}
          title="Delete Your Script"
          body="Are you sure you want to delete this script?"
          className="code-delete-modal"
          visible={showDeleteModalIndex !== -1}
          cancelText="CANCEL"
          confirmText="DELETE"
          onCancel={() => setShowDeleteModalIndex(-1)}
          onConfirm={() => {
            const newScripts = integrationData?.filter(
              (_script, index) => index !== showDeleteModalIndex
            );
            updateCodeIntegration(newScripts);
            setShowDeleteModalIndex(-1);
          }}
        />
      )}
    </>
  );
};

CodeIntegrationModals.defaultProps = {
  showAddModal: false,
  showDeleteModalIndex: null,
};

CodeIntegrationModals.propTypes = {
  integrationData: PropTypes.instanceOf(Array),
  showAddModal: PropTypes.bool,
  showDeleteModalIndex: PropTypes.number,
  setShowAddModal: PropTypes.func.isRequired,
  updateCodeIntegration: PropTypes.func.isRequired,
  setShowDeleteModalIndex: PropTypes.func.isRequired,
};

export default CodeIntegrationModals;
