import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.4">
      <path
        d="M6 4L10 8L6 12"
        stroke="#05034D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

const ChevronIcon = (props) => <Icon component={svg} {...props} />;

export default ChevronIcon;
