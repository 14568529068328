import { Tooltip } from 'antd';

const DynamicTooltip = ({ showTooltip, title, children }) => {
  if (showTooltip) {
    return <Tooltip title={title}>{children}</Tooltip>;
  }
  return children;
};

export default DynamicTooltip;
