import React, { useState, useCallback, Fragment } from 'react';
import { Row, Checkbox, Popover, Button, Space } from 'antd';
import { ReactSortable } from 'react-sortablejs';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Input from 'components/Input';
import DragIcon from 'components/Icons/DragIcon';
import EditIcon from 'components/Icons/EditIcon';

import './FormView.scss';

const FormView = ({
  form,
  columns,
  saveForm,
  handleFocus,
  setIsDraggable,
  configText,
  language,
}) => {
  const [formFields, setFormFields] = useState(form ? form : []);
  const [selectedField, setSelectedField] = useState();
  const [updateFormTitle, setUpdateFormTitle] = useState(false);
  const [itemKey, setItemKey] = useState();
  const [updateFields, setUpdateFields] = useState(false);

  // eslint-disable-next-line
  const debouncedSave = useCallback(
    (formFieldsCopy, columnsCopy) => {
      // _.debounce((formFieldsCopy, columnsCopy) => {
      saveForm({ formCopy: formFieldsCopy, editable: false, columns: columnsCopy });
      // }, 1000),
    },
    [saveForm]
  );

  const handleChange = (event, index) => {
    const formFieldsCopy = [...formFields];
    const columnsCopy = columns;
    switch (event.target.type) {
      case 'text':
        formFieldsCopy[index].value = event.target.value;
        break;
      case 'checkbox':
        formFieldsCopy[index]['required'] = event.target.checked;
        break;
      default:
      // do nothing
    }
    setFormFields(formFieldsCopy);
    debouncedSave(formFieldsCopy, columnsCopy);
  };

  const updateCustomField = (index, value) => {
    if (value) {
      const formFieldsCopy = [...formFields];
      formFieldsCopy[index].key = value;

      setFormFields(formFieldsCopy);
      const columnsCopy = columns;
      debouncedSave(formFieldsCopy, columnsCopy);
      setItemKey('');
      setSelectedField('');
    } else {
      if (!!index) {
        setUpdateFormTitle(false);
      }
      setSelectedField(index);
    }
  };

  const updateHeadValue = (shouldOpen, value) => {
    if (value) {
      const formFieldsCopy = [...formFields];
      let addNew = true;
      for (let i = 0; i < formFieldsCopy.length; i++) {
        if (formFieldsCopy[i].head) {
          formFieldsCopy[i].key = value;
          formFieldsCopy[i].custom = true;
          addNew = false;
          break;
        }
      }
      if (addNew) {
        formFieldsCopy.push({
          key: value,
          custom: true,
          head: true,
        });
      }
      setFormFields(formFieldsCopy);
      const columnsCopy = columns;
      debouncedSave(formFieldsCopy, columnsCopy);
      setUpdateFormTitle(false);
    } else {
      if (!!shouldOpen) {
        setSelectedField(false);
      }
      setUpdateFormTitle(shouldOpen);
    }
  };

  const sortFields = (newState) => {
    if (updateFields) {
      setFormFields(newState);
      const columnsCopy = columns;
      debouncedSave(newState, columnsCopy);
      setUpdateFields(false);
    }
  };

  let formTitle = form.find((elem) => elem.head);
  if (formTitle) {
    if (formTitle.custom) {
      formTitle = formTitle.key;
    } else {
      formTitle = configText('Please fill the form below');
    }
  } else {
    formTitle = configText('Please fill the form below');
  }

  return (
    <div className="form-view">
      <Row className="form-view-header">
        <h3>{formTitle}</h3>
        <Popover
          placement={
            language.toLowerCase() === 'hebrew' || language.toLowerCase() === 'arabic'
              ? 'topLeft'
              : 'topRight'
          }
          onVisibleChange={() => updateHeadValue(true)}
          trigger="click"
          visible={updateFormTitle}
          content={
            <div
              className={`edit-container ${language.toLowerCase()}`}
              onClick={(e) => e.stopPropagation()}>
              <Input
                autoFocus
                onFocus={handleFocus}
                defaultValue={formTitle}
                onClick={(e) => handleFocus(e)}
                onChange={(e) => setItemKey(e.target.value)}
              />

              <div className="button-wrapper">
                <Space>
                  <Button
                    onClick={(e) => {
                      updateHeadValue(true, itemKey);
                    }}>
                    Save
                  </Button>
                  <Button onClick={() => updateHeadValue(false)}>Cancel</Button>
                </Space>
              </div>
            </div>
          }>
          <EditIcon
            id="form-editor-edit-button"
            className={`form-editor-edit-button`}
            onClick={(e) => e.stopPropagation()}
          />
        </Popover>
      </Row>
      <Row className="form-view-fields-row">
        <ReactSortable
          list={formFields}
          onUpdate={() => {
            setUpdateFields(true);
          }}
          setList={(newState) => {
            sortFields(newState);
          }}
          animation={400}
          handle=".handle"
          className={`form-list-items ${columns === 2 ? 'form-list-two-columns' : ''}`}>
          {_.map(formFields, (item, listIndex) =>
            item.selected || item.saved ? (
              <div
                key={`${listIndex}`}
                className={item.selected ? 'form-list-item selected' : 'form-list-item'}>
                <div className="form-label">
                  <label>{configText(item.key)}</label>{' '}
                  {item.required ? <span className="required">*</span> : null}
                  {item.editable ? (
                    <Popover
                      onVisibleChange={() => updateCustomField(listIndex)}
                      trigger="click"
                      visible={selectedField === listIndex}
                      content={
                        <div
                          className={`edit-container ${language.toLowerCase()}`}
                          onClick={(e) => e.stopPropagation()}>
                          <Input
                            autoFocus
                            onFocus={handleFocus}
                            defaultValue={item.key}
                            onClick={(e) => handleFocus(e)}
                            onChange={(e) => setItemKey(e.target.value)}
                          />

                          <div className="button-wrapper">
                            <Space>
                              <Button
                                onClick={(e) => {
                                  updateCustomField(listIndex, itemKey);
                                }}>
                                Save
                              </Button>
                              <Button onClick={() => updateCustomField(false)}>Cancel</Button>
                            </Space>
                          </div>
                        </div>
                      }>
                      <EditIcon
                        id="form-editor-edit-button"
                        className={`form-editor-edit-button ${
                          item.required ? 'required-editable' : 'editable'
                        }`}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </Popover>
                  ) : null}
                </div>

                <div className="form-field-wrapper">
                  <div
                    className="handle-draggable handle"
                    onMouseDown={() => setIsDraggable(false)}
                    onMouseUp={() => setIsDraggable(true)}>
                    <DragIcon />
                  </div>
                  <Input
                    type="text"
                    placeholder={`${configText('Enter')} ${configText(item.key)}`}
                    onChange={(e) => handleChange(e, listIndex)}
                    onClick={handleFocus}
                    id={item.key}
                    defaultValue={item.value}
                    disabled={
                      window.location.href.indexOf('/cd/') === -1 &&
                      window.location.href.indexOf('/proposal-preview') === -1
                        ? true
                        : false
                    }
                  />
                </div>

                <Checkbox
                  name={item.key}
                  className="required"
                  onChange={(e) => handleChange(e, listIndex)}
                  onClick={(e) => e.stopPropagation()}
                  checked={item.required}>
                  {configText('Required')}
                </Checkbox>
              </div>
            ) : (
              <Fragment key={listIndex}></Fragment>
            )
          )}
        </ReactSortable>
      </Row>
    </div>
  );
};

FormView.propTypes = {
  form: PropTypes.instanceOf(Array).isRequired,
  saveForm: PropTypes.func.isRequired,
  handleFocus: PropTypes.func.isRequired,
  configText: PropTypes.func.isRequired,
};

export default FormView;
