import { EditorState, convertToRaw, convertFromRaw, genKey } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import getRaw from './getRaw';
import { hyphenatedWord } from 'utils/string';

function formatToPlainText(html) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}

const createNewItem = (text) => ({
  key: genKey(),
  text,
  type: 'ordered-list-item',
  depth: 0,
  inlineStyleRanges: [],
  entityRanges: [],
  data: {},
});

const updateEditorState = (editorState, blocks) =>
  EditorState.createWithContent(
    convertFromRaw({
      blocks,
      entityMap: {},
    })
  );

const getTerms = ({ prop, configText, user, expiryDate }) => {
  const title = prop?.draft?.terms?.title || hyphenatedWord(configText('section terms title'));

  let rawBlocks = prop?.draft?.terms?.raw?.blocks || [];
  let editorState = EditorState.createWithContent(
    convertFromRaw({
      blocks: rawBlocks,
      entityMap: {},
    })
  );

  const [firstBlock, ...remainingBlocks] = convertToRaw(editorState.getCurrentContent()).blocks;
  let orderedList;

  const hasExpiryDate = expiryDate && expiryDate !== 'NA';
  const hasGlobalExpiryDate =
    !expiryDate && (user?.expiry !== '0' || !user?.expiry || prop?.expiryDate);
  const noExpiryDate =
    !prop?.expiryDate ||
    (user?.expiry && expiryDate === 'NA') ||
    expiryDate === 'NA' ||
    !user?.expiry ||
    user?.expiry === '' ||
    user?.expiry === 0;

  const configTextFormatted = formatToPlainText(
    configText('terms expiry text').replace(/\n/gim, '')
  );

  if (prop?.fromUserTemplate || expiryDate) {
    if (firstBlock && firstBlock.text.includes('{{proposal.expireDate}}')) {
      editorState = updateEditorState(editorState, remainingBlocks);
    }

    const rawData = convertToRaw(editorState.getCurrentContent());
    const textString = stateToHTML(convertFromRaw(rawData));
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = textString;
    rawBlocks = rawData?.blocks;
    orderedList = tempDiv.innerHTML;
  }

  if (
    hasGlobalExpiryDate ||
    ((user?.expiry === '0' || !user?.expiry) && expiryDate === undefined)
  ) {
    let terms;

    if (
      hasGlobalExpiryDate &&
      user?.expiry !== null &&
      user?.expiry !== '' &&
      !prop?.fromUserTemplate
    ) {
      terms = configText('terms custom expiry');
    } else if (hasGlobalExpiryDate && user?.expiry !== null && prop?.fromUserTemplate) {
      const textContent = configTextFormatted;
      const newItem = createNewItem(textContent);
      const newBlocks = [newItem, ...convertToRaw(editorState.getCurrentContent()).blocks];
      editorState = updateEditorState(editorState, newBlocks);

      const rawData = convertToRaw(editorState.getCurrentContent());
      const textString = stateToHTML(convertFromRaw(rawData));
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = textString;
      orderedList = tempDiv.innerHTML;

      terms = orderedList;
    } else {
      switch (prop.clientType) {
        case 'private':
        case 'smedium':
          terms = configText('terms simple');
          break;
        case 'ent':
          terms = configText('terms ent');
          break;
        default:
          terms = prop?.fromUserTemplate ? orderedList?.outerHTML : configText('terms simple');
          break;
      }
    }

    const formattedTerms = terms.replace(/\n/gim, '');

    return {
      title,
      rawtitle: getRaw(title),
      subrawtitle: getRaw(''),
      text: formattedTerms,
      raw: getRaw(formattedTerms),
    };
  }

  if (hasExpiryDate) {
    const textContent = configTextFormatted;
    const newItem = createNewItem(textContent);
    const newBlocks = [newItem, ...convertToRaw(editorState.getCurrentContent()).blocks];
    editorState = updateEditorState(editorState, newBlocks);

    const rawData = convertToRaw(editorState.getCurrentContent());
    const textString = stateToHTML(convertFromRaw(rawData));
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = textString;
    orderedList = tempDiv.innerHTML;

    return {
      title,
      rawtitle: getRaw(title),
      subrawtitle: getRaw(''),
      text: orderedList,
      raw: rawData,
    };
  }

  if (noExpiryDate) {
    return {
      title,
      rawtitle: getRaw(title),
      subrawtitle: getRaw(''),
      text: orderedList,
      raw: {
        blocks: rawBlocks,
        entityMap: {},
      },
    };
  }
};

export default getTerms;
