import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { PhotoAlbum } from 'react-photo-album';

import utils from 'utils/utils';

const RenderGallery = ({ images, config, saveGallery }) => {
  const [photos, setPhotos] = useState([]);
  const { options, containerProps, imageProps } = utils.albumConfig(config);
  const isOldGallery = images.some((img) => img.w === 5 && 'x' in img);

  useEffect(() => {
    setPhotos(utils.albumImagesBuilder(images));
  }, [images]);

  // prevent multiple change in short span of time
  const debounceSaveGallery = useRef(
    _.debounce((value) => {
      saveGallery(value);
    }, 500)
  ).current;

  const handleImageLoad = (event) => {
    /* 
    REMARK : can be remove after few months
    only used to make new gallery compatible with old gallery, 
    since new gallery requries height and width which is missing from old gallery
    */
    const { naturalWidth, naturalHeight, id } = event.target;
    if (!naturalWidth || !naturalHeight || !id) {
      return;
    }

    setPhotos((prev) => {
      const newPhotos = [...prev];
      // find the photo and add width and height
      const photoIndex = newPhotos.findIndex((p) => p.id === id);
      newPhotos[photoIndex].width = naturalWidth;
      newPhotos[photoIndex].height = naturalHeight;

      // save the data when all images are having height
      const shouldSave = newPhotos.every((img) => img.height);
      if (shouldSave) {
        debounceSaveGallery({ data: newPhotos, config: config || {} });
      }

      return newPhotos;
    });
  };

  const renderPhoto = ({ photo, imageProps: { alt, style, ...restImageProps } }) => {
    const imageId = photo.id;

    return (
      <img
        alt={alt}
        id={imageId}
        style={style}
        {...restImageProps}
        {...(isOldGallery ? { onLoad: handleImageLoad } : {})}
      />
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <PhotoAlbum
        photos={photos}
        {...options}
        {...(isOldGallery && { renderPhoto })}
        componentsProps={{
          containerProps,
          imageProps,
        }}
      />
    </div>
  );
};

RenderGallery.propTypes = {
  images: PropTypes.array.isRequired,
  config: PropTypes.instanceOf(Object),
  saveGallery: PropTypes.instanceOf(Object).isRequired,
};

export default RenderGallery;
