import { create as createConfetti } from 'canvas-confetti';
import { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import './CanvasConfetti.scss';

const CanvasConfetti = ({ event, delay }) => {
  const canvasRef = useRef(null);
  const confettiInstance = useRef(null);

  const initializeConfetti = useCallback(() => {
    if (canvasRef.current && !confettiInstance.current) {
      confettiInstance.current = createConfetti(canvasRef.current, {
        resize: true,
        useWorker: true,
      });
    }
  }, []);

  useEffect(() => {
    initializeConfetti();

    return () => {
      // Cleanup confetti instance on unmount
      confettiInstance.current = null;
    };
  }, [initializeConfetti]);

  useEffect(() => {
    if (!confettiInstance.current) return;
    const defaults = {
      scalar: 2,
      spread: 180,
      particleCount: 30,
      origin: { y: -0.1 },
      startVelocity: -35,
    };
    if (event === 'ONBOARDED') {
      const launchConfetti = () => {
        const promises = [
          confettiInstance.current({
            ...defaults,
            shapes: ['rectangle', 'sphere'],
            colors: ['#FF2929', '#2F2C88'],
          }),
          confettiInstance.current({
            ...defaults,
            shapes: ['square', 'circle'],
            // shapes: [shapes.heart, shapes.tree],
            colors: ['#2F2C88', '#FF2929'],
          }),
        ];

        Promise.all(promises).catch(console.error);
      };

      // Delay confetti launch
      setTimeout(launchConfetti, delay);
    }
  }, [delay, event]);

  return <canvas className="confetti" ref={canvasRef} />;
};

CanvasConfetti.defaultProps = {
  delay: 1000,
};

CanvasConfetti.propTypes = {
  event: PropTypes.string.isRequired,
  delay: PropTypes.number,
};

export default CanvasConfetti;
