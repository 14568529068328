import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5 3C14.5 6.4875 12.25 7.95 9.7 9.675C7.0375 11.4375 4 13.425 4 18H5.5C5.5 14.2125 7.9375 12.6 10.525 10.9125C13.075 9.225 16 7.275 16 3H14.5Z"
      fill="#7876C1"
    />
    <path
      d="M14.6552 17H5.34483C5.13793 17 5 16.8286 5 16.5714C5 16.3143 5.13793 16.1429 5.34483 16.1429H14.6552C14.8621 16.1429 15 16.3143 15 16.5714C15 16.8286 14.8621 17 14.6552 17ZM13.9655 15.2857H5.68966C5.48276 15.2857 5.34483 15.1143 5.34483 14.8571C5.34483 14.6 5.48276 14.4286 5.68966 14.4286H13.9655C14.1724 14.4286 14.3103 14.6 14.3103 14.8571C14.3103 15.1143 14.1724 15.2857 13.9655 15.2857ZM13.2759 13.5714H6.37931C6.17241 13.5714 6.03448 13.4 6.03448 13.1429C6.03448 12.8857 6.17241 12.7143 6.37931 12.7143H13.2759C13.4828 12.7143 13.6207 12.8857 13.6207 13.1429C13.6207 13.4 13.4828 13.5714 13.2759 13.5714ZM11.5517 11.8571H8.10345C7.89655 11.8571 7.75862 11.6857 7.75862 11.4286C7.75862 11.1714 7.89655 11 8.10345 11H11.5517C11.7586 11 11.8966 11.1714 11.8966 11.4286C11.8966 11.6857 11.7586 11.8571 11.5517 11.8571Z"
      fill="#7876C1"
    />
    <path
      d="M14.6552 4.71429H5.34483C5.13793 4.71429 5 4.57143 5 4.35714C5 4.14286 5.13793 4 5.34483 4H14.6552C14.8621 4 15 4.14286 15 4.35714C15 4.57143 14.8621 4.71429 14.6552 4.71429ZM14.3103 6.14286H6.03448C5.82759 6.14286 5.68966 6 5.68966 5.78571C5.68966 5.57143 5.82759 5.42857 6.03448 5.42857H14.3103C14.5172 5.42857 14.6552 5.57143 14.6552 5.78571C14.6552 6 14.5172 6.14286 14.3103 6.14286ZM13.6207 7.57143H6.72414C6.51724 7.57143 6.37931 7.42857 6.37931 7.21429C6.37931 7 6.51724 6.85714 6.72414 6.85714H13.6207C13.8276 6.85714 13.9655 7 13.9655 7.21429C13.9655 7.42857 13.8276 7.57143 13.6207 7.57143ZM11.8966 9H8.44828C8.24138 9 8.10345 8.85714 8.10345 8.64286C8.10345 8.42857 8.24138 8.28571 8.44828 8.28571H11.8966C12.1034 8.28571 12.2414 8.42857 12.2414 8.64286C12.2414 8.85714 12.1034 9 11.8966 9Z"
      fill="#C7BCE4"
    />
    <path
      d="M16 18H14.5C14.5 14.2125 12.0625 12.6 9.475 10.9125C6.925 9.225 4 7.275 4 3H5.5C5.5 6.4875 7.75 7.95 10.3 9.675C12.9625 11.4375 16 13.425 16 18Z"
      fill="#C7BCE4"
    />
  </svg>
);

const DNAIcon = (props) => <Icon component={svg} {...props} />;

export default DNAIcon;
