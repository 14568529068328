import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { notification } from 'antd';
import PropTypes from 'prop-types';

import { DISCONNECT_ZAPIER } from 'graphql/mutations/integrationMutation';
import IntegrationItem from '../IntegrationItem';
import images from 'constants/images';

const ZapierIntegration = ({ data, isModalItem, userRole }) => {
  const [integrationData, setIntegrationData] = useState(data);
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (data) {
      setIntegrationData(data);
    }
  }, [data]);

  const [disconnectZapier] = useMutation(DISCONNECT_ZAPIER, {
    onCompleted: () => {
      setLoading('disconnected');
      notification.success({
        message: 'Zapier Disconnected',
        description: 'Your account has been disconnected from Zapier.',
      });
    },
    onError: () => {
      setLoading('');
      notification.error({
        message: 'Zapier Disconnected Failed',
        description: 'Your account failed to disconnect try again.',
      });
    },
  });

  const handleClick = () => {
    if (!integrationData || !integrationData.length) {
      setLoading('connecting');
      window.open(
        'https://zapier.com/developer/public-invite/25191/22c290c7fcee0e9c59ab4551c0b87e0f/',
        '_blank'
      );
    }
    // since zaps are not present click means disconnect
    setLoading('disconnecting');
    disconnectZapier();
  };

  return (
    <IntegrationItem
      type="zapier"
      name="zapier"
      description="Connect the apps you use everyday to automate your work and be more productive"
      integrationLink="https://support.goprospero.com/article/show/140029-how-to-integrate-prospero-to-zapier"
      status={
        loading || (integrationData && integrationData.length ? 'connected' : 'disconnected') // this can be connecting or disconnecting
      }
      image={images.ZAPIER_ICON}
      handleClick={handleClick}
      isModalItem={isModalItem}
      userRole={userRole}
    />
  );
};

ZapierIntegration.defaultProps = {
  data: null,
  isModalItem: false,
};

ZapierIntegration.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(null),
  ]),
  isModalItem: PropTypes.bool,
  userRole: PropTypes.string,
};

export default ZapierIntegration;
