import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.42188 11.625C2.06094 11.625 1.75195 11.4965 1.49492 11.2395C1.23789 10.9824 1.10938 10.6734 1.10938 10.3125V2.34375C0.932292 2.34375 0.778646 2.27865 0.648438 2.14844C0.518229 2.01823 0.453125 1.86458 0.453125 1.6875C0.453125 1.51042 0.518229 1.35677 0.648438 1.22656C0.778646 1.09635 0.933292 1.03125 1.11238 1.03125H3.64062C3.64062 0.854167 3.70573 0.700521 3.83594 0.570312C3.96615 0.440104 4.11979 0.375 4.29688 0.375H6.6875C6.86458 0.375 7.01823 0.440104 7.14844 0.570312C7.27865 0.700521 7.34375 0.854167 7.34375 1.03125H9.872C10.0511 1.03125 10.2057 1.09635 10.3359 1.22656C10.4661 1.35677 10.5312 1.51042 10.5312 1.6875C10.5312 1.86458 10.4661 2.01823 10.3359 2.14844C10.2057 2.27865 10.0521 2.34375 9.875 2.34375V10.3125C9.875 10.6734 9.74648 10.9824 9.48945 11.2395C9.23242 11.4965 8.92344 11.625 8.5625 11.625H2.42188ZM2.42188 2.34375V10.3125H8.5625V2.34375H2.42188ZM3.59375 8.34375C3.59375 8.52083 3.65885 8.67448 3.78906 8.80469C3.91927 8.9349 4.07292 9 4.25 9C4.42708 9 4.58073 8.9349 4.71094 8.80469C4.84115 8.67448 4.90625 8.52083 4.90625 8.34375V4.3125C4.90625 4.13542 4.84115 3.98177 4.71094 3.85156C4.58073 3.72135 4.42708 3.65625 4.25 3.65625C4.07292 3.65625 3.91927 3.72135 3.78906 3.85156C3.65885 3.98177 3.59375 4.13542 3.59375 4.3125V8.34375ZM6.07812 8.34375C6.07812 8.52083 6.14323 8.67448 6.27344 8.80469C6.40365 8.9349 6.55729 9 6.73438 9C6.91146 9 7.0651 8.9349 7.19531 8.80469C7.32552 8.67448 7.39062 8.52083 7.39062 8.34375V4.3125C7.39062 4.13542 7.32552 3.98177 7.19531 3.85156C7.0651 3.72135 6.91146 3.65625 6.73438 3.65625C6.55729 3.65625 6.40365 3.72135 6.27344 3.85156C6.14323 3.98177 6.07812 4.13542 6.07812 4.3125V8.34375Z"
      fill="#05034D"
    />
  </svg>
);

const DeleteIcon2 = (props) => <Icon component={svg} {...props} />;

export default DeleteIcon2;
