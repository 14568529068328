/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 4L15.5 7.5M15.5 7.5L12.5 11M15.5 7.5L7 7.5"
      stroke="#F03F3B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 1H2C1.44772 1 1 1.44772 1 2V13C1 13.5523 1.44772 14 2 14H9"
      stroke="#05034D"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

const SignOutIcon = (props) => (
  <Icon style={{ display: 'flex', alignItems: 'cente' }} component={svg} {...props} />
);

export default SignOutIcon;
