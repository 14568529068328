import React from 'react';
import { Col, Popover, Tag } from 'antd';
import PropTypes from 'prop-types';

import TotalIcon from 'components/Icons/TotalIcon';

const Totals = ({
  prop,
  subtvalue,
  curr,
  totes,
  state: { stab },
  chosen,
  changeTaxText,
  changeTaxVal,
  changeTaxUnit,
  changeSubtotalText,
  changesubtotalBeforeTaxText,
  changeSubtotalValue,
  changeTotalText,
  changeDiscountText,
  changeDiscountVal,
  changeDiscountUnit,
  configText,
  dis,
  tax,
  multipleTotal,
  price,
  roundTotal,
  changeTotal,
  showSubTotal,
  showSubTotalBeforeTax,
  showTotal,
  discountBtn,
  taxBtn,
  calculateDiscount,
  setVisible,
  visible,
  totalText,
  subTotalElement,
  discountElement,
  subtotalBeforeTaxElement,
  taxElement,
  totalElement,
  total,
  setDiscountVisible,
  discountVisible,
  taxVisible,
  setTaxVisible,
}) => {
  const clickHideButton = () => {
    setVisible(false);
    setDiscountVisible(false);
    setTaxVisible(false);
  };

  const popOverContent = (
    <>
      {total() ? (
        <div className="totals-container">
          <div className="totals-container-close">
            <button
              className="totals-container-close-button"
              type="button"
              onClick={clickHideButton}>
              Hide
            </button>
          </div>
          <Col className="totals-content">
            {showSubTotal && subTotalElement()}
            {dis && discountElement()}
            {dis && tax && showSubTotalBeforeTax && subtotalBeforeTaxElement()}
            {tax && taxElement()}
            {total()}
          </Col>
        </div>
      ) : null}
    </>
  );

  return (
    <div className="totals-popover-container">
      <Popover
        overlayClassName={`totals-popover `}
        content={popOverContent}
        trigger="click"
        placement="top"
        visible={visible || discountVisible || taxVisible}
        onVisibleChange={(value) => value && setVisible(value)}>
        <Tag icon={<TotalIcon />} className="totals-container-tag">
          {totalText}
        </Tag>
      </Popover>
    </div>
  );
};

Totals.defaultProps = {
  dis: '',
  tax: '',
  subtvalue: '',
  roundTotal: false,
};

Totals.propTypes = {
  prop: PropTypes.instanceOf(Object).isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  subtvalue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  curr: PropTypes.string.isRequired,
  totes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  chosen: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  changeTaxText: PropTypes.func.isRequired,
  changeTaxVal: PropTypes.func.isRequired,
  changeTaxUnit: PropTypes.func.isRequired,
  changeSubtotalText: PropTypes.func.isRequired,
  changeSubtotalValue: PropTypes.func.isRequired,
  changesubtotalBeforeTaxText: PropTypes.func.isRequired,
  changeTotalText: PropTypes.func.isRequired,
  changeDiscountText: PropTypes.func.isRequired,
  changeDiscountVal: PropTypes.func.isRequired,
  changeDiscountUnit: PropTypes.func.isRequired,
  configText: PropTypes.func.isRequired,
  dis: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  tax: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  multipleTotal: PropTypes.instanceOf(Object).isRequired,
  price: PropTypes.bool.isRequired,
  roundTotal: PropTypes.bool,
  changeTotal: PropTypes.func.isRequired,
  discountBtn: PropTypes.bool,
};

export default Totals;
