import { EditorState, SelectionState, AtomicBlockUtils } from 'draft-js';

const handleDuplicateElement = async ({
  content,
  entityType,
  key,
  state,
  dispatch,
  props,
  editorChange,
  editorRef,
  updateLoaderState,
  updateSelection,
}) => {
  if (updateLoaderState) {
    updateLoaderState(true);
  }
  let data;
  let entityKey;
  let contentStateWithEntity;
  const editorState = editorRef.current;
  const contentState = editorState.getCurrentContent();

  if (entityType === 'form') {
    data = { data: content.form, config: content.config };
    contentStateWithEntity = await contentState.createEntity(entityType, 'MUTABLE', data);
  } else if (entityType === 'table') {
    data = { data: content };
    contentStateWithEntity = await contentState.createEntity('table', 'MUTABLE', data);
  } else if (entityType === 'testimonial') {
    data = { data: content.data, config: content?.config || {} };
    contentStateWithEntity = await contentState.createEntity('testimonial', 'MUTABLE', data);
  } else if (entityType === 'html') {
    data = { ...content };
    contentStateWithEntity = await contentState.createEntity('html', 'MUTABLE', data);
  } else {
    data = { texcontent: content };
    contentStateWithEntity = await contentState.createEntity('TOKEN', 'IMMUTABLE', data);
  }

  entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  content = editorState.getCurrentContent();

  const lastBlock = content.getBlockForKey(key);
  const lastKey = lastBlock.getKey();
  const length = lastBlock.getLength();

  const newes = EditorState.acceptSelection(
    editorState,
    new SelectionState({
      anchorKey: lastKey,
      anchorOffset: length,
      focusKey: lastKey,
      focusOffset: length,
      isBackward: false,
    })
  );

  const es = await AtomicBlockUtils.insertAtomicBlock(newes, entityKey, ' ');

  editorChange(
    {
      editorState: es,
      props,
      dispatch,
      editorRef,
      updateSelection,
    },
    () => {
      if (updateLoaderState) {
        updateLoaderState(false);
      }
    }
  );
};

export default handleDuplicateElement;
