/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg
    width="297"
    height="220"
    viewBox="0 0 297 220"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_8135_21728)">
      <rect x="30" y="19" width="237" height="160" rx="8" fill="#F6F5FB" />
    </g>
    <path
      d="M30 27C30 22.5817 33.5817 19 38 19H259C263.418 19 267 22.5817 267 27V33H30V27Z"
      fill="white"
    />
    <circle cx="41" cy="26" r="3" fill="#F87750" />
    <circle cx="51" cy="26" r="3" fill="#F9C938" />
    <circle cx="61" cy="26" r="3" fill="#6DB142" />
    <rect x="71" y="64" width="155" height="100" rx="4" fill="#ECECF6" />
    <rect x="79" y="91" width="92" height="23" rx="4" fill="white" />
    <rect x="79" y="70" width="92" height="15" rx="4" fill="white" />
    <rect x="179" y="70" width="38" height="73" rx="4" fill="white" />
    <rect x="79" y="120" width="92" height="23" rx="4" fill="white" />
    <rect x="192" y="151" width="25" height="7" rx="2" fill="#F03F3B" />
    <circle cx="116.5" cy="50.5" r="4.5" fill="#01C34F" />
    <circle cx="137.5" cy="50.5" r="4.5" fill="#01C34F" />
    <circle cx="158.5" cy="50.5" r="4.5" fill="#01C34F" />
    <rect x="120" y="50" width="15" height="1" fill="#01C34F" />
    <rect x="140" y="50" width="15" height="1" fill="#01C34F" />
    <rect x="157" y="50" width="20" height="1" fill="#01C34F" />
    <circle cx="179.5" cy="50.5" r="4.5" fill="#7876C1" />
    <defs>
      <filter
        id="filter0_d_8135_21728"
        x="0"
        y="0"
        width="297"
        height="220"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="11" />
        <feGaussianBlur stdDeviation="15" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0908559 0 0 0 0 0.07875 0 0 0 0 0.45 0 0 0 0.15 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8135_21728" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_8135_21728"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const NewUserImage = (props) => <Icon component={svg} {...props} />;

export default NewUserImage;
