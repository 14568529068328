import React from 'react';
import { Divider } from 'antd';
import PropTypes from 'prop-types';

const TitleDivider = ({ content }) => (
  <Divider orientation="left" plain className="custom-divider">
    <h5 className="text">{content}</h5>
  </Divider>
);

TitleDivider.propTypes = {
  content: PropTypes.string.isRequired,
};

export default TitleDivider;
