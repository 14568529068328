import React, { useState } from 'react';
import { Button, Input, Modal } from 'antd';
import PropTypes from 'prop-types';
import CloseIcon from 'components/Icons/CloseIcon';

import './AddChatwayIntegrationModal.scss';
import { RedirectIcon } from 'components/Icons';

const AddChatwayIntegrationModal = ({ visible, onCancel, onConfirm, loading }) => {
  const [code, setCode] = useState();
  const [error, setError] = useState();

  const onCodeLinkClick = () => {
    window.open('https://go.chatway.app/code', '_blank');
  };

  return (
    <Modal
      zIndex={1009}
      width={'514px'}
      className="confirm-modal code-add-modal"
      centered
      visible={visible}
      onCancel={onCancel}
      closeIcon={<CloseIcon className="close-icon" />}
      footer={null}>
      <h3 className="title">Add Your Script</h3>
      <div className="divider" />
      <p className="code-link">
        Paste here your&nbsp;
        <a href="https://go.chatway.app/code" target="_blank" rel="noreferrer">
          Chatway installation code
        </a>
        &nbsp;
        <RedirectIcon onClick={onCodeLinkClick} />
      </p>
      <div className="add-code">
        <p>JS Code Script</p>
        <Input.TextArea
          value={code}
          className={error ? 'error' : ''}
          placeholder='<script id="chatway" async="true" src="https://cdn.chatway.app/widget.js?id=Your_ID_Here"></script>'
          onChange={(e) => {
            setCode(e.target.value);
          }}
        />
      </div>

      <div className="button-wrapper">
        <Button
          className="button confirm"
          type="primary"
          loading={loading}
          onClick={() => {
            if (code?.trim()) {
              onConfirm(code);
              setError(false);
            } else {
              setError(true);
            }
          }}>
          Connect Chatway
        </Button>
        <Button
          className="button cancel"
          type="primary"
          onClick={() => {
            onCancel();
            setCode('');
          }}>
          CANCEL
        </Button>
      </div>
    </Modal>
  );
};

AddChatwayIntegrationModal.defaultProps = {
  visible: false,
};

AddChatwayIntegrationModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
};

export default AddChatwayIntegrationModal;
