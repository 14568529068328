import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.1" width="16" height="16" rx="8" fill="#005AFF" />
    <path
      d="M5.17157 5.17135C5.5621 4.78083 6.19526 4.78083 6.58579 5.17135L10.8284 9.41399C11.219 9.80452 11.219 10.4377 10.8284 10.8282C10.4379 11.2187 9.80474 11.2187 9.41421 10.8282L5.17157 6.58557C4.78105 6.19504 4.78105 5.56188 5.17157 5.17135Z"
      fill="#005AFF"
    />
    <rect
      x="11.5356"
      y="5.87842"
      width="8"
      height="2"
      rx="1"
      transform="rotate(135 11.5356 5.87842)"
      fill="#005AFF"
    />
  </svg>
);

const SectionCancelIcon = (props) => <Icon component={svg} {...props} />;

export default SectionCancelIcon;
