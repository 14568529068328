import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Row, Col, Checkbox, Form } from 'antd';

import CloseIcon from 'components/Icons/CloseIcon';
import { SLACK_NOTIFICATION } from 'constants/index';

import './SlackNotificationModal.scss';

const SlackNotificationModal = ({
  title,
  visible,
  showSlackNotificationModal,
  slackNotifications,
  updateNotifications,
  zIndex,
}) => {
  const [notifications, setNotifications] = useState(slackNotifications || SLACK_NOTIFICATION);
  const [initialValues, setInitialValues] = useState({});

  const updateNoti = (actions) => {
    let tempNoti = SLACK_NOTIFICATION.map((x) => {
      return { ...x };
    });
    SLACK_NOTIFICATION.forEach((notificationType, index) => {
      actions.forEach((action) => {
        if (action === notificationType.action) {
          tempNoti[index].selected = true;
        }
      });
    });
    setNotifications(tempNoti);
    setInitialValues({
      'checkbox-group': actions,
    });
  };

  const getDefaultValues = useCallback(() => {
    let def = [];
    notifications.forEach((notificationType) => {
      if (notificationType.selected) {
        def.push(notificationType.action);
      }
    });
    setInitialValues({ 'checkbox-group': def });
  }, [notifications]); //eslint-disable-line

  useEffect(() => {
    getDefaultValues();
  }, [getDefaultValues]);

  const saveNotifications = () => {
    updateNotifications(notifications);
    showSlackNotificationModal(false);
  };

  return (
    <Modal
      className="simple-info-modal slack-notification-modal"
      visible={visible}
      zIndex={zIndex}
      footer={[
        <div className="button-wrapper">
          <Button
            className="button cancel"
            type="primary"
            onClick={() => showSlackNotificationModal(false)}>
            CANCEL
          </Button>
          <Button className="button confirm" type="primary" onClick={saveNotifications}>
            Save
          </Button>
        </div>,
      ]}
      width={800}
      closeIcon={<CloseIcon className="close-icon" />}
      onCancel={() => showSlackNotificationModal(false)}>
      <div>
        <h3 className="title">Slack Settings</h3>

        <div className="divider" />
        <div className="body">
          <Form
            layout="vertical"
            name="form_in_modal"
            className="form"
            initialValues={initialValues}>
            <Form.Item name="checkbox-group" label="Slack Notifications Type">
              <Checkbox.Group onChange={updateNoti}>
                <Row className="slack-notification-button-row">
                  {notifications.map((notificationType) => (
                    <Col key={notificationType.action}>
                      <Checkbox
                        className="slack-notification-checkbox"
                        value={notificationType.action}
                        key={notificationType.action}>
                        {notificationType.text}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

SlackNotificationModal.defaultProps = {
  visible: false,
  showIcon: true,
  isSaving: false,
  maskClosable: true,
  onCancel: () => {},
  onConfirm: () => {},
};

SlackNotificationModal.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  visible: PropTypes.bool,
  showIcon: PropTypes.bool,
  isSaving: PropTypes.bool,
  maskClosable: PropTypes.bool,
};

export default SlackNotificationModal;
