import React, { useEffect, useState } from 'react';
import { Col, Row, Button as AntdButton } from 'antd';
import { useMutation, useReactiveVar } from '@apollo/client';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import images from 'constants/images';
import { userVar } from 'graphql/cache';
import { USER_UPDATE } from 'graphql/mutations/userMutations';

const INTEGRATION_BUTTON_TYPES = {
  connected: {
    text: 'connected',
    color: '#01c34f !important',
    borderLeftColor: '#01c34f !important',
    background: '#dcf6e7 !important',
    border: '#dcf6e7 !important',
  },

  disconnected: {
    text: 'connect',
    borderLeftColor: '#f03f3b !important',
    color: '#C4C4C4 !important',
    background: '#FFFFFF !important',
    border: '#C4C4C4 !important',
  },
};

const Button = styled(AntdButton)`
  color: ${(props) => INTEGRATION_BUTTON_TYPES[props.status].color};
  background: ${(props) => INTEGRATION_BUTTON_TYPES[props.status].background};
  border: ${(props) => `1px solid ${INTEGRATION_BUTTON_TYPES[props.status].border}`};
  &:hover {
    color: ${(props) => INTEGRATION_BUTTON_TYPES.connected.color};
    border: ${(props) => `1px solid ${INTEGRATION_BUTTON_TYPES.connected.color}`};
  }
  a:hover span {
    display: none;
  }
`;

const Item = styled(Row)`
  border-left: ${(props) => `4px solid ${INTEGRATION_BUTTON_TYPES[props.status].borderLeftColor}`};
`;

const UserwayIntegration = ({ data, isModalItem, saveProposal }) => {
  const user = useReactiveVar(userVar);
  const [accessibility, setUserway] = useState(data);

  useEffect(() => {
    setUserway(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [saveUser] = useMutation(USER_UPDATE, {
    async onCompleted({ updateUser }) {
      if ('userway' in updateUser) {
        userVar({ ...window.structuredClone(user), userway: updateUser.userway });
      }
    },
  });

  const toggleUserway = () => {
    const userway = !accessibility;

    setUserway(userway);

    if (isModalItem) {
      saveProposal({ userway });
    } else {
      saveUser({
        variables: {
          user: {
            id: userVar()._id,
            userway,
          },
        },
      });
    }
  };

  const status = accessibility ? 'connected' : 'disconnected';

  return (
    <Item
      className="integration-item integration-item-code"
      status={status}
      align-items="center"
      justify="center">
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: isModalItem ? 12 : 7 }}
        xl={{ span: isModalItem ? 10 : 6 }}>
        <div className="item-brand-name">
          <img src={images.USERWAY_ICON} height={30} alt="prospero-codes" />
          <span className="item-name">UserWay</span>
        </div>

        {isModalItem && (
          <span className="item-status-description">
            {accessibility
              ? 'The accessibility widget is displayed on your proposals'
              : 'Add an accessibility widget for your proposals'}
          </span>
        )}
      </Col>

      {!isModalItem && (
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
          xl={{ span: 10 }}
          className="item-description">
          {accessibility
            ? 'The accessibility widget is displayed on your proposals'
            : 'Add an accessibility widget for your proposals'}
        </Col>
      )}

      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: isModalItem ? 12 : 9 }}
        xl={{ span: isModalItem ? 14 : 8 }}
        className="value">
        <a
          href="https://support.goprospero.com/article/show/169171-how-to-enable-accessibility-widget-on-my-proposals"
          className="integration-link"
          target="_blank"
          rel="noreferrer">
          Integration guide
        </a>

        <Button className="integration-button" status={status} onClick={toggleUserway}>
          {!accessibility ? 'Enable' : 'Enabled'}
        </Button>
      </Col>
    </Item>
  );
};
UserwayIntegration.defaultProps = {
  data: false,
  isModalItem: false,
  saveProposal: null,
};

UserwayIntegration.propTypes = {
  data: PropTypes.bool,
  isModalItem: PropTypes.bool,
  saveProposal: PropTypes.func,
};

export default UserwayIntegration;
