import _ from 'lodash';

/* eslint-disable array-callback-return */
// Get updated templates by checking all filter conditions
export const getFilteredTemplates = ({ templates }) => {
  let allTemplates = [...templates];

  const filteredTemplates = { all: [], starred: [] };

  allTemplates.forEach((item) => {
    if (item?.starred && item.starred[0] === 'true') {
      filteredTemplates.starred.push(item);
    } else {
      filteredTemplates.all.push(item);
    }
  });

  return filteredTemplates;
};

// Get updated covers by chekcing all filter conditions
export const getFilteredCoversOrMedia = ({
  covers,
  medias,
  selectedCoverType,
  searchValue,
  sortValue,
}) => {
  let allCoversMedias = (covers || medias).sort((a, b) => {
    if (a.uploadedBy === 'user' && b.uploadedBy === 'admin') return -1;
    if (a.uploadedBy === 'admin' && b.uploadedBy === 'user') return 1;
    return 0;
  });

  if (selectedCoverType === 'full-page')
    allCoversMedias = allCoversMedias.filter((coverMedia) => coverMedia.type === 'full-page');
  else allCoversMedias = allCoversMedias.filter((coverMedia) => coverMedia.type !== 'full-page');

  allCoversMedias.sort((a, b) =>
    sortValue === 'asc'
      ? new Date(b.createdAt) - new Date(a.createdAt)
      : new Date(a.createdAt) - new Date(b.createdAt)
  );

  const filteredCoversMedias = { all: [], starred: [] };

  allCoversMedias.forEach((item) => {
    if (item?.starred && item.starred[0] === 'true') {
      filteredCoversMedias.starred.push(item);
    } else {
      filteredCoversMedias.all.push(item);
    }
  });

  filteredCoversMedias.starred = filteredCoversMedias.starred.map((item, index) => ({
    ...item,
    title:
      selectedCoverType === 'top-cover'
        ? `Top cover starred-${index + 1}`
        : `Full cover starred-${index + 1}`,
  }));

  filteredCoversMedias.all = filteredCoversMedias.all.map((item, index) => ({
    ...item,
    title: selectedCoverType === 'top-cover' ? `Top cover-${index + 1}` : `Full cover-${index + 1}`,
  }));

  const filterRegex = (cover) => {
    const searchRegex = new RegExp(_.escapeRegExp(searchValue), 'i');
    if (cover.title && cover.name) {
      return searchRegex.test(cover.title && cover.name);
    } else if (cover.name) {
      return searchRegex.test(cover.name);
    } else if (cover.title) {
      return searchRegex.test(cover.title);
    }
  };

  if (searchValue) {
    filteredCoversMedias.starred = filteredCoversMedias.starred.filter((cover) =>
      filterRegex(cover)
    );
    filteredCoversMedias.all = filteredCoversMedias.all.filter((cover) => filterRegex(cover));
  }

  return filteredCoversMedias;
};

// Get updated sections by chekcing all filter conditions
export const getFilteredSections = ({ sections, searchValue }) => {
  const allSections = sections;
  const filteredSections = { all: [], starred: [] };

  allSections.forEach((item) => {
    if (item?.starred && item.starred[0] === 'true') {
      filteredSections.starred.push(item);
    } else {
      filteredSections.all.push(item);
    }
  });

  if (searchValue) {
    const searchRegex = new RegExp(_.escapeRegExp(searchValue), 'i');

    filteredSections.starred = filteredSections.starred.filter(
      (section) =>
        searchRegex.test(section.title) ||
        searchRegex.test(section.libraryTitle) ||
        searchRegex.test(section.text)
    );

    filteredSections.all = filteredSections.all.filter(
      (section) =>
        searchRegex.test(section.title) ||
        searchRegex.test(section.libraryTitle) ||
        searchRegex.test(section.text)
    );
  }

  return filteredSections;
};
