import React from 'react';
import PropTypes from 'prop-types';

const Link = ({ entityKey, children, contentState, dispatch, className }) => {
  const entity = contentState.getEntity(entityKey);
  const {
    data: { url },
  } = entity;

  let text = '';
  contentState.getBlockMap().forEach((contentBlock) => {
    contentBlock.findEntityRanges(
      (character) => {
        const entity = character.getEntity();
        return entity === entityKey;
      },
      (start, end) => {
        text += contentBlock.getText().slice(start, end);
      }
    );
  });

  const handleLinkClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (entity && entity.getType() === 'LINK') {
      dispatch({ type: 'setLinkUrlValue', linkUrlValue: url });
      dispatch({ type: 'setLinkTextValue', linkTextValue: text });
      dispatch({ type: 'setDisplayLink', displayLink: 'link-options' });
    } else {
      dispatch({ type: 'setLinkUrlValue', linkUrlValue: '' });
      dispatch({ type: 'setLinkTextValue', linkTextValue: '' });
    }
  };

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={`data-link ${className}`}
      onClick={handleLinkClick}
      style={{
        color: '#1890ff',
        textDecoration: 'underline',
      }}>
      {children}
    </a>
  );
};

Link.defaultProps = {
  entityKey: '',
};

Link.propTypes = {
  entityKey: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default Link;
