import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" stroke="#F5F6FA" />
    <path
      d="M20.3705 22.404H17.0345L16.4825 24H14.7185L17.7305 15.612H19.6865L22.6985 24H20.9225L20.3705 22.404ZM19.9145 21.06L18.7025 17.556L17.4905 21.06H19.9145ZM25.0483 24.084C24.7443 24.084 24.4923 23.992 24.2923 23.808C24.1003 23.616 24.0043 23.38 24.0043 23.1C24.0043 22.82 24.1003 22.588 24.2923 22.404C24.4923 22.212 24.7443 22.116 25.0483 22.116C25.3443 22.116 25.5883 22.212 25.7803 22.404C25.9723 22.588 26.0683 22.82 26.0683 23.1C26.0683 23.38 25.9723 23.616 25.7803 23.808C25.5883 23.992 25.3443 24.084 25.0483 24.084Z"
      fill="#4F4E7B"
    />
  </svg>
);

const AlphaBlockIcon = (props) => <Icon component={svg} {...props} />;

export default AlphaBlockIcon;
