import PropTypes from 'prop-types';
import { Empty } from 'antd';

import './NotFound.scss';

const NotFound = ({ description }) => {
  return <Empty className="not-found" description={description} />;
};

NotFound.defaultProps = {
  description: <span>Page Not Found</span>,
};

NotFound.propTypes = {
  description: PropTypes.instanceOf(Object),
};

export default NotFound;
