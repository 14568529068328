import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.25 12L3 8M3 8L7.25 4M3 8L13 8"
      stroke="#05034D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const BackArrowIcon = (props) => <Icon component={svg} {...props} />;

export default BackArrowIcon;
