/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.3816 13.9936C12.8991 13.9936 14.9399 11.9528 14.9399 9.43529C14.9399 6.91779 12.8991 4.87695 10.3816 4.87695C7.86408 4.87695 5.82324 6.91779 5.82324 9.43529C5.82324 11.9528 7.86408 13.9936 10.3816 13.9936Z"
      fill="#3076B0"
    />
    <path
      d="M19.2441 17.4482V42.7378H27.0962V30.2316C27.0962 26.9316 27.717 23.7357 31.8087 23.7357C35.8441 23.7357 35.8941 27.5087 35.8941 30.4399V42.7399H43.7503V28.8712C43.7503 22.0587 42.2837 16.8232 34.3212 16.8232C30.4982 16.8232 27.9357 18.9212 26.8878 20.9066H26.7816V17.4482H19.2441ZM6.44824 17.4482H14.3128V42.7378H6.44824V17.4482Z"
      fill="#3076B0"
    />
  </svg>
);

const LinkedinIcon = (props) => <Icon component={svg} {...props} />;

export default LinkedinIcon;
