import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.7771 11.2057C19.7771 10.6023 19.7236 10.0294 19.632 9.47168H11V12.9169H15.9424C15.7208 14.0474 15.0716 15.0023 14.1091 15.6516V17.9432H17.0576C18.7841 16.3467 19.7771 13.994 19.7771 11.2057Z"
      fill="#4285F4"
    />
    <path
      d="M11.0006 20.1664C13.4756 20.1664 15.5457 19.3414 17.0582 17.9435L14.1096 15.6518C13.2846 16.2018 12.2381 16.538 11.0006 16.538C8.60962 16.538 6.58531 14.9261 5.85962 12.749H2.81934V15.1094C4.3242 18.1039 7.41795 20.1664 11.0006 20.1664Z"
      fill="#34A853"
    />
    <path
      d="M5.85871 12.7496C5.66773 12.1996 5.56842 11.6114 5.56842 11.0004C5.56842 10.3892 5.67537 9.801 5.85871 9.251V6.89062H2.81842C2.19204 8.12812 1.83301 9.51839 1.83301 11.0004C1.83301 12.4822 2.19204 13.8726 2.81842 15.1101L5.85871 12.7496Z"
      fill="#FBBC05"
    />
    <path
      d="M11.0006 5.46148C12.3527 5.46148 13.5596 5.92746 14.5145 6.83649L17.127 4.22398C15.5457 2.74204 13.4756 1.83301 11.0006 1.83301C7.41795 1.83301 4.3242 3.89551 2.81934 6.88996L5.85962 9.2504C6.58531 7.07329 8.60962 5.46148 11.0006 5.46148Z"
      fill="#EA4335"
    />
  </svg>
);

const GoogleIcon = (props) => <Icon component={svg} {...props} />;

export default GoogleIcon;
