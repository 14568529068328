import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Tooltip, message } from 'antd';
import { useIntercom } from 'react-use-intercom';

import CloseIcon from 'components/Icons/CloseIcon';
import EnglishFlagIcon from 'components/Icons/EnglishFlagIcon';
import UploadIcon from 'components/Icons/UploadIcon';
import images from 'constants/images';
import UploadProposal from 'components/UploadProposal/UploadProposal';

import './NewProposalModal.scss';

const NewProposalModal = ({ visible, onCancel, createNewProposal }) => {
  const { trackEvent } = useIntercom();
  const [showUploadModal, setShowUploadModal] = useState(false);

  const onSuccessUpload = ({ docHtmlData, urls, proposalFileType }) => {
    if (docHtmlData) {
      trackEvent('click-upload-external-proposal-doc');
      createNewProposal({
        proposalType: 'external-proposal-doc',
        proposalFile: docHtmlData,
        proposalFileType,
      });
      message.success('Document converted successfully');
    } else {
      trackEvent('click-upload-external-proposal');
      if (urls?.length > 0) {
        createNewProposal({
          proposalType: 'external-proposal',
          proposalFile: urls,
          proposalFileType,
        });
      }
      const fileType =
        proposalFileType === 'pdf' ? 'PDF' : proposalFileType === 'docx' ? 'DOCX' : 'Image';
      message.success(`${fileType} uploaded successfully`);
    }
  };

  const onCancelUpload = () => {
    setShowUploadModal(false);
  };

  const openUploadModal = () => {
    setShowUploadModal(true);
  };

  return (
    <>
      <Modal
        visible={visible && !showUploadModal}
        centered
        footer={null}
        closeIcon={<CloseIcon className="close-icon" />}
        className="dashboard-modal"
        width="762px"
        onCancel={onCancel}>
        <div className="modal-container new-proposal-modal">
          <h1>Select proposal type</h1>
          <div className="color-divider" />
          <p className="sub-heading">You can start from scratch or use a predefined template.</p>
          <div className="type-selector">
            <div
              className="box from-scratch"
              onClick={() => createNewProposal({ proposalType: 'scratch' })}>
              <h2>Start from scratch</h2>
              <img alt="proposal-from-scratch" src={images.PROPOSAL_FROM_SCRATCH} />
              <p className="explainer">
                Start building <b>your own</b> proposal with our wizard
              </p>
            </div>
            <div
              className="box from-template"
              onClick={() => createNewProposal({ proposalType: 'template' })}>
              <Tooltip
                overlayClassName="lang-tooltip"
                title="English only"
                placement="top"
                color="white">
                <EnglishFlagIcon className="lang-info" />
              </Tooltip>
              <h2>Use template</h2>
              <img alt="proposal-from-template" src={images.PAYPAL_FROM_TEMPLATES} />
              <p className="explainer">
                Use our predefined templates from <b>our library</b>
              </p>
            </div>
          </div>
          <div className="modal-container-import">
            <span className="cursor-import" onClick={openUploadModal}>
              <span className="plus-import">
                {' '}
                <UploadIcon />{' '}
              </span>{' '}
              Import Proposal
            </span>
          </div>
        </div>
      </Modal>
      <UploadProposal
        showUploadModal={showUploadModal}
        onCancelUpload={onCancelUpload}
        onSuccessUpload={onSuccessUpload}
      />
    </>
  );
};

NewProposalModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  createNewProposal: PropTypes.func.isRequired,
};

export default NewProposalModal;
