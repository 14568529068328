import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.34961 7.50156L7.35493 6.52363C7.22916 6.39781 7.0885 6.3349 6.93294 6.3349C6.77739 6.3349 6.6385 6.39601 6.51628 6.51823C6.39405 6.64045 6.33294 6.78212 6.33294 6.94323C6.33294 7.10434 6.39343 7.24538 6.51439 7.36635L7.93294 8.7849C8.05112 8.90712 8.189 8.96823 8.34658 8.96823C8.50415 8.96823 8.64405 8.90712 8.76628 8.7849L11.8829 5.66823C12.0052 5.54601 12.0663 5.40434 12.0663 5.24323C12.0663 5.08212 12.0078 4.94045 11.8909 4.81823C11.7634 4.69601 11.6218 4.6349 11.4663 4.6349C11.3107 4.6349 11.1718 4.69601 11.0496 4.81823L8.34961 7.50156ZM5.19961 12.0016C4.86961 12.0016 4.58711 11.8841 4.35211 11.6491C4.11711 11.4141 3.99961 11.1316 3.99961 10.8016V2.80156C3.99961 2.47156 4.11711 2.18906 4.35211 1.95406C4.58711 1.71906 4.86961 1.60156 5.19961 1.60156H13.1996C13.5296 1.60156 13.8121 1.71906 14.0471 1.95406C14.2821 2.18906 14.3996 2.47156 14.3996 2.80156V10.8016C14.3996 11.1316 14.2821 11.4141 14.0471 11.6491C13.8121 11.8841 13.5296 12.0016 13.1996 12.0016H5.19961ZM5.19961 10.8016H13.1996V2.80156H5.19961V10.8016ZM2.79961 14.4016C2.46961 14.4016 2.18711 14.2841 1.95211 14.0491C1.71711 13.8141 1.59961 13.5316 1.59961 13.2016V4.60156C1.59961 4.43156 1.65677 4.28906 1.77109 4.17406C1.88543 4.05906 2.02709 4.00156 2.19609 4.00156C2.3651 4.00156 2.50794 4.05906 2.62461 4.17406C2.74128 4.28906 2.79961 4.43156 2.79961 4.60156V13.2016H11.3996C11.5696 13.2016 11.7121 13.2587 11.8271 13.373C11.9421 13.4874 11.9996 13.629 11.9996 13.798C11.9996 13.9671 11.9421 14.1099 11.8271 14.2266C11.7121 14.3432 11.5696 14.4016 11.3996 14.4016H2.79961Z"
      fill="#05034D"
    />
  </svg>
);

const AddLibraryIcon = (props) => <Icon component={svg} {...props} />;

export default AddLibraryIcon;
