import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"></svg>
);

const PriceIcon = (props) => <Icon component={svg} {...props} />;

export default PriceIcon;
