import { Button, Form, Input, Popover, Row } from 'antd';
import React from 'react';
import { useState } from 'react';
import './RecipientModals.scss';
import PageHeader from 'components/PageHeader';
import * as yup from 'yup';
import { DeleteIcon } from 'components/Icons';

const addRecipientSchema = yup.object().shape({
  firstName: yup.string().optional(),
  lastName: yup.string().optional(),
  email: yup
    .string()
    .email('Invalid email address.')
    .required('Email is required.')
    .test('unique-email', 'Recipient email already exists in list.', function (value) {
      const { path, options } = this; // access current context
      const [currentIndex] = path.match(/\d+/) || []; // extract the index from the path
      const otherItems = options.context.recipients.filter((_, index) => currentIndex ? index !== +currentIndex : true);
      return !otherItems.some((item) => item.email?.toLowerCase() === value?.toLowerCase());
    }),
});
const editRecipientSchema = yup.object().shape({ recipients: yup.array().of(addRecipientSchema) });

export const AddRecipientPopover = ({ onAddRecipient,recipients, onVisibleChange }) => {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    onAddRecipient(values);
    onVisibleChange(false);
  };

  // Handle Enter key to submit the form
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      form.submit(); // Manually trigger form submission on Enter
    }
  };

  const yupAddSync = {
    async validator({ field }, value) {
      await addRecipientSchema.validateAt(field, { [field]: value }, { context:{recipients} });
    },
  };

  return (
    <div className="popover-wrapper">
      <PageHeader pageTitle={'Add Recipients'} />
      <Form form={form} className="add-recipient-form" onFinish={onFinish}>
        <Form.Item
          name="firstName"
          rules={[yupAddSync]}
          colon={false}
          label={
            <Row justify="space-between" className="form-label">
              <span className="label">First Name</span>
            </Row>
          }>
          <Input onKeyDown={handleKeyPress} />
        </Form.Item>
        <Form.Item
          name="lastName"
          rules={[yupAddSync]}
          colon={false}
          label={
            <Row justify="space-between" className="form-label">
              <span className="label">Last Name</span>
            </Row>
          }>
          <Input onKeyDown={handleKeyPress} />
        </Form.Item>
        <Form.Item
          name="email"
          required
          colon={false}
          rules={[yupAddSync]}
          className="email-input"
          label={
            <Row justify="space-between" className="form-label">
              <span className="label">Email Address</span>
            </Row>
          }>
          <Input onKeyDown={handleKeyPress} />
        </Form.Item>
      </Form>
      <Row className="recipient-form-footer-section">
        <Button className="cancel-btn" onClick={onVisibleChange}>
          CANCEL
        </Button>
        <Button className="add-btn" onClick={() => form.submit()}>
          ADD
        </Button>
      </Row>
    </div>
  );
};

export const EditRecipientPopover = ({ recipients, onEditRecipient, onVisibleChange }) => {
  const [form] = Form.useForm();

  const yupEditSync = {
    async validator({ field }, value) {
      const allValues = form.getFieldsValue();
      const keys = field.split('.');
      const lastKey = keys.pop(); // Get the last key
      const lastObject = keys.reduce((acc, part) => acc[part], allValues);
      if (lastObject && lastKey in lastObject) {
        lastObject[lastKey] = value;
      }
      await editRecipientSchema.validateAt(field, allValues,{context:allValues});
    },
  };

  const onFinish = (values) => {
    onEditRecipient(values.recipients);
    onVisibleChange(false);
  };

  return (
    <div className="popover-wrapper">
      <PageHeader pageTitle={'Edit Recipients'} />
      <Form
        style={{ flexDirection: 'column' }}
        form={form}
        className="add-recipient-form edit-recipient-form"
        initialValues={{ recipients }}
        onFinish={onFinish}
        autoComplete="off">
        <Form.List name="recipients">
          {(fields, { remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Row key={key} style={{ gap: '12px' }}  wrap={false}>
                  <Form.Item
                    {...restField}
                    name={[name, 'firstName']}
                    fieldKey={[fieldKey, 'firstName']}
                    rules={[yupEditSync]}
                    colon={false}
                    label={
                      <Row justify="space-between" className="form-label">
                        <span className="label">First Name</span>
                      </Row>
                    }>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'lastName']}
                    fieldKey={[fieldKey, 'lastName']}
                    rules={[yupEditSync]}
                    colon={false}
                    label={
                      <Row justify="space-between" className="form-label">
                        <span className="label">Last Name</span>
                      </Row>
                    }>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'email']}
                    fieldKey={[fieldKey, 'email']}
                    rules={[yupEditSync]}
                    colon={false}
                    dependencies={fields.map(({ name:fieldName }) => ["recipients",fieldName, 'email'])}
                    className="email-input"
                    label={
                      <Row justify="space-between" className="form-label">
                        <span className="label">First Name</span>
                      </Row>
                    }>
                    <Input placeholder="Email" />
                  </Form.Item>
                  {fields.length > 1 && (
                    <DeleteIcon className="delete-icon" onClick={() => remove(name)} />
                  )}
                </Row>
              ))}
            </>
          )}
        </Form.List>
        <Row className="recipient-form-footer-section">
          <Button className="cancel-btn" onClick={onVisibleChange}>
            CANCEL
          </Button>
          <Button className="add-btn" htmlType="submit">
            SAVE
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export const PopoverComponent = ({ icon, content = {} }) => {
  const [visible, setVisible] = useState(false);
  return (
    <Popover
      trigger="click"
      onVisibleChange={(state) => setVisible(state)}
      visible={visible}
      content={
        visible ? (
          React.cloneElement(content, { onVisibleChange: () => setVisible(!visible) })
        ) : (
          <></>
        )
      }>
      {icon && React.cloneElement(icon, { onClick: () => setVisible(!visible) })}
    </Popover>
  );
};
