import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="97" height="80" viewBox="0 0 97 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="96" height="79" rx="3.5" stroke="#E2E3E8" />
  </svg>
);

const EmptyBlockIcon = (props) => <Icon component={svg} {...props} />;

export default EmptyBlockIcon;
