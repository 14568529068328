import React, { useImperativeHandle, forwardRef } from 'react';
import { useQuery } from '@apollo/client';
import { Card, Col, Row } from 'antd';

import helpers from 'helpers';
import FileIcon from 'components/Icons/FileIcon';
import { GET_ALL_MEDIAS_LIBRARIES } from 'graphql/queries/contentLibraryQueries';

import './Medias.scss';

const Medias = forwardRef(
  ({ selectedFolder, saveProposal, dragStart, dragEnd, coverType, handleToolbarElements }, ref) => {
    const { data, refetch: refetchMedias } = useQuery(GET_ALL_MEDIAS_LIBRARIES, {
      fetchPolicy: 'network-only',
      variables: {
        key: selectedFolder,
      },
    });

    useImperativeHandle(ref, () => ({
      async reloadItems() {
        await refetchMedias({
          variables: {
            key: selectedFolder,
          },
        });
      },
    }));

    return (
      <Row gutter={[15, 24]} className="library-medias-container">
        {data &&
          data.fetchContentLibraryMediasItems &&
          data.fetchContentLibraryMediasItems.map((item, itemIndex) =>
            item.src && helpers.isImage(item.src) ? (
              <Col span={12} key={item._id}>
                <Card
                  draggable
                  onDragStart={(event) => dragStart(event, item)}
                  onDragEnd={dragEnd}
                  className="toolbar-library-media"
                  data-src={helpers.formatS3Link(item?.src)}
                  id={`toolbar-library-media-${item._id}`}
                  cover={
                    <div
                      onMouseDown={(e) => handleToolbarElements(e, 'add-media-image', item.src)}
                      style={{
                        backgroundImage: `url("${helpers.formatS3Link(item?.src)}")`,
                        height: '8.5em',
                        backgroundSize: 'cover',
                      }}></div>
                  }
                  actions={[<span>{item.name}</span>]}
                />
              </Col>
            ) : (
              <Col span={12} key={item._id}>
                <Card
                  draggable
                  onDragStart={(event) => dragStart(event, item)}
                  onDragEnd={dragEnd}
                  className="toolbar-library-media"
                  data-media={JSON.stringify(item)}
                  id={`toolbar-library-attachment-media-${item._id}`}
                  cover={
                    <div
                      style={{
                        height: '8.5em',
                      }}
                      className="media-attachment-file">
                      <FileIcon />
                    </div>
                  }
                  actions={[<span>{item.name}</span>]}
                />
              </Col>
            )
          )}
      </Row>
    );
  }
);

export default Medias;
