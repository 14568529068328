/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 6.5L8 10.5L12 6.5"
      stroke="#2F2C88"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CheckDownIcon = (props) => <Icon component={svg} {...props} />;

export default CheckDownIcon;
