import { gql } from '@apollo/client';

export const FETCH_WIX_CONTACTS = gql`
  query FetchWixContacts($offset: Float) {
    fetchWixContacts(offset: $offset) {
      name
      domain
      description
      logo
      instanceId
      currency
      contacts {
        value
        email
      }
      hasNext
    }
  }
`;

export const FETCH_WIX_PRODUCTS_AND_SERVICES_AND_INFO = gql`
  query FetchWixProductsAndServicesAndInfo($instanceId: String) {
    fetchWixProductsAndServices(instanceId: $instanceId) {
      services {
        name
        domain
        instanceId
        description
        logo
        currency
        totalItems
        fields {
          name
          description
          price
        }
      }
      products {
        name
        domain
        instanceId
        description
        logo
        currency
        totalItems
        fields {
          name
          description
          price
        }
      }
    }
  }
`;

export const FETCH_MORE_WIX_PRODUCTS_AND_SERVICES = gql`
  query FetchWixProductsAndServicesAndInfo($instanceId: String, $type: String, $offset: Float) {
    fetchMoreWixProductsAndServices(instanceId: $instanceId, type: $type, offset: $offset) {
      type
      instanceId
      fields {
        name
        description
        price
      }
    }
  }
`;
