import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
    <svg width="24" height="16" viewBox="0 0 24 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 14C21.1 14 21.99 13.1 21.99 12L22 2C22 0.9 21.1 0 20 0H4C2.9 0 2 0.9 2 2V12C2 13.1 2.9 14 4 14H0V16H24V14H20ZM4 2H20V12H4V2Z" fill="#05034D"/>
    </svg>    
);

const DesktopIcon = (props) => <Icon component={svg} {...props} />;

export default DesktopIcon;
