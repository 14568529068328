import React, { useState, useEffect } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import PropTypes from 'prop-types';

import DragIcon from 'components/Icons/DragIcon';
import DescriptionIcon from 'components/Icons/DescriptionIcon';
import DeleteIcon from 'components/Icons/DeleteIcon';
import Input from 'components/Input';

const dropdownMenu = (x, showenDescription, showHideDescription, removeItem) => {
  return (
    <Menu className="tablist-options-dropdown-menu">
      {showenDescription.indexOf(x.id || x.name) === -1 && !x.description && (
        <Menu.Item
          key="1"
          icon={<DescriptionIcon />}
          onClick={() => showHideDescription(x, 'push')}>
          Add Description
        </Menu.Item>
      )}

      {(showenDescription.indexOf(x.id || x.name) !== -1 || x.description) && (
        <Menu.Item key="2" icon={<DescriptionIcon />} onClick={() => showHideDescription(x, 'pop')}>
          Remove Description
        </Menu.Item>
      )}

      <Menu.Item key="3" icon={<DeleteIcon />} onClick={removeItem(x)}>
        Delete
      </Menu.Item>
    </Menu>
  );
};

const Miles = ({
  x,
  editTime,
  removeItem,
  editName,
  showenDescription,
  editDescription,
  showHideDescription,
}) => {
  const [milesValue, setMilesValue] = useState(x.name);
  const [timeValue, setTimeValue] = useState(x.time);
  const [milesDescriptionValue, setMilesDescriptionValue] = useState(x.description);

  useEffect(() => {
    setMilesValue(x.name);
    setTimeValue(x.time);
    setMilesDescriptionValue(x.description);
  }, [x.description, x.time, x.name]); //eslint-disable-line

  const handleMiles = (event) => {
    setMilesValue(event.target.value);
    editName(x)(event);
  };

  const handleEditTime = (event) => {
    setTimeValue(event.target.value);
    editTime(x)(event);
  };

  const handleMilesDescription = (event) => {
    setMilesDescriptionValue(event.target.value);
    editDescription(x)(event);
  };

  return (
    <div className="tablist-sortlist-container">
      <div className="handle">
        <DragIcon />
        <DragIcon />
      </div>
      <div className="tablist-sortlist-content">
        <div className="noselect tablist-input-container">
          <Input
            containerclass="tablist-input miles-input"
            value={milesValue}
            onChange={handleMiles}
            type="textarea"
            autoSize={{ minRows: 1, maxRows: 2 }}
          />

          <Input
            containerclass="tablist-input miles-time"
            value={timeValue}
            onChange={handleEditTime}
            placeholder="i.e - 2 Weeks"
          />

          <Dropdown
            overlay={() => {
              return dropdownMenu(x, showenDescription, showHideDescription, removeItem);
            }}
            trigger={['click']}
            placement="bottomRight">
            <MoreOutlined className="tablist-input-options" onClick={(e) => e.preventDefault()} />
          </Dropdown>
        </div>

        {(showenDescription.indexOf(x.id || x.name) !== -1 || x.description) && (
          <Input
            containerclass="tablist-input-description"
            type="textarea"
            autoSize={{ minRows: 1 }}
            placeholder="Add your description..."
            value={milesDescriptionValue}
            onChange={handleMilesDescription}
            allowClear
          />
        )}
      </div>
    </div>
  );
};

Miles.propTypes = {
  x: PropTypes.instanceOf(Object).isRequired,
  editTime: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  showenDescription: PropTypes.instanceOf(Array).isRequired,
  editName: PropTypes.func.isRequired,
  editDescription: PropTypes.func.isRequired,
  showHideDescription: PropTypes.func.isRequired,
};

export default Miles;
