import React from 'react';
import Icon from '@ant-design/icons';

const svg = ({ color = '#F03F3B' }) => (
  <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.13095 3.75939C5.56838 4.15625 5.56838 4.84375 5.13095 5.24061L2.3692 7.74626C1.72659 8.32927 0.697267 7.87331 0.697267 7.00564L0.697267 1.99436C0.697267 1.12669 1.72659 0.670732 2.3692 1.25375L5.13095 3.75939Z"
      fill={color}
    />
  </svg>
);

const RightIcon = (props) => <Icon component={() => svg(props)} {...props} />;

export default RightIcon;
