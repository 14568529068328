/**
 * <PropsRoute/> is used in place of <Route/>
 * This allows additional props to be passed in
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useHistory } from 'react-router-dom';

const unauthorizedPaths = [
  '/login',
  '/signup',
  '/forgot-password',
  '/reset-password',
  '/update-password',
  '/quickbooks/disconnect',
  '/cd',
  '/proposal-preview',
  '/template-preview',
  '/PDF',
  '/roxy',
  '/download/pdf',
  '/xero',
  '/monday',
  '/wix',
  '/oauth/wix',
  '/wix-app-connect',
];

const checkAuthorized = (path) => {
  let isAuthorizedPath = true;
  for (let i = 0; i < unauthorizedPaths.length; i++) {
    if (path.indexOf(unauthorizedPaths[i]) > -1) {
      isAuthorizedPath = false;
      break;
    }
  }
  return isAuthorizedPath;
};

const renderMergedProps = (component, ...rest) => {
  const redirectUrl = rest[2].location.pathname !== '/login' ? rest[2].location.pathname : '';

  if (rest[1].loggedIn === false && checkAuthorized(rest[1].location.pathname)) {
    return <Redirect to={redirectUrl ? `/login?redirect_url=${redirectUrl}` : '/login'} />;
  } else {
    const finalProps = Object.assign({}, ...rest);
    return React.createElement(component, finalProps);
  }
};

const PropsRoute = ({ component, ...rest }) => {
  const history = useHistory();
  return (
    <Route
      {...rest}
      render={(routeProps) => renderMergedProps(component, routeProps, rest, history)}
    />
  );
};

PropsRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default PropsRoute;
