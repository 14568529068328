import React from 'react';
import PropTypes from 'prop-types';
import { Card, Menu, Dropdown, Button, Input } from 'antd';

import MenuSmallIcon from 'components/Icons/MenuSmallIcon';
import StarIcon from 'components/Icons/StarIcon';
import UnStartIcon from 'components/Icons/UnStarIcon';
import ThumbnailCreator from 'components/ThumbnailCreator';
import helpers from 'helpers';
import {
  PublishSection,
  PublishHeaderSection,
} from 'pages/Proposal/components/Publish/components/PublishContent/components';

import './SectionCard.scss';

const SectionCard = ({
  userId,
  userRole,
  sectionId,
  title,
  isStarred,
  onClickMenuItem,
  onDragStart,
  item,
  sectionUserId,
  sectionAuid,
  itemEditable,
  setEditValue,
}) => {
  const [publishSection] = ThumbnailCreator({ title, elementId: sectionId, type: 'sections' });

  const menu = () => {
    return (
      <Menu>
        <Menu.Item
          onClick={() =>
            onClickMenuItem({
              actionType: itemEditable ? 'save' : 'rename',
              sectionId,
              sectionName: title,
            })
          }>
          {itemEditable ? 'Save' : 'Rename'}
        </Menu.Item>
        <Menu.Item onClick={() => onClickMenuItem({ actionType: 'delete', sectionId })}>
          Delete
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Card draggable="true" onDragStart={onDragStart} className="content-library-card">
      <div className="card-content">
        <div className="hover-items-container">
          <div className="hover-items" data-html2canvas-ignore>
            <div className="buttons">
              <Button
                onClick={() =>
                  onClickMenuItem({ actionType: 'preview', sectionId, sectionData: item })
                }>
                Preview
              </Button>
              {userId === sectionUserId && (
                <Button onClick={() => onClickMenuItem({ actionType: 'edit_section', sectionId })}>
                  Edit Section
                </Button>
              )}
            </div>
          </div>
        </div>
        {item.thumbnail ? (
          <div className="card-description">
            <img
              alt={helpers.formatS3Link(item?.thumbnail)}
              src={helpers.formatS3Link(item?.thumbnail)}
            />
          </div>
        ) : item.isHeader && (!item.thumbnail || item.thumbnail === '') ? (
          <div
            className={`card-description publish-content ${
              item ? `language-${item?.language?.toLowerCase() || 'english'}` : ''
            }`}
            style={{ flexDirection: 'column' }}
            id={sectionId}
            ref={publishSection}>
            <PublishHeaderSection
              proposal={{ draft: { item } }}
              name="item"
              configText={(x) => x}
              ispdf={false}
              isPreview={true}
            />
          </div>
        ) : (
          <div
            className={`card-description publish-content ${
              item ? `language-${item?.language?.toLowerCase() || 'english'}` : ''
            }`}
            style={{ flexDirection: 'column' }}
            id={sectionId}
            ref={publishSection}>
            <PublishSection
              prop={{ draft: { item } }}
              name="item"
              configText={(x) => x}
              ispdf={false}
            />
          </div>
        )}
      </div>

      <div className="card-title">
        {isStarred ? (
          <StarIcon
            className="anticon-star"
            onClick={() =>
              onClickMenuItem({
                actionType: 'starOrUnstar',
                sectionId,
                starred: false,
              })
            }
          />
        ) : (
          <UnStartIcon
            className="anticon-star"
            onClick={() =>
              onClickMenuItem({
                actionType: 'starOrUnstar',
                sectionId,
                starred: true,
              })
            }
          />
        )}
        {itemEditable ? (
          <Input
            defaultValue={title}
            onChange={(e) => setEditValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                onClickMenuItem({ actionType: 'save', sectionId });
              }
            }}
            autoFocus
          />
        ) : (
          <span
            className={`title ${item.language === 'hebrew' ? 'hebrew' : ''}`}
            title={title}
            onDoubleClick={() =>
              userId === sectionUserId || userId === sectionAuid || userRole === 'manager'
                ? onClickMenuItem({ actionType: 'rename', sectionId })
                : {}
            }>
            {title}
          </span>
        )}

        {(item.uid === userId || item.auid === userId || userRole === 'manager') && (
          <Dropdown className="menu-icon" overlay={menu} trigger={['click']}>
            <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              <MenuSmallIcon />
            </a>
          </Dropdown>
        )}
      </div>
    </Card>
  );
};

SectionCard.defaultProps = {
  description: '',
  isStarred: false,
  itemEditable: false,
};

SectionCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  isStarred: PropTypes.bool,
  onClickMenuItem: PropTypes.func.isRequired,
  sectionId: PropTypes.string.isRequired,
  sectionUserId: PropTypes.string.isRequired,
  sectionAuid: PropTypes.string,
  itemEditable: PropTypes.bool,
  setEditValue: PropTypes.func.isRequired,
  userRole: PropTypes.string,
};

export default SectionCard;
