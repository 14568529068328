/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.2" cx="12" cy="12" r="12" fill="#01C44F" />
    <path
      d="M7 12L10.6364 16L17 9"
      stroke="#01C44F"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const SuccessIcon = (props) => <Icon component={svg} {...props} />;

export default SuccessIcon;
