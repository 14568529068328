import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="8" fill="#C7BCE4" />
    <path
      d="M18 10C18 14.4187 14.4187 18 10 18C5.58125 18 2 14.4187 2 10C2 5.58125 5.58125 2 10 2C14.4187 2 18 5.58125 18 10ZM10 3.5C6.40937 3.5 3.5 6.40937 3.5 10C3.5 13.5906 6.40937 16.5 10 16.5C13.5906 16.5 16.5 13.5906 16.5 10C16.5 6.40937 13.5906 3.5 10 3.5Z"
      fill="#C7BCE4"
    />
    <path
      d="M6.64071 10.6129C6.24046 10.6129 5.90041 10.9629 6.0268 11.344C6.53237 12.8823 8.05811 14 9.86372 14C11.6693 14 13.171 12.8823 13.7037 11.344C13.827 10.9629 13.49 10.6129 13.0627 10.6129H6.64071Z"
      fill="#7876C1"
    />
    <path
      d="M7.40207 8.80645C7.93172 8.80645 8.36506 8.40282 8.36506 7.90323C8.36506 7.40363 7.93172 7 7.40207 7C6.86942 7 6.43908 7.40363 6.43908 7.90323C6.43908 8.40282 6.86942 8.80645 7.40207 8.80645Z"
      fill="#7876C1"
    />
    <path
      d="M10.5499 7.74234C10.3242 7.97097 10.3362 8.32661 10.5769 8.54113C10.8207 8.75282 11.2029 8.74153 11.4286 8.51573C11.5941 8.34919 11.8469 8.24194 12.193 8.24194C12.515 8.24194 12.7948 8.34919 12.9573 8.51573C13.186 8.74153 13.5652 8.75282 13.809 8.54113C14.0527 8.32661 14.0648 7.97097 13.8361 7.74234C13.3997 7.30484 12.7497 7.1129 12.193 7.1129C11.6122 7.1129 10.9892 7.30484 10.5499 7.74234Z"
      fill="#7876C1"
    />
  </svg>
);

const FaceWinkIcon = (props) => <Icon component={svg} {...props} />;

export default FaceWinkIcon;
