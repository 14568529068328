import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="9" y="4" width="1" height="7" rx="0.5" transform="rotate(90 9 4)" fill="#05034D" />
    <rect x="9" y="6" width="1" height="7" rx="0.5" transform="rotate(90 9 6)" fill="#05034D" />
    <rect x="6" y="8" width="1" height="4" rx="0.5" transform="rotate(90 6 8)" fill="#05034D" />
    <rect x="0.5" y="0.5" width="10" height="12" rx="1.5" stroke="#05034D" />
  </svg>
);

const DescriptionIcon = (props) => <Icon component={svg} {...props} />;

export default DescriptionIcon;
