import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { notification } from 'antd';
import { useMutation } from '@apollo/client';

import { DISCONNECT_MONDAY } from 'graphql/mutations/integrationMutation';
import IntegrationItem from '../IntegrationItem';
import images from 'constants/images';
import utils from 'utils/utils';
import { userVar, fromAppVar } from 'graphql/cache';

const MondayIntegration = ({ data, isModalItem, userRole, refetchUser, user }) => {
  const [integrationData, setIntegrationData] = useState(data);
  const [loading, setLoading] = useState();
  const [doRefetch, setDoRefetch] = useState(true);

  const mondayConnectionStatus = utils.getQueryStringValue('monday');

  useEffect(() => {
    if (data) {
      setIntegrationData(data);
    }
  }, [data]);

  useEffect(() => {
    async function checkUser() {
      if (mondayConnectionStatus === 'connected' && doRefetch) {
        const userData = await refetchUser();
        if (userData) {
          console.log('connected', userData);
          userVar({ ...user, ...userData });
          setIntegrationData(userData.monday);
          setLoading(Object.keys(userData?.monday).length ? 'connected' : '');
        } else {
          setIntegrationData(user.monday);
          setLoading(Object.keys(user.monday).length ? 'connected' : '');
        }
        setDoRefetch(false);
      } else if (mondayConnectionStatus === 'failed') {
        setLoading('');
      }
    }
    checkUser();
  }, [mondayConnectionStatus, refetchUser, user, doRefetch, data]);

  const handleConnect = async () => {
    const authUrl = `https://auth.monday.com/oauth2/authorize?client_id=${process.env.REACT_APP_MONDAY_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_MONDAY_REDIRECT_URI}`;
    await utils.refetchUserOnClosingTab(authUrl, undefined, true);
    const userData = await refetchUser();
    if (userData) {
      if (userData._id) {
        userVar({ ...user, ...userData });
        setIntegrationData(userData.monday);
        setLoading(Object.keys(userData?.monday).length ? 'connected' : '');
      } else {
        userVar({ ...user, ...userData.data.fetchUser });
        setIntegrationData(userData.data.fetchUser.monday);
        setLoading(Object.keys(userData?.data?.fetchUser?.monday).length ? 'connected' : '');
      }
    } else {
      setIntegrationData(user.monday);
    }
  };

  const handleInstall = async () => {
    const authUrl = process.env.REACT_APP_MONDAY_INSTALL_URI;
    await utils.refetchUserOnClosingTab(authUrl);
    // open popup
  };

  const handleClick = () => {
    if (!integrationData || !Object.keys(integrationData).length) {
      if (fromAppVar() === 'monday') {
        setLoading('connecting');
        return handleConnect();
      } else {
        handleInstall();
      }
    } else {
      setLoading('disconnecting');
      return disconnectMonday({
        variables: {
          connectionIds: ['test'],
        },
      });
    }
  };

  const [disconnectMonday, { loading: isDisconnectingMonday }] = useMutation(DISCONNECT_MONDAY, {
    onCompleted: async () => {
      const userData = await refetchUser();
      if (userData) {
        console.log('disconnected', userData);
        userVar({ ...user, ...userData });
      }
      // setRemoveLoader(false);
      setLoading('');
      notification.success({
        message: 'monday Disconnected',
        description: 'Your monday is now disconnected from Prospero.',
      });
    },
    onError: () => {
      // setRemoveLoader(false);
      notification.error({
        message: ' monday Disconnected Failed',
        description: 'Your account failed to disconnect try again.',
      });
    },
  });

  let loadingStatus = isDisconnectingMonday ? 'disconnecting' : loading ? loading : '';

  return (
    <>
      <IntegrationItem
        type="monday"
        name="monday"
        description="Create proposals for contacts, leads, or from any board in monday"
        integrationLink="https://support.goprospero.com/article/show/167654-how-to-integrate-prospero-with-monday"
        status={
          loadingStatus || // this can be connecting or disconnecting
          (integrationData && Object.keys(integrationData).length ? 'connected' : 'disconnected')
        }
        image={images.MONDAY_ICON}
        handleClick={handleClick}
        isModalItem={isModalItem}
        userRole={userRole}
      />
    </>
  );
};

MondayIntegration.defaultProps = {
  data: null,
  isModalItem: false,
  userRole: '',
  refetchUser: () => {},
};

MondayIntegration.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(null),
  ]),
  isModalItem: PropTypes.bool,
  userRole: PropTypes.string,
  refetchUser: PropTypes.func,
};

export default MondayIntegration;
