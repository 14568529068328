import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
    <path
      d="M7.75593 5.37287C7.35716 4.91241 6.64284 4.91241 6.24407 5.37287L3.23682 8.84535C2.67594 9.49299 3.136 10.5 3.99275 10.5L10.0073 10.5C10.864 10.5 11.3241 9.49299 10.7632 8.84535L7.75593 5.37287Z"
      fill="#2F2C88"
    />
  </svg>
);

const ArrowUpDropdown = (props) => <Icon component={svg} {...props} />;

export default ArrowUpDropdown;
