import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="54" height="56" viewBox="-3 2 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.6697 25.0074C27.2453 26.1918 26.0755 26.0523 26.0755 26.0523L24.4783 26.0528L25.1088 23.7065C25.1088 23.7065 26.1197 23.7116 26.7875 23.7116C27.6049 23.7116 27.9015 24.3608 27.6697 25.0074ZM31.024 23.911C31.2834 21.8574 29.7183 21.0002 27.7645 21.0002H22.6308L19.6006 32.0449H22.8627L23.7799 28.6397L26.3789 28.6556C26.3789 28.6556 30.4038 28.826 31.024 23.911Z"
      fill="#2F3086"
    />
    <path
      d="M12.4075 24.0132H15.3829L14.1575 28.7277C14.1575 28.7277 13.852 29.779 14.8355 29.8601C15.6123 29.9243 16.1904 28.9973 16.3799 28.3693C16.6287 27.5446 17.5959 24.0132 17.5959 24.0132H20.6611L18.3286 32.0375H15.652L15.9811 30.8911C15.9811 30.8911 14.6156 32.5559 12.587 32.3564C10.7837 32.1792 10.6282 30.8712 10.9407 29.2396C11.094 28.4384 12.4075 24.0132 12.4075 24.0132Z"
      fill="#2F3086"
    />
    <path
      d="M33.8011 30.4058C33.2914 30.5353 32.6734 30.6051 32.5508 30.1423C32.2142 28.8733 35.1853 28.5018 35.1853 28.5018C35.2054 29.658 34.1923 30.3063 33.8011 30.4058ZM38.2954 28.0831C38.6875 26.7484 39.1829 25.4844 38.7839 24.7838C38.1733 23.7116 37.0693 23.6174 35.4545 23.6174C33.6703 23.6174 31.4674 23.9564 30.7497 26.3289H33.72C33.72 26.3289 33.9906 25.4353 35.1057 25.4916C36.0925 25.5414 36.0386 26.2205 35.6748 26.5958C35.037 27.2537 33.2755 26.8888 31.3247 27.5656C29.6201 28.1569 29.0237 30.3984 29.3939 31.2929C29.7528 32.1601 30.4212 32.2708 31.3228 32.3594C32.7709 32.5016 33.881 31.6943 34.3979 31.2205C34.3979 31.7466 34.4117 32.0405 34.4117 32.0405H37.5374L37.5298 31.7464C37.5298 31.7464 37.2514 31.6726 37.2825 31.2812C37.3051 30.9946 37.845 29.6162 38.2954 28.0831Z"
      fill="#2F3086"
    />
    <path
      d="M8.20455 24.9822C7.78266 25.9593 6.47488 25.8482 6.47488 25.8482L4.95215 25.8412L5.50826 23.7719C5.50826 23.7719 6.87058 23.777 7.5384 23.777C8.25347 23.777 8.47692 24.3514 8.20455 24.9822ZM11.6652 23.572C11.9244 21.5187 10.1176 21.0002 8.16388 21.0002H3.03026L0 32.0449H3.26208L4.28062 28.295L6.11041 28.3099C6.11041 28.3099 6.86555 28.2659 6.8805 28.9752C6.89641 29.7317 6.31974 31.1209 6.35718 32.0449H9.70826L9.70084 31.7507C9.70084 31.7507 9.42237 31.6769 9.45335 31.2855C9.46627 31.1227 9.64606 30.6077 9.88134 29.9128C10.0231 29.6065 10.2372 28.8799 10.2175 28.2882C10.1927 27.5495 9.72931 27.2068 9.06101 26.9669C11.1444 26.4784 11.6652 23.572 11.6652 23.572Z"
      fill="#2F3086"
    />
    <path
      d="M39.6779 24.0132H42.7083V28.8277L45.3298 24.0132H48.1109L42.5038 33.7469C42.5038 33.7469 41.9834 34.5634 41.3376 34.9954C40.8071 35.3504 40.155 35.3346 39.9557 35.3488C38.8481 35.3393 37.5122 35.3363 37.5122 35.3363L38.1566 33.0161L39.1994 33.0143C39.1994 33.0143 39.6766 32.9656 39.8611 32.7302C40.0367 32.5059 40.1264 32.2816 40.1264 31.9527C40.1264 31.4595 39.6779 24.0132 39.6779 24.0132Z"
      fill="#2F3086"
    />
    <path d="M51.9929 22.155L48.4941 34.326L55.0432 28.2404L51.9929 22.155Z" fill="#098047" />
    <path d="M49.7967 22.155L46.2979 34.326L52.8469 28.2404L49.7967 22.155Z" fill="#F07322" />
  </svg>
);

const RupayIcon = (props) => <Icon component={svg} {...props} />;

export default RupayIcon;
