import { Col, Row } from 'antd';
import HyphenRoundIcon from 'components/Icons/HyphenRoundIcon';

import './SelectedLibraryItems.scss';

const SelectedLibraryItems = ({ selectedItemsArr, removeItem }) => {
  return (
    <div className="selected-items-section">
      <h3>Selected items</h3>
      {selectedItemsArr.length ? (
        <Row gutter={[15, 15]}>
          {selectedItemsArr.map((it) => (
            <Col span={8} className="selected-item">
              <HyphenRoundIcon
                className="remove-icon"
                onClick={(e) => {
                  e.preventDefault();
                  removeItem(it);
                }}
              />
              <img className="item-img" src={it.src} alt={it.name} />
            </Col>
          ))}
        </Row>
      ) : (
        <div className="selected-items-empty">
          <svg
            width="232"
            height="127"
            viewBox="0 0 232 127"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
              x="74.0742"
              y="0.000610352"
              width="84.0315"
              height="84.0315"
              rx="4.56693"
              fill="#C7BCE4"
            />
            <rect
              x="171.715"
              y="34.7093"
              width="60.2835"
              height="60.2835"
              rx="4.56693"
              fill="#C7BCE4"
            />
            <rect
              opacity="0.6"
              x="171.715"
              y="34.7093"
              width="60.2835"
              height="60.2835"
              rx="4.56693"
              fill="url(#paint0_linear)"
            />
            <rect y="34.7093" width="60.2835" height="60.2835" rx="4.56693" fill="#C7BCE4" />
            <rect
              opacity="0.6"
              y="34.7093"
              width="60.2835"
              height="60.2835"
              rx="4.56693"
              fill="url(#paint1_linear)"
            />
            <rect
              opacity="0.6"
              x="73.9844"
              y="0.000610352"
              width="84.0315"
              height="84.0315"
              rx="4.56693"
              fill="url(#paint2_linear)"
            />
            <ellipse
              cx="120.566"
              cy="100.276"
              rx="47.4961"
              ry="6.85039"
              fill="url(#paint3_radial)"
              fillOpacity="0.8"
            />
            <circle
              cx="149.794"
              cy="73.0715"
              r="15.9843"
              stroke="#7876C1"
              strokeWidth="8.22047"
              strokeLinejoin="round"
              strokeDasharray="7.31 7.31"
            />
            <g opacity="0.7" filter="url(#filter0_f)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M147.786 79.0615C147.09 78.8536 146.441 79.5021 146.649 80.1981L156.842 114.321C157.074 115.096 158.124 115.21 158.516 114.502L165.168 102.492L178.433 115.757C178.789 116.113 179.368 116.113 179.724 115.757L183.345 112.136C183.702 111.779 183.702 111.201 183.345 110.844L170.081 97.58L182.09 90.9283C182.798 90.5364 182.684 89.4857 181.909 89.2542L147.786 79.0615Z"
                fill="#7876C1"
              />
            </g>
            <mask
              id="path-10-outside-1"
              maskUnits="userSpaceOnUse"
              x="133.675"
              y="53.985"
              width="68.5894"
              height="68.5894"
              fill="black">
              <rect fill="white" x="133.675" y="53.985" width="68.5894" height="68.5894" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M151.438 70.8411C150.742 70.6332 150.094 71.2816 150.302 71.9777L160.494 106.101C160.726 106.876 161.777 106.99 162.169 106.282L168.821 94.2718L182.085 107.536C182.442 107.893 183.02 107.893 183.377 107.536L186.997 103.915C187.354 103.559 187.354 102.98 186.997 102.624L173.733 89.3596L185.743 82.7079C186.45 82.3159 186.337 81.2653 185.561 81.0337L151.438 70.8411Z"
              />
            </mask>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M151.438 70.8411C150.742 70.6332 150.094 71.2816 150.302 71.9777L160.494 106.101C160.726 106.876 161.777 106.99 162.169 106.282L168.821 94.2718L182.085 107.536C182.442 107.893 183.02 107.893 183.377 107.536L186.997 103.915C187.354 103.559 187.354 102.98 186.997 102.624L173.733 89.3596L185.743 82.7079C186.45 82.3159 186.337 81.2653 185.561 81.0337L151.438 70.8411Z"
              fill="#7876C1"
            />
            <path
              d="M150.302 71.9777L152.927 71.1934L152.927 71.1934L150.302 71.9777ZM151.438 70.8411L152.223 68.2155L152.223 68.2155L151.438 70.8411ZM160.494 106.101L163.12 105.317L163.12 105.317L160.494 106.101ZM162.169 106.282L164.566 107.61L164.566 107.61L162.169 106.282ZM168.821 94.2718L170.758 92.3342L168.186 89.7621L166.424 92.9442L168.821 94.2718ZM183.377 107.536L181.439 105.599L183.377 107.536ZM186.997 103.915L188.935 105.853L186.997 103.915ZM186.997 102.624L188.935 100.686L186.997 102.624ZM173.733 89.3596L172.405 86.9625L169.223 88.725L171.795 91.2972L173.733 89.3596ZM185.743 82.7079L187.07 85.1049L187.07 85.1049L185.743 82.7079ZM185.561 81.0337L186.346 78.4082L186.346 78.4082L185.561 81.0337ZM152.927 71.1934C153.343 72.5855 152.046 73.8824 150.654 73.4666L152.223 68.2155C149.438 67.3839 146.845 69.9778 147.676 72.7619L152.927 71.1934ZM163.12 105.317L152.927 71.1934L147.676 72.7619L157.869 106.885L163.12 105.317ZM159.771 104.954C160.555 103.539 162.657 103.766 163.12 105.317L157.869 106.885C158.795 109.986 162.998 110.441 164.566 107.61L159.771 104.954ZM166.424 92.9442L159.771 104.954L164.566 107.61L171.218 95.5995L166.424 92.9442ZM184.022 105.599L170.758 92.3342L166.883 96.2094L180.147 109.474L184.022 105.599ZM181.439 105.599C182.152 104.885 183.309 104.885 184.022 105.599L180.147 109.474C181.574 110.9 183.887 110.9 185.314 109.474L181.439 105.599ZM185.06 101.978L181.439 105.599L185.314 109.474L188.935 105.853L185.06 101.978ZM185.06 104.561C184.346 103.848 184.346 102.691 185.06 101.978L188.935 105.853C190.362 104.426 190.362 102.113 188.935 100.686L185.06 104.561ZM171.795 91.2972L185.06 104.561L188.935 100.686L175.671 87.422L171.795 91.2972ZM184.415 80.3108L172.405 86.9625L175.061 91.7566L187.07 85.1049L184.415 80.3108ZM184.777 83.6592C183.227 83.1961 182.999 81.0948 184.415 80.3109L187.07 85.1049C189.901 83.5369 189.446 79.3344 186.346 78.4082L184.777 83.6592ZM150.654 73.4666L184.777 83.6592L186.346 78.4082L152.223 68.2155L150.654 73.4666Z"
              fill="white"
              mask="url(#path-10-outside-1)"
            />
            <defs>
              <filter
                id="filter0_f"
                x="139.302"
                y="71.715"
                width="51.6166"
                height="51.6162"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="3.65354" result="effect1_foregroundBlur" />
              </filter>
              <linearGradient
                id="paint0_linear"
                x1="228.801"
                y1="34.7093"
                x2="173.998"
                y2="94.9928"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#7876C1" />
                <stop offset="1" stopColor="#C7BCE4" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint1_linear"
                x1="57.0866"
                y1="34.7093"
                x2="2.28346"
                y2="94.9928"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#7876C1" />
                <stop offset="1" stopColor="#C7BCE4" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint2_linear"
                x1="153.56"
                y1="0.000611357"
                x2="77.1674"
                y2="84.0321"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#7876C1" />
                <stop offset="1" stopColor="#C7BCE4" stopOpacity="0" />
              </linearGradient>
              <radialGradient
                id="paint3_radial"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(120.566 100.276) rotate(90) scale(6.85039 47.4961)">
                <stop stopColor="#C7BCE4" />
                <stop offset="1" stopColor="#C7BCE4" stopOpacity="0" />
              </radialGradient>
            </defs>
          </svg>
        </div>
      )}
    </div>
  );
};

export default SelectedLibraryItems;
