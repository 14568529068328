import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" stroke="#F5F6FA" />
    <path
      d="M15 19.8095L18.7037 24L25 16"
      stroke="#4F4E7B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CheckBlockIcon = (props) => <Icon component={svg} {...props} />;

export default CheckBlockIcon;
