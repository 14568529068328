import React, { useState, useEffect } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import PropTypes from 'prop-types';

import DragIcon from 'components/Icons/DragIcon';
import DescriptionIcon from 'components/Icons/DescriptionIcon';
import DeleteIcon from 'components/Icons/DeleteIcon';
import Input from 'components/Input';

const dropdownMenu = (x, showenDescription, showHideDescription, removeItem) => {
  return (
    <Menu className="tablist-options-dropdown-menu">
      {showenDescription.indexOf(x.name || x.id) === -1 && !x.description && (
        <Menu.Item
          key="1"
          icon={<DescriptionIcon />}
          onClick={() => showHideDescription(x, 'push')}>
          Add Description
        </Menu.Item>
      )}

      {(showenDescription.indexOf(x.name || x.id) !== -1 || x.description) && (
        <Menu.Item key="2" icon={<DescriptionIcon />} onClick={() => showHideDescription(x, 'pop')}>
          Remove Description
        </Menu.Item>
      )}

      <Menu.Item key="3" icon={<DeleteIcon />} onClick={removeItem(x)}>
        Delete
      </Menu.Item>
    </Menu>
  );
};

const Super = ({
  x,
  removeItem,
  editSuper,
  showenDescription,
  editDescription,
  superTransform,
  showHideDescription,
}) => {
  const [superValue, setSuperValue] = useState(
    x.super && typeof x.super.text === 'string' ? x.super.text : superTransform(x.name)
  );
  const [superDescriptionValue, setSuperDescriptionValue] = useState(x.description);

  useEffect(() => {
    setSuperDescriptionValue(x.description);
    setSuperValue(
      x.super && typeof x.super.text === 'string' ? x.super.text : superTransform(x.name)
    );
  }, [x.description, x.super.text]); //eslint-disable-line

  const handleSuper = (event) => {
    setSuperValue(event.target.value);
    editSuper(x)(event);
  };

  const handleSuperDescription = (event) => {
    setSuperDescriptionValue(event.target.value);
    editDescription(x)(event);
  };

  return (
    <div className="tablist-sortlist-container">
      <div className="handle">
        <DragIcon />
        <DragIcon />
      </div>
      <div className="tablist-sortlist-content">
        <div className="noselect tablist-input-container">
          <Input
            containerclass="tablist-input super-textarea"
            value={superValue}
            onChange={handleSuper}
            type="textarea"
            label={x.name}
            autoSize={{ minRows: 1, maxRows: 6 }}
          />

          <Dropdown
            overlay={() => {
              return dropdownMenu(x, showenDescription, showHideDescription, removeItem);
            }}
            trigger={['click']}
            placement="bottomRight">
            <MoreOutlined className="tablist-input-options" onClick={(e) => e.preventDefault()} />
          </Dropdown>
        </div>

        {(showenDescription.indexOf(x.name || x.id) !== -1 || x.description) && (
          <Input
            containerclass="tablist-input-description"
            type="textarea"
            autoSize={{ minRows: 1 }}
            placeholder="Add your description..."
            value={superDescriptionValue}
            onChange={handleSuperDescription}
            allowClear
          />
        )}
      </div>
    </div>
  );
};

Super.propTypes = {
  x: PropTypes.instanceOf(Object).isRequired,
  removeItem: PropTypes.func.isRequired,
  showenDescription: PropTypes.instanceOf(Array).isRequired,
  editSuper: PropTypes.func.isRequired,
  editDescription: PropTypes.func.isRequired,
  showHideDescription: PropTypes.func.isRequired,
  superTransform: PropTypes.func.isRequired,
};

export default Super;
