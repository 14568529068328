import { useState, useEffect } from 'react';
import _ from 'lodash';

const findScreenSize = ({ width }) => {
  if (width < 576) return 'xs';
  if (width >= 1600) return 'xxl';
  if (width >= 1200) return 'xl';
  if (width >= 992) return 'lg';
  if (width >= 768) return 'md';
  if (width >= 576) return 'sm';
};

function getWindowDimensions(props) {
  const { innerWidth: width, innerHeight: height } = window;
  const isIPad =
    /Macintosh/i.test(navigator.userAgent) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 1;
  return {
    width: props?.customWidth || width,
    height: props?.customHeight || height,
    isMobile:
      window.navigator.userAgent.match(/Android/i) ||
      window.navigator.userAgent.match(/webOS/i) ||
      window.navigator.userAgent.match(/iPhone/i) ||
      window.navigator.userAgent.match(/iPad/i) ||
      window.navigator.userAgent.match(/iPod/i) ||
      window.navigator.userAgent.match(/BlackBerry/i) ||
      window.navigator.userAgent.match(/Windows Phone/i) ||
      isIPad ||
      width < 767,
    allowedEditing: width >= 360,
    screenSize: findScreenSize({ width: props?.customWidth || width }),
  };
}

export default function useWindowDimensions(props) {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions(props));

  useEffect(() => {
    const debouncedResizeHandler = _.debounce(() => {
      setWindowDimensions(getWindowDimensions());
    }, 100); // 100ms

    window.addEventListener('resize', debouncedResizeHandler);
    return () => window.removeEventListener('resize', debouncedResizeHandler);
  }, []);

  return windowDimensions;
}
