/* eslint-disable */
import React, { useState, useEffect, useReducer, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Col, Row } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Prelist, ChoosenTotals } from './components';
import { userVar } from 'graphql/cache';
import helpers from 'helpers/proposal';
import DraftContext from 'context/DraftContext';
import DoubleArrowIcon from 'components/Icons/DoubleArrowIcon';
import useWindowDimensions from 'hooks/useWindowDimensions';

import './Tablist.scss';

const initialState = {
  chosen: '',
  stab: '',
  optionsDropdown: false,
  selectedValue: null,
  totalValue: 0,
  optionsDeleteModal: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setChosen':
      return { ...state, chosen: typeof action.chosen === 'string' ? {} : action.chosen };
    case 'setStab':
      if (action.reset) {
        const tempState = JSON.parse(JSON.stringify(state));
        if (Object.keys(tempState.chosen).length > 0) {
          const chosenData = tempState.chosen[action.stab];
          if (chosenData.discount) {
            chosenData.discount.val = 0;
          }
          if (chosenData.tax) {
            chosenData.tax.val = 0;
          }
          (tempState.chosen[action.stab] = chosenData), (tempState.stab = action.stab);
        }
        return tempState;
      } else {
        return { ...state, stab: action.stab };
      }
    case 'setOptionsDropdown':
      return { ...state, optionsDropdown: action.optionsDropdown };
    case 'setSelectedValue':
      return { ...state, selectedValue: action.selectedValue };
    case 'setTotalValue':
      return { ...state, totalValue: action.totalValue };
    case 'setOptionsDeleteModal':
      return { ...state, optionsDeleteModal: action.optionsDeleteModal };
    default:
      throw new Error('Unexpected action');
  }
};

const Tablist = ({
  prop,
  propsChosen,
  tabType,
  price,
  superVal,
  currency,
  tabs,
  config,
  prelist,
  transform,
  onChange,
  editstrategy,
  save,
  saveUser,
  setDelivsRegex,
  setSuperRegex,
  setMilesRegex,
  isWizard,
  action,
}) => {
  const { width } = useWindowDimensions();

  const [state, dispatch] = useReducer(reducer, initialState);

  const previousPriceType = useRef(price);
  const previousTabs = useRef(tabs);
  const previousChosen = useRef(propsChosen);

  const user = useReactiveVar(userVar);
  const [collapse, setCollapse] = useState(width < 1200);

  const configText = (x, disableTrim) => {
    return helpers.configText({
      x,
      notmarked: true,
      config,
      prop,
      user: user,
      vars: {},
      disableTrim,
    });
  };

  const handleUndo = (e) => {
    if (e.ctrlKey && e.key === 'z' && state.undoOption) {
      dispatch({ type: 'setChosen', chosen: state.undoOption });
      dispatch({ type: 'undoOption', undoOption: null });
    }
  };

  useEffect(() => {
    console.log('loger width', width);
    setCollapse(width < 1200);
  }, [width]);

  useEffect(() => {
    document.addEventListener('keydown', handleUndo);
  });

  useEffect(() => {
    const defaultChosen = propsChosen || helpers.getDefaultOptions({ prelist, config, prop });
    // switch if tax is type -1 or the other way
    // datautils.taxdisfix(defaultChosen);

    _.each(defaultChosen, (v) => {
      if (v.discount && !v.discount.type) {
        if (v.discount.val < 0) {
          v.discount.val = Math.abs(v.discount.val);
          v.discount.type = -1;
        } else {
          v.discount.type = 1;
        }
      }
    });

    if (
      JSON.stringify(defaultChosen) !== JSON.stringify(previousChosen) &&
      Object.keys(defaultChosen).length
    ) {
      const stabKeys = Object.keys(defaultChosen);

      dispatch({
        type: 'setStab',
        stab: state.stab && stabKeys.indexOf(state.stab) > -1 ? state.stab : stabKeys[0],
      });
      dispatch({
        type: 'setTotalValue',
        totalValue: defaultChosen[Object.keys(defaultChosen)[0]].total,
      });
    }

    if (
      previousPriceType !== price &&
      previousTabs !== tabs &&
      state.stab &&
      state.chosen &&
      state.chosen[state.stab]
    ) {
      dispatch({
        type: 'setSelectedValue',
        stab: state.chosen[state.stab].type,
      });
    }
  }, [prelist]);

  useEffect(() => {
    if (state.chosen === '' || Object.keys(state.chosen).length === 0 || isWizard) {
      dispatch({ type: 'setChosen', chosen: propsChosen });
    }
  }, [propsChosen]);

  useEffect(() => {
    if (!action) return;

    if (action === 'reset-super-choosen') {
      // applicable only for super section
      state.chosen['OPTION 1'].list = [];
    } else if (action === 'reset-price-choosen') {
      // applicable for price section
      const keys = Object.keys(state.chosen);
      keys.map((key) => {
        state.chosen[key].list = [];
      });
    } else if (action === 'reset-miles-choosen') {
      // applicable only for miles section
      state.chosen = {};
    }
  }, [action]);

  const listChanged = (t, list) => {
    const chosen = _.merge({}, state.chosen);
    chosen[t].list = list;

    onChange(chosen);
    dispatch({ type: 'setChosen', chosen });
  };

  const handleState = (type, value, typeValue) => {
    switch (type) {
      case 'delivs':
        setDelivsRegex(value);
        break;
      case 'miles':
        setMilesRegex(value);
        break;
      case 'super':
        setSuperRegex(value);
        break;
      case 'currencies':
        break;
      default:
        return;
    }

    if (typeValue) {
      const userConfig = JSON.parse(JSON.stringify(user.config)) || {};
      let newValue;

      if (type === 'super') {
        newValue = userConfig && userConfig[type] ? userConfig[type] : {};

        newValue[typeValue?.replace(/\./g, '')] = {
          name: typeValue,
          editable: true,
        };
      } else if (type === 'currencies') {
        newValue = userConfig?.currencies || [];

        // remove or add
        if (value === 'remove') {
          // typeValue is array
          newValue = newValue.filter((item) => !typeValue.includes(item));
        } else {
          const isExists = newValue.some((item) => item === typeValue);
          if (isExists) return;

          newValue.push(typeValue);
        }
      } else {
        newValue = userConfig && userConfig[type] ? userConfig[type] : [];
        if (!newValue.find((newVal) => newVal.name === typeValue))
          newValue.push({ name: typeValue, editable: true });
      }

      userConfig[type] = newValue;

      saveUser({
        variables: {
          user: {
            id: user._id,
            config: userConfig,
          },
        },
      });
    }
  };

  const addItem = (x, desc = '', price = 0, text) => {
    return (notFromList, tempChosen) => {
      if (x) {
        let chosen = _.merge({}, tempChosen);
        if (superVal && notFromList === true) {
          chosen[state.stab].list.push({
            id: uuidv4(),
            name: x,
            super: { text: x, type: null },
          });
        } else if (chosen[state.stab]) {
          chosen[state.stab].list.push({
            id: uuidv4(),
            name: x,
            description: desc,
            price: price,
            type: null,
            // text for super
            ...(text ? { text } : {}),
          });
        } else if (!Object.keys(chosen).length) {
          chosen = {
            [state.stab]: {
              total: '',
              list: [
                {
                  id: uuidv4(),
                  name: x,
                  description: desc,
                  price: price,
                  type: null,
                },
              ],
            },
          };
        }

        onChange(chosen);
        dispatch({ type: 'setChosen', chosen });
      }
    };
  };

  const removeItem = (name) => {
    const userConfig = JSON.parse(JSON.stringify(user.config));
    let newValue;

    if (tabType === 'super') {
      newValue = userConfig && userConfig[tabType] ? userConfig[tabType] : {};
      delete newValue[name];
    } else {
      newValue = userConfig && userConfig[tabType] ? userConfig[tabType] : [];
      newValue = newValue.filter((value) => value.name !== name);
    }

    userConfig[tabType] = newValue;
    saveUser({
      variables: {
        user: {
          id: user._id,
          config: userConfig,
        },
      },
    });
  };

  const addNewItem = (value) => {
    addItem(value)(true, state.chosen);
    handleState(tabType, '', value);
  };

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="tablist-container">
      <Col span={24} className={`tablist-prelist-container ${collapse ? 'inactive' : 'active'}`}>
        <div className="sidebar-opener" onClick={() => setCollapse(!collapse)}>
          <DoubleArrowIcon />
        </div>

        <DraftContext.Consumer>
          {({ fetchMoreWixProductsAndServices, fetchMoreWixProductsAndServicesLoading }) => (
            <Prelist
              prelist={JSON.parse(JSON.stringify(prelist))}
              chosen={state.chosen}
              tabType={tabType}
              addItem={addItem}
              removeItem={removeItem}
              addNewItem={addNewItem}
              handleState={handleState}
              wixConnectedInstance={prop.wixConnectedInstance}
              fetchMoreWixProductsAndServicesLoading={fetchMoreWixProductsAndServicesLoading}
              fetchMoreWixProductsAndServices={fetchMoreWixProductsAndServices}
            />
          )}
        </DraftContext.Consumer>
      </Col>
      <ChoosenTotals
        isWizard={isWizard}
        currency={currency}
        state={state}
        dispatch={(dispatchOptions) => {
          if (dispatchOptions.type === 'setChosen')
            onChange(dispatchOptions.chosen, dispatchOptions.orderSave);
          dispatch(dispatchOptions);
        }}
        chosen={state.chosen}
        transform={transform}
        tabType={tabType}
        price={price}
        editstrategy={editstrategy}
        tabs={tabs}
        save={save}
        configText={configText}
        listChanged={listChanged}
        prop={prop}
        handleState={handleState}
        saveUser={saveUser}
      />
    </Row>
  );
};

Tablist.defaultProps = {
  propsChosen: '',
  tabs: false,
  editstrategy: '',
  currency: '',
  save: () => {},
  setDelivsRegex: () => {},
  setSuperRegex: () => {},
  setMilesRegex: () => {},
  superVal: false,
  price: false,
  transform: () => {},
  isWizard: false,
  action: '',
};

Tablist.propTypes = {
  prop: PropTypes.instanceOf(Object).isRequired,
  propsChosen: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  price: PropTypes.bool,
  tabs: PropTypes.bool,
  config: PropTypes.instanceOf(Object).isRequired,
  prelist: PropTypes.instanceOf(Array).isRequired,
  transform: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  tabType: PropTypes.string.isRequired,
  editstrategy: PropTypes.string,
  currency: PropTypes.string,
  save: PropTypes.func,
  setDelivsRegex: PropTypes.func,
  setSuperRegex: PropTypes.func,
  setMilesRegex: PropTypes.func,
  superVal: PropTypes.bool,
  isWizard: PropTypes.bool,
  action: PropTypes.string,
};

export default Tablist;
