import { gql } from '@apollo/client';

export const FETCH_TEAM_MEMBERS = gql`
  query FetchTeamMembers($teamId: String) {
    fetchTeamMembers(teamId: $teamId) {
      _id
      emails {
        address
        verified
      }
      profile {
        name
        image
      }
      props
      createdAt
      roles
      domain
      createdAt
      sharedTemplates
    }
  }
`;
