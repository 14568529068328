/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.9108 43.7439V26.6689H33.6712L34.5275 19.9835H27.9108V15.7252C27.9108 13.796 28.4483 12.4752 31.2171 12.4752H34.7254V6.51477C33.0191 6.33144 31.3025 6.24394 29.5858 6.25019C24.4941 6.25019 20.9983 9.35852 20.9983 15.0648V19.971H15.2754V26.6564H21.0108V43.7439H27.9108Z"
      fill="#4488F7"
    />
  </svg>
);

const FacebookIcon = (props) => <Icon component={svg} {...props} />;

export default FacebookIcon;
