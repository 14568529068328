import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Input, notification } from 'antd';
import { useMutation } from '@apollo/client';

import {
  CREATE_MORNING_INVOICE_APPROVAL_REQUEST,
  DISCONNECT_MORNING_INVOICE,
} from 'graphql/mutations/integrationMutation';
import IntegrationItem from '../IntegrationItem';
import images from 'constants/images';
import utils from 'utils/utils';
import CloseIcon from 'components/Icons/CloseIcon';

import './MorningIntegration.scss';

const MorningIntegration = ({ data, isModalItem, userRole, handleRefetchUser, user }) => {
  const [integrationData, setIntegrationData] = useState(data);
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [connectionEmail, setConnectionEmail] = useState(user?.emails?.[0]?.address);
  const [loading, setLoading] = useState();

  const morningInvoiceConnectionStatus = utils.getQueryStringValue('morninginvoice');

  useEffect(() => {
    if (data) {
      setIntegrationData(data);
    }
  }, [data]);

  const checkUser = async () => {
    if (morningInvoiceConnectionStatus === 'connected') {
      handleRefetchUser();
      setLoading('connected');
    } else if (morningInvoiceConnectionStatus === 'failed') {
      setLoading('disconnected');
    }
  };

  useEffect(() => {
    checkUser();
  }, [morningInvoiceConnectionStatus]); //eslint-disable-line

  const [disconnectMorningInvoice, { loading: isDisconnectingMorningInvoice }] = useMutation(
    DISCONNECT_MORNING_INVOICE,
    {
      onCompleted: async () => {
        setLoading('');
        handleRefetchUser();
        notification.success({
          message: 'morning Disconnected',
          description: 'Your account has been disconnected from morning.',
        });
      },
      onError: () => {
        setLoading('');
        notification.error({
          message: 'morning Disconnect Failed',
          description: 'Your account failed to disconnect try again.',
        });
      },
    }
  );

  const [createMorningInvoiceApprovalRequest, { loading: isMorningInvoiceAuthUrlLoading }] =
    useMutation(CREATE_MORNING_INVOICE_APPROVAL_REQUEST, {
      onCompleted: () => {
        handleRefetchUser();
        setLoading('');
        setShowConnectModal(false);
        notification.success({
          message: 'morning connection request sent',
          description: 'Check your mail account',
        });
      },
      onError: () => {
        setLoading('');
        notification.error({
          message: 'morning connection request failed',
          description: 'Only enter morning account address',
        });
      },
    });

  const handleConnect = () => {
    setLoading('connecting');
    createMorningInvoiceApprovalRequest({
      variables: {
        email: connectionEmail,
      },
    });
  };

  const handleClick = async () => {
    if (!integrationData || !integrationData?.id) {
      if (integrationData?.expiresIn && new Date(integrationData?.expiresIn) > new Date()) {
        setLoading('connecting');
        const refetchedUser = await handleRefetchUser();
        if (refetchedUser?.morninginvoice?.expiresIn) {
          notification.error({
            message: 'Morning connection request',
            description: 'Connection request already sent, please check your mail account',
          });
        } else if (refetchedUser?.morninginvoice?.id) {
          notification.success({
            message: 'Morning Connected',
            description: 'User connected successfully',
          });
        }
        return setLoading('');
      } else {
        return setShowConnectModal(true);
      }
    }
    setLoading('disconnecting');
    disconnectMorningInvoice();
  };

  let loadingStatus = isMorningInvoiceAuthUrlLoading
    ? 'connecting'
    : isDisconnectingMorningInvoice
    ? 'disconnecting'
    : loading
    ? loading
    : '';

  return (
    <>
      <IntegrationItem
        type="morninginvoice"
        name="morning"
        description="Import contacts & generate invoices with a click"
        integrationLink="https://support.goprospero.com/article/show/155215-how-to-integrate-prospero-with-morning"
        status={
          loadingStatus || // this can be connecting or disconnecting
          (integrationData?.id ? 'connected' : 'disconnected')
        }
        image={images.MORNING_INVOICE_ICON}
        handleClick={handleClick}
        isModalItem={isModalItem}
        userRole={userRole}
      />

      {showConnectModal && (
        <Modal
          zIndex={1009}
          className={'confirm-modal morninginvoice-connect-modal'}
          centered
          visible={showConnectModal}
          onCancel={() => setShowConnectModal(false)}
          closeIcon={<CloseIcon className="close-icon" />}
          closable={true}
          showIcon={true}
          footer={null}>
          <div className="title">morning connection address</div>

          <div className="divider" />

          <div className="body">
            <Input
              placeholder="Enter email address"
              value={connectionEmail}
              onChange={(event) => setConnectionEmail(event.target.value)}
            />
          </div>

          <div className="button-wrapper">
            <Button
              className="button confirm"
              type="primary"
              onClick={handleConnect}
              disabled={!connectionEmail}>
              CONNECT
            </Button>
            <Button
              className="button cancel"
              type="primary"
              onClick={() => setShowConnectModal(false)}>
              CANCEL
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

MorningIntegration.defaultProps = {
  data: null,
  isModalItem: false,
  userRole: '',
  handleRefetchUser: () => {},
};

MorningIntegration.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(null),
  ]),
  isModalItem: PropTypes.bool,
  userRole: PropTypes.string,
  handleRefetchUser: PropTypes.func,
};

export default MorningIntegration;
