// import React, { useState } from 'react';
import { Modal, Tabs } from 'antd';
import PropTypes from 'prop-types';

import CloseIcon from 'components/Icons/CloseIcon';

import './KeyboardShortcutModal.scss';
import { Fragment } from 'react';

const KeyboardShortcutModal = ({ visible, handleCancel }) => {
  const isMac = navigator.userAgent.toLowerCase().includes('mac');

  const commands = {
    Basic: [
      { keys: ['Ctrl', 'C'], value: 'Copy' },
      { keys: ['Ctrl', 'V'], value: 'Paste' },
      { keys: ['Ctrl', 'Z'], value: 'Undo' },
      { keys: ['Ctrl', 'Y'], value: 'Redo' },
      { keys: ['Ctrl', 'A'], value: 'Select all' },
      { keys: ['Ctrl', 'S'], value: 'Save' },
      { keys: ['Esc'], value: 'Close modals' },
      { keys: ['Ctrl', 'Shift', 'S'], value: 'Show/Hide shortcut' },
    ],
    Formatting: [
      { keys: ['Tab'], value: 'Move selected paragraph one cell right (left for RTL)' },
      {
        keys: ['Shift', 'Tab'],
        value: 'Move selected paragrap one cell left (right for RTL)',
      },

      { keys: ['Ctrl', 'B'], value: 'Bold' },
      { keys: ['Ctrl', 'U'], value: 'Underline' },
      { keys: ['Ctrl', 'I'], value: 'Italic' },
      { keys: ['Ctrl', 'Shift', 'X'], value: 'Strikethrough' },

      { keys: ['Ctrl', 'Shift', 'E'], value: 'Center align' },
      { keys: ['Ctrl', 'Shift', 'J'], value: 'Justify' },
      { keys: ['Ctrl', 'Shift', 'L'], value: 'Left align' },
      { keys: ['Ctrl', 'Shift', 'R'], value: 'Right align' },

      { keys: ['Ctrl', 'K'], value: 'Insert link' },

      { keys: ['Delete'], value: 'Delete next character' },
      { keys: ['Ctrl', 'Delete'], value: 'Delete next word' },

      { keys: ['Ctrl', '\\'], value: 'Clear formatting' },
    ],
    Selection: [
      { keys: ['Shift', ['↑', '↓']], value: 'Extend selection line' },
      { keys: ['Shift', ['→', '←']], value: 'Extend selection character' },
      { keys: ['Ctrl', 'Shift', ['→', '←']], value: 'Extend selection word' },

      // { keys: ['Ctrl', 'Shift', '↑'], value: 'Extend selection to starting of paragraph' },
      // { keys: ['Ctrl', 'Shift', '↓'], value: 'Extend selection to ending of paragraph' },

      { keys: ['Shift', 'Page ↑'], value: 'Select a frame of text above the cursor.' },
      { keys: ['Shift', 'Page ↓'], value: 'Select a frame of text below the cursor.' },

      {
        keys: ['Shift', 'Home'],
        value: 'Select the text between the cursor and the beginning of the current line.',
      },
      {
        keys: ['Shift', 'End'],
        value: 'Select the text between the cursor and the end of the current line.',
      },
      {
        keys: ['Ctrl', 'Shift', 'Home'],
        value: 'Select the text between the cursor and the beginning of the text entry field.',
      },
      {
        keys: ['Ctrl', 'Shift', 'End'],
        value: ' Select the text between the cursor and the end of the text entry field.',
      },
    ],
    'Cursor navigation': [
      {
        keys: ['Home'],
        value: 'Move cursor to beginning of current line',
      },
      {
        keys: ['End'],
        value: 'Move cursor to end of current line.',
      },
      {
        keys: ['Ctrl', 'Home'],
        value: 'Move cursor to top of the text entry field.',
      },
      {
        keys: ['Ctrl', 'End'],
        value: 'Move cursor to bottom of the text entry field.',
      },

      { keys: ['Ctrl', '→'], value: 'Move cursor to beginning of next word.' },
      { keys: ['Ctrl', '←'], value: 'Move cursor to beginning of current word.' },
      {
        keys: ['Ctrl', '↑'],
        value:
          'Jump the cursor to previous paragraph if exists, else starting of the current paragraph.',
      },
      {
        keys: ['Ctrl', '↓'],
        value: 'Jump the cursor to next paragraph if exists, else ending of the current paragraph.',
      },
    ],
    // Others: [

    // ],
  };

  return (
    <Modal
      className="confirm-modal keyboard-shortcut-modal"
      visible={visible}
      closeIcon={<CloseIcon className="close-icon" />}
      getContainer={false}
      onCancel={handleCancel}
      footer={null}>
      <h3 className="title">Keyboard Shortcuts</h3>
      <div className="divider" />

      <div className="body">
        <Tabs>
          {Object.keys(commands).map((tab) => (
            <Tabs.TabPane key={tab} tab={tab}>
              {commands[tab].map((c, i) => (
                <CommanDisplay key={i} {...c} isMac={isMac} />
              ))}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    </Modal>
  );
};

KeyboardShortcutModal.defaultProps = {
  visible: false,
};

KeyboardShortcutModal.propTypes = {
  visible: PropTypes.bool,
  handleCancel: PropTypes.func,
};

export default KeyboardShortcutModal;

function CommanDisplay({ keys, value, isMac }) {
  if (isMac) {
    const notInMacKeys = ['Home', 'End', 'Page ↑', 'Page ↓', 'Delete'];
    const exclude = keys.find((k) => notInMacKeys.includes(k));

    if (exclude) {
      return null;
    }
  }

  return (
    <div className="command-display">
      {keys.map((btn, index) => (
        <Fragment key={index}>
          <CommandKey btn={btn} isMac={isMac} />

          {index + 1 === keys.length ? (
            <span className="key-equal">=</span>
          ) : (
            <span className="key-plus">+</span>
          )}
        </Fragment>
      ))}
      <span className="key-value">{value}</span>
    </div>
  );
}

function CommandKey({ btn, isMac }) {
  if (Array.isArray(btn)) {
    return btn.map((k2, index) => (
      <Fragment key={`${index}${k2}`}>
        <span className="key-button">{k2}</span>
        {index + 1 < btn.length && <span className="key-or">or</span>}
      </Fragment>
    ));
  }

  return <span className="key-button">{btn === 'Ctrl' ? (isMac ? '⌘' : 'Ctrl') : btn}</span>;
}
