import React, { useEffect } from 'react';
import { message } from 'antd';
import { useLazyQuery } from '@apollo/client';

import { getUserId } from 'graphql/cache';
import { CONNECT_FRESHBOOKS } from 'graphql/queries/integrationQueries';
import Loader from 'components/Loader/Loader';
import utils from 'utils/utils';

const FreshbooksWindow = ({ history }) => {
  window.close(); // only works if window is opened via script (window.open)

  const [connectFreshbook] = useLazyQuery(CONNECT_FRESHBOOKS, {
    variables: { code: utils.getQueryStringValue('code') },
    skip: !getUserId() || !utils.getQueryStringValue('code'),
    fetchPolicy: 'network-only',
    onCompleted: () => {
      sessionStorage.removeItem('freshBookConnected');
      message.success('FreshBooks Connected Successfully');
      history.push('/integration?freshbooks=connected');
    },
    onError: () => {
      sessionStorage.removeItem('freshBookConnected');
      message.error('FreshBooks Connect Failed');
      setTimeout(() => history.push('/integration?freshbooks=failed'), 1000);
    },
  });

  useEffect(() => {
    if (sessionStorage.getItem('freshBookConnected') !== 'true') {
      sessionStorage.setItem('freshBookConnected', 'true');
      connectFreshbook();
    }
  }, [connectFreshbook]);

  useEffect(() => {
    return sessionStorage.removeItem('freshBookConnected');
  });

  return <Loader />;
};

export default FreshbooksWindow;
