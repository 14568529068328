import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Menu, Modal, Table, List } from 'antd';

const UsersDetailsInfo = ({
  isFetchingAdminUsers,
  limit,
  users,
  usersCount,
  setCurrentUser,
  setOffset,
  setLimit,
  setShowFakeSubModal,
  setShowResetTrialModal,
  handleUsersActionClick,
}) => {
  // const history = useHistory();

  const handleOnClick = useCallback(
    (type, userInfo) => {
      Modal.confirm({
        title: `Are you sure you want to ${type.replace(/([a-z])([A-Z])/g, '$1 $2')} ${
          userInfo.name
        }`,
        onOk: () => handleUsersActionClick(type, userInfo),
      });
    },
    [handleUsersActionClick]
  );

  const menu = useCallback(
    (user) => {
      const userInfo = { _id: user._id, name: user.profile.name };
      return (
        <Menu>
          <Menu.Item key="creds" onClick={() => handleOnClick('addCredits', userInfo)}>
            Creds + 1
          </Menu.Item>
          <Menu.Item
            key="fake"
            onClick={() => {
              setCurrentUser(userInfo);
              setShowFakeSubModal(true);
            }}>
            Fake sub
          </Menu.Item>
          <Menu.Item
            key="cleanFake"
            onClick={() => handleOnClick('cleanFakeSubscription', userInfo)}>
            Clean fake sub
          </Menu.Item>
          <Menu.Item
            key="resetTrial"
            onClick={() => {
              setCurrentUser(userInfo);
              setShowResetTrialModal(true);
            }}>
            Reset trial to now
          </Menu.Item>
          <Menu.Item key="simulate" onClick={() => handleOnClick('simulateUser', userInfo)}>
            Simulate
          </Menu.Item>
          <Menu.Item key="resetPwd" onClick={() => handleOnClick('resetUserPassword', userInfo)}>
            Reset password
          </Menu.Item>
          <Menu.Item
            key="cancel"
            onClick={() => {
              setCurrentUser(userInfo);
              handleUsersActionClick('getUserPaymentStatus', userInfo, null, 'cancel');
            }}>
            Cancel Plan
          </Menu.Item>
          <Menu.Item
            key="refund"
            onClick={() => {
              setCurrentUser(userInfo);
              handleUsersActionClick('getUserInvoices', userInfo, null, 'refund');
            }}>
            Refunds
          </Menu.Item>
          <Menu.Item key="kill" onClick={() => handleOnClick('realDeleteUserAccount', userInfo)}>
            Kill
          </Menu.Item>
          <Menu.Item
            key="consolePayment"
            onClick={() => handleOnClick('getUserPaymentStatus', userInfo)}>
            Console payment status
          </Menu.Item>
          <Menu.Item key="revive" onClick={() => handleOnClick('reviveUser', userInfo)}>
            Revive user
          </Menu.Item>
          <Menu.Item key="export" onClick={() => handleOnClick('exportData', userInfo)}>
            Export data
          </Menu.Item>
          <Menu.Item key="login" onClick={() => handleOnClick('impersonateUser', userInfo)}>
            Login as user
          </Menu.Item>
          <Menu.Item key="blockUser" onClick={() => handleOnClick('blockUser', userInfo)}>
            Block user
          </Menu.Item>
        </Menu>
      );
    },
    [
      setCurrentUser,
      setShowFakeSubModal,
      setShowResetTrialModal,
      handleOnClick,
      handleUsersActionClick,
    ]
  );

  const userColumn = [
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Props',
      dataIndex: 'props',
      key: 'props',
    },
    {
      title: 'Where',
      dataIndex: 'where',
      key: 'where',
    },
    {
      title: 'Landing URL',
      dataIndex: 'landingUrl',
      key: 'landingUrl',
      render(landingUrl) {
        return {
          children: (
            <div style={parseInt(landingUrl.length) > 20 ? { width: '17em' } : { width: '10em' }}>
              <a target="_blank" rel="noreferrer" href={decodeURIComponent(landingUrl)}>
                {decodeURIComponent(landingUrl)}
              </a>
            </div>
          ),
        };
      },
    },
    {
      title: 'Referrer URL',
      dataIndex: 'referrerUrl',
      key: 'referrerUrl',
      render(referrerUrl) {
        return {
          children: (
            <div>
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://${decodeURIComponent(referrerUrl)}`}>
                {decodeURIComponent(referrerUrl)}
              </a>
            </div>
          ),
        };
      },
    },
    {
      title: 'Payment',
      dataIndex: 'payment',
      key: 'payment',
      render: (payment, user) =>
        payment === 'Stripe' ? (
          <a
            href={`https://dashboard.stripe.com/search?query=${
              user.paymentEmail ||
              (user.profile && user.profile.companyname ? user.profile.companyname : '')
            }`}
            target="_blank"
            rel="noreferrer">
            Stripe({user.paymentEmail})
          </a>
        ) : (
          <span>PayPal {user.paymentEmail && `(${user.paymentEmail})`}</span>
        ),
    },
    {
      title: 'PType',
      dataIndex: 'pType',
      key: 'pType',
    },
    {
      title: 'UId',
      dataIndex: 'uid',
      key: 'uid',
      render: (id) => (
        <a href={`https://app.goprospero.com/livelongandprosper?ufilter=${id}&debugmode=true1`}>
          {id}
        </a>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      onCell: (record) => ({
        className: record.deleted ? 'admin-user-deleted' : '',
      }),
      render: (data) => {
        return (
          <Dropdown overlay={() => menu(data)} trigger="click" placement="bottomRight" arrow>
            <Button type="link">Action</Button>
          </Dropdown>
        );
      },
    },
  ];

  const renderUserInfoList = (userInfo, idx) => {
    const { teamInfo } = userInfo.actions;

    const userListInfo = [
      {
        title: 'IP',
        value: <div>{userInfo?.ip || 'NA'}</div>,
      },
      {
        title: 'Landing URL',
        value: (
          <div
            style={
              parseInt(userInfo?.landingUrl.length) > 20 ? { width: '70em' } : { width: '10em' }
            }>
            <a target="_blank" rel="noreferrer" href={decodeURIComponent(userInfo?.landingUrl)}>
              {decodeURIComponent(userInfo?.landingUrl)}
            </a>
          </div>
        ),
      },
      {
        title: 'Referrer URL',
        value: (
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://${decodeURIComponent(userInfo?.referrerUrl)}`}>
              {decodeURIComponent(userInfo?.referrerUrl)}
            </a>
          </div>
        ),
      },
      {
        title: 'Team Admin',
        value: (
          <div>
            {teamInfo?._id ? (
              <>
                <span>ID: {teamInfo?._id}</span>
                <br />
                <span>Email: {teamInfo?.emails?.[0]?.address}</span>
                <br />
                <span>Name: {teamInfo?.profile?.name}</span>
              </>
            ) : (
              <span>Not Team Member</span>
            )}
          </div>
        ),
      },
    ];

    return (
      <List
        itemLayout="horizontal"
        dataSource={userListInfo}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={<a href="https://ant.design">{item.title}</a>}
              description={item.value}
            />
          </List.Item>
        )}
      />
    );
  };

  return (
    <Table
      loading={isFetchingAdminUsers}
      dataSource={users}
      columns={userColumn}
      rowKey={'uid'}
      expandedRowRender={renderUserInfoList}
      pagination={{
        total: usersCount,
        defaultPageSize: limit,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30'],
        onChange: async (page, size) => {
          setLimit(size);
          setOffset(page - 1);
        },
      }}
    />
  );
};

UsersDetailsInfo.defaultProps = {
  isFetchingAdminUsers: false,
  users: [],
  filters: {},
  usersCount: 0,
  limit: 10,
};

UsersDetailsInfo.propTypes = {
  isFetchingAdminUsers: PropTypes.bool,
  usersCount: PropTypes.number,
  limit: PropTypes.number,
  updateAdminUsers: PropTypes.func.isRequired,
  fetchMore: PropTypes.func.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
  setOffset: PropTypes.func.isRequired,
  setLimit: PropTypes.func.isRequired,
  setShowFakeSubModal: PropTypes.func.isRequired,
  setShowResetTrialModal: PropTypes.func.isRequired,
  handleUsersActionClick: PropTypes.func.isRequired,
  filters: PropTypes.instanceOf(Object),
  users: PropTypes.arrayOf(PropTypes.shape({})),
};

export default UsersDetailsInfo;
