const colors = [
  {
    type: 'border',
    textColor: '#C62828',
    color: '#C62828',
  },
  {
    type: 'border',
    textColor: '#FF6E40',
    color: '#FF6E40',
  },
  {
    type: 'border',
    textColor: '#FF9800',
    color: '#FF9800',
  },
  {
    type: 'border',
    textColor: '#FFB300',
    color: '#FFC400',
  },
  {
    type: 'border',
    textColor: '#9E9D24',
    color: '#9E9D24',
  },
  {
    type: 'border',
    textColor: '#64DD17',
    color: '#64DD17',
  },
  {
    type: 'border',
    textColor: '#00C853',
    color: '#00C853',
  },
  {
    type: 'border',
    textColor: '#00BFA5',
    color: '#00BFA5',
  },
  {
    type: 'border',
    textColor: '#00897B',
    color: '#00897B',
  },
  {
    type: 'border',
    textColor: '#1B5E20',
    color: '#1B5E20',
  },
  {
    type: 'border',
    textColor: '#6A1B9A',
    color: '#6A1B9A',
  },
  {
    type: 'border',
    textColor: '#6200EA',
    color: '#6200EA',
  },
  {
    type: 'border',
    textColor: '#00B8D4',
    color: '#00B8D4',
  },
  {
    type: 'border',
    textColor: '#00B0FF',
    color: '#00B0FF',
  },
  {
    type: 'border',
    textColor: '#304FFE',
    color: '#304FFE',
  },
  {
    type: 'border',
    textColor: '#0D47A1',
    color: '#0D47A1',
  },
  {
    type: 'fill',
    textColor: '#661100',
    color: '#FFD4CC',
  },
  {
    type: 'fill',
    textColor: '#663300',
    color: '#FFE5CC',
  },
  {
    type: 'fill',
    textColor: '#665500',
    color: '#FFF7CC',
  },
  {
    type: 'fill',
    textColor: '#556600',
    color: '#F7FFCC',
  },
  {
    type: 'fill',
    textColor: '#336600',
    color: '#E5FFCC',
  },
  {
    type: 'fill',
    textColor: '#116600',
    color: '#D4FFCC',
  },
  {
    type: 'fill',
    textColor: '#006611',
    color: '#CCFFD4',
  },
  {
    type: 'fill',
    textColor: '#006633',
    color: '#CCFFE5',
  },
  {
    type: 'fill',
    textColor: '#006655',
    color: '#CCFFF6',
  },
  {
    type: 'fill',
    textColor: '#003366',
    color: '#CCE6FF',
  },
  {
    type: 'fill',
    textColor: '#110066',
    color: '#D4CCFF',
  },
  {
    type: 'fill',
    textColor: '#330066',
    color: '#E6CCFF',
  },
  {
    type: 'fill',
    textColor: '#550066',
    color: '#F7CCFF',
  },
  {
    type: 'fill',
    textColor: '#660055',
    color: '#FFCCF7',
  },
  {
    type: 'fill',
    textColor: '#660033',
    color: '#FFCCE5',
  },
  {
    type: 'fill',
    textColor: '#660011',
    color: '#FFCCD5',
  },
];

export { colors };
