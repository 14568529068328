import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="48" height="48" viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M4 6C4 3.79086 5.79086 2 8 2H40C42.2091 2 44 3.79086 44 6V38C44 40.2091 42.2091 42 40 42H8C5.79086 42 4 40.2091 4 38V6Z"
        fill="white"
      />
      <path
        d="M4 6C4 3.79086 5.79086 2 8 2H40C42.2091 2 44 3.79086 44 6V38C44 40.2091 42.2091 42 40 42H8C5.79086 42 4 40.2091 4 38V6Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M22 12.8872C23.1716 12.8872 23.6667 14.1294 25.0303 14.1294C26.3939 14.1294 28 12.8872 28 12.8872C28 12.8872 29.2727 12 31.0303 12C33.0909 12 34 13.8336 34 13.8336V19.7199C34 20.5051 33.121 21.0024 32.3895 20.7169C31.9283 20.5369 31.4399 20.3984 31.0303 20.3992C29.841 20.4015 28 21.4047 28 21.4047C28 21.4047 27.3939 22.0553 24.7879 21.9962C22.4242 21.9962 22 21.0125 22 21.0125V12.8872Z"
        fill="#7876C1"
      />
      <rect x="20" y="11" width="2" height="21" rx="1" fill="#C7BCE4" />
      <path
        d="M23 29C23.5523 29 24 29.4477 24 30L24 31L18 31L18 30C18 29.4477 18.4477 29 19 29L23 29Z"
        fill="#C7BCE4"
      />
      <rect
        x="27"
        y="31"
        width="2"
        height="12"
        rx="1"
        transform="rotate(90 27 31)"
        fill="#C7BCE4"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="48"
        height="48"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.72549 0 0 0 0 0.721569 0 0 0 0 0.839216 0 0 0 0.2 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="24"
        y1="2"
        x2="24"
        y2="42"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F5F6FA" />
      </linearGradient>
    </defs>
  </svg>
);

const GoalTableIcon = (props) => <Icon component={svg} {...props} />;

export default GoalTableIcon;
