import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { Button } from 'antd';

import Settings from './Settings';
import DragablePhotoAlbum from './DragablePhotoAlbum';
import utils from 'utils/utils';

const GalleryImageEditor = ({ images, config, onLayoutChange, onConfigChange }) => {
  const [openSettings, setOpenSettings] = useState(false);

  const [photos, setPhotos] = useState([]);
  const [isOldGallery, setIsOldGallery] = useState(false);

  useEffect(() => {
    const _isOldGallery = images.some((img) => img.w === 5 && 'x' in img);
    setIsOldGallery(_isOldGallery);
    setPhotos(utils.albumImagesBuilder(images));
  }, [images]);

  // prevent multiple change in short span of time
  const debounceLayoutChange = useRef(
    _.debounce((value) => {
      onLayoutChange(value);
    }, 500)
  ).current;

  return (
    <>
      <div className="gallery-image-editor">
        <Settings
          setOpenSettings={setOpenSettings}
          isOpen={openSettings}
          images={images}
          config={config}
          onConfigChange={onConfigChange}
          onLayoutChange={onLayoutChange}>
          <DragablePhotoAlbum
            isOldGallery={isOldGallery}
            photos={photos}
            setPhotos={setPhotos}
            onLayoutChange={debounceLayoutChange}
          />
        </Settings>
      </div>

      <Button
        className="open-setting-btn"
        onClick={() => setOpenSettings(!openSettings)}
        size="small">
        Settings
      </Button>
    </>
  );
};

export default GalleryImageEditor;
