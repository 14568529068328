import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import utils from 'utils/utils';
import Loader from 'components/Loader';
import { GET_REDIRECTION_PROPOSAL } from 'graphql/queries/proposalQueries';

const ProposalRedirection = () => {
  const history = useHistory();

  const [fetchProposal] = useLazyQuery(GET_REDIRECTION_PROPOSAL, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: ({ fetchProposalRedirection }) => {
      if (fetchProposalRedirection?.wixProposalId) {
        const isOwner = utils.getQueryStringValue('owner');
        window.proposalId = fetchProposalRedirection.wixProposalId;

        history.push(
          `/proposal-preview?proposalId=${fetchProposalRedirection.wixProposalId}&owner=${isOwner}`
        );
      } else if (fetchProposalRedirection) {
        history.push(`/cd/${fetchProposalRedirection._id}`);
      }
    },
  });

  useEffect(() => {
    const userSub = utils.getQueryStringValue('sub');
    const proposalPath = utils.getQueryStringValue('path');

    fetchProposal({
      variables: {
        domain: userSub,
        proposal: proposalPath,
      },
    });
  }, [fetchProposal]);

  return <Loader />;
};

export default ProposalRedirection;
