import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.2" cx="12" cy="12" r="12" fill="#FF1B1B" />
    <path
      d="M16.8492 6.95012C17.2397 7.34064 17.2397 7.97381 16.8492 8.36433L8.36389 16.8496C7.97336 17.2401 7.3402 17.2401 6.94967 16.8496C6.55915 16.4591 6.55915 15.8259 6.94967 15.4354L15.435 6.95012C15.8255 6.5596 16.4586 6.5596 16.8492 6.95012Z"
      fill="#FF1B1B"
    />
    <path
      d="M6.94963 6.94929C7.34016 6.55877 7.97332 6.55877 8.36385 6.94929L16.8491 15.4346C17.2397 15.8251 17.2397 16.4583 16.8491 16.8488C16.4586 17.2393 15.8254 17.2393 15.4349 16.8488L6.94963 8.36351C6.55911 7.97298 6.55911 7.33982 6.94963 6.94929Z"
      fill="#FF1B1B"
    />
  </svg>
);

const FailedIcon = (props) => <Icon component={svg} {...props} />;

export default FailedIcon;
