/* eslint-disable prefer-destructuring */
import { useState } from 'react';
import utils from 'utils/utils';
import { breakSpamUrl, getUrlHtml } from 'utils/spamUrl';

/*
  Hook that checks for suspicious links
*/
export default function useSuspiciousLink({ proposalId, userId }) {
  const [spamlinks, setSpamlinks] = useState([]);
  const [safelinks, setSafelinks] = useState([]);

  // check for spam link via backend
  const checkSuspiciousUrl = async (url, type = 'link') => {
    try {
      let urls = [];

      if (type === 'html') {
        // find urls form the string
        urls = getUrlHtml(url);

        // exclude cached urls
        urls.forEach((url) => {
          if (!spamlinks.includes(url) || !safelinks.includes(url)) {
            urls.push(url);
          }
        });
      } else {
        // url is not link OR
        // already found the url in cache no need to hit api
        if (!utils.isLink(url) || spamlinks.includes(url)) {
          return;
        }
        urls = [url];
      }

      if (!urls?.length) {
        return;
      }

      await fetch(`${process.env.REACT_APP_API_URL}/suspicious-url`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ urls, userId, proposalId }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          if (data?.safeUrls || data?.unsafeUrls) {
            setSafelinks((p) => [...p, ...data?.safeUrls]);
            setSpamlinks((p) => [...p, ...data?.unsafeUrls]);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    } catch (error) {
      console.error('Error checking suspicious URL:', error);
    }
  };

  const breakSpamLink = (url) => {
    // if the url is in spamlinks, then break it
    if (spamlinks.includes(url)) {
      return breakSpamUrl(url);
    }
    return url;
  };

  const addSpamlinks = (urls) => {
    setSpamlinks((prev) => {
      let newUrls = [...prev];

      urls.forEach((obj) => {
        if (!newUrls.includes(obj.url) && obj.blocked) {
          newUrls.push(obj.url);
        }
      });

      return newUrls;
    });
  };

  return { checkSuspiciousUrl, breakSpamLink, addSpamlinks };
}
