/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line
      x1="14.9741"
      y1="14.9116"
      x2="18"
      y2="17.9375"
      stroke="#C7BCE4"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <circle cx="8.73505" cy="8.73505" r="7.23505" stroke="#5D5AB5" strokeWidth="3" />
  </svg>
);

const SearchIcon = (props) => <Icon component={svg} {...props} />;

export default SearchIcon;
