// this component will be rendered in wix proposal preview and client view
import { WixPricing, WixPaymentSchedule } from '.';
import { DEFAULT_CURRENCY, CURRENCY_SYMBOL } from 'constants/currency';
import { getPricingTable, getPaymentSchedule, formatLocale } from './wix-pricing-helper';
import { DEFAULT_TEMPLATE_PRICING } from 'constants/index';

export default function WixPriceMilesPublish({
  prop,
  texcontent,
  templateWixPreview,
  wixPreview,
  userWixPreview,
  configText,
}) {
  const fontSize = Number.parseInt(prop.draft.bodyFont.fontSize || 20);
  const isLargeFont = fontSize > 20;

  const wixPricing =
    templateWixPreview && !prop?.wixPricing ? DEFAULT_TEMPLATE_PRICING : prop?.wixPricing || {};

  const [_contentType] = Object.keys(JSON.parse(texcontent));
  const currency = CURRENCY_SYMBOL[wixPricing?.currencyCode || DEFAULT_CURRENCY];
  const currencyCode = wixPricing?.currencyCode || DEFAULT_CURRENCY;

  const locale = formatLocale(wixPricing);
  
  if (_contentType === 'deliverables' || _contentType === 'pricing') {
    const { lineItems, summaryItems, total, shouldShortenDigits } = getPricingTable(
      wixPricing,
      currency,
      configText,
      fontSize,
      currencyCode,
      locale
    );

    return (
      <div className={isLargeFont ? 'mobile-screen large-font' : ''}>
        <WixPricing
          lineItems={lineItems}
          summaryItems={summaryItems}
          total={total}
          wixPreview={wixPreview}
          userWixPreview={userWixPreview}
          configText={configText}
          currency={currency}
          shorten={shouldShortenDigits}
          isLargeFont={isLargeFont}
          currencyCode={wixPricing?.currencyCode || DEFAULT_CURRENCY}
          locale={locale}
        />
      </div>
    );
  }
  // ---------for content type milestones------------------
  else if (_contentType === 'milestones') {
    const { paymentTerms, notes } = getPaymentSchedule(
      wixPricing?.paymentSettings,
      currency,
      wixPricing?.calculatedTotals?.priceSummary?.total || 0,
      configText,
      currencyCode,
      locale
    );

    return (
      <div className={isLargeFont ? 'mobile-screen large-font' : ''}>
        <WixPaymentSchedule paymentTerms={paymentTerms} notes={notes} configText={configText} />
      </div>
    );
  }

  return null;
}
