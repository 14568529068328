import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Space, DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const WixRevHeader = ({ refetch, filters, handleFilters, handleReset, downloadCSV }) => {
  return (
    <div className="table-action">
      <h3>Users</h3>
      <div>
        <Space>
          <Button type="primary" onClick={downloadCSV}>
            Generate CSV
          </Button>

          <RangePicker
            value={[
              filters.startDate
                ? moment(filters.startDate)
                : moment(filters.endDate).subtract(1, 'month'),
              moment(filters.endDate),
            ]}
            onChange={(values) => {
              let dataValue = values;
              if (!values) {
                dataValue = [moment(), moment()];
              }
              handleFilters(
                'date',
                dataValue.map((el) => el.toISOString())
              );
              refetch();
            }}
          />

          <Button type="primary" onClick={handleReset}>
            Reset
          </Button>
        </Space>
      </div>
    </div>
  );
};

WixRevHeader.defaultProps = {
  filters: {},
};

WixRevHeader.propTypes = {
  refetch: PropTypes.func.isRequired,
  filters: PropTypes.instanceOf(Object),
  handleReset: PropTypes.func.isRequired,
  handleFilters: PropTypes.func.isRequired,
  downloadCSV: PropTypes.func.isRequired,
};

export default WixRevHeader;
