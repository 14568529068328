import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { useLazyQuery, useReactiveVar } from '@apollo/client';

import { CONNECT_SLACK } from 'graphql/queries/integrationQueries';
import { getUserId, userVar } from 'graphql/cache';
import Loader from 'components/Loader/Loader';
import utils from 'utils/utils';

const SlackWindow = ({ history }) => {
  window.close(); // only works if window is opened via script (window.open)

  let user = useReactiveVar(userVar);
  const [loading, setLoading] = useState(false);

  const [connectSlack] = useLazyQuery(CONNECT_SLACK, {
    variables: { code: window?.location?.href.split('code=')[1].split('&')[0] || '' },
    skip: !getUserId() || !window?.location?.href.split('code=')[1].split('&')[0],
    fetchPolicy: 'network-only',
    onCompleted: () => {
      sessionStorage.removeItem('slackConnected');
      message.success('Slack Connected Successfully');
      history.push('/integration?slack=connected');
      setLoading(false);
    },
    onError: (err) => {
      console.log('error', err);
      sessionStorage.removeItem('slackConnected');
      message.error('Slack Connect Failed');
      history.push('/integration?slack=failed');
      setLoading(false);
    },
  });

  useEffect(() => {
    const slackCode = utils.getQueryStringValue('code');
    const slackState = utils.getQueryStringValue('state');
    if (!loading) {
      if (!user) {
        const redirectUrl = `/slack?code=${slackCode}&state=${slackState}`;
        sessionStorage.setItem('redirect-url', redirectUrl);
        history.push(`/login?${redirectUrl}`);
      } else {
        connectSlack();
        setLoading(true);
      }
    }
  }, [connectSlack, loading, history, user]);

  return <Loader />;
};

export default SlackWindow;
