import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, notification } from 'antd';
import { useMutation } from '@apollo/client';

import {
  CREATE_QUICKBOOKS_AUTH_URL,
  DISCONNECT_QUICKBOOKS,
} from 'graphql/mutations/integrationMutation';
import { userVar } from 'graphql/cache';
import IntegrationItem from '../IntegrationItem';
import images from 'constants/images';
import utils from 'utils/utils';
import CloseIcon from 'components/Icons/CloseIcon';

import './QuickbooksIntegration.scss';

const QuickbooksIntegration = ({ data, isModalItem, userRole, refetchUser, user }) => {
  const [integrationData, setIntegrationData] = useState(data);
  const [loading, setLoading] = useState();
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);

  const quickbooksConnectionStatus = utils.getQueryStringValue('quickbooks');

  useEffect(() => {
    if (data) {
      setIntegrationData(data);
    }
  }, [data]);

  const checkUser = async () => {
    if (quickbooksConnectionStatus === 'connected') {
      const userData = await refetchUser();
      if (userData) {
        userVar({ ...user, ...userData.data.fetchUser });
        setIntegrationData(userData.data.fetchUser.quickbooks);
        setLoading(userData.data?.fetchUser?.quickbooks?.expires_in ? 'connected' : '');
      } else {
        setIntegrationData(user.quickbooks);
        setLoading(user?.quickbooks?.expires_in ? 'connected' : '');
      }
    } else if (quickbooksConnectionStatus === 'failed') {
      setLoading('disconnected');
    }
  };

  useEffect(() => {
    checkUser();
  }, [quickbooksConnectionStatus]); //eslint-disable-line

  const [disconnectQuickBooks, { loading: isDisconnectingQuickBooks }] = useMutation(
    DISCONNECT_QUICKBOOKS,
    {
      onCompleted: async () => {
        setLoading('');
        const userData = await refetchUser();
        if (userData) {
          userVar({ ...user, ...userData.data.fetchUser });
        }
        setIntegrationData('');
        notification.success({
          message: 'QuickBooks Disconnected',
          description: 'Your account has been disconnected from QuickBooks.',
        });
      },
      onError: () => {
        setLoading('');
        notification.error({
          message: 'QuickBooks Disconnected Failed',
          description: 'Your account failed to disconnect try again.',
        });
      },
    }
  );

  const [createQuickBooksAuthUrl, { loading: isQuickBooksAuthUrlLoading }] = useMutation(
    CREATE_QUICKBOOKS_AUTH_URL,
    {
      onCompleted: async (data) => {
        const authData = data.createQuickBooksAuthUrl;
        await utils.refetchUserOnClosingTab(authData.url, authData.tab);
        setShowConnectModal(false);
        setTimeout(async () => {
          const userData = await refetchUser();
          if (userData) {
            userVar({ ...user, ...userData.data.fetchUser });
            setIntegrationData(userData.data.fetchUser.quickbooks);
            setLoading(userData.data?.fetchUser?.quickbooks?.expires_in ? 'connected' : '');
          } else {
            setIntegrationData(user.quickbooks);
            setLoading(user?.quickbooks?.expires_in ? 'connected' : '');
          }
        }, [5000]);
      },
      onError: () => {
        notification.error({
          message: 'QuickBooks Create Auth URL Failed',
          description: 'Something went wrong',
        });
      },
    }
  );

  const handleConnect = () => {
    const height = window.outerHeight;
    const width = window.outerWidth;

    createQuickBooksAuthUrl({
      variables: {
        height,
        width,
      },
    });
  };

  const handleClick = () => {
    if (
      !integrationData ||
      !integrationData?.expires_in ||
      !integrationData?.x_refresh_token_expires_in
    ) {
      setLoading('connecting');
      return handleConnect();
    }
    setLoading('disconnecting');
    disconnectQuickBooks();
  };

  let loadingStatus = isQuickBooksAuthUrlLoading
    ? 'connecting'
    : isDisconnectingQuickBooks
    ? 'disconnecting'
    : loading
    ? loading
    : '';

  return (
    <>
      <IntegrationItem
        type="quickbooks"
        name="quickbooks"
        description="Import contacts & generate invoices with a click"
        integrationLink="https://support.goprospero.com/article/show/140043-how-to-integrate-prospero-to-quickbooks"
        status={
          loadingStatus || // this can be connecting or disconnecting
          (integrationData?.expires_in && integrationData?.x_refresh_token_expires_in
            ? 'connected'
            : 'disconnected')
        }
        image={images.QUICKBOOKS_ICON}
        handleClick={() =>
          integrationData?.expires_in && integrationData?.x_refresh_token_expires_in
            ? handleClick()
            : setShowConnectModal(true)
        }
        isModalItem={isModalItem}
        userRole={userRole}
      />

      {showConnectModal && (
        <Modal
          zIndex={1009}
          className={'confirm-modal quickbooks-connect-modal'}
          centered
          visible={showConnectModal}
          onCancel={() => setShowConnectModal(false)}
          closeIcon={<CloseIcon className="close-icon" />}
          closable={true}
          showIcon={true}
          footer={null}>
          <img className="title" src={images.QUICKBOOKS_LOGO} alt={'QuickBooks'} />

          <div className="divider" />

          <div className="body">Import contacts & generate invoices with a click</div>

          <div className="button-wrapper">
            <Button
              className="button quickbooks-connect"
              type="primary"
              onMouseLeave={() => setMouseOver(false)}
              onMouseEnter={() => setMouseOver(true)}
              onClick={handleClick}>
              <img
                src={
                  mouseOver ? images.QUICKBOOKS_CONNECT_HOVER : images.QUICKBOOKS_CONNECT_DEFAULT
                }
                alt={'QuickBooks'}
              />
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

QuickbooksIntegration.defaultProps = {
  data: null,
  isModalItem: false,
  userRole: '',
  refetchUser: () => {},
};

QuickbooksIntegration.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(null),
  ]),
  isModalItem: PropTypes.bool,
  userRole: PropTypes.string,
  refetchUser: PropTypes.func,
};

export default QuickbooksIntegration;
