import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="97" height="90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <svg width="97" height="120" viewBox="0 0 97 120">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0615 57.9769C7.11297 57.5129 0 50.1102 0 41.0462C0 31.6828 7.5905 24.0923 16.9538 24.0923C17.3904 24.0923 17.8231 24.1088 18.2514 24.1412C20.5629 10.4381 32.4856 0 46.8462 0C59.3185 0 69.9519 7.8736 74.0469 18.9215C74.9268 18.8008 75.8254 18.7385 76.7385 18.7385C87.5802 18.7385 96.3692 27.5275 96.3692 38.3692C96.3692 49.211 87.5802 58 76.7385 58H46.8462H16.9538H16.0615V57.9769Z"
        fill="#C7BCE4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0615 57.9769C7.11297 57.5129 0 50.1102 0 41.0462C0 31.6828 7.5905 24.0923 16.9538 24.0923C17.3904 24.0923 17.8231 24.1088 18.2514 24.1412C20.5629 10.4381 32.4856 0 46.8462 0C59.3185 0 69.9519 7.8736 74.0469 18.9215C74.9268 18.8008 75.8254 18.7385 76.7385 18.7385C87.5802 18.7385 96.3692 27.5275 96.3692 38.3692C96.3692 49.211 87.5802 58 76.7385 58H46.8462H16.9538H16.0615V57.9769Z"
        fill="#C4C4C4"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="69.6"
          y1="7.13847"
          x2="32.5692"
          y2="54.8769"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#7977C1" />
          <stop offset="1" stopColor="#C7BCE4" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
    <svg
      width="24"
      height="27"
      viewBox="0 0 24 27"
      x="35"
      y="15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.2893 0.718108C11.6806 0.322712 12.3194 0.322711 12.7107 0.718108L20.7063 8.79655C21.3313 9.42799 20.884 10.5 19.9956 10.5H4.00442C3.116 10.5 2.66871 9.42799 3.29368 8.79655L11.2893 0.718108Z"
        fill="white"
      />
      <rect x="8" y="8" width="8" height="9" rx="1" fill="white" />
      <rect x="8" y="19" width="8" height="4" rx="1" fill="white" />
      <rect x="8" y="25" width="8" height="2" rx="1" fill="white" />
    </svg>
    <svg
      x="0"
      y="62"
      width="104"
      height="15"
      viewBox="0 0 104 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="52" cy="7.5" rx="52" ry="7.5" fill="url(#paint0_radial)" fillOpacity="0.8" />
      <defs>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(52 7.5) rotate(90) scale(7.5 52)">
          <stop stopColor="#C7BCE4" />
          <stop offset="1" stopColor="#C7BCE4" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  </svg>
);

const UploadCloudIcon = (props) => <Icon component={svg} {...props} />;

export default UploadCloudIcon;
