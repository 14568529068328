import React from 'react';
import Icon from '@ant-design/icons';

const ContentIcon = () => (
  <svg width="35" height="28" viewBox="0 0 35 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="31" height="24" rx="2" fill="#5D5AB5" />
    <rect width="32" height="25" rx="2" fill="#7876C1" />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="2"
      y="4"
      width="28"
      height="20">
      <rect x="2" y="4.31567" width="28" height="19.6842" rx="2" fill="#7876C1" />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M23.9628 13.6107C23.6202 13.2587 23.0728 13.2076 22.6711 13.4901L11.9545 21.0239C11.1555 21.5856 11.5529 22.842 12.5296 22.842H30.5786C31.463 22.842 31.912 21.7783 31.2952 21.1445L23.9628 13.6107Z"
        fill="#C7BCE4"
      />
      <path
        d="M11.9382 11.283C11.3603 10.7382 10.458 10.7382 9.88014 11.283L-0.32883 20.9086C-1.3167 21.84 -0.657535 23.5 0.700191 23.5H21.1181C22.4759 23.5 23.135 21.84 22.1471 20.9086L11.9382 11.283Z"
        fill="#C7BCE4"
        stroke="#7876C1"
      />
    </g>
    <circle cx="25" cy="7" r="3" fill="#C7BCE4" />
  </svg>
);

const SignatureUploadIcon = (props) => <Icon component={ContentIcon} {...props} />;

export default SignatureUploadIcon;
