import PropTypes from 'prop-types';
import { Empty } from 'antd';

import './InvalidConnection.scss';

const InvalidConnection = ({ description }) => {
  return <Empty className="invalid-connection" description={description} />;
};

InvalidConnection.defaultProps = {
  description: (
    <div className="invalid-connection-message">
      <span>Please log in with the Prospero account associated with this monday account.</span>
    </div>
  ),
};

InvalidConnection.propTypes = {
  description: PropTypes.instanceOf(Object),
};

export default InvalidConnection;
