import React, { useState, useEffect } from 'react';
import { Button, Modal, Col, Row, Divider, message, Tooltip, Select } from 'antd';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { useIntercom } from 'react-use-intercom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import CloseIcon from 'components/Icons/CloseIcon';
import DropdownIcon from 'components/Icons/DropdownIcon';
import helpers from 'helpers/proposal';
import utils from 'utils/utils';
import Input from 'components/Input';
import Loader from 'components/Loader';
import { GET_CONFIGURATION } from 'graphql/queries/configurationQueries';
import { GET_SINGLE_PROPOSAL } from 'graphql/queries/proposalQueries';
import XeroAccountMappingModal from '../XeroAccountMappingModal/XeroAccountMappingModal';
import {
  CREATE_QUICKBOOKS_INVOICE,
  CREATE_FRESHBOOKS_INVOICE,
  CREATE_XERO_INVOICE,
  CREATE_MORNING_INVOICE,
} from 'graphql/mutations/integrationMutation';

import './GenerateInvoiceModal.scss';

const { Option } = Select;

const GenerateInvoiceModal = ({ onCancel, proposalId, invoiceType, organisations }) => {
  const { trackEvent } = useIntercom();

  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [proposal, setProposal] = useState(null);
  const [config, setConfig] = useState();
  const [discount, setDiscount] = useState({ unit: '%', val: 100 });
  const [absoluteTotal, setAbsoluteTotal] = useState('');
  const [invoiceLanguage, setInvoiceLanguage] = useState('hebrew');
  const [showXeroAccountMappingModal, setShowXeroAccountMappingModal] = useState(false);
  const [invoiceOrganisation, setInvoiceOrganisation] = useState(
    organisations.length ? organisations[0].tenantId : null
  );

  useQuery(GET_SINGLE_PROPOSAL, {
    notifyOnNetworkStatusChange: true,
    variables: {
      pid: proposalId,
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ fetchProposal }) => {
      if (fetchProposal) {
        const updatedProposal = JSON.parse(JSON.stringify(fetchProposal));
        setProposal(updatedProposal);
      } else {
        setProposal(null);
      }
    },
  });

  const [loadConfig, { loading: isFetchingConfiguration }] = useLazyQuery(GET_CONFIGURATION, {
    fetchPolicy: 'cache-and-network',
    skip: config,
    onCompleted: (data) => {
      setConfig(data?.fetchConfiguration);
    },
  });

  const onSuccessIntercomInvoice = (link) => {
    trackEvent('createdInvoice', {
      link: link,
      propid: proposal._id,
    });

    if (invoiceType === 'quickbooks') {
      let linkHtml = document.createElement('a');
      linkHtml.href = link;

      //Set properties as you wise
      linkHtml.download = `${proposal.pnum} - QuickBooks Invoice`;
      linkHtml.target = 'blank';

      //this part will append the anchor tag and remove it after automatic click
      document.body.append(linkHtml);
      linkHtml.click();
    } else if (invoiceType === 'freshbooks') {
      let linkHtml = document.createElement('a');
      linkHtml.href = link;

      //Set properties as you wise
      linkHtml.download = `${proposal.pnum} - FreshBooks Invoice`;
      linkHtml.target = 'blank';

      //this part will append the anchor tag and remove it after automatic click
      document.body.append(linkHtml);
      linkHtml.click();
    } else if (invoiceType === 'xero') {
      let linkHtml = document.createElement('a');
      linkHtml.href = 'data:application/pdf;base64,' + Buffer.from(link).toString('base64');

      //Set properties as you wise
      linkHtml.download = `${proposal.pnum} - Xero Invoice`;
      linkHtml.target = 'blank';

      //this part will append the anchor tag and remove it after automatic click
      document.body.append(linkHtml);
      linkHtml.click();
    } else if (invoiceType === 'morninginvoice') {
      let linkHtml = document.createElement('a');
      linkHtml.href = link;

      //Set properties as you wise
      linkHtml.download = `${proposal.pnum}_Morning_Invoice`;
      linkHtml.target = 'blank';

      //this part will append the anchor tag and remove it after automatic click
      document.body.append(linkHtml);
      linkHtml.click();
    } else {
      window.open(link, '_BLANK');
    }
  };

  const [createQuickBooksInvoice] = useMutation(CREATE_QUICKBOOKS_INVOICE, {
    onCompleted: (data) => {
      setLoadingGenerate(false);
      onCancel();
      if (data?.createQuickBooksInvoice) {
        const base64String = window.btoa(data?.createQuickBooksInvoice);

        const link = `data:application/pdf;base64,${base64String}`;
        onSuccessIntercomInvoice(link);
      }

      message.success('Invoice generated successfully.');
    },
    onError: ({ graphQLErrors }) => {
      setLoadingGenerate(false);
      const [error] = graphQLErrors;
      if (error) {
        if (error?.extensions?.code === 'Error: Token Expired. Reconnect to QuickBooks') {
          message.error('Token Expired. Reconnect to QuickBooks');
        } else {
          if (error.message.indexOf('QuickBooks Error:') > -1) {
            message.error({
              content: `${error.message}`,
              style: {
                marginLeft: '25%',
                width: '50%',
              },
            });
          } else {
            message.error(`${error.message}`);
          }
        }
      }
    },
  });

  const [createFreshbooksInvoice] = useMutation(CREATE_FRESHBOOKS_INVOICE, {
    onCompleted: (data) => {
      setLoadingGenerate(false);
      onCancel();
      if (data?.createFreshbooksInvoice) {
        onSuccessIntercomInvoice(data?.createFreshbooksInvoice);
      }
      message.success('Invoice generated successfully.');
    },
    onError: (error) => {
      setLoadingGenerate(false);
      message.error(`Problem creating template:${error.message}`);
    },
  });

  const [createXeroInvoice] = useMutation(CREATE_XERO_INVOICE, {
    onCompleted: (data) => {
      setLoadingGenerate(false);
      onCancel();
      if (data?.createXeroInvoice) {
        onSuccessIntercomInvoice(data?.createXeroInvoice);
      }
      message.success('Invoice generated successfully.');
    },
    onError: (error) => {
      setLoadingGenerate(false);
      message.error(`Problem creating template:${error.message}`);
    },
  });

  const [createMorningInvoice] = useMutation(CREATE_MORNING_INVOICE, {
    onCompleted: (data) => {
      setLoadingGenerate(false);
      onCancel();
      if (data?.createMorningInvoice) {
        onSuccessIntercomInvoice(data?.createMorningInvoice);
      }
      setInvoiceLanguage('hebrew');
      message.success('Invoice generated successfully.');
    },
    onError: (error) => {
      setLoadingGenerate(false);
      message.error(`Problem creating template:${error.message}`);
    },
  });

  useEffect(() => {
    if (proposal) {
      loadConfig({
        variables: {
          language: proposal?.language?.toLowerCase() || 'english',
        },
      });
    }
  }, [loadConfig, proposal]);

  const onConfirm = (accountMap) => {
    // let absoluteCurr = config && config['currency'] ? config['currency'] : '$';
    const mutationInfo = {
      variables: {
        invoiceInfo: {
          organisationId: invoiceType === 'xero' ? invoiceOrganisation : undefined,
          accountMap: invoiceType === 'xero' ? accountMap : undefined,
          id: proposal._id,
          dis: {
            val: discount.val,
            unit: discount.unit,
          },
          absoluteTotal: realtotal || parseFloat(absoluteTotal),
          absoluteCurr: curr,
          language: invoiceLanguage,
        },
      },
    };

    if (invoiceType === 'quickbooks') {
      setLoadingGenerate(true);
      createQuickBooksInvoice(mutationInfo);
    } else if (invoiceType === 'freshbooks') {
      setLoadingGenerate(true);
      createFreshbooksInvoice(mutationInfo);
    } else if (invoiceType === 'xero') {
      if (showXeroAccountMappingModal) {
        setLoadingGenerate(true);
        createXeroInvoice(mutationInfo);
        setShowXeroAccountMappingModal(false);
      } else {
        setLoadingGenerate(true);
        setShowXeroAccountMappingModal(true);
      }
    } else if (invoiceType === 'morninginvoice') {
      setLoadingGenerate(true);
      createMorningInvoice(mutationInfo);
    }
  };

  if (isFetchingConfiguration || !proposal) {
    return <Loader />;
  }

  let thetable = utils.findTableInProp(proposal);
  if (thetable) {
    // basically no need since moved to inside the function
    helpers.taxdisfix(thetable.deliverables.chosen);
  }
  let table_curr = utils.findCurrInProp(proposal);

  let total = 0;
  let curr = config && config['currency'] ? config['currency'] : '$';
  // let absoluteCurr = curr;
  let distext = '';
  let taxtext = '';
  let deptext = '';
  let realtotal = total;

  curr = table_curr || proposal.currency || curr;

  const currencyOptions = {
    prop: proposal,
    curr,
    limitDec: proposal?.decimalPrecision || 2,
    parsingDecimal: proposal?.decimalPrecision || false,
  };

  if (thetable) {
    let thelist = utils.findListInTable(thetable);

    curr = thetable.curr || curr;

    _.each(thelist.list, (x) => {
      if (!x.checked && thelist.selectable) {
        return;
      }
      total +=
        x.price && thelist.quantity
          ? Number(
              proposal.priceSeperator === '.'
                ? String(x.price).replaceAll(',', '.')
                : String(x.price)
            ) * (x.items || 1)
          : Number(
              proposal.priceSeperator === '.'
                ? String(x.price).replaceAll(',', '.')
                : String(x.price)
            )
          ? Number(
              proposal.priceSeperator === '.'
                ? String(x.price).replaceAll(',', '.')
                : String(x.price)
            )
          : 0;
    });

    let listDiscount = thelist.discount;

    if (listDiscount) {
      if (listDiscount.type === -1) {
        // listDiscount
        if (listDiscount.unit === '%') {
          let disv =
            total *
            (Number(
              proposal.priceSeperator === '.'
                ? String(listDiscount.val).replaceAll(',', '.')
                : String(listDiscount.val)
            ) /
              100);
          total = total - disv;
          distext = `After ${helpers.concatcurr({ x: disv, ...currencyOptions })} Discount`;
        } else {
          total =
            total -
            Number(
              proposal.priceSeperator === '.'
                ? String(listDiscount.val).replaceAll(',', '.')
                : String(listDiscount.val)
            );
          distext = `After ${helpers.concatcurr({
            x: listDiscount.val,
            ...currencyOptions,
          })} Discount`;
        }
      }
    }

    // tax does not go into total, because total is deposit?
    // why? ==>
    // How is tax factored into Deposits?
    //When you set a Deposit, the percentage that is set is calculated based on the Invoice total before tax.
    // So if you had a $113 Invoice (with $13 being tax) and asked for a 50% Deposit, the Deposit would set to $50
    // ---> still does it work with 100% deposit ???
    // (i.e dis, i.e invoice amount, because then in server we don't use the deposit feature so we lie about invoice amount...

    // so.. should add tax to toal, if deposit is 100% or == to amount

    let shouldaddtax = false;

    if (discount) {
      if (discount.unit === '%' && discount.val === 100) {
        shouldaddtax = true;
      } else if (discount.unit !== '%' && discount.val >= total) {
        shouldaddtax = true;
      } else {
        deptext = ' (Deposit)';
      }
    }

    let { tax } = thelist;
    if (tax) {
      if (tax.unit === '%') {
        let taxv =
          total *
          (Number(
            proposal.priceSeperator === '.' ? String(tax.val).replaceAll(',', '.') : String(tax.val)
          ) /
            100);
        if (shouldaddtax) {
          total = total + taxv;
          taxtext = `After ${helpers.concatcurr({
            x: taxv,
            ...currencyOptions,
          })} Tax`;
        } else {
          taxtext = `Before ${helpers.concatcurr({
            x: taxv,
            ...currencyOptions,
          })} Tax`;
        }
      } else {
        if (shouldaddtax) {
          total =
            total +
            Number(
              proposal.priceSeperator === '.'
                ? String(tax.val).replaceAll(',', '.')
                : String(tax.val)
            );
          taxtext = `After ${helpers.concatcurr({
            x: tax.val,
            ...currencyOptions,
          })} Tax`;
        } else {
          taxtext = `Before ${helpers.concatcurr({
            x: tax.val,
            ...currencyOptions,
          })} Tax`;
        }
      }
    }

    realtotal = total;

    // this is not a normal discount - more like a discount for the invoice, i.e partial payment, just bad name
    if (discount) {
      if (discount.unit === '%') {
        realtotal = realtotal * (discount.val / 100);
      } else {
        realtotal = discount.val;
      }
    }
  }

  const toLeft =
    curr && ((curr.length === 1 && curr !== '%') || curr === 'R$' || curr === 'Rp' || curr === 'RP')
      ? curr
      : '';
  const toRight = !toLeft ? curr : '';

  const invoiceName = {
    quickbooks: 'QuickBooks',
    freshbooks: 'FreshBooks',
    morninginvoice: 'morning',
    xero: 'Xero',
  };

  return (
    <Modal
      className="simple-info-modal generate-invoice-modal"
      centered
      visible
      onCancel={onCancel}
      footer={null}
      closeIcon={<CloseIcon />}>
      <h3 className="title">Generate {invoiceName[invoiceType] || 'Xero'} Invoice</h3>
      <div className="divider" />

      <div className="body">
        <Col>
          {!!thetable && (
            <>
              <Row justify="space-between">
                <Col span={12} className="head-cost">
                  Project Total
                </Col>
                <Col span={10}>{helpers.concatcurr({ x: total, ...currencyOptions })}</Col>
              </Row>
              <Row justify="start" className="tax-discount">
                {distext}
              </Row>
              <Row justify="start" className="tax-discount">
                {taxtext}
              </Row>
              <Row justify="space-between">
                <Col span={12} className="head-cost">
                  Invoice Amount
                </Col>
                <Col span={10}>
                  <Input
                    value={discount.val}
                    disabled={invoiceType === 'xero' || invoiceType === 'morninginvoice'}
                    placeholder={'value'}
                    addonAfter={
                      <Tooltip placement="top" title={'Click to switch unit'} color="white">
                        <div
                          onClick={() => {
                            discount.unit === '%'
                              ? setDiscount({ unit: null, val: total })
                              : setDiscount({ unit: '%', val: 100 });
                          }}>
                          {discount.unit || curr || config['currency']}
                        </div>
                      </Tooltip>
                    }
                    onChange={(e) => {
                      let v = e.target.value;
                      let newDiscount = JSON.parse(JSON.stringify(discount));

                      let val = utils.onlyNumSeperator(proposal, v);

                      let fval = parseFloat(val);
                      if (fval > 100 && newDiscount.unit === '%') {
                      } else if (fval > total && newDiscount.unit !== '%') {
                      } else {
                        newDiscount.val = val;
                      }

                      setDiscount(newDiscount);
                    }}
                  />
                </Col>
              </Row>
              {invoiceType === 'xero' ? (
                <Row>
                  <h5 className="xero-message">
                    Xero integration is supported for full amount only
                  </h5>
                </Row>
              ) : (
                ''
              )}
              {invoiceType === 'morninginvoice' ? (
                <Row>
                  <h5 className="morning-message">
                    Morning integration is supported for full amount only
                  </h5>
                </Row>
              ) : (
                ''
              )}
              <Divider />
            </>
          )}

          {invoiceType === 'xero' ? (
            <Row justify="space-between xero-organisation">
              <Col span={12} className="total-cost">
                Xero Organisations
              </Col>
              <Col span={10} className="total-cost">
                <Select
                  defaultValue={organisations[0].tenantId}
                  onChange={setInvoiceOrganisation}
                  suffixIcon={<DropdownIcon />}>
                  {organisations?.map((organisation) => (
                    <Option key={organisation.tenantId} value={organisation.tenantId}>
                      {organisation.tenantName}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          ) : (
            ''
          )}

          <Row justify="space-between">
            <Col span={12} className="total-cost">{`Invoice Total${deptext}`}</Col>
            <Col span={10} className="total-cost">
              {thetable ? (
                helpers.concatcurr({ x: realtotal, ...currencyOptions })
              ) : (
                <Input
                  className="w-input prov5-totalprice invoice-absolute-total"
                  value={absoluteTotal}
                  placeholder={'value'}
                  onChange={(e) => setAbsoluteTotal(e.target.value)}
                  suffixtext={toRight ? curr : ''}
                  prefixtext={toLeft ? curr : ''}
                />
              )}
            </Col>
          </Row>

          {invoiceType === 'morninginvoice' ? (
            <Row justify="space-between morning-language">
              <Col span={12} className="total-cost">
                Language
              </Col>
              <Col span={10} className="total-cost">
                <Select
                  defaultValue="Hebrew"
                  onChange={setInvoiceLanguage}
                  suffixIcon={<DropdownIcon />}>
                  <Option value="hebrew">Hebrew</Option>
                  <Option value="english">English</Option>
                </Select>
              </Col>
            </Row>
          ) : (
            ''
          )}

          {invoiceType === 'xero' ? (
            <Row>
              <h5 className="currency-alert">
                The currency used here may differ from your Xero default currency.
              </h5>
              <h5 className="currency-alert">
                You can change your Prospero default currency from <a href="/settings">settings</a>.
              </h5>
            </Row>
          ) : (
            ''
          )}

          {invoiceType === 'morninginvoice' ? (
            <Row>
              <h5 className="currency-alert">
                Currently the tax you can add is only the Israel tax (17%).
              </h5>
              <h5 className="currency-alert">Other tax value will be taken as 0.</h5>
            </Row>
          ) : (
            ''
          )}
        </Col>
      </div>

      <div className="button-wrapper">
        <Button
          className="button confirm"
          type="primary"
          loading={loadingGenerate}
          disabled={thetable ? realtotal === 0 : parseFloat(absoluteTotal) === 0 || !absoluteTotal}
          onClick={onConfirm}>
          {invoiceType === 'xero' ? 'Map Accounts' : 'Generate'}
        </Button>
        <Button className="button cancel" type="primary" onClick={onCancel}>
          Cancel
        </Button>
      </div>
      {showXeroAccountMappingModal && (
        <XeroAccountMappingModal
          onConfirm={onConfirm}
          setShowXeroAccountMappingModal={setShowXeroAccountMappingModal}
          prop={proposal}
          tenantId={invoiceOrganisation}
          setLoadingGenerate={setLoadingGenerate}
          absoluteTotal={absoluteTotal}
          absoluteCurr={curr}
        />
      )}
    </Modal>
  );
};

GenerateInvoiceModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default GenerateInvoiceModal;
