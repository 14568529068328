import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="7" height="37" viewBox="0 0 7 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
      <circle cx="1" cy="11" r="1" fill="#4F4E7B" />
      <circle cx="1" cy="1" r="1" fill="#4F4E7B" />
      <circle cx="1" cy="36" r="1" fill="#4F4E7B" />
      <circle cx="1" cy="16" r="1" fill="#4F4E7B" />
      <circle cx="1" cy="6" r="1" fill="#4F4E7B" />
      <circle cx="1" cy="21" r="1" fill="#4F4E7B" />
      <circle cx="1" cy="26" r="1" fill="#4F4E7B" />
      <circle cx="1" cy="31" r="1" fill="#4F4E7B" />
      <circle cx="6" cy="11" r="1" fill="#4F4E7B" />
      <circle cx="6" cy="1" r="1" fill="#4F4E7B" />
      <circle cx="6" cy="36" r="1" fill="#4F4E7B" />
      <circle cx="6" cy="16" r="1" fill="#4F4E7B" />
      <circle cx="6" cy="6" r="1" fill="#4F4E7B" />
      <circle cx="6" cy="21" r="1" fill="#4F4E7B" />
      <circle cx="6" cy="26" r="1" fill="#4F4E7B" />
      <circle cx="6" cy="31" r="1" fill="#4F4E7B" />
    </g>
  </svg>
);

const TableDragIcon = (props) => <Icon component={svg} {...props} />;

export default TableDragIcon;
