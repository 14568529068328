import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, useHistory, useLocation } from 'react-router-dom';
import { Layout, message, Switch } from 'antd';
import TechnicalIcon from 'components/Icons/TechnicalIcon';
import CloseIcon from 'components/Icons/CloseIcon';
import {
  ApolloProvider,
  ApolloClient,
  useReactiveVar,
  split,
  useLazyQuery,
  useMutation,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import { persistCache, LocalStorageWrapper, CachePersistor } from 'apollo3-cache-persist';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { dashboard } from '@wix/dashboard';
import { createClient } from '@wix/sdk';
import jwt_decode from 'jwt-decode';
import CookieConsent from 'react-cookie-consent';
import { v4 as uuidv4 } from 'uuid';

import ContextProvider from 'context/ContextProvider';
// import hoc to pass additional props to routes
import moment from 'moment';
import PropsRoute from 'routes/PropsRoute';
import Routes from 'routes';
import Path from 'routes/path';
import HeaderBar from 'components/HeaderBar';
import SideBar from 'components/SideBar';
import TrailEndHeader from 'components/TrailEndHeader';
import utils from 'utils/utils';
import SessionTimeout from 'components/SessionTimeout';
import AdminHeaderBar from 'components/AdminHeaderBar';
import Loader from 'components/Loader';
import TrialEndPopup from 'components/TrialEndPopup';
import TrialEndReminder from 'components/TrialEndReminder';
import NetworkStatus from 'components/NetworkStatus';

import { typeDefs } from 'graphql/schema';
import {
  cache,
  getUserId,
  isLoggedInVar,
  userProfileVar,
  userVar,
  adminVar,
  fromAppVar,
  appSessionTokenVar,
  mondayBoardIdVar,
  featureFlagsVar,
} from 'graphql/cache';
import { USER_DETAILS } from 'graphql/queries/userQueries';
import { FETCH_FEATURE_FLAGS } from 'graphql/queries/featureFlagQueries';
import { NO_HEADER_SIDEBAR_ROUTES } from 'constants/index';
import { PROPOSAL_SUBSCRIPTION_TOPIC } from 'constants/index';
import { UPDATE_PROPOSAL_MUTATION } from 'graphql/mutations/proposalMutation';
import { UPDATE_FEATURE_FLAG_MUTATION } from 'graphql/mutations/featureFlagMutation';
import {
  UPDATE_LIBRARY_TEMPLATE_MUTATION,
  UPDATE_LIBRARY_SECTION_MUTATION,
} from 'graphql/mutations/contentLibraryMutations';
import { updateIntercomUser } from 'helpers/users';

import 'styles/main.scss';
import 'antd/dist/antd.css';

const { Content } = Layout;
const templateWixPreview = window.location.pathname.includes('/template-preview');
const proposalWixPreview = window.location.pathname.includes('/proposal-preview');
const daystrial = process.env.REACT_APP_DAYS_TRAIL || 14;
const tabID = sessionStorage.tabID ? sessionStorage.tabID : (sessionStorage.tabID = uuidv4());
const seenReminder = JSON.parse(localStorage.getItem('seen-reminder'));

const logout = async () => {
  const persistor = new CachePersistor({
    cache: client.cache,
    storage: window.localStorage,
  });

  await client.clearStore();
  await persistor.purge();

  isLoggedInVar(false);
  userProfileVar('');
  userVar('');

  localStorage.clear();
  sessionStorage.removeItem('lastTimeStamp');
  window.location.reload();
};

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      if (err.message.startsWith('Access denied') && !window.proposalId && !window.templateId) {
        return logout();
      }
    }
  }
});

const parseHeaders = (rawHeaders) => {
  const headers = new Headers();
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
  preProcessedHeaders.split(/\r?\n/).forEach((line) => {
    const parts = line.split(':');
    const key = parts.shift().trim();
    if (key) {
      const value = parts.join(':').trim();
      headers.append(key, value);
    }
  });
  return headers;
};

export const uploadFetch = (url, options) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const opts = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers: parseHeaders(xhr.getAllResponseHeaders() || ''),
      };
      opts.url = 'responseURL' in xhr ? xhr.responseURL : opts.headers.get('X-Request-URL');
      const body = 'response' in xhr ? xhr.response : xhr.responseText;
      resolve(new Response(body, opts));
    };
    xhr.onerror = () => {
      reject(new TypeError('Network request failed'));
    };
    xhr.ontimeout = () => {
      reject(new TypeError('Network request failed'));
    };
    xhr.open(options.method, url, true);

    Object.keys(options.headers).forEach((key) => {
      xhr.setRequestHeader(key, options.headers[key]);
    });

    if (xhr.upload) {
      xhr.upload.onprogress = options.onProgress;
    }

    options.onAbortPossible &&
      options.onAbortPossible(() => {
        xhr.abort();
      });

    xhr.send(options.body);
  });

const customFetch = (uri, options) => {
  console.log("here", uri)
  if (options.useUpload) {
    return uploadFetch(uri, options);
  }
  return fetch(uri, options);
};

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  fetch: customFetch,
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_GRAPHQL_WS_URL,
  options: {
    lazy: true,
    reconnect: true,
    timeout: 300000,
    inactivityTimeout: 0,
    keepAlive: true,
    keepAliveInterval: 30000,
    connectionParams: async () => {
      const adminData = adminVar();
      let token =
        (adminData && localStorage.getItem('proxy-token')) ||
        localStorage.getItem('prospero-token');
      const path = window.location.pathname;
      let proposalId = '';
      let instanceId = '';

      if (path.includes('/cd/')) {
        proposalId = path.replace('/cd/', '');
      } else if (path.includes('/PDF/')) {
        proposalId = path.replace('/PDF/', '');
      } else if (path.includes('/proposal-editor') || path.includes('/template-editor')) {
        ({ instanceId } = window);
      } else if (proposalWixPreview || path.includes('/roxy') || templateWixPreview) {
        if (proposalWixPreview) {
          proposalId = utils.getParameterByName('proposalId', window?.location?.href);
        }
        token = '';
      }

      return {
        Authorization: instanceId
          ? `InstanceId ${instanceId}`
          : token
            ? `Bearer ${token}`
            : proposalId
              ? `Proposal ${proposalId}`
              : '',
        tabID: tabID,
      };
    },
    onError: (error) => {
      // error.message has to match what the server returns.
      if (error.message === 'Invalid authentication') {
        // Reset the WS connection for it to carry the new JWT.
        wsLink.subscriptionClient.close(false, false);
      }
    },
    onDisconnected: () => {
      console.log('disconnected');
    },
  },
});

wsLink.subscriptionClient.maxConnectTimeGenerator.duration = () =>
  wsLink.subscriptionClient.maxConnectTimeGenerator.max;

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink
);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const adminData = adminVar();
  let token =
    (adminData && localStorage.getItem('proxy-token')) || localStorage.getItem('prospero-token');
  const path = window.location.pathname;
  let instanceId = '';

  if (!adminData && localStorage.getItem('proxy-token')) {
    localStorage.removeItem('proxy-token');
    localStorage.removeItem('proxy-user-id');
  }

  if (path.includes('/proposal-editor') || path.includes('/template-editor')) {
    ({ instanceId } = window);
  } else if (proposalWixPreview || templateWixPreview) {
    token = '';
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: instanceId ? `InstanceId ${instanceId}` : token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, splitLink]),
  cache,
  typeDefs,
  fetch: customFetch,
});

const AppContainer = (props) => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const user = useReactiveVar(userVar);

  const [featuresBugs, setFeaturesBugs] = useState(null);
  const [path, setPath] = useState('');
  const [flagItemLoading, setFlagItemLoading] = useState({});
  const [openFeaturesDrawer, setOpenFeaturesDrawer] = useState(false);
  const [userLockedPopup, setUserLockedPopup] = useState(false);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [messageApi, messageContextHolder] = message.useMessage();

  const history = useHistory();
  const location = useLocation();
  const { update } = useIntercom();

  const showFlagsToggle =
    process.env.REACT_APP_ENV !== 'production' && location.pathname === Path.ADMIN;

  const { isOnline } = props;

  const [fetchFeatureFlags] = useLazyQuery(FETCH_FEATURE_FLAGS, {
    onCompleted: (data) => {
      if (data?.fetchFeatureFlags?.length > 0) {
        setFeaturesBugs(data.fetchFeatureFlags[0]);
        featureFlagsVar(data.fetchFeatureFlags[0]);
      }
    },
  });

  const [loadUser] = useLazyQuery(USER_DETAILS, {
    onCompleted: (data) => {
      if (data) {
        const {
          fetchUser: {
            profile: {
              companyname,
              name,
              address,
              image,
              vatnumber,
              contactnumber,
              terms,
              whatsappNumber,
              seenTour,
            },
            emails,
            _id,
          },
        } = data;

        userProfileVar({
          name,
          companyname,
          email: emails[0].address,
          address,
          image,
          contactnumber,
          vatnumber,
          terms,
          whatsappNumber,
          seenTour,
        });
        userVar({ ...user, ...data.fetchUser });

        if (data.fetchUser.createdAt && !data?.fetchUser?.wixApp?.instanceId) {
          const { fetchUser } = data;
          updateIntercomUser(fetchUser, update);
        }

        utils.updateClarity({ _id, name, email: emails?.[0].address });
        setIsLoadingUser(false);

        if (fromAppVar() === 'monday') {
          if (
            utils.verifyMondayAccount(
              data?.fetchUser?.monday?.account?.account?.id,
              appSessionTokenVar()
            )
          ) {
            history.push('/invalid');
          }
        }
      }
    },
    onError: () => setIsLoadingUser(false),
  });

  useEffect(() => {
    if (
      isLoggedIn &&
      !user &&
      location.pathname !== '/xero' &&
      location.pathname !== '/monday' &&
      location.pathname !== '/oauth/wix' &&
      !location.pathname.includes('/proposal-preview') &&
      !templateWixPreview
    ) {
      loadUser({
        fetchPolicy: 'network-only',
        variables: {
          id: getUserId(),
          type: window.instanceId ? 'wix-user' : '',
        },
      });
    } else if (
      !location.pathname.includes('/proposal-editor') &&
      !location.pathname.includes('/template-editor')
    ) {
      setIsLoadingUser(false);
      utils.loginredirect(user, history);
    }
  }, [isLoggedIn, loadUser, user, history, location.pathname, path]);

  useEffect(() => {
    if (!featuresBugs) {
      fetchFeatureFlags({
        fetchPolicy: 'network-only',
      });
    }
  }, [fetchFeatureFlags, featuresBugs]);

  useEffect(() => {
    if (path !== location.pathname) {
      setPath(location.pathname);
      if (user) utils.loginredirect(user, history);
    }
    if (location.pathname === '/livelongandprosper' && window.clarity) {
      window.clarity('pause');
    } else if (window?.clarity) {
      window.clarity('resume');
    }

    // ------hide userway widget-----
    const previewPath = location.pathname.substring(0, 3);
    if (
      previewPath !== '/pd' &&
      previewPath !== '/cd' &&
      (previewPath.includes('/proposal-editor') || previewPath.includes('/template-editor'))
    ) {
      try {
        window?.UserWay.iconVisibilityOff();
        window?.UserWay.resetAll();
      } catch (e) { }
    }
    // ------hide userway widget-----
  }, [user, location, history, path]);

  const [updateProposal] = useMutation(UPDATE_PROPOSAL_MUTATION);

  const [updateTemplate] = useMutation(UPDATE_LIBRARY_TEMPLATE_MUTATION);

  const [updateSection] = useMutation(UPDATE_LIBRARY_SECTION_MUTATION);

  const [updateFeatureFlag] = useMutation(UPDATE_FEATURE_FLAG_MUTATION);

  useEffect(() => {
    if (!isOnline) {
      if (isLoggedIn) {
        messageApi.open({
          type: 'error',
          content: 'Network offline. Recent changes are not saved',
          duration: 0,
        });
      } else {
        message.destroy();
      }
    } else {
      if (isOnline) {
        const unsavedProposal = localStorage.getItem('unsavedproposal');

        if (unsavedProposal && path !== '/d/:pid' && path !== '/t/:tid' && path !== '/s/:sid') {
          const proposal = JSON.parse(unsavedProposal);
          localStorage.setItem('unsavedproposal', '');
          if (proposal?.templateName) {
            updateTemplate({
              variables: {
                topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${proposal?.channel || proposal?.auid || proposal?.uid
                  }`,
                updateType: 'edit',
                template: { ...proposal, id: proposal._id },
              },
            });
          } else if (proposal.sectionId) {
            updateSection({
              variables: {
                topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${proposal?.channel || proposal?.auid || proposal?.uid
                  }`,
                updateType: 'edit',
                section: {
                  ...proposal.draft[proposal.sectionId],
                  thumbnail: '',
                },
              },
            });
          } else {
            updateProposal({
              variables: {
                topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${proposal?.channel || proposal?.auid || proposal?.uid
                  }`,
                updateType: 'edit',
                proposal: {
                  ...proposal,
                  id: proposal._id,
                },
              },
            });
          }
        }
        message.destroy();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline, isLoggedIn]);

  if (isLoadingUser) {
    return <Loader />;
  }

  const showHeaderSidebar = NO_HEADER_SIDEBAR_ROUTES.some((disabledRoute) =>
    location.pathname.includes(disabledRoute)
  );

  const everPayed = user?.paymentStatus?.everPayed;
  const canLock = user?.paymentStatus?.canLock;

  const trialTime = () => {
    if (!user) {
      return 666;
    }
    return daystrial - moment().diff(moment(user.stopperAnchor || user.createdAt), 'days');
  };

  const numberOfDaysLeft = daystrial - Math.ceil(trialTime());

  const updateFeature = async (key, newValue) => {
    // Update loading state
    const updateLoadingState = (isLoading) =>
      setFlagItemLoading((prevState) => ({
        ...prevState,
        [key]: isLoading,
      }));

    updateLoadingState(true);

    try {
      const response = await updateFeatureFlag({
        variables: {
          _id: '6733c17e6892d45eaaa5933e',
          key,
          value: newValue,
        },
      });

      if (response.data?.updateFeatureFlag) {
        const updatedState = (prevFeatures) => ({
          ...prevFeatures,
          [key]: newValue,
        });

        setFeaturesBugs((prevFeatures) => updatedState(prevFeatures));

        const newFeatureFlags = updatedState(featureFlagsVar());
        featureFlagsVar(newFeatureFlags);
      }
    } catch (error) {
      console.error('Failed to update feature flag:', error);
    } finally {
      // Reset loading state
      updateLoadingState(false);
    }
  };

  const flagEntries = featuresBugs ? Object.entries(featuresBugs) : [];

  return (
    <Layout className={`${canLock ? 'handle-user-lock' : ''}`}>
      {messageContextHolder}
      {!showHeaderSidebar && (
        <>
          <PropsRoute component={HeaderBar} loggedIn={isLoggedIn} user={user} location={location} />
          <PropsRoute component={AdminHeaderBar} {...props} />
        </>
      )}
      <Layout>
        {isLoggedIn && !showHeaderSidebar && (
          <PropsRoute component={SideBar} user={user} isLocked={canLock} {...props} />
        )}
        <Content className="main-content">
          {canLock && (
            <TrailEndHeader isTeamMember={!!user?.teamId} everPayed={everPayed || false} />
          )}

          <Routes {...props} loggedIn={isLoggedIn} />

          {canLock && !userLockedPopup && !user?.teamId ? (
            <TrialEndPopup
              userLockedPopup={userLockedPopup}
              setUserLockedPopup={setUserLockedPopup}
              everPayed={everPayed || false}
            />
          ) : !everPayed &&
            !seenReminder &&
            daystrial - numberOfDaysLeft < 8 &&
            !user?.teamId &&
            !user?.wixApp ? (
            <TrialEndReminder
              userLockedPopup={userLockedPopup}
              setUserLockedPopup={setUserLockedPopup}
              daysLeft={daystrial - numberOfDaysLeft}
            />
          ) : null}
        </Content>
      </Layout>
      {showFlagsToggle && (
        <>
          {openFeaturesDrawer && (
            <>
              <Layout.Sider
                className={`feature-flags-sider ${!openFeaturesDrawer ? 'sider-is-collapsed' : ''
                  }`}>
                <div className="title">
                  <h3>Feature Flags</h3>
                  <div className="divider" />
                </div>
                <div className="content-flags">
                  {flagEntries.map(([key, value]) => (
                    <div className="feature-flag-item" key={key}>
                      <label title={key} htmlFor={key} className="check-label">
                        {key}
                      </label>
                      <Switch
                        id={key}
                        checked={value}
                        onChange={(newValue) => updateFeature(key, newValue)}
                        loading={flagItemLoading[key] || false}
                      />
                    </div>
                  ))}
                </div>
              </Layout.Sider>
              <div
                className="feature-flags-sider-overlay-toggle"
                onClick={() => setOpenFeaturesDrawer(!openFeaturesDrawer)}
              />
            </>
          )}
          <div
            className={`feature-flags-sider-collapse ${!openFeaturesDrawer ? 'feature-flags-sider-collapse-open' : ''
              }`}
            onClick={() => setOpenFeaturesDrawer(!openFeaturesDrawer)}>
            {!openFeaturesDrawer ? <TechnicalIcon /> : <CloseIcon />}
            <span className="btn-text">Feature Flags</span>
          </div>
        </>
      )}
    </Layout>
  );
};

function App(props) {
  // const [shouldPoll, setShouldPolling] = useState(true);
  const featureFlags = useReactiveVar(featureFlagsVar) || {};

  const [loading, setLoading] = useState(false);
  const [isOnline, setOnline] = useState(true);
  const isClientOnline = NetworkStatus();

  wsLink.subscriptionClient.onDisconnected(() => {
    setOnline(false);
  });

  wsLink.subscriptionClient.onConnected(() => {
    setOnline(true);
  });

  wsLink.subscriptionClient.onReconnected(() => {
    setOnline(true);
  });

  useEffect(() => {
    // await before instantiating ApolloClient, else queries might run before the cache is persisted
    if (window.navigator.cookieEnabled) {
      persistCache({
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
      }).then(() => {
        setLoading(false);
      });
    }

    let fromApp = utils.getParameterByNameOldWay('referrer', window?.location?.href);
    if (!!fromApp) {
      fromAppVar(fromApp);
    }

    let boardId = utils.getParameterByNameOldWay('boardId', window?.location?.href);
    if (!!boardId) {
      mondayBoardIdVar(boardId);
      localStorage.setItem('boardId', boardId);
    }

    let sessionToken = utils.getParameterByNameOldWay('sessionToken', window?.location?.href);
    if (!!sessionToken) {
      appSessionTokenVar(sessionToken);
      localStorage.setItem('sessionToken', sessionToken);
    }

    if (
      !window.instanceId &&
      ((window.location.href.includes('/proposal-editor') &&
        utils.getQueryStringValue('proposalId')) ||
        (window.location.href.includes('/template-editor') &&
          utils.getQueryStringValue('templateId')) ||
        (window.location.href.includes('/proposal-preview') &&
          utils.getQueryStringValue('proposalId') &&
          utils.getQueryStringValue('owner') === 'true') ||
        (templateWixPreview && utils.getQueryStringValue('templateId')))
    ) {
      setLoading(true);

      /* use for testing purposes uncomment the following code */
      /* use this URL localhost:3000/proposal-editor?proposalId=1e80b80b-3ef6-4298-b4c2-4e82b9cfb83f&templateId=custom_blank_id */
      /* comment out the Wix code after the following code */
      // window.instanceId = '0eb86a64-305a-48c3-81c4-0a2b0f420675'; //decoded.instanceId;
      // window.proposalApi = {
      //   send: () => {
      //     console.log('send');
      //   },
      //   onSave: () => {
      //     console.log('save');
      //   },
      //   edit: () => {
      //     console.log('edit');
      //   },
      //   onLoaded: () => {
      //     console.log('loaded');
      //   },
      // };
      // window.proposalId = '5a9604cf-0b51-444f-adb4-0fb6c3f69809'; //proposalId;
      // window.templateId = '65785824663604254d5aefa9'; //templateId;
      // window.wixTemplateId = '73de0d18-3f3f-4cd9-a8ba-b2ae15b8c55b'; //wixTemplateId;
      // if (!window.location.pathname.includes('/proposal-preview')) {
      //   isLoggedInVar(true);
      // }
      // setLoading(false);
      /* use for testing purposes */
      try {
        const wixClient = createClient({
          host: dashboard.host(),
          auth: dashboard.auth(),
          modules: { dashboard },
        });

        wixClient?.dashboard?.observeState((state) => {
          const { proposalApi, proposalId, templateId } = state;
          console.log(`proposalId: ${proposalId}, templateId: ${templateId} `, proposalApi, state);
          const instance = utils.getQueryStringValue('instance');
          const decoded = jwt_decode(instance);
          console.log(instance, decoded, '=-=-intance');

          window.instanceId = decoded.instanceId;
          window.proposalApi = proposalApi;
          window.proposalId = proposalId;
          window.templateId = templateId;
          window.wixTemplateId = window.location.href.includes('/template-editor')
            ? templateId
            : '';
          if (!window.location.pathname.includes('/proposal-preview')) {
            isLoggedInVar(true);
          }
          setLoading(false);
        });

        if (utils.getQueryStringValue('proposalId')) {
          window.proposalId = utils.getQueryStringValue('proposalId');
        } else if (utils.getQueryStringValue('templateId')) {
          window.templateId = utils.getQueryStringValue('templateId');
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (utils.getQueryStringValue('proposalId') && utils.getQueryStringValue('owner') !== 'true') {
      window.proposalId = utils.getQueryStringValue('proposalId');
    }

    return () => { };
  }, []);

  props = { ...props /*, shouldPoll: shouldPoll*/, isOnline: isClientOnline && isOnline };

  const isIntercomActive =
    window.location.href.indexOf('/PDF/') !== -1 ||
      window.location.href.indexOf('/cd/') !== -1 ||
      window.location.href.indexOf('/proposal-preview') !== -1 ||
      window.location.href.indexOf('/proposal-editor') !== -1 ||
      window.location.href.indexOf('/template-editor') !== -1 ||
      templateWixPreview
      ? false
      : true;

  return !window.navigator.cookieEnabled &&
    utils.getParameterByName('referrer', window?.location?.href) === 'monday' ? (
    <CookieConsent
      onAccept={() => {
        window.location.reload(false);
      }}
      buttonText="Refresh"
      overlay={true}>
      Prospero uses cookies to enhance the user experience, please enable 3rd party cookies from
      your browser & refresh the app.
    </CookieConsent>
  ) : loading ? (
    <div>Loading</div>
  ) : (
    <ApolloProvider client={client}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <ContextProvider featureFlags={featureFlags} isOnline={isOnline}>
          <Router>
            <IntercomProvider
              appId={process.env.REACT_APP_INTERCOM_APP_ID}
              autoBoot={isIntercomActive}
              shouldInitialize={
                !window.location.pathname.includes('/proposal-editor') &&
                !window.location.pathname.includes('/template-editor')
              }>
              <AppContainer {...props} />
            </IntercomProvider>
            {!window.location.pathname.match(/\/cd\//) && (
              <SessionTimeout /*setPolling={setShouldPolling}*/ />
            )}
          </Router>
        </ContextProvider>
      </GoogleOAuthProvider>
    </ApolloProvider>
  );
}

export default App;
