import React, { useEffect, useState } from 'react';
import { Button, Modal, Col, Row, Select, Form } from 'antd';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import CloseIcon from 'components/Icons/CloseIcon';
import DropdownIcon from 'components/Icons/DropdownIcon';
import utils from 'utils/utils';
import { FETCH_XERO_ACCOUNTS } from 'graphql/queries/integrationQueries';
import Loader from 'components/Loader';

import './XeroAccountMappingModal.scss';

const { Option } = Select;

const GenerateInvoiceModal = ({
  prop,
  tenantId,
  onConfirm,
  setShowXeroAccountMappingModal,
  setLoadingGenerate,
  absoluteTotal,
  absoluteCurr,
}) => {
  const [accounts, setAccounts] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [accountMaps, setAccountMaps] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);

  const [fetchXeroAccounts, { loading: isLoading }] = useLazyQuery(FETCH_XERO_ACCOUNTS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      tenantId: tenantId,
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ fetchXeroAccounts }) => {
      if (fetchXeroAccounts) {
        const accounts = JSON.parse(JSON.stringify(fetchXeroAccounts));
        let thetable = utils.findTableInProp(prop);
        if (!thetable || !thetable.deliverables || !thetable.deliverables.chosen) {
          thetable = {
            curr: absoluteCurr,
            deliverables: {
              chosen: {
                'OPTION 1': {
                  list: [
                    {
                      name: utils.getTitleText(prop, 'header', ' '),
                      price: absoluteTotal,
                    },
                  ],
                },
              },
            },
          };
        }
        const thelist = utils.findListInTable(thetable);
        if (thelist.selectable) {
          thelist.list = thelist.list.filter((l) => l.checked);
        }
        setLineItems(thelist?.list || []);
        setAccounts(accounts);
        const mapData = [];
        for (let i = 0; i < thelist?.list.length; i++) {
          mapData.push({
            index: i,
            name: thelist?.list[i].name,
            accountCode: accounts[0].code,
          });
        }
        setLoadingGenerate(false);
        setAccountMaps(mapData);
      } else {
        setLoadingGenerate(false);
        setAccountMaps([]);
      }
    },
  });

  useEffect(() => {
    fetchXeroAccounts();
  }, [fetchXeroAccounts]);

  const onCancel = () => {
    setShowXeroAccountMappingModal(false);
  };

  const setAccountMapping = (name, index, accountCode) => {
    let mapData = [...accountMaps];
    let append = true;
    for (let i = 0; i < mapData.length; i++) {
      if (mapData[i].index === index) {
        append = false;
        mapData[i] = { index, name, accountCode };
      }
    }
    if (append) {
      mapData.push({
        index,
        name,
        accountCode,
      });
    }
    setAccountMaps(mapData);
    setFilteredAccounts([]);
  };

  const submit = () => {
    onConfirm(accountMaps);
  };

  const onSearch = (data) => {
    if (data === '') {
      setFilteredAccounts([]);
    } else {
      let filteredData = [];
      for (const accountData of accounts) {
        if (
          accountData.name.includes(data) ||
          accountData.code.includes(data) ||
          accountData.taxName.includes(data) ||
          accountData.taxRate.includes(data)
        ) {
          filteredData.push(accountData);
        }
      }
      setFilteredAccounts(filteredData);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Modal
      className="simple-info-modal account-map"
      centered
      visible
      onCancel={onCancel}
      footer={null}
      width={900}
      closeIcon={<CloseIcon />}>
      <h3 className="title">Xero account mapping</h3>
      <div className="divider" />

      <div className="body-accounts-map">
        <Col>
          {!!lineItems && accounts.length && (
            <>
              <Form>
                <Row className="accounts-head">
                  <Col span={12}>Item</Col>
                  <Col span={12}>Xero Accounts</Col>
                </Row>
                {lineItems.map((lineItem, index) => (
                  <Row key={index}>
                    <Col span={12}>{lineItem.name}</Col>
                    <Col span={12}>
                      <Select
                        className="xero-account-select"
                        defaultValue={`
                          ${accounts[0].name} (${accounts[0].taxName} ${accounts[0].taxRate}%)
                        `}
                        onChange={(accountCode) => {
                          setAccountMapping(lineItem.name, index, accountCode);
                        }}
                        showSearch
                        onSearch={onSearch}
                        filterOption={false}
                        suffixIcon={<DropdownIcon />}>
                        {filteredAccounts.length > 0
                          ? filteredAccounts?.map((account, accountIndex) => (
                              <Option key={account.code || accountIndex} value={account.code}>
                                {account.name} ({account.taxName} {account.taxRate}%)
                              </Option>
                            ))
                          : accounts?.map((account, accountIndex) => (
                              <Option key={account.code || accountIndex} value={account.code}>
                                {account.name} ({account.taxName} {account.taxRate}%)
                              </Option>
                            ))}
                      </Select>
                    </Col>
                  </Row>
                ))}
              </Form>
            </>
          )}
        </Col>
      </div>

      <div className="button-wrapper">
        <Button className="button confirm account-map-button" type="primary" onClick={submit}>
          Generate
        </Button>
        <Button className="button cancel account-map-button" type="primary" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

GenerateInvoiceModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default GenerateInvoiceModal;
