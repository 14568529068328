import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { List, Button } from 'antd';

import PopConfirm from 'components/PopConfirm';

const RefundsModal = ({ visible, setVisible, userInfo, handleSubmit }) => {
  return (
    <PopConfirm
      title="Refund Invoices!"
      body={
        <List
          className="refund-modal-list"
          itemLayout="horizontal"
          dataSource={userInfo.payments}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  disabled={
                    item?.charges?.data?.[0]?.amount_refunded || item?.L_STATUS === 'Refunded'
                      ? true
                      : ''
                  }
                  onClick={() => {
                    handleSubmit('refundCharges', userInfo, {
                      chargeId: item?.charges?.data[0]?.id,
                      transactionId: item?.L_TRANSACTIONID,
                    });
                  }}>
                  {item?.charges?.data?.[0]?.amount_refunded || item?.L_STATUS === 'Refunded'
                    ? 'Refunded'
                    : 'Refund'}
                </Button>,
              ]}>
              <List.Item.Meta
                title={<a href="https://ant.design">${item?.amount / 100 || item?.L_AMT}</a>}
              />
              <div>
                {item?.charges?.data[0]?.created
                  ? moment.unix(item?.charges?.data[0]?.created).format('DD/MM/YYYY')
                  : moment(item?.L_TIMESTAMP).format('DD/MM/YYYY')}
              </div>
            </List.Item>
          )}
        />
      }
      visible={visible}
      cancelText="Close"
      onCancel={() => setVisible(false)}
      onConfirm={() => {
        handleSubmit();
        setVisible(false);
      }}
    />
  );
};

RefundsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  userInfo: PropTypes.instanceOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default RefundsModal;
