import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { AtomicBlockUtils } from 'draft-js';
import { useIntercom } from 'react-use-intercom';
import PropTypes from 'prop-types';

import VideoModal from './VideoModal/VideoModal';
import GalleryEditModal from './GalleryEditModal/GalleryEditModal';
import PriceEditModal from './PriceEditModal/PriceEditModal';
import MilesEditModal from './MilesEditModal/MilesEditModal';
import SuperEditModal from './SuperEditModal/SuperEditModal';
import HtmlEditModal from './HtmlEditModal/HtmlEditModal';
import DraftContext from 'context/DraftContext';

import { CHECK_VIDEO_OEMBED_MUTATION } from 'graphql/mutations/proposalMutation';

const RichEditorModals = ({
  user,
  prop,
  config,
  state,
  dispatch,
  handleEditorChange,
  saveProposal,
  saveUser,
  focusEditor,
  checkSuspiciousUrl,
}) => {
  const { trackEvent } = useIntercom();

  const [videoOembedError, setVideoOembedError] = useState('');
  const [savingRichEditorModal, setSavingRichEditorModal] = useState(false);

  const [checkVideoOembed] = useMutation(CHECK_VIDEO_OEMBED_MUTATION, {
    onCompleted: async ({ handleOembed }) => {
      if (!handleOembed.html) {
        if (!prop?.wixProposalId) {
          trackEvent('insert-video-error', {
            e: 'no-html-toembed',
            link: handleOembed.html,
          });
        }

        setVideoOembedError(
          'Problem with embedding this link, contact support or try another link'
        );
        setSavingRichEditorModal(false);
        return;
      }

      trackEvent('insert-video-ok', {
        link: handleOembed.html,
      });

      const editorState = state.videoDialog;
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity('media', 'IMMUTABLE', handleOembed);
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const es = await AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');

      trackEvent('edit-pop', {
        type: 'video',
      });

      handleEditorChange({ es });
      setSavingRichEditorModal(false);
      dispatch({ type: 'setVideoDialog', videoDialog: false });
      dispatch({ type: 'setDropEditorState', dropEditorState: '' });
    },
    onError: (error) => {
      setSavingRichEditorModal(false);
      setVideoOembedError('Problem with embedding this link, contact support or try another link');
    },
  });

  const removeItem = (type, name) => {
    const userConfig = {};
    let newValue;

    if (type === 'super') {
      newValue = userConfig && userConfig[type] ? userConfig[type] : {};
      delete newValue[name];
    } else {
      newValue = userConfig && userConfig[type] ? userConfig[type] : [];
      newValue = newValue.filter((value) => value.name !== name);
    }
  };

  return (
    <>
      {state.videoDialog && (
        <VideoModal
          videoOembedError={videoOembedError}
          setVideoOembedError={setVideoOembedError}
          savingRichEditorModal={savingRichEditorModal}
          visible={!!state.videoDialog}
          onCancel={() => dispatch({ type: 'setVideoDialog', videoDialog: false })}
          onConfirm={({ videoLink }) => {
            if (!videoLink) {
              return setVideoOembedError('Video link is required.');
            }
            setSavingRichEditorModal(true);
            checkVideoOembed({
              variables: {
                link: videoLink,
              },
            });
            focusEditor();
          }}
        />
      )}
      {state.galleryEdit && (
        <GalleryEditModal
          visible={!!state.galleryEdit}
          savingRichEditorModal={savingRichEditorModal}
          onCancel={() => {
            dispatch({ type: 'setGalleryEdit', galleryEdit: false });
            state.galleryEdit.cancel();
          }}
          onConfirm={(galleryEdit) => {
            setSavingRichEditorModal(true);

            const newState = {
              data: galleryEdit.images,
              config: galleryEdit.config,
            };

            state.galleryEdit.save(newState, () => {
              if (!prop?.wixProposalId) {
                trackEvent('edit-pop', {
                  type: 'gallery',
                });
              }
            });

            setTimeout(() => {
              setSavingRichEditorModal(false);
              dispatch({ type: 'setGalleryEdit', galleryEdit: false });
            }, 200);
            focusEditor();
          }}
          galleryEdit={state.galleryEdit}
          prop={prop}
          isWix={prop?.wixProposalId}
        />
      )}
      {state.priceEdit && (
        <DraftContext.Consumer>
          {({ wixProductsAndServices, wixSiteProperties }) => (
            <PriceEditModal
              visible={!!state.priceEdit}
              savingRichEditorModal={savingRichEditorModal}
              onCancel={() => {
                dispatch({ type: 'setPriceEdit', priceEdit: false });
                state.priceEdit.cancel();
              }}
              onConfirm={(tempProp) => {
                setSavingRichEditorModal(true);
                state.priceEdit.save(JSON.stringify(tempProp), () => {
                  trackEvent('edit-pop', {
                    type: 'price',
                  });

                  setSavingRichEditorModal(false);
                  dispatch({ type: 'setPriceEdit', priceEdit: false });
                  focusEditor();
                });
              }}
              prop={prop}
              user={user}
              config={config}
              removeItem={removeItem}
              priceEdit={state.priceEdit}
              dispatch={dispatch}
              saveUser={saveUser}
              wixProductsAndServices={wixProductsAndServices}
              wixSiteProperties={wixSiteProperties}
            />
          )}
        </DraftContext.Consumer>
      )}
      {state.timeEdit && (
        <MilesEditModal
          visible={!!state.timeEdit}
          savingRichEditorModal={savingRichEditorModal}
          onCancel={() => {
            dispatch({ type: 'setTimeEdit', timeEdit: false });
            state.timeEdit.cancel();
            setSavingRichEditorModal(false);
          }}
          onConfirm={(tempProp) => {
            setSavingRichEditorModal(true);
            state.timeEdit.save(JSON.stringify(tempProp), () => {
              trackEvent('edit-pop', {
                type: 'miles',
              });

              setSavingRichEditorModal(false);
              dispatch({ type: 'setTimeEdit', timeEdit: false });
            });
            focusEditor();
          }}
          prop={prop}
          user={user}
          config={config}
          saveProposal={saveProposal}
          removeItem={removeItem}
          timeEdit={state.timeEdit}
          dispatch={dispatch}
          saveUser={saveUser}
        />
      )}
      {state.superEdit && (
        <DraftContext.Consumer>
          {({ wixSiteProperties }) => (
            <SuperEditModal
              visible={!!state.superEdit}
              savingRichEditorModal={savingRichEditorModal}
              onCancel={() => {
                dispatch({ type: 'setSuperEdit', superEdit: false });
                state.superEdit.cancel();
                setSavingRichEditorModal(false);
              }}
              onConfirm={(tempProp) => {
                setSavingRichEditorModal(true);
                state.superEdit.save(JSON.stringify(tempProp), () => {
                  trackEvent('edit-pop', {
                    type: 'supers',
                  });

                  setSavingRichEditorModal(false);
                  dispatch({ type: 'setSuperEdit', superEdit: false });
                });
                focusEditor();
              }}
              prop={prop}
              user={user}
              config={config}
              saveProposal={saveProposal}
              removeItem={removeItem}
              superEdit={state.superEdit}
              dispatch={dispatch}
              saveUser={saveUser}
              wixSiteProperties={wixSiteProperties}
            />
          )}
        </DraftContext.Consumer>
      )}
      {state.htmlEdit && (
        <HtmlEditModal
          htmlEdit={state.htmlEdit}
          savingRichEditorModal={savingRichEditorModal}
          visible={!!state.htmlEdit}
          onCancel={() => dispatch({ type: 'setHtmlEdit', htmlEdit: false })}
          onConfirm={async (htmlCode) => {
            setSavingRichEditorModal(true);

            // find all url from html and check for spam
            checkSuspiciousUrl(htmlCode, 'html');

            if (state?.htmlEdit?.save) {
              state.htmlEdit.save(htmlCode, () => {
                dispatch({ type: 'setHtmlEdit', htmlEdit: false });
                setSavingRichEditorModal(false);
              });
            } else {
              const editorState = state?.htmlEdit?.editorState;
              if (editorState) {
                const contentState = editorState.getCurrentContent();
                const contentStateWithEntity = contentState.createEntity('html', 'IMMUTABLE', {
                  htmlCode: htmlCode,
                });
                const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
                const es = await AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
                handleEditorChange({ es });
              }
              dispatch({ type: 'setHtmlEdit', htmlEdit: false });
              setSavingRichEditorModal(false);
            }
            if (!prop?.wixProposalId) {
              trackEvent('edit-pop', {
                type: 'html',
              });
            }
            focusEditor();
          }}
        />
      )}
    </>
  );
};

RichEditorModals.defaultProps = {
  prop: '',
  config: '',
  saveProposal: () => {},
};

RichEditorModals.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  handleEditorChange: PropTypes.func.isRequired,
  prop: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  config: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  saveProposal: PropTypes.func,
  saveUser: PropTypes.func.isRequired,
};

export default RichEditorModals;
