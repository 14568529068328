/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="29" height="24" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3" y="4" width="23" height="16" rx="1.96923" fill="#CBCED8" />
    <rect opacity="0.3" x="3" y="8.12891" width="23" height="3.6129" fill="#05034D" />
    <rect
      opacity="0.3"
      x="5"
      y="13.8066"
      width="6.5"
      height="1.54839"
      rx="0.774194"
      fill="#05034D"
    />
    <rect
      opacity="0.3"
      x="5"
      y="16.3867"
      width="3.5"
      height="1.54839"
      rx="0.774194"
      fill="#05034D"
    />
  </svg>
);

const CreditCardIcon = (props) => <Icon component={svg} {...props} />;

export default CreditCardIcon;
