/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="4" fill="#F5F6FA" />
    <path
      d="M8 12C8 11.4477 8.44772 11 9 11H15C15.5523 11 16 11.4477 16 12C16 12.5523 15.5523 13 15 13H9C8.44772 13 8 12.5523 8 12Z"
      fill="#2F2C88"
    />
    <rect x="13" y="8" width="8" height="2" rx="1" transform="rotate(90 13 8)" fill="#2F2C88" />
  </svg>
);

const AddBlockIcon = (props) => <Icon component={svg} {...props} />;

export default AddBlockIcon;
