import React from 'react';
import Icon from '@ant-design/icons';

const InfoSvg = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7.5" cy="7.5" r="7.5" fill="#7876C1" />
    <path
      d="M7.68044 4.56004C7.38444 4.56004 7.13644 4.46804 6.93644 4.28404C6.74444 4.09204 6.64844 3.85604 6.64844 3.57604C6.64844 3.29604 6.74444 3.06404 6.93644 2.88004C7.13644 2.68804 7.38444 2.59204 7.68044 2.59204C7.97644 2.59204 8.22044 2.68804 8.41244 2.88004C8.61244 3.06404 8.71244 3.29604 8.71244 3.57604C8.71244 3.85604 8.61244 4.09204 8.41244 4.28404C8.22044 4.46804 7.97644 4.56004 7.68044 4.56004ZM8.50844 5.35204V12H6.82844V5.35204H8.50844Z"
      fill="white"
    />
  </svg>
);

const InfoIcon = (props) => <Icon component={InfoSvg} {...props} />;

export default InfoIcon;
