/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.2" width="40" height="40" rx="4" fill="#C7BCE4" />
    <circle cx="19.498" cy="12.8125" r="6.56248" fill="#7876C1" />
    <path
      d="M19.5 17.1876C12.6169 17.1876 6.97127 23.369 6.41927 31.2333C6.37029 31.9311 6.94211 32.5 7.64167 32.5H31.3583C32.0578 32.5 32.6296 31.9311 32.5807 31.2333C32.0287 23.369 26.3831 17.1876 19.5 17.1876Z"
      fill="#7876C1"
    />
    <path
      d="M31.0995 31.7245L29.9453 29.1651C29.8449 29.115 29.7445 29.0648 29.6442 29.0648H16.2956C15.3422 26.7062 12.9836 25 10.2737 25C6.66058 25 3.75 27.9106 3.75 31.5237C3.75 35.1369 6.66058 38.0474 10.2737 38.0474C12.9836 38.0474 15.3422 36.3914 16.2956 33.9827H18.8549C18.9553 33.9827 19.0557 33.9325 19.156 33.8823L20.3604 32.6779C20.511 32.5274 20.7619 32.5274 20.9626 32.6779L22.167 33.8823C22.2673 33.9827 22.3677 33.9827 22.4681 33.9827H23.6223C23.7226 33.9827 23.823 33.9325 23.9234 33.8823L25.1277 32.6779C25.2783 32.5274 25.5292 32.5274 25.7299 32.6779L26.9343 33.8823C27.0347 33.9827 27.135 33.9827 27.2354 33.9827H28.6405C28.7409 33.9827 28.8412 33.9325 28.9416 33.8823L31.0995 32.3266C31.3002 32.1761 31.3002 31.9252 31.0995 31.7245ZM10.1232 33.4307C9.06934 33.4307 8.21624 32.5776 8.21624 31.5237C8.21624 30.4699 9.06934 29.6168 10.1232 29.6168C11.177 29.6168 12.0301 30.4699 12.0301 31.5237C11.9799 32.5776 11.177 33.4307 10.1232 33.4307Z"
      fill="#C7BCE4"
    />
    <circle cx="10.0969" cy="31.3462" r="3.17308" fill="#F4F2FA" />
  </svg>
);

const UserPermission = (props) => <Icon component={svg} {...props} />;

export default UserPermission;
