import React from 'react';
import { ContentState } from 'draft-js';
import PropTypes from 'prop-types';

import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';

const VideoComponent = ({ block, blockProps }) => {
  const contentState = ContentState.createFromBlockArray([block]);
  const ent = block.getEntityAt(0);
  const entity = contentState.getEntity(ent);

  const { data } = entity;

  const videoHtml = data.html;
  const temp = document.createElement('div');
  temp.innerHTML = videoHtml;

  const theIframe = temp.firstChild;
  let aspect = 56.25;

  const width = Number(theIframe.width);
  const height = Number(theIframe.height);
  if (width && height) {
    aspect = (height / width) * 100;
  }

  const handleBlur = () => {
    blockProps.handleEditComponent(false);
  };

  const handleRemove = () => blockProps?.onRemove(block.getKey());

  return (
    <ComponentWrapper
      showDrag
      showActionButtons
      showDelete
      isDraggable
      sectionName={blockProps.sectionName}
      setDraggingElement={blockProps.setDraggingElement}
      blockKey={block.getKey()}
      remove={handleRemove}
      onBlur={handleBlur}
      componentType={'Video'}
      handleEditComponent={blockProps.handleEditComponent}
      setDropDisabled={blockProps.setDropDisabled}>
      {!videoHtml ? (
        <div className="gallery-container" onClick={blockProps.editComponent}>
          <div className="gallery-image-container">
            <div className="gallery-uploader">
              <div>Add Video</div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="media-component image-component"
          style={{ textAlign: data['text-align'] || 'center' }}>
          <div
            className="embed-wrap"
            dangerouslySetInnerHTML={{ __html: videoHtml }}
            style={{ paddingBottom: `${aspect}%` }}
          />
        </div>
      )}
    </ComponentWrapper>
  );
};

VideoComponent.propTypes = {
  block: PropTypes.instanceOf(Object).isRequired,
};

export default VideoComponent;
