/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="29" height="24" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.14455 8.83763L9.14454 8.83764L10.668 9.76923H10.6707H18.55C18.9381 9.76923 19.3081 9.92182 19.5792 10.1899C19.8499 10.4577 20 10.8185 20 11.1923V21.5769C20 21.9508 19.8499 22.3115 19.5792 22.5793C19.3081 22.8474 18.9381 23 18.55 23H2.45C2.06189 23 1.69188 22.8474 1.42078 22.5793C1.15005 22.3115 1 21.9508 1 21.5769V8.42308C1 8.04925 1.15005 7.68846 1.42078 7.42071C1.69188 7.15259 2.06189 7 2.45 7L7.532 7.00001L7.53394 7C7.74098 6.9996 7.94329 7.0564 8.118 7.16303C8.29243 7.26949 8.43187 7.42099 8.52201 7.59931C8.5221 7.5995 8.52219 7.59968 8.52229 7.59986L9.14455 8.83763Z"
      fill="white"
      stroke="#05034D"
      strokeWidth="2"
    />
    <line
      x1="17"
      y1="5"
      x2="17"
      y2="1"
      stroke="#2F2C88"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="23.8485"
      y1="10.8686"
      x2="27.8083"
      y2="10.3029"
      stroke="#2F2C88"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="21.8468"
      y1="6.57275"
      x2="24.5941"
      y2="3.15324"
      stroke="#2F2C88"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const AddFolder = (props) => <Icon component={svg} {...props} />;

export default AddFolder;
