import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg fill="#8281A6" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="3" r="1.3" />
    <circle cx="8" cy="8" r="1.3" />
    <circle cx="8" cy="13" r="1.3" />
  </svg>
);

const ClosedVariableMenuIcon = (props) => <Icon component={svg} {...props} />;

export default ClosedVariableMenuIcon;
