import { Select, Form } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const { Option, OptGroup } = Select;

const FontSelect = ({ customFonts, fontLists, defaultFont, font, onChange, id }) => {
  return (
    <Form.Item label="Font" colon={false} style={{ position: 'relative' }} className={id}>
      <Select
        allowClear
        showSearch
        placeholder="Verdana"
        getPopupContainer={() => document.querySelector(`.${id}`)}
        value={font.family || font.title}
        suffixIcon={<CaretDownOutlined color="red" />}
        filterOption={(input, option) =>
          (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
        }
        onChange={(value) => {
          if (value === undefined) {
            value = defaultFont;
          }
          onChange(value);
        }}>
        {customFonts?.length > 0 && (
          <>
            <OptGroup key="uploadedFonts" label="Uploaded Fonts">
              {customFonts.map((font) => (
                <Option key={font.family} style={{ fontFamily: font.family }}>
                  {font.family}
                </Option>
              ))}
            </OptGroup>
            <Option className="divider" />
          </>
        )}
        {fontLists.map((item) => (
          <Option key={item.family} style={{ fontFamily: item.family }} className="">
            {item.family}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

FontSelect.propTypes = {
  customFonts: PropTypes.array,
  fontLists: PropTypes.array,
  defaultFont: PropTypes.string,
  font: PropTypes.object,
  onChange: PropTypes.func,
};

export default FontSelect;
