import React from 'react';
import { Button, DatePicker, Space, Table } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

const { RangePicker } = DatePicker;

const PayPalTransactionDetails = ({
  payPal,
  refetch,
  filters,
  isFetchingAdminPayPal,
  setLoadInvoice,
  handleFilters,
}) => {
  const payPalColumn = [
    {
      title: 'Created At',
      dataIndex: 'L_TIMESTAMP',
      key: 'L_TIMESTAMP',
    },
    {
      title: 'Timezone',
      dataIndex: 'L_TIMEZONE',
      key: 'L_TIMEZONE',
    },
    {
      title: 'Type',
      dataIndex: 'L_TYPE',
      key: 'L_TYPE',
    },
    {
      title: 'Email',
      dataIndex: 'L_EMAIL',
      key: 'L_EMAIL',
    },
    {
      title: 'Name',
      dataIndex: 'L_NAME',
      key: 'L_NAME',
    },
    {
      title: 'Txn id',
      dataIndex: 'L_TRANSACTIONID',
      key: 'L_TRANSACTIONID',
    },
    {
      title: 'Status',
      dataIndex: 'L_STATUS',
      key: 'L_STATUS',
    },
    {
      title: 'Amount',
      dataIndex: 'L_AMT',
      key: 'L_AMT',
    },
    {
      title: 'Currency',
      dataIndex: 'L_CURRENCYCODE',
      key: 'L_CURRENCYCODE',
    },
    {
      title: 'Fee',
      dataIndex: 'L_FEEAMT',
      key: 'L_FEEAMT',
    },

    {
      title: 'Net Amt',
      dataIndex: 'L_NETAMT',
      key: 'L_NETAMT',
    },
  ];

  return (
    <div className="admin-table-wrapper">
      <div className="table-action">
        <h3>All Transactions</h3>
        <div>
          <div>
            <div />
            <Space>
              <Button type="primary" onClick={() => setLoadInvoice(true)}>
                Invoices
              </Button>
              <RangePicker
                value={[moment(filters.startDate), moment(filters.endDate)]}
                onChange={(values) => {
                  let dataValue = values;
                  if (!values) {
                    dataValue = [moment(), moment()];
                  }
                  handleFilters(
                    'date',
                    dataValue.map((el) => el.toISOString())
                  );
                  refetch();
                }}
              />
            </Space>
          </div>
        </div>
      </div>
      <Table loading={isFetchingAdminPayPal} dataSource={payPal} columns={payPalColumn} />
    </div>
  );
};

PayPalTransactionDetails.defaultProps = {
  isFetchingAdminPayPal: false,
  payPal: [],
  filters: {},
};

PayPalTransactionDetails.propTypes = {
  isFetchingAdminPayPal: PropTypes.bool,
  filters: PropTypes.instanceOf(Object),
  payPal: PropTypes.arrayOf(PropTypes.shape({})),
  refetch: PropTypes.func.isRequired,
  setLoadInvoice: PropTypes.func.isRequired,
  handleFilters: PropTypes.func.isRequired,
};

export default PayPalTransactionDetails;
