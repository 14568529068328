import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="32" height="8" viewBox="0 0 32 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="8" rx="4" fill="#005AFF" />
    <rect opacity="0.6" x="8" y="2" width="16" height="1" rx="0.5" fill="white" />
    <rect opacity="0.6" x="8" y="5" width="16" height="1" rx="0.5" fill="white" />
  </svg>
);

const ResizeIcon = (props) => <Icon component={svg} {...props} />;

export default ResizeIcon;
