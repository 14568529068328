/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.76536 12.332L0.865365 8.4487C0.776476 8.35981 0.707031 8.26259 0.657031 8.15703C0.607031 8.05148 0.582031 7.93759 0.582031 7.81536C0.582031 7.69314 0.607031 7.5737 0.657031 7.45703C0.707031 7.34036 0.776476 7.23759 0.865365 7.1487L4.5487 3.46536L2.9487 1.86536C2.82648 1.74314 2.76814 1.60148 2.7737 1.44036C2.77925 1.27925 2.84314 1.13759 2.96536 1.01536C3.08759 0.893142 3.22925 0.832031 3.39036 0.832031C3.55148 0.832031 3.69314 0.893142 3.81536 1.01536L9.93203 7.1487C10.0209 7.23759 10.0876 7.33759 10.132 7.4487C10.1765 7.55981 10.1987 7.67648 10.1987 7.7987C10.1987 7.92092 10.1765 8.03759 10.132 8.1487C10.0876 8.25981 10.0209 8.35981 9.93203 8.4487L6.0487 12.332C5.95981 12.4209 5.85981 12.4904 5.7487 12.5404C5.63759 12.5904 5.52092 12.6154 5.3987 12.6154C5.27648 12.6043 5.16259 12.5765 5.05703 12.532C4.95148 12.4876 4.85425 12.4209 4.76536 12.332ZM5.3987 4.31536L1.91536 7.7987H8.88203L5.3987 4.31536ZM11.9487 12.5987C11.5376 12.5987 11.1904 12.4598 10.907 12.182C10.6237 11.9043 10.482 11.5598 10.482 11.1487C10.482 10.882 10.5431 10.6237 10.6654 10.3737C10.7876 10.1237 10.932 9.88759 11.0987 9.66537L11.5987 9.0487C11.6876 8.92648 11.8043 8.86537 11.9487 8.86537C12.0931 8.86537 12.2098 8.92648 12.2987 9.0487L12.782 9.66537C12.9376 9.88759 13.0793 10.1237 13.207 10.3737C13.3348 10.6237 13.3987 10.882 13.3987 11.1487C13.3987 11.5598 13.257 11.9043 12.9737 12.182C12.6904 12.4598 12.3487 12.5987 11.9487 12.5987Z"
      fill="#05034D"
    />
  </svg>
);

const PaintBucketIcon = (props) => <Icon component={svg} {...props} />;

export default PaintBucketIcon;
