import { CharacterMetadata, ContentBlock } from 'draft-js';
import Immutable from 'immutable';

const { List, Map, Repeat } = Immutable;

const createCellBlock = function (key, text, cls) {
  return new ContentBlock({
    key,
    type: 'ordered-list-item',
    text,
    data: new Map({ class: cls }),
    characterList: List(Repeat(CharacterMetadata.create(), text.length)),
  });
};

export default createCellBlock;
