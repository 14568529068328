import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="76" height="34" viewBox="0 0 76 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="76" height="34" rx="4" fill="#7876C1" />
    <rect x="24" width="2" height="34" fill="white" />
  </svg>
);

const ColumnsTwoLeftIcon = (props) => <Icon component={svg} {...props} />;

export default ColumnsTwoLeftIcon;
