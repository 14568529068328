import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.85917 4.24104C8.10889 3.67421 7.19952 3.35736 6.25946 3.33523C5.31939 3.3131 4.39612 3.58681 3.62 4.1177C1.26584 5.72812 0.991669 9.09812 3.05459 11.0677L9.64042 17.356C9.7373 17.4485 9.86608 17.5001 10 17.5001C10.1339 17.5001 10.2627 17.4485 10.3596 17.356L16.9442 11.0685C19.0075 9.09812 18.7329 5.72854 16.3783 4.1177C15.6019 3.58676 14.6784 3.31314 13.7381 3.33549C12.7977 3.35785 11.8882 3.67505 11.1379 4.24229L10 5.10271L8.85917 4.24104Z"
      fill="#C7BCE4"
    />
    <path
      d="M13.6195 4.80917C13.2248 4.91289 12.9647 5.31705 13.0353 5.71696C13.111 6.11573 13.492 6.35872 13.8845 6.25815C14.1731 6.18335 14.5014 6.23292 14.8386 6.46434C15.1523 6.67965 15.3431 6.98626 15.3741 7.28041C15.4244 7.68484 15.7852 7.95097 16.1845 7.87819C16.586 7.80226 16.8696 7.4142 16.8215 7.00662C16.7307 6.22756 16.2441 5.57912 15.7016 5.20684C15.1357 4.81847 14.382 4.61569 13.6195 4.80917Z"
      fill="#7876C1"
    />
  </svg>
);

const HeartIcon = (props) => <Icon component={svg} {...props} />;

export default HeartIcon;
