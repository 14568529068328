import React from 'react';
import PropTypes from 'prop-types';

import PopConfirm from 'components/PopConfirm';
import { Form, InputNumber } from 'antd';

const ResetTrialModal = ({ visible, setVisible, userInfo, handleSubmit }) => {
  const [form] = Form.useForm();

  return (
    <PopConfirm
      title="Are you sure want to reset trial?"
      body={
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ days: 1 }}
          form={form}
          onFinish={(values) => handleSubmit('resetTrial', userInfo, { days: values.days })}>
          <Form.Item
            label="For how many days(1-14)"
            name="days"
            rules={[{ required: true, message: 'Please input days!' }]}>
            <InputNumber placeholder="Days" max={14} min={0} />
          </Form.Item>
        </Form>
      }
      visible={visible}
      cancelText="CANCEL"
      confirmText="OK"
      onCancel={() => setVisible(false)}
      onConfirm={() => {
        form.submit();
        setVisible(false);
      }}
    />
  );
};

ResetTrialModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  userInfo: PropTypes.instanceOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
export default ResetTrialModal;
