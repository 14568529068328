/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="21" height="21" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="4" fill="#F5F6FA" />
    <path
      d="M19.0165 11.18H22.5855C23.4947 11.18 24.2344 11.9197 24.2344 12.8289C24.2344 12.9181 24.2354 13.0065 24.2365 13.0943C24.2449 13.8086 24.2529 14.4844 23.6931 15.2305C23.339 15.7024 22.8524 16.039 22.286 16.204L16.7851 17.8056C16.2404 17.9642 15.6647 17.9641 15.1201 17.8056L9.61925 16.204C9.05277 16.0391 8.56618 15.7024 8.21208 15.2305C7.6534 14.486 7.66085 13.815 7.66888 13.0921C7.66985 13.0052 7.67082 12.9175 7.67082 12.8289C7.67082 11.9197 8.41053 11.18 9.31972 11.18H12.8888V9.74185C12.8888 9.05707 13.4459 8.5 14.1307 8.5H17.7746C18.4594 8.5 19.0165 9.05707 19.0165 9.74185V11.18ZM14.1307 9.55189C14.0259 9.55189 13.9407 9.63709 13.9407 9.74185V11.18H17.9646V9.74185C17.9646 9.63712 17.8794 9.55189 17.7746 9.55189H14.1307ZM19.6572 18.0695C20.4229 17.8447 21.3782 17.5642 22.5801 17.2142C23.2172 17.0287 23.7816 16.6867 24.2345 16.2152V21.2629C24.2345 22.1721 23.4947 22.9117 22.5856 22.9117H9.31977C8.41054 22.9117 7.67087 22.1721 7.67087 21.2629V16.2152C8.1237 16.6867 8.68804 17.0287 9.32526 17.2143C10.5333 17.566 11.4926 17.8475 12.2605 18.0729C15.3341 18.9749 15.3403 18.9767 15.9526 18.9767C16.5674 18.9767 16.6431 18.9545 19.6572 18.0695Z"
      fill="#2F2C88"
    />
  </svg>
);

const ProjectMarketingIcon = (props) => <Icon component={svg} {...props} />;

export default ProjectMarketingIcon;
