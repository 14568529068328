/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.3269 2.61539H9.96461C9.84276 2.61624 9.72309 2.58301 9.61911 2.51947C9.51514 2.45593 9.43098 2.3646 9.37615 2.25577L8.79423 1.08539C8.63113 0.758696 8.38009 0.484013 8.06935 0.292258C7.75861 0.100502 7.40052 -0.000713805 7.03538 3.78932e-06H2.28846C1.68152 3.78932e-06 1.09944 0.241109 0.670275 0.670279C0.241105 1.09945 0 1.68153 0 2.28846V14.7115C0 15.3185 0.241105 15.9006 0.670275 16.3297C1.09944 16.7589 1.68152 17 2.28846 17H17.3269C17.9339 17 18.5159 16.7589 18.9451 16.3297C19.3743 15.9006 19.6154 15.3185 19.6154 14.7115V4.90385C19.6154 4.29691 19.3743 3.71483 18.9451 3.28566C18.5159 2.85649 17.9339 2.61539 17.3269 2.61539Z"
      fill="#FFCBC6"
    />
    <path
      d="M0.670275 16.3296C0.241105 15.9004 0 15.3184 0 14.7114V6H19.6154V14.7114C19.6154 15.3184 19.3743 15.9004 18.9451 16.3296C18.5159 16.7588 17.9339 16.9999 17.3269 16.9999H2.28846C1.68152 16.9999 1.09944 16.7588 0.670275 16.3296Z"
      fill="#FF9492"
    />
  </svg>
);

const FolderIcon = (props) => <Icon component={svg} {...props} />;

export default FolderIcon;
