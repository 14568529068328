/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="2" height="10" viewBox="0 0 2 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="1" cy="1" r="1" fill="#05034D" />
    <circle cx="1" cy="5" r="1" fill="#05034D" />
    <circle cx="1" cy="9" r="1" fill="#05034D" />
  </svg>
);

const MenuSmallIcon = (props) => <Icon component={svg} {...props} />;

export default MenuSmallIcon;
