/* eslint-disable react/jsx-curly-newline */
import React, { useState } from 'react';
import { Card, Menu, Dropdown, Input, Button, Image } from 'antd';
import PropTypes from 'prop-types';

import MenuSmallIcon from 'components/Icons/MenuSmallIcon';
import StarIcon from 'components/Icons/StarIcon';
import UnStartIcon from 'components/Icons/UnStarIcon';
import FileIcon from 'components/Icons/FileIcon';
import helpers from 'helpers';
import PreviewProposalModal from '../PreviewProposalModal/PreviewProposalModal';
import PdfIcon from 'components/Icons/PdfIcon';
import XlsxIcon from 'components/Icons/XlsxIcon';
import DocxIcon from 'components/Icons/DocxIcon';

import './CoverOrMediaCard.scss';

const CoverOrMediaCard = ({
  item,
  type,
  onClickMenuItem,
  index,
  onDragStart,
  userId,
  userRole,
  itemEditable,
  editValue,
  setEditValue,
}) => {
  const [previewModal, showPreviewModal] = useState(false);

  const menu = () => {
    return (
      <Menu>
        <Menu.Item
          onClick={() =>
            onClickMenuItem({ actionType: itemEditable ? 'save' : 'rename', ...item })
          }>
          {itemEditable ? 'Save' : 'Rename'}
        </Menu.Item>
        <Menu.Item onClick={() => onClickMenuItem({ actionType: 'delete', ...item })}>
          Delete
        </Menu.Item>
      </Menu>
    );
  };

  const renderFileIcon = () => {
    const fileType = item.type;
    if (fileType === 'application/pdf' || fileType === 'pdf') {
      return <PdfIcon />;
    } else if (
      [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ].includes(fileType)
    ) {
      return <XlsxIcon />;
    } else if (
      [
        'attachmentapplication/msword',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ].includes(fileType)
    ) {
      return <DocxIcon />;
    }
    return <FileIcon />;
  };

  const src = item.img || item.src;
  const title = item.name || item.title;
  const isGif = /\.gif$/i.test(src);
  const isImage = type === 'covers' || helpers.isImage(src);

  return (
    <Card
      draggable="true"
      onDragStart={onDragStart}
      className="content-library-card"
      cover={
        <>
          <div className="hover-items-container">
            <div className="hover-items">
              <div className="buttons">
                <Button
                  onClick={() =>
                    showPreviewModal({
                      title,
                      src,
                      type: item.type,
                    })
                  }>
                  Preview
                </Button>
              </div>
            </div>
          </div>
          {isImage ? (
            <Image alt={title} src={helpers.formatS3Link(src)} />
          ) : (
            <div className="cover-file-icon">{renderFileIcon()}</div>
          )}
        </>
      }>
      {isGif && <div className="gif-chips">GIF</div>}
      <div className="card-title">
        {item.starred[0] === 'true' ? (
          <StarIcon
            className="anticon-star"
            onClick={() =>
              onClickMenuItem({
                ...item,
                actionType: 'starOrUnstar',
                starred: false,
                index,
              })
            }
          />
        ) : (
          <UnStartIcon
            className="anticon-star"
            onClick={() =>
              onClickMenuItem({
                ...item,
                actionType: 'starOrUnstar',
                starred: true,
                index,
              })
            }
          />
        )}

        {itemEditable && editValue ? (
          <Input
            defaultValue={title}
            onChange={(e) => setEditValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                onClickMenuItem({ actionType: 'save', ...item });
              }
            }}
            onBlur={() => onClickMenuItem({ actionType: 'save', ...item })}
            autoFocus
          />
        ) : (
          <span
            className="title"
            onDoubleClick={() =>
              userId === item.uid || userId === item.auid || userRole === 'manager'
                ? onClickMenuItem({
                    actionType: 'rename',
                    ...item,
                    name: item.name,
                    title: item.title,
                  })
                : {}
            }>
            {title}
          </span>
        )}

        {item.uploadedBy === 'user' &&
          (item.uid === userId || item.auid === userId || userRole === 'manager') && (
            <Dropdown className="menu-icon" overlay={menu} trigger={['click']}>
              <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                <MenuSmallIcon />
              </a>
            </Dropdown>
          )}
      </div>

      {previewModal && (
        <PreviewProposalModal
          onCancel={() => showPreviewModal(false)}
          title={previewModal.title}
          coverMedia={previewModal.src}
          type={previewModal.type}
        />
      )}
    </Card>
  );
};

CoverOrMediaCard.defaultProps = {
  editValue: '',
  itemEditable: false,
};

CoverOrMediaCard.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  type: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onDragStart: PropTypes.func.isRequired,
  itemEditable: PropTypes.bool,
  onClickMenuItem: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  setEditValue: PropTypes.func.isRequired,
  editValue: PropTypes.string,
  userRole: PropTypes.string,
};

export default CoverOrMediaCard;
