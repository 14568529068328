/* eslint-disable */
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Input, Button } from 'antd';

import { ChevronRight, CloseIcon } from 'components/Icons';

const TitleEditModal = ({
  onClosePane,
  editColumnName,
  resetColumnName,
  chosen,
  stab,
  configText,
}) => {
  const getValue = (value, defaultValue) => {
    return typeof value === 'string' ? value : defaultValue;
  };

  return (
    <>
      <div className="tabList-drawer tabList-drawer-right title-edit">
        <div className="tabList-drawer-mask" onClick={onClosePane}></div>
        <div className="tabList-drawer-content-wrapper">
          <div className="tabList-drawer-content" aria-modal="true" role="dialog">
            <div className="tabList-drawer-wrapper-body">
              <div className="tabList-drawer-header">
                <div className="tabList-drawer-title">Title names</div>
                <button
                  type="button"
                  aria-label="Close"
                  className="tabList-drawer-close"
                  onClick={onClosePane}>
                  <CloseIcon className="close-icon" />
                  <ChevronRight className="arrow-icon" />
                </button>
              </div>

              <div className="tabList-drawer-body">
                <Input
                  placeholder="Description"
                  value={getValue(chosen[stab]?.columnName?.deliverable, configText('Deliverable'))}
                  onChange={(e) => editColumnName('deliverable', e.target.value)}
                />

                <Input
                  placeholder="Quantity"
                  value={getValue(chosen[stab]?.columnName?.item, configText('Quantity'))}
                  onChange={(e) => editColumnName('item', e.target.value)}
                />

                <Input
                  placeholder="Line total"
                  value={getValue(chosen[stab]?.columnName?.price, configText('Line Total'))}
                  onChange={(e) => editColumnName('price', e.target.value)}
                />

                <Input
                  placeholder="Price"
                  value={getValue(chosen[stab]?.columnName?.rowprice, configText('Price'))}
                  onChange={(e) => editColumnName('rowprice', e.target.value)}
                />
              </div>

              <div className="tabList-drawer-footer">
                <Button className="reset-btn" type="text" onClick={resetColumnName}>
                  Reset
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

TitleEditModal.propTypes = {
  onClosePane: PropTypes.func,
};

export default TitleEditModal;
