import { gql } from '@apollo/client';

export const GET_STRIPE_SUPPORTED_CURRENCIES = gql`
  query FetchStripeSupportedCurrencies {
    fetchStripeSupportedCurrencies
  }
`;

export const CONNECT_FRESHBOOKS = gql`
  query connectFreshbooksQuery($code: String!) {
    connectFreshbooks(code: $code) {
      freshbooks
    }
  }
`;

export const CONNECT_QUICKBOOKS = gql`
  query connectQuickBooksMutation($url: String!) {
    connectQuickBooks(url: $url)
  }
`;

export const CONNECT_MONDAY = gql`
  query connectMondayMutation($url: String!) {
    connectMonday(url: $url) {
      token
      user {
        _id
        profile {
          language
          currency
        }
        monday
      }
    }
  }
`;

export const FETCH_MONDAY_CONTACTS = gql`
  query fetchMondayContacts($boardId: String!) {
    fetchMondayContacts(boardId: $boardId)
  }
`;

export const CONNECT_XERO = gql`
  query connectXeroQuery($url: String!) {
    connectXero(url: $url) {
      token
      user {
        _id
        profile {
          language
          currency
          terms
          name
        }
        xero
        ptype
      }
    }
  }
`;

export const FETCH_XERO_ACCOUNTS = gql`
  query fetchXeroAccounts($tenantId: String!) {
    fetchXeroAccounts(tenantId: $tenantId)
  }
`;

export const CONNECT_SLACK = gql`
  query connectSlackQuery($code: String!) {
    connectSlack(code: $code)
  }
`;
