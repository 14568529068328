import { AtomicBlockUtils } from 'draft-js';

const insertTeXBlock = async (editorState, type) => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity('TOKEN', 'IMMUTABLE', {
    texcontent: type === 'price' ? -42 : -43,
  });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  return AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
};

export default insertTeXBlock;
