import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3346 1.66663L9.16797 10.8333"
      stroke="#2F2C88"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.3346 1.66663L12.5013 18.3333L9.16797 10.8333L1.66797 7.49996L18.3346 1.66663Z"
      stroke="#2F2C88"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const SendIcon = (props) => <Icon component={svg} {...props} />;

export default SendIcon;
