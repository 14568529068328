import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="504" height="23" viewBox="0 0 504 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 4C0 1.79087 1.79086 0 4 0H499.805C502.014 0 503.805 1.79086 503.805 4V22.5702H0V4Z"
      fill="#F5F6FA"
    />
    <circle cx="12.8517" cy="11.2834" r="3.75796" fill="#FF4D4F" />
    <circle cx="24.7111" cy="11.2834" r="3.75796" fill="#FFA940" />
    <circle cx="36.5626" cy="11.2834" r="3.75796" fill="#389E0D" />
  </svg>
);

const WindowHeaderIcon = (props) => <Icon component={svg} {...props} />;

export default WindowHeaderIcon;
