/* eslint-disable react/no-unknown-property */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Input, Row, Col } from 'antd';

import IncrementIcon from 'components/Icons/IncrementIcon';
import DecrementIcon from 'components/Icons/DecrementIcon';

import './InputNumber.scss';

const InputNumber = forwardRef((props, ref) => {
  const {
    containerclass,
    labelclass,
    sublabelclass,
    icon,
    label,
    sublabel,
    value,
    placeholder,
    onChange,
    min,
    max,
    step,
    onClick,
  } = props;

  return (
    <div className={`input-container input-number-container ${containerclass}`}>
      {label && (
        <Row>
          <label className={`input-label ${labelclass}`}>{label}</label>
          {icon}
        </Row>
      )}
      {sublabel && <p className={`input-sub-label ${sublabelclass}`}>{sublabel}</p>}
      <Input
        ref={ref}
        type={props?.type || 'text'}
        min={min}
        max={max}
        step={step}
        value={value}
        placeholder={placeholder}
        onClick={onClick}
        onChange={onChange}
      />
      <Col className="increment-container">
        <IncrementIcon
          onClick={() =>
            props.onHandleChange(
              props?.value < max ? (props?.value || 0) + (step || 1) : props?.value
            )
          }
        />
        <DecrementIcon
          onClick={() =>
            props.onHandleChange(
              props?.value > min ? (props?.value || 0) - (step || 1) : props?.value
            )
          }
        />
      </Col>
    </div>
  );
});

InputNumber.defaultProps = {
  containerclass: '',
  labelclass: '',
  sublabelclass: '',
  icon: '',
  label: '',
  sublabel: '',
  type: '',
  max: Infinity,
  min: -Infinity,
};

InputNumber.propTypes = {
  containerclass: PropTypes.string,
  labelclass: PropTypes.string,
  sublabelclass: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  label: PropTypes.string,
  sublabel: PropTypes.string,
  type: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
};

export default InputNumber;
