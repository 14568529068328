import moment from 'moment';

import utils from 'utils/utils';

const getUserShortName = ({ name }) => {
  const userParts = name ? name.split(/[ -]/) : [];
  let initials = '';
  for (let i = 0; i < userParts.length; i += 1) {
    initials += userParts[i].charAt(0);
  }
  if (initials.length > 2 && initials.search(/[A-Z]/) !== -1) {
    initials = initials.replace(/[a-z]+/g, '');
  }
  initials = initials.substr(0, 2).toUpperCase();
  return initials;
};

const updateIntercomUser = (user, update) => {
  const daystrial = parseInt(process.env.REACT_APP_DAYS_TRAIL) || 14;

  const utmData = {
    utm_source: utils.readCookie('utm_source'),
    utm_campaign: utils.readCookie('utm_campaign'),
    utm_medium: utils.readCookie('utm_medium'),
  };

  update({
    userId: user._id,
    name: user?.profile?.name,
    email: user.emails && user.emails[0].address,
    createdAt: Math.floor(new Date(user.createdAt).getTime() / 1000),
    userHash: user.userHash,
    // didCancelPlan,
    customAttributes: {
      locked: !!user?.paymentStatus?.canLock,
      language: user?.profile?.language?.toLowerCase() || 'english',
      where: user?.profile?.where || '?',
      canLockUser: user?.paymentStatus?.canLock,
      propnum: user?.props?.length || 0,
      trial:
        daystrial - moment().diff(moment(user.stopperAnchor || user.createdAt), 'days') > 0
          ? true
          : false,
    },
    ...utmData,
  });
};

export { getUserShortName, updateIntercomUser };
