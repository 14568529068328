import { gql } from '@apollo/client';

export const MANAGE_TEAM_MEMBERS = gql`
  mutation ManageTeamMembersMutation(
    $membersToBeAdded: [TeamsDefaultInput!]
    $membersToBeDeleted: [TeamsDefaultInput!]
    $planInfo: PlanInfoInput
  ) {
    manageTeamMembers(
      membersToBeAdded: $membersToBeAdded
      membersToBeDeleted: $membersToBeDeleted
      planInfo: $planInfo
    ) {
      add {
        user {
          _id
          emails {
            address
          }
          profile {
            name
            image
          }
          props
          createdAt
          roles
          domain
          createdAt
        }
        error
      }
      delete {
        user {
          _id
          emails {
            address
          }
        }
        error
      }
      paymentResponse {
        status
        link
      }
    }
  }
`;

export const UPDATE_TEAM_MEMBER = gql`
  mutation UpdateTeamMemberMutation($member: TeamsInput!) {
    updateTeamMember(member: $member)
  }
`;

export const DELETE_TEAM_MEMBER = gql`
  mutation DeleteTeamMemberMutation($uid: String!) {
    deleteTeamMember(uid: $uid)
  }
`;

export const SHARE_PROPOSAL_TEMPLATE_TO_TEAM = gql`
  mutation ShareProposalTemplateToTeamMutation(
    $members: [String!]!
    $proposal: ProposalActivityInput
    $template: ProposalActivityInput
  ) {
    shareProposalTemplateToTeam(members: $members, proposal: $proposal, template: $template)
  }
`;
