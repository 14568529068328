import React, { useEffect, useState, useCallback } from 'react';
import { Row, Tooltip, Input, Collapse, Popover, Col } from 'antd';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import * as _ from 'lodash';
import { useReactiveVar } from '@apollo/client';
import moment from 'moment';

import CloseIcon from 'components/Icons/CloseIcon';
import IconButton from 'components/IconButton';
import AddBlockIcon from 'components/Icons/AddBlockIcon';
import DeleteIcon from 'components/Icons/DeleteIcon';
import NewCopyIcon from 'components/Icons/NewCopyIcon';
import NewCopyDarkIcon from 'components/Icons/NewCopyDarkIcon';
import VariablesEditIcon from 'components/Icons/VariablesEditIcon';
import ClosedMenuIcon from 'components/Icons/ClosedMenuIcon';
import ClosedVariableMenuIcon from 'components/Icons/ClosedVariableMenuIcon';
import InfoIcon from 'components/Icons/InfoIcon';
import helpers from 'helpers';
import { formatLocale } from 'pages/Proposal/components/RichEditor/wix-components/wix-pricing-helper';
import utils from 'utils/utils';
import { sanitizeString } from 'utils/xss';
import { ELEMENTS_VARIABLES, DEFAULT_TEMPLATE_PRICING } from 'constants/index';
import { userVar } from 'graphql/cache';

import '../ElementsMenu.scss';

const { Panel } = Collapse;

const SECTION_ELEMENTS_VARIABLES = JSON.parse(JSON.stringify(ELEMENTS_VARIABLES));
delete SECTION_ELEMENTS_VARIABLES.custom;

const VariablesView = ({
  saveProposal,
  variables,
  openVariablesList,
  showVariables,
  setShowVariables,
  setOpenVariablesList,
  scrollingTo,
  setScrollingTo,
  language,
  isSection,
  wixPricing,
  wixEditor,
  templateWixEditor,
}) => {
  const user = useReactiveVar(userVar);

  const [tempVariables, setTempVariables] = useState(
    isSection ? SECTION_ELEMENTS_VARIABLES : { ...ELEMENTS_VARIABLES, ...variables }
  );
  const [updateVariable, setUpdateVariable] = useState('');
  const [copiedVariable, setCopiedVariable] = useState('');
  const [openPopover, setOpenPopover] = useState({});
  const [errors, setErrors] = useState('');
  const [variableName, setVariableName] = useState('');

  const handleScroll = useCallback(
    (panelId) => {
      setTimeout(() => {
        const sectionEl = document.querySelector(`[id="${panelId}"]`);
        if (sectionEl) {
          sectionEl.scrollIntoView({
            behavior: 'smooth',
          });
          sectionEl.classList.add('animate-simple-section');
          setTimeout(() => sectionEl.classList.remove('animate-simple-section'), 500);
          setScrollingTo('');
        }
      }, 500);
    },
    [setScrollingTo]
  );

  useEffect(() => {
    if (tempVariables?.length) {
      const dummyVariables = tempVariables || variables || ELEMENTS_VARIABLES;
      dummyVariables.client.fullName = {
        count: dummyVariables?.client?.fullName?.count || 0,
        value:
          (dummyVariables?.client?.firstName?.value || '') +
          ' ' +
          (dummyVariables?.client?.lastName?.value || ''),
      };
      dummyVariables.sender.fullName = {
        count: dummyVariables?.sender?.fullName?.count || 0,
        value:
          (dummyVariables?.sender?.firstName?.value || '') +
          ' ' +
          (dummyVariables?.sender?.lastName?.value || ''),
      };
      dummyVariables.sender2.fullName = {
        count: dummyVariables?.sender2?.fullName?.count || 0,
        value:
          (dummyVariables?.sender2?.firstName?.value || '') +
          ' ' +
          (dummyVariables?.sender2?.lastName?.value || ''),
      };
      setTempVariables(dummyVariables);
      handleScroll(scrollingTo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // tempVariables,
    variables,
    openVariablesList,
    showVariables,
    scrollingTo,
  ]);

  useEffect(() => {
    if (updateVariable === 'updated') {
      // prevent data changing if its just updated
      setUpdateVariable('');
      return;
    }
    if (variables && !_.isEqual(variables, tempVariables)) {
      setTempVariables({ ...variables });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables]);

  const closeVariables = () => {
    setShowVariables(false);
    setOpenVariablesList(false);
  };

  // window.addEventListener('keydown', function (e) {
  //   if (e.code === 'Enter' || e.code === 'Escape') {
  //     setUpdateVariable('');
  //   }
  // });

  const saveProposalWithUpdatedVariable = (dummyVariables, custom) => {
    saveProposal({ 'draft.variables': dummyVariables || tempVariables });
    setScrollingTo('');

    if (custom) {
      setUpdateVariable('updated'); // 'updated' flag to prevent data changing in useEffect
      setVariableName('');
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveVariableDebouce = useCallback(
    _.debounce((dummyVariables) => saveProposalWithUpdatedVariable(dummyVariables), 500),
    []
  );

  const createFullname = (data) => {
    return `${data?.firstName?.value} ${data?.lastName?.value}`.trim();
  };

  const changeVariable = (external, key, value) => {
    let dummyVariables = JSON.parse(JSON.stringify(tempVariables));
    dummyVariables[external][key].value = sanitizeString(value);

    if (
      ['client', 'sender', 'sender2'].includes(external) &&
      (key === 'firstName' || key === 'lastName')
    ) {
      dummyVariables[external].fullName = {
        count: dummyVariables?.[external]?.fullName?.count || 0,
        value: createFullname(dummyVariables?.[external]),
      };
    }

    saveVariableDebouce(dummyVariables);
  };

  const handleOpenChange = (variable) => {
    setOpenPopover({ ...openPopover, [variable]: !openPopover[variable] });
  };

  const getNext = (item) => {
    if (
      tempVariables.custom[`custom${parseInt(item) + 1}`] ||
      tempVariables.custom[`custom${parseInt(item) + 1}`] === ''
    ) {
      return getNext(item + 1);
    } else {
      return parseInt(item) + 1;
    }
  };

  const addCustomVariable = () => {
    let dummyVariables = JSON.parse(JSON.stringify(tempVariables));
    dummyVariables.custom[`custom${getNext(Object.keys(dummyVariables.custom).length)}`] = {
      value: '',
      count: 0,
    };
    saveProposal({ 'draft.variables': dummyVariables });
    setScrollingTo('');
  };

  const deleteCustomVariable = (variableName) => {
    let dummyVariables = JSON.parse(JSON.stringify(tempVariables));
    delete dummyVariables.custom[variableName];
    setOpenPopover({});
    saveProposal({ 'draft.variables': dummyVariables });
  };

  const copyVariable = (value, variable) => {
    setScrollingTo('');
    copy(`{{${value}.${variable}}}`);
    setCopiedVariable(`${value}.${variable}`);
    setTimeout(function () {
      setCopiedVariable('');
      setOpenPopover({ ...openPopover, [variable]: false });
    }, 5000);
  };

  const onRenameClick = (value, variable) => {
    setVariableName(variable);
    setUpdateVariable(updateVariable === `${value}.${variable}` ? '' : `${value}.${variable}`);
    setOpenPopover({});
  };

  const editCustomVariable = (newName, _variable) => {
    if (newName.indexOf('.') > -1) {
      newName = newName.replaceAll('.', '_');
      setErrors(_variable);
    } else {
      setErrors('');
    }
    setVariableName(newName);
  };

  const saveCustomVariable = (e, index) => {
    const { code, ckey, keyCode } = e;

    if (code === 'Escape' || ckey === 'Escape' || keyCode === 27) {
      setErrors('');
      setUpdateVariable('');
      setVariableName('');
    } else if (code === 'Enter' || ckey === 'Enter' || keyCode === 13) {
      // update variable
      const dummyVariables = JSON.parse(JSON.stringify(tempVariables));
      const newVariables = { ...dummyVariables };
      newVariables.custom = {};

      for (const oldVariable in dummyVariables.custom) {
        const oldValues = dummyVariables.custom[oldVariable];

        if (`custom.${oldVariable}` === updateVariable) {
          newVariables.custom[variableName] = oldValues;
        } else {
          newVariables.custom[oldVariable] = oldValues;
        }
      }

      setTempVariables(newVariables);
      saveProposalWithUpdatedVariable(newVariables, true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.code === 'Escape' || e.key === 'Escape' || e.keyCode === 27) {
      setErrors('');
    }
    if (e.code === 'Enter' || e.key === 'Enter' || e.keyCode === 13) {
      setErrors('');
      saveProposalWithUpdatedVariable();
    }
  };

  const transformVariableValue = (key, value) => {
    const datesValue = ['createdDate', 'sentDate', 'expireDate'];

    const pricing = templateWixEditor && wixPricing ? DEFAULT_TEMPLATE_PRICING : wixPricing || {};

    const locale = formatLocale(pricing);
    const dateFormat = helpers.formatDateByRegion(locale);
    // transform dates
    if (datesValue.includes(key) && value?.length >= 9) {
      if (value.length === 9 || value.length === 10) {
        // NOTE this will not work for date 2023-04-15
        // convert 25-04-2023 to 2023-04-25 00:00:00
        value = `${value.split('-').reverse().join('-')} 00:00:00`;
      }
      const d = moment(value).format(
        templateWixEditor || wixEditor ? dateFormat : user?.profile?.dateFormat || 'DD/MM/YYYY'
      );

      return d === 'Invalid date' ? value : d;
    }

    return value;
  };

  const customMenu = (value, variable) => {
    return (
      <Col>
        <Tooltip
          title="Copied!"
          visible={copiedVariable === `${value}.${variable}`}
          className={`elements-menu-drag ${
            copiedVariable === `${value}.${variable}` ? 'copied' : ''
          }`}>
          <Row className="custom-menu-item copy" onClick={(e) => copyVariable(value, variable)}>
            <NewCopyDarkIcon className="custom-menu-item-icon" />
            Copy
          </Row>
        </Tooltip>
        <Row className="custom-menu-item edit" onClick={() => onRenameClick(value, variable)}>
          <VariablesEditIcon className="custom-menu-item-icon" />
          Rename
        </Row>
        <Row className="custom-menu-item delete" onClick={(e) => deleteCustomVariable(variable)}>
          <DeleteIcon className="custom-menu-item-icon" />
          Delete
        </Row>
      </Col>
    );
  };

  if (!showVariables) {
    return null;
  }

  return (
    <div className="elements-menu variables-view">
      <div className="head-variables">
        <div>
          <span className="left-text">Variables</span>
          {isSection && (
            <Tooltip
              title="Values of these added variables will be changed based on the proposal it's being used. You can set or change the values when editing your proposal."
              overlayClassName="elements-menu-tooltip">
              <span className="text-icon">
                <InfoIcon />
              </span>
            </Tooltip>
          )}
        </div>
        <span>
          <IconButton
            className="close-variables"
            text="Close"
            onButtonClick={closeVariables}
            icon={<CloseIcon className="close-variables-icon" />}
            override={true}
          />
        </span>
      </div>
      <Collapse
        defaultActiveKey={Object.keys(tempVariables)}
        expandIcon={({ isActive }) => (
          <ClosedMenuIcon className={`${isActive ? 'opened' : ''} menu-icon`} />
        )}>
        {Object.keys(tempVariables).map((value) => {
          return (
            <Panel header={utils.capitalizeFirstLetter(value)} key={value} id={value}>
              <div className="variables-list">
                {Object.keys(tempVariables[value]).map((variable, index) => {
                  return (
                    <Row className="items variables-keys" key={`variables-keys-${index}`}>
                      {value === 'custom' &&
                        errors === variable &&
                        updateVariable === `${value}.${variable}` && (
                          <div className="error-message-variables">
                            Dots(.) are not allowed and will be automatically change to underscore
                            (_)
                          </div>
                        )}
                      <div className={`head-variables ${language}`}>
                        <span className="text">
                          {updateVariable === `${value}.${variable}` ? (
                            <Input
                              className={`variable-input3 ${language}`}
                              value={variableName}
                              maxLength={30}
                              onChange={(e) => editCustomVariable(e.target.value, variable)}
                              onBlur={() => saveCustomVariable({ code: 'Enter' }, index)}
                              onKeyDown={(e) => saveCustomVariable(e, index)}
                              placeholder="Enter variable name"
                            />
                          ) : (
                            <div>
                              {`${value}.${variable}`.length > 19 ? (
                                <Tooltip
                                  title={`${value}.${variable}`}
                                  className={`elements-menu-drag ${
                                    copiedVariable === `${value}.${variable}` ? 'copied' : ''
                                  }`}
                                  overlayClassName="elements-menu-tooltip">
                                  <span className="text">{`${value}.${variable}`}</span>
                                </Tooltip>
                              ) : isSection ? (
                                <Tooltip
                                  title="Variables added to this section will show the corresponding value when used in a proposal. You can set or change the values when editing your proposal."
                                  className={`elements-menu-drag ${
                                    copiedVariable === `${value}.${variable}` ? 'copied' : ''
                                  }`}
                                  overlayClassName="elements-menu-tooltip">
                                  <span className="text">{`${value}.${variable}`}</span>
                                </Tooltip>
                              ) : (
                                <span className="text">{`${value}.${variable}`}</span>
                              )}

                              <VariableCount
                                count={
                                  variables?.[value]?.[variable]?.count ||
                                  tempVariables?.[value]?.[variable]?.count ||
                                  0
                                }
                              />
                            </div>
                          )}
                        </span>
                        <span className="right-icons">
                          {value !== 'custom' ? (
                            <Tooltip
                              title={
                                copiedVariable === `${value}.${variable}`
                                  ? 'Copied!'
                                  : 'Copy to clipboard'
                              }
                              className={`elements-menu-drag ${
                                copiedVariable === `${value}.${variable}` ? 'copied' : ''
                              }`}
                              overlayClassName="elements-menu-tooltip">
                              <div onClick={(e) => copyVariable(value, variable)}>
                                <NewCopyIcon className="copy-icon" />
                              </div>
                            </Tooltip>
                          ) : (
                            <Popover
                              overlayClassName={`variables-popover ${language}`}
                              placement={
                                language === 'hebrew' || language === 'arabic'
                                  ? 'topLeft'
                                  : 'topRight'
                              }
                              trigger="click"
                              content={() => customMenu(value, variable)}
                              visible={!!openPopover[variable]}
                              onClick={handleOpenChange}
                              onVisibleChange={(e) => handleOpenChange(variable)}>
                              <ClosedVariableMenuIcon className="custom-closed-menu-icon" />
                            </Popover>
                          )}
                        </span>
                      </div>
                      {!isSection && (
                        <VariableInput
                          isSection={isSection}
                          value={value}
                          variable={variable}
                          language={language}
                          variables={variables}
                          transformVariableValue={transformVariableValue}
                          tempVariables={tempVariables}
                          handleKeyDown={handleKeyDown}
                          changeVariable={changeVariable}
                        />
                      )}
                      <span className="spacer" />
                    </Row>
                  );
                })}
                {!isSection && value === 'custom' && (
                  <Row
                    className={`items add-custom-field ${
                      Object.keys(tempVariables.custom).length > 0 ? 'multiple' : 'none'
                    }`}>
                    <Tooltip
                      title="Add Custom Variable"
                      className="elements-menu-drag"
                      overlayClassName="elements-menu-tooltip">
                      <AddBlockIcon
                        className="add-custom-variable-icon"
                        onClick={addCustomVariable}
                      />
                    </Tooltip>
                  </Row>
                )}
              </div>
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

VariablesView.propTypes = {
  saveProposal: PropTypes.func.isRequired,
  variables: PropTypes.object,
  openVariablesList: PropTypes.bool.isRequired,
  showVariables: PropTypes.bool.isRequired,
  setShowVariables: PropTypes.func,
  setOpenVariablesList: PropTypes.func.isRequired,
  wixPricing: PropTypes.object,
  wixEditor: PropTypes.bool,
  templateWixEditor: PropTypes.bool,
};

export default VariablesView;

const VariableInput = ({
  isSection,
  value,
  variable,
  language,
  variables,
  transformVariableValue,
  tempVariables,
  handleKeyDown,
  changeVariable,
}) => {
  const array = [
    'number',
    'quote',
    'discount',
    'tax',
    'subTotal',
    'total',
    'createdDate',
    'sentDate',
    'expireDate',
  ];

  const isNumber = value === 'proposal' && array.includes(variable);

  const disabled = isSection
    ? true
    : (value === 'client' && variable === 'fullName') ||
      isNumber ||
      (value === 'sender' && variable === 'fullName') ||
      (value === 'sender2' && variable === 'fullName');

  const calculatedValue = isSection
    ? null
    : isNumber
    ? variables
      ? transformVariableValue(variable, variables[value][variable].value)
      : !!tempVariables[value][variable]?.value
      ? transformVariableValue(variable, tempVariables[value][variable].value)
      : ''
    : !!tempVariables[value][variable]?.value
    ? transformVariableValue(variable, tempVariables[value][variable].value)
    : '';

  const placeholder =
    value !== 'custom'
      ? `${utils.capitalizeFirstLetter(value)} ${utils.splitAndCapitaliseFirstLetter(variable)}`
      : 'Custom Value';

  if (disabled) {
    return (
      <Input
        className={`variables-input ${language}`}
        disabled={disabled}
        value={calculatedValue}
        placeholder={placeholder}
      />
    );
  }

  return (
    <Input
      className={`variables-input ${language}`}
      onChange={(e) => changeVariable(value, variable, e.target.value)}
      maxLength={50}
      defaultValue={calculatedValue}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
    />
  );
};

const VariableCount = ({ count }) => {
  if (count < 1) {
    return null;
  }

  return (
    <span className="count">
      <span>{count}</span>
    </span>
  );
};
