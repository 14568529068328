/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const MenuSvg = () => (
  <svg width="4" height="20" viewBox="0 0 4 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="2" cy="18" r="2" transform="rotate(-180 2 18)" fill="#05034D" />
    <circle cx="2" cy="10" r="2" transform="rotate(-180 2 10)" fill="#05034D" />
    <circle cx="2" cy="2" r="2" transform="rotate(-180 2 2)" fill="#05034D" />
  </svg>
);

const MenuIcon = (props) => <Icon component={MenuSvg} {...props} />;

export default MenuIcon;
