/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7982 1.23564C12.4458 -0.411887 15.1169 -0.411887 16.7644 1.23568C18.4119 2.88324 18.4119 5.55435 16.7644 7.20192L13.2385 10.7279C13.2333 10.733 13.2277 10.7372 13.222 10.7413C13.2165 10.7454 13.211 10.7495 13.206 10.7544C13.3727 9.58705 13.1463 8.78796 13.0149 8.32456C12.9823 8.20951 12.9556 8.11516 12.9422 8.04099L15.2728 5.71037C16.0954 4.88775 16.0954 3.54988 15.2728 2.72726C14.4502 1.90463 13.1123 1.90463 12.2897 2.72726L8.7638 6.25315C7.93454 7.08242 7.97022 8.44276 8.7638 9.23627C8.84698 9.31945 8.93709 9.39043 9.02973 9.45611C9.03114 9.52228 9.03742 9.5932 9.044 9.66753C9.07283 9.99316 9.10745 10.3841 8.7638 10.7278L8.12027 11.3714C7.81771 11.198 7.53119 10.9868 7.27223 10.7278C5.7258 9.18136 5.56802 6.46588 7.27223 4.76161L10.7982 1.23564ZM10.1531 6.35543C10.4555 6.52886 10.7421 6.74001 11.001 6.99897C12.5822 8.58013 12.6736 11.2926 11.001 12.9652L7.20183 16.7644C5.55426 18.4119 2.88319 18.4119 1.23562 16.7644C-0.411874 15.1168 -0.411874 12.4457 1.23562 10.7982L5.03479 6.99894C5.03992 6.99382 5.04559 6.98963 5.05125 6.98545C5.05678 6.98136 5.06229 6.97728 5.06731 6.97236C4.90061 8.1397 5.12705 8.93877 5.25837 9.40218C5.29098 9.51723 5.31772 9.61159 5.33112 9.68576L2.7272 12.2897C1.90458 13.1123 1.90458 14.4502 2.7272 15.2728C3.54982 16.0954 4.8877 16.0954 5.71032 15.2728L9.50952 11.4736C10.3388 10.6443 10.3031 9.28406 9.50952 8.49048C9.42634 8.4073 9.33624 8.33632 9.24353 8.27065C9.24212 8.20448 9.23584 8.13356 9.22926 8.05925C9.20043 7.73361 9.16581 7.34263 9.50952 6.99897L10.1531 6.35543Z"
      fill="#9E9EB7"
    />
  </svg>
);

const LinkIcon = (props) => <Icon component={svg} {...props} />;

export default LinkIcon;
