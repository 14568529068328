/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10.0007" r="6.66667" fill="#7876C1" />
    <path
      d="M10 17.5C5.85775 17.5 2.5 14.1422 2.5 10C2.5 5.85775 5.85775 2.5 10 2.5C14.1422 2.5 17.5 5.85775 17.5 10C17.5 14.1422 14.1422 17.5 10 17.5Z"
      fill="#C7BCE4"
    />
    <path
      d="M7.67861 6.66732L7.67861 9.16732L12.3215 9.16732L12.3215 6.66732L15.4167 10.0007L12.3215 13.334L12.3215 10.834L7.67861 10.834L7.67861 13.334L4.58337 10.0007L7.67861 6.66732Z"
      fill="#7876C1"
    />
  </svg>
);

const ExpandIcon = (props) => <Icon component={svg} {...props} />;

export default ExpandIcon;
