import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0003 6.33332H11.3337V3.99999C11.3337 2.15999 9.84033 0.666656 8.00033 0.666656C6.16033 0.666656 4.66699 2.15999 4.66699 3.99999V6.33332H4.00033C3.26699 6.33332 2.66699 6.93332 2.66699 7.66666V13.3333C2.66699 14.0667 3.26699 14.6667 4.00033 14.6667H12.0003C12.7337 14.6667 13.3337 14.0667 13.3337 13.3333V7.66666C13.3337 6.93332 12.7337 6.33332 12.0003 6.33332ZM6.00033 3.99999C6.00033 2.89332 6.89366 1.99999 8.00033 1.99999C9.10699 1.99999 10.0003 2.89332 10.0003 3.99999V6.33332H6.00033V3.99999ZM12.0003 13.3333H4.00033V7.66666H12.0003V13.3333ZM8.00033 11.3333C8.73366 11.3333 9.33366 10.7333 9.33366 9.99999C9.33366 9.26666 8.73366 8.66666 8.00033 8.66666C7.26699 8.66666 6.66699 9.26666 6.66699 9.99999C6.66699 10.7333 7.26699 11.3333 8.00033 11.3333Z"
      fill="#534B7A"
    />
    <rect x="4" y="7" width="8" height="7" fill="#534B7A" />
    <circle cx="8" cy="10" r="1" fill="#ECEBF2" />
  </svg>
);

const CloseLockIcon = (props) => <Icon component={svg} {...props} />;

export default CloseLockIcon;
