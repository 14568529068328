import React, { useState } from 'react';
import { Col, Row, Select } from 'antd';
import { useQuery } from '@apollo/client';
import { CaretDownOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import _ from 'lodash';

import helpers from 'helpers/proposal';
import Loader from 'components/Loader';
import { FETCH_ADMIN_CONFIGURATIONS } from 'graphql/queries/adminQueries';

import './LanguageConfig.scss';

const { Option } = Select;

const findConfigFields = () => {
  let fields = [];

  const ptypes = ['design', 'web', 'video', 'copy', 'other'];
  _.each(ptypes, function (ptype) {
    fields.push(`${ptype} delivs`);
  });

  _.each(ptypes, function (ptype) {
    fields.push(`${ptype} goals`);
  });

  _.each(ptypes, function (ptype) {
    fields.push(`${ptype} desc`);
  });

  fields.push('whyme private');
  fields.push('whyme ent');
  fields.push('milestones preface');
  fields.push('time period filler');

  fields.push('table pricing preface');
  fields.push('value pricing preface');

  const sections = helpers.defaultSectionOrder;
  _.each(sections, (s) => {
    fields.push(`section ${s} title`);
  });

  fields.push('section signature title');

  _.each(ptypes, (ptype) => {
    fields.push(`project type ${ptype}`);
  });

  fields.push('mr client');
  fields.push('ms client');
  fields.push('wizard company desc placeholder');
  fields.push('the new x project template');
  fields.push('Everything in option 1');
  fields.push('Everything in option 2');
  fields.push('OPTION 1');
  fields.push('OPTION 2');
  fields.push('OPTION 3');
  fields.push('OPTION 4');
  fields.push('OPTION 5');
  fields.push('OPTION 6');
  fields.push('currency');

  fields.push('header by');
  fields.push('header contact');
  fields.push('header prepared for');
  fields.push('sign proposal button text');
  fields.push('contact word');
  fields.push('call word');

  fields.push('sign uname proposal template');
  fields.push('sign dialog full name');
  fields.push('sign dialog your name');
  fields.push('sign dialog terms');
  fields.push('sign dialog cancel');

  fields.push('proposal signed on');

  fields.push('are you really title');
  fields.push('are you really subtitle');
  fields.push('are you really no button');
  fields.push('are you really yes button');

  fields.push('client pdf dialog title');
  fields.push('client pdf dialog subtitle');
  fields.push('client pdf dialog download button');

  fields.push('gen pdf dialog title');
  fields.push('gen pdf dialog subtitle');
  fields.push('gen pdf dialog button');

  fields.push('total text');
  fields.push('subtotal text');
  fields.push('discount text');
  fields.push('tax text');

  fields.push('and word with spaces');
  fields.push('date format');
  fields.push('the company');

  fields = [
    'milestones',
    ...fields,
    'terms simple',
    'terms ent',
    'superpowers simple',
    'superpowers ent',
    'whats next',
  ];
  return fields;
};

const LanguageConfig = () => {
  const [language, setLanguage] = useState('english');
  const [name, setName] = useState({});
  const [config, setConfig] = useState('');

  const { loading: isFetchingAdminConfigurations, refetch } = useQuery(FETCH_ADMIN_CONFIGURATIONS, {
    variables: {
      language,
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ fetchAdminConfigurations }) => setConfig(fetchAdminConfigurations),
  });

  if (isFetchingAdminConfigurations) {
    return <Loader />;
  }

  const onChange = (e, key) => {
    const { value } = e.target;
    if (value) {
      setName({ [key]: value });
      // const { _id } = config;
      // Config.update(_id, {
      //   $set: { [key]: value },
      // });
    }
  };

  const fields = findConfigFields();

  const fieldItems = _.map(fields, (key) => {
    return (
      <Col className="field" key={key} span={24}>
        <Row>
          <span>{key}</span>
        </Row>
        <Row>
          <textarea value={name[key] || config[key]} onChange={(e) => onChange(e, key)} />
        </Row>
      </Col>
    );
  });

  return (
    <div className="language-config-container">
      <Row justify="end">
        <Select
          defaultValue={language}
          suffixIcon={<CaretDownOutlined color="red" />}
          onChange={async (value) => {
            await setLanguage(value);
            refetch();
          }}>
          <Option value="english">English</Option>
          <Option value="hebrew">Hebrew</Option>
        </Select>
      </Row>
      <Row className={`items-container language-${language || 'english'}`}>{fieldItems}</Row>
    </div>
  );
};

LanguageConfig.defaultProps = {
  englishConfig: {},
  hebrewConfig: {},
};

LanguageConfig.propTypes = {
  isConfigReady: PropTypes.bool.isRequired,
  englishConfig: PropTypes.shape({}),
  hebrewConfig: PropTypes.shape({}),
};

export default LanguageConfig;
