import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, message, Empty } from 'antd';
import { useMutation, useQuery } from '@apollo/client';

import PropTypes from 'prop-types';

import { getFilteredSections } from 'helpers/contentlibrary';
import { GET_ALL_SECTIONS_LIBRARIES } from 'graphql/queries/contentLibraryQueries';
import {
  UPDATE_CONTENT_LIBRARY,
  CONTENT_LIBRARY_SECTION_DELETE,
} from 'graphql/mutations/contentLibraryMutations';

import ConfirmModal from 'components/ConfirmModal';
import ContentLibraryTree from '../ContentLibraryTree/ContentLibraryTree';
import SectionCard from '../SectionCard/SectionCard';
import PreviewProposalModal from '../PreviewProposalModal/PreviewProposalModal';
import ContentLoader from '../HeaderTabLoader/HeaderTabLoader';

const HeaderTab = ({ user, searchValue, config, isLocked, loadConfig }) => {
  const [sections, setSections] = useState([]);
  const [previewModal, showPreviewModal] = useState(false);
  const [selectedFolderKey, changeSelectedFolderKey] = useState('');
  const [selectedSection, setSelectedSection] = useState(null);
  const [folderName, setFolderName] = useState(null);
  const [selectedSectionId, setSelectedSectionId] = useState('');
  const [confirmModal, showConfirmModal] = useState(false);
  const [selectedSectionEditValue, setSelectedSectionEditValue] = useState('');

  const history = useHistory();

  const { loading: isFetchingSections, refetch: refetchSections } = useQuery(
    GET_ALL_SECTIONS_LIBRARIES,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        type: 'sections',
      },
      onCompleted: ({ fetchContentLibrarySectionsItems }) => {
        setSections(fetchContentLibrarySectionsItems.sections.slice());
      },
    }
  );

  const [updateContentItem] = useMutation(UPDATE_CONTENT_LIBRARY, {
    onCompleted() {
      refetchSections();
    },
  });

  const [deleteSection] = useMutation(CONTENT_LIBRARY_SECTION_DELETE, {
    onCompleted() {
      let tempSection = sections.filter((section) => section._id !== selectedSectionId);
      setSections(tempSection);
      setSelectedSectionId('');
      message.success('Header deleted successfully');
      showConfirmModal(false);
    },
    onError() {
      message.error('Header deletion failed');
      showConfirmModal(false);
    },
  });

  const onSelect = (selectedKeys) => {
    changeSelectedFolderKey(selectedKeys[0]);
  };

  useEffect(() => {
    refetchSections({
      type: 'sections',
      key: selectedFolderKey,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFolderKey]);

  const filteredSections = getFilteredSections({
    sections,
    searchValue,
  });

  const { starred: starredItems, all: allItems } = filteredSections;

  const starOrUnstar = ({ starred, sectionId }) => {
    updateContentItem({
      variables: {
        id: sectionId,
        type: 'sections',
        item: { starred },
      },
    });
  };

  const confirmDeleteSection = () => {
    deleteSection({
      variables: {
        _id: selectedSectionId,
        folderKey: selectedFolderKey,
      },
    });
  };

  const onClickMenuItem = async ({ actionType, sectionId, starred, sectionData, sectionName }) => {
    if (actionType === 'delete') {
      setSelectedSectionId(sectionId);
      showConfirmModal(true);
    } else if (actionType === 'starOrUnstar') {
      starOrUnstar({ starred, sectionId });
    } else if (actionType === 'preview') {
      setSelectedSection(sectionData);
      showPreviewModal(true);
    } else if (actionType === 'edit_section') {
      history.push(`/s/${sectionId}`);
    } else if (actionType === 'rename') {
      setSelectedSectionId(sectionId);
      setSelectedSectionEditValue(sectionName);
    } else if (actionType === 'save') {
      await updateContentItem({
        variables: {
          id: sectionId,
          type: 'sections',
          item: { libraryTitle: selectedSectionEditValue },
        },
      });
      setSelectedSectionId('');
      setSelectedSectionEditValue('');
    } else {
      message.warning('Coming soon 😊');
    }
  };

  const isSectionsEmpty = !(starredItems.length || allItems.length);

  // Filter out items with the key 'isHeader'
  const filteredStarredItems = starredItems.filter((item) => item.isHeader);
  const filteredAllItems = sections.filter((item) => item.isHeader);

  return (
    <div className="content-library-item content-library-item-section">
      <ContentLibraryTree
        onSelect={onSelect}
        type="headers"
        onUpdate={() =>
          refetchSections({
            type: 'sections',
            key: selectedFolderKey,
          })
        }
        userId={user && user._id}
        userRole={user?.roles && user?.roles[0]}
        isLocked={isLocked}
        setFolderName={setFolderName}
      />
      <div className="split-d2">
        {isFetchingSections ? (
          <ContentLoader selectedFolderKey={selectedFolderKey} starredItems={starredItems} />
        ) : (
          <>
            {selectedFolderKey === 'starred-folder' ? (
              <div className="stared-header">
                MY STARRED HEADERS
                <Badge className="star-badge" count={starredItems.length} folderName={folderName} />
              </div>
            ) : folderName ? (
              <div className="stared-header">{folderName}</div>
            ) : (
              <div className="stared-header">HEADERS</div>
            )}

            <div className="scroll-wrapper">
              <React.Fragment>
                {isSectionsEmpty && (
                  <div className="item-row empty-wrapper">
                    <Empty
                      className="empty-view"
                      description="Looks like you have no header files yet"
                    />
                  </div>
                )}

                <div className="item-row covers-card">
                  {filteredStarredItems.map((item) => {
                    return (
                      <SectionCard
                        onDragStart={(ev) => {
                          ev.dataTransfer.setData('data', JSON.stringify(item));
                        }}
                        item={item}
                        title={item.libraryTitle}
                        key={item._id}
                        description={item.text || item.title}
                        onClickMenuItem={onClickMenuItem}
                        sectionId={item._id}
                        isStarred
                        userId={user._id}
                        userRole={user?.roles && user?.roles[0]}
                        sectionUserId={item.uid}
                        sectionAuid={item.auid}
                        itemEditable={selectedSectionId === item._id}
                        setEditValue={setSelectedSectionEditValue}
                      />
                    );
                  })}
                </div>

                {filteredStarredItems.length ? <div className="divider-line" /> : null}

                <div className="item-row covers-card">
                  {filteredAllItems.map((item) => {
                    return (
                      <SectionCard
                        onDragStart={(ev) => {
                          ev.dataTransfer.setData('data', JSON.stringify(item));
                        }}
                        item={item}
                        title={item.libraryTitle}
                        key={item._id}
                        description={item.text || item.title}
                        sectionId={item._id}
                        userId={user._id}
                        userRole={user?.roles && user?.roles[0]}
                        onClickMenuItem={onClickMenuItem}
                        sectionUserId={item.uid}
                        sectionAuid={item.auid}
                        itemEditable={selectedSectionId === item._id}
                        setEditValue={setSelectedSectionEditValue}
                      />
                    );
                  })}
                </div>
              </React.Fragment>
            </div>
          </>
        )}
      </div>
      {previewModal && (
        <PreviewProposalModal
          onCancel={() => showPreviewModal(false)}
          section={selectedSection}
          config={config}
          user={user}
          title={selectedSection.libraryTitle}
          loadConfig={loadConfig}
        />
      )}
      {confirmModal && (
        <ConfirmModal
          title="Delete Section?"
          body="This action cannot be undone"
          visible={confirmModal}
          cancelText="CANCEL"
          confirmText="YES, DELETE"
          onCancel={() => showConfirmModal(false)}
          onConfirm={confirmDeleteSection}
        />
      )}
    </div>
  );
};

HeaderTab.defaultProps = {
  sections: [],
  searchValue: '',
  config: {},
  isLocked: false,
};

HeaderTab.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  searchValue: PropTypes.string,
  config: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object).isRequired,
  isLocked: PropTypes.bool,
};

export default HeaderTab;
