/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.2" width="40" height="40" rx="4" fill="#C7BCE4" />
    <circle cx="20" cy="10" r="6.25" fill="#C7BCE4" />
    <rect x="7.5" y="26.25" width="25" height="8.75" rx="2" fill="#7876C1" />
    <path
      d="M16.25 15H23.75L24.8112 20.9122C24.9333 21.5926 25.2867 22.21 25.8116 22.6599L30 26.25H10L14.1884 22.6599C14.7133 22.21 15.0667 21.5926 15.1888 20.9122L16.25 15Z"
      fill="#C7BCE4"
    />
  </svg>
);

const StampIcon = (props) => <Icon component={svg} {...props} />;

export default StampIcon;
