const SignatureTypes = [
  {
    text: 'Draw',
    value: 'sigdraw',
  },
  {
    text: 'Type',
    value: 'sigtext',
  },
  {
    text: 'Upload',
    value: 'sigimage',
  },
];

const SignatureOptions = [
  {
    text: 'Draw',
    value: 'draw',
  },
  {
    text: 'Type',
    value: 'type',
  },
  {
    text: 'Upload',
    value: 'upload',
  },
];

export { SignatureTypes, SignatureOptions };
