/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="#C9C8E6">
    <path d="M9.8585 7.5L12.5002 10.1333V10C12.5002 9.33696 12.2368 8.70107 11.7679 8.23223C11.2991 7.76339 10.6632 7.5 10.0002 7.5H9.8585ZM6.27516 8.16667L7.56683 9.45833C7.52516 9.63333 7.50016 9.80833 7.50016 10C7.50016 10.663 7.76356 11.2989 8.2324 11.7678C8.70124 12.2366 9.33712 12.5 10.0002 12.5C10.1835 12.5 10.3668 12.475 10.5418 12.4333L11.8335 13.725C11.2752 14 10.6585 14.1667 10.0002 14.1667C8.89509 14.1667 7.83529 13.7277 7.05389 12.9463C6.27248 12.1649 5.8335 11.1051 5.8335 10C5.8335 9.34167 6.00016 8.725 6.27516 8.16667ZM1.66683 3.55833L3.56683 5.45833L3.94183 5.83333C2.56683 6.91667 1.4835 8.33333 0.833496 10C2.27516 13.6583 5.8335 16.25 10.0002 16.25C11.2918 16.25 12.5252 16 13.6502 15.55L14.0085 15.9L16.4418 18.3333L17.5002 17.275L2.72516 2.5M10.0002 5.83333C11.1052 5.83333 12.165 6.27232 12.9464 7.05372C13.7278 7.83512 14.1668 8.89493 14.1668 10C14.1668 10.5333 14.0585 11.05 13.8668 11.5167L16.3085 13.9583C17.5585 12.9167 18.5585 11.55 19.1668 10C17.7252 6.34167 14.1668 3.75 10.0002 3.75C8.8335 3.75 7.71683 3.95833 6.66683 4.33333L8.47516 6.125C8.95016 5.94167 9.4585 5.83333 10.0002 5.83333Z" />
  </svg>
);

const EyeoffIcon = (props) => <Icon component={svg} {...props} />;

export default EyeoffIcon;
