import React from 'react';
import PropTypes from 'prop-types';

import MoveLibraryModal from './MoveLibraryModal';

const ContentLibraryModals = ({
  moveModal,
  showMoveModal,
  selectedFolderKey,
  template,
  refetchTemplates,
  refetchContentLibraryTree,
}) => {
  return (
    <>
      {moveModal && (
        <MoveLibraryModal
          visible={moveModal}
          onCancel={() => showMoveModal(false)}
          template={template}
          selectedFolderKey={selectedFolderKey}
          refetchTemplates={refetchTemplates}
          refetchContentLibraryTree={refetchContentLibraryTree}
        />
      )}
    </>
  );
};

ContentLibraryModals.defaultProps = {
  moveModal: false,
  selectedFolderKey: '',
  showMoveModal: () => {},
  refetchTemplates: () => {},
  refetchContentLibraryTree: () => {},
};

ContentLibraryModals.propTypes = {
  moveModal: PropTypes.bool,
  template: PropTypes.instanceOf(Object),
  selectedFolderKey: PropTypes.string,
  showMoveModal: PropTypes.func,
  refetchTemplates: PropTypes.func,
  refetchContentLibraryTree: PropTypes.func,
};

export default ContentLibraryModals;
