/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="109" height="17" viewBox="0 0 109 17" fill="none">
    <g clipPath="url(#clip0_14043_35899)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.58756 7.48349V11.3656H4.14827C5.35611 11.3656 6.18224 10.4979 6.18224 9.42483C6.18224 8.35123 5.35611 7.48349 4.14827 7.48349H2.58756ZM0.460938 5.58643H4.30084C6.61111 5.58643 8.3244 7.29256 8.3244 9.42483C8.3244 11.5571 6.61111 13.2627 4.28586 13.2627H2.58756V16.3214H0.460938V5.58643Z"
        fill="#05034D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.9062 5.58643H17.4094C19.9793 5.58643 21.5245 7.15976 21.5245 9.18909C21.5245 10.5278 20.852 11.8366 19.3679 12.38L21.9073 16.3214H19.475L16.1244 11.0126H17.1647C18.8175 11.0126 19.3829 9.95389 19.3829 9.18909C19.3829 8.11549 18.7715 7.49789 17.1647 7.49789H16.0329V16.3214H13.9062V5.58643Z"
        fill="#05034D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.5816 10.9541C36.5816 9.02821 34.9754 7.45434 32.9714 7.45434C30.9674 7.45434 29.3767 9.02821 29.3767 10.9541C29.3767 12.8805 30.9674 14.4538 32.9714 14.4538C34.9754 14.4538 36.5816 12.8805 36.5816 10.9541ZM27.2656 10.9541C27.2656 7.91034 29.805 5.43994 32.9714 5.43994C36.1538 5.43994 38.6932 7.91034 38.6932 10.9541C38.6932 14.0127 36.1538 16.4687 32.9714 16.4687C29.805 16.4687 27.2656 14.0127 27.2656 10.9541Z"
        fill="#05034D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M43.9219 14.5423L45.8649 13.0714C46.4158 13.9834 47.2874 14.5125 48.2817 14.5125C49.368 14.5125 49.9494 13.8362 49.9494 13.1013C49.9494 12.2191 48.8331 11.9391 47.6392 11.5866C46.14 11.1455 44.4728 10.5866 44.4728 8.52794C44.4728 6.80687 46.0335 5.43994 48.1901 5.43994C50.011 5.43994 51.0513 6.10128 51.954 6.99781L50.1947 8.27781C49.7358 7.61594 49.0773 7.26341 48.2056 7.26341C47.2114 7.26341 46.676 7.77754 46.676 8.45434C46.676 9.27781 47.7468 9.55674 48.9397 9.93914C50.4543 10.4101 52.1676 11.057 52.1676 13.1157C52.1676 14.8213 50.76 16.4682 48.2972 16.4682C46.2782 16.4682 44.9316 15.6447 43.9219 14.5423Z"
        fill="#05034D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M60.4469 7.48349V11.3656H62.0077C63.2155 11.3656 64.0416 10.4979 64.0416 9.42483C64.0416 8.35123 63.2155 7.48349 62.0077 7.48349H60.4469ZM58.3203 5.58643H62.1602C64.4705 5.58643 66.1838 7.29256 66.1838 9.42483C66.1838 11.5571 64.4705 13.2627 62.1453 13.2627H60.4469V16.3214H58.3203V5.58643Z"
        fill="#05034D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M71.7656 5.58643H78.3896V7.54216H73.8922V9.95389H78.3896V11.9096H73.8922V14.3656H78.3896V16.3214H71.7656V5.58643Z"
        fill="#05034D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M84.3906 5.58643H87.8938C90.4637 5.58643 92.0088 7.15976 92.0088 9.18909C92.0088 10.5278 91.3364 11.8366 89.8523 12.38L92.3917 16.3214H89.9593L86.6088 11.0126H87.6491C89.3019 11.0126 89.8672 9.95389 89.8672 9.18909C89.8672 8.11549 89.2558 7.49789 87.6491 7.49789H86.5172V16.3214H84.3906V5.58643Z"
        fill="#05034D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M106.761 10.9541C106.761 9.02821 105.155 7.45434 103.151 7.45434C101.147 7.45434 99.5564 9.02821 99.5564 10.9541C99.5564 12.8805 101.147 14.4538 103.151 14.4538C105.155 14.4538 106.761 12.8805 106.761 10.9541ZM97.4453 10.9541C97.4453 7.91034 99.9847 5.43994 103.151 5.43994C106.334 5.43994 108.873 7.91034 108.873 10.9541C108.873 14.0127 106.334 16.4687 103.151 16.4687C99.9847 16.4687 97.4453 14.0127 97.4453 10.9541Z"
        fill="#05034D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 1.45777H108.872V0.541504H0V1.45777Z"
        fill="#F03F3B"
      />
    </g>
    <defs>
      <clipPath id="clip0_14043_35899">
        <rect width="109" height="16" fill="white" transform="translate(0 0.541504)" />
      </clipPath>
    </defs>
  </svg>
);

const ProsperoLogo = (props) => <Icon component={svg} {...props} />;

export default ProsperoLogo;
