/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8038 6.64902C11.3281 6.36581 11.3281 5.61359 10.8038 5.33039L1.10849 0.0932758C0.47751 -0.247557 -0.225744 0.407363 0.0693763 1.06097L2.05205 5.45201L6.72546 5.9898L2.05202 6.5276L0.0693906 10.9186C-0.225731 11.5722 0.477528 12.2271 1.1085 11.8862L10.8038 6.64902Z"
      fill="#F03F3B"
    />
  </svg>
);

const PreviewIcon = (props) => <Icon component={svg} {...props} />;

export default PreviewIcon;
