import { useQuery } from '@apollo/client';
import { GET_TREEVIEW_DATA } from 'graphql/queries/contentLibraryQueries';
import React, { useEffect, useState } from 'react';

import FolderIcon from 'components/Icons/FolderIcon';
import RightIcon from 'components/Icons/RightIcon';
import StarIcon from 'components/Icons/StarIcon';

import './LibraryFolderList.scss';
import { Skeleton } from 'antd';

const LibraryItems = ({ type, selectedFolder = '', setSelectedFolder }) => {
  const [currentFolderData, setCurrentFolderData] = useState([]);

  const { data, loading } = useQuery(GET_TREEVIEW_DATA, {
    variables: {
      type,
    },
  });

  useEffect(() => {
    (async () => {
      if (data && data.fetchContentLibrary) {
        if (selectedFolder) {
          const folders = [];
          let key = '';
          setCurrentFolderData(
            selectedFolder.split('.').reduce((o, k) => {
              key = `${key ? `${key}.` : ''}${k}`;
              const child = o && o[k];
              if (child && child.title)
                folders.push({
                  title: child.title,
                  key: key,
                });
              return child;
            }, data.fetchContentLibrary)?.children || []
          );
        } else {
          setCurrentFolderData(data.fetchContentLibrary.children);
        }
      } else {
        setCurrentFolderData([]);
      }
    })();
  }, [selectedFolder, data, setCurrentFolderData]);

  return (
    <div className="gallery-library-folders">
      {loading ? (
        <Skeleton
          active
          style={{
            marginTop: '16px',
          }}
          title={false}
          paragraph={{
            rows: 4, // Number of rows
            width: ['50%', '50%', '50%', '50%'], // Ensure equal width for each row
          }}
        />
      ) : (
        <>
          {!selectedFolder && (
            <div className="folder" onClick={() => setSelectedFolder(`all-starred`)}>
              <StarIcon className="star-icon" />
              <span className="folder-name">All Starred</span>
              <RightIcon color="#7876C1" />
            </div>
          )}
          {selectedFolder !== 'all-starred' &&
            currentFolderData &&
            currentFolderData.map((folder, i) => {
              return (
                <div
                  key={i}
                  className="folder"
                  onClick={() =>
                    setSelectedFolder(`${selectedFolder ? `${selectedFolder}.` : ''}children.${i}`)
                  }>
                  <FolderIcon />
                  <span className="folder-name">{folder.title}</span>
                  <RightIcon color="#7876C1" />
                </div>
              );
            })}{' '}
        </>
      )}
    </div>
  );
};

export default LibraryItems;
