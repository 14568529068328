/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg
    width="297"
    height="220"
    viewBox="0 0 297 220"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_8135_21728)">
      <rect x="30" y="19" width="237" height="160" rx="8" fill="#F6F5FB" />
    </g>
    <path
      d="M30 27C30 22.5817 33.5817 19 38 19H259C263.418 19 267 22.5817 267 27V33H30V27Z"
      fill="white"
    />
    <circle cx="41" cy="26" r="3" fill="#F87750" />
    <circle cx="51" cy="26" r="3" fill="#F9C938" />
    <circle cx="61" cy="26" r="3" fill="#6DB142" />
    <rect x="110" y="44" width="77.6147" height="23.2844" rx="3.44954" fill="#ECECF6" />
    <rect x="128.973" y="49.1743" width="50.0183" height="3.44954" rx="1.72477" fill="white" />
    <rect x="128.973" y="56.9358" width="31.9083" height="3.44954" rx="1.72477" fill="white" />
    <circle cx="119.918" cy="54.7798" r="5.6055" fill="white" />
    <rect x="36" y="43" width="61" height="18.3" rx="2.71111" fill="#ECECF6" />
    <rect x="50.9111" y="47.0667" width="39.3111" height="2.71111" rx="1.35556" fill="white" />
    <rect x="50.9111" y="53.1667" width="25.0778" height="2.71111" rx="1.35556" fill="white" />
    <circle cx="43.7945" cy="51.4722" r="4.40556" fill="white" />
    <rect x="36" y="67" width="61" height="18.3" rx="2.71111" fill="#ECECF6" />
    <rect x="50.9111" y="71.0667" width="39.3111" height="2.71111" rx="1.35556" fill="white" />
    <rect x="50.9111" y="77.1667" width="25.0778" height="2.71111" rx="1.35556" fill="white" />
    <circle cx="43.7945" cy="75.4722" r="4.40556" fill="white" />
    <rect x="36" y="90" width="61" height="18.3" rx="2.71111" fill="#ECECF6" />
    <rect x="50.9111" y="94.0667" width="39.3111" height="2.71111" rx="1.35556" fill="white" />
    <rect x="50.9111" y="100.167" width="25.0778" height="2.71111" rx="1.35556" fill="white" />
    <circle cx="43.7945" cy="98.4722" r="4.40556" fill="white" />
    <rect x="36" y="114" width="61" height="18.3" rx="2.71111" fill="#ECECF6" />
    <rect x="50.9111" y="118.067" width="39.3111" height="2.71111" rx="1.35556" fill="white" />
    <rect x="50.9111" y="124.167" width="25.0778" height="2.71111" rx="1.35556" fill="white" />
    <circle cx="43.7945" cy="122.472" r="4.40556" fill="white" />
    <rect x="36" y="138" width="61" height="18.3" rx="2.71111" fill="#ECECF6" />
    <rect x="50.9111" y="142.067" width="39.3111" height="2.71111" rx="1.35556" fill="white" />
    <rect x="50.9111" y="148.167" width="25.0778" height="2.71111" rx="1.35556" fill="white" />
    <circle cx="43.7945" cy="146.472" r="4.40556" fill="white" />
    <rect x="110" y="114.716" width="77.6147" height="23.2844" rx="3.44954" fill="#ECECF6" />
    <rect x="128.973" y="119.89" width="50.0183" height="3.44954" rx="1.72477" fill="white" />
    <rect x="128.973" y="127.651" width="31.9083" height="3.44954" rx="1.72477" fill="white" />
    <circle cx="119.918" cy="125.495" r="5.6055" fill="white" />
    <rect x="177" y="79.3579" width="77.6147" height="23.2844" rx="3.44954" fill="#ECECF6" />
    <rect x="182.174" y="84.5322" width="50.0183" height="3.44954" rx="1.72477" fill="white" />
    <rect x="182.174" y="92.2937" width="31.9083" height="3.44954" rx="1.72477" fill="white" />
    <circle cx="245.56" cy="90.1377" r="5.6055" fill="white" />
    <defs>
      <filter
        id="filter0_d_8135_21728"
        x="0"
        y="0"
        width="297"
        height="220"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="11" />
        <feGaussianBlur stdDeviation="15" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0908559 0 0 0 0 0.07875 0 0 0 0 0.45 0 0 0 0.15 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8135_21728" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_8135_21728"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const ExistingUserImage = (props) => <Icon component={svg} {...props} />;

export default ExistingUserImage;
