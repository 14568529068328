import * as xss from 'xss';
import _ from 'lodash';

const whiteListObj = {
  script: [],
}; //can use later

let options = {
  css: false,
  whiteList: whiteListObj,
}; // Custom rules

let myxss = new xss.FilterXSS(options);

/*eslint-disable*/
const jsBaseList = [
  'javascript:',
  '&lt;script&gt;',
  '&lt;/script&gt;',
  'document.execCommand',
];
/*eslint-enable*/

const jsEventsList = ['onError', 'onClick', 'onFocus']; // can update later eg: ["onClick=", "onFocus"]

const blackList = jsEventsList.concat(jsBaseList);

const cleanObj = (obj) => {
  for (const k in obj) {
    try {
      let val = obj[k];
      if (typeof val === 'string') {
        if (/<\/?[a-z][\s\S]*>/i.test(val)) {
          val = myxss.process(val);
        }
        val = cleanStrRecursively(val);
        obj[k] = val;
      } else {
        val = cleanObj(val);
      }
    } catch (e) {
      console.error(e);
    }
  }
  return obj;
};

const sanitizeString = (msgStr) => {
  msgStr = myxss.process(msgStr);
  return cleanStrRecursively(msgStr);
};

const cleanStrRecursively = (str) => {
  for (let keyword of blackList) {
    let r = new RegExp(_.escapeRegExp(keyword), 'gmi');

    str = str.replace(r, '');
  }
  for (let keyword of blackList) {
    if (str.includes(keyword)) {
      cleanStrRecursively(str);
    }
  }
  return str;
};

// escape special characters for regex
const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export { sanitizeString, cleanObj as sanitizeObject, escapeRegExp };
