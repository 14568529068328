/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { notification } from 'antd';
import PropTypes from 'prop-types';

import { DISCONNECT_INTEGROMAT, ENABLE_INTEGROMAT } from 'graphql/mutations/integrationMutation';
import IntegrationItem from '../IntegrationItem';
import images from 'constants/images';

const IntegromatIntegration = ({ data, isModalItem, userRole }) => {
  const [integrationData, setIntegrationData] = useState(data);
  const [isIntegromatEnabled, setIsIntegromatEnabled] = useState(false);
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (data) {
      setIntegrationData(data);
      const integromatHooks = data;
      if (!integromatHooks || (integromatHooks && !integromatHooks.length)) return false;

      integromatHooks.forEach((integromatHook) => {
        if (integromatHook.enabled) {
          if (!isIntegromatEnabled) {
            setIsIntegromatEnabled(true);
          }
        }
      });
    }
  }, [data]);

  const [disconnectIntegromat] = useMutation(DISCONNECT_INTEGROMAT, {
    onCompleted: ({ handleIntegromat }) => {
      setIntegrationData(handleIntegromat.integromatHooks || []);
      setLoading('disconnected');
      notification.success({
        message: 'Integromat Disconnected',
        description: 'Your account has been disconnected from Integromat.',
      });
    },
    onError: () => {
      setLoading('');
      notification.error({
        message: 'Integromat Disconnected Failed',
        description: 'Your account failed to disconnect try again.',
      });
    },
  });

  const [enableIntegromat] = useMutation(ENABLE_INTEGROMAT, {
    onCompleted: ({ handleIntegromat }) => {
      setIntegrationData(handleIntegromat?.integromatHooks || []);
      setLoading('connected');
      notification.success({
        message: 'Integromat Connected',
        description: 'Your account has been connected to Integromat.',
      });
    },
    onError: () => {
      setLoading('');
      notification.error({
        message: 'Integromat Connection Failed',
        description: 'Your account failed to connect, try again.',
      });
    },
  });

  const handleClick = () => {
    if (!integrationData || !integrationData.length) {
      setLoading('connecting');
      return window.open(`https://www.make.com/en/integrations/prospero`, '_blank');
    } else if (integrationData.length) {
      const isEnabled = integrationData?.filter((data) => data.enabled);
      if (!isEnabled.length) {
        setLoading('connecting');
        enableIntegromat({ variables: { status: 'enabled' } });
      } else {
        setLoading('disconnecting');
        disconnectIntegromat({ variables: { status: '' } });
      }
    }
  };

  return (
    <IntegrationItem
      type="integromat"
      name="make"
      subname="(integromat)"
      description="Connect the apps you use everyday to automate your work and be more productive"
      integrationLink="https://support.goprospero.com/article/show/140088-how-to-create-triggers-from-intergomat"
      status={
        loading || (isIntegromatEnabled ? 'connected' : 'disconnected') // this can be connecting or disconnecting
      }
      image={images.INTEGROMAT_ICON}
      handleClick={handleClick}
      isModalItem={isModalItem}
      userRole={userRole}
    />
  );
};

IntegromatIntegration.defaultProps = {
  data: null,
  isModalItem: false,
  userRole: '',
};

IntegromatIntegration.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(null),
  ]),
  isModalItem: PropTypes.bool,
  userRole: PropTypes.string,
};

export default IntegromatIntegration;
