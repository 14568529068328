import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#E2E3E8" />
    <rect x="7" y="11" width="10" height="2" rx="1" fill="#2F2C88" />
    <rect x="13" y="7" width="10" height="2" rx="1" transform="rotate(90 13 7)" fill="#2F2C88" />
  </svg>
);

const AddRoundIcon = (props) => <Icon component={svg} {...props} />;

export default AddRoundIcon;
