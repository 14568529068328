import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="20" height="20" rx="10" fill="#C7BCE4" />
    <path
      d="M13.7794 9.44532C14.1434 9.72553 14.1434 10.2745 13.7794 10.5547L8.877 14.3286C8.4167 14.6829 7.75 14.3548 7.75 13.7739L7.75 6.22609C7.75 5.64521 8.4167 5.31707 8.877 5.67141L13.7794 9.44532Z"
      fill="#7876C1"
    />
  </svg>
);

const PlayIcon = (props) => <Icon component={svg} {...props} />;

export default PlayIcon;
