import React, { useState } from 'react';
import { Row, DatePicker, Divider, Col, Input, Tooltip, Popover, Avatar, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import SearchIcon from 'components/Icons/SearchIcon';
import MultiSelect from 'components/MultiSelect';
import FilterIcon from 'components/Icons/FilterIcon';
import CalendarIcon from 'components/Icons/CalendarIcon';
import UsersIcon from 'components/Icons/UsersIcon';
import TeamUsersIcon from 'components/Icons/TeamUsersIcon';

import './UserActivityHeaderFilter.scss';

const { RangePicker } = DatePicker;

const UserActivityHeaderFilter = ({
  onChangeSearch,
  filterItems,
  onChangeFilter,
  selectedDate,
  onChangeDate,
  teamList,
  onChangeTeamsFilter,
  isTeamMember,
  userPlanType,
  searchValue,
}) => {
  const history = useHistory();

  const [showSearchBox, showHideSearchBox] = useState(searchValue.length ? true : false);
  const [open, setOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState('');
  const [teamsVisible, setTeamsVisible] = useState(false);

  const enableDisableDatePicker = () => {
    if (!open) setOpen(true);
  };

  const closeDatePicker = () => {
    setOpen(false);
  };

  const disabledFutureDate = (current) => {
    return current > moment().endOf('day');
  };

  const handleTeamsVisibleChange = (visible) => {
    setActiveFilter(visible ? 'users' : '');
    setTeamsVisible(visible);
  };

  const onPlanClick = () => {
    history.push('/plans');
  };

  return (
    <div className="activity-filter">
      <Row
        justify="left"
        align="middle"
        className="activity-item-filter-menu"
        gutter={[0, { xs: 24, sm: 24, md: 0, lg: 0, xl: 0 }]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Row>
            <Col xs={8} sm={8} md={8} lg={8} xl={8} className="date-picker-col">
              <RangePicker
                bordered={false}
                suffixIcon={
                  <Tooltip title="Date Filter">
                    <CalendarIcon onClick={closeDatePicker} />
                  </Tooltip>
                }
                disabledDate={disabledFutureDate}
                value={selectedDate}
                className="date-picker "
                placeholder={['Start Date', 'End Date']}
                allowClear
                onFocus={enableDisableDatePicker}
                onChange={(value) => {
                  onChangeDate(value || null);
                  setOpen(false);
                }}
              />
            </Col>
            <Col xs={16} sm={16} md={16} lg={16} xl={16} className="search-filter-col">
              <div className="icons-container">
                <Input
                  className={`search-activity ${showSearchBox ? 'showInput' : ''}`}
                  placeholder="Search activities..."
                  autoFocus
                  onChange={onChangeSearch}
                  value={searchValue}
                  suffix={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <SearchIcon onClick={() => showHideSearchBox(!showSearchBox)} />
                  }
                />
                <SearchIcon
                  className={`content-search-icon ${showSearchBox ? 'hide-search' : ''}`}
                  onClick={() => showHideSearchBox(!showSearchBox)}
                />
                <Divider type="vertical" className="vertical-divider" />

                <MultiSelect
                  filterList={filterItems}
                  onChangeFilter={onChangeFilter}
                  isCheckBox={true}
                  onVisibleChangeHandle={(visible) => setActiveFilter(visible ? 'status' : '')}
                  iconComponent={
                    <FilterIcon
                      className={`${
                        activeFilter === 'status' ||
                        filterItems?.find(
                          (filterItem) => filterItem?.checked && filterItem?.key !== 'All'
                        )
                          ? 'anticon-active'
                          : ''
                      }`}
                    />
                  }
                />
                <Divider type="vertical" className="vertical-divider" />
                {teamList.length <= 1 && !isTeamMember ? (
                  <Popover
                    content={
                      <Col>
                        <Avatar
                          size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                          icon={<TeamUsersIcon />}
                        />
                        <h3>Upgrade to Invite Your Team</h3>
                        <Divider />
                        <p>
                          Close even more deals with them for only $
                          {userPlanType === 'new' ? 10 : 8} a month per user.
                        </p>
                        <Button
                          size="middle"
                          type="button"
                          className="button"
                          onClick={onPlanClick}>
                          {` 🎉 Upgrade now!`}
                        </Button>
                      </Col>
                    }
                    placement="bottomLeft"
                    overlayClassName="teams-dashboard-popup"
                    trigger={'click'}
                    visible={teamsVisible}
                    onVisibleChange={handleTeamsVisibleChange}>
                    <Tooltip title="Users Filter">
                      <UsersIcon
                        className={`teams-filter ${
                          activeFilter === 'users' ? 'anticon-active' : ''
                        }`}
                      />
                    </Tooltip>
                  </Popover>
                ) : !isTeamMember ? (
                  <MultiSelect
                    filterList={teamList}
                    onChangeFilter={onChangeTeamsFilter}
                    onVisibleChangeHandle={(visible) => setActiveFilter(visible ? 'users' : '')}
                    iconComponent={
                      <UsersIcon
                        className={`${
                          activeFilter === 'users' || teamList?.find((team) => team?.checked)
                            ? 'anticon-active'
                            : ''
                        }`}
                      />
                    }
                  />
                ) : null}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

UserActivityHeaderFilter.defaultProps = {
  selectedDate: [],
  teamList: [],
  isTeamMember: false,
  searchValue: '',
};

UserActivityHeaderFilter.propTypes = {
  onChangeSearch: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  onChangeTeamsFilter: PropTypes.func.isRequired,
  selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  filterItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  teamList: PropTypes.arrayOf(PropTypes.shape({})),
  isTeamMember: PropTypes.bool,
  searchValue: PropTypes.string,
};

export default UserActivityHeaderFilter;
