import React from 'react';
import { Button, Row, Col, Slider } from 'antd';
import Dropzone from 'react-dropzone';

import UploadCloudIcon from 'components/Icons/UploadCloudIcon';
import ArrowTop from 'components/Icons/ArrowTop';

import './SelectImages.scss';

const SelectImages = ({
  dropImage,
  uploadStatus,
  showProgress,
  imageName,
  addFromLib,
  uploadingImages,
  hideLibraryButton,
}) => {
  return (
    <Col className="select-image">
      {showProgress ? (
        <div className="logo-upload-progress">
          {/* <p className="progress-title">Uploading Image...</p> */}
          <p className="upload-image-name" title={imageName}>
            {imageName}
          </p>
          <div className="spacer" />
          <Slider value={uploadStatus.percent} tooltipVisible />
        </div>
      ) : (
        <>
          <Dropzone onDrop={dropImage} accept="image/jpeg, image/png, image/svg+xml, image/bmp">
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <div
                  {...getRootProps()}
                  className={`dropzone ${isDragActive && 'dropzone--isActive'}`}>
                  <input {...getInputProps()} />
                  <Col className="select-image-uploader">
                    <UploadCloudIcon />
                    <p className="select-image-title">
                      {uploadingImages.length <= 0 && `Start by adding your files`}
                    </p>
                    <p className="select-image-info">
                      Drag & drop your files or upload from you computer
                    </p>
                    <p className="select-image-details">Maximum 3MB</p>
                    <span className="image-size">
                      Recommended sizes - Small: 900px width x 465px height Large: 900px width x
                      1250px height
                    </span>
                    <Row className="select-image-upload-text">
                      <ArrowTop className="select-image-upload-icon" />
                      <h3>UPLOAD IMAGES</h3>
                    </Row>
                  </Col>
                </div>
              );
            }}
          </Dropzone>

          {!hideLibraryButton && (
            <>
              <p>Or</p>
              <Button className="button cancel" type="primary" onClick={addFromLib}>
                Choose From Library
              </Button>
            </>
          )}

          {uploadingImages.length > 0 && (
            <div className="image-preview">
              {uploadingImages.map((img) => (
                <img src={img} key={img} alt="" />
              ))}
            </div>
          )}
        </>
      )}
    </Col>
  );
};

export default SelectImages;
