import ProjectDesignIcon from 'components/Icons/ProjectDesignIcon';
import ProjectDevelopmentIcon from 'components/Icons/ProjectDevelopmentIcon';
import ProjectVideoIcon from 'components/Icons/ProjectVideoIcon';
import ProjectCopywritingIcon from 'components/Icons/ProjectCopywritingIcon';
import ProjectMarketingIcon from 'components/Icons/ProjectMarketingIcon';
import ProjectOtherIcon from 'components/Icons/ProjectOtherIcon';

const ProjectTypes = [
  {
    label: 'Design',
    key: 'design',
    icon: <ProjectDesignIcon className="checkbox-icon" />,
  },
  {
    label: 'Development',
    key: 'web',
    icon: <ProjectDevelopmentIcon className="checkbox-icon" />,
  },
  {
    label: 'Video',
    key: 'video',
    icon: <ProjectVideoIcon className="checkbox-icon" />,
  },
  {
    label: 'Marketing',
    key: 'marketing',
    icon: <ProjectMarketingIcon className="checkbox-icon" />,
  },
  {
    label: 'Copywriting',
    key: 'copy',
    icon: <ProjectCopywritingIcon className="checkbox-icon" />,
  },
  {
    label: 'Other',
    key: 'other',
    icon: <ProjectOtherIcon className="checkbox-icon" />,
  },
];

export default ProjectTypes;
