import { getVisibleSelectionRect, SelectionState } from 'draft-js';
import _ from 'lodash';

let lastOffset; // used when editor loses focus

const findParent = (el, parentSelector /* optional */) => {
  // If no parentSelector defined will bubble up all the way to *document*
  if (parentSelector === undefined) {
    parentSelector = document;
  }

  let p = el.parentNode;

  let isPresent = 0;
  while (p && p !== parentSelector && isPresent === 0) {
    const o = p;
    if (o.id === parentSelector) {
      isPresent = 1;
    }
    p = o.parentNode;
  }

  return isPresent;
};

const getSelectionRange = () => {
  let t = '';
  if (window?.getSelection) {
    t = window.getSelection();
  } else if (document?.getSelection) {
    t = document.getSelection();
  } else if (document?.selection) {
    t = document.selection.createRange().text;
  }
  if (t.rangeCount === 0) return null;
  return t ? t?.getRangeAt(0) : null;
};

const getSelectedBlockElement = (range, id) => {
  let node = range.startContainer;
  const parentEl = range.commonAncestorContainer;

  if (!findParent(parentEl, id)) return null;

  do {
    const nodeIsDataBlock = node.getAttribute ? node.getAttribute('data-block') : null;
    if (nodeIsDataBlock) return node;
    node = node.parentNode;
  } while (node !== null);
  return null;
};

const getSelectionCoords = (id) => {
  const editorBounds = document.getElementById(`r_${id}`)?.getBoundingClientRect();
  if (!editorBounds) {
    return { offsetLeft: 0, offsetTop: 0, offsetRight: 0, offsetBottom: 0 };
  }
  let therect = null;
  const srange = getSelectionRange();
  if (srange && srange.getClientRects) {
    const rects = srange.getClientRects();
    _.each(rects, function (r) {
      if (r.width !== 0 && !therect) {
        therect = r;
      }
    });
  }
  therect = therect || getVisibleSelectionRect(window);

  if (!getVisibleSelectionRect(window)) {
    // const selectionOut = window.getSelection && window.getSelection();
    // const range = selectionOut && selectionOut.rangeCount > 0 && selectionOut?.getRangeAt?.(0);
    // const domReact = (range.startContainer)?.getBoundingClientRect();
    // if (domReact) {
    //   therect = {
    //     bottom: domReact.bottom,
    //     height: domReact.height,
    //     left: domReact.left,
    //     right: domReact.right,
    //     top: domReact.top - 236,
    //     width: 0
    //   };
    // }

    therect = {
      bottom: 0,
      height: 0,
      left: editorBounds.left + 1,
      right: 0,
      top: 0,
      width: 0,
    };
  }

  if (!therect) {
    return null;
  }
  const rangeBounds = therect;
  const selection = window?.getSelection();

  // Check if there is a valid selection and that it contains at least one range
  if (!selection || selection.rangeCount === 0) {
    console.error('No selection or range found.', lastOffset);
    return lastOffset;
  }

  const range = selection?.getRangeAt?.(0);
  let rect = range?.getBoundingClientRect();
  const contentRect = document.getElementById(`r_${id}`)?.getBoundingClientRect();

  // Handle the case when the cursor is at a new line
  if (rect?.width === 0 && rect?.height === 0) {
    rect = range?.startContainer?.parentElement?.getBoundingClientRect();
  }

  const clientRects = range?.getClientRects();
  let lastRect;

  if (clientRects?.length > 0) {
    lastRect = clientRects[clientRects?.length - 1];
  }

  const offsetLeft = rangeBounds?.left - editorBounds?.left - 142 / 2; // + (rangeWidth / 2) ;  /* 72px is width of inline toolbar */
  const offsetRight = editorBounds?.right - rangeBounds?.right - 142 / 2; // + (rangeWidth / 2) ;  /* 72px is width of inline toolbar */
  const offsetBottom = rect?.bottom - contentRect?.top + window?.scrollY;
  // 42px is height of inline toolbar (35px) + 5px center triangle and 2px for spacing
  const offsetTop =
    lastRect?.top && clientRects?.length > 1
      ? lastRect.top + window.scrollY + lastRect.height - editorBounds.top - 60
      : rangeBounds.top - editorBounds.top - 30;
  const offsetAILeft = lastRect?.right
    ? lastRect.right - editorBounds?.left + window?.scrollX
    : rangeBounds?.left - editorBounds?.left;
  const offsetAIRight = Math.floor(rect?.left - editorBounds?.left);

  lastOffset = {
    offsetLeft: {
      aiLeft: offsetAILeft,
      toolbarLeft: offsetLeft,
    },
    offsetTop,
    offsetRight: {
      aiRight: offsetAIRight,
      toolbarRight: offsetRight,
    },
    offsetBottom,
  };

  return lastOffset;
};

const updateSelectionInlineToolbar = (sectionId, proposalLanguage, dispatch) => {
  const selectionCoords = getSelectionCoords(sectionId);

  if (selectionCoords) {
    dispatch({
      type: 'setInlineToolbar',
      inlineToolbar: {
        show: true,
        position:
          proposalLanguage !== 'hebrew'
            ? {
                top: selectionCoords.offsetTop,
                left: selectionCoords.offsetLeft,
                bottom: selectionCoords.offsetBottom,
              }
            : {
                top: selectionCoords.offsetTop,
                right: selectionCoords.offsetRight,
                bottom: selectionCoords.offsetBottom,
              },
      },
    });
  }
};

const getBlockSelection = (block) => {
  // get the entire block
  const blockKey = block.getKey();
  const blockLength = block.getLength();
  // console.log(blockKey, blockLength);

  return SelectionState.createEmpty(blockKey).merge({
    anchorOffset: 0,
    focusOffset: blockLength,
  });
};

export {
  getSelectionRange,
  getSelectedBlockElement,
  getSelectionCoords,
  updateSelectionInlineToolbar,
  getBlockSelection,
};
