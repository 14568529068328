import { RichUtils } from 'draft-js';
import updateSelection from './updateSelection';

const onTab = ({
  e,
  editorChange,
  editorRef,
  editorState,
  props,
  dispatch,
  onToggleCustomBockStyle,
  tabType,
}) => {
  e.preventDefault();
  const selection = editorState?.getSelection();

  if (selection.isCollapsed()) {
    const maxDepth = 4;
    editorChange({
      editorState: RichUtils.onTab(e, editorState, maxDepth),
      props,
      dispatch,
      updateSelection,
      editorRef,
    });
  } else {
    onToggleCustomBockStyle({
      blockType: tabType,
      type: 'intent',
    });
  }
};

export default onTab;
