import { gql } from '@apollo/client';

export const FILE_UPLOAD = gql`
  mutation($file: Upload!, $path: String!) {
    uploadFile(file: $file, path: $path) {
      filename
      mimetype
      encoding
      url
    }
  }
`;

export const FILES_UPLOAD = gql`
  mutation($files: [Upload!]!, $path: String!) {
    uploadFiles(files: $files, path: $path) {
      filename
      mimetype
      encoding
      url
    }
  }
`;

export const COPY_FILE = gql`
  mutation($copyFile: CopyFileInput!) {
    copyFile(copyFile: $copyFile)
  }
`;

export const GENERATE_IDENTITY_TOKEN = gql`
  mutation {
    generateIdentityToken
  }
`;
