import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="48" height="48" viewBox="0 -6 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="8" y="14" width="16" height="16" rx="2" stroke="#7876C1" strokeWidth="2" />
    <rect x="27" y="19" width="14" height="2" rx="1" fill="#C7BCE4" />
    <rect x="27" y="15" width="14" height="2" rx="1" fill="#C7BCE4" />
    <rect x="27" y="23" width="14" height="2" rx="1" fill="#C7BCE4" />
    <rect x="27" y="27" width="7" height="2" rx="1" fill="#C7BCE4" />
  </svg>
);

const NewSectionIcon = (props) => <Icon component={svg} {...props} />;

export default NewSectionIcon;
