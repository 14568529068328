import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.37012 5.62C4.37012 5.11333 4.51678 4.71667 4.81012 4.43C5.11012 4.14333 5.49345 4 5.96012 4C6.42678 4 6.80678 4.14333 7.10012 4.43C7.40012 4.71667 7.55012 5.11333 7.55012 5.62C7.55012 6.13333 7.40012 6.53333 7.10012 6.82C6.80678 7.10667 6.42678 7.25 5.96012 7.25C5.49345 7.25 5.11012 7.10667 4.81012 6.82C4.51678 6.53333 4.37012 6.13333 4.37012 5.62ZM10.7901 4.12L6.86012 11.12H5.50012L9.42012 4.12H10.7901ZM5.95012 4.84C5.58345 4.84 5.40012 5.1 5.40012 5.62C5.40012 6.14667 5.58345 6.41 5.95012 6.41C6.13012 6.41 6.27012 6.34667 6.37012 6.22C6.47012 6.08667 6.52012 5.88667 6.52012 5.62C6.52012 5.1 6.33012 4.84 5.95012 4.84ZM8.76012 9.61C8.76012 9.09667 8.90678 8.7 9.20012 8.42C9.50012 8.13333 9.88345 7.99 10.3501 7.99C10.8168 7.99 11.1935 8.13333 11.4801 8.42C11.7735 8.7 11.9201 9.09667 11.9201 9.61C11.9201 10.1233 11.7735 10.5233 11.4801 10.81C11.1935 11.0967 10.8168 11.24 10.3501 11.24C9.87678 11.24 9.49345 11.0967 9.20012 10.81C8.90678 10.5233 8.76012 10.1233 8.76012 9.61ZM10.3401 8.83C9.96012 8.83 9.77012 9.09 9.77012 9.61C9.77012 10.1367 9.96012 10.4 10.3401 10.4C10.7135 10.4 10.9001 10.1367 10.9001 9.61C10.9001 9.09 10.7135 8.83 10.3401 8.83Z"
      fill="#05034D"
    />
    <rect x="1.5" y="1.5" width="13" height="13" rx="6.5" stroke="#05034D" />
  </svg>
);

const DiscountIcon = (props) => <Icon component={svg} {...props} />;

export default DiscountIcon;
