import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Popover } from 'antd';
import { ChromePicker } from 'react-color';
import './ChromeBox.scss';

const ChromeBox = ({ color, onChange, disableAlpha = false }) => {
  const [colorValue, setColor] = useState(color);

  useEffect(() => {
    setColor(color);
  }, [color]);

  const onColorChange = (selectedColor) => {
    const {
      hex,
      rgb: { r, g, b, a },
    } = selectedColor;
    const color = disableAlpha ? hex : `rgba(${r},${g},${b},${a})`;

    setColor(color);
    debounceChange(color);
  };

  const debounceChange = useRef(
    _.debounce((color) => {
      onChange(color);
    }, 300)
  ).current;

  return (
    <Popover
      overlayClassName="color-popover"
      placement="bottom"
      content={
        <ChromePicker disableAlpha={disableAlpha} color={colorValue} onChange={onColorChange} />
      }
      trigger="click">
      <div className="color-wrapper">
        <div className="background" style={{ backgroundColor: colorValue }} />
      </div>
    </Popover>
  );
};

ChromeBox.propTypes = {
  color: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disableAlpha: PropTypes.bool,
};

export default ChromeBox;
