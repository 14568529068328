import { gql } from '@apollo/client';

export const UPDATE_WHITELISTED_LINK = gql`
  mutation updateWhitelistedLinkMutation($_id: String!, $blocked: Boolean!) {
    updateWhitelistedLink(_id: $_id, blocked: $blocked) {
      _id
      url
      blocked
      createdAt
      updatedAt
    }
  }
`;
