import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 5L1 7L3 9"
      stroke="#05034D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect width="13" height="2" rx="1" transform="matrix(-1 0 0 1 14 0)" fill="#05034D" />
    <rect width="8" height="2" rx="1" transform="matrix(-1 0 0 1 14 4)" fill="#05034D" />
    <rect width="13" height="2" rx="1" transform="matrix(-1 0 0 1 14 12)" fill="#05034D" />
    <rect width="8" height="2" rx="1" transform="matrix(-1 0 0 1 14 8)" fill="#05034D" />
  </svg>
);

const LeftIntentIcon = (props) => <Icon component={svg} {...props} />;

export default LeftIntentIcon;
