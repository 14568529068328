import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
    <path d="M2.61111 33.2222C4.83333 31.8889 6.83337 30.037 8.61115 27.6667C10.463 25.2222 11.3889 22.7778 11.3889 20.3333C11.3889 19.6667 11.2037 19.1481 10.8334 18.7778C10.463 18.3333 9.90744 18.1111 9.1667 18.1111C7.31485 18.1111 5.72228 17.4815 4.38894 16.2222C3.12968 14.8889 2.5 13.2963 2.5 11.4444C2.5 9.59259 3.12968 8.07407 4.38894 6.88888C5.72228 5.62963 7.31485 5 9.1667 5C11.7593 5 13.7963 5.96296 15.2778 7.88888C16.8333 9.74074 17.6112 12.1852 17.6112 15.2222C17.6112 19.3704 16.463 23.1481 14.1667 26.5556C11.9445 29.8889 8.50006 32.7037 3.83339 35L2.61111 33.2222ZM21.8334 33.2222C24.0556 31.8889 26.0556 30.037 27.8333 27.6667C29.6852 25.2222 30.6111 22.7778 30.6111 20.3333C30.6111 19.6667 30.4259 19.1481 30.0556 18.7778C29.6852 18.3333 29.1296 18.1111 28.3889 18.1111C26.537 18.1111 24.9445 17.4815 23.6111 16.2222C22.3519 14.8889 21.7223 13.2963 21.7223 11.4444C21.7223 9.59259 22.3519 8.07407 23.6111 6.88888C24.9445 5.62963 26.537 5 28.3889 5C30.9815 5 33.0186 5.96296 34.5 7.88888C36.0556 9.74074 36.8334 12.1852 36.8334 15.2222C36.8334 19.3704 35.6852 23.1481 33.3889 26.5556C31.1667 29.8889 27.7223 32.7037 23.0556 35L21.8334 33.2222Z" />
  </svg>
);

const Quote2Icon = (props) => <Icon component={svg} {...props} />;

export default Quote2Icon;
