import React from 'react';
import PropTypes from 'prop-types';
import { Popover, Row, Col, Divider } from 'antd';

import DeleteTableRowColumnIcon from 'components/Icons/DeleteTableRowColumnIcon';
import Button from 'components/Button';

const RemoveRow = ({
  hoveredRow,
  rowIndex,
  deleteRow,
  rowDeleteVisibleChange,
  setRowDeleteVisibleChange,
  setHoveredDeleteRow,
  setColumnDeleteVisibleChange,
}) => {
  return (
    <div
      className="table-row-action"
      style={
        hoveredRow === rowIndex || rowDeleteVisibleChange === rowIndex
          ? { opacity: 1 }
          : { opacity: 0 }
      }>
      <Popover
        content={
          <Col className="table-delete-popover">
            <h3 className="ant-popover-title">Delete Row {rowIndex + 1}?</h3>
            <Divider />
            <Row>
              <Button
                type="primary"
                size="large"
                className="Prosprich-editor-components-popover-confirm"
                text="Delete"
                onClick={() => {
                  deleteRow();
                  setRowDeleteVisibleChange(false);
                }}
              />
              <Button
                type="default"
                size="large"
                className="Prosprich-editor-components-popover-cancel"
                text="Cancel"
                onClick={() => setRowDeleteVisibleChange(false)}
              />
            </Row>
          </Col>
        }
        overlayClassName="Prosprich-editor-components-popover"
        trigger="click"
        visible={rowDeleteVisibleChange === rowIndex}
        onVisibleChange={() => {
          setRowDeleteVisibleChange(rowIndex);
          setColumnDeleteVisibleChange(false);
        }}>
        <DeleteTableRowColumnIcon
          onMouseOver={() => setHoveredDeleteRow(rowIndex)}
          onMouseLeave={() => setHoveredDeleteRow('')}
        />
      </Popover>
    </div>
  );
};

RemoveRow.defaultProps = {
  hoveredRow: '',
  rowDeleteVisibleChange: '',
};

RemoveRow.propTypes = {
  hoveredRow: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rowIndex: PropTypes.number.isRequired,
  deleteRow: PropTypes.func.isRequired,
  rowDeleteVisibleChange: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setRowDeleteVisibleChange: PropTypes.func.isRequired,
  setHoveredDeleteRow: PropTypes.func.isRequired,
  setColumnDeleteVisibleChange: PropTypes.func.isRequired,
};

export default RemoveRow;
