import { Button, Card, message, Modal, Row, Space, Statistic } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import React, { useState } from 'react';

import { FETCH_ADMIN_PROXY_SERVER_DETAILS } from 'graphql/queries/adminQueries';
import { RESTART_ADMIN_PROXY_SERVER } from 'graphql/mutations/adminMutations';

import './DigitalOcean.scss';

const DigitalOcean = () => {
  const [runningProcess, setRunningProcess] = useState(0);

  const { loading: isFetchingAdminProxyServerDetails, refetch } = useQuery(
    FETCH_ADMIN_PROXY_SERVER_DETAILS,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ fetchAdminProxyServerDetails }) =>
        setRunningProcess(fetchAdminProxyServerDetails),
    }
  );

  const [restartAdminProxyServer] = useMutation(RESTART_ADMIN_PROXY_SERVER, {
    onCompleted: () => message.success('Process restarted successfully'),
    onError: () => message.error(`Failed to restart server`),
  });

  const restartProcess = () => {
    Modal.confirm({
      title: 'Do you want to restart process?',
      onOk: restartAdminProxyServer,
    });
  };

  const refetchServerStatus = async () => {
    try {
      const serverStatus = await refetch();
      setRunningProcess(serverStatus?.data?.fetchAdminProxyServerDetails);
    } catch (err) {
      setRunningProcess();
    }
  };

  return (
    <div className="admin-wrapper digital-ocean">
      <Card>
        <div className="status">
          <Statistic
            loading={isFetchingAdminProxyServerDetails}
            title="Running process"
            value={runningProcess}
          />
          <Statistic
            loading={isFetchingAdminProxyServerDetails}
            title="Server status"
            value={runningProcess ? 'Active' : 'Inactive'}
            valueStyle={{ color: runningProcess ? '#3f8600' : '#cf1322' }}
          />
        </div>
        <Row justify="center" align="middle">
          <Space>
            <Button style={{ marginTop: 16 }} type="primary" danger onClick={refetchServerStatus}>
              Refresh
            </Button>
            <Button style={{ marginTop: 16 }} type="primary" danger onClick={restartProcess}>
              Restart
            </Button>
          </Space>
        </Row>
      </Card>
    </div>
  );
};

export default DigitalOcean;
