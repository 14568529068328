import { useImperativeHandle, forwardRef } from 'react';
import { useQuery } from '@apollo/client';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';

import helpers from 'helpers';
import { PublishSection } from 'pages/Proposal/components/Publish/components/PublishContent/components';
import { GET_ALL_SECTIONS_LIBRARIES } from 'graphql/queries/contentLibraryQueries';

import './Sections.scss';

const Sections = forwardRef(
  ({ selectedFolder, dragStart, dragEnd, addNewSectionFromLibrary }, ref) => {
    const { data, refetch: refetchSections } = useQuery(GET_ALL_SECTIONS_LIBRARIES, {
      fetchPolicy: 'network-only',
      variables: {
        type: 'sections',
        key: selectedFolder,
      },
    });

    useImperativeHandle(ref, () => ({
      async reloadItems() {
        await refetchSections({
          variables: {
            type: 'sections',
            key: selectedFolder,
          },
        });
      },
    }));

    return (
      <Row className="library-sections-container">
        {data?.fetchContentLibrarySectionsItems?.sections.filter((item) => !item.isHeader).map((item, itemIndex) => (
          <Col
            span={24}
            draggable
            onDragStart={(event) => dragStart(event, item)}
            onDragEnd={dragEnd}
            className="toolbar-library-section"
            id={`toolbar-library-section-${item._id}`}
            data-section={JSON.stringify(item)}
            key={itemIndex + item._id}
            onClick={() => addNewSectionFromLibrary({ section: item })}>
            {item.thumbnail ? (
              <div className="section-text">
                <div className="overlay-mask" />
                <img
                  src={helpers.formatS3Link(item?.thumbnail)}
                  className="section-image"
                  alt="..."
                />
              </div>
            ) : (
              <div className="section-text" style={{ flexDirection: 'column' }}>
                <PublishSection
                  prop={{ draft: { item } }}
                  name="item"
                  configText={(x) => x}
                  ispdf={false}
                />
              </div>
            )}
            <Row className="section-title">
              <span>{item.libraryTitle}</span>
            </Row>
          </Col>
        ))}
      </Row>
    );
  }
);

Sections.propTypes = {
  selectedFolder: PropTypes.string.isRequired,
  dragStart: PropTypes.func.isRequired,
  dragEnd: PropTypes.func.isRequired,
  addNewSectionFromLibrary: PropTypes.func.isRequired,
};

export default Sections;
