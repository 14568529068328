import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16" rx="8" fill="#F5F6FA" />
    <rect
      x="5.17188"
      y="4.22864"
      width="9.33333"
      height="1.33333"
      rx="0.666667"
      transform="rotate(45 5.17188 4.22864)"
      fill="#05034D"
    />
    <rect
      x="11.7715"
      y="5.17139"
      width="9.33333"
      height="1.33333"
      rx="0.666667"
      transform="rotate(135 11.7715 5.17139)"
      fill="#05034D"
    />
  </svg>
);

const RemoveIcon = (props) => <Icon component={svg} {...props} />;

export default RemoveIcon;
