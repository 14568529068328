import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#F5F6FA" />
    <rect
      x="7.75781"
      y="6.3418"
      width="14"
      height="2"
      rx="1"
      transform="rotate(45 7.75781 6.3418)"
      fill="#05034D"
    />
    <rect
      x="17.6562"
      y="7.75586"
      width="14"
      height="2"
      rx="1"
      transform="rotate(135 17.6562 7.75586)"
      fill="#05034D"
    />
  </svg>
);

const CloseIcon = (props) => <Icon component={svg} {...props} />;

export default CloseIcon;
