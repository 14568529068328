import React, { useState } from 'react';
import { Table } from 'antd';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import _ from 'lodash';

import { FETCH_ADMIN_PAYPAL } from 'graphql/queries/adminQueries';
import { PayPalTransactionDetails, PayPalInvoice } from './components';

const PaypalDetails = () => {
  const initialFilters = {
    startDate: moment().subtract(1, 'month').toISOString(),
    endDate: moment().endOf('day').toISOString(),
  };
  const [filters, setFilters] = useState(initialFilters);
  const [payPal, setPayPal] = useState([]);
  const [sum, setSum] = useState([]);
  const [loadInvoice, setLoadInvoice] = useState(false);

  const totalSumF = (only, not, totals) => {
    let totalSum = [];

    _.forEach(totals, (v, k) => {
      if (not === k) {
        return null;
      }
      if ((only && k === only) || !only) {
        totalSum.push({
          type: k,
          amt: v.toFixed(2),
        });
      }
    });

    return totalSum;
  };

  const typeToType = (transaction, language) => {
    let ret =
      transaction['L_NUMBER0'] ||
      transaction['SUBJECT'] ||
      transaction['L_NAME0'] ||
      transaction['NOTE'];

    if (
      transaction['L_NUMBER0'] === 'The Pricing Class' ||
      transaction['L_NUMBER0'] === 'The Marketing Class' ||
      transaction['L_NUMBER0'] === 'The Bundle'
    ) {
      ret = language === 'he' ? 'שיעור למעצבים: ' : 'Online Class: ';
      ret += transaction['L_NUMBER0'] || transaction['SUBJECT'];
    }

    if (transaction['L_NUMBER0'] === 'propcredit') {
      ret = language === 'he' ? 'תשלום פרוספרו חד פעמי' : 'Prospero One Time Payment';
    }

    if (transaction['SUBJECT'] === 'Prospero Monthly Subscription, $8') {
      ret = language === 'he' ? 'תשלום מנוי פרוספרו' : 'Prospero Monthly Subscription Payment';
      ret += ` (${transaction['AMT']})`;
    }

    if (transaction['SUBJECT'] === 'Prospero Yearly Subscription, $60') {
      ret = language === 'he' ? 'תשלום מנוי פרוספרו' : 'Prospero Yearly Subscription Payment = $60';
    }

    if (transaction['EMAIL'] === 'paypal@gumroad.com') {
      ret = language === 'he' ? 'תשלום מרוכז גאמרואוד - ספר' : 'Gumroad Book payment';
    }

    if (transaction['TRANSACTIONTYPE'] === 'refund') {
      ret = language === 'he' ? 'ביטול - החזר יזום' : 'Refund';
    }

    if (transaction['TRANSACTIONTYPE'] === 'reversal') {
      ret = language === 'he' ? 'ביטול - החזר' : 'Reversal';
    }

    return ret;
  };

  const updateAdminPayPal = (transactions) => {
    let totals = {};
    let sumInfo = [];
    let tempTransactions = [...transactions];

    tempTransactions = tempTransactions.sort(
      (a, b) => new Date(b['L_TIMESTAMP']) - new Date(a['L_TIMESTAMP'])
    );
    tempTransactions = _.uniq(tempTransactions, (x) => x['L_TRANSACTIONID']);

    tempTransactions.forEach((vals) => {
      // let classname = 'trans'
      // if (this.state.ignores[vals['L_TRANSACTIONID']]) {
      //   classname += ' ' + 'ignore';
      // }

      // if (!vals.details) {
      //   classname += ' ' + 'no-details';
      // }

      if (vals.details) {
        const type = typeToType(vals.details);
        if (!type) {
          console.warn('undefined trans type', vals);
        }
        totals[type] = parseFloat(vals['L_AMT']) + (totals[type] || 0);
        totals['final'] = parseFloat(vals['L_AMT']) + (totals['final'] || 0);
      }
    });

    sumInfo = [...sumInfo, ...totalSumF(null, 'final', totals)];
    sumInfo = [...sumInfo, ...totalSumF('final', totals)];

    setSum(sumInfo);
    setPayPal(tempTransactions);
  };

  const { loading: isFetchingAdminPayPal, refetch } = useQuery(FETCH_ADMIN_PAYPAL, {
    variables: {
      userCondition: {
        queryDates: {
          startDate: filters.startDate,
          endDate: filters.endDate,
        },
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ fetchAdminPayPal }) => {
      updateAdminPayPal(fetchAdminPayPal);
    },
  });

  const handleFilters = (type, value) => {
    filters[type] = value;
    setFilters({ ...filters });
  };

  const sumColumn = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Amount ($)',
      dataIndex: 'amt',
      key: 'amt',
    },
  ];

  return (
    <div>
      <div className="admin-table-wrapper">
        <div className="table-action">
          <h3>Sum</h3>
        </div>
        <Table dataSource={sum} columns={sumColumn} />
      </div>

      {loadInvoice ? (
        <PayPalInvoice payPal={payPal} typeToType={typeToType} setLoadInvoice={setLoadInvoice} />
      ) : (
        <PayPalTransactionDetails
          payPal={payPal}
          refetch={refetch}
          filters={filters}
          handleFilters={handleFilters}
          isFetchingAdminPayPal={isFetchingAdminPayPal}
          setLoadInvoice={setLoadInvoice}
        />
      )}
    </div>
  );
};

export default PaypalDetails;
