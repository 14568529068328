import { useEffect } from 'react';

/**
 * Hook that handle clicks outside of the passed ref
 */
export default function useOutsideClick(
  ref,
  handleClick = () => {},
  handleClickCondition = () => true
) {
  useEffect(() => {
    /**
     * Handle if clicked on outside of element
     */
    const handleOutsideClick = (event) => {
      if (ref?.current && !ref?.current?.contains(event.target) && handleClickCondition(event)) {
        handleClick();
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [ref, handleClick, handleClickCondition]);
}
