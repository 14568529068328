import React from 'react';
import { Row, Col, Badge } from 'antd';

import './HeaderTabLoader.scss';

const Skeleton = (props) => <div className="content-library-skeleton" {...props}></div>;

const ContentSkeleton = () => (
  <Col
    xs={{ span: 24, offset: 0, order: 2 }}
    sm={{ span: 24, offset: 0, order: 2 }}
    md={{ span: 24, offset: 0, order: 2 }}
    lg={{ span: 6, offset: 0, order: 1 }}
    xl={{ span: 8, offset: 0, order: 1 }}
    className="value">
    <Skeleton />
  </Col>
);

const HeaderTabLoader = ({ selectedFolderKey, starredItems, folderName }) => {
  return (
    <div className="content-library-skeleton-container scroll-wrapper">
      {selectedFolderKey === 'starred-folder' ? (
        <div className="stared-header">
          MY STARRED HEADERS
          <Badge className="star-badge" count={starredItems.length} />
        </div>
      ) : folderName ? (
        <div className="stared-header">{folderName}</div>
      ) : (
        <div className="stared-header">HEADERS</div>
      )}
      <Row className="item-row covers-card">
        {Array.from({ length: 8 }, (_, x) => (
          <ContentSkeleton key={x} />
        ))}
      </Row>
    </div>
  );
};

export default HeaderTabLoader;
