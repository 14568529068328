import React, { useEffect, useState } from 'react';
import { Button, Space, Table, Col } from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';

import sigData from './poptinSig.js';

const PayPalInvoice = ({ payPal, typeToType, setLoadInvoice }) => {
  const [invoiceData, setInvoiceData] = useState([]);

  const fdate = (x) => {
    var dd = x.getDate();
    var mm = x.getMonth() + 1;
    var yyyy = x.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return dd + '/' + mm + '/' + yyyy;
  };

  useEffect(() => {
    if (!invoiceData?.length) {
      let language = window.confirm('English Invoices?') ? 'en' : 'he';
      let invoice_number = parseInt(prompt('start with invoice number:') || '0');
      let invoiceInfo = [];

      _.map(payPal, (row) => {
        if (!row.details) {
          return null;
        }

        const totalpayed = parseInt(row['L_NETAMT']);

        const x = {
          date: new Date(row['L_TIMESTAMP']),
          name: row['L_NAME'].split(' ')[0],
          last_name: row['L_NAME'].split(' ')[1],
          email: row['L_EMAIL'],
          country: row.details['COUNTRYCODE'],
          invoice_number: invoice_number,
          price_USD: Math.floor(row['L_NETAMT']),
          price_ILS: Math.floor(parseInt(row['L_AMT']) * row.usdtoils),
          txn_id: row['L_TRANSACTIONID'],
          class_name: row.details['L_NUMBER0'] || row.details['SUBJECT'],
          type: typeToType(row.details, language),
        };

        // after using it:
        invoice_number++;

        const date = language === 'he' ? ' תאריך: ' + fdate(x.date) : 'Date: ' + fdate(x.date);
        const info =
          language === 'he'
            ? 'בלפור 23, תל אביב-יפו, ישראל, מיקוד: 6521134 Tel Aviv, Israel  23 Balfur St.<br>טלפון: +972-54-232-7696<br>עוסק מורשה: 558228854<br>אימייל: ransegall@gmail.com<br>'
            : 'Tel Aviv, Israel  23 Balfur St. Postal Code: 6521134 <br>Phone number: +972-54-232-7696<br>email: ran@thenuschool.com<br>';

        const tobuyer =
          language === 'he'
            ? 'לכבוד: ' + x.name + ' ' + x.last_name
            : 'To: ' + x.name + ' ' + x.last_name;

        const address = language === 'he' ? 'כתובת: ' + x.email : 'email: ' + x.email;
        const country = language === 'he' ? 'מדינה: ' + x.country : 'Country: ' + x.country;
        const invoice =
          language === 'he'
            ? 'חשבונית מס / קבלה: ' + x.invoice_number + ' | מקור '
            : 'Invoice / Receipt: ' + x.invoice_number; //eslint-disable

        const tableColumn =
          language === 'he'
            ? [
                {
                  title: 'תיאור',
                  dataIndex: 'price1',
                  key: 'type',
                },
                {
                  title: 'מחיר דולר',
                  dataIndex: 'price2',
                  key: 'price2',
                },
                {
                  title: 'מחיר ש"ח',
                  dataIndex: 'description',
                  key: 'description',
                },
              ]
            : [
                {
                  title: 'Description',
                  dataIndex: 'description',
                  key: 'description',
                },
                {
                  title: 'Price in $',
                  dataIndex: 'price',
                  key: 'price',
                },
              ];

        const tableData =
          language === 'he'
            ? [
                {
                  price1: x.price_ILS,
                  price2: x.price_USD,
                  description: x.type,
                },
                {
                  price1: x.price_ILS,
                  price2: 'סה"כ',
                  description: '',
                },
                {
                  price1: 0,
                  price2: 'מע״מ % :',
                  description: '',
                },
                {
                  price1: x.price_ILS,
                  price2: 'סה"כ לתשלום',
                  description: '',
                },
              ]
            : [
                {
                  price: totalpayed,
                  description: x.type,
                },
                {
                  price: totalpayed,
                  description: 'Sub Total: ',
                },
                {
                  price: 0,
                  description: 'VAT %: ',
                },

                {
                  price: totalpayed,
                  description: 'Total: ',
                },
              ];

        // const price = language === 'he' ?
        //   '<td>' + x.type + '</td><td>' + x.price_USD + '</td><td>' + x.price_ILS + '</td>' :
        //   '<td>' + x.type + '</td><td>' + totalpayed + '</td>';

        // const total = language === 'he' ? '<td></td><td>סה"כ</td><td>' + x.price_ILS + '</td>' : '<td>Sub Total: </td><td>' + totalpayed + '</td>'
        // const vat = language === 'he' ? '<td></td><td>מע״מ % :</td><td>0</td>' : '<td>VAT %: </td><td>0</td>';
        // const sum = language === 'he' ? '<td></td><td>סה"כ לתשלום</td><td>' + x.price_ILS + '</td>' : '<td>Total: </td><td>' + totalpayed + '</td>';
        // const empty = language === 'he' ? '<td></td><td></td><td></td>' : '<td></td><td></td>';
        const paypal =
          language === 'he'
            ? 'שולם באמצעות PayPal, העברה מספר: ' + x.txn_id
            : 'Paid with PayPal, Transaction num: ' + x.txn_id;
        const sig =
          language === 'he'
            ? '<div>חתימה: <img src="data:image/gif;base64,' +
              sigData +
              '" /></div><div><div>מסמך ממוחשב, המכיל חתימה דיגיטלית מאובטחת</div>'
            : '<div>Signature: <img src="data:image/gif;base64,' + sigData + '" /></div>';
        const name = language === 'he' ? 'הנוסקול Poptin' : 'Poptin';

        invoiceInfo.push({
          name,
          date,
          info,
          tobuyer,
          address,
          country,
          invoice,
          tableInfo: {
            tableColumn,
            tableData,
          },
          paypal,
          sig,
        });
        // return (
        //   <div className="invoice-page" onClick={() => console.log(row)}>
        //     <div className="nudetails">
        //       <div>
        //         <h3 >{name}</h3>
        //       </div>
        //       <div dangerouslySetInnerHTML={{ __html: date }} />
        //       <div dangerouslySetInnerHTML={{ __html: info }} />
        //     </div>
        //     <div className="hr" />
        //     <div dangerouslySetInnerHTML={{ __html: tobuyer }} />
        //     <div dangerouslySetInnerHTML={{ __html: address }} />
        //     <div dangerouslySetInnerHTML={{ __html: country }} />
        //     <h3 dangerouslySetInnerHTML={{ __html: invoice }} />
        //     <table className="table">
        //       <tr dangerouslySetInnerHTML={{ __html: headers }} />
        //       <tr dangerouslySetInnerHTML={{ __html: price }} />

        //       <tr dangerouslySetInnerHTML={{ __html: empty }} />
        //       <tr dangerouslySetInnerHTML={{ __html: total }} />
        //       <tr dangerouslySetInnerHTML={{ __html: VAT }} />
        //       <tr dangerouslySetInnerHTML={{ __html: sum }} />
        //     </table>
        //     <div dangerouslySetInnerHTML={{ __html: paypal }} />
        //     <div className="sighr">
        //       <div dangerouslySetInnerHTML={{ __html: sig }} />
        //       <div className="hr" />
        //     </div>
        //   </div>
        // );
      });

      setInvoiceData(invoiceInfo);
    }
  }, [payPal, typeToType, invoiceData]);

  return (
    <div className="admin-table-wrapper">
      <div className="table-action">
        <h3>All Invoices</h3>
        <div>
          <div>
            <div />
            <Space>
              <Button type="primary" onClick={() => setLoadInvoice(false)}>
                Back
              </Button>
            </Space>
          </div>
        </div>
      </div>
      {invoiceData?.map((invoice, index) => (
        <Col
          key={index}
          style={{
            border: '1px solid lightgray',
            padding: '3em',
            margin: '1em 0',
            background: 'white',
          }}>
          <div className="nudetails">
            <div>
              <h3>{invoice?.name}</h3>
            </div>
            <div dangerouslySetInnerHTML={{ __html: invoice?.date }} />
            <div dangerouslySetInnerHTML={{ __html: invoice?.info }} />
          </div>
          <div className="hr" />
          <div dangerouslySetInnerHTML={{ __html: invoice?.tobuyer }} />
          <div dangerouslySetInnerHTML={{ __html: invoice?.address }} />
          <div dangerouslySetInnerHTML={{ __html: invoice?.country }} />
          <h3 dangerouslySetInnerHTML={{ __html: invoice?.invoice }} />
          <Table
            pagination={false}
            dataSource={invoice?.tableInfo?.tableData}
            columns={invoice?.tableInfo?.tableColumn}
          />
          <div dangerouslySetInnerHTML={{ __html: invoice?.tableInfopaypal }} />
          <div className="sighr">
            <div dangerouslySetInnerHTML={{ __html: invoice?.sig }} />
            <div className="hr" />
          </div>
        </Col>
      ))}
    </div>
  );
};

PayPalInvoice.defaultProps = {
  payPal: [],
};

PayPalInvoice.propTypes = {
  payPal: PropTypes.arrayOf(PropTypes.shape({})),
  setLoadInvoice: PropTypes.func.isRequired,
};

export default PayPalInvoice;
