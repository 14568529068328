/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.05464 0.87268C6.24021 0.509054 6.75979 0.509054 6.94536 0.87268L8.33289 3.59155C8.40554 3.73391 8.54187 3.83296 8.69971 3.85806L11.7143 4.3375C12.1174 4.40162 12.278 4.89577 11.9895 5.18462L9.8325 7.34442C9.71955 7.4575 9.66748 7.61777 9.69238 7.77564L10.168 10.7908C10.2316 11.1941 9.81122 11.4995 9.44735 11.3144L6.72671 9.93033C6.58426 9.85786 6.41574 9.85786 6.27329 9.93033L3.55265 11.3144C3.18878 11.4995 2.76844 11.1941 2.83204 10.7908L3.30762 7.77564C3.33252 7.61777 3.28045 7.4575 3.1675 7.34442L1.01048 5.18462C0.721994 4.89577 0.882551 4.40162 1.28572 4.3375L4.30029 3.85806C4.45813 3.83296 4.59446 3.73391 4.66711 3.59155L6.05464 0.87268Z"
      fill="#E2E3E8"
    />
  </svg>
);

const StarIcon = (props) => <Icon component={svg} {...props} />;

export default StarIcon;
