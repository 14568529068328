import React from 'react';
import { Button as AntButton } from 'antd';
import PropTypes from 'prop-types';

import AddIcon from '../Icons/AddIcon';

import './IconButton.scss';

const Button = ({
  text,
  onButtonClick,
  icon,
  className = '',
  disabled = false,
  override = false,
  left = false,
}) => (
  <AntButton
    className={`prosper-icon-button ${className}`}
    onClick={onButtonClick}
    disabled={disabled}>
    {left ? icon || <AddIcon className="prosper-button-add-icon" /> : null}
    <span className={override ? '' : 'prosper-button-text'}> {text}</span>{' '}
    {left ? null : icon || <AddIcon className="prosper-button-add-icon" />}
  </AntButton>
);

Button.defaultProps = {
  onButtonClick: () => {},
  disabled: false,
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Button;
