/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.72134 6.68306C10.5826 5.99597 11.1346 4.93742 11.1346 3.74998C11.1346 1.67892 9.45565 0 7.38459 0C5.31354 0 3.63461 1.67892 3.63461 3.74998C3.63461 4.93742 4.18653 5.99595 5.04782 6.68305C2.48724 7.66167 0.534077 10.205 0.018715 13.3442C-0.130031 14.2502 0.628942 14.9999 1.54712 14.9999H13.222C14.1402 14.9999 14.8992 14.2502 14.7504 13.3442C14.2351 10.205 12.2819 7.66169 9.72134 6.68306Z"
      fill="white"
      fillOpacity="0.6"
    />
    <circle cx="12.3846" cy="7.49998" r="3.74998" fill="white" />
    <path
      d="M12.3846 9.99992C8.72771 9.99992 5.68211 13.0533 5.01874 17.0941C4.87 18.0001 5.62897 18.7499 6.54714 18.7499H18.2221C19.1402 18.7499 19.8992 18.0001 19.7505 17.0941C19.0871 13.0533 16.0415 9.99992 12.3846 9.99992Z"
      fill="white"
    />
  </svg>
);

const AddUsers = (props) => <Icon component={svg} {...props} />;

export default AddUsers;
