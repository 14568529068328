import _ from 'lodash';
import getRaw from './getRaw';

const getMilestones2 = ({ prop, configText }) => {
  // milestones not avaiable
  // chosen not avaiable
  // keys in chosen = 0
  if (!prop.milestones || !prop.milestones.chosen || _.keys(prop.milestones.chosen).length === 0) {
    return {};
  }

  // length of list = 0
  const title = configText('section milestones title');
  const { chosen } = prop.milestones;
  const [fkey] = _.keys(chosen);
  if (!chosen[fkey]?.list || !chosen[fkey]?.list?.length) {
    return {};
  }

  return {
    title: title,
    rawtitle: getRaw(title),
    subrawtitle: getRaw(''),
    raw: {
      entityMap: {
        0: {
          type: 'TOKEN',
          mutability: 'IMMUTABLE',
          data: { texcontent: JSON.stringify({ milestones: prop.milestones }) },
        },
      },
      blocks: [
        {
          key: 'as2e8',
          text: configText(`milestones preface`, true),
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: 'as2e9',
          text: '',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: '7de23',
          text: ' ',
          type: 'atomic',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [{ offset: 0, length: 1, key: 0 }],
          data: {},
        },
        {
          key: 'as2f6',
          text: '',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ],
    },
  };
};

export default getMilestones2;
