import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
    <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.24061 13.1837C5.84375 13.6211 5.15625 13.6211 4.75939 13.1837L2.25374 10.4219C1.67073 9.77933 2.12669 8.75 2.99436 8.75L8.00564 8.75C8.87331 8.75 9.32927 9.77933 8.74625 10.4219L6.24061 13.1837Z" fill="#F03F3B"/>
        <path d="M4.75939 0.816313C5.15625 0.378889 5.84375 0.37889 6.24061 0.816314L8.74626 3.57807C9.32927 4.22067 8.87331 5.25 8.00564 5.25L2.99436 5.25C2.12669 5.25 1.67073 4.22067 2.25375 3.57807L4.75939 0.816313Z" fill="#F03F3B"/>
    </svg>


);

const SelectionIcon = (props) => <Icon component={svg} {...props} />;

export default SelectionIcon;
