import { gql } from '@apollo/client';

export const CREATE_STRIPE_SESSION = gql`
  mutation CreateStripeSessionMutation(
    $price: String!
    $membersToBeAdded: [TeamsDefaultInput!]
    $membersToBeDeleted: [TeamsDefaultInput!]
    $planInfo: PlanInfoInput
  ) {
    createStripeSession(
      price: $price
      membersToBeAdded: $membersToBeAdded
      membersToBeDeleted: $membersToBeDeleted
      planInfo: $planInfo
    )
  }
`;

export const CREATE_STRIPE_CARD_SESSION = gql`
  mutation CreateStripeCardSessionMutation {
    createStripeCardSession
  }
`;

export const CREATE_STRIPE_SUBSCRIPTION = gql`
  mutation CreateStripeSubscriptionMutation($token: String!) {
    createStripeSubscription(token: $token)
  }
`;

export const UPDATE_STRIPE_CARD = gql`
  mutation UpdateStripeCardMutation($session: String!) {
    updateStripeCard(session: $session)
  }
`;

export const CANCEL_STRIPE_SUBSCRIPTION = gql`
  mutation CancelStripeSubscriptionMutation($reason: String!) {
    cancelStripeSubscription(reason: $reason)
  }
`;

export const CREATE_PAYPAL_SUBSCRIPTION = gql`
  mutation CreatePaypalSubscriptionMutation($data: PayPalSubscriptionInput!) {
    createPaypalSubscription(data: $data)
  }
`;

export const UPDATE_OLD_PAYPAL_SUBSCRIPTION = gql`
  mutation updateOldPayPalSubscriptionMutation($token: String!) {
    updateOldPayPalSubscription(token: $token)
  }
`;

export const CANCEL_PAYPAL_SUBSCRIPTION = gql`
  mutation CancelPaypalSubscriptionMutation($reason: String!) {
    cancelPaypalSubscription(reason: $reason)
  }
`;

export const CANCEL_OLD_PAYPAL_SUBSCRIPTION = gql`
  mutation CancelOldPaypalSubscriptionMutation($reason: String!) {
    cancelOldPayPalSubscription(reason: $reason)
  }
`;

export const UPDATE_USER_INVOICING_DETAILS = gql`
  mutation UpdateUserInvoicingDetailsMutation($details: InvoiceDetailsInput!) {
    updateUserInvoicingDetails(details: $details) {
      profile {
        companyname
        address
        vatnumber
        infoUpdate
      }
    }
  }
`;
