import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import DashboardFilter from '../DashboardFilter';
import DashboardListHeader from '../DashboardListHeader';
import PageHeader from 'components/PageHeader';
import ReviewWidget from 'components/ReviewWidget/ReviewWidget';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { FilterIcon, CloseIcon } from 'components/Icons';
import IconButton from 'components/IconButton';
import './DashboardHeader.scss';

const DashboardHeader = ({
  userPlanType,
  teamList,
  approvedCount,
  acceptanceRate,
  onChangeSearch,
  filterItems,
  reportItems,
  onChangeTeamsFilter,
  onDownloadReportFilter,
  onChangeFilter,
  onChangeTagsFilter,
  trash,
  onShowTrash,
  onChangeDate,
  selectedDate,
  onClicknNewProposal,
  isFetchingTeamMembers,
  isTeamMember,
  reviewWidget,
  wixReview,
  showButton,
  tagsData,
  tagsType,
  setTagsType,
  showFilters,
  setShowFilters,
  isLocked,
}) => {
  const { width } = useWindowDimensions();
  const isMobile = width <= 992;

  return (
    <div className="dashboard-header shrink">
      <ReviewWidget reviewWidget={reviewWidget} wixReview={wixReview} />
      <PageHeader
        pageTitle={trash ? 'Trash' : 'Your Proposals'}
        showButton={
          trash ? null : (
            <>
              {showButton && isMobile && (
                <>
                  <Button className="filter-btn" onClick={() => setShowFilters(!showFilters)}>
                    {showFilters ? (
                      <CloseIcon className="close-icon" />
                    ) : (
                      <FilterIcon className="filter-icon" />
                    )}
                  </Button>
                </>
              )}
              <IconButton
                text={isMobile ? 'CREATE' : 'NEW PROPOSAL'}
                onButtonClick={onClicknNewProposal}
                disabled={isLocked}
              />
            </>
          )
        }
      />
      <DashboardFilter
        userPlanType={userPlanType}
        isTeamMember={isTeamMember}
        teamList={teamList}
        isFetchingTeamMembers={isFetchingTeamMembers}
        approvedCount={approvedCount}
        trash={trash}
        acceptanceRate={acceptanceRate}
        onChangeSearch={onChangeSearch}
        filterItems={filterItems}
        reportItems={reportItems}
        onChangeFilter={onChangeFilter}
        onChangeTagsFilter={onChangeTagsFilter}
        onShowTrash={onShowTrash}
        onChangeTeamsFilter={onChangeTeamsFilter}
        onDownloadReportFilter={onDownloadReportFilter}
        onChangeDate={onChangeDate}
        selectedDate={selectedDate}
        tagsData={tagsData}
        tagsType={tagsType}
        setTagsType={setTagsType}
      />
      <DashboardListHeader />
    </div>
  );
};

DashboardHeader.defaultProps = {
  selectedDate: null,
  teamList: [],
  onChangeTeamsFilter: () => { },
  isTeamMember: false,
  reviewWidget: 0,
};

DashboardHeader.propTypes = {
  userPlanType: PropTypes.string,
  approvedCount: PropTypes.number.isRequired,
  acceptanceRate: PropTypes.number.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  onChangeTeamsFilter: PropTypes.func,
  onDownloadReportFilter: PropTypes.func.isRequired,
  selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  filterItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  reportItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClicknNewProposal: PropTypes.func.isRequired,
  teamList: PropTypes.arrayOf(PropTypes.shape({})),
  isTeamMember: PropTypes.bool,
  reviewWidget: PropTypes.number,
};

export default DashboardHeader;
