import React, { useState, useRef } from 'react';
import { Tabs } from 'antd';
import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import PropTypes from 'prop-types';

// Drag & Drop node
const TabNode = ({ connectDragSource, connectDropTarget, children }) =>
  connectDragSource(connectDropTarget(children));

TabNode.propTypes = {
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};

const cardTarget = {
  drop(props, monitor) {
    const dragKey = monitor.getItem().index;
    const hoverKey = props.index;

    if (dragKey === hoverKey) {
      return;
    }

    props.moveTabNode(dragKey, hoverKey);
    monitor.getItem().index = hoverKey;
  },
};

const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
    };
  },
};

const WrapTabNode = DropTarget('DND_NODE', cardTarget, (connect) => ({
  connectDropTarget: connect.dropTarget(),
}))(
  DragSource('DND_NODE', cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }))(TabNode)
);

const DraggableTabs = (props) => {
  const [order, setOrder] = useState([]);
  const dndRef = useRef();

  const moveTabNode = (dragKey, hoverKey) => {
    const newOrder = order.slice();
    const { children, onSort } = props;

    React.Children.forEach(children, (c) => {
      if (newOrder.indexOf(c.key) === -1) {
        newOrder.push(c.key);
      }
    });

    const dragIndex = newOrder.indexOf(dragKey);
    const hoverIndex = newOrder.indexOf(hoverKey);

    newOrder.splice(dragIndex, 1);
    newOrder.splice(hoverIndex, 0, dragKey);

    setOrder(newOrder);
    onSort(newOrder);
  };

  const renderTabBar = (tabProps, DefaultTabBar) => (
    <DefaultTabBar {...tabProps}>
      {(node) => (
        <WrapTabNode key={node.key} index={node.key} moveTabNode={moveTabNode}>
          {node}
        </WrapTabNode>
      )}
    </DefaultTabBar>
  );

  const { children } = props;
  const tabs = [];

  React.Children.forEach(children, (c) => {
    tabs.push(c);
  });

  const orderTabs = tabs.slice().sort((a, b) => {
    const orderA = order.indexOf(a.key);
    const orderB = order.indexOf(b.key);

    if (orderA !== -1 && orderB !== -1) {
      return orderA - orderB;
    }
    if (orderA !== -1) {
      return -1;
    }
    if (orderB !== -1) {
      return 1;
    }

    const ia = tabs.indexOf(a);
    const ib = tabs.indexOf(b);

    return ia - ib;
  });

  return (
    <DndProvider
      backend={HTML5Backend}
      context={dndRef.current ? dndRef.current.ownerDocument.defaultView : window}>
      <Tabs
        renderTabBar={renderTabBar}
        activeKey={props.activeKey}
        type={props.type}
        onEdit={props.onEdit}
        onChange={props.onChange}>
        {orderTabs}
      </Tabs>
    </DndProvider>
  );
};

DraggableTabs.propTypes = {
  onSort: PropTypes.func.isRequired,
  children: PropTypes.instanceOf(Array).isRequired,
};

export default DraggableTabs;
