import Immutable from 'immutable';
import PropTypes from 'prop-types';

const ListWrapper = ({ type, children }) => {
  if (type === 'ol') return <ol className="public-DraftStyleDefault-ol">{children}</ol>;
  return <ul className="public-DraftStyleDefault-ul">{children}</ul>;
};

ListWrapper.defaultProps = {
  children: null,
};

ListWrapper.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.element,
};

const getBlockRenderMap = () =>
  Immutable.Map({
    nobreak: {
      element: 'nobreak',
    },
    right: {
      element: 'right',
    },
    xxx: {
      element: 'xxx',
    },
    yyy: {
      element: 'yyy',
    },
    totes: {
      element: 'totes',
    },
    'editor-align-left': {
      element: 'div',
    },
    'editor-align-center': {
      element: 'div',
    },
    'editor-align-right': {
      element: 'div',
    },
    'editor-align-justify': {
      element: 'div',
    },
    'editor-direction-ltr': {
      element: 'div',
    },
    'editor-direction-rtl': {
      element: 'div',
    },
    'editor-align-center editor-direction-ltr': {
      element: 'div',
    },
    'editor-align-center editor-direction-rtl': {
      element: 'div',
    },
    'editor-align-right editor-direction-ltr': {
      element: 'div',
    },
    'editor-align-right editor-direction-rtl': {
      element: 'div',
    },
    'editor-align-left editor-direction-ltr': {
      element: 'div',
    },
    'editor-align-left editor-direction-rtl': {
      element: 'div',
    },
    'editor-align-justify editor-direction-ltr': {
      element: 'div',
    },
    'editor-align-justify editor-direction-rtl': {
      element: 'div',
    },
  });

export default getBlockRenderMap;
