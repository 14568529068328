/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clip-path="url(#clip0_14112_26480)">
      <path
        d="M16.6667 7.5H9.16667C8.24619 7.5 7.5 8.24619 7.5 9.16667V16.6667C7.5 17.5871 8.24619 18.3333 9.16667 18.3333H16.6667C17.5871 18.3333 18.3333 17.5871 18.3333 16.6667V9.16667C18.3333 8.24619 17.5871 7.5 16.6667 7.5Z"
        stroke="white"
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.16406 12.5H3.33073C2.8887 12.5 2.46478 12.3244 2.15222 12.0119C1.83966 11.6993 1.66406 11.2754 1.66406 10.8334V3.33335C1.66406 2.89133 1.83966 2.4674 2.15222 2.15484C2.46478 1.84228 2.8887 1.66669 3.33073 1.66669H10.8307C11.2728 1.66669 11.6967 1.84228 12.0092 2.15484C12.3218 2.4674 12.4974 2.89133 12.4974 3.33335V4.16669"
        stroke="white"
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_14112_26480">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const PublishedCopyIcon = (props) => <Icon component={svg} {...props} />;

export default PublishedCopyIcon;
