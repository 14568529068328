import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.2509 22.8075C26.3347 21.8844 24.1158 20.5372 23.0393 19.9943C21.6373 19.2882 21.5219 19.2305 20.42 20.0491C19.6849 20.5955 19.1963 21.0836 18.3361 20.9001C17.4759 20.7167 15.6066 19.6822 13.9698 18.0506C12.333 16.419 11.2386 14.4955 11.0545 13.6382C10.8705 12.7809 11.3667 12.298 11.9078 11.5612C12.6705 10.5228 12.6128 10.3497 11.9609 8.94771C11.4526 7.8573 10.0662 5.65917 9.13969 4.74761C8.14851 3.76855 8.14851 3.94163 7.50984 4.20702C6.98989 4.42579 6.49106 4.69172 6.01961 5.00146C5.09651 5.61475 4.58419 6.12418 4.22591 6.88978C3.86763 7.65538 3.70667 9.45023 5.55691 12.8115C7.40714 16.1727 8.70525 17.8914 11.392 20.5707C14.0788 23.25 16.1449 24.6906 19.1651 26.3845C22.9014 28.477 24.3345 28.0691 25.1024 27.7114C25.8703 27.3537 26.382 26.846 26.9965 25.9229C27.307 25.4523 27.5735 24.954 27.7926 24.4344C28.0586 23.7981 28.2317 23.7981 27.2509 22.8075Z"
      stroke="#005AFF"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
);

const CallIcon = (props) => <Icon component={svg} {...props} />;

export default CallIcon;
