/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { Row, Col, Empty, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';

import EditableSelect from 'components/EditableSelect';
import Input from 'components/Input';
import helpers from 'helpers/proposal';
import DeleteIcon from 'components/Icons/DeleteIcon';
import VariablesEditIcon from 'components/Icons/VariablesEditIcon';
import Price from './Price';
import Super from './Super';
import Miles from './Miles';
import utils from 'utils/utils';
import { sanitizeString } from 'utils/xss';

const Sortlist = ({
  prop,
  price,
  onlistChange,
  curr,
  configText,
  transform,
  selectable,
  valuePayemntType,
  tabType,
  quantity,
  listItem,
  t,
  state: { stab },
  chosen,
  changeTabName,
  saveTabName,
  getpkgname,
  delOption,
  handleValueOptionsDropdown,
  dispatch,
  calculateDiscount,
  calculateTax,
  calcDiscPrice,
  showPane,
}) => {
  const [list, setList] = useState(listItem);
  const [showenDescription, setShowenDescription] = useState([]);
  const [showenDiscount, setShowenDiscount] = useState([]);
  const [presentPrice, setPresentPrice] = useState('');

  const [currentPrice, setCurrentPrice] = useState('');
  // const [currentDiscount, setCurrentDiscount] = useState('');
  // const [currentSymbol, setCurrentSymbol] = useState('');

  const prevProps = useRef(listItem);

  useEffect(() => {
    const prevLateVal = prevProps.current;
    const nextLateVal = listItem;

    if (typeof nextLateVal !== 'undefined' && prevLateVal !== nextLateVal) {
      setList(listItem);
    }
  }, [listItem]);

  // const calcDiscPrice = (price, discount, symbol) => {
  //   if (discount && price) {
  //     if (currentPrice === '') {
  //       setCurrentPrice(price);
  //     }
  //     if (symbol === '%') {
  //       if (discount >= 100) {
  //         if (discount > 100) return price;
  //       }
  //       price = price - price * (discount / 100);
  //     } else {
  //       price = price - discount;
  //     }
  //   }
  //   return price;
  // };

  const editTime = (x) => {
    return (e) => {
      _.each(list, (y) => {
        if (x.id === y.id) {
          x.time = sanitizeString(e.target.value);
        }
      });
      onlistChange(list);
    };
  };

  const editSuper = (x) => {
    return (e) => {
      _.each(list, (y, i) => {
        if (x.id === y.id) {
          x.super = { text: sanitizeString(e.target.value), order: i };
        }
      });

      onlistChange(list);
      e.target.style.height = '5px';
      e.target.style.height = `${e.target.scrollHeight}px`;
    };
  };

  const editPrice = (x) => {
    const propCurr = prop.curr || curr || configText('currency');

    return (v) => {
      v = prop.priceSeperator === '.' ? v.replace(/[^0-9,]/g, '') : v.replace(/[^0-9.]/g, '');
      const tempList = [...list];

      if (utils.checkNan(v, prop.priceSeperator === '.')) {
        return;
      }

      let totes = 0;

      _.each(tempList, (y, index) => {
        if (x.id === y.id) {
          if (x.name === y.name) {
            setCurrentPrice(v);
          }

          const discVal = x?.discount?.value || 0;
          const discUnit = x?.discount?.unit || '%';

          if (x?.discount) {
            // for unit other than %
            if (y?.discount?.value && y?.discount?.unit !== '%') {
              // check if discount amount > price, set discount value = price
              if (discVal > v) {
                x.discount.value = 0;
              }
            }
          }
          const price = prop.priceSeperator === '.' ? v.replaceAll(',', '.') : v;
          const subTotal = calcDiscPrice(
            price * (chosen[stab]?.quantity ? x.items || 1 : 1),
            x?.discount?.value,
            discUnit,
            prop.priceSeperator === '.'
          );
          let z = subTotal.toString();
          if (x.name === y.name) {
            const _currentPrice = v;
            x.currentPrice = Number.isNaN(_currentPrice) ? 0 : _currentPrice;
          }
          if (prop.priceSeperator === '.') {
            z = z.replace('.', ',');
          }
          // x.price = helpers.onlyNumSeperator({ prop, x: z, curr: propCurr }) || '';
          totes = totes + Number(z.replaceAll(',', '.'));
          chosen[stab].list[index].currentPrice = v;
          chosen[stab].list[index].price = z;
        } else {
          const discVal = y?.discount?.value || 0;
          const discUnit = y?.discount?.unit || '%';

          const price =
            prop.priceSeperator === '.'
              ? String(y?.currentPrice)?.replaceAll(',', '.') ||
                String(y?.price)?.replaceAll(',', '.')
              : y?.currentPrice || y?.price;

          const subTotal = calcDiscPrice(
            parseFloat(price * (chosen[stab]?.quantity ? y.items || 1 : 1)),
            discVal,
            discUnit,
            prop.priceSeperator === '.'
          );
          let z = subTotal.toString();
          if (prop.priceSeperator === '.') {
            z = z.replace('.', ',');
          }
          // const price = helpers.onlyNumSeperator({ prop, x: z, curr: propCurr }) || '';
          totes += Number(z.replaceAll(',', '.'));
        }
      });

      if (!chosen[stab].selectable) {
        [totes] = calculateDiscount({
          chosen,
          curr,
          totes: totes,
          totespredis: {},
          subtvalue: 0,
          discount: chosen[stab].discount,
        });

        [totes] = calculateTax({
          chosen,
          curr,
          totes: totes,
          totespredis: {},
          subtvalue: 0,
          taxes: chosen[stab].tax,
        });

        // reset the final discount to 0
        chosen[stab].total = 0;
        if (chosen[stab]?.discount?.unit !== '%' && totes < chosen[stab]?.discount?.val) {
          chosen[stab].discount.val = 0;
        }
      }

      dispatch({ type: 'setChosen', chosen });

      onlistChange(list);
    };
  };

  const editItemDiscount = (e, x, type, value) => {
    /*
      e = event
      x = row object
      type = value | unit
      value = $ | %
    */
    // remove negative sign, convert to number
    // const propCurr = prop.curr || curr || configText('currency');
    let v =
      prop.priceSeperator === '.'
        ? e?.target?.value.replace(/[^0-9,]/g, '')
        : e?.target?.value.replace(/[^0-9.]/g, '');
    if (utils.checkNan(v, prop.priceSeperator === '.')) {
      return;
    }

    let val = helpers.onlyNumSeperator({ prop, x: v, curr });
    let fval = helpers.parseFloatCurr({ prop, x: val, curr });

    if (fval < 0) {
      fval *= -1; // convert to positive number
    }
    const tempList = list;

    let totes = 0;

    tempList.forEach((l, index) => {
      if (l.id === x.id) {
        // set discount object if not set
        if (!l.discount) {
          l.discount = { value: 0, unit: '%', type: -1 };
        }

        // set discount to 0 on toggling unit
        if (type === 'unit') {
          // set the new unit
          l.discount.unit = value;
          l.discount.value = 0;

          // x.price = calcDiscPrice(x.currentPrice || x.price, l.discount.value, l.discount.unit, prop.priceSeperator === '.');

          chosen[stab].list[index].discount = {
            unit: value,
            value: 0,
          };
        } else {
          // set price to 0, if not set
          if (!x.currentPrice || Number.isNaN(x?.currentPrice)) {
            x.currentPrice = x.price || 0;
            setCurrentPrice(x.currentPrice);
          }

          // discount should be <= 100
          if (x.discount.unit === '%' && v > 100) {
            v = 100;
          }
          // for $ type, v should <= currentPrice
          if (x.discount.unit !== '%' && fval > x.currentPrice) {
            v = 0;
          }

          // set new value
          l.discount.value = v;
          // calculate new discounted price
          const price =
            prop.priceSeperator === '.'
              ? x?.currentPrice
                ? String(x?.currentPrice)?.replaceAll(',', '.')
                : String(x?.price)?.replaceAll(',', '.')
              : x?.currentPrice || x?.price;
          const subTotal = calcDiscPrice(
            price * (chosen[stab]?.quantity ? x.items || 1 : 1),
            l.discount.value,
            l.discount.unit,
            prop.priceSeperator === '.'
          );

          let z = subTotal.toString();
          if (prop.priceSeperator === '.') {
            z = z.replace('.', ',');
          }
          chosen[stab].list[index].discount.value = v;
          chosen[stab].list[index].price = z;
          totes = totes + Number(z.replaceAll(',', '.'));
          // x.price = helpers.onlyNumSeperator({ prop, x: z, curr: propCurr }) || '';
          // totes += (x.price || 0) * (l.items || 1);
        }
      } else {
        const discVal = l?.discount?.value || 0;
        const discUnit = l?.discount?.unit || '%';

        const price =
          prop.priceSeperator === '.'
            ? String(l?.currentPrice)?.replaceAll(',', '.') ||
              String(l?.price)?.replaceAll(',', '.')
            : l?.currentPrice || l?.price;
        const subTotal = calcDiscPrice(
          price * (chosen[stab]?.quantity ? x.items || 1 : 1),
          discVal,
          discUnit,
          prop.priceSeperator === '.'
        );
        let z = subTotal.toString();
        // if (prop.priceSeperator === '.') {
        //   z = z.replace('.', ',');
        // }
        // const price = helpers.onlyNumSeperator({ prop, x: z, curr: propCurr }) || '';
        totes += (Number(z.replaceAll(',', '.')) || 0) * (l.items || 1);
      }
    });

    [totes] = calculateDiscount({
      chosen,
      curr,
      totes: totes,
      totespredis: {},
      subtvalue: 0,
      discount: chosen[stab].discount,
    });

    [totes] = calculateTax({
      chosen,
      curr,
      totes: totes,
      totespredis: {},
      subtvalue: 0,
      taxes: chosen[stab].tax,
    });

    // reset the final discount to 0
    chosen[stab].total = 0;
    if (chosen[stab]?.discount?.unit !== '%' && totes < chosen[stab]?.discount?.val) {
      chosen[stab].discount.val = 0;
    }

    dispatch({ type: 'setChosen', chosen });

    setList(tempList);
    onlistChange(tempList);
  };

  const editName = (x) => {
    return (e) => {
      const tempList = list;
      _.each(tempList, (y) => {
        if (x.id === y.id) {
          x.name = sanitizeString(e.target.value);
        }
      });
      setList(tempList);

      onlistChange(tempList);
    };
  };

  const handleTotalChange = (e, x) => {
    const tempList = list;
    tempList.forEach((l) => {
      if (l.id === x.id) l.checked = e.target.checked;
    });

    setList(tempList);
    onlistChange(tempList);
  };

  const removeItem = (x) => {
    return () => {
      const tempList = _.union([], _.without(list, x));
      setList(tempList);
      onlistChange(tempList);
    };
  };

  const showHideDescription = (x, type) => {
    let tempShowenDescription = Array.from(showenDescription);
    const tempList = Array.from(list);
    if (type === 'push') tempShowenDescription.push(x.id && !x.super ? x.id : x.name);
    else {
      if (x.id) {
        tempShowenDescription = tempShowenDescription.filter((description) => description !== x.id);
      } else if (x.name) {
        tempShowenDescription = tempShowenDescription.filter(
          (description) => description !== x.name
        );
      }

      _.each(tempList, (y) => {
        if (x.id === y.id) {
          x.description = '';
        } else if (x.name === y.name) {
          x.description = '';
          y.description = '';
        }
      });

      onlistChange(tempList);
    }
    setShowenDescription(tempShowenDescription);
  };

  const showHideDiscount = (x, type) => {
    let tempShowenDiscount = Array.from(showenDiscount);
    const tempList = Array.from(list);

    if (type === 'push') tempShowenDiscount.push(x.id && !x.super ? x.id : x.name);
    else {
      if (x.id) tempShowenDiscount = tempShowenDiscount.filter((discount) => discount !== x.id);
      else if (x.name)
        tempShowenDiscount = tempShowenDiscount.filter((discount) => discount !== x.name);

      _.each(tempList, (y) => {
        if (x.id === y.id) {
          x.discount = '';
          x.price = x.currentPrice !== '' ? x.currentPrice : x.price;
        } else if (x.name === y.name) {
          x.discount = '';
          y.discount = '';
          x.price = x.currentPrice !== '' ? x.currentPrice : x.price;
        }
      });

      onlistChange(tempList);
    }

    setShowenDiscount(tempShowenDiscount);
  };

  const editQuantity = (value, x, type = 'quantity') => {
    // type = items | quantity | years
    const tempList = [...list];

    tempList.forEach((l) => {
      if (l.id === x.id) l[type] = Number(value) || '';
    });

    setList(tempList);
    onlistChange(tempList);
  };

  const editDescription = (x) => {
    return (e) => {
      const tempList = list;

      _.each(tempList, (y) => {
        if (x.id === y.id || x.name === y.name) {
          x.description = sanitizeString(e.target.value);
        }
      });

      onlistChange(tempList);
    };
  };

  const handleOptionsDropdown = (value, id) => {
    const tempList = [...list];
    tempList.forEach((l) => {
      if (l.id === id) l.type = value;
    });

    setList(tempList);
    onlistChange(tempList);
  };

  const renderSuperList = () => {
    const superTransform = transform || ((x) => x);

    const onSort = (newState) => {
      //remove falsy from newState
      const removedFalsy = newState.map((obj) => {
        Object.keys(obj).forEach((key) => {
          if (obj[key] === false) {
            delete obj[key];
          }
        });
        return obj;
      });

      if (JSON.stringify(list) !== JSON.stringify(removedFalsy)) {
        setList(newState);
        onlistChange(newState);
      }
    };

    return list.length ? (
      <ReactSortable list={list} setList={onSort} animation={400} handle=".handle">
        {_.map(list, (x, superIndex) => (
          <Super
            key={x.name + superIndex}
            x={x}
            editSuper={editSuper}
            removeItem={removeItem}
            showenDescription={showenDescription}
            superTransform={superTransform}
            editDescription={editDescription}
            showHideDescription={showHideDescription}
          />
        ))}
      </ReactSortable>
    ) : (
      <Empty description={'Select your strengths from the left menu, or add your own'} />
    );
  };

  const getValue = (value, defaultValue) => {
    return typeof value === 'string' ? value : defaultValue;
  };

  const renderPriceTableList = () => {
    const propCurr = prop.curr || prop.currency || curr || configText('currency');
    const recurringTypes = ['Hourly', 'Monthly', 'Annually'];
    const recurring = price && quantity && list.find((x) => recurringTypes.includes(x.type));

    return (
      <div
        className={`
        ${stab === t ? 'tablist-active-tab' : 'tablist-inactive-tab'} 
        ${quantity && 'tablist-quantity'} 
        ${recurring && 'tablist-recurring'}`}>
        <Row className={`tablist-input-header ${!!chosen[stab]?.structured ? 'structured' : 'ss'}`}>
          <Col flex="auto" className="tablist-label deliverable">
            <Input
              type="textarea"
              autoSize={{ maxRows: 2 }}
              className="borderless-input flush"
              readOnly={true}
              value={getValue(chosen[stab]?.columnName?.deliverable, configText('Deliverable'))}
            />
          </Col>
          <Col className="price-inputs">
            {recurring && (
              <div className="tablist-quantity-label quantity">
                <Input className="borderless-input flush" readOnly value="Duration" />
              </div>
            )}
            {quantity && (
              <div className="tablist-label items">
                <Input
                  type="textarea"
                  autoSize={{ maxRows: 2 }}
                  className="borderless-input flush"
                  readOnly={true}
                  value={getValue(chosen[stab]?.columnName?.item, configText('quantity'))}
                />
              </div>
            )}
            <div className="tablist-label price">
              <Input
                type="textarea"
                autoSize={{ maxRows: 2 }}
                className="borderless-input flush"
                readOnly={true}
                value={getValue(
                  chosen[stab]?.columnName?.price,
                  !!chosen[stab]?.structured ? configText('line total text') : configText('Price')
                )}
              />
            </div>
            <div className="tablist-label price-options">
              <Input className="borderless-input flush" readOnly value="Payment Type" />
              {
                // show edit icon for structured
                !!chosen[stab]?.structured && (
                  <Tooltip title="Rename titles">
                    <VariablesEditIcon
                      className="edit-icon"
                      onClick={() => showPane('edit-title')}
                    />
                  </Tooltip>
                )
              }
            </div>
          </Col>
        </Row>
        {list.length ? (
          <ReactSortable
            list={list}
            setList={(newState) => {
              if (JSON.stringify(list) !== JSON.stringify(newState)) {
                setList(newState);
                onlistChange(newState);
              }
            }}
            animation={400}
            handle=".handle">
            {_.map(list, (x, listIndex) => (
              <Price
                displayType="table"
                discountValue={x?.discount?.value}
                currentPrice={presentPrice}
                key={x.id}
                listIndex={`${x.id}`}
                t={t}
                price={price}
                propCurr={propCurr}
                valuePayemntType={valuePayemntType}
                x={x}
                quantity={quantity}
                selectable={selectable}
                handleTotalChange={handleTotalChange}
                editName={editName}
                configText={configText}
                editQuantity={editQuantity}
                editPrice={editPrice}
                editDescription={editDescription}
                editItemDiscount={editItemDiscount}
                removeItem={removeItem}
                showenDescription={showenDescription}
                showHideDescription={showHideDescription}
                showenDiscount={showenDiscount}
                showHideDiscount={showHideDiscount}
                handleOptionsDropdown={handleOptionsDropdown}
                language={prop?.language}
              />
            ))}
          </ReactSortable>
        ) : (
          <Empty description={false} />
        )}
      </div>
    );
  };

  useEffect(() => {
    // for first time allow save
    if (!stab) changeTabName(getpkgname(stab, chosen[stab], true));
  }, [stab]);

  const renderPriceValueList = () => {
    const propCurr = prop.curr || prop.currency || curr || configText('currency');

    return (
      <div className="sortlist-container">
        <div className="sortlist-price-delete" onClick={delOption(t)}>
          <DeleteIcon />
        </div>
        <Row className="sortlist-price-fields">
          <Input
            value={getpkgname(stab, chosen[stab])}
            onChange={changeTabName}
            onBlur={saveTabName}
          />

          <EditableSelect
            items={['One-Time', 'Monthly', 'Annually', 'Hourly']}
            selectClassName="price-options-dropdown"
            inputClassName="price-options-input"
            placeholder="Payment Type"
            x={chosen[stab] || {}}
            handleOptionsDropdown={handleValueOptionsDropdown}
            configText={configText}
            dropdownClassName={
              prop?.language === 'hebrew' || prop?.language === 'arabic'
                ? 'wizard-language-rtl'
                : ''
            }
          />
        </Row>
        {list?.length ? (
          <ReactSortable
            list={list}
            setList={(newState) => {
              if (JSON.stringify(list) !== JSON.stringify(newState)) {
                setList(newState);
                onlistChange(newState);
              }
            }}
            animation={400}
            handle=".handle">
            {_.map(list, (x, listIndex) => (
              <Price
                displayType="value"
                discountValue={x?.discount?.value}
                key={x.id + listIndex}
                listIndex={`${x.id} + ${listIndex}`}
                t={t}
                stab={stab}
                price={price}
                propCurr={propCurr}
                valuePayemntType={valuePayemntType}
                x={x}
                quantity={quantity}
                selectable={selectable}
                handleTotalChange={handleTotalChange}
                editName={editName}
                configText={configText}
                editQuantity={editQuantity}
                editPrice={editPrice}
                editDescription={editDescription}
                removeItem={removeItem}
                showenDescription={showenDescription}
                setShowenDescription={setShowenDescription}
                showHideDescription={showHideDescription}
                handleOptionsDropdown={handleOptionsDropdown}
                language={prop?.language}
              />
            ))}
          </ReactSortable>
        ) : (
          <Empty description={false} />
        )}
      </div>
    );
  };

  const renderMilesList = () => {
    if (stab === t)
      return (
        <div className={stab === t ? 'tablist-active-tab' : 'tablist-inactive-tab'}>
          {list.length ? (
            <ReactSortable
              list={list}
              setList={(newState) => {
                if (JSON.stringify(list) !== JSON.stringify(newState)) {
                  setList(newState);
                  onlistChange(newState);
                }
              }}
              animation={400}
              handle=".handle">
              {_.map(list, (x, milesIndex) => (
                <Miles
                  key={x.id + milesIndex}
                  x={x}
                  editName={editName}
                  editTime={editTime}
                  removeItem={removeItem}
                  showenDescription={showenDescription}
                  editDescription={editDescription}
                  showHideDescription={showHideDescription}
                />
              ))}
            </ReactSortable>
          ) : (
            <Empty description={false} />
          )}
        </div>
      );
    return null;
  };

  return tabType === 'delivs'
    ? price
      ? renderPriceTableList()
      : renderPriceValueList()
    : tabType === 'miles'
    ? renderMilesList()
    : renderSuperList();
};

Sortlist.defaultProps = {
  price: false,
  curr: '',
  selectable: '',
  valuePayemntType: false,
  quantity: false,
  transform: () => {},
  dispatch: () => {},
};

Sortlist.propTypes = {
  listItem: PropTypes.instanceOf(Array).isRequired,
  prop: PropTypes.instanceOf(Object).isRequired,
  price: PropTypes.bool,
  onlistChange: PropTypes.func.isRequired,
  curr: PropTypes.string,
  configText: PropTypes.instanceOf(Object).isRequired,
  transform: PropTypes.func,
  dispatch: PropTypes.func,
  tabType: PropTypes.string.isRequired,
  selectable: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  valuePayemntType: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  quantity: PropTypes.bool,
  showPane: PropTypes.func,
};

export default Sortlist;
