import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import helpers from 'helpers/proposal';
import utils from 'utils/utils';

const ProposalMetaInformation = ({ proposal, user }) => {
  const metaInformation = helpers.getProposalMeta({
    proposal,
  });
  const { titlehtml, description, logo } = metaInformation;

  const renderScripts = () => {
    if (!user) {
      const scripts = [];
      const path = window.location.pathname;
      const chatwayScripts =
        path.includes('/PDF/') || !proposal?.chatwayScripts ? [] : proposal?.chatwayScripts;
      // eslint-disable-next-line array-callback-return
      [...(proposal?.scripts || []), ...chatwayScripts].forEach((script, scriptIndex) => {
        const tempEl = document.createElement('div');
        tempEl.setAttribute('key', scriptIndex);
        tempEl.innerHTML = script;
        const scripstEl = tempEl.getElementsByTagName('script');
        [...scripstEl].forEach((scriptEl) => {
          const async = { scriptEl };
          const defer = { scriptEl };
          if (scriptEl.src && scriptEl.innerHTML)
            scripts.push({
              src: scriptEl.src,
              innerHTML: scriptEl.innerHTML,
              type: 'text/javascript',
              async,
              defer,
            });
          else if (scriptEl.innerHTML)
            scripts.push({
              innerHTML: scriptEl.innerHTML,
              type: 'text/javascript',
              async,
              defer,
            });
          else if (scriptEl.src)
            scripts.push({
              src: scriptEl.src,
              type: 'text/javascript',
              async,
              defer,
            });
        });
      });

      return scripts;
    }
    return [];
  };

  return (
    <Helmet
      htmlAttributes={{
        lang:
          proposal?.language?.toLowerCase() === 'hebrew'
            ? 'he'
              ? proposal?.language?.toLowerCase() === 'arabic'
              : 'ar'
            : 'en',
      }}
      meta={[
        { name: 'robots', content: 'noindex' },
        { name: 'robots', content: 'nofollow' },
        { property: 'og:title', content: titlehtml },
        {
          property: 'og:description',
          content: description ? description.replace(/(<([^>]+)>)/gi, '') : '',
        },
        {
          property: 'description',
          content: description ? description.replace(/(<([^>]+)>)/gi, '') : '',
        },
        { property: 'og:image', content: logo },
      ]}
      script={renderScripts()}>
      <title>
        Prospero -{' '}
        {utils.changeVariable(proposal?.draft?.variables, titlehtml, user?.profile?.dateFormat)}
      </title>
    </Helmet>
  );
};

ProposalMetaInformation.defaultProps = {
  proposal: '',
};

ProposalMetaInformation.propTypes = {
  proposal: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
};

export default ProposalMetaInformation;
