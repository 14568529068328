import {  Row } from 'antd';
import './Preview.scss';
import {
  EmailNotebookAlert,
  EmailNotebookIcon,
  EmailNotebookReminderIcon,
  EmailNotebookTickIcon,
  ProsperoLogo,
} from 'components/Icons';
import { emailSafeFonts } from 'pages/Emails/Modals/GeneralSettings';

export const PreviewContent = ({ generalSettings, emailKey, formData, showPowerBy = false }) => {
  const { companyLogo, buttonColor, buttonTextColor } = generalSettings;
  const { previewText, emailBody, buttonText } = formData;

  const icons = {
    publishedProposalToProspect: <EmailNotebookIcon />,
    reminderToProspect: <EmailNotebookReminderIcon />,
    secondReminderToProspect: <EmailNotebookAlert />,
    signedPDFToProspect: <EmailNotebookTickIcon />,
  };


  const font =  emailSafeFonts[generalSettings.font];
  return (
    <div className="email-content" style={{ fontFamily: font }}>
      {!companyLogo.includes('default') && (
        <img className="brand-logo" src={companyLogo} alt="brand logo"></img>
      )}
      <Row className="preview-text-section">
        {icons[emailKey]}
        <div className="preview-text word-wrap" style={previewText?.RTL ? { direction: 'rtl' } : {}}>
          <span >{previewText?.value}</span>
        </div>
        <a
          className="preview-action-button word-wrap"
          style={{
            color: buttonTextColor,
            backgroundColor: buttonColor,
            direction: buttonText?.RTL ? 'rtl' : 'auto',
          }}>
          {buttonText?.value}
        </a>
      </Row>
      <div
        className="email-body word-wrap"
        style={emailBody?.RTL ? { direction: 'rtl', textAlign: 'right' } : {}}
        dangerouslySetInnerHTML={{ __html: emailBody?.value }}></div>
      {showPowerBy && (
        <Row className="power-by-section">
          <span className="power-by-text">Powered by</span>
          <ProsperoLogo />
        </Row>
      )}
    </div>
  );
};
