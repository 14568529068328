import { gql } from '@apollo/client';

export const MANIPULATE_USER = gql`
  mutation manipulateUserMutation(
    $uid: String!
    $type: String!
    $adminManipulationInfo: AdminManipulationInfoInput
  ) {
    manipulateUser(uid: $uid, type: $type, adminManipulationInfo: $adminManipulationInfo) {
      type
      defaultPassword
      user {
        _id
        profile {
          name
        }
        emails {
          address
        }
      }
      proposals {
        _id
        date
      }
      transactions {
        _id
        type
        trans
        time
        reason
      }
      payments
      refund
      paymentStatus {
        activeProfileStartDate
        canLock
        everPayed
        trialTime
        lastStatus
        fullSubType
        didCancel
        nextPaymentDate
        validTill
        payments
        proratedAmount
        lastInvoice
        payPalProfile
      }
    }
  }
`;

export const RESTART_ADMIN_PROXY_SERVER = gql`
  mutation restartAdminProxyServerMutation {
    restartAdminProxyServer
  }
`;

export const DELETE_ADMIN_COVER = gql`
  mutation deleteAdminCoverMutation($coverId: String!) {
    deleteAdminCover(coverId: $coverId)
  }
`;

export const USER_IMPERSONATE = gql`
  mutation generateImpersonateToken($_id: String!) {
    generateImpersonateToken(uid: $_id) {
      token
      user {
        _id
        emails {
          address
        }
        profile {
          name
          companyname
          address
          country
          countryCode
          vatnumber
          contactnumber
          whatsappNumber
          image
          terms
          priceSeperator
          decimalPrecision
          currency
          redirectAfter
          redirectTo
          contactButton
          callButton
          dateFormat
          role
          firstPayment
          affiliate
          infoUpdate
          seenTour
        }
        props
        roles
        otherptype
        config
        ptype
        teamId
        freshbooks
        stripe {
          stripe_user_id
          currency
          country
          isActive
        }
        quickbooks {
          expires_in
          x_refresh_token_expires_in
        }
        xero
        monday
        slack
        signature
        scripts
        chatwayScripts
        domain
        cname
        planType
        dns
        defaultLogo
        affiliateId
        paymentStatus {
          canLock
          everPayed
          activeProfileStartDate
        }
        createdAt
        stopperAnchor
        isAdmin
        deletedTemplates
        reviewWidget
        subscriptionId
        zaps {
          proposalId
        }
        integromatHooks {
          enabled
        }
        wix {
          instanceId
          name
          domain
          logo
          metaSiteId
        }
        wixApp {
          instanceId
          name
          domain
          logo
          metaSiteId
          locale {
            country
            languageCode
          }
        }
        morninginvoice {
          id
          expiresIn
        }
      }
    }
  }
`;

export const UPDATE_COMMISSION_PAYMENT = gql`
  mutation updateCommissionPaymentMutation($userId: String!, $createdAt: String!) {
    updateCommissionPayment(userId: $userId, createdAt: $createdAt)
  }
`;

export const RESEND_ADMIN_MAIL_JOB = gql`
  mutation resendAdminMailJobMutation($jobId: String!) {
    resendAdminMailJob(jobId: $jobId)
  }
`;

export const UPDATE_AI_PROMPT_TEXT = gql`
  mutation updateAIPromptTextMutation($prompt: String!) {
    updateAIPromptText(prompt: $prompt)
  }
`;
