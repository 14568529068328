import React, { useReducer, useEffect } from 'react';
import { Col, Row, Slider, Divider, Button } from 'antd';
import { AlphaPicker, HuePicker } from 'react-color';

import './ShadowPicker.scss';

const initialState = {
  xOffset: 0,
  yOffset: 0,
  blur: 0,
  spread: 0,
  alphaColor: '',
  hueColor: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setXOffset':
      return { ...state, xOffset: action.xOffset };
    case 'setYOffset':
      return { ...state, yOffset: action.yOffset };
    case 'setBlur':
      return { ...state, blur: action.blur };
    case 'setSpread':
      return { ...state, spread: action.spread };
    case 'setAlphaColor':
      return { ...state, alphaColor: action.alphaColor };
    case 'setHueColor':
      return { ...state, hueColor: action.hueColor };
    case 'reset':
      return initialState;
    default:
      throw new Error('Unexpected action');
  }
};

const ShadowPicker = ({ shadow, onChange, save, cancel }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (shadow) {
      dispatch({ type: 'setXOffset', xOffset: shadow?.xOffset });
      dispatch({ type: 'setYOffset', yOffset: shadow?.yOffset });
      dispatch({ type: 'setBlur', blur: shadow?.blur });
      dispatch({ type: 'setSpread', spread: shadow?.spread });
      dispatch({ type: 'setAlphaColor', alphaColor: shadow?.alphaColor });
      dispatch({ type: 'setHueColor', hueColor: shadow?.hueColor });
    }
  }, []); //eslint-disable-line

  const onSave = () => {
    save(state);
  };

  const onCancel = () => {
    // setXOffset(0);
    // setYOffset(0);
    // setBlur(0);
    // setSpread(0);
    // setAlphaColor(0);
    // setHueColor(0);
    cancel();
  };

  const handleChange = (value, dispatchType, stateType) => {
    dispatch({ type: dispatchType, [stateType]: value });
    onChange({ ...state, [stateType]: value });
  };

  return (
    <Row className="shadow-picker" gutter={16}>
      <Col className="gutter-row" span={12}>
        <small className="image-crop-label">X offset {state.xOffset}</small>
        <Slider
          step={1}
          min={0}
          max={20}
          value={state.xOffset}
          onChange={(value) => handleChange(value, 'setXOffset', 'xOffset')}
        />
      </Col>
      <Col className="gutter-row" span={12}>
        <small className="image-crop-label">Y offset {state.yOffset}</small>
        <Slider
          step={1}
          min={0}
          max={20}
          value={state.yOffset}
          onChange={(value) => handleChange(value, 'setYOffset', 'yOffset')}
        />
      </Col>
      <Divider />
      <Col className="gutter-row" span={12}>
        <small className="image-crop-label">Blur {state.blur}</small>
        <Slider
          step={1}
          min={0}
          max={20}
          value={state.blur}
          onChange={(value) => handleChange(value, 'setBlur', 'blur')}
        />
      </Col>
      <Col className="gutter-row" span={12}>
        <small className="image-crop-label">Spread {state.spread}</small>
        <Slider
          step={1}
          min={0}
          max={20}
          value={state.spread}
          onChange={(value) => handleChange(value, 'setSpread', 'spread')}
        />
      </Col>
      <Divider />
      <Col className="gutter-row" span={24}>
        <small className="image-crop-label">Color {state?.hueColor?.hex}</small>
        <HuePicker
          color={state?.hueColor}
          onChange={(value) => handleChange(value, 'setHueColor', 'hueColor')}
        />
      </Col>
      <Col className="gutter-row" span={24}>
        <small className="image-crop-label">Transparency {state?.alphaColor?.rgb?.a}</small>
        <AlphaPicker
          color={state?.alphaColor?.rgb}
          onChange={(value) => handleChange(value, 'setAlphaColor', 'alphaColor')}
        />
      </Col>
      <Col className="gutter-row shadow-picker-button" span={24}>
        <Row>
          <Button
            size="middle"
            type="button"
            className="Prosprich-editor-components-popover-confirm"
            onClick={onSave}>
            Save
          </Button>
          <Button
            size="middle"
            type="button"
            className="Prosprich-editor-components-popover-cancel"
            onClick={onCancel}>
            Cancel
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

export default ShadowPicker;
