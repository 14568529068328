import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="#C7BCE4" fillOpacity="0.3" />
    <path
      d="M9 17.697L16 24M16 24L23 17.697M16 24V8"
      stroke="#7876C1"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const DownloadingIcon = (props) => <Icon component={svg} {...props} />;

export default DownloadingIcon;
