import { Empty } from 'antd';

import './EmptyProposal.scss';

const EmptyProposal = ({ status }) => {
  return (
    <Empty
      className="empty-proposal"
      description={<span>Proposal {status === 'expired' ? 'Expired' : 'Not Found'}</span>}
    />
  );
};

export default EmptyProposal;
