import React, { useState } from 'react';
import { Button, Input, Modal } from 'antd';
import PropTypes from 'prop-types';
import CloseIcon from 'components/Icons/CloseIcon';

import './AddCodeIntegrationModal.scss';

const AddCodeIntegrationModal = ({ visible, onCancel, onConfirm }) => {
  const [code, setCode] = useState();
  return (
    <Modal
      zIndex={1009}
      className="confirm-modal code-add-modal"
      centered
      visible={visible}
      onCancel={onCancel}
      closeIcon={<CloseIcon className="close-icon" />}
      footer={null}>
      <h3 className="title">Add Your Script</h3>
      <div className="divider" />

      <div className="add-code">
        <p>JS Code Script</p>
        <Input.TextArea
          value={code}
          onChange={(e) => {
            setCode(e.target.value);
          }}
        />
      </div>

      <div className="button-wrapper">
        <Button
          className="button confirm"
          type="primary"
          onClick={() => {
            onConfirm(code);
            setCode('');
          }}>
          SAVE SCRIPT
        </Button>
        <Button
          className="button cancel"
          type="primary"
          onClick={() => {
            onCancel();
            setCode('');
          }}>
          CANCEL
        </Button>
      </div>
    </Modal>
  );
};

AddCodeIntegrationModal.defaultProps = {
  visible: false,
};

AddCodeIntegrationModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

export default AddCodeIntegrationModal;
