import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="34" height="36" viewBox="0 0 34 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.1868 0H4.67336C3.5162 0 2.57812 0.938073 2.57812 2.09524V32.127C2.57812 33.2842 3.5162 34.2222 4.67336 34.2222H28.7686C29.9258 34.2222 30.8638 33.2842 30.8638 32.127V9.31176C30.8638 8.73813 30.6287 8.18958 30.2132 7.7941L22.6314 0.577574C22.2418 0.206794 21.7246 0 21.1868 0Z"
      fill="#C7BCE4"></path>
    <mask
      id="mask0"
      masktype="alpha"
      maskUnits="userSpaceOnUse"
      x="2"
      y="0"
      width="29"
      height="36">
      <path
        d="M21.1898 0H4.67336C3.5162 0 2.57812 0.938071 2.57812 2.09524V33.9048C2.57812 35.0619 3.5162 36 4.67336 36H28.7686C29.9258 36 30.8638 35.0619 30.8638 33.9048V9.27347C30.8638 8.69805 30.6272 8.14795 30.2094 7.75225L22.6306 0.574025C22.2415 0.20543 21.7258 0 21.1898 0Z"
        fill="#7876C1"></path>
    </mask>
    <g mask="url(#mask0)"></g>
  </svg>
);

const FileIcon = (props) => <Icon component={svg} {...props} />;

export default FileIcon;
