/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="48" height="40" viewBox="0 0 48 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="8" y="10" width="32" height="16" rx="2" stroke="#C7BCE4" strokeWidth="2" />
    <rect x="37" y="17" width="2" height="27" rx="1" transform="rotate(90 37 17)" fill="#7876C1" />
    <path
      d="M35 15L38 18L35 21"
      stroke="#7876C1"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 21L10 18L13 15"
      stroke="#7876C1"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const TitleFullWidth = (props) => <Icon component={svg} {...props} />;

export default TitleFullWidth;
