import React, { useImperativeHandle, forwardRef } from 'react';
import { useQuery } from '@apollo/client';
import { Card, Col, Row } from 'antd';

import helpers from 'helpers';
import { GET_ALL_COVERS_LIBRARIES } from 'graphql/queries/contentLibraryQueries';

import './Covers.scss';

const Covers = forwardRef(
  ({ selectedFolder, saveProposal, dragStart, dragEnd, coverType, name }, ref) => {
    const { data, refetch: refetchCovers } = useQuery(GET_ALL_COVERS_LIBRARIES, {
      fetchPolicy: 'network-only',
      variables: {
        type: coverType,
        key: selectedFolder,
      },
    });

    useImperativeHandle(ref, () => ({
      async reloadItems() {
        await refetchCovers({
          variables: {
            type: coverType,
            key: selectedFolder,
          },
        });
      },
    }));

    let covers = data?.fetchContentLibraryCoversItems
      ? [...data?.fetchContentLibraryCoversItems]
      : [];

    return (
      <Row gutter={[15, 24]} className="library-covers-container">
        {covers
          ?.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
          ?.map((item, itemIndex) => (
            <Col
              span={12}
              key={item._id}
              onClick={() => saveProposal({ ['draft.' + name + '.cover']: item.img })}>
              <Card
                onDragStart={dragStart}
                onDragEnd={dragEnd}
                draggable
                data-img={helpers.formatS3Link(item?.img)}
                id={`toolbar-library-cover-${item._id}`}
                {...(item.img && {
                  cover: (
                    <div
                      style={{
                        backgroundImage: `url("${helpers.formatS3Link(item?.img)}")`,
                        height: coverType === 'full' ? '20em' : '8.5em',
                      }}></div>
                  ),
                })}
                actions={[<span>{item.name || `Top Cover ${itemIndex + 1}`}</span>]}
              />
            </Col>
          ))}
      </Row>
    );
  }
);

export default Covers;
