import React from 'react';
import PropTypes from 'prop-types';

import PopConfirm from 'components/PopConfirm';
import { Form, InputNumber, Select } from 'antd';

const FakeSubModal = ({ visible, setVisible, userInfo, handleSubmit }) => {
  const [form] = Form.useForm();

  return (
    <PopConfirm
      title="Are you sure you manually subscribe this user ?"
      body={
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ subType: 'monthly', months: 1 }}
          form={form}
          onFinish={(values) =>
            handleSubmit('fakeSubscription', userInfo, {
              months: values.months,
              subtype: values.subType,
            })
          }>
          <Form.Item
            label="For how many months starting now?"
            name="months"
            rules={[{ required: true, message: 'Please input months!' }]}>
            <InputNumber placeholder="Months" />
          </Form.Item>
          <Form.Item
            label="Subscription type"
            name="subType"
            rules={[{ required: true, message: 'Select subscription type!' }]}>
            <Select
              placeholder="Subscription type"
              defaultValue="monthly"
              onChange={(val) => {
                form.setFieldsValue({ subType: val });
              }}>
              <Select.Option value="monthly">Monthly</Select.Option>
              <Select.Option value="yearly">Yearly</Select.Option>
            </Select>
            ,
          </Form.Item>
        </Form>
      }
      visible={visible}
      cancelText="CANCEL"
      confirmText="OK"
      onCancel={() => setVisible(false)}
      onConfirm={() => {
        form.submit();
        setVisible(false);
      }}
    />
  );
};

FakeSubModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  userInfo: PropTypes.instanceOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default FakeSubModal;
