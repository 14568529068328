/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.16992 0.335938V7.31592C9.16992 8.10693 9.00146 8.79297 8.66455 9.37402C8.32764 9.9502 7.84424 10.3896 7.21436 10.6924C6.58447 10.9951 5.83984 11.1465 4.98047 11.1465C3.68164 11.1465 2.65869 10.8096 1.91162 10.1357C1.16455 9.46191 0.783691 8.53906 0.769043 7.36719V0.335938H3.35449V7.41846C3.38379 8.58545 3.92578 9.16895 4.98047 9.16895C5.5127 9.16895 5.91553 9.02246 6.18896 8.72949C6.4624 8.43652 6.59912 7.96045 6.59912 7.30127V0.335938H9.16992Z"
      fill="#9E9EB7"
    />
    <rect x="1" y="12" width="8" height="1.5" rx="0.75" fill="#9E9EB7" />
  </svg>
);

const UnderlineIcon = (props) => <Icon component={svg} {...props} />;

export default UnderlineIcon;
