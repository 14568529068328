import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="7.5"
      y="13"
      width="10"
      height="1"
      rx="0.5"
      transform="rotate(-90 7.5 13)"
      fill="#05034D"
    />
    <path d="M4 6L8 3L12 6" stroke="#05034D" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const ArrowUpEnabled = (props) => <Icon component={svg} {...props} />;

export default ArrowUpEnabled;
