import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import { notification } from 'antd';
import Loader from 'components/Loader/Loader';

import { CONNECT_XERO } from 'graphql/queries/integrationQueries';
import { CREATE_XERO_AUTH_URL } from 'graphql/mutations/integrationMutation';
import { isLoggedInVar, userVar, userProfileVar } from 'graphql/cache';
// import utils from 'utils/utils';

const XeroWindow = ({ history }) => {
  const [loading, setLoading] = useState(false);

  const [connectXero] = useLazyQuery(CONNECT_XERO, {
    variables: { url: window.location.href },
    skip: !window.location.href,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.connectXero.token) {
        window.localStorage.setItem('prospero-token', data.connectXero.token);
        window.localStorage.setItem('prospero-user-id', data.connectXero.user._id);

        isLoggedInVar(true);
        userVar({ ...data.connectXero.user });
        userProfileVar({ ...data.connectXero.user.profile });
        history.push('/integration?xero=connected');
      } else {
        window.close(); // only works if window is opened via script (window.open)
      }
    },
    onError: (err) => {
      console.log('error', err);
      message.error('Xero Connect Failed');
      setTimeout(() => history.push('/integration?xero=failed'), 1000);
    },
  });

  const [createXeroAuthUrl] = useMutation(CREATE_XERO_AUTH_URL, {
    onCompleted: async (data) => {
      const authData = data.createXeroAuthUrl;
      window.open(authData.url, '_self');
      /*
      await utils.refetchUserOnClosingTab(authData.url, authData.tab);
      connectXero({
        variables: {
          fromSelf: false,
          url: window.location.href
        }
      });
      */
    },
    onError: (err) => {
      console.log('error', err);
      notification.error({
        message: 'Xero Create Auth URL Failed',
        description: 'Something went wrong',
      });
    },
  });

  useEffect(() => {
    const code = window?.location?.href?.split('?')[1]?.split('&')[0]; // eslint-disable-line

    if (!code) {
      const height = window.outerHeight;
      const width = window.outerWidth;

      createXeroAuthUrl({
        variables: {
          height,
          width,
        },
      });
    } else {
      if (!loading) {
        connectXero();
        setLoading(true);
      }
    }
  }, [connectXero, createXeroAuthUrl, loading, setLoading]);

  return <Loader />;
};

export default XeroWindow;
