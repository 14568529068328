import React, { useState, useRef } from 'react';
import { PhotoAlbum } from 'react-photo-album';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

import utils from 'utils/utils';
import { useSettings } from './Settings';
import SortablePhotoFrame from './SortablePhotoFrame';
import PhotoFrame from './PhotoFrame';

const DragablePhotoAlbum = ({ isOldGallery, photos, setPhotos, onLayoutChange }) => {
  const config = useSettings();
  const { options, containerProps, imageProps } = utils.albumConfig(config);

  const handleImageLoad = (event) => {
    /* 
    REMARK : can be remove after few months
    only used to make new gallery compatible with old gallery, 
    since new gallery requries height and width which is missing from old gallery
    */

    const { naturalWidth, naturalHeight, id } = event.target;
    if (!naturalWidth || !naturalHeight || !id) {
      return;
    }

    setPhotos((prev) => {
      const newPhotos = [...prev];
      // find the photo and add width and height
      const photoIndex = newPhotos.findIndex((p) => p.id === id);
      newPhotos[photoIndex].width = naturalWidth;
      newPhotos[photoIndex].height = naturalHeight;

      onLayoutChange(newPhotos);
      return newPhotos;
    });
  };

  const renderedPhotos = useRef({});
  const [activeId, setActiveId] = useState();
  const activeIndex = activeId ? photos.findIndex((photo) => photo.id === activeId) : undefined;

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 5 } }),
    useSensor(TouchSensor, { activationConstraint: { delay: 50, tolerance: 10 } }),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const handleDragStart = React.useCallback(({ active }) => setActiveId(active.id), []);

  const handleDragEnd = React.useCallback((event) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setPhotos((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        const newPhotos = arrayMove(items, oldIndex, newIndex);

        onLayoutChange(newPhotos);

        return newPhotos;
      });
    }

    setActiveId(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRemoveImage = (id) => {
    setPhotos((items) => {
      const newPhotos = [...items].filter((p) => p.id !== id);
      onLayoutChange(newPhotos);
      return newPhotos;
    });
  };

  const renderPhoto = (props) => {
    const imageId = props.photo.id;
    // capture rendered photos for future use in DragOverlay
    renderedPhotos.current[imageId] = props;

    return (
      <SortablePhotoFrame
        activeIndex={activeIndex}
        onRemoveImage={() => onRemoveImage(imageId)}
        {...(isOldGallery ? { handleImageLoad } : {})}
        {...props}
      />
    );
  };

  return (
    <div
      className="gallery-album"
      style={{
        '--gallery-spacing': `${config.spacing / 2 + 2}px`,
      }}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}>
        <SortableContext items={photos}>
          <PhotoAlbum
            photos={photos}
            {...options}
            spacing={config.spacing}
            renderPhoto={renderPhoto}
            componentsProps={{
              containerProps,
              imageProps,
            }}
          />
        </SortableContext>

        <DragOverlay>
          {activeId && <PhotoFrame overlay {...renderedPhotos.current[activeId]} />}
        </DragOverlay>
      </DndContext>
    </div>
  );
};

export default DragablePhotoAlbum;
