import './WixPricing.scss';

export default function WixPaymentSchedule({ paymentTerms, notes, configText }) {
  const nonBreakableWord = (str) => {
    return str
      .split('-')
      .map((item) => {
        return `<span class='no-break'>${item}</span>`;
      })
      .join('-');
  };

  if (!paymentTerms?.length) {
    return null;
  }

  return (
    <div className="pricing-wrapper payment-schedule">
      <div className="border">
        <table>
          <thead className="pricing-header item-row">
            <tr>
              <td className="item-col payment">
                <span className="no-break">{configText('payment text')}</span>
              </td>
              <td className="item-col amount hide-mobile">
                <span className="no-break">{configText('amount text')}</span>
              </td>
              <td className="item-col due">
                <span>{configText('due date')}</span>
              </td>
            </tr>
          </thead>

          <tbody className="pricing-deliverables">
            {paymentTerms.map((item, i) => (
              <tr key={i} className="deliverable item-row">
                <td className="item-col payment">
                  {item.title}
                  {item.amount && (
                    <div className="no-break hide-desktop subtitle-wrapper">{item.amount}</div>
                  )}
                </td>
                <td
                  className="item-col amount hide-mobile no-break"
                  dangerouslySetInnerHTML={{ __html: nonBreakableWord(item.amount) }}
                />
                <td className="item-col due">{item.dueDate}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {notes.length > 0 && <div className="notes">{notes}</div>}
    </div>
  );
}
