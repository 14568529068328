import React, { useEffect, useState } from 'react';
import { Button, Card, Menu, Dropdown, Input } from 'antd';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import MenuSmallIcon from 'components/Icons/MenuSmallIcon';
import StarIcon from 'components/Icons/StarIcon';
import UnStartIcon from 'components/Icons/UnStarIcon';
import Loader from 'components/Loader';
import PublishContent from 'pages/Proposal/components/Publish/components/PublishContent';
import helpers from 'helpers';
import utils from 'utils/utils';
import ThumbnailCreator from 'components/ThumbnailCreator';
import { GET_TEMPLATE } from 'graphql/queries/contentLibraryQueries';

import './TemplateCard.scss';

const TemplateCard = ({
  thumbnail,
  title,
  isStarred,
  templateId,
  templateUserId,
  templateAuid,
  disabledFilter,
  onClickMenuItem,
  config,
  user,
  onDragStart,
  itemEditable,
  setEditValue,
  isLocked,
  variables,
}) => {
  const [publishContent] = ThumbnailCreator({ title, elementId: templateId, type: 'templates' });

  const [templateData, setTemplateData] = useState(null);
  const [showPreview, toggleShowPreview] = useState(false);

  const [getTemplate, { loading }] = useLazyQuery(GET_TEMPLATE, {
    variables: {
      _id: templateId,
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ fetchTemplate }) => {
      if (!templateData) {
        setTemplateData(fetchTemplate);
      }

      if (showPreview) {
        onClickMenuItem({ actionType: 'preview', templateId, templateData: fetchTemplate });
        toggleShowPreview(false);
      }
    },
  });

  useEffect(() => {
    const onResize = () => {
      document.querySelectorAll('.template-thumbnail-container').forEach((item) => {
        const width = item.clientWidth;
        item.style.height = `${width}px`;
        item.firstElementChild.style.transform = `scale(${(width / 900).toFixed(2)})`;
      });
    };
    window.addEventListener('resize', onResize);

    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const menu = () => {
    const isAdminTemplate = templateUserId === user._id || templateAuid === user._id ? false : true;

    return (
      <Menu>
        {!isAdminTemplate && (
          <>
            <Menu.Item onClick={() => onClickMenuItem({ actionType: 'duplicate', templateId })}>
              Duplicate
            </Menu.Item>
            <Menu.Item onClick={() => onClickMenuItem({ actionType: 'share', templateId })}>
              Share
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                onClickMenuItem({
                  actionType: itemEditable ? 'save' : 'rename',
                  templateId,
                  templateName: title,
                })
              }>
              {itemEditable ? 'Save' : 'Rename'}
            </Menu.Item>
            <Menu.Item onClick={() => onClickMenuItem({ actionType: 'move', templateId })}>
              Move to folder
            </Menu.Item>
            <Menu.Item onClick={() => onClickMenuItem({ actionType: 'delete', templateId })}>
              Delete
            </Menu.Item>
          </>
        )}

        {isAdminTemplate && (
          <>
            <Menu.Item onClick={() => onClickMenuItem({ actionType: 'duplicate', templateId })}>
              Duplicate
            </Menu.Item>
            <Menu.Item onClick={() => onClickMenuItem({ actionType: 'hideorenable', templateId })}>
              {disabledFilter ? 'Enable' : 'Hide'}
            </Menu.Item>
          </>
        )}
      </Menu>
    );
  };

  useEffect(() => {
    if (!thumbnail) {
      getTemplate();
    }
  }, [getTemplate, thumbnail]);

  return (
    <Card
      draggable="true"
      onDragStart={onDragStart}
      className="content-library-card"
      cover={
        loading ? (
          <Loader />
        ) : (
          <>
            <div className="hover-items-container">
              <div className="hover-items" data-html2canvas-ignore>
                <div className="buttons">
                  {!isLocked && (
                    <>
                      {(user._id === templateUserId || user._id === templateAuid) && (
                        <Button
                          onClick={() =>
                            onClickMenuItem({ actionType: 'edit_template', templateId })
                          }>
                          Edit Template
                        </Button>
                      )}

                      <Button
                        onClick={() => {
                          toggleShowPreview(true);
                          getTemplate();
                        }}>
                        Preview
                      </Button>

                      <Button
                        onClick={() => onClickMenuItem({ actionType: 'use_template', templateId })}>
                        Use as Proposal
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
            {thumbnail ? (
              <img alt={helpers.formatS3Link(thumbnail)} src={helpers.formatS3Link(thumbnail)} />
            ) : templateData && config ? (
              <div className="template-thumbnail-container" ref={publishContent} id={templateId}>
                <div className="template-thumbnail">
                  <PublishContent config={config} prop={templateData} user={user} previewTemplate />
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </>
        )
      }>
      <div className="card-title">
        {isStarred ? (
          <StarIcon
            className="anticon-star"
            onClick={() =>
              onClickMenuItem({
                actionType: 'starOrUnstar',
                templateId,
                starred: false,
              })
            }
          />
        ) : (
          <UnStartIcon
            className="anticon-star"
            onClick={() =>
              onClickMenuItem({
                actionType: 'starOrUnstar',
                templateId,
                starred: true,
              })
            }
          />
        )}

        {itemEditable ? (
          <Input
            defaultValue={title}
            onChange={(e) => setEditValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                e.preventDefault();
                onClickMenuItem({ actionType: 'save', templateId });
              }
            }}
            autoFocus
          />
        ) : (
          <span
            className="title"
            title={utils.changeVariable(variables, title, user?.profile?.dateFormat)}
            onDoubleClick={() =>
              user._id === templateUserId ||
              user._id === templateAuid ||
              (user.roles && user.roles[0] === 'manager')
                ? onClickMenuItem({ actionType: 'rename', templateId })
                : {}
            }>
            {utils.changeVariable(variables, title, user?.profile?.dateFormat)}
          </span>
        )}

        <Dropdown overlay={menu} className="menu-icon" trigger={['click']}>
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <MenuSmallIcon />
          </a>
        </Dropdown>
      </div>
    </Card>
  );
};

TemplateCard.defaultProps = {
  thumbnail: '',
  isStarred: false,
  disabledFilter: false,
  config: {},
  templateAuid: '',
  itemEditable: false,
  isLocked: false,
};

TemplateCard.propTypes = {
  title: PropTypes.string.isRequired,
  thumbnail: PropTypes.string,
  isStarred: PropTypes.bool,
  templateId: PropTypes.string.isRequired,
  templateUserId: PropTypes.string.isRequired,
  templateAuid: PropTypes.string,
  disabledFilter: PropTypes.bool,
  onClickMenuItem: PropTypes.func.isRequired,
  config: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object).isRequired,
  itemEditable: PropTypes.bool,
  setEditValue: PropTypes.func.isRequired,
  isLocked: PropTypes.bool,
};

export default TemplateCard;
