const Path = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  LOGIN: '/login',
  SIGNUP: '/signup',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  USERSTEAM: '/users',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: (token) => `/reset-password/${token}`,
  UPDATE_PASSWORD: (token) => `/update-password/${token}`,
  INTEGRATION: '/integration',
  EMAILS: '/emails',
  STRIPEINTEGRATIONS: '/stripe/integrations',
  CONTENT_LIBRARY: '/contentlibrary',
  QUICKBOOKS: '/quickbooks',
  FRESHBOOKS: '/freshbooks',
  XERO: '/xero',
  SLACK: '/slack',
  MONDAY: '/monday',
  INVALID_CONNECTION: '/invalid',
  QUICKBOOKS_DISCONNECT: '/quickbooks/disconnect',
  AFFILIATE: '/affiliate',
  PROPOSAL: (pid) => `/w/${pid}`,
  DRAFT: (pid) => `/d/${pid}`,
  TEMPLATE: (tid) => `/t/${tid}`,
  SECTION: (sid) => `/s/${sid}`,
  PUBLISH_TEMPLATE: (tid) => `/pt/${tid}`,
  PUBLISH_SECTION: (sid) => `/ps/${sid}`,
  PUBLISH: (id) => `/pd/${id}`,
  CLIENT: (cid) => `/cd/${cid}`,
  PDF: (id) => `/PDF/${id}`,
  DOWNLOAD_PDF: `/download/pdf`,
  ADMIN: '/livelongandprosper',
  PLANS: '/plans',
  TERMS: '/terms',
  ACTIVITY: '/activity',
  ROXY: '/roxy',
  WIX_OAUTH: '/oauth/wix',
  WIX_APP_CONNECT: '/wix-app-connect',
  WIX_CONNECT: '/wix-connect',
  WIX: '/wix',
  WIX_TEMPLATE_PREVIEW: '/template-preview',
  WIX_PROPOSAL_PREVIEW: '/proposal-preview',
  WIX_PROPOSAL_EDITOR: '/proposal-editor',
  WIX_TEMPLATE_EDITOR: '/template-editor',
};

export default Path;
