/* eslint-disable react/no-unknown-property */
import React from 'react';
import { Button as AntButton } from 'antd';
import PropTypes from 'prop-types';
import GoogleIcon from 'components/Icons/GoogleIcon';

const CustomGoogleButton = ({
  type,
  htmlType,
  size,
  className,
  block,
  text,
  shape,
  onClick,
  disabled,
  style,
  danger,
  loading,
}) => (
  <AntButton
    type={type}
    htmlType={htmlType}
    size={size}
    shape={shape}
    block={block}
    icon={<GoogleIcon className="google-login-icon" />}
    className={className}
    disabled={disabled}
    onClick={onClick}
    danger={danger}
    style={style}
    loading={loading}>
    <span className="button-text">{text}</span>
  </AntButton>
);

CustomGoogleButton.defaultProps = {
  type: '',
  htmlType: '',
  size: '',
  className: '',
  block: false,
  icon: '',
  shape: '',
  disabled: false,
  onClick: () => {},
  style: {},
  danger: false,
};

CustomGoogleButton.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
  type: PropTypes.string,
  htmlType: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  block: PropTypes.bool,
  danger: PropTypes.bool,
  shape: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.instanceOf(Object),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
};

export default CustomGoogleButton;
