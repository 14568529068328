import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.3996 14.6429V14.5588C8.3996 13.9342 8.0028 13.3897 7.506 13C6.85757 12.4921 6.38592 11.7988 6.15632 11.0161C5.92672 10.2333 5.95054 9.39986 6.22448 8.631C6.49841 7.86215 7.00891 7.19593 7.68533 6.72457C8.36174 6.2532 9.17062 6 10 6C10.8294 6 11.6383 6.2532 12.3147 6.72457C12.9911 7.19593 13.5016 7.86215 13.7755 8.631C14.0495 9.39986 14.0733 10.2333 13.8437 11.0161C13.6141 11.7988 13.1424 12.4921 12.494 13C11.9964 13.3905 11.5996 13.9342 11.5996 14.5588V14.6429M8.3996 14.6429V16.2143C8.3996 16.4227 8.48389 16.6225 8.63391 16.7699C8.78394 16.9172 8.98743 17 9.1996 17H10.7996C11.0118 17 11.2153 16.9172 11.3653 16.7699C11.5153 16.6225 11.5996 16.4227 11.5996 16.2143V14.6429M8.3996 14.6429H11.5996H8.3996Z"
      fill="#C7BCE4"
    />
    <path
      d="M8.3996 14.6429V14.5588C8.3996 13.9342 8.0028 13.3897 7.506 13C6.85757 12.4921 6.38592 11.7988 6.15632 11.0161C5.92672 10.2333 5.95054 9.39986 6.22448 8.631C6.49841 7.86215 7.00891 7.19593 7.68533 6.72457C8.36174 6.2532 9.17062 6 10 6C10.8294 6 11.6383 6.2532 12.3147 6.72457C12.9911 7.19593 13.5016 7.86215 13.7755 8.631C14.0495 9.39986 14.0733 10.2333 13.8437 11.0161C13.6141 11.7988 13.1424 12.4921 12.494 13C11.9964 13.3905 11.5996 13.9342 11.5996 14.5588V14.6429M8.3996 14.6429V16.2143C8.3996 16.4227 8.48389 16.6225 8.63391 16.7699C8.78394 16.9172 8.98743 17 9.1996 17H10.7996C11.0118 17 11.2153 16.9172 11.3653 16.7699C11.5153 16.6225 11.5996 16.4227 11.5996 16.2143V14.6429M8.3996 14.6429H11.5996"
      stroke="#C7BCE4"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.2222 9.75H17M3.77778 9.75H3M10 3.75V3M14.3999 5.50725L14.9498 4.977M5.60089 5.50725L5.05022 4.977M10 12V10.5"
      stroke="#7876C1"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const BulbIcon = (props) => <Icon component={svg} {...props} />;

export default BulbIcon;
