import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import { DISCONNECT_FRESHBOOKS } from 'graphql/mutations/integrationMutation';
import { userVar } from 'graphql/cache';
import IntegrationItem from '../IntegrationItem';
import utils from 'utils/utils';
import FreshbookIcon from 'components/Icons/FreshbookIcon';

const FreshbooksIntegration = ({
  data,
  isModalItem,
  userRole,
  refetchUser,
  isLoading,
  location,
  user,
}) => {
  const [integrationData, setIntegrationData] = useState(data);
  const [loading, setLoading] = useState();

  const freshbooksConnectionStatus = utils.getQueryStringValue('freshbooks');

  useEffect(() => {
    if (data) {
      setIntegrationData(data);
    }
  }, [data]);

  useEffect(() => {
    async function checkUser() {
      if (freshbooksConnectionStatus === 'connected') {
        const userData = await refetchUser();
        if (userData) {
          userVar({ ...user, ...userData.data.fetchUser });
          setIntegrationData(userData.data.fetchUser.freshbooks);
        } else {
          setIntegrationData(user.freshbooks);
        }
        setLoading('connected');
      } else if (freshbooksConnectionStatus === 'failed') {
        setLoading('disconnected');
      }
    }
    checkUser();
  }, [freshbooksConnectionStatus, refetchUser, user]);

  const [disconnectFreshbooks, { loading: isDisconnectingFreshbooks }] = useMutation(
    DISCONNECT_FRESHBOOKS,
    {
      onCompleted: async () => {
        const userData = await refetchUser();
        if (userData) {
          userVar({ ...user, ...userData.data.fetchUser });
        }
        setIntegrationData('');
        setLoading('disconnected');
        notification.success({
          message: 'FreshBooks Disconnected',
          description: 'Your account has been disconnected from FreshBooks.',
        });
      },
      onError: () => {
        notification.error({
          message: 'FreshBooks Disconnected Failed',
          description: 'Your account failed to disconnect try again.',
        });
      },
    }
  );

  const handleConnect = async () => {
    const clientId = process.env.REACT_APP_FRESHBOOKS_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_FRESHBOOKS_REDIRECT_URI;
    const authUrl = `https://my.freshbooks.com/service/auth/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}`;

    await utils.refetchUserOnClosingTab(authUrl);
    const userData = await refetchUser();
    if (userData) {
      userVar({ ...user, ...userData.data.fetchUser });
      setIntegrationData(userData.data.fetchUser.freshbooks);
    } else {
      setIntegrationData(user.freshbooks);
    }
  };

  const handleClick = () => {
    if (!integrationData || !integrationData.ID || !integrationData.ID.id) {
      setLoading('connecting');
      return handleConnect();
    }
    setLoading('disconnecting');
    return disconnectFreshbooks();
  };

  return (
    <IntegrationItem
      type="freshbooks"
      name="freshBooks"
      description="Import contacts & generate invoices with a click"
      integrationLink="https://support.goprospero.com/article/show/140027-how-to-integrate-prospero-to-freshbooks"
      status={
        loading ||
        isDisconnectingFreshbooks || // this can be connecting or disconnecting
        (integrationData && integrationData.ID && integrationData.ID.id
          ? 'connected'
          : 'disconnected')
      }
      logo={<FreshbookIcon />}
      handleClick={handleClick}
      isModalItem={isModalItem}
      userRole={userRole}
    />
  );
};

FreshbooksIntegration.defaultProps = {
  data: null,
  isModalItem: false,
  refetchUser: () => {},
};

FreshbooksIntegration.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(null),
  ]),
  isModalItem: PropTypes.bool,
  userRole: PropTypes.string,
  refetchUser: PropTypes.func,
};

export default FreshbooksIntegration;
