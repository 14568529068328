import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Menu, Dropdown, Checkbox, Radio, Input, Empty } from 'antd';

import SearchFadedIcon from 'components/Icons/SearchFadedIcon';
import TagBadge from 'components/TagBadge';
import { escapeRegExp } from 'utils/xss';

import './MultiSelectMultiMenu.scss';

const MenuItem = ({ item }) => (
  <Menu.Item key={item.id} className="menu-item">
    <Checkbox className="item-text">{item.name}</Checkbox>
  </Menu.Item>
);

const MultiSelectMultiMenu = ({
  className,
  filterList,
  iconComponent,
  onChangeFilter,
  onChangeTagsFilter,
  hideCheckBox,
  isCheckBox,
  isRadio,
  trash,
  onVisibleChangeHandle,
  tags,
  tagsType,
  setTagsType,
}) => {
  const [serachedText, setSearchTagText] = useState('');
  const [visible, setVisible] = useState(false);
  const [searchedIds, setSearchedIds] = useState(null);

  const searchTags = (e) => {
    e.stopPropagation();
    const text = e.target.value;
    setSearchTagText(text);
    if (!text?.length) {
      setSearchedIds(null);
      return;
    }

    const regex = new RegExp(escapeRegExp(text), 'i');
    setSearchedIds(tags.filter((tag) => regex.test(tag.name)).map((t) => t._id));
  };

  const onMenuItemClick = (e) => e.stopPropagation();

  const tagsList = tags.filter((item) => {
    if (searchedIds && !searchedIds.includes(item._id)) {
      return false;
    }
    return true;
  });

  const menuItems = (
    <div className="status-tags-filter" onClick={(e) => e.stopPropagation()}>
      <div className="status-menu border-right">
        <div className="menu-header border-bottom">
          <span className="title">Status</span>
        </div>
        <div className="menu-item-wrapper">
          {filterList.map((item) => (
            <div key={item.id} className="menu-item" onClick={onMenuItemClick}>
              {isRadio ? (
                <Radio
                  className={`item-text ${hideCheckBox ? 'hide-multi-checkbox' : ''}`}
                  checked={item.checked}
                  onChange={() => onChangeFilter(item)}
                  onClick={onMenuItemClick}>
                  {item.name}
                </Radio>
              ) : isCheckBox ? (
                <Checkbox
                  className={`item-text ${hideCheckBox ? 'hide-multi-checkbox' : ''}`}
                  checked={item.checked}
                  onChange={() => onChangeFilter(item)}
                  onClick={onMenuItemClick}>
                  {item.name}
                </Checkbox>
              ) : null}
            </div>
          ))}
        </div>
      </div>
      <div className="tag-menu">
        <div className="menu-header border-bottom">
          <div className="tags-title">
            <span className="title">Tags</span>
            <span className="count">({tags?.length || 0})</span>
          </div>
          <Radio.Group
            className="radio-toggle"
            value={tagsType}
            onClick={onMenuItemClick}
            onChange={(e) => {
              tagsType === 'or' ? setTagsType('and') : setTagsType('or');
            }}>
            <Radio.Button value="or" onClick={onMenuItemClick}>
              <span className="switch-toggle-button-text"> OR </span>
            </Radio.Button>
            <Radio.Button value="and" onClick={onMenuItemClick}>
              <span className="switch-toggle-button-text"> AND </span>
            </Radio.Button>
          </Radio.Group>
        </div>
        <div className="tag-search border-bottom">
          <Input
            placeholder="Search tags"
            onClick={onMenuItemClick}
            onChange={searchTags}
            value={serachedText}
            prefix={<SearchFadedIcon className="search--tag-suffix" />}
            allowClear
          />
        </div>
        <div className="menu-item-wrapper">
          {!tagsList.length && <Empty />}

          {tagsList.map((item) => (
            <div key={item._id} className="menu-item" onClick={onMenuItemClick}>
              {isRadio ? (
                <Radio
                  className={`item-text ${hideCheckBox ? 'hide-multi-checkbox' : ''}`}
                  checked={item.checked}
                  onChange={() => onChangeTagsFilter(item)}
                  onClick={onMenuItemClick}>
                  {item.name}
                </Radio>
              ) : isCheckBox ? (
                <Checkbox
                  className={`item-text ${hideCheckBox ? 'hide-multi-checkbox' : ''}`}
                  checked={item.checked}
                  onChange={() => onChangeTagsFilter(item)}
                  onClick={onMenuItemClick}>
                  {item.name === 'All Tags' ? item.name : <TagBadge {...item} />}
                </Checkbox>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
  return (
    <Dropdown
      overlayClassName={`multi-select ${className}`}
      overlay={menuItems}
      disabled={trash ? true : false}
      trigger={['click']}
      visible={visible}
      onVisibleChange={(value) => {
        setVisible(value);
        onVisibleChangeHandle(value);
      }}>
      {iconComponent}
    </Dropdown>
  );
};

MenuItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
  }).isRequired,
};

MultiSelectMultiMenu.defaultProps = {
  showBorderBelowFirstItem: true,
  isCheckBox: true,
  isRadio: false,
  onVisibleChangeHandle: () => {},
};

MultiSelectMultiMenu.propTypes = {
  filterList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  iconComponent: PropTypes.shape({}),
  onChangeFilter: PropTypes.func.isRequired,
  showBorderBelowFirstItem: PropTypes.bool,
  isCheckBox: PropTypes.bool,
  isRadio: PropTypes.bool,
  onVisibleChangeHandle: PropTypes.func,
};

export default MultiSelectMultiMenu;
