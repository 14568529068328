import helpers from 'helpers';

const getMaxDigitCount = (fontSize) => {
  // maximum number of digits to be shown with currency and .00
  // for large digits curency and .00 wont be displayed
  if (fontSize > 26) {
    return 7;
  }
  if (fontSize > 16) {
    return 9;
  }
  return 11;
};

export const getPricingTable = (
  wixPricing,
  currency,
  configText,
  fontSize = 20,
  currencyCode,
  locale
) => {
  let lineItems = [];
  let summaryItems = [];
  let taxes = {};
  let total = '';
  let shortenDigits = false;

  const prefixMinus = (str) => {
    const minusKeys = ['discount'];
    return minusKeys.includes(str.toLowerCase()) ? '-' : '';
  };

  if (wixPricing?.lineItems) {
    const maxDigitCount = getMaxDigitCount(fontSize);

    wixPricing.lineItems.forEach((item) => {
      if (item.pricesBreakdown?.lineItemPrice?.length > maxDigitCount || fontSize > 26) {
        shortenDigits = true;
      }
    });

    lineItems = wixPricing.lineItems.map((item) => {
      const lineItem = {
        name: item.name,
        description: item.description || '',
        quantity: item.quantity,
        lineItemPrice: item.pricesBreakdown?.lineItemPrice || 0,
        price: item.price,
        tax: [],
        discount: 0,
      };

      const name = item.pricesBreakdown.taxDetails?.taxName || configText('Tax');
      const rate = item.pricesBreakdown.taxDetails?.taxRate || '0';
      const tax = item.pricesBreakdown.taxDetails?.totalTax || '0';

      if (parseFloat(item.pricesBreakdown?.taxDetails?.totalTax) > 0) {
        // sample text -> GST (12%)
        const taxName = `${name} (${toPercent(rate)})`;

        lineItem.tax.push({ name: taxName, amount: tax });

        if (taxes[taxName]) {
          // sum up the tax amount
          taxes[taxName].tax = taxes[taxName].tax + Number(tax);
        } else {
          taxes[taxName] = {
            name: taxName,
            tax: Number(tax),
          };
        }
      }

      return lineItem;
    });
  }

  if (wixPricing?.calculatedTotals?.priceSummary) {
    const wix_summary = wixPricing?.calculatedTotals?.priceSummary;
    const priceSummary = [
      { d: configText('sub total'), price: wix_summary.subtotal },
      { d: configText('shipping text'), price: wix_summary.shipping },
      { d: configText('additional fees'), price: wix_summary.additionalFees },
      { d: configText('discount text'), price: wix_summary.discount },
    ];

    // filter out items with price '0'
    const nonZeroPriceSummary = priceSummary.filter(
      (item) => parseFloat(item.price) !== 0 && item.price !== undefined
    );

    // add currency symbol to front of value
    summaryItems = nonZeroPriceSummary.map((item) => ({
      ...item,
      price: `${prefixMinus(item.d)}${helpers.prefixCurrencySymbol(
        item.price,
        currencyCode,
        locale
      )}`,
    }));

    // add taxes to subtotal
    if (Object.keys(taxes).length) {
      Object.keys(taxes).forEach((uniqueName) => {
        summaryItems.push({
          d: taxes[uniqueName].name,
          price: helpers.prefixCurrencySymbol(
            roundToDecimal(taxes[uniqueName].tax),
            currencyCode,
            locale
          ),
        });
      });
    }

    total = helpers.prefixCurrencySymbol(wix_summary.total, currencyCode, locale);

    // if there is tax, discount
    if (!Object.keys(taxes).length && parseFloat(wix_summary.discount) === 0) {
      summaryItems = [];
    }
  }
  return { lineItems, summaryItems, total, shouldShortenDigits: shortenDigits };
};

export const getPaymentSchedule = (
  paymentSettings,
  currency,
  total,
  configText,
  currencyCode,
  locale
) => {
  let paymentTerms = [];
  let notes = '';

  if (paymentSettings?.type === 'SINGLE_PAYMENT' && paymentSettings?.singlePaymentSchedule) {
    const paymentSchedule = paymentSettings.singlePaymentSchedule;

    paymentTerms.push({
      title: configText('payment text', true),
      amount: '100%',
      dueDate: `${configText('within text')} ${paymentSchedule?.dueDateOffset || 30} ${configText(
        'day(s) from signing date'
      )}`,
    });

    if (
      !isNaN(parseFloat(paymentSchedule?.deposit?.amount)) &&
      parseFloat(paymentSchedule?.deposit?.amount) > 0
    ) {
      // show $(amount - deposit)
      paymentTerms[0].amount = helpers.prefixCurrencySymbol(
        roundToDecimal(Number(total) - Number(paymentSchedule.deposit.amount)),
        currencyCode,
        locale
      );

      const formatedDeposit = helpers.prefixCurrencySymbol(
        roundToDecimal(paymentSchedule.deposit.amount),
        currencyCode,
        locale
      );

      notes = `${configText('deposit of text')} ${formatedDeposit} ${configText(
        'is requested text'
      )}`;
    }
  } else if (paymentSettings?.multiplePaymentSchedule?.items) {
    paymentTerms = paymentSettings.multiplePaymentSchedule.items.map((item, index) => ({
      title: item.title,
      amount: helpers.prefixCurrencySymbol(item.amount, currencyCode, locale),
      dueDate: getDueDate(item?.dueDateOffset || 0, item?.issueDateOffset || 0, index, configText),
    }));
  }

  return { paymentTerms, notes };
};

const getDueDate = (dueDateOffset, issueDateOffset, index, configText) => {
  const position = ['', '1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th'];

  switch (index) {
    case 0:
      return `${configText('within text')} ${dueDateOffset} ${configText(
        'day(s) from signing date',
        true
      )}`;
    default:
      const days = dueDateOffset + issueDateOffset;
      const languageChineseKorean =
        configText('language', true) === 'chinese' || configText('language', true) === 'korean';
      return `${
        languageChineseKorean ? `${configText('day(s) after alt text')} ${days}` : days
      } ${configText('day(s) after text')} ${
        position[index] || configText('previous text')
      } ${configText('payment')}`;
  }
};

/* Temporarily comment */
// const toPlural = (num, str) => {
//   if (num < 2) {
//     return str;
//   } else return `${str}s`;
// };

const roundToDecimal = (value, decimalPlaces = 2) => {
  // input -> roundToDecimal(57.0001,2)
  // output -> "57.00"
  const factor = 10 ** decimalPlaces;
  return (Math.round(value * factor) / factor).toFixed(decimalPlaces);
};

const toPercent = (num) => {
  // input and output -> "0.10" -> "10%"
  // input and output -> "0.1005" -> "10.05%"

  let percentage = (Number(num) * 100).toFixed(2);

  // Remove trailing zeros from the decimal part if it's a whole number
  percentage = percentage.replace(/\.?0*$/, '');

  return `${percentage}%`;
};

export const formatLocale = (pricing) => {
  if (pricing?.locale?.language && pricing?.locale?.country) {
    return `${pricing.locale.language}-${pricing.locale.country}`;
  }
  return 'en-US';
};
