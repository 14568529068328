/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.98705 23L6.98705 23L6.98705 22.0042L5.99128 22L5.98705 23ZM5.98706 25.5794L4.98706 25.5794L5.98706 25.5794ZM9.27533 27.1093L9.91946 27.8742L9.27533 27.1093ZM14.1551 23V22H13.7901L13.5109 22.2351L14.1551 23ZM4 8C4 6.89543 4.89543 6 6 6V4C3.79086 4 2 5.79086 2 8H4ZM4 20V8H2V20H4ZM5.99128 22C4.89078 21.9953 4 21.1017 4 20H2C2 22.2034 3.78153 23.9907 5.98283 24L5.99128 22ZM6.98706 25.5794L6.98705 23L4.98705 23L4.98706 25.5794L6.98706 25.5794ZM8.63119 26.3443C7.98082 26.892 6.98706 26.4297 6.98706 25.5794L4.98706 25.5794C4.98706 28.1302 7.96836 29.5172 9.91946 27.8742L8.63119 26.3443ZM13.5109 22.2351L8.63119 26.3443L9.91946 27.8742L14.7992 23.7649L13.5109 22.2351ZM26 22H14.1551V24H26V22ZM28 20C28 21.1046 27.1046 22 26 22V24C28.2091 24 30 22.2091 30 20H28ZM28 8V20H30V8H28ZM26 6C27.1046 6 28 6.89543 28 8H30C30 5.79086 28.2091 4 26 4V6ZM6 6H26V4H6V6Z"
      fill="#005AFF"
    />
    <rect x="6" y="10" width="20" height="2" rx="1" fill="#005AFF" />
    <rect x="6" y="16" width="15" height="2" rx="1" fill="#005AFF" />
  </svg>
);

const MessageIcon = (props) => <Icon component={svg} {...props} />;

export default MessageIcon;
