import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg viewBox="0 -6 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="23" y="14" width="2" height="11" rx="1" fill="#7876C1" />
    <path
      d="M27 22L24 25L21 22"
      stroke="#7876C1"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 23V29C16 29.5523 16.4477 30 17 30H31C31.5523 30 32 29.5523 32 29V23"
      stroke="#C7BCE4"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ImportSectionIcon = (props) => <Icon component={svg} {...props} />;

export default ImportSectionIcon;
