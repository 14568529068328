import getRaw from './getRaw';

const getPricing2 = ({ prop, configText }) => {
  const title = configText('section pricing2 title');
  const pricing = prop.pricing || { strategy: 'table1' };
  const { strategy } = pricing;

  return {
    title: title,
    rawtitle: getRaw(title),
    subrawtitle: getRaw(''),
    raw: {
      entityMap: {
        0: {
          type: 'TOKEN',
          mutability: 'IMMUTABLE',
          data: {
            texcontent: JSON.stringify({
              deliverables: prop.deliverables,
              curr: prop.curr || prop.currency,
              pricing: prop.pricing,
            }),
          },
        },
      },
      blocks: [
        {
          key: 'as2e8',
          text: configText(`${strategy} pricing preface`, true),
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: 'as2e9',
          text: '',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: '7de23',
          text: ' ',
          type: 'atomic',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [{ offset: 0, length: 1, key: 0 }],
          data: {},
        },
        {
          key: 'as2f5',
          text: '',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ],
    },
  };
};

export default getPricing2;