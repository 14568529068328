import React, { useState, useEffect, useCallback } from 'react';
import { ContentState } from 'draft-js';
import PropTypes from 'prop-types';

import { TableList } from './components';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';

let m_pos = 0;
let tPageY = 0;

const TableComponent = ({ block, blockProps }) => {
  const contentState = ContentState.createFromBlockArray([block]);
  const ent = block.getEntityAt(0);
  const entity = contentState.getEntity(ent);

  const { data } = entity;

  const [table, setTable] = useState(JSON.parse(JSON.stringify(data.data)));
  const [config, setConfig] = useState(
    data.config
      ? JSON.parse(JSON.stringify(data.config))
      : { topRowColor: 'lightgrey', rowColor: 'white', height: 'auto' }
  );
  const [tableHeight, setTableHeight] = useState(
    data.config ? JSON.parse(JSON.stringify(data.config)).height : 'auto'
  );

  //eslint-disable-next-line
  const _save = (newTable, newConfig) => {
    const entityKey = block.getEntityAt(0);
    if (entityKey) {
      contentState.replaceEntityData(entityKey, {
        data: JSON.parse(JSON.stringify(newTable)),
        config: JSON.parse(JSON.stringify(newConfig)),
      });
      blockProps.update(block);
      _finishEdit();
    }
  };

  // eslint-disable-next-line
  const debouncedSave = useCallback(
    (newTable, newConfig) => {
      _save(newTable, newConfig);
    },
    [_save]
  );

  const _finishEdit = () => blockProps.onFinishEdit(block.getKey());

  const onHeightChange = useCallback(
    ({ height }) => {
      const tempConfig = JSON.parse(JSON.stringify(config));
      tempConfig.height = height;
      setConfig(tempConfig);
      debouncedSave(table, tempConfig);
    },
    [setConfig, debouncedSave, config, table]
  );

  const handleResize = useCallback(
    (e) => {
      e.preventDefault();
      const { clientHeight } = document.getElementById('table-container');

      tPageY >= e.pageY
        ? (m_pos = m_pos - (tPageY - e.pageY) || clientHeight)
        : (m_pos = m_pos + (e.pageY - tPageY) || clientHeight);

      tPageY = e.pageY;

      setTableHeight(m_pos);
      onHeightChange({ height: m_pos });
    },
    [onHeightChange]
  );

  useEffect(() => {
    document.addEventListener(
      'mouseup',
      function () {
        document.removeEventListener('mousemove', handleResize, false);
      },
      false
    );
  }, [handleResize]);

  const handleFocus = useCallback(
    (e) => {
      blockProps.handleEditComponent(true);
      e.preventDefault();
      e.stopPropagation();
    },
    [blockProps]
  );

  const handleDuplicate = () => {
    blockProps.handleDuplicateElement(table, 'table', block.getKey());
    blockProps.handleEditComponent(false);
  };

  const handleRemove = () => {
    blockProps && blockProps.onRemove(block.getKey());
    blockProps.handleEditComponent(false);
  };

  const saveTable = ({ tableCopy }) => {
    setTable(tableCopy);
    debouncedSave(tableCopy, config);
    blockProps.handleEditComponent(false);
  };

  const editColumn = (p, k, e, index) => {
    const inputValue = e.target.innerText;
    const obj = p.p;
    const objId = obj.id;
    const position = k.k;
    const values = Object.values(obj);

    if (values.indexOf(inputValue) === -1 || index !== values.indexOf(inputValue)) {
      obj[position] = inputValue;
      const tableCopy = table || [];
      tableCopy.forEach((object) => {
        if (object.id === objId) {
          object = obj[position];
        }
      });

      setTable(tableCopy);
      debouncedSave(tableCopy, config);
    }
  };

  const addRow = () => {
    const tableCopy = JSON.parse(JSON.stringify(table)) || [];

    const newID = tableCopy && tableCopy.length ? tableCopy[tableCopy.length - 1].id + 1 : 0;

    const newRow = {};

    if (tableCopy && tableCopy.length) {
      Object.keys(tableCopy[0])
        .filter((k) => k !== 'id')
        .forEach((key) => {
          newRow[key] = '-';
        });
    } else {
      newRow['0'] = '-';
    }

    newRow.id = newID;

    tableCopy.push(newRow);

    saveTable({ tableCopy });
  };

  const addColumn = () => {
    let tableCopy = JSON.parse(JSON.stringify(table)) || [];

    if (tableCopy && tableCopy.length) {
      tableCopy.forEach((s, index) => {
        const currentRow = Object.keys(s).filter((k) => k !== 'id');
        s[Number(currentRow[currentRow.length - 1]) + 1] = `-`;
        tableCopy[index] = s;
      });
    } else {
      tableCopy = [];
      tableCopy[0] = {
        0: '-',
        id: 0,
      };
    }

    saveTable({ tableCopy });
  };

  const deleteRow = (id) => {
    let tableCopy = JSON.parse(JSON.stringify(table)) || [];

    tableCopy = tableCopy.filter((v) => v.id !== id);

    saveTable({ tableCopy });
  };

  const deleteColumn = (i) => {
    const tableCopy = JSON.parse(JSON.stringify(table)) || [];

    tableCopy.forEach((s, index) => {
      const currentRow = Object.keys(s).filter((k) => k !== 'id');
      const keyToBeDeleted = currentRow[i];
      delete s[keyToBeDeleted];
      tableCopy[index] = s;
    });

    saveTable({ tableCopy });
  };

  const onColorChange = ({ color, rowSketch, topRowSketch }) => {
    const tempConfig = JSON.parse(JSON.stringify(config));
    if (rowSketch) {
      tempConfig.rowColor = color.hex;
    } else if (topRowSketch) {
      tempConfig.topRowColor = color.hex;
    }
    setConfig(tempConfig);
    _save(table, tempConfig);
  };

  const onDragMouseDown = (e) => {
    if (e.clientY > 4) {
      m_pos = e.y;
      document.addEventListener('mousemove', handleResize, false);
    }
  };

  const tableStyle = {
    align: 'center',
    height: tableHeight !== 'auto' ? `${tableHeight}px` : tableHeight,
  };

  return (
    <ComponentWrapper
      showDrag
      isDraggable
      showDuplicate
      showDelete
      showActionButtons
      showTableActions
      config={config}
      sectionName={blockProps.sectionName}
      setDraggingElement={blockProps.setDraggingElement}
      blockKey={block.getKey()}
      duplicate={handleDuplicate}
      remove={handleRemove}
      onFocus={handleFocus}
      addRow={addRow}
      addColumn={addColumn}
      onColorChange={onColorChange}
      onDragMouseDown={onDragMouseDown}
      handleEditComponent={blockProps.handleEditComponent}
      setDropDisabled={blockProps.setDropDisabled}
      componentType={'Table'}>
      <div className="rich-editor-component-table">
        <table className="table-container" id="table-container" cellSpacing="3" style={tableStyle}>
          <tbody>
            <TableList
              table={table}
              config={config}
              setTable={setTable}
              editColumn={editColumn}
              deleteRow={deleteRow}
              deleteColumn={deleteColumn}
              saveTable={saveTable}
            />
          </tbody>
        </table>
      </div>
    </ComponentWrapper>
  );
};

TableComponent.propTypes = {
  block: PropTypes.instanceOf(Object).isRequired,
  blockProps: PropTypes.instanceOf(Object).isRequired,
};

export default TableComponent;
