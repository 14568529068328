import { Spin } from 'antd';
import React, { useState, useRef } from 'react';
import { LibraryFolderList, LibraryFolderItems, SelectedLibraryItems } from '..';
import './ContentLibrary.scss';

const ContentLibrary = ({ selectedItems, setSeletedItem, copying, showSelectedFiles = true }) => {
  const [selectedFolderList, setSelectedFolderList] = useState(['']);
  const navRef = useRef(null);

  const selectedItemsArr = Object.values(selectedItems);
  const removeItem = (item) => {
    const { [item.src]: _, ...rest } = selectedItems;
    setSeletedItem(rest);
  };

  return (
    <div className={`library-modal-container ${copying ? 'item-copying' : ''}`}>
      {copying ? (
        <Spin />
      ) : (
        <>
          <div className="nav-section" ref={navRef}>
            <div>
              {selectedFolderList.map((selectedFolder, i) => (
                <div className="nav-section-col">
                  <LibraryFolderList
                    type="medias"
                    selectedFolder={selectedFolder}
                    setSelectedFolder={(f) => {
                      setSelectedFolderList([...selectedFolderList.slice(0, i + 1), f]);
                      setTimeout(() => navRef?.current?.scroll(10000, 0), 100);
                    }}
                  />

                  <LibraryFolderItems
                    type="medias"
                    selectedFolder={selectedFolder}
                    selectedItems={selectedItems}
                    addToSeletedItem={(m) => {
                      if (!(m.src in selectedItems)) {
                        setSeletedItem({
                          ...selectedItems,
                          [m.src]: m,
                        });
                      } else {
                        removeItem(m);
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          </div>

          {showSelectedFiles && (
            <SelectedLibraryItems selectedItemsArr={selectedItemsArr} removeItem={removeItem} />
          )}
        </>
      )}
    </div>
  );
};

export default ContentLibrary;
