import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 5.5L12.5 10.5L7.5 15.5"
      stroke="#05034D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ClosedMenuIcon = (props) => <Icon component={svg} {...props} />;

export default ClosedMenuIcon;
