import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.3484 6.63565C23.0036 4.28911 19.8771 3 16.5477 3C9.69212 3 4.1074 8.56041 4.10143 15.3862C4.10143 17.5723 4.67422 19.6991 5.76014 21.5822L4 28.004L10.599 26.2813C12.4189 27.2674 14.4654 27.7902 16.5477 27.7902H16.5537C23.4093 27.7902 28.994 22.2298 29 15.3981C28.994 12.0891 27.6993 8.97625 25.3484 6.63565ZM16.5477 25.6931C14.6862 25.6931 12.8663 25.1941 11.2792 24.2555L10.9033 24.0298L6.98926 25.0515L8.03341 21.2496L7.78878 20.8575C6.7506 19.2179 6.20764 17.3228 6.20764 15.3802C6.20764 9.71289 10.8496 5.09109 16.5537 5.09109C19.3162 5.09109 21.9117 6.16635 23.8687 8.10893C25.8198 10.0574 26.8938 12.6416 26.8938 15.3921C26.8878 21.0773 22.2458 25.6931 16.5477 25.6931ZM22.222 17.9822C21.9117 17.8278 20.3842 17.0792 20.0979 16.9723C19.8115 16.8713 19.6026 16.8179 19.3998 17.1268C19.1909 17.4357 18.5943 18.1367 18.4153 18.3387C18.2363 18.5466 18.0513 18.5703 17.741 18.4159C17.4308 18.2614 16.4284 17.9347 15.241 16.8773C14.3162 16.0575 13.6957 15.0416 13.5107 14.7327C13.3317 14.4238 13.4928 14.2575 13.648 14.103C13.7852 13.9664 13.9582 13.7406 14.1134 13.5624C14.2685 13.3842 14.3222 13.2535 14.4236 13.0456C14.5251 12.8377 14.4773 12.6594 14.3998 12.505C14.3222 12.3505 13.7017 10.8238 13.4391 10.206C13.1885 9.60002 12.932 9.68319 12.741 9.67725C12.5621 9.66537 12.3532 9.66537 12.1444 9.66537C11.9356 9.66537 11.6014 9.74259 11.315 10.0515C11.0286 10.3604 10.2291 11.1089 10.2291 12.6357C10.2291 14.1624 11.3449 15.6297 11.5 15.8377C11.6551 16.0456 13.6897 19.1703 16.8103 20.5129C17.5501 20.8337 18.1289 21.0238 18.5823 21.1664C19.3282 21.404 20.0024 21.3684 20.5394 21.2911C21.136 21.202 22.3771 20.5426 22.6396 19.8179C22.8962 19.0931 22.8962 18.4753 22.8186 18.3446C22.741 18.2139 22.5322 18.1367 22.222 17.9822Z"
      fill="#24CD5B"
    />
  </svg>
);

const WhatsappIcon = (props) => <Icon component={svg} {...props} />;

export default WhatsappIcon;
