import React from 'react';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import CloseIcon from 'components/Icons/CloseIcon';
import TrashIcon from 'components/Icons/TrashIcon';

import './CofirmModal.scss';

const ConfirmModal = ({
  title,
  body,
  visible,
  cancelText,
  confirmText,
  onCancel,
  onConfirm,
  showIcon,
  className,
  maskClosable,
  isSaving,
  zIndex,
}) => (
  <Modal
    className={`confirm-modal ${className}`}
    centered
    zIndex={zIndex}
    visible={visible}
    onCancel={onCancel}
    closeIcon={<CloseIcon className="close-icon" />}
    showIcon={showIcon}
    footer={null}
    maskClosable={maskClosable}>
    <TrashIcon className="logo" />
    <h3 className="title">{title}</h3>
    <div className="divider" />

    <p className="body">{body}</p>

    <div className="button-wrapper">
      {confirmText && (
        <Button className="button confirm" type="primary" onClick={onConfirm} loading={isSaving}>
          {confirmText}
        </Button>
      )}
      {cancelText && (
        <Button className="button cancel" type="primary" onClick={onCancel} disabled={isSaving}>
          {cancelText}
        </Button>
      )}
    </div>
  </Modal>
);

ConfirmModal.defaultProps = {
  visible: false,
  showIcon: true,
  isSaving: false,
  maskClosable: true,
  onCancel: () => {},
  onConfirm: () => {},
};

ConfirmModal.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  visible: PropTypes.bool,
  showIcon: PropTypes.bool,
  isSaving: PropTypes.bool,
  maskClosable: PropTypes.bool,
};

export default ConfirmModal;
