import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="50" height="49" viewBox="0 0 50 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_14427_21549)">
      <path
        d="M12.7571 4.02832L2.35085 6.72342C0.586521 7.18036 -0.473326 8.98105 -0.0163851 10.7454L9.0855 45.8893C9.54244 47.6536 11.3431 48.7135 13.1075 48.2566L28.8868 44.1699C30.6511 43.7129 31.711 41.9122 31.254 40.1479L24.0924 12.4954C23.9194 11.8275 23.5414 11.2306 23.0115 10.7886L15.6982 4.68872C14.8815 4.00755 13.7866 3.76169 12.7571 4.02832Z"
        fill="#5D5AB5"
      />
      <path
        d="M27.5922 1.46094H10.0031C8.18059 1.46094 6.70312 2.9384 6.70312 4.76094V40.1609C6.70312 41.9835 8.18058 43.4609 10.0031 43.4609H35.8031C37.6257 43.4609 39.1031 41.9835 39.1031 40.1609V13.1345C39.1031 12.2705 38.7643 11.441 38.1594 10.824L29.9484 2.45048C29.3278 1.81756 28.4787 1.46094 27.5922 1.46094Z"
        fill="#7876C1"
      />
      <rect x="10.9062" y="19.2617" width="19.2" height="2.4" rx="1.2" fill="#C7BCE4" />
      <rect x="10.9062" y="23.8613" width="9.6" height="2.4" rx="1.2" fill="#C7BCE4" />
      <circle cx="14.5063" cy="10.4613" r="3.6" fill="#C7BCE4" />
      <mask id="mask0_14427_21549" maskUnits="userSpaceOnUse" x="6" y="1" width="33" height="45">
        <path
          d="M27.3939 1.26172H9.8C7.97746 1.26172 6.5 2.73918 6.5 4.56172V42.1435C6.5 43.9661 7.97746 45.4435 9.8 45.4435H35.6C37.4225 45.4435 38.9 43.9661 38.9 42.1435V12.8893C38.9 12.0225 38.5589 11.1905 37.9505 10.573L29.7444 2.24547C29.1242 1.61608 28.2775 1.26172 27.3939 1.26172Z"
          fill="#7876C1"
        />
      </mask>
      <g mask="url(#mask0_14427_21549)">
        <path
          d="M28.7812 9.88517V-2.84961L44.9813 11.5352H30.4313C29.52 11.5352 28.7812 10.7964 28.7812 9.88517Z"
          fill="#C7BCE4"
        />
      </g>
    </g>
    <circle cx="37.0029" cy="33.9717" r="12.417" fill="#01C44F" />
    <path
      d="M31.8281 33.9717L35.5909 38.1107L42.1756 30.8674"
      stroke="white"
      stroke-width="2.0695"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <clipPath id="clip0_14427_21549">
        <rect width="40" height="48" fill="white" transform="translate(0 0.554688)" />
      </clipPath>
    </defs>
  </svg>
);

const EmailNotebookTickIcon = (props) => <Icon component={svg} {...props} />;

export default EmailNotebookTickIcon;
