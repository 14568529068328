import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="31" cy="31" r="12" fill="white" />
  </svg>
);

const CurrentProgressIcon = (props) => <Icon component={svg} {...props} />;

export default CurrentProgressIcon;
