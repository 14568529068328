import React, { useState } from 'react';
import Button from 'components/Button';
import { Space } from 'antd';
import PropTypes from 'prop-types';
import { ZoomOutOutlined, ZoomInOutlined, RedoOutlined } from '@ant-design/icons';

import './ZoomHandler.scss';

const ZoomHandler = ({ setZoom }) => {
  const [zoomValue, setComponentZoomValue] = useState(100);
  const zoomMinValue = 10;
  const zoomMaxValue = 200;

  const updateZoomValue = (increment = null) => {
    const newZoom = increment ? zoomValue + increment : 100;
    if (newZoom >= zoomMinValue && newZoom <= zoomMaxValue) {
      setComponentZoomValue(newZoom);
      setZoom(newZoom);
    }
  };
  return (
    <div className="zoom-component-container">
      <Space size={15}>
        <Button
          className={zoomValue === zoomMinValue ? 'btn-disabled btn-margin' : 'btn-margin'}
          onClick={() => updateZoomValue(-5)}
          type="text"
          block
          text=""
          icon={<ZoomOutOutlined />}></Button>
        <span>{zoomValue}%</span>
        <Button
          className={zoomValue === 100 ? 'btn-disabled btn-margin' : 'btn-margin'}
          onClick={() => updateZoomValue()}
          type="text"
          block
          text=""
          icon={<RedoOutlined />}></Button>
        <Button
          className={zoomValue === zoomMaxValue ? 'btn-disabled btn-margin' : 'btn-margin'}
          onClick={() => updateZoomValue(5)}
          type="text"
          block
          text=""
          icon={<ZoomInOutlined />}></Button>
      </Space>
    </div>
  );
};

ZoomHandler.defaultProps = {};

ZoomHandler.propTypes = {
  setZoom: PropTypes.func.isRequired,
};

export default ZoomHandler;
