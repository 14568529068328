import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.75 1L18 6M18 6L12.75 11M18 6L1 6"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ArrowRight = (props) => <Icon component={svg} {...props} />;

export default ArrowRight;
