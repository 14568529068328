import React, { useState } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Row, Dropdown, Menu, Button, Col, Cascader, Tooltip } from 'antd';
import PropTypes from 'prop-types';

import { proposalsClientVar, mondayBoardIdVar } from 'graphql/cache';
import { FETCH_MONDAY_CONTACTS } from 'graphql/queries/integrationQueries';
import images from 'constants/images';
import AddRoundIcon from 'components/Icons/AddRoundIcon';
import AddUser from 'components/Icons/AddUser';
import InfoIcon from 'components/Icons/InfoIcon';

import './MondayClientName.scss';

const MondayClientName = ({
  type,
  action,
  onChange,
  clientNameValue,
  label,
  placeholder,
  language,
  dupClientErr,
  fromTemplate,
  alert,
}) => {
  const proposalsClients = useReactiveVar(proposalsClientVar);

  const [clientName, setClientName] = useState(clientNameValue);
  const [clientNames, setClientNames] = useState(proposalsClients);
  const [visibleMondayClientName, setVisibleMondayClientName] = useState(false);

  useQuery(FETCH_MONDAY_CONTACTS, {
    fetchPolicy: 'network-only',
    variables: {
      boardId: mondayBoardIdVar(),
    },
    onCompleted: ({ fetchMondayContacts }) => {
      if (fetchMondayContacts.length) {
        setClientNames(fetchMondayContacts);
        proposalsClientVar(fetchMondayContacts);
      } else {
        setClientName(clientNameValue);
      }
    },
  });

  const onInputChange = (value, email, company, mondayItemId) => {
    setClientName(value);
    setVisibleMondayClientName(false);
    if (fromTemplate) {
      onChange(value, email, company, mondayItemId);
    } else {
      if (action === 'contact') {
        onChange({ type: 'setName', action: 'name', value: company || '' });
        onChange({ type: 'setEmail', action: 'email', value: email || '' });
        onChange({ type: 'setMondayItemId', action: 'mondayItemId', value: mondayItemId || '' });
      }
      onChange({ type, action, value });
    }
  };

  const menu = (
    <Menu
      className={`monday-client-name-dropdown-menu ${
        language === 'hebrew' || language === 'arabic' ? 'language-rtl' : ''
      }`}>
      <Menu.Item>
        <Cascader
          allowClear={false}
          placement="topRight"
          open={true}
          displayRender={(label) => {
            return label;
          }}
          options={clientNames?.map((clientContact) => ({
            value: clientContact.value || clientContact.name,
            label: (
              <span className="monday-ant-cascader-menu-item">
                <span className="monday-ant-cascader-menu-item-value">
                  {' '}
                  {clientContact.value || clientContact.name}
                </span>
                <span className="monday-ant-cascader-menu-item-value">
                  {clientContact.email ? `(${clientContact.email})` : ''}
                </span>
              </span>
            ),
            email: clientContact.email,
            company: clientContact.company,
            id: clientContact.id,
            disabled: false,
          }))}
          getPopupContainer={(trigger) => trigger.parentElement}
          suffixIcon={<AddUser />}
          placeholder="New Contact"
          expandTrigger="hover"
          onChange={(_, selectedContact) => {
            onInputChange(
              !!selectedContact[0].value ? selectedContact[0].value : '',
              !!selectedContact[0].email ? selectedContact[0].email : '',
              !!selectedContact[0].company ? selectedContact[0].company : '',
              !!selectedContact[0].id ? selectedContact[0].id : ''
            );
          }}
          showSearch={{
            filter: (inputValue, path) =>
              path.some(
                (option) => option?.value?.toLowerCase().indexOf(inputValue?.toLowerCase()) > -1
              ),
            matchInputWidth: true,
            sort: (a, b, inputValue, names) => {
              const callback = (elem) => elem[names?.value]?.indexOf(inputValue) > -1;
              return a?.findIndex(callback) - b?.findIndex(callback);
            },
            render: (_, path) => {
              const name = path?.[0]?.value || path?.[0]?.name;
              const label = path?.[0]?.label;
              return (
                <span
                  className="monday-ant-cascader-menu-item"
                  onClick={() => onInputChange(!!name?.trim() ? name : '')}>
                  {label}
                </span>
              );
            },
          }}
          notFoundContent="No contact found"
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              setVisibleMondayClientName(false);
              onInputChange(!!event.target.value?.trim() ? event.target.value : '');
            }
          }}
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="monday-client-name-dropdown-container">
      {label && (
        <Row>
          <label className="input-label">{label}</label>
          <Tooltip
            overlayClassName="wizard-tooltip-email"
            title="Search for a monday contact or add a new one that will be available on Prospero only. 
            Fetching existing contacts is available only from the Contacts and Leads tabs on your monday dashboard."
            color="black"
            key="white">
            <InfoIcon />
          </Tooltip>
        </Row>
      )}
      {alert && (
        <div className="monday-contacts-alert">
          Fetching existing contacts is available only from the Contacts and Leads tabs on your
          monday dashboard
        </div>
      )}
      <Dropdown overlay={menu} trigger={['click']} visible={visibleMondayClientName}>
        <Button
          id="monday-client-name-dropdown-button"
          onClick={() => setVisibleMondayClientName(!visibleMondayClientName)}>
          <Row justify="space-between">
            <Col>
              <img
                alt="Monday"
                className={fromTemplate ? 'monday-template' : ''}
                src={images.MONDAY_ICON}
              />
              <span className={`monday-button-text ${fromTemplate ? 'monday-template' : ''}`}>
                {clientName || 'Select/Add Contact (↵ to add)'}
              </span>
            </Col>
            <Col>
              <AddRoundIcon />
            </Col>
          </Row>
        </Button>
      </Dropdown>
      <p>{dupClientErr}</p>
    </div>
  );
};

MondayClientName.defaultProps = {
  dupClientErr: '',
  clientNameValue: '',
};

MondayClientName.propTypes = {
  type: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  clientNameValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  dupClientErr: PropTypes.string,
};

export default MondayClientName;
