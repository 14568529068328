/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40.9024 16.6604C40.9295 17.025 40.9295 17.3875 40.9295 17.75C40.9295 28.8438 32.4857 41.6271 17.0545 41.6271C12.3003 41.6271 7.88366 40.25 4.16699 37.8583C4.84199 37.9354 5.49199 37.9625 6.19408 37.9625C10.117 37.9625 13.7274 36.6375 16.6128 34.3771C12.9232 34.3 9.83157 31.8833 8.76491 28.5583C9.28366 28.6354 9.80449 28.6875 10.3503 28.6875C11.1024 28.6875 11.8587 28.5833 12.5607 28.4021C8.71282 27.6229 5.83158 24.2458 5.83158 20.1667V20.0625C6.95033 20.6854 8.24824 21.075 9.62324 21.1271C7.36283 19.6229 5.88158 17.05 5.88158 14.1396C5.88158 12.5813 6.29616 11.1521 7.02324 9.90626C11.1545 14.9958 17.3649 18.3229 24.3274 18.6875C24.1982 18.0625 24.1191 17.4146 24.1191 16.7646C24.1191 12.1396 27.8607 8.37292 32.5107 8.37292C34.9274 8.37292 37.1087 9.38542 38.642 11.0229C40.5378 10.6583 42.3545 9.95626 43.967 8.99584C43.3441 10.9438 42.017 12.5813 40.2774 13.6208C41.967 13.4375 43.6045 12.9708 45.1087 12.3208C43.967 13.9833 42.5399 15.4646 40.9024 16.6604Z"
      fill="#49A0EB"
    />
  </svg>
);

const TwitterIcon = (props) => <Icon component={svg} {...props} />;

export default TwitterIcon;
