import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="30" height="25" viewBox="-3 0 32 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.1233 2.36084H10.6543V15.3004H18.1233V2.36084Z" fill="#FF5A00" />
    <path
      d="M11.1513 8.83098C11.1513 6.20205 12.4329 3.86877 14.3996 2.3612C12.9522 1.26381 11.1264 0.600586 9.13486 0.600586C4.41699 0.600586 0.599609 4.28162 0.599609 8.83098C0.599609 13.3803 4.41699 17.0614 9.13486 17.0614C11.1264 17.0614 12.9522 16.3982 14.3996 15.3008C12.4302 13.8145 11.1513 11.4599 11.1513 8.83098Z"
      fill="#EB001B"
    />
    <path
      d="M28.1999 8.8305C28.1999 13.3799 24.3826 17.0609 19.6647 17.0609C17.6731 17.0609 15.8473 16.3977 14.3999 15.3003C16.3915 13.79 17.6483 11.4594 17.6483 8.8305C17.6483 6.20156 16.3666 3.86828 14.3999 2.36071C15.8445 1.26332 17.6704 0.600098 19.6619 0.600098C24.3826 0.600098 28.1999 4.30511 28.1999 8.8305Z"
      fill="#F79E1B"
    />
  </svg>
);

const MasterCardIcon = (props) => <Icon component={svg} {...props} />;

export default MasterCardIcon;
