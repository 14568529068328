/* eslint-disable */
import React, { useState, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Tablist } from '../../../modules';
import helpers from 'helpers/proposal';

const MilesInformation = React.forwardRef(
  ({ prop, config, handleState, user, save, saveUser, setSaveDisabled }, ref) => {
    const [miles, setMiles] = useState([]);
    const [milesRegex, setMilesRegex] = useState('');
    const [chosenMiles, setChosenMiles] = useState('');

    useEffect(() => {
      // milestones
      const userConfig = user.config;

      let tempMiles = [];
      const userMiles = userConfig && userConfig.miles ? userConfig.miles : '';

      tempMiles = config.milestones ? _.compact(config.milestones.split('\n')) : null;
      tempMiles = tempMiles.map((mile) => {
        return { name: mile, editable: false };
      });

      if (userMiles) {
        tempMiles = _.union(tempMiles, userMiles || []);
      }

      if (milesRegex) {
        const searchRegex = new RegExp(milesRegex.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
        tempMiles = tempMiles.filter((mile) => searchRegex.test(mile.name));
      }

      const tempChosenMiles = prop.milestones
        ? prop.milestones.chosen
        : helpers.getDefaultOptions({ prelist: tempMiles, config, prop });

      setMiles(tempMiles);
      setChosenMiles(tempChosenMiles);
    }, [prop, milesRegex]);

    useImperativeHandle(
      ref,
      () => ({
        resetChosen: () => {
          const newData = {
            'OPTION 1': { total: '', list: [] },
          };
          setChosenMiles(newData);
          onSave(newData);
        },
      }),
      [setChosenMiles]
    );

    const onSave = (chosen) => {
      // save in db
      save({ milestones: { chosen } }, true);

      // prevent the create button for few seconds
      setSaveDisabled(true);
      setTimeout(() => {
        setSaveDisabled(false);
      }, 3000);
    };

    return (
      <Tablist
        tabType="miles"
        tabs={false}
        config={config}
        prop={prop}
        save={save}
        saveUser={saveUser}
        prelist={miles}
        handleState={handleState}
        propsChosen={chosenMiles ? _.merge({}, chosenMiles) : chosenMiles}
        setMilesRegex={setMilesRegex}
        onChange={(chosen) => {
          if (!prop.milestones || !_.isEqual(prop.milestones.chosen, chosen)) {
            // update local data
            setChosenMiles(chosen);
            onSave(chosen);
          }
        }}
        isWizard
      />
    );
  }
);

MilesInformation.propTypes = {
  prop: PropTypes.instanceOf(Object).isRequired,
  handleState: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  config: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
};

export default MilesInformation;
