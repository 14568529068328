import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Input, Space, DatePicker, Checkbox } from 'antd';

const { RangePicker } = DatePicker;

const UsersDetailsHeader = ({
  refetch,
  filters,
  handleFilters,
  handleReset,
  debounceSearchHandler,
}) => {
  return (
    <div className="table-action">
      <h3>Users</h3>
      <div>
        <Space>
          <Checkbox
            checked={filters.paid}
            onChange={(value) => {
              handleFilters('paid', value.target.checked);
            }}>
            Show paid only
          </Checkbox>
          <Checkbox
            checked={filters.wixUsers}
            onChange={(value) => {
              handleFilters('wixUsers', value.target.checked);
            }}>
            Show Wix Users
          </Checkbox>
          <RangePicker
            value={[moment(filters.startDate), moment(filters.endDate)]}
            onChange={(values) => {
              let dataValue = values;
              if (!values) {
                dataValue = [moment(), moment()];
              }
              handleFilters(
                'date',
                dataValue.map((el) => el.toISOString())
              );
              refetch();
            }}
          />

          <Input.Search
            width="200"
            placeholder="Email / Uid / Name"
            // value={filters.search}
            onChange={(e) => debounceSearchHandler(e.target.value)}
            onSearch={() => refetch()}
          />

          <Button type="primary" onClick={handleReset}>
            Reset
          </Button>
        </Space>
      </div>
    </div>
  );
};

UsersDetailsHeader.defaultProps = {
  filters: {},
};

UsersDetailsHeader.propTypes = {
  refetch: PropTypes.func.isRequired,
  filters: PropTypes.instanceOf(Object),
  handleReset: PropTypes.func.isRequired,
  handleFilters: PropTypes.func.isRequired,
};

export default UsersDetailsHeader;
