import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useMutation, useReactiveVar, useLazyQuery } from '@apollo/client';

import Loader from 'components/Loader';
import utils from 'utils/utils';
import CloseIcon from 'components/Icons/CloseIcon';
import { getUserId, userVar } from 'graphql/cache';
import { DISCONNECT_QUICKBOOKS_BY_REALM_ID } from 'graphql/mutations/integrationMutation';
import { USER_DETAILS } from 'graphql/queries/userQueries';

const QuickbooksDisconnect = () => {
  const history = useHistory();
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);

  let user = useReactiveVar(userVar);

  const [loadUser] = useLazyQuery(USER_DETAILS, {
    skip: !getUserId(),
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (res) => {
      const { fetchUser } = res;
      userVar({ ...user, ...fetchUser });
    },
  });

  const handleDisconnection = () => {
    loadUser({
      variables: {
        id: getUserId(),
      },
    });
    setShowDisconnectModal('disconnected');
  };

  const [disconnectQuickBooksByRealmId, { loading: isDisconnectingQuickBooksByRealmId }] =
    useMutation(DISCONNECT_QUICKBOOKS_BY_REALM_ID, {
      onCompleted: handleDisconnection,
      onError: handleDisconnection,
    });

  useEffect(() => {
    const realmId = utils.getURLParam('realmId');

    if (realmId) {
      disconnectQuickBooksByRealmId({
        variables: {
          realmId,
        },
      });
    } else {
      setShowDisconnectModal('failed');
    }
  }, [disconnectQuickBooksByRealmId]);

  return (
    <div>
      {isDisconnectingQuickBooksByRealmId && <Loader />}
      {showDisconnectModal && (
        <Modal
          zIndex={1009}
          className={'confirm-modal quickbooks-disconnect-modal'}
          centered
          visible={showDisconnectModal}
          onCancel={() => history.push('/integration')}
          closeIcon={<CloseIcon className="close-icon" />}
          closable={true}
          showIcon={true}
          footer={null}>
          <div className="title">
            {showDisconnectModal === 'disconnected'
              ? 'QuickBooks Disconnected'
              : 'QuickBooks Disconnection Failed'}
          </div>

          <div className="divider" />

          <div className="body">
            {showDisconnectModal === 'disconnected' ? (
              <>
                <p>Your QuickBooks account has already been disconnected</p>
                <span>
                  To reconnect follow:{' '}
                  <a
                    href="https://support.goprospero.com/article/show/108519-how-to-integrate-prospero-to-quickbooks"
                    target="_blank"
                    rel="noreferrer">
                    Integration Guide
                  </a>
                </span>
              </>
            ) : (
              <>
                <p>Missing RealmId</p>
                <span>
                  To disconnect go to:{' '}
                  <a
                    onClick={() => history.push('/integrations')}
                    style={{ textDecoration: 'underline' }}>
                    Integrations Page
                  </a>
                </span>
              </>
            )}
          </div>

          <div className="button-wrapper">
            <Button
              className="button confirm"
              type="primary"
              onClick={() => history.push('/dashboard')}>
              Go to Dashboard
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

QuickbooksDisconnect.propTypes = {};

export default QuickbooksDisconnect;
