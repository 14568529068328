import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.2" width="35" height="35" rx="17.5" fill="#FF1B1B" />
    <rect
      x="11.3127"
      y="9.25"
      width="20.4167"
      height="2.91667"
      rx="1.45833"
      transform="rotate(45 11.3127 9.25)"
      fill="#FF1B1B"
    />
    <rect
      x="25.7495"
      y="11.3135"
      width="20.4167"
      height="2.91667"
      rx="1.45833"
      transform="rotate(135 25.7495 11.3135)"
      fill="#FF1B1B"
    />
  </svg>
);

const ErrorIcon = (props) => <Icon component={svg} {...props} />;

export default ErrorIcon;
