import React from 'react';
import PropTypes from 'prop-types';

import PageHeader from 'components/PageHeader';
import { UserActivityHeaderFilter } from './components';

import './UserActivityHeader.scss';

const UserActivityHeader = ({
  teamList,
  onChangeSearch,
  filterItems,
  onChangeTeamsFilter,
  onChangeFilter,
  onChangeDate,
  selectedDate,
  isTeamMember,
  userPlanType,
  searchValue,
}) => {
  return (
    <div className="activity-header">
      <PageHeader pageTitle="Activities" />
      <UserActivityHeaderFilter
        teamList={teamList}
        onChangeSearch={onChangeSearch}
        filterItems={filterItems}
        onChangeFilter={onChangeFilter}
        onChangeTeamsFilter={onChangeTeamsFilter}
        onChangeDate={onChangeDate}
        selectedDate={selectedDate}
        isTeamMember={isTeamMember}
        userPlanType={userPlanType}
        searchValue={searchValue}
      />
    </div>
  );
};

UserActivityHeader.defaultProps = {
  selectedDate: [],
  teamList: [],
  isTeamMember: false,
  userPlanType: '',
  searchValue: '',
};

UserActivityHeader.propTypes = {
  onChangeSearch: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  onChangeTeamsFilter: PropTypes.func.isRequired,
  selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  filterItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  teamList: PropTypes.arrayOf(PropTypes.shape({})),
  isTeamMember: PropTypes.bool,
  userPlanType: PropTypes.string,
  searchValue: PropTypes.string,
};

export default UserActivityHeader;
