import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.24061 9.18369C5.84375 9.62111 5.15625 9.62111 4.75939 9.18369L2.25374 6.42193C1.67073 5.77933 2.12669 4.75 2.99436 4.75L8.00564 4.75C8.87331 4.75 9.32927 5.77933 8.74625 6.42193L6.24061 9.18369Z"
      fill="#F03F3B"
    />
  </svg>
);

const DropdownIcon = (props) => <Icon component={svg} {...props} />;

export default DropdownIcon;
