/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_11320_14006)">
      <path
        d="M11 16.5V28C11 28.5523 11.4477 29 12 29H24.6337C24.8702 29 25.0991 29.0838 25.2796 29.2366L29.8541 33.1073C30.504 33.6572 31.5 33.1953 31.5 32.3439V30C31.5 29.4477 31.9477 29 32.5 29H36C36.5523 29 37 28.5523 37 28V13C37 12.4477 36.5523 12 36 12H27.25"
        stroke="#C7BCE4"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12 10.1429C12 9.70895 12.3518 9.35718 12.7857 9.35718H15.9286C16.3625 9.35718 16.7143 9.70895 16.7143 10.1429V13.2857C16.7143 13.7197 16.3625 14.0715 15.9286 14.0715H12.7857C12.3518 14.0715 12 13.7197 12 13.2857V10.1429Z"
        fill="#7876C1"
      />
      <path
        d="M18.2852 10.1429C18.2852 9.70895 18.6369 9.35718 19.0709 9.35718H22.2137C22.6477 9.35718 22.9994 9.70895 22.9994 10.1429V13.2857C22.9994 13.7197 22.6477 14.0715 22.2137 14.0715H19.0709C18.6369 14.0715 18.2852 13.7197 18.2852 13.2857V10.1429Z"
        fill="#7876C1"
      />
      <path
        d="M15.1423 7C14.3566 7.39286 12.7852 7.78571 12.7852 10.9286"
        stroke="#7876C1"
        strokeWidth="1.57143"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.4275 7C20.6417 7.39286 19.0703 7.78571 19.0703 10.9286"
        stroke="#7876C1"
        strokeWidth="1.57143"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="15" y="18" width="18" height="2" rx="1" fill="#C7BCE4" />
      <rect x="15" y="22" width="15" height="2" rx="1" fill="#C7BCE4" />
    </g>
    <defs>
      <filter
        id="filter0_d_11320_14006"
        x="0"
        y="0"
        width="48"
        height="48"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.72549 0 0 0 0 0.721569 0 0 0 0 0.839216 0 0 0 0.2 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11320_14006" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_11320_14006"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_11320_14006"
        x1="24"
        y1="2"
        x2="24"
        y2="42"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F5F6FA" />
      </linearGradient>
    </defs>
  </svg>
);

const TestimonialIcon = (props) => <Icon component={svg} {...props} />;

export default TestimonialIcon;
