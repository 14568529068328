/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame 2">
      <g id="Group 290">
        <g id="Ellipse 84">
          <mask id="path-1-inside-1_14043_26208" fill="white">
            <path d="M9.57812 16.6667C10.2685 16.6667 10.8281 16.2936 10.8281 15.8334H8.32812C8.32812 16.2936 8.88777 16.6667 9.57812 16.6667Z" />
          </mask>
          <path
            d="M9.57812 16.6667C10.2685 16.6667 10.8281 16.2936 10.8281 15.8334H8.32812C8.32812 16.2936 8.88777 16.6667 9.57812 16.6667Z"
            fill="white"
          />
          <path
            d="M10.8281 15.8334H11.8281V14.8334H10.8281V15.8334ZM8.32812 15.8334V14.8334H7.32812V15.8334H8.32812ZM9.82812 15.8334C9.82812 15.7382 9.85867 15.6643 9.8855 15.622C9.90962 15.584 9.92545 15.5785 9.90731 15.5906C9.86688 15.6175 9.75341 15.6667 9.57812 15.6667V17.6667C10.0932 17.6667 10.6047 17.5293 11.0167 17.2547C11.4217 16.9847 11.8281 16.5019 11.8281 15.8334H9.82812ZM9.57812 15.6667C9.40284 15.6667 9.28937 15.6175 9.24894 15.5906C9.2308 15.5785 9.24663 15.584 9.27075 15.622C9.29758 15.6643 9.32812 15.7382 9.32812 15.8334H7.32812C7.32812 16.5019 7.73455 16.9847 8.13954 17.2547C8.55152 17.5293 9.06306 17.6667 9.57812 17.6667V15.6667ZM8.32812 16.8334H10.8281V14.8334H8.32812V16.8334Z"
            fill="#05034D"
            mask="url(#path-1-inside-1_14043_26208)"
          />
        </g>
        <rect
          id="Rectangle 83"
          x="8.82812"
          y="3.83337"
          width="1.5"
          height="1.5"
          rx="0.75"
          fill="white"
          stroke="#05034D"
        />
        <path
          id="Rectangle 81"
          d="M14.2917 12.9687V13.1178L14.3733 13.2425L15.4041 14.8174C15.5492 15.0391 15.3902 15.3333 15.1252 15.3333H4.04143C3.77647 15.3333 3.61742 15.0391 3.76253 14.8174L4.79335 13.2425L4.875 13.1178V12.9687V10.1041V9.37496C4.875 6.77462 6.98299 4.66663 9.58333 4.66663C12.1837 4.66663 14.2917 6.77462 14.2917 9.37496V10.1041V12.9687Z"
          fill="white"
          stroke="#05034D"
        />
        <rect
          id="Rectangle 82"
          x="6.88021"
          y="12.7083"
          width="5.41667"
          height="0.416667"
          rx="0.208333"
          fill="white"
          stroke="#05034D"
          stroke-width="0.416667"
        />
      </g>
    </g>
  </svg>
);

const ReminderIcon = (props) => <Icon component={svg} {...props} />;

export default ReminderIcon;
