import getRaw from './getRaw';

const getOverview = ({ prop, configText }) => {
  const title = configText('section overview title');
  let text = '';

  text += prop.client && prop.client.desc ? `<div>${prop.client.desc}</div>` : '';
  text += prop.project && prop.project.desc ? `<div>${prop.project.desc}</div>` : '';
  text += prop.project && prop.project.goals ? `<div>${prop.project.goals}</div>` : '';
  text = text || '<div>Write something about the client and the project</div>';

  return {
    title,
    rawtitle: getRaw(title),
    subrawtitle: getRaw(''),
    text,
    raw: getRaw(text)
  };
};

export default getOverview;