import React, { useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useMutation, useReactiveVar } from '@apollo/client';
import { message } from 'antd';

import Loader from 'components/Loader/Loader';
import utils from 'utils/utils';
import Path from 'routes/path';
import { userVar, getUserId } from 'graphql/cache';
import { CONNECT_WIX, CONNECT_NEW_WIX_USER } from 'graphql/mutations/integrationMutation';

const WixWindow = ({ history }) => {
  let user = useReactiveVar(userVar);

  if (utils.getQueryStringValue('close')) {
    window.close();
  }

  const handleWixConnection = useCallback(
    (connectionData) => {
      sessionStorage.removeItem('wixConnected');
      sessionStorage.removeItem('redirect-url');
      sessionStorage.removeItem('wix-state');
      message.success('Wix Connected Successfully');

      const wixWebConnection = sessionStorage.getItem('wix-web-connection');

      if (connectionData?.connectWix?.access_token) {
        const windowTab = window.open(
          `https://www.wix.com/installer/close-window?access_token=${connectionData.connectWix.access_token}`,
          wixWebConnection ? '_self' : '_blank'
        );

        if (!wixWebConnection) {
          const windowTimer = setInterval(() => {
            if (
              windowTab?.location?.href?.indexOf('https://www.wix.com/installer/close-window') < 0
            ) {
              clearInterval(windowTimer);
              windowTab.location.href = `${window.location.href}?close=true`;
              history.push(`${Path.INTEGRATION}?wix=connected`);
            } else {
              history.push(`${Path.INTEGRATION}?wix=connected`);
            }
          }, 500);
        } else {
          const windowTimer = setInterval(() => {
            if (window?.location?.href.indexOf('https://www.wix.com/installer/close-window') < 0) {
              clearInterval(windowTimer);
              windowTab.location.href = `${window.location.href}?close=true`;
            }
          }, 500);
          sessionStorage.setItem('wix-web-connection', false);
        }
      } else {
        window.close();
      }
    },
    [history]
  );

  const [connectWix] = useMutation(CONNECT_WIX, {
    skip: !getUserId() || !window.location.href,
    onCompleted: handleWixConnection,
    onError: () => {
      sessionStorage.removeItem('wixConnected');
      sessionStorage.removeItem('redirect-url');
      message.error('Wix Connect Failed');

      const wixWebConnection = sessionStorage.getItem('wix-web-connection');

      wixWebConnection && sessionStorage.setItem('wix-web-connection', false) && window.close();
    },
  });

  const [connectNewWixUser] = useMutation(CONNECT_NEW_WIX_USER, {
    skip: !window.location.href,
    onCompleted: (data) => {
      const { connectNewWixUser } = data;

      if (connectNewWixUser) {
        // window.ga('send', 'event', 'registeration', 'sign up to trial', 'freetrial');
        // window.ga('send', 'pageview', '/event/sign-up');
        // window.gtag('event', 'conversion', { send_to: 'AW-848665564/735ACKCqsJEDENy31pQD' });
        // window.localStorage.setItem('prospero-token', token);
        // window.localStorage.setItem('prospero-user-id', user._id);

        // trackEvent('sign-up');
        // updateIntercomUser(user, update);

        // isLoggedInVar(true);
        console.log(connectNewWixUser, '=-=-=connectNewWixUser');
        window.open(
          `https://www.wix.com/installer/close-window?access_token=${connectNewWixUser}`,
          '_self'
        );
      }
    },
    onError: (err) => {
      console.log(err);
      message.error('Wix Connect Failed');
    },
  });

  useEffect(() => {
    const wixToken = utils.getQueryStringValue('token');
    const wixCode = utils.getQueryStringValue('code');
    const wixInstanceId = utils.getQueryStringValue('instanceId');
    const wixState = utils.getQueryStringValue('state');

    if (wixToken) {
      const wixNewState = sessionStorage.getItem('wix-state') || uuidv4();
      sessionStorage.setItem('wix-state', wixNewState);

      window.location.href = `https://www.wix.com/installer/install?token=${wixToken}&appId=${process.env.REACT_APP_WIX_APP_ID}&redirectUrl=${process.env.REACT_APP_WIX_REDIRECT_URI}&state=${wixNewState}`;
    } else if (wixCode && wixInstanceId && wixState === sessionStorage.getItem('wix-state')) {
      if (!user) {
        connectNewWixUser({
          variables: {
            wixCode,
            wixInstanceId,
          },
        });
      } else {
        const wixInfo = user?.wix?.find((wixInstance) => wixInstance.instanceId === wixInstanceId);
        if (wixInfo) {
          handleWixConnection();
        } else {
          connectWix({
            variables: {
              wixCode,
              wixInstanceId,
            },
          });
        }
      }
    } else {
      const wixNewState = sessionStorage.getItem('wix-state') || uuidv4();
      sessionStorage.setItem('wix-state', wixNewState);

      window.open(
        `https://www.wix.com/installer/install?appId=${process.env.REACT_APP_WIX_APP_ID}&state=${wixNewState}&redirectUrl=${process.env.REACT_APP_WIX_REDIRECT_URI}`,
        '_self'
      );
    }
  }, [user, connectWix, connectNewWixUser, handleWixConnection]);

  return <Loader />;
};

export default WixWindow;
