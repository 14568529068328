import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 10.6957L11 2M11 2L20 10.6957M11 2V22"
      stroke="#7876C1"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ArrowTop = (props) => <Icon component={svg} {...props} />;

export default ArrowTop;
