export const scopeStylesInHTML = (htmlString, scopeClass = '.section-wrapper') => {
  // Create a DOM parser to work with the HTML string
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Find all <style> tags in the HTML
  const styleTags = doc.querySelectorAll('style');

  styleTags.forEach((styleTag) => {
    let cssText = styleTag.textContent;

    // Add the scope class only if it doesn't already exist in the selector
    cssText = cssText.replace(/([^\r\n,{}]+)(?=\s*{)/g, (selector) => {
      const trimmedSelector = selector.trim();

      // Skip @-rules and empty selectors
      if (trimmedSelector.startsWith('@') || trimmedSelector === '') {
        return selector;
      }

      // Only prepend the scope class if it isn't already present
      if (!trimmedSelector.startsWith(scopeClass) && !trimmedSelector.includes(` ${scopeClass}`)) {
        return `${scopeClass} ${trimmedSelector}`;
      }

      return selector; // Return as-is if already scoped
    });

    // Replace the style content with the updated scoped CSS
    styleTag.textContent = cssText;
  });

  // Serialize the modified HTML back to a string
  return doc.documentElement.outerHTML;
};
