import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="49" height="48" viewBox="0 0 49 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M4.94727 6C4.94727 3.79086 6.73813 2 8.94727 2H40.9473C43.1564 2 44.9473 3.79086 44.9473 6V38C44.9473 40.2091 43.1564 42 40.9473 42H8.94727C6.73813 42 4.94727 40.2091 4.94727 38V6Z"
        fill="white"
      />
      <path
        d="M4.94727 6C4.94727 3.79086 6.73813 2 8.94727 2H40.9473C43.1564 2 44.9473 3.79086 44.9473 6V38C44.9473 40.2091 43.1564 42 40.9473 42H8.94727C6.73813 42 4.94727 40.2091 4.94727 38V6Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M27.748 10H17.1973C15.6785 10 14.4473 11.2312 14.4473 12.75V33.8673C14.4473 35.3861 15.6785 36.6173 17.1973 36.6173H32.6973C34.216 36.6173 35.4473 35.3861 35.4473 33.8673V17.6852C35.4473 16.9545 35.1565 16.2538 34.639 15.7379L29.6897 10.8027C29.1742 10.2887 28.476 10 27.748 10Z"
        fill="#C7BCE4"
      />
      <mask id="mask0" maskUnits="userSpaceOnUse" x="14" y="10" width="22" height="28">
        <path
          d="M27.7519 10H17.1973C15.6785 10 14.4473 11.2312 14.4473 12.75V35.25C14.4473 36.7688 15.6785 38 17.1973 38H32.6973C34.216 38 35.4473 36.7688 35.4473 35.25V17.657C35.4473 16.9239 35.1546 16.2212 34.6342 15.7049L29.6889 10.7979C29.1738 10.2868 28.4776 10 27.7519 10Z"
          fill="#7876C1"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M29.1926 15.7388L29 10.2131L35.3384 16.5073L30.0272 16.6924C29.5852 16.7078 29.2115 16.2809 29.1926 15.7388Z"
          fill="#7876C1"
        />
        <path
          d="M28.9473 23H20.9473C20.395 23 19.9473 23.4477 19.9473 24V25C19.9473 25.5523 20.395 26 20.9473 26H28.9473C29.4996 26 29.9473 25.5523 29.9473 25V24C29.9473 23.4477 29.4996 23 28.9473 23Z"
          fill="#7876C1"
        />
        <path
          d="M26.4473 28.5V20.5C26.4473 19.9477 25.9996 19.5 25.4473 19.5H24.4473C23.895 19.5 23.4473 19.9477 23.4473 20.5V28.5C23.4473 29.0523 23.895 29.5 24.4473 29.5H25.4473C25.9996 29.5 26.4473 29.0523 26.4473 28.5Z"
          fill="#7876C1"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0.947266"
        y="0"
        width="48"
        height="48"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.72549 0 0 0 0 0.721569 0 0 0 0 0.839216 0 0 0 0.2 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="24.9473"
        y1="2"
        x2="24.9473"
        y2="42"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F5F6FA" />
      </linearGradient>
    </defs>
  </svg>
);

const AddFileIcon = (props) => <Icon component={svg} {...props} />;

export default AddFileIcon;
