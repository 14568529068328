import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="54" height="50" viewBox="0 0 54 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_14427_21499)">
      <g clipPath="url(#clip1_14427_21499)">
        <path
          d="M14.2571 3.58496L3.85085 6.28006C2.08652 6.737 1.02667 8.53769 1.48361 10.302L10.5855 45.446C11.0424 47.2103 12.8431 48.2701 14.6075 47.8132L30.3868 43.7265C32.1511 43.2696 33.211 41.4689 32.754 39.7046L25.5924 12.0521C25.4194 11.3841 25.0414 10.7872 24.5115 10.3452L17.1982 4.24536C16.3815 3.56419 15.2866 3.31834 14.2571 3.58496Z"
          fill="#5D5AB5"
        />
        <path
          d="M29.0922 1.01855H11.5031C9.68059 1.01855 8.20312 2.49602 8.20312 4.31856V39.7186C8.20312 41.5411 9.68058 43.0186 11.5031 43.0186H37.3031C39.1257 43.0186 40.6031 41.5411 40.6031 39.7186V12.6921C40.6031 11.8281 40.2643 10.9986 39.6594 10.3817L31.4484 2.0081C30.8278 1.37518 29.9787 1.01855 29.0922 1.01855Z"
          fill="#7876C1"
        />
        <rect x="12.4062" y="18.8184" width="19.2" height="2.4" rx="1.2" fill="#C7BCE4" />
        <rect x="12.4062" y="23.418" width="9.6" height="2.4" rx="1.2" fill="#C7BCE4" />
        <circle cx="16.0063" cy="10.018" r="3.6" fill="#C7BCE4" />
        <mask id="mask0_14427_21499" maskUnits="userSpaceOnUse" x="8" y="0" width="33" height="46">
          <path
            d="M28.8939 0.818359H11.3C9.47746 0.818359 8 2.29582 8 4.11836V41.7002C8 43.5227 9.47746 45.0002 11.3 45.0002H37.1C38.9225 45.0002 40.4 43.5227 40.4 41.7002V12.4459C40.4 11.5791 40.0589 10.7471 39.4505 10.1297L31.2444 1.80211C30.6242 1.17273 29.7775 0.818359 28.8939 0.818359Z"
            fill="#7876C1"
          />
        </mask>
        <g mask="url(#mask0_14427_21499)">
          <path
            d="M30.2812 9.44181V-3.29297L46.4813 11.0918H31.9313C31.02 11.0918 30.2812 10.3531 30.2812 9.44181Z"
            fill="#C7BCE4"
          />
        </g>
      </g>
    </g>
    <path
      d="M43.6595 46.2258C41.8903 46.769 40.0557 45.9055 39.5619 44.2971L45.9688 42.3301C46.4626 43.9384 45.4287 45.6826 43.6595 46.2258Z"
      fill="#FFCBC6"
    />
    <path
      d="M35.3894 27.7088C35.1352 27.4123 34.958 27.0577 34.8735 26.6764C34.789 26.295 34.7999 25.8988 34.9052 25.5226C35.0105 25.1465 35.2069 24.8022 35.477 24.5201C35.7472 24.238 36.0827 24.0269 36.4539 23.9055C36.8251 23.7841 37.2206 23.7561 37.6052 23.8239C37.9898 23.8918 38.3517 24.0536 38.6589 24.2947C38.9661 24.5359 39.2091 24.8492 39.3664 25.2067C39.5236 25.5642 39.5902 25.955 39.5603 26.3444L37.1923 26.1628L35.3894 27.7088Z"
      fill="#FFCBC6"
    />
    <path
      d="M47.0376 31.2885L49.0232 37.7557L51.5998 40.2545C52.032 40.6737 51.8566 41.4029 51.281 41.5796L34.1641 46.8349C33.5885 47.0117 33.0341 46.5066 33.1567 45.917L33.8872 42.4029L31.9016 35.9357C30.6183 31.756 32.9663 27.3274 37.146 26.0441C41.3257 24.7608 45.7543 27.1088 47.0376 31.2885Z"
      fill="#FF9492"
    />
    <rect
      width="9.74837"
      height="1.82782"
      rx="0.913909"
      transform="matrix(-0.955958 0.293505 0.293505 0.955958 45.9766 38.1846)"
      fill="#FFCBC6"
    />
    <path
      d="M46.4534 25.9116C47.4799 26.4133 49.4514 28.2091 49.1255 31.3783"
      stroke="#FFCBC6"
      stroke-width="1.15818"
      stroke-linecap="round"
    />
    <path
      d="M48.1979 23.5611C49.6644 24.2779 52.4808 26.8432 52.0152 31.3707"
      stroke="#FFCBC6"
      stroke-width="1.15818"
      stroke-linecap="round"
    />
    <defs>
      <clipPath id="clip0_14427_21499">
        <rect width="53" height="48" fill="white" transform="translate(0 0.277344)" />
      </clipPath>
      <clipPath id="clip1_14427_21499">
        <rect width="48" height="48" fill="white" transform="translate(1 -0.181641)" />
      </clipPath>
    </defs>
  </svg>
);

const EmailNotebookReminderIcon = (props) => <Icon component={svg} {...props} />;

export default EmailNotebookReminderIcon;
