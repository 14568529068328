import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="17" viewBox="0 0 36 17" fill="none">
    <g clip-path="url(#clip0_14134_21712)">
      <path
        d="M4.93223 12.2598C15.9271 13.3715 25.5952 10.6601 34.5255 4.41124C33.5932 3.88259 33.0706 4.34405 32.6489 4.62232C29.3654 6.63006 25.7244 7.82387 22.1296 9.04234C16.9512 10.8067 11.6404 11.0771 6.21733 10.5758C5.98253 10.5688 5.7625 10.4806 5.25114 10.3255C7.56506 9.10333 10.2757 9.22005 12.1226 7.59963C12.0452 7.46926 11.993 7.29308 11.9156 7.1627C10.2798 7.53238 8.60846 7.91262 6.9623 8.24706C5.31614 8.5815 3.69519 8.87015 2.05947 9.23982C0.862034 9.51796 0.529925 10.2296 1.27097 11.1599C2.21293 12.3756 3.33749 13.4222 4.40118 14.5252C4.51425 14.645 4.67341 14.7895 4.8369 14.8177C5.27085 14.8424 5.67348 14.7615 6.13264 14.7404C6.09954 14.3669 6.16297 13.9265 6.03336 13.62C5.83677 13.2183 5.4828 12.9399 4.93223 12.2598Z"
        fill="#F03F3B"
      />
    </g>
    <defs>
      <clipPath id="clip0_14134_21712">
        <rect
          width="33.9594"
          height="7.03822"
          fill="white"
          transform="matrix(-0.958798 0.28409 0.28409 0.958798 33.2188 0.000976562)"
        />
      </clipPath>
    </defs>
  </svg>
);

const ComingUpIcon = (props) => <Icon component={svg} {...props} />;

export default ComingUpIcon;
