import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CopyToClipboard from 'react-copy-to-clipboard';

import { Row, Col, Image, Form, Input, Button, Divider } from 'antd';

import LinkWhiteIcon from 'components/Icons/LinkWhite';
import MailIcon from 'components/Icons/MailIcon';
import LinkedinIcon from 'components/Icons/LinkedinIcon';
import TwitterIcon from 'components/Icons/TwitterIcon';
import FacebookIcon from 'components/Icons/FacebookIcon';
import images from 'constants/images';

const InfoTable = ({
  user,
  credits,
  paypalId,
  loadingPaypal,
  affiliateUrl,
  savePaypal,
  requestPayment,
  loadingRequestPayment,
}) => {
  const [copied, setCopied] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      'PayPal Address': paypalId,
    });
  }, [paypalId, form]);

  const shareViaMail = () => {
    const subject = 'Invitation%20to%20Join%20Prospero';
    const emailBody = `Hi%20,%0A%20Use%20following%20link%20to%20join%20Prospero%20${affiliateUrl}`;
    window.open(`mailto:?subject=${subject}&body=${emailBody}`, '_blank');
  };

  const shareViaLinkedIn = () => {
    window.open(
      `https://www.linkedin.com/shareArticle?mini=true&url=https%3A//www.goprospero.com/?affiliate=${user.affiliateId}`,
      'popUpWindow',
      'height=400,width=600,left=10,top=10,scrollbars=yes,menubar=no'
    );
  };

  const shareViaTwitter = () => {
    window.open(
      `https://twitter.com/home?status=https%3A//www.goprospero.com/?affiliate=${user.affiliateId}`,
      'popUpWindow',
      'height=400,width=600,left=10,top=10,scrollbars=yes,menubar=no'
    );
  };

  const shareViaFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=https%3A//www.goprospero.com/?affiliate=${user.affiliateId}`,
      'popUpWindow',
      'height=400,width=600,left=10,top=10,scrollbars=yes,menubar=no'
    );
  };

  const validateMessages = {
    required: 'PayPal Address is required!',
    types: {
      paypalId: 'Not a valid PayPal address!',
    },
  };

  return (
    <div className="affiliate-main-content">
      <Row className="checkout">
        <Col xs={2} sm={24} md={24} lg={10} xl={9} xxl={6} className="checkout-left">
          <h3>Balance</h3>
          <p>Current balance</p>
          {credits >= 100 ? (
            <h1 className="payout-active">${credits ? credits.toFixed(2) : 0}</h1>
          ) : (
            <h1>${credits ? credits.toFixed(2) : 0}</h1>
          )}

          <Button
            disabled={credits < 100}
            className={credits < 100 ? '' : 'payout-active'}
            loading={loadingRequestPayment}
            onClick={requestPayment}>
            {credits < 100 ? 'Request payment from 100$' : 'Payment is available now'}
          </Button>
        </Col>
        <Col xs={2} sm={24} md={24} lg={1} xl={1} xxl={1} className="checkout-divider">
          <Divider />
        </Col>
        <Col xs={2} sm={24} md={24} lg={13} xl={14} xxl={17} className="checkout-right">
          <div className="heading-bar">
            <h3>Provide paypal account</h3>
            <Image src={images.PAYPAL_LOGO} />
          </div>

          <Form
            initialValues={{
              'PayPal Address': paypalId,
            }}
            form={form}
            layout="vertical"
            validateMessages={validateMessages}
            onFinish={savePaypal}>
            <Form.Item
              colon={false}
              name={['PayPal Address']}
              label="Your PayPal Address"
              rules={[{ type: 'email' }]}>
              <Input placeholder="Your PayPal address" className="email-input" />
            </Form.Item>
            <Button
              loading={loadingPaypal}
              className="update-btn"
              type="default"
              htmlType="submit"
              size="large">
              UPDATE ACCOUNT
            </Button>
          </Form>
        </Col>
      </Row>
      <Row className="link">
        <Row className="heading">
          <h3>Your unique affiliate link</h3>
          <span>
            <strong>Get 25%</strong> lifetime commission on any new client that registered via your
            affiliate link
          </span>
        </Row>
        <Row className="link-input-wrapper">
          <Input value={affiliateUrl} className="affiliate-link" />
          <CopyToClipboard
            text={affiliateUrl}
            onCopy={() => {
              setCopied(true);
              setTimeout(() => {
                setCopied(false);
              }, 2000);
            }}>
            <Button className="affiliate-btn">
              <span className="link-text">{copied ? 'COPIED' : 'COPY LINK'}</span>
              <span className="icon-wrapper">
                <LinkWhiteIcon />
              </span>
            </Button>
          </CopyToClipboard>
        </Row>
      </Row>
      <Row className="social-share">
        <Row className="heading">
          <h3>Total referrals and commission</h3>
        </Row>
        <Row className="social-icon-grp">
          <Col xs={12} lg={6}>
            <div className="icon-wrapper" onClick={shareViaMail}>
              <MailIcon />
              <p>Share via email</p>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="icon-wrapper" onClick={shareViaLinkedIn}>
              <LinkedinIcon />
              <p>Share on Linkedin</p>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="icon-wrapper" onClick={shareViaTwitter}>
              <TwitterIcon />
              <p>Share via Twitter</p>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="icon-wrapper" onClick={shareViaFacebook}>
              <FacebookIcon />
              <p>Share via Facebook</p>
            </div>
          </Col>
        </Row>
      </Row>
    </div>
  );
};

InfoTable.defaultProps = {
  credits: 0,
  yearlyAffiliates: [],
  paypalId: '',
  loadingPaypal: false,
  loadingRequestPayment: false,
  affiliateUrl: '',
};

InfoTable.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  credits: PropTypes.number,
  paypalId: PropTypes.string,
  loadingPaypal: PropTypes.bool,
  loadingRequestPayment: PropTypes.bool,
  affiliateUrl: PropTypes.string,
  savePaypal: PropTypes.func.isRequired,
  requestPayment: PropTypes.func.isRequired,
};

export default InfoTable;
