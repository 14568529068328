import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 7L7 10L4 7"
      stroke="#05034D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const DecrementIcon = (props) => <Icon component={svg} {...props} />;

export default DecrementIcon;
