/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 1000 1000"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#0075DE"
      d="M1000,576.239c0-5.131-0.097-10.238-0.277-15.325l0,0V0H423.239
	C189.203,0-0.521,189.724-0.521,423.761V1000H587v-0.135C816.064,994.155,1000,806.678,1000,576.239z"
    />
    <path
      fill="#FFFFFF"
      d="M707.754,129.649c-0.646-0.005-1.265-0.022-1.921-0.023
	c-16.888-0.042-33.788,0.401-50.665-0.075c-72.536-2.031-134.928,16.835-174.186,90.174c-1.007-31.924,0.77-52.527-0.416-65.834
	v-24.778H304.087v731.334h176.479c0-67.423,0-284.431,0-284.431h202.98V425.886h-202.98v-0.546V321.29h140.185
	c69.173-2.189,126.745-46.152,144.407-108.933c4.032-14.336,6.419-26.396,6.994-36.508h0.154v-46.201H707.754z"
    />
  </svg>
);

const FreshbookIcon = (props) => <Icon component={svg} {...props} />;

export default FreshbookIcon;
