/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.6149 0.400146H1.5298C0.906045 0.400146 0.400391 0.9058 0.400391 1.52956V18.4707C0.400391 19.0945 0.906044 19.6001 1.5298 19.6001H18.471C19.0947 19.6001 19.6004 19.0945 19.6004 18.4707V5.38561C19.6004 5.08607 19.4814 4.7988 19.2696 4.587L15.4135 0.730944C15.2017 0.519138 14.9145 0.400146 14.6149 0.400146Z" fill="#C9C8E6" />
    <path d="M4.91602 0.400146H12.8219V3.78838C12.8219 4.41214 12.3162 4.91779 11.6925 4.91779H6.04543C5.42167 4.91779 4.91602 4.41214 4.91602 3.78838V0.400146Z" fill="#7876C1" />
    <path d="M7 12C7 10.344 8.34395 9 10 9C11.656 9 13 10.344 13 12C13 13.656 11.656 15 10 15C8.34395 15 7 13.656 7 12Z" stroke="#7876C1" strokeWidth="2" />
  </svg>
);

const SaveIcon = (props) => <Icon component={svg} {...props} />;

export default SaveIcon;
