import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="48" height="48" viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M4 6C4 3.79086 5.79086 2 8 2H40C42.2091 2 44 3.79086 44 6V38C44 40.2091 42.2091 42 40 42H8C5.79086 42 4 40.2091 4 38V6Z"
        fill="white"
      />
      <path
        d="M4 6C4 3.79086 5.79086 2 8 2H40C42.2091 2 44 3.79086 44 6V38C44 40.2091 42.2091 42 40 42H8C5.79086 42 4 40.2091 4 38V6Z"
        fill="url(#paint0_linear)"
      />
      <rect x="10" y="12" width="28" height="20" rx="2" stroke="#C7BCE4" strokeWidth="2" />
      <path
        d="M27.8343 22.8597C28.3957 22.4374 28.3581 21.5831 27.7619 21.2117L22.5287 17.9522C21.8626 17.5373 21 18.0162 21 18.801L21 25.9966C21 26.8213 21.942 27.2915 22.6011 26.7958L27.8343 22.8597Z"
        fill="#7876C1"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="48"
        height="48"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.72549 0 0 0 0 0.721569 0 0 0 0 0.839216 0 0 0 0.2 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="24"
        y1="2"
        x2="24"
        y2="42"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F5F6FA" />
      </linearGradient>
    </defs>
  </svg>
);

const VideoIcon = (props) => <Icon component={svg} {...props} />;

export default VideoIcon;
