import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#F5F6FA" />
    <rect
      x="18"
      y="13"
      width="12"
      height="2"
      rx="0.999999"
      transform="rotate(-180 18 13)"
      fill="#05034D"
    />
  </svg>
);
const HyphenRoundIcon = (props) => <Icon component={svg} {...props} />;

export default HyphenRoundIcon;
