import React, { useEffect, useState } from 'react';
import { Col, Row, Button as AntdButton, Input } from 'antd';
import { useMutation, useReactiveVar } from '@apollo/client';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { userVar } from 'graphql/cache';
import { UPDATE_SCRIPT } from 'graphql/mutations/integrationMutation';
import DeleteIcon from 'components/Icons/DeleteIcon';
import CodeIntegrationModals from './CodeIntegrationModals/CodeIntegrationModals';
import images from 'constants/images';

import './CodeIntegration.scss';

const INTEGRATION_BUTTON_TYPES = {
  connected: {
    text: 'connected',
    color: '#01c34f !important',
    borderLeftColor: '#01c34f !important',
    background: '#dcf6e7 !important',
    border: '#dcf6e7 !important',
  },

  disconnected: {
    text: 'connect',
    borderLeftColor: '#f03f3b !important',
    color: '#C4C4C4 !important',
    background: '#FFFFFF !important',
    border: '#C4C4C4 !important',
  },
};

const Button = styled(AntdButton)`
  color: ${(props) => INTEGRATION_BUTTON_TYPES[props.status].color};
  background: ${(props) => INTEGRATION_BUTTON_TYPES[props.status].background};
  border: ${(props) => `1px solid ${INTEGRATION_BUTTON_TYPES[props.status].border}`};
  &:hover {
    color: ${(props) => INTEGRATION_BUTTON_TYPES[props.status].color};
    border: ${(props) => `1px solid ${INTEGRATION_BUTTON_TYPES[props.status].border} !important`};
    background: ${(props) => INTEGRATION_BUTTON_TYPES[props.status].background};
  }
  a:hover span {
    display: none;
  }
`;

// const Item = styled(Row)`
//   border-left: ${() => `4px solid #01c34f`};
// `;
const Item = styled(Row)`
  border-left: ${(props) => `4px solid ${INTEGRATION_BUTTON_TYPES[props.status].borderLeftColor}`};
`;

const CodeIntegration = ({ data, isModalItem, userRole, saveProposal }) => {
  const user = useReactiveVar(userVar);

  const [integrationData, setIntegrationData] = useState(data);
  const [showDeleteModalIndex, setShowDeleteModalIndex] = useState(-1);
  const [showAddModal, setShowAddModal] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);

  useEffect(() => {
    if (data) {
      setIntegrationData(data);
    }
  }, [data]);

  const [updateScript] = useMutation(UPDATE_SCRIPT, {
    onCompleted: (data) => {
      if (data?.updateScript) {
        let userInfo = JSON.parse(JSON.stringify(user));
        userInfo['scripts'] = data?.updateScript;
        userVar({ ...user, ...userInfo });
      }
    },
  });

  const updateCodeIntegration = (scripts) => {
    if (isModalItem) {
      saveProposal({
        scripts,
      });
    } else {
      updateScript({
        variables: {
          scripts,
        },
      });
    }
  };

  const isIntegrationButtonDisabled = userRole === 'editor' && !isModalItem;
  const status = integrationData && integrationData.length ? 'connected' : 'disconnected';

  return (
    <Item
      className="integration-item integration-item-code"
      status={status}
      align-items="center"
      justify="center">
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: isModalItem ? 12 : 7 }}
        xl={{ span: isModalItem ? 10 : 6 }}>
        <div className="item-brand-name">
          <img src={images.CODES_ICON} alt="prospero-codes" />
          <span className="item-name">3rd Party Code</span>
        </div>

        {isModalItem &&
          (integrationData && integrationData.length ? (
            <span className="item-status-description">
              The integration is&nbsp;
              <span className="item-active">active</span>
            </span>
          ) : (
            <span className="item-status-description">
              {' '}
              Use it for Google Analytics, Hotjar, Live chat codes, Poptin or any other tool
            </span>
          ))}
      </Col>

      {!isModalItem && (
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
          xl={{ span: 10 }}
          className="item-description">
          {integrationData && integrationData.length ? (
            <>
              The integration is&nbsp;<span className="item-active">active</span>
            </>
          ) : (
            <>Use it for Google Analytics, Hotjar, Live chat codes, Poptin or any other tool</>
          )}
        </Col>
      )}
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: isModalItem ? 12 : 9 }}
        xl={{ span: isModalItem ? 14 : 8 }}
        className="value">
        <a
          href="https://support.goprospero.com/article/show/140753-how-to-connect-with-third-party-integrations"
          className="integration-link"
          target="_blank"
          rel="noreferrer">
          Integration guide
        </a>

        <Button
          onClick={() => {
            setShowAddModal(true);
          }}
          className="integration-button"
          status={status}
          disabled={isIntegrationButtonDisabled}>
          {integrationData && integrationData.length
            ? INTEGRATION_BUTTON_TYPES.connected.text
            : INTEGRATION_BUTTON_TYPES.disconnected.text}
        </Button>
      </Col>
      {integrationData?.length ? (
        <div className="code-list">
          {integrationData.map((el, index) =>
            editIndex === index ? (
              <div key={index} className="edit-code-wrapper">
                <Input.TextArea
                  value={el}
                  onChange={(e) => {
                    let integrationDataInfo = [...integrationData];
                    integrationDataInfo[index] = e.target.value;
                    setIntegrationData([...integrationDataInfo]);
                  }}
                  className="edit-code"
                />
                <div className="action-btn-wrapper">
                  <AntdButton
                    className="integration-action save-btn"
                    onClick={() => {
                      setEditIndex(-1);
                      updateCodeIntegration(integrationData);
                    }}>
                    Save
                  </AntdButton>
                  <AntdButton
                    className="integration-action delete-btn"
                    onClick={() => {
                      setShowDeleteModalIndex(index);
                    }}>
                    <DeleteIcon />
                  </AntdButton>
                </div>
              </div>
            ) : (
              <div key={index} className="code-items">
                <span className="code">{el}</span>
                <Row className="action-btn-wrapper">
                  <AntdButton
                    className="integration-action edit-btn"
                    onClick={() => {
                      setEditIndex(index);
                    }}>
                    Edit
                  </AntdButton>
                  <AntdButton
                    className="integration-action delete-btn"
                    onClick={() => {
                      setShowDeleteModalIndex(index);
                    }}>
                    <DeleteIcon />
                  </AntdButton>
                </Row>
              </div>
            )
          )}
        </div>
      ) : null}

      <CodeIntegrationModals
        showAddModal={showAddModal}
        setShowAddModal={setShowAddModal}
        updateCodeIntegration={updateCodeIntegration}
        integrationData={integrationData}
        showDeleteModalIndex={showDeleteModalIndex}
        setShowDeleteModalIndex={setShowDeleteModalIndex}
      />
    </Item>
  );
};

CodeIntegration.defaultProps = {
  data: [],
  isModalItem: false,
  userRole: '',
  saveProposal: () => {},
};

CodeIntegration.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.shape({
      stripe_user_id: PropTypes.string,
    }),
    PropTypes.instanceOf(Array),
  ]),
  isModalItem: PropTypes.bool,
  userRole: PropTypes.string,
  saveProposal: PropTypes.func,
};

export default CodeIntegration;
