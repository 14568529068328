import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import { Slider, Button, Row, Col } from 'antd';

const ImageEditor = ({
  optionList,
  imgContent,
  crop,
  rotation,
  zoom,
  opacity,
  activeWidth,
  setCrop,
  setRotation,
  onCropComplete,
  setZoom,
  setOpacity,
  setActiveWidth,
}) => {
  const [imageDefaultAspect, setImageDefaultAspect] = useState(0);

  return (
    <div>
      <div className="crop-container">
        <Cropper
          image={imgContent}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={
            activeWidth.width && activeWidth.height
              ? activeWidth.width / activeWidth.height
              : imageDefaultAspect
          }
          onCropChange={(value) => setCrop({ x: value.x || 0, y: value.y || 0 })}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          style={{ mediaStyle: { opacity } }}
          onMediaLoaded={(imageSize) =>
            imageSize?.naturalWidth &&
            imageSize?.naturalHeight &&
            setImageDefaultAspect(imageSize.naturalWidth / imageSize.naturalHeight)
          }
        />
      </div>
      <div className="image-action-wrapper">
        <Row gutter={16}>
          <Col className="gutter-row">
            <small className="image-crop-label">Zoom {zoom}</small>
            <Slider step={0.1} min={0.1} max={3} value={zoom} onChange={setZoom} />
          </Col>
          <Col className="gutter-row">
            <small className="image-crop-label">Rotation {rotation}</small>
            <Slider step={1} min={0} max={360} value={rotation} onChange={setRotation} />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row">
            <small className="image-crop-label">Opacity {opacity}</small>
            <Slider step={0.1} min={0.1} max={1} value={opacity} onChange={setOpacity} />
          </Col>
          <Col className="gutter-row">
            <div>
              <small className="image-crop-label">Crop</small>
            </div>
            <div className="crop-dimension-wrapper">
              {optionList.map((option) => (
                <Button
                  type={option.id === activeWidth.id ? 'primary' : 'default'}
                  key={option.id}
                  onClick={() => setActiveWidth(option)}>
                  {option.label}
                </Button>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ImageEditor;
