import React from 'react';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';

const DownloadPdfErrorModal = ({ downloadPDFError, onCancel }) => {
  return (
    <Modal
      className="simple-info-modal"
      centered
      visible
      footer={null}
      closeIcon={null}
      onCancel={onCancel}>
      <h3 className="title">Problem Downloading PDF</h3>
      <div className="divider" />

      <p className="body">
        Please try in a few minutes or contact support.
        <br />
        Error: {downloadPDFError}
      </p>

      <div className="button-wrapper">
        <Button className="button cancel" type="primary" onClick={onCancel}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

DownloadPdfErrorModal.propTypes = {
  downloadPDFError: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DownloadPdfErrorModal;
