// check in proposal
const keysToCheck = ['scripts', 'redirectTo', 'clientPayableLink'];
// check for entityMap of these
const raws = ['rawemail', 'rawname', 'rawtitle', 'rawsubtitle', 'raw', 'raw1', 'raw2', 'raw3'];
// no need to check these in draft
const skipInDraft = ['sectionorder', 'titleFont', 'bodyFont'];

const replaceContent = (content, url) => {
  return content.replaceAll(url, breakSpamUrl(url));
};

export const breakSpamUrl = (url) => {
  try {
    // convert www.google.com with www[dot]google[dot]com
    if (!url.startsWith('http')) {
      url = `http://${url}`;
    }

    const parsedUrl = new URL(url);
    const brokenHostname = parsedUrl.hostname.replace(/\./g, '[dot]');
    return parsedUrl.href.replace(parsedUrl.hostname, brokenHostname);
  } catch (err) {
    console.error('ERROR bad url', err);
  }
};

export const unbreakSpamUrl = (url) => {
  // convert www[dot]google[dot]com with www.google.com
  return url.replace(/\[dot\]/g, '.');
};

// const replaceBadUrlHtml = (html, badUrl) => {
//   const escapedBadUrl = badUrl.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
//   const pattern = new RegExp('<a\\s+(?:[^>]*?\\s+)?href=(["\'])(https?:\\/\\/' + escapedBadUrl + ')\\1[^>]*>.*?<\\/a>', 'gi');
//   return html.replace(pattern, breakSpamUrl(html,badUrl));
//   return html.replace(pattern, breakSpamUrl(badUrl));
// }

export const removeSpamUrls = (proposal, spamUrls, type = 'proposal') => {
  const spamUrlsKeys = Object.keys(spamUrls);

  if (!spamUrlsKeys?.length) {
    return proposal;
  }

  spamUrlsKeys.forEach((spamUrl) => {
    // no need to break whitelisted spam url
    // { url : true} (blocked true)
    // { url : false} (blocked false, i.e white listed)
    if (type === 'proposal' && spamUrls[spamUrl]) {
      proposal = proposalUrlReplacement(spamUrl, proposal);
    }
  });

  return proposal;
};

export const proposalUrlReplacement = (badUrl, proposal, where) => {
  // replace url
  if (!where || (where && where === 'draft')) {
    // loop all keys of draft
    Object.keys(proposal.draft).forEach((draftKey) => {
      // skip this keys
      if (skipInDraft.includes(draftKey)) {
        return;
      }

      // check for all types of raw.entityMap (eg. rawemail, raw, rawtitle)
      raws.forEach((rawkey) => {
        if (proposal.draft[draftKey]?.[rawkey]?.entityMap) {
          // findAndReplaceUrlInEntitymap(draftKey, rawkey);

          const { entityMap } = proposal.draft[draftKey][rawkey];
          if (entityMap) {
            Object.keys(entityMap).forEach((index) => {
              // for link
              // raw.entityMap.0.type === 'LINK'
              // raw.entityMap.0.data.url
              if (entityMap[index]?.type === 'LINK' && entityMap[index]?.data?.url === badUrl) {
                entityMap[index].data.url = breakSpamUrl(badUrl);
              }
              // for html
              // raw.entityMap.0.type === 'html'
              // raw.entityMap.0.data.htmlCode
              else if (entityMap[index]?.type === 'html' && entityMap[index]?.data?.htmlCode) {
                if (entityMap[index]?.data?.htmlCode.includes(badUrl)) {
                  entityMap[index].data.htmlCode = replaceContent(
                    entityMap[index].data.htmlCode,
                    badUrl
                  );
                }
              }
            });
            proposal.draft[draftKey][rawkey].entityMap = entityMap;
          }
        }
      });
    });
  }
  if (!where || (where && keysToCheck.includes(where))) {
    if (where === 'scripts' && proposal?.scripts && Array.isArray(proposal.scripts)) {
      // for array of scripts
      proposal.scripts = proposal.scripts.map((s) => replaceContent(s, badUrl));
    } else if (proposal[where]) {
      proposal[where] = breakSpamUrl(proposal[where], badUrl);
    }
  }

  return proposal;
};

export const getUrlHtml = (html) => {
  // get all links from html code
  const tempElement = document.createElement('div');
  tempElement.innerHTML = html;

  const anchorElements = tempElement.querySelectorAll('a');

  const urls = [];

  // Iterate through anchor elements and extract href URLs
  anchorElements.forEach((anchor) => {
    const href = anchor.getAttribute('href');
    if (href) {
      urls.push(href);
    }
  });

  return urls;
};

// convert array to object with url as key and blocked flag as value
export const formatSpamUrls = (spamUrls) => {
  if (!spamUrls) {
    return {};
  }
  return spamUrls.reduce((acc, s) => {
    acc[s.url] = s.blocked;
    return acc;
  }, {});
};
