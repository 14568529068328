import React from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/html-lint';

import './HTMLFormatter.scss';

const HTMLFormatter = ({ onChange, htmlCode, onSyntaxCheck }) => {
  return (
    <div className="html-formatter">
      <div className="editor-container">
        <CodeMirror
          value={htmlCode}
          options={{
            mode: 'htmlmixed',
            theme: 'default',
            lineNumbers: true,
            lint: true,
            lintOnChange: true,
          }}
          onBeforeChange={(editor, data, value) => {
            onChange(value);
            // onSyntaxCheck(editor.state.lint?.marked?.length === 0);
          }}
        />
      </div>
    </div>
  );
};

export default HTMLFormatter;
