import React, { useState, Suspense, lazy } from 'react';
import { useMutation } from '@apollo/client';
import { message } from 'antd';
import PropTypes from 'prop-types';

import { MANAGE_TEAM_MEMBERS } from 'graphql/mutations/usersTeamMutation';
import { userVar } from 'graphql/cache';

const CancelPlanModal = lazy(() => import('./CancelPlanModal/CancelPlanModal'));
const ChangePlanModal = lazy(() => import('./ChangePlanModal/ChangePlanModal'));
const AddUser = lazy(() => import('pages/UsersTeam/components/AddUser'));

const PlansInfoModals = ({
  user,
  plan,
  startPolling,
  stopPolling,
  usersTeam,
  refetchInvoices,
  refetchTeamMembers,
  showCancelModal,
  setShowCancelModal,
  showChangeModal,
  setShowChangeModal,
  showAddUserModal,
  setShowAddUserModal,
  setPlan,
  newUsersCount,
  pendingUsersTeam,
  // createPayPalSubscription,
  renewaldate,
  c_user_subtype,
  trial,
  trialTime,
  handleStripeClick,
  handleChangeCard,
  stripeLoading,
  refetchUser,
  setLoading,
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const handleManageTeamMembersResponse = (member, type, errorCallback) => {
    if (member.error) {
      message.error(
        `User ${member.user.emails[0].address} addtion failed: ${
          member.error.match(/E11000/) ? 'User already exists' : member.error
        }`
      );
      errorCallback();
    } else {
      message.success(
        `User ${member.user.emails[0].address} ${type === 'add' ? 'added' : 'removed'} successfully`
      );
    }
  };

  const [manageTeamMembers] = useMutation(MANAGE_TEAM_MEMBERS, {
    onCompleted: async ({ manageTeamMembers }) => {
      let error = false;

      const errorCallback = () => {
        error = true;
      };

      manageTeamMembers?.add?.forEach((member) =>
        handleManageTeamMembersResponse(member, 'add', errorCallback)
      );
      manageTeamMembers?.delete?.forEach((member) =>
        handleManageTeamMembersResponse(member, 'delete', errorCallback)
      );

      const paymentResponse = manageTeamMembers?.paymentResponse;

      if (paymentResponse?.error) {
        message.error(paymentResponse?.error);
        setIsSaving(false);
        return setPlan(user?.paymentStatus?.subType);
      } else if (paymentResponse?.link) {
        window.open(paymentResponse.link, '_self');
      }

      setTimeout(async () => {
        refetchTeamMembers();
        refetchInvoices();
        const updatedUser = await refetchUser();
        if (updatedUser?.data?.fetchUser) {
          userVar({ ...user, ...updatedUser?.data?.fetchUser });
          if (user?.paymentStatus?.subType && user?.paymentStatus?.subType !== plan) {
            message.success('Plan switched successfully');
          }
          if (!error) {
            setShowAddUserModal(false);
          }
        }
        setIsSaving(false);
      }, 10000);
    },
    onError: (error) => {
      setIsSaving(false);
      message.error(`User add failed: ${error.message}`);
    },
  });

  return (
    <>
      {showCancelModal && (
        <Suspense fallback={<div>Page is Loading...</div>}>
          <CancelPlanModal
            user={user}
            startPolling={startPolling}
            stopPolling={stopPolling}
            visible={showCancelModal}
            setShowCancelModal={setShowCancelModal}
            renewaldate={renewaldate}
            refetchUser={refetchUser}
            setLoading={setLoading}
          />
        </Suspense>
      )}

      {showChangeModal && (
        <Suspense fallback={<div>Page is Loading...</div>}>
          <ChangePlanModal
            user={user}
            // trial={trial}
            plan={plan}
            // startPolling={startPolling}
            stopPolling={stopPolling}
            visible={showChangeModal}
            setShowChangeModal={setShowChangeModal}
            // createPayPalSubscription={createPayPalSubscription}
            // renewaldate={renewaldate}
            c_user_subtype={c_user_subtype}
            handleStripeClick={handleStripeClick}
            handleChangeCard={handleChangeCard}
            stripeLoading={stripeLoading}
          />
        </Suspense>
      )}

      {showAddUserModal && (
        <Suspense fallback={<div>Page is Loading...</div>}>
          <AddUser
            isPlan={true}
            title={'MANAGE PLAN & USERS'}
            user={user}
            plan={plan}
            usersTeam={usersTeam}
            pendingUsersTeam={pendingUsersTeam}
            visible={showAddUserModal}
            isSaving={isSaving}
            setPlan={setPlan}
            newUsersCountFromTrial={newUsersCount}
            onCancel={() => setShowAddUserModal(false)}
            trial={trial}
            trialTime={trialTime}
            onConfirm={(price, { membersToBeAdded = [], membersToBeDeleted = [], planInfo }) => {
              setIsSaving(true);
              if (price) {
                handleStripeClick(price, {
                  membersToBeAdded,
                  membersToBeDeleted,
                  planInfo,
                });
              } else {
                manageTeamMembers({
                  variables: {
                    membersToBeAdded,
                    membersToBeDeleted,
                    planInfo,
                  },
                });
              }
            }}
          />
        </Suspense>
      )}
    </>
  );
};

PlansInfoModals.defaultProps = {
  showCancelModal: false,
  stripeLoading: false,
  showAddUserModal: false,
  refetchTeamMembers: () => {},
  refetchInvoices: () => {},
  startPolling: () => {},
  stopPolling: () => {},
  refetchUser: () => {},
  newUsersCount: 0,
  c_user_subtype: '',
  user: {},
  pendingUsersTeam: [],
  trialTime: 0,
  renewaldate: null,
};

PlansInfoModals.propTypes = {
  // createPayPalSubscription: PropTypes.func.isRequired,
  setShowChangeModal: PropTypes.func.isRequired,
  setShowAddUserModal: PropTypes.func.isRequired,
  setPlan: PropTypes.func.isRequired,
  user: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  usersTeam: PropTypes.instanceOf(Array),
  pendingUsersTeam: PropTypes.instanceOf(Array),
  startPolling: PropTypes.func,
  stopPolling: PropTypes.func,
  showCancelModal: PropTypes.bool,
  setShowCancelModal: PropTypes.func.isRequired,
  refetchTeamMembers: PropTypes.func,
  refetchInvoices: PropTypes.func,
  plan: PropTypes.string.isRequired,
  handleChangeCard: PropTypes.func.isRequired,
  stripeLoading: PropTypes.bool,
  showAddUserModal: PropTypes.bool,
  newUsersCount: PropTypes.number,
  c_user_subtype: PropTypes.string,
  trialTime: PropTypes.number,
  renewaldate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(null)]),
  refetchUser: PropTypes.func,
  setLoading: PropTypes.func.isRequired,
};

export default PlansInfoModals;
