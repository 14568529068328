import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.3" width="16" height="16" rx="8" fill="white" />
    <path
      d="M4 8C4 7.44772 4.44772 7 5 7H11C11.5523 7 12 7.44772 12 8C12 8.55228 11.5523 9 11 9H5C4.44772 9 4 8.55228 4 8Z"
      fill="white"
    />
    <rect x="9" y="4" width="8" height="2" rx="1" transform="rotate(90 9 4)" fill="white" />
  </svg>
);

const SectionAddIcon = (props) => <Icon component={svg} {...props} />;

export default SectionAddIcon;
