/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M0.664062 7.99984C0.664062 7.99984 3.33073 2.6665 7.99739 2.6665C12.6641 2.6665 15.3307 7.99984 15.3307 7.99984C15.3307 7.99984 12.6641 13.3332 7.99739 13.3332C3.33073 13.3332 0.664062 7.99984 0.664062 7.99984Z"
      stroke="#2F2C88"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
      stroke="#2F2C88"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const EyeIcon3 = (props) => <Icon component={svg} {...props} />;

export default EyeIcon3;
