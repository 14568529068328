import React, { useState } from 'react';
import { Modal, Form, Input, Select, Row, Col, Tag, Divider, Tooltip, Switch, message } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { useMutation, useLazyQuery } from '@apollo/client';

import PropTypes from 'prop-types';
import Button from 'components/Button';
import moment from 'moment';

import CloseIcon from 'components/Icons/CloseIcon';
import DeleteIcon from 'components/Icons/DeleteIcon';
import TeamUsersIcon from 'components/Icons/TeamUsersIcon';
import InfoIcon from 'components/Icons/InfoIcon';
import CircleTickIcon from 'components/Icons/CircleTickIcon';

import { FETCH_USER_PRORATION } from 'graphql/queries/transactionsQueries';
import { FEATURES_LIST_2 } from 'constants/index';
import { DELETE_TEAM_MEMBER } from 'graphql/mutations/usersTeamMutation';

import './AddUser.scss';

const daystrial = parseInt(process.env.REACT_APP_DAYS_TRAIL) || 14;

const {
  REACT_APP_STRIPE_OLD_MONTHLY_PLAN_ID,
  REACT_APP_STRIPE_OLD_YEARLY_PLAN_ID,
  REACT_APP_STRIPE_NEW_MONTHLY_PLAN_ID,
  REACT_APP_STRIPE_NEW_YEARLY_PLAN_ID,
} = process.env;

const jobs = [
  { text: 'Manager', value: 'manager' },
  { text: 'Editor', value: 'editor' },
];

const { Option } = Select;

const AddUser = ({
  title,
  user,
  plan,
  className,
  onCancel,
  onConfirm,
  visible,
  usersTeam,
  pendingUsersTeam,
  isSaving,
  setPlan,
  newUsersCountFromTrial,
  trial,
  trialTime,
  isPlan,
}) => {
  const [newUsersCount, setNewUsersCount] = useState(newUsersCountFromTrial);
  const [proration, setProration] = useState('');
  const [usersToBeDeleted, setUsersToBeDeleted] = useState([]);
  const [oldPlan] = useState(plan);

  const userPlanType = user.planType
    ? user.planType
    : new Date(user.createdAt) > new Date(process.env.REACT_APP_PLAN_REVISE_DATE)
    ? 'new'
    : '';

  const [loadUserProration, { loading: loadingProration }] = useLazyQuery(FETCH_USER_PRORATION, {
    fetchPolicy: 'network-only',
    onCompleted: ({ fetchUserProration }) => setProration(fetchUserProration),
  });

  let validDate = undefined;
  if (daystrial > moment().diff(moment(user.createdAt), 'days')) {
    validDate = moment()
      .add(daystrial - moment().diff(moment(user.createdAt), 'days'), 'days')
      .toDate();
  } else {
    validDate =
      user?.paymentStatus?.validTill &&
      moment(user?.paymentStatus?.validTill).format('MMM Do, YYYY');
  }

  const [removeOneTeamMember] = useMutation(DELETE_TEAM_MEMBER, {
    onCompleted: ({ removeOneTeamMember }) => {
      if (removeOneTeamMember) {
        let updatedUsersTeam = usersTeam.filter((team) => {
          return team._id !== usersToBeDeleted._id;
        });
        if (updatedUsersTeam) {
          message.success('User removed successfully');
        }
      }
    },
    onError: (error) => {
      console.log('error::::', error);
      onFinish(usersTeam);
    },
  });

  const doRemoveUserTeam = (_id) => {
    if (usersTeam.length > 0) {
      removeOneTeamMember({ variables: { uid: _id } });
      let newUserTeam = usersTeam.filter((user) => {
        return user._id !== _id;
      });
      usersTeam = newUserTeam;
    }
  };

  const onFinish = async ({ users }) => {
    const teamMembers = users?.filter(function (user) {
      return (
        usersTeam.filter(function (teamMember) {
          return teamMember.emails[0].address === user.email;
        }).length === 0
      );
    });

    if ((trial || !user?.paymentStatus?.everPayed) && userPlanType && !user?.subscriptionId) {
      onConfirm(
        userPlanType === 'new'
          ? plan === 'monthly'
            ? REACT_APP_STRIPE_NEW_MONTHLY_PLAN_ID
            : REACT_APP_STRIPE_NEW_YEARLY_PLAN_ID
          : plan === 'monthly'
          ? REACT_APP_STRIPE_OLD_MONTHLY_PLAN_ID
          : REACT_APP_STRIPE_OLD_YEARLY_PLAN_ID,
        {
          membersToBeAdded: teamMembers,
          membersToBeDeleted: usersToBeDeleted,
        }
      );
    } else {
      let planInfo = {};

      if (
        (user?.paymentStatus?.subType !== plan && (!trial || isPlan)) ||
        pendingUsersTeam.length ||
        (!validDate && user?.paymentStatus?.everPayed)
      ) {
        if (user.subscriptionId) {
          planInfo = {
            plan: 'stripe',
            planType:
              userPlanType === 'new'
                ? plan === 'monthly'
                  ? REACT_APP_STRIPE_NEW_MONTHLY_PLAN_ID
                  : REACT_APP_STRIPE_NEW_YEARLY_PLAN_ID
                : plan === 'monthly'
                ? REACT_APP_STRIPE_OLD_MONTHLY_PLAN_ID
                : REACT_APP_STRIPE_OLD_YEARLY_PLAN_ID,
          };
        } else if (userPlanType !== 'new') {
          planInfo = {
            plan: 'oldPaypal',
            planType: plan,
          };
        }
      }

      if (teamMembers?.length || usersToBeDeleted?.length) {
        const mutationInfo = {
          membersToBeAdded: teamMembers,
          membersToBeDeleted: usersToBeDeleted,
          planInfo,
        };

        onConfirm(null, mutationInfo);
      } else if (
        user?.paymentStatus?.subType !== plan ||
        pendingUsersTeam.length ||
        (!validDate && user?.paymentStatus?.everPayed)
      ) {
        const mutationInfo = {
          membersToBeAdded: [],
          membersToBeDeleted: [],
          planInfo,
        };
        onConfirm(null, mutationInfo);
      } else {
        onCancel(false);
      }
    }
  };

  const [form] = Form.useForm();

  const planValue =
    userPlanType === 'new' ? (plan === 'yearly' ? 96 : 10) : plan === 'yearly' ? 60 : 8;

  const planTimeLeft =
    trialTime > 0
      ? plan === 'yearly'
        ? moment()
            .add(Math.floor(trialTime + 1), 'days')
            .add(1, 'year')
        : moment()
            .add(Math.floor(trialTime + 1), 'days')
            .add(1, 'month')
      : user?.paymentStatus?.paymentMode?.type === 'paypal' || !proration?.renewalDate
      ? plan === 'yearly'
        ? moment().add(1, 'year')
        : moment().add(1, 'month')
      : user?.paymentStatus?.subType !== plan
      ? plan === 'yearly'
        ? moment().add(1, 'year')
        : moment().add(1, 'month')
      : moment.unix(proration?.renewalDate);

  const getInitialUsers = () => {
    let users = usersTeam.map((team) => ({
      email: team.emails[0].address,
      role: team.roles && team.roles[0],
    }));

    for (let i = 0; i < newUsersCount; i++) {
      users.push({ email: '', role: [] });
    }

    return users;
  };

  const renderSummaryInfo = () => {
    return (
      <>
        <ul className="add-user-box">
          <li>
            You {newUsersCount < 0 ? 'removed' : 'added'} <b>{Math.abs(newUsersCount)}</b>{' '}
            {newUsersCount > 0 && 'additional'} user(s), bringing you to a total of{' '}
            {usersTeam.length + newUsersCount + 1} user(s) on the <b>{plan}</b> plan.
          </li>
          {newUsersCount > 0 || proration?.amount ? (
            <li>
              You will be charged{' '}
              <b>
                $
                {trial || !user?.paymentStatus?.everPayed
                  ? (newUsersCount + usersTeam.length + 1) *
                    (userPlanType === 'new'
                      ? plan === 'yearly'
                        ? 96
                        : 10
                      : plan === 'yearly'
                      ? 60
                      : 8)
                  : user?.paymentStatus?.paymentMode?.type === 'paypal' || !proration
                  ? newUsersCount *
                    (userPlanType === 'new'
                      ? plan === 'yearly'
                        ? 96
                        : 10
                      : plan === 'yearly'
                      ? 60
                      : 8)
                  : Math.abs((proration?.amount / 100).toFixed(2))}
              </b>{' '}
              {trial ? `after ${Math.floor(trialTime + 1)} days` : 'today'}.
            </li>
          ) : null}
          <li>
            Your next payment of{' '}
            <b>
              $
              {(usersTeam.length + newUsersCount + 1) *
                (userPlanType === 'new'
                  ? plan === 'yearly'
                    ? 96
                    : 10
                  : plan === 'yearly'
                  ? 60
                  : 8)}
            </b>{' '}
            will be due{' '}
            <b>
              <Tooltip title={planTimeLeft?.format('LL')}>
                {planTimeLeft?.format('DD/MM/YYYY')}
              </Tooltip>
            </b>{' '}
            when your plan renews.
          </li>
        </ul>
      </>
    );
  };

  const renderSingleUserSummaryInfo = (list, isActive) => {
    return (
      <div className="single-user-plan-summary">
        <p className="single-user-plan-summary-heading">Add another user to get these features</p>
        {list.map((item) => (
          <Row key={item.text} className="single-user-plan-summary-list">
            <CircleTickIcon className="feature-list-check-icon" />
            <p>{item.text}</p>
            <Tooltip title={item.tooltip} overlayClassName="feature-list-info-tooltip">
              <InfoIcon className="feature-list-info-icon" />
            </Tooltip>
          </Row>
        ))}
      </div>
    );
  };

  const { everPayed, isLegacy, didCancel, validTill } = user?.paymentStatus;
  let validdate = undefined;
  if (daystrial > moment().diff(moment(user.createdAt), 'days')) {
    validdate = moment()
      .add(daystrial - moment().diff(moment(user.createdAt), 'days'), 'days')
      .format('MMM Do, YYYY');
  } else {
    validdate = validTill && moment(validTill).format('MMM Do, YYYY');
  }
  let { nextPaymentDate } = user?.paymentStatus;
  let renewaldate = nextPaymentDate && moment(nextPaymentDate).format('MMM Do, YYYY');
  if (isLegacy) {
    nextPaymentDate = null;
    renewaldate = null;
  } else if (!renewaldate && trial) {
    renewaldate = moment().add(trialTime, 'days').format('MMM Do, YYYY');
  }

  return (
    <Modal
      className={`simple-info-modal sign-up-info-modal add-user-modal ${className}`}
      centered
      visible={visible}
      footer={null}
      closeIcon={<CloseIcon className="close-icon" />}
      onCancel={() => {
        onCancel(false);
        setPlan(oldPlan);
      }}
      maskClosable={!isSaving}>
      <Row>
        <Form
          form={form}
          name="dynamic_form_nest_item"
          className="add-user-form"
          initialValues={{
            users: getInitialUsers(),
          }}
          onFinish={onFinish}
          autoComplete="off">
          <Col span={13}>
            <Col
              className={`add-user-modal-header ${
                (newUsersCount || usersTeam.length) && 'add-user-modal-shadow'
              }`}>
              <h3 className="title">{title || 'Add New User'}</h3>
              <div className="divider" />
              <Col className="add-user-modal-admin">
                <Row>
                  <span>Email</span>
                </Row>
                <Row>
                  <span>{user.emails[0].address} </span> <Tag>Admin</Tag>
                </Row>
              </Col>
            </Col>

            <div className="form-container">
              <div className="form-wraper">
                <Form.List name="users" autoComplete="off">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <Row key={`AddUser${index}`} className="user-group-container">
                          <div className="user-group">
                            <DeleteIcon
                              className="remove-icon"
                              onClick={(e) => {
                                if (usersTeam.length && usersTeam[field.key]) {
                                  let tempUsersToBeDeleted = usersToBeDeleted || [];
                                  tempUsersToBeDeleted.push({
                                    _id: usersTeam[field.key]._id,
                                    email: usersTeam[field.key].emails[0].address,
                                    role: usersTeam[field.key].roles[0],
                                  });
                                  setUsersToBeDeleted(tempUsersToBeDeleted);
                                  doRemoveUserTeam(usersTeam[field.key]._id);
                                }
                                remove(field.name);
                                setNewUsersCount(newUsersCount - 1);
                                if (user?.paymentStatus?.paymentMode?.type === 'card') {
                                  loadUserProration({
                                    variables: {
                                      numberOfTeamMembers: usersTeam.length + newUsersCount - 1,
                                      plan:
                                        userPlanType === 'new'
                                          ? plan === 'monthly'
                                            ? REACT_APP_STRIPE_NEW_MONTHLY_PLAN_ID
                                            : REACT_APP_STRIPE_NEW_YEARLY_PLAN_ID
                                          : plan === 'monthly'
                                          ? REACT_APP_STRIPE_OLD_MONTHLY_PLAN_ID
                                          : REACT_APP_STRIPE_OLD_YEARLY_PLAN_ID,
                                    },
                                  });
                                }
                              }}
                            />
                            <Row
                              className={`user-info-container ${
                                usersTeam[field.key]?.emails[0]?.verified === false
                                  ? 'user-info-container-unverified'
                                  : ''
                              }`}
                              key={`user-info-container-${index}`}>
                              <Form.Item
                                label="Email"
                                className="user-group-item user-group-item-email"
                                name={[field.name, 'email']}
                                rules={[
                                  {
                                    required: true,
                                    type: 'email',
                                    message: 'Please enter an email',
                                  },
                                ]}>
                                <Input
                                  placeholder="Email Address"
                                  disabled={index >= usersTeam.length ? false : true}
                                  autoFocus
                                />
                              </Form.Item>
                              <Form.Item
                                label="Role"
                                className="user-group-item user-group-item-role"
                                name={[field.name, 'role']}
                                rules={[{ required: true, message: 'Role required' }]}>
                                <Select
                                  suffixIcon={<CaretDownOutlined color="red" />}
                                  disabled={index >= usersTeam.length ? false : true}>
                                  {jobs.map((job) => (
                                    <Option key={job.value} value={job.value}>
                                      {job.text}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Row>
                          </div>
                          {index !== fields.length - 1 ? (
                            <div className="add-user-divider" />
                          ) : (
                            <></>
                          )}
                        </Row>
                      ))}
                      <div className="actions">
                        <Button
                          htmlType="button"
                          disabled={loadingProration}
                          onClick={() => {
                            add();
                            setNewUsersCount(newUsersCount + 1);
                            if (user?.paymentStatus?.paymentMode?.type === 'card') {
                              loadUserProration({
                                variables: {
                                  numberOfTeamMembers: usersTeam.length + newUsersCount + 1,
                                  plan:
                                    userPlanType === 'new'
                                      ? planValue === 10
                                        ? REACT_APP_STRIPE_NEW_MONTHLY_PLAN_ID
                                        : REACT_APP_STRIPE_NEW_YEARLY_PLAN_ID
                                      : planValue === 8
                                      ? REACT_APP_STRIPE_OLD_MONTHLY_PLAN_ID
                                      : REACT_APP_STRIPE_OLD_YEARLY_PLAN_ID,
                                },
                              });
                            }
                          }}
                          className="add-user"
                          text={`+ NEW USER ${`FOR $${
                            planValue === 8 || planValue === 10
                              ? `${planValue}/mo.`
                              : `${planValue}/yr.`
                          }`}`}></Button>
                      </div>
                    </>
                  )}
                </Form.List>
              </div>
            </div>
          </Col>
          <Col className="add-user-modal-plan-summary" span={11}>
            <TeamUsersIcon className="logo" />
            <>
              <h3 className="title">Summary</h3>

              <Form.Item name={['planToggle']}>
                <>
                  <Row className="plans-type">
                    <span className={plan === 'yearly' ? '' : 'active'}>Monthly &nbsp;</span>
                    <Switch
                      checked={plan === 'yearly' ? true : false}
                      disabled={loadingProration}
                      onChange={(value) => {
                        const planType = value ? 'yearly' : 'monthly';
                        setPlan(planType);
                        loadUserProration({
                          variables: {
                            numberOfTeamMembers: usersTeam.length + newUsersCount,
                            plan:
                              userPlanType === 'new'
                                ? planType === 'monthly'
                                  ? REACT_APP_STRIPE_NEW_MONTHLY_PLAN_ID
                                  : REACT_APP_STRIPE_NEW_YEARLY_PLAN_ID
                                : planType === 'monthly'
                                ? REACT_APP_STRIPE_OLD_MONTHLY_PLAN_ID
                                : REACT_APP_STRIPE_OLD_YEARLY_PLAN_ID,
                          },
                        });
                      }}
                    />
                    <div>
                      <span className={plan === 'yearly' ? 'active' : ''}>Annually</span>
                      <Row className="plans-type discount">
                        <span>20% Less</span>
                      </Row>
                    </div>
                  </Row>
                </>
              </Form.Item>

              <Col className="summary-content-container">
                <Row className="summary-content-content">
                  <Col span={12} className="summary-content-title">
                    <span>
                      Current ({usersTeam?.length ? `${usersTeam.length + 1} users` : '1 user'})
                      <Tooltip
                        title="Admin User Plan Price"
                        overlayClassName="summary-content-title-tooltip">
                        <InfoIcon className="summary-content-title-icon" />
                      </Tooltip>
                    </span>
                  </Col>
                  <Col span={12} className="summary-content-value">
                    <span className="totals-content-unit">
                      ${usersTeam?.length ? planValue * (usersTeam.length + 1) : planValue}
                    </span>
                  </Col>
                </Row>

                {newUsersCount ? (
                  <Row className="summary-content-content">
                    <Col span={12} className="summary-content-title">
                      <span>
                        New (+{newUsersCount} users)
                        <Tooltip
                          title="New Users Plan Price"
                          overlayClassName="summary-content-title-tooltip">
                          <InfoIcon className="summary-content-title-icon" />
                        </Tooltip>
                      </span>
                    </Col>
                    <Col span={12} className="summary-content-value">
                      <span className="summary-content-value-unit">
                        ${newUsersCount * planValue}
                      </span>
                    </Col>
                  </Row>
                ) : null}

                <Row className="summary-content-content">
                  <Col span={12} className="summary-content-title"></Col>
                  <Col span={12} className="summary-content-value">
                    <Divider />
                  </Col>
                </Row>

                <Row className="summary-content-content">
                  <Col span={12} className="summary-content-title">
                    <span>
                      Total
                      <Tooltip
                        title="Total Plan Price"
                        overlayClassName="summary-content-title-tooltip">
                        <InfoIcon className="summary-content-title-icon" />
                      </Tooltip>
                    </span>
                  </Col>
                  <Col span={12} className="summary-content-value-total">
                    <span className="summary-content-value-unit">
                      $
                      {newUsersCount * planValue +
                        (usersTeam?.length ? planValue * (usersTeam.length + 1) : planValue)}
                    </span>
                    <span>{plan === 'monthly' ? '/mo.' : '/yr.'}</span>
                  </Col>
                </Row>
                <Row className="summary-content-content">
                  <Col span={24} className="summary-content-credits">
                    <span>
                      {renewaldate && !trial
                        ? !didCancel
                          ? `Payment on ${renewaldate}`
                          : `Canceled, expires on ${renewaldate}`
                        : didCancel && !isLegacy
                        ? validdate
                          ? `Canceled but valid till ${validdate}`
                          : 'Canceled'
                        : trial
                        ? `Trial expires on ${renewaldate}`
                        : !everPayed
                        ? `Trial expired ${Math.abs(trialTime).toFixed(0)} days ago`
                        : ''}
                    </span>
                  </Col>
                  {proration?.endingBalance ? (
                    <Col span={24} className="summary-content-credits">
                      <span>
                        *Credits ${Math.abs(proration?.endingBalance / 100)} for unused subscription
                        time may apply to the final price
                      </span>
                    </Col>
                  ) : null}
                </Row>
              </Col>
            </>

            {newUsersCount || (proration && user?.paymentStatus?.subType !== plan) ? (
              <Row className="summary-info">
                <Col span={24}>
                  <span>{renderSummaryInfo()}</span>
                </Col>
              </Row>
            ) : usersTeam.length < 1 ? (
              <Row className="summary-info">
                <Col span={24}>
                  <span>{renderSingleUserSummaryInfo(FEATURES_LIST_2, true)}</span>
                </Col>
              </Row>
            ) : null}

            <Col className="actions">
              <Button
                htmlType="submit"
                className="submit"
                text={
                  (trial || !user?.paymentStatus?.everPayed) && isPlan && !user?.subscriptionId
                    ? 'CHECKOUT'
                    : !validDate && user?.paymentStatus?.everPayed
                    ? 'RENEW'
                    : 'SAVE'
                }
                loading={isSaving}
              />
              <Button
                htmlType="button"
                onClick={() => {
                  onCancel(false);
                  setPlan(oldPlan);
                }}
                className="cancel"
                text="CANCEL"
                disabled={isSaving}
              />
            </Col>
          </Col>
        </Form>
      </Row>
    </Modal>
  );
};

AddUser.defaultProps = {
  title: '',
  plan: 'yearly',
  isSaving: false,
  isPlan: false,
  newUsersCountFromTrial: 0,
  pendingUsersTeam: [],
  trialTime: 0,
  setPlan: () => {},
};

AddUser.propTypes = {
  title: PropTypes.string,
  plan: PropTypes.string,
  user: PropTypes.instanceOf(Object).isRequired,
  usersTeam: PropTypes.instanceOf(Array).isRequired,
  pendingUsersTeam: PropTypes.instanceOf(Array),
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
  isPlan: PropTypes.bool,
  newUsersCountFromTrial: PropTypes.number,
  trialTime: PropTypes.number,
  setPlan: PropTypes.func,
};

export default AddUser;
