import React, { useState, useEffect } from 'react';
import * as icons from '../../components/Icons';

const PAGE_SIZE = 30;
const MAX_PAGE = Math.floor(Object.keys(icons).length / PAGE_SIZE);

export default function IconsPage() {
  const [components, setComponents] = useState([]);
  const [page, setpage] = useState(0);

  useEffect(() => {
    importComponents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const prevPage = () => {
    setpage((prev) => Math.max(0, prev - 1));
  };
  const nextPage = () => {
    setpage((prev) => Math.min(MAX_PAGE, prev + 1));
  };

  const importComponents = async () => {
    const importedComponents = [];

    const slicedIcons = Object.keys(icons).slice(page * PAGE_SIZE, page * PAGE_SIZE + PAGE_SIZE);

    if (slicedIcons.length === 0) {
      return;
    }

    for (const componentName of slicedIcons) {
      try {
        const componentModule = await import(`../../components/Icons/${componentName}`);
        importedComponents.push({ componentName, component: componentModule.default });
      } catch (error) {
        console.error(`Error importing component "${componentName}":`, error);
      }
    }

    setComponents(importedComponents);
  };

  return (
    <div style={{ padding: '100px 50px' }}>
      <div>
        <button onClick={prevPage}>Prev</button>
        {page}
        <button onClick={nextPage}>Next</button>
      </div>

      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {components.map((item, i) => (
          <RenderComponent key={i} componentName={item.componentName} component={item.component} />
        ))}

        {/* {
            <div>
                {`export { default as ${componentName} } from './ ${componentName}';`}
             </div>
        } */}
      </div>
    </div>
  );
}

function RenderComponent({ componentName, component }) {
  const IconComponent = component;

  return (
    <div style={{ padding: 10 }}>
      <IconComponent />
      <div>{componentName}</div>
    </div>
  );
}
