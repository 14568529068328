/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.87031 5.59184C4.67188 5.81055 4.32812 5.81055 4.12969 5.59184L0.495303 1.58596C0.203796 1.26466 0.431776 0.75 0.86561 0.75L8.13439 0.75C8.56822 0.75 8.7962 1.26466 8.5047 1.58597L4.87031 5.59184Z"
      fill="#7876C1"
    />
  </svg>
);

const FolderArrow = (props) => <Icon component={svg} {...props} />;

export default FolderArrow;
