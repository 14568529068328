import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 8L1 4.5L5 1"
      stroke="#05034D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const BackIcon = (props) => <Icon component={svg} {...props} />;

export default BackIcon;
