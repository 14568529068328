import { PROPOSAL_SUBSCRIPTION_TOPIC } from 'constants/index';
import _ from 'lodash';

import helpers from 'helpers';

let time = 0;
let isOn = false;
let start = 0;
let timer;
let sessionSaved = false;
let clientIP = '';
const MAX_SESSION_TIME = 5 * 60 * 1000;
let isSavingForm = false;

const onSavingFormChange = (_isSavingForm) => {
  isSavingForm = _isSavingForm;
};

const startTimer = (logTime) => {
  if (!isOn) {
    isOn = true;
    time = logTime;
    start = Date.now() - logTime;
    timer = setInterval(() => {
      time = Date.now() - start;
    }, 1000);
  }
};

const startTimerMobile = (logTime) => {
  if (!isOn) {
    isOn = true;
    time = logTime;
    start = Date.now() - logTime;

    timer = setInterval(() => {
      time = Date.now() - start;
    }, 1000);
  }
};

const resetTimer = (setLogTime) => {
  clearInterval(timer);
  setLogTime(0);
  time = 0;
  isOn = false;
  start = 0;
};

const logTimer = async ({ isClient, prop, updateProposalClient, setLogTime, session }) => {
  // prevent updating when saving is going on
  if (!prop || !isClient || isSavingForm) {
    return;
  }

  // prevent updating when prerendering (for slack or whatsapp)
  const userAgent = (navigator?.userAgent || '').toLowerCase();
  if (userAgent.includes('prerender')) {
    return;
  }

  if (clientIP === '') {
    // fetch client ip only if not fetched before
    try {
      let response = await fetch('https://www.cloudflare.com/cdn-cgi/trace');
      if (response) {
        response = await response.text();
        const result = helpers.transformReadableText(response);
        if (result?.ip) {
          clientIP = result.ip;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  let openLogs = prop?.openLogs?.slice() || [];
  const utcTime = new Date(new Date().toUTCString()).toISOString();

  const browserName = await helpers.getBrowserName();

  if (!clientIP || clientIP === '') {
    clientIP = browserName;
  }

  const identifier = `${clientIP}-${session.tab_id}`;

  const logIndex = openLogs.findIndex((log) => identifier === log.clientIP);

  let newSession = false;
  // if session is not saved AND
  // visit=1 OR last visit time was > 5 min ago then new session

  if (
    !sessionSaved &&
    (session?.visit === 1 || Date.now() - session?.last_visited > MAX_SESSION_TIME)
  ) {
    newSession = true;
  }

  if (newSession || logIndex === -1) {
    // new session
    openLogs = [
      {
        date: utcTime,
        clientIP: identifier,
        watched: Math.max(Number(time) / 1000, 2), // at least 2 seconds
        deviceInfo: {
          deviceType: /Mobi|Android/i.test(navigator.userAgent) ? 'Mobile' : 'Desktop',
          deviceOS: helpers.getOS(),
          deviceBrowser: browserName,
          deviceName: helpers.getDeviceName(),
          deviceAgent: window.navigator.userAgent,
        },
      },
      ...openLogs,
    ];
  } else {
    // update old session
    openLogs[logIndex] = {
      ...openLogs[logIndex],
      watched: Number(openLogs[logIndex].watched) + Number(time) / 1000,
    };
  }

  sessionSaved = true;
  updateProposalClient({
    variables: {
      topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${prop?.channel || prop?.auid || prop?.uid}`,
      updateType: 'time',
      proposal: {
        id: prop._id,
        _id: prop._id,
        pid: prop.pid,
        uid: prop.uid,
        openLogs: openLogs,
        lastSeen: utcTime,
      },
    },
  }).then(({ data }) => {
    if (data) {
      resetTimer(setLogTime);
    }
  });
};

const setseen = _.once((params, prop, updateProposalClient, clientWixPreview) => {
  const userAgent = (navigator?.userAgent || '').toLowerCase();
  if (prop?.state === 'seen' || prop?.state === 'approved' || userAgent.includes('prerender')) {
    return;
  }

  const isClient = !!params.cid || !!clientWixPreview;

  if ((params.cid || clientWixPreview) && isClient && prop) {
    updateProposalClient({
      variables: {
        topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${prop?.channel || prop?.auid || prop?.uid}`,
        updateType: 'open',
        proposal: {
          id: prop._id,
          _id: prop._id,
          pid: prop.pid,
          uid: prop.uid,
          client: prop.client,
          state: 'seen',
        },
      },
    }).then(({ data }) => {
      if (data) {
        try {
          var thedate = new Date();
          if (
            window.location.href.indexOf('/cd/') === -1 &&
            window.location.href.indexOf('/proposal-preview') === -1
          ) {
            window.ga('set', 'dimension2', thedate.getMonth() + 1 + '-' + thedate.getFullYear());
            window.ga('send', 'event', 'prop-state', 'seen', window.location.href);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        return true;
      }
    });
  }
});

const proposalTimer = {
  startTimer,
  startTimerMobile,
  // resetTimer,
  logTimer,
  // setSeenCount,
  setseen,
  onSavingFormChange,
};

export default proposalTimer;
