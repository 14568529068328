/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="23" height="10" viewBox="0 0 23 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 5L6 1M9 5L6 9M9 5H1M14 5L17 1M14 5L17 9M14 5H22.5"
      stroke="#05034D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Collapse = (props) => <Icon component={svg} {...props} />;

export default Collapse;
