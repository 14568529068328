import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Input, Form, Radio, Avatar, message } from 'antd';

import CloseIcon from 'components/Icons/CloseIcon';
import Uploader from 'components/Uploader';

import { getUserShortName } from 'helpers/users';
import { resizeImageForS3 } from 'utils/uploadToS3';

import utils from 'utils/utils';
import './EditUser.scss';

const validatePassword = (value) => {
  const errorResponse = utils.simplePasswordPolicy(value);
  if (errorResponse === null) {
    return {
      validateStatus: 'success',
      errorMsg: null,
    };
  } else {
    return {
      validateStatus: 'error',
      errorMsg: errorResponse,
    };
  }
};

const EditUser = ({ visible, onCancel, type, onConfirm, selectedUser }) => {
  const inputFileRef = useRef(null);
  const [userImage, setUserImage] = useState(selectedUser?.profile?.image);

  const [form] = Form.useForm();
  const [password, setPassword] = useState({
    value: '',
  });
  const [upload] = Uploader();
  const [disableButton, enableOrDisableButton] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [imageToBeUploaded, setImageToBeUploaded] = useState('');

  const onFinish = async (values) => {
    if (password.validateStatus === 'error') {
      return;
    }

    enableOrDisableButton(true);
    let url = '';

    if (imageToBeUploaded) {
      const size = { width: 300, height: 300 };
      try {
        const reSizedImage = await resizeImageForS3({
          file: imageToBeUploaded,
          path: 'profile',
          size,
        });

        reSizedImage.path = 'profile';

        const uploadedImage = await upload(reSizedImage, `profile/${selectedUser._id}`);
        url = uploadedImage.url; //eslint-disable-line
      } catch (error) {
        message.error(error.message);
      }
    }

    onConfirm({
      editUserInfo: {
        ...values,
        userImage: url || selectedUser?.profile?.image,
      },
    });
    form.resetFields();

    setUploading(false);
    enableOrDisableButton(false);
  };

  const onCloseForm = () => {
    onCancel();
    form.resetFields();
  };

  const onPasswordChange = (value) => {
    setPassword({
      ...validatePassword(value),
      value,
    });
  };

  const onImageSelect = async (e) => {
    const [file] = e.target.files;
    if (!file) return;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setUserImage(reader.result);
    };

    setImageToBeUploaded(file);
  };

  const uploadUserPhoto = () => {
    inputFileRef.current.click();
  };

  return (
    <Modal
      visible={visible}
      className="user-modal add-new-user"
      onCancel={onCancel}
      footer={null}
      closeIcon={<CloseIcon className="close-icon" />}>
      <h3 className="page-title">
        {type === 'add'
          ? 'Add New User'
          : `Editing ${selectedUser.profile && selectedUser.profile.name}`}
      </h3>

      <div className="color-divider" />

      <div className="user-image-container">
        <span className="user-photo-title">User Photo</span>
        <div>
          {userImage ? (
            <Avatar src={userImage} size={64} />
          ) : (
            <Avatar size={64}>
              {getUserShortName({ name: selectedUser.profile && selectedUser.profile.name })}
            </Avatar>
          )}

          <Button size="small" className="change-btn" onClick={uploadUserPhoto}>
            Change
          </Button>
        </div>
      </div>

      <Form
        className="user-form"
        layout="vertical"
        onFinish={onFinish}
        form={form}
        initialValues={
          selectedUser
            ? {
                userName: selectedUser.profile.name,
                email: selectedUser.emails[0].address,
                role: selectedUser.roles && selectedUser.roles[0],
              }
            : {}
        }>
        <Form.Item
          label="User Name"
          name="userName"
          rules={[{ required: true, message: 'User name cannot be empty!' }]}>
          <Input placeholder="Shalom Cohen" />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Email Address cannot be empty!' }]}>
          <Input placeholder="hello@site2u.co.il" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          validateStatus={password.validateStatus}
          help={password.errorMsg}>
          <Input.Password
            value={password.value}
            onChange={(e) => onPasswordChange(e.target.value)}
            min={8}
          />
        </Form.Item>

        <Form.Item label="Job" name="role" rules={[{ required: true, message: 'Role Required!' }]}>
          <Radio.Group>
            <Radio style={{ display: 'block' }} value="manager">
              Manager
            </Radio>
            <Radio style={{ display: 'block' }} value="editor">
              Editor
            </Radio>
          </Radio.Group>
        </Form.Item>

        <div className="actions">
          <Button htmlType="submit" className="submit" disabled={disableButton}>
            {' '}
            {uploading ? 'Saving' : 'Save'}
          </Button>
          <Button onClick={onCloseForm} className="cancel">
            Cancel
          </Button>
        </div>
      </Form>
      <input
        type="file"
        className="file_bag"
        accept="image/*"
        ref={inputFileRef}
        onChange={onImageSelect}
        style={{ display: 'none', position: 'absolute', left: -10000 }}
      />
    </Modal>
  );
};

EditUser.defaultProps = {
  type: 'add',
  selectedUser: '',
};

EditUser.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['add', 'edit']),
  onConfirm: PropTypes.func.isRequired,
  selectedUser: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
};

export default EditUser;
