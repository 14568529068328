import handleKeyCommand from './handleKeyCommand';
import updateSelection from './updateSelection';
import onTab from './onTab';
import handleDuplicateElement from './handleDuplicateElement';
import removeTeX from './removeTeX';
import insertTeXBlock from './insertTeXBlock';
import insertEmptyBlock from './insertEmptyBlock';

const utils = {
  handleKeyCommand,
  updateSelection,
  onTab,
  handleDuplicateElement,
  removeTeX,
  insertTeXBlock,
  insertEmptyBlock,
};

export default utils;
