import moment from 'moment';
import _ from 'lodash';

import { MONTH_NAMES } from 'constants/index';

// Sort all the activities
export const sortActivities = ({ teamsActivity }) => {
  return teamsActivity.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
};
// Group all the activities by month and year
export const activitiesByMonthAndYear = ({ activityDate }) => {
  const name = MONTH_NAMES[activityDate.getMonth()];
  return `${name} ${activityDate.getFullYear()}`;
};

// Filter activities by team members
export const filterActivityByTeam = ({ teamList, teamsActivity }) => {
  const teamsFilter = teamList.filter((item) => item.checked).map((item) => item.id);

  if (!teamsFilter.length) {
    return teamsActivity;
  }

  return teamsActivity.filter((activity) => {
    return teamsFilter.includes(activity.user._id);
  });
};

// Filter activities based on value
export const findActivityBySearch = ({ teamsActivity, searchValue }) => {
  const regex = new RegExp(_.escapeRegExp(searchValue), 'i');
  return teamsActivity.filter(
    (activity) =>
      regex.test(activity.type) ||
      (activity.user && regex.test(activity.user.name)) ||
      (activity.proposal && regex.test(activity.proposal.name))
  );
};

// Filter activities based on selected actiivty status filter
export const findActivitiesByStatusFilter = ({ teamsActivity, filterItems }) => {
  // If all is selected show all the activities
  if (filterItems[0].checked) return teamsActivity;
  const statusFilter = [];

  filterItems.forEach((item) => {
    if (item.checked) {
      statusFilter.push(item.key);
    }
  });
  return teamsActivity.filter((activity) => statusFilter.includes(activity.type));
};

// Get updated activity list satisfying all the conditions
export const getUpdatedActivityList = ({ teamsActivity }) => {
  const activitiesByDays = _.groupBy(teamsActivity, (activity) =>
    moment(activity.createdAt).format('MMMM Do YYYY') === moment(new Date()).format('MMMM Do YYYY')
      ? 'Today'
      : moment(activity.createdAt).format('MMMM Do YYYY')
  );

  return activitiesByDays;
};
