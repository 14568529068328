/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="45" height="19" viewBox="0 0 54 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.7686 19.0001V10.7764H19.4759L20.4101 11.9942L21.3752 10.7764H52.9809V18.4329C52.9809 18.4329 52.1543 18.9919 51.1984 19.0001H33.6977L32.6444 17.7037V19.0001H29.1929V16.7872C29.1929 16.7872 28.7214 17.0961 27.7021 17.0961H26.5273V19.0001H21.3014L20.3685 17.7561L19.4213 19.0001H10.7686Z"
      fill="white"
    />
    <path
      d="M0.599609 4.57772L2.56316 0H5.95891L7.07325 2.56426V0H11.2945L11.9578 1.85336L12.601 0H31.5499V0.931749C31.5499 0.931749 32.5461 0 34.1831 0L40.3314 0.0214864L41.4265 2.55218V0H44.959L45.9313 1.45368V0H49.4962V8.2237H45.9313L44.9995 6.76531V8.2237H39.8094L39.2875 6.92735H37.8922L37.3788 8.2237H33.859C32.4504 8.2237 31.5499 7.31098 31.5499 7.31098V8.2237H26.243L25.1897 6.92735V8.2237H5.45585L4.93427 6.92735H3.54345L3.02556 8.2237H0.599609V4.57772Z"
      fill="white"
    />
    <path
      d="M3.25777 1.01416L0.609375 7.17181H2.33362L2.82228 5.93877H5.66311L6.14924 7.17181H7.91146L5.26559 1.01416H3.25777ZM4.23763 2.44723L5.10355 4.60191H3.36918L4.23763 2.44723Z"
      fill="#016FD0"
    />
    <path
      d="M8.0957 7.17035V1.0127L10.5459 1.0218L11.9711 4.99186L13.3621 1.0127H15.7928V7.17035H14.2534V2.63313L12.6215 7.17035H11.2715L9.63512 2.63313V7.17035H8.0957Z"
      fill="#016FD0"
    />
    <path
      d="M16.8447 7.17035V1.0127H21.8681V2.39007H18.4003V3.44335H21.7871V4.7397H18.4003V5.83349H21.8681V7.17035H16.8447Z"
      fill="#016FD0"
    />
    <path
      d="M22.7588 1.01416V7.17181H24.2982V4.98422H24.9464L26.7922 7.17181H28.6734L26.6478 4.90321C27.4791 4.83306 28.3366 4.11957 28.3366 3.01186C28.3366 1.71607 27.3196 1.01416 26.1845 1.01416H22.7588ZM24.2982 2.39153H26.0579C26.48 2.39153 26.7871 2.72173 26.7871 3.0397C26.7871 3.44879 26.3892 3.68788 26.0807 3.68788H24.2982V2.39153Z"
      fill="#016FD0"
    />
    <path d="M30.5367 7.17035H28.9648V1.0127H30.5367V7.17035Z" fill="#016FD0" />
    <path
      d="M34.2627 7.17035H33.9234C32.2818 7.17035 31.2852 5.87705 31.2852 4.11684C31.2852 2.31315 32.2707 1.0127 34.3437 1.0127H36.0452V2.47109H34.2815C33.44 2.47109 32.8448 3.12782 32.8448 4.13203C32.8448 5.32454 33.5254 5.82539 34.5058 5.82539H34.9109L34.2627 7.17035Z"
      fill="#016FD0"
    />
    <path
      d="M37.6152 1.01416L34.9668 7.17181H36.691L37.1797 5.93877H40.0205L40.5067 7.17181H42.2689L39.623 1.01416H37.6152ZM38.5951 2.44723L39.461 4.60191H37.7266L38.5951 2.44723Z"
      fill="#016FD0"
    />
    <path
      d="M42.4482 7.17035V1.0127H44.4054L46.9044 4.88149V1.0127H48.4439V7.17035H46.55L43.9877 3.20028V7.17035H42.4482Z"
      fill="#016FD0"
    />
    <path
      d="M11.8203 17.9467V11.7891H16.8437V13.1664H13.3759V14.2197H16.7626V15.5161H13.3759V16.6099H16.8437V17.9467H11.8203Z"
      fill="#016FD0"
    />
    <path
      d="M36.4346 17.9467V11.7891H41.4579V13.1664H37.9902V14.2197H41.3607V15.5161H37.9902V16.6099H41.4579V17.9467H36.4346Z"
      fill="#016FD0"
    />
    <path
      d="M17.0397 17.9467L19.4855 14.9059L16.9814 11.7891H18.9209L20.4122 13.7159L21.9086 11.7891H23.7721L21.3009 14.8679L23.7513 17.9467H21.8121L20.3641 16.0503L18.9513 17.9467H17.0397Z"
      fill="#016FD0"
    />
    <path
      d="M23.9346 11.7891V17.9467H25.5145V16.0022H27.1349C28.506 16.0022 29.5453 15.2748 29.5453 13.8602C29.5453 12.6883 28.7302 11.7891 27.335 11.7891H23.9346ZM25.5145 13.1816H27.221C27.664 13.1816 27.9806 13.4531 27.9806 13.8906C27.9806 14.3015 27.6656 14.5995 27.216 14.5995H25.5145V13.1816Z"
      fill="#016FD0"
    />
    <path
      d="M30.2129 11.7891V17.9467H31.7523V15.7591H32.4005L34.2463 17.9467H36.1275L34.1019 15.6781C34.9332 15.608 35.7907 14.8945 35.7907 13.7868C35.7907 12.491 34.7737 11.7891 33.6386 11.7891H30.2129ZM31.7523 13.1664H33.512C33.9341 13.1664 34.2412 13.4966 34.2412 13.8146C34.2412 14.2237 33.8433 14.4628 33.5348 14.4628H31.7523V13.1664Z"
      fill="#016FD0"
    />
    <path
      d="M42.173 17.9467V16.6099H45.2539C45.7097 16.6099 45.9071 16.3635 45.9071 16.0933C45.9071 15.8345 45.7103 15.5728 45.2539 15.5728H43.8617C42.6515 15.5728 41.9775 14.8355 41.9775 13.7285C41.9775 12.7412 42.5947 11.7891 44.393 11.7891H47.3908L46.7426 13.1745H44.1499C43.6543 13.1745 43.5018 13.4346 43.5018 13.683C43.5018 13.9382 43.6903 14.2197 44.0689 14.2197H45.5273C46.8763 14.2197 47.4617 14.9849 47.4617 15.987C47.4617 17.0643 46.8094 17.9467 45.4539 17.9467H42.173Z"
      fill="#016FD0"
    />
    <path
      d="M47.8224 17.9467V16.6099H50.9033C51.3591 16.6099 51.5565 16.3635 51.5565 16.0933C51.5565 15.8345 51.3597 15.5728 50.9033 15.5728H49.5111C48.3009 15.5728 47.627 14.8355 47.627 13.7285C47.627 12.7412 48.2441 11.7891 50.0424 11.7891H53.0402L52.392 13.1745H49.7994C49.3037 13.1745 49.1512 13.4346 49.1512 13.683C49.1512 13.9382 49.3397 14.2197 49.7183 14.2197H51.1767C52.5257 14.2197 53.1111 14.9849 53.1111 15.987C53.1111 17.0643 52.4588 17.9467 51.1033 17.9467H47.8224Z"
      fill="#016FD0"
    />
  </svg>
);

const AmericanExpressIcon = (props) => <Icon component={svg} {...props} />;

export default AmericanExpressIcon;
