/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="12" height="12" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="10" height="10" rx="3" fill="#d9d9d9" />
    <rect x="4" y="3" width="10" height="10" rx="3" fill="white" />
    <rect x="5" y="4" width="10" height="10" rx="3" fill="#d9d9d9" />
  </svg>
);

const CopyIcon = (props) => <Icon component={svg} {...props} />;

export default CopyIcon;
