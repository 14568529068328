import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="7.99988" y="4" width="20" height="24" rx="2" fill="#C7BCE4" />
    <path
      d="M5.33324 8H18.6668C19.398 8 20 8.60204 20 9.33324V22.6668C20 23.398 19.3981 24 18.6668 24H5.33324C4.60204 24 4 23.398 4 22.6668V9.33324C4 8.60204 4.60204 8 5.33324 8Z"
      fill="#5D5AB5"
    />
    <path
      d="M7.53381 21L10.7696 15.986L7.80581 11H10.1908L11.8087 14.1868C11.9574 14.4892 12.0596 14.7148 12.1155 14.8634H12.1364C12.2432 14.6218 12.3549 14.3869 12.4713 14.159L14.2006 11H16.3904L13.3498 15.9581L16.467 21H14.1377L12.2688 17.4993C12.1808 17.3505 12.1062 17.194 12.0457 17.0321H12.0179C11.9632 17.1908 11.8908 17.3428 11.8016 17.4852L9.87694 21H7.53381Z"
      fill="#C7BCE4"
    />
  </svg>
);

const DownloadReportIcon = (props) => <Icon component={svg} {...props} />;

export default DownloadReportIcon;
