import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="30" height="18" viewBox="-2 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.7366 13.9209V13.6016H27.6538L27.5572 13.8145L27.4606 13.6016H27.3778V13.9209H27.433V13.6814L27.5296 13.8943H27.5986L27.6952 13.6814V13.9209H27.7366ZM27.1846 13.9209V13.6548H27.295V13.6016H27.019V13.6548H27.1294V13.9209H27.1846Z"
      fill="#00A2E5"
    />
    <path d="M17.9263 15.2906H10.4604V2.35596H17.9263V15.2906Z" fill="#7375CF" />
    <path
      d="M10.9431 8.82494C10.9431 6.20341 12.2127 3.86134 14.1999 2.35763C12.7509 1.25313 10.9155 0.601074 8.92832 0.601074C4.22251 0.601074 0.399902 4.28717 0.399902 8.82494C0.399902 13.3627 4.22251 17.0488 8.92832 17.0488C10.9155 17.0488 12.7509 16.3967 14.1999 15.2922C12.2127 13.7885 10.9431 11.4465 10.9431 8.82494Z"
      fill="#EB001B"
    />
    <path
      d="M27.9997 8.82396C27.9997 13.3617 24.1771 17.0478 19.4713 17.0478C17.4841 17.0478 15.6487 16.3958 14.1997 15.2913C16.1869 13.7876 17.4565 11.4455 17.4565 8.82396C17.4565 6.20244 16.1869 3.86037 14.1997 2.35665C15.6487 1.25215 17.4841 0.600098 19.4713 0.600098C24.1771 0.600098 27.9997 4.2862 27.9997 8.82396Z"
      fill="#00A2E5"
    />
  </svg>
);

const MaestroCardIcon = (props) => <Icon component={svg} {...props} />;

export default MaestroCardIcon;
