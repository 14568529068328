/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="2" fill="#F4F2FA" />
    <path
      d="M7.5 7C7.5 5.89543 8.39543 5 9.5 5H18.125H22.5586C23.1187 5 23.6531 5.23487 24.0319 5.64748L26.625 8.47222L28.3385 10.7121C28.6054 11.061 28.75 11.488 28.75 11.9273V17.5V28C28.75 29.1046 27.8546 30 26.75 30H9.5C8.39543 30 7.5 29.1046 7.5 28V7Z"
      fill="#C7BCE4"
    />
    <circle cx="26.25" cy="26.25" r="8.75" fill="#7876C1" />
    <rect x="25" y="20" width="2.5" height="7.5" rx="1.25" fill="#C7BCE4" />
    <rect
      x="31.5137"
      y="28.3586"
      width="2.5"
      height="6.14723"
      rx="1.25"
      transform="rotate(123.117 31.5137 28.3586)"
      fill="#C7BCE4"
    />
  </svg>
);

const ActivityLog = (props) => <Icon component={svg} {...props} />;

export default ActivityLog;
