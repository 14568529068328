/* eslint-disable */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Select, Button, Divider, Input, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { CURRENCY_FORMATS } from 'constants/currency';
import AddIcon from 'components/Icons/TickIcon';
import { useReactiveVar } from '@apollo/client';
import { userVar } from 'graphql/cache';
import DeleteIcon from 'components/Icons/DeleteIcon';
import ConfirmModal from 'components/ConfirmModal';

const { Option } = Select;

const Currency = ({ configText, prop, curr, onChange, handleState }) => {
  const user = useReactiveVar(userVar);
  const inputRef = useRef();
  const [currency, setCurrency] = useState('');
  const [showSearch, setShowSearch] = useState('');
  const [value, setValue] = useState(null);
  const [currencyOptions, setCurrencyOptions] = useState([...CURRENCY_FORMATS]);
  const [confirmModal, showConfirmModal] = useState(false);

  useEffect(() => {
    if (!user?.config?.currencies) {
      return;
    }

    // update currencyOptions
    setCurrencyOptions((prev) => {
      const newData = [...prev];
      user.config.currencies.forEach((currency) => {
        const currencyExist = isExists(newData, currency);
        if (currencyExist === -1) {
          newData.push({ symbol: currency, code: currency, custom: true });
        }
        // if "adding" then remove adding flag
        else if (currencyExist > 0 && newData[currencyExist]?.custom === 'adding') {
          newData[currencyExist].custom = true;
        }
      });

      return newData;
    });
  }, [user]);

  useEffect(() => {
    setCurrency(prop.curr || curr || configText('currency'));
  }, [prop.curr, curr]);

  const debounceChange = _.debounce(function (v) {
    onChange && onChange(v);
  }, 200);

  const setCurr = (value) => {
    if (!value) return;
    let c = value.trim();

    setCurrency(c);
    debounceChange(c);
  };

  const getValue = useMemo(() => {
    const c = CURRENCY_FORMATS.find((item) => item.symbol === currency);
    return c ? `${c.symbol} (${c.code})` : currency;
  }, [currency]);

  const onValueChange = (e) => {
    setValue(e.target.value.substr(0, 10));
  };

  const onSelect = (v) => {
    setCurr(v.split(' ')[0]);
  };

  const onCustomAdd = () => {
    if (value.length === 0) {
      inputRef?.current.focus();
      return;
    }

    setCurr(value);
    setValue(null);

    // dont add to custom if already exists
    if (isExists(currencyOptions, value) === -1) {
      setCurrencyOptions((prev) => [...prev, { symbol: value, code: value, custom: 'adding' }]);

      handleState('currencies', null, value);
    }
  };

  const deleteCurrency = (e, _currency) => {
    e.stopPropagation();
    showConfirmModal(_currency);
  };

  const confirmDeleteCurrency = () => {
    const _currency = confirmModal;
    showConfirmModal(false);
    // remove from list
    setCurrencyOptions((prev) => prev.filter((f) => f.symbol !== _currency));

    // if deleteing the selected currency change currency in proposal to default  currency of user
    if (currency === _currency) {
      onSelect(user?.profile?.currency || '$');
    }
    // api call
    handleState('currencies', 'remove', [_currency]);
  };

  const isExists = (option, currency) => {
    return option.findIndex((e) => e.symbol === currency);
  };

  useEffect(() => {
    // add custom currency to list
    if (currency) {
      if (isExists(currencyOptions, currency) === -1) {
        setCurrencyOptions((prev) => [...prev, { symbol: currency, code: currency, custom: true }]);
      }
    }
  }, [currency]);

  return (
    <>
      <Select
        className="currency-select"
        value={getValue}
        onChange={onSelect}
        filterOption={(input, option) =>
          (option?.label || '').toUpperCase().includes(input.toUpperCase())
        }
        onDropdownVisibleChange={(type) => {
          setValue(null);
          setShowSearch(type);
        }}
        showSearch={showSearch}
        // NOTE: might require, if we want to close on click
        // open={open}
        // onInputKeyDown={() => setOpen(true)}
        // onFocus={() => setOpen(true)}
        // onBlur={() =>  setOpen(false)}
        // onSearch={setCurr}
        optionLabelProp="hello"
        dropdownRender={(menu) => (
          <div className="tablist-currency-menu">
            {menu}

            <Divider />
            {value === null ? (
              <Button
                className="custom-button"
                onClick={() => {
                  setTimeout(() => inputRef?.current.focus(), 200);
                  setValue('');
                }}>
                Add custom
              </Button>
            ) : (
              <div className="input-wrap">
                <Input
                  ref={inputRef}
                  value={value}
                  onChange={onValueChange}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                    if (e.key === 'Enter') {
                      onCustomAdd();
                    }
                  }}
                  placeholder="Currency"
                />
                <Button className="add-button" onClick={onCustomAdd}>
                  <AddIcon />
                </Button>
              </div>
            )}
          </div>
        )}
        // options={currencyOptions.map((currency_format) => ({
        //   label: `${currency_format.symbol} (${currency_format.code})`,
        //   value: currency_format.symbol,
        // }))}
        notFoundContent={<></>}>
        {currencyOptions.map((item) => (
          <Option key={item.symbol} label={`${item.code} ${item.symbol}`}>
            {item.custom && item.symbol !== user?.profile?.currency && (
              <div className="custom-currency-suffix">
                {item.custom === 'adding' ? (
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />} />
                ) : (
                  <DeleteIcon
                    size="18"
                    className="delete-icon"
                    onClick={(e) => deleteCurrency(e, item.symbol)}
                  />
                )}
              </div>
            )}
            {item.symbol} ({item.code})
          </Option>
        ))}
      </Select>

      {confirmModal && (
        <ConfirmModal
          title="Delete Currency?"
          body="This action cannot be undone"
          visible={!!confirmModal}
          cancelText="CANCEL"
          confirmText="YES, DELETE"
          onCancel={() => showConfirmModal(false)}
          onConfirm={confirmDeleteCurrency}
        />
      )}
    </>
  );
};

Currency.propTypes = {
  configText: PropTypes.func.isRequired,
  prop: PropTypes.instanceOf(Object).isRequired,
  curr: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  handleState: PropTypes.func.isRequired,
};

export default Currency;
