import React, { useEffect, useState } from 'react';
import { ContentState } from 'draft-js';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { FormEditor, FormView } from './components';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';

const FormComponent = ({ block, blockProps }) => {
  const contentState = ContentState.createFromBlockArray([block]);
  const ent = block.getEntityAt(0);
  const entity = contentState.getEntity(ent);

  const { data } = entity;

  const [form, setForm] = useState(data?.data ? JSON.parse(JSON.stringify(data.data)) : []);
  const [config, setConfig] = useState(
    data.config ? JSON.parse(JSON.stringify(data.config)) : { editable: true, columns: 1 }
  );
  const [isDraggable, setIsDraggable] = useState(true);

  useEffect(() => {
    if (!_.isEqual(data.data, form)) {
      setForm(JSON.parse(JSON.stringify(data.data)));
    }
    if (data.config && !_.isEqual(data.config, config)) {
      setConfig(JSON.parse(JSON.stringify(data.config)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.data, data.config]);

  const _save = (formCopy, configCopy) => {
    const entityKey = block.getEntityAt(0);
    if (entityKey) {
      contentState.replaceEntityData(entityKey, {
        data: formCopy || JSON.parse(JSON.stringify(form)),
        config: configCopy || config,
      });
      blockProps.update(block);
    }
  };

  const handleFocus = (e) => {
    blockProps.handleEditComponent(true);
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDuplicate = () => {
    const formData = {
      form,
      config,
    };
    blockProps.handleDuplicateElement(formData, 'form', block.getKey());
    blockProps.handleEditComponent(false);
  };

  const handleEdit = (value) => {
    const configCopy = { ...config };
    configCopy['editable'] = typeof value === 'boolean' ? value : true;

    setConfig(configCopy);

    blockProps.handleEditComponent(true);
    _save(form, configCopy);
  };

  const handleBlur = () => {
    blockProps.handleEditComponent(false);
  };

  const handleColumn = (value) => {
    const configCopy = { ...config };
    configCopy['columns'] = value;

    setConfig(configCopy);

    _save(form, configCopy);
  };

  const handleRemove = (e) => {
    // e.preventDefault();
    blockProps && blockProps.onRemove(block.getKey());
    blockProps.handleEditComponent(false);
  };

  const saveForm = ({ formCopy, editable, columns }) => {
    const configCopy = { ...config };
    configCopy['editable'] = editable;
    configCopy['columns'] = columns || configCopy.columns;

    setForm(formCopy);
    setConfig(configCopy);

    _save(formCopy, configCopy);
    blockProps.handleEditComponent(false);
  };

  return (
    <ComponentWrapper
      showDrag
      showActionButtons={config.editable ? false : true}
      config={config}
      showDuplicate
      showEdit
      showDelete
      sectionName={blockProps.sectionName}
      setDraggingElement={blockProps.setDraggingElement}
      blockKey={block.getKey()}
      duplicate={handleDuplicate}
      remove={handleRemove}
      onEdit={handleEdit}
      onFocus={handleFocus}
      onBlur={handleBlur}
      handleColumn={handleColumn}
      handleEditComponent={blockProps.handleEditComponent}
      isDraggable={isDraggable}
      setDropDisabled={blockProps.setDropDisabled}
      componentType="Form">
      <div className="form-container">
        {config.editable ? (
          <FormEditor
            form={form}
            saveForm={saveForm}
            deleteForm={handleRemove}
            handleFocus={handleFocus}
            closeForm={() => handleEdit(false)}
            configText={blockProps.configText}
            language={blockProps.language}
          />
        ) : (
          <FormView
            form={form}
            columns={config.columns}
            saveForm={saveForm}
            handleFocus={handleFocus}
            setIsDraggable={setIsDraggable}
            configText={blockProps.configText}
            language={blockProps.language}
          />
        )}
      </div>
    </ComponentWrapper>
  );
};

FormComponent.propTypes = {
  block: PropTypes.instanceOf(Object).isRequired,
  blockProps: PropTypes.instanceOf(Object).isRequired,
};

export default FormComponent;
