import React from 'react';
import './ContentLoader.scss';

const ContentLoader = ({ length = 12 }) => {
  return (
    <div className="content-library-skeleton-container">
      <div className="item-row covers-card">
        {Array.from({ length }, (_, x) => (
          <div className="value" key={x}>
            <div className="content-library-skeleton" />
          </div>
        ))}
      </div>
    </div>
  );
};

export { ContentLoader };
