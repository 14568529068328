import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 4H3.33333H14"
      stroke="#FF4E4E"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33594 3.99992V2.66659C5.33594 2.31296 5.47641 1.97382 5.72646 1.72378C5.97651 1.47373 6.31565 1.33325 6.66927 1.33325H9.33594C9.68956 1.33325 10.0287 1.47373 10.2787 1.72378C10.5288 1.97382 10.6693 2.31296 10.6693 2.66659V3.99992M12.6693 3.99992V13.3333C12.6693 13.6869 12.5288 14.026 12.2787 14.2761C12.0287 14.5261 11.6896 14.6666 11.3359 14.6666H4.66927C4.31565 14.6666 3.97651 14.5261 3.72646 14.2761C3.47641 14.026 3.33594 13.6869 3.33594 13.3333V3.99992H12.6693Z"
      stroke="#FF4E4E"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const DeleteIcon = (props) => <Icon component={svg} {...props} />;

export default DeleteIcon;
