import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Badge, message, Empty } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { getFilteredCoversOrMedia } from 'helpers/contentlibrary';
import { GET_ALL_MEDIAS_LIBRARIES } from 'graphql/queries/contentLibraryQueries';
import {
  UPDATE_CONTENT_LIBRARY,
  CONTENT_LIBRARY_MEDIA_DELETE,
} from 'graphql/mutations/contentLibraryMutations';
import { ContentLoader } from '../ContentLoader/ContentLoader';

import ConfirmModal from 'components/ConfirmModal';
import ContentLibraryTree from '../ContentLibraryTree/ContentLibraryTree';
import CoverOrMediaCard from '../CoverOrMediaCard/CoverOrMediaCard';

const MediaTab = forwardRef(
  ({ filterItems, user, searchValue, onFolderChange, sortValue, isLocked }, ref) => {
    const [medias, setMedias] = useState([]);
    const [selectedMediaId, setSelectedMediaId] = useState('');
    const [selectedMediaEditValue, setSelectedMediaEditValue] = useState('');
    const [selectedMediaStarred, setSelectedMediaStarred] = useState('');

    const { loading: isFetchingMedias, refetch: refetchMedias } = useQuery(
      GET_ALL_MEDIAS_LIBRARIES,
      {
        notifyOnNetworkStatusChange: true,
        variables: {
          type: 'medias',
        },
        onCompleted: ({ fetchContentLibraryMediasItems }) => {
          setMedias(fetchContentLibraryMediasItems.slice());
        },
      }
    );

    const [updateContentItem] = useMutation(UPDATE_CONTENT_LIBRARY, {
      async onCompleted() {
        setMedias(
          medias.map((media) =>
            media._id === selectedMediaId
              ? selectedMediaStarred !== ''
                ? { ...media, starred: selectedMediaStarred }
                : { ...media, name: selectedMediaEditValue ? selectedMediaEditValue : media.name }
              : media
          )
        );
        setSelectedMediaId('');
        setSelectedMediaEditValue('');
        setSelectedMediaStarred('');
      },
    });

    const [deleteMedia] = useMutation(CONTENT_LIBRARY_MEDIA_DELETE, {
      onCompleted() {
        let tempMedias = medias.filter((media) => media._id !== selectedMediaId);
        setMedias(tempMedias);
        message.success('Media deleted successfully');
        showConfirmModal(false);
        setSelectedMediaId('');
      },
      onError() {
        message.error('Media deletion failed');
        showConfirmModal(false);
      },
    });

    useImperativeHandle(ref, () => ({
      async reloadItems() {
        await refetchMedias();
      },
    }));

    const [selectedFolderKey, changeSelectedFolderKey] = useState('');
    const [confirmModal, showConfirmModal] = useState(false);

    const onSelect = (selectedKeys) => {
      changeSelectedFolderKey(selectedKeys[0]);
      onFolderChange(selectedKeys[0]);
    };

    useEffect(() => {
      refetchMedias({
        type: 'medias',
        key: selectedFolderKey,
      });
    }, [refetchMedias, selectedFolderKey]);

    const filteredMedias = getFilteredCoversOrMedia({
      medias,
      filterItems,
      searchValue,
      sortValue,
    });

    localStorage.setItem('selectedFolderKey', selectedFolderKey);

    const { starred: starredItems, all: allItems } = filteredMedias;
    const isMediasEmpty = !(filteredMedias.starred.length || filteredMedias.all.length);

    const starOrUnstar = ({ starred, mediaId }) => {
      updateContentItem({
        variables: {
          id: mediaId,
          type: 'medias',
          item: { starred },
        },
      });
    };

    const saveMediaTitle = ({ mediaId }) => {
      updateContentItem({
        variables: {
          id: mediaId,
          type: 'medias',
          item: { name: selectedMediaEditValue },
        },
      });
    };

    const confirmDeleteMedia = () => {
      console.log(selectedMediaId);
      deleteMedia({
        variables: {
          _id: selectedMediaId,
          folderKey: selectedFolderKey,
        },
      });
    };

    const onClickMenuItem = async ({ actionType, _id: mediaId, starred, name }) => {
      setSelectedMediaId(mediaId);
      if (actionType === 'delete') {
        showConfirmModal(true);
      } else if (actionType === 'rename') {
        setSelectedMediaEditValue(name);
      } else if (actionType === 'starOrUnstar') {
        setSelectedMediaStarred(starred);
        starOrUnstar({ starred, mediaId });
      } else if (actionType === 'save') {
        saveMediaTitle({ mediaId });
      } else {
        message.warning('Coming soon 😊');
      }
    };

    return (
      <div className="content-library-item">
        <ContentLibraryTree
          onSelect={onSelect}
          type="medias"
          onUpdate={() =>
            refetchMedias({
              type: 'medias',
              key: selectedFolderKey,
            })
          }
          userId={user && user._id}
          userRole={user?.roles && user?.roles[0]}
          isLocked={isLocked}
        />
        <div className="split-d2">
          {isFetchingMedias ? (
            <ContentLoader />
          ) : (
            <>
              {starredItems.length ? (
                <div className="stared-header">
                  MY STARRED MEDIAS
                  <Badge className="star-badge" count={starredItems.length} />
                </div>
              ) : null}

              <div className="scroll-wrapper">
                <React.Fragment>
                  {isMediasEmpty && (
                    <div className="item-row empty-wrapper">
                      <Empty
                        className="empty-view"
                        description="Looks like you have no media files yet"
                      />
                    </div>
                  )}
                  <div className="item-row medias-card">
                    {starredItems.map((item, itemIndex) => {
                      return (
                        <CoverOrMediaCard
                          onDragStart={(ev) => {
                            ev.dataTransfer.setData('data', JSON.stringify(item));
                          }}
                          item={item}
                          type="media"
                          key={item._id}
                          index={itemIndex}
                          userId={user && user._id}
                          userRole={user?.roles && user?.roles[0]}
                          onClickMenuItem={onClickMenuItem}
                          itemEditable={selectedMediaId === item._id}
                          setEditValue={setSelectedMediaEditValue}
                          editValue={selectedMediaEditValue}
                        />
                      );
                    })}
                  </div>
                  {starredItems.length ? <div className="divider-line" /> : null}
                  <div className="item-row medias-card">
                    {allItems.map((item, itemIndex) => {
                      return (
                        <CoverOrMediaCard
                          onDragStart={(ev) => {
                            ev.dataTransfer.setData('data', JSON.stringify(item));
                          }}
                          item={item}
                          type="media"
                          key={item._id}
                          index={itemIndex}
                          userId={user && user._id}
                          userRole={user?.roles && user?.roles[0]}
                          onClickMenuItem={onClickMenuItem}
                          itemEditable={selectedMediaId === item._id}
                          setEditValue={setSelectedMediaEditValue}
                          editValue={selectedMediaEditValue}
                        />
                      );
                    })}
                  </div>
                </React.Fragment>
              </div>
            </>
          )}
        </div>
        <ConfirmModal
          title="Delete media?"
          body="This action cannot be undone"
          visible={confirmModal}
          cancelText="CANCEL"
          confirmText="YES, DELETE"
          onCancel={() => showConfirmModal(false)}
          onConfirm={confirmDeleteMedia}
        />
      </div>
    );
  }
);

MediaTab.defaultProps = {
  searchValue: '',
  isLocked: false,
};

MediaTab.propTypes = {
  filterItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  onFolderChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  sortValue: PropTypes.string.isRequired,
  isLocked: PropTypes.bool,
};

export default MediaTab;
