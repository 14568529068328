import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 2C0 0.89543 0.895431 0 2 0H7.5H10.3593C10.9262 0 11.4666 0.240643 11.8459 0.662071L13.5 2.5L14.6 3.96667C14.8596 4.31286 15 4.73393 15 5.16667V9V16C15 17.1046 14.1046 18 13 18H2C0.89543 18 0 17.1046 0 16V2Z"
      fill="#C9C8E6"
    />
    <path
      d="M11 10.0526L7.5 13M7.5 13L4 10.0526M7.5 13L7.5 5"
      stroke="#7876C1"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const DownloadIcon = (props) => <Icon component={svg} {...props} />;

export default DownloadIcon;
