// used for grid breakpoints in dashboard header, skeelton, proposal item,
export const proposalItem = {
  proposal: { xs: 11, sm: 8, xl: 7 },
  client: { xs: 11, sm: 6, lg: 5 },
  watched: { xs: 0, lg: 3 },
  opened: { xs: 0, sm: 3, lg: 2 },
  quote: { xs: 0, lg: 3, xl: 3 },
  status: { xs: 0, sm: 3 },
  action: { xs: 2, lg: 1 },
};
