import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="31" height="36" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.3022 34.2515L13.8148 29.8592L15.6356 32.7111C15.6356 32.7111 14.4789 32.5051 13.4253 33.009C12.3716 33.5128 11.3022 34.2515 11.3022 34.2515Z"
      fill="#0049D6"
    />
    <path
      d="M9.50249 32.1569C9.30433 31.7052 8.8952 31.3811 8.41016 31.2916L2.43538 30.1886C1.0242 29.928 0 28.6974 0 27.2624V9.4376C0 7.24315 1.07541 5.18801 2.87834 3.937L6.73511 1.26087C8.2588 0.203614 10.137 -0.212322 11.9646 0.102782L25.7458 2.47884C28.6002 2.97099 30.6858 5.44689 30.6858 8.34349V21.8467C30.6858 23.4451 30.0428 24.9763 28.9017 26.0955L23.9935 30.9093C22.1323 32.7347 19.4832 33.5112 16.9311 32.9795L14.0796 32.3855C13.5712 32.2796 13.0445 32.4464 12.6897 32.8255L11.4907 34.1071C11.1257 34.4971 10.4808 34.3868 10.2662 33.8977L9.50249 32.1569Z"
      fill="#0446DE"
    />
    <path
      d="M6.54188 6.08901C3.54571 5.48017 0.743652 7.7701 0.743652 10.8275V26.0544C0.743652 27.4888 1.76699 28.719 3.17742 28.9802L9.09647 30.0764C9.59127 30.168 10.0062 30.5035 10.1994 30.9682L10.7304 32.2457C10.8914 32.6327 11.4057 32.7107 11.6741 32.3888L12.6986 31.16C13.0066 30.7906 13.4741 30.5929 13.9537 30.6292L18.6601 30.9858C21.0347 31.1657 23.0606 29.2875 23.0606 26.906V11.8775C23.0606 10.4625 22.0642 9.24324 20.6775 8.96147L6.54188 6.08901Z"
      fill="#0049D6"
    />
    <path
      d="M6.2208 6.07429C4.38437 5.71815 2.67871 7.12481 2.67871 8.99546V24.6524C2.67871 26.1313 3.76476 27.3857 5.22837 27.5974L9.28865 28.1846C9.78295 28.2561 10.2084 28.5709 10.4214 29.0226L10.992 30.2328C11.1498 30.6207 11.6628 30.703 11.9339 30.3838L12.7934 29.3866C13.1251 29.0016 13.6304 28.8126 14.1334 28.8853L19.659 29.6845C21.4532 29.944 23.0605 28.5525 23.0605 26.7396V11.794C23.0605 10.369 22.0503 9.1441 20.6515 8.87282L6.2208 6.07429Z"
      fill="white"
    />
    <path
      d="M16.7387 21.7984L8.14945 20.2926C7.52453 20.183 7.01606 20.683 7.25781 21.2696C8.04308 23.175 10.2364 26.1704 14.1222 25.1717C14.9224 24.966 16.7322 23.9257 17.2422 22.5401C17.3696 22.194 17.102 21.8621 16.7387 21.7984Z"
      fill="#0054F8"
    />
    <ellipse
      cx="8.44261"
      cy="14.7005"
      rx="1.52289"
      ry="2.61066"
      transform="rotate(-4.90348 8.44261 14.7005)"
      fill="#0054F8"
    />
    <ellipse
      cx="16.4919"
      cy="16.1871"
      rx="1.52289"
      ry="2.61066"
      transform="rotate(-4.90348 16.4919 16.1871)"
      fill="#0054F8"
    />
  </svg>
);

const ChatwayBrandIcon = (props) => <Icon component={svg} {...props} />;

export default ChatwayBrandIcon;
