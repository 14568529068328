import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="5" width="10" height="1" rx="0.5" transform="rotate(90 5 0)" fill="#05034D" />
    <path d="M8 7L4.5 10L1 7" stroke="#05034D" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const ArrowDown = (props) => <Icon component={svg} {...props} />;

export default ArrowDown;
