import React from 'react';
import { Row, Col } from 'antd';

import './DashboardListHeader.scss';
import { proposalItem } from 'helpers/grid';

const DashboardListHeader = () => {
  return (
    <Row className="dashboard-list-header" justify="middle" align="top" gutter={8}>
      <Col {...proposalItem.proposal} className="header-value value-proposal-title">
        Proposal Name
      </Col>
      <Col {...proposalItem.client} className="header-value value-prospect-name">
        Prospect
      </Col>
      <Col {...proposalItem.watched} className="header-value value-watched">
        Watched
      </Col>
      <Col {...proposalItem.opened} className="header-value value-opened">
        Opened
      </Col>
      <Col {...proposalItem.quote} className="header-value value-quote">
        Quote
      </Col>
      <Col {...proposalItem.status} className="header-value value-status">
        Status
      </Col>
      <Col {...proposalItem.action} className="header-value action-column" />
    </Row>
  );
};

export default DashboardListHeader;
