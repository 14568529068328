import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 3.33398H7.5V7.08398L7.91667 9.58398L10 11.2507L12.0833 10.834L13.3333 9.58398L12.0833 7.08398L12.5 3.33398Z"
      fill="#7876C1"
    />
    <path
      d="M15.0809 3.82228C14.9691 3.71501 14.8179 3.65384 14.6595 3.65384H13.5714L12.381 2.5H7.61907L6.42859 3.65384H5.34056C5.18219 3.65384 5.03099 3.71501 4.9191 3.82228L1.66669 6.97575L4.19165 9.42301L5.23812 8.40875V16.3462C5.23812 16.9831 5.77145 17.5 6.42859 17.5H13.5714C14.2286 17.5 14.7619 16.9831 14.7619 16.3462V8.40875L15.8084 9.42301L18.3334 6.97575L15.0809 3.82228ZM10.5952 5.38461V7.69228C10.5952 8.00959 10.3274 8.2692 9.99999 8.2692C9.67261 8.2692 9.40475 8.00959 9.40475 7.69228V5.38461L7.61903 3.65385H12.3809L10.5952 5.38461Z"
      fill="#C7BCE4"
    />
  </svg>
);

const ShirtIcon = (props) => <Icon component={svg} {...props} />;

export default ShirtIcon;
