import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg viewBox="0 -6 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="8" y="14" width="32" height="16" rx="2" stroke="#C7BCE4" strokeWidth="2" />
    <rect x="37" y="21" width="2" height="27" rx="1" transform="rotate(90 37 21)" fill="#7876C1" />
    <path
      d="M35 19L38 22L35 25"
      stroke="#7876C1"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 25L10 22L13 19"
      stroke="#7876C1"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const FullWidthImageIcon = (props) => <Icon component={svg} {...props} />;

export default FullWidthImageIcon;
