import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.75 1C0.75 0.861929 0.861929 0.75 1 0.75H8.5H12.2806C12.355 0.75 12.4254 0.783082 12.4729 0.840267L14.702 3.52407L16.2056 5.69635C16.2345 5.73816 16.25 5.78779 16.25 5.83863V11.0526V19C16.25 19.1381 16.1381 19.25 16 19.25H1C0.861928 19.25 0.75 19.1381 0.75 19V1Z"
      stroke="#05034A"
      strokeWidth="1.5"
    />
    <path
      d="M12 10.4211L8.5 13M8.5 13L5 10.4211M8.5 13L8.5 6"
      stroke="#05034A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const DownloadUIIcon = (props) => <Icon component={svg} {...props} />;

export default DownloadUIIcon;
