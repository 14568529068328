import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 2C0 0.895431 0.895431 0 2 0L14 0C15.1046 0 16 0.895431 16 2V14C16 15.1046 15.1046 16 14 16L2 16C0.895431 16 0 15.1046 0 14L0 2Z"
      fill="#FF1B1B"
    />
    <rect
      x="5.41406"
      y="4"
      width="10"
      height="2"
      rx="1"
      transform="rotate(45 5.41406 4)"
      fill="white"
    />
    <rect
      x="12.4844"
      y="5.41406"
      width="10"
      height="2"
      rx="1"
      transform="rotate(135 12.4844 5.41406)"
      fill="white"
    />
  </svg>
);

const DeleteTableRowColumnIcon = (props) => <Icon component={svg} {...props} />;

export default DeleteTableRowColumnIcon;
