import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.1094 2.18453C4.67534 1.98265 5.28695 1.94554 5.87314 2.07753C6.45934 2.20952 6.99602 2.50518 7.4208 2.93014C7.84559 3.3551 8.14101 3.8919 8.27274 4.47814C8.40447 5.06437 8.36709 5.67595 8.16495 6.24178L18 16.0766L16.0756 18L6.24056 8.16612C5.67463 8.36801 5.06301 8.40511 4.47682 8.27312C3.89063 8.14114 3.35394 7.84547 2.92916 7.42051C2.50438 6.99555 2.20895 6.45875 2.07722 5.87251C1.94549 5.28628 1.98287 4.6747 2.18502 4.10887L4.21279 6.13749C4.33827 6.26741 4.48837 6.37104 4.65434 6.44233C4.8203 6.51362 4.9988 6.55115 5.17942 6.55272C5.36004 6.55429 5.53917 6.51987 5.70635 6.45147C5.87352 6.38308 6.02541 6.28207 6.15313 6.15435C6.28085 6.02663 6.38186 5.87476 6.45026 5.70758C6.51866 5.54041 6.55307 5.36129 6.5515 5.18067C6.54993 5.00006 6.51241 4.82156 6.44112 4.6556C6.36982 4.48964 6.26619 4.33955 6.13627 4.21406L4.1085 2.18363L4.1094 2.18453Z"
      fill="#7876C1"
    />
    <path
      d="M16.3967 2.28906L13.511 3.89237L13.19 5.49568L11.2665 7.41911L12.5497 8.7014L14.4723 6.77797L16.0756 6.45695L17.679 3.57135L16.3967 2.28906Z"
      fill="#C7BCE4"
    />
    <path
      d="M9.02197 12.8716L7.09849 10.9473L2.61036 15.4362L2.5224 15.5332C2.30186 15.803 2.19326 16.1471 2.21899 16.4946C2.24471 16.842 2.4028 17.1664 2.66065 17.4008C2.91851 17.6351 3.25646 17.7616 3.60483 17.7541C3.95319 17.7466 4.2854 17.6057 4.53294 17.3605L9.02197 12.8716Z"
      fill="#C7BCE4"
    />
  </svg>
);

const TechnicalIcon = (props) => <Icon component={svg} {...props} />;

export default TechnicalIcon;
