import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Button, DatePicker, Space, Input,
  Table, Tag, Menu, Dropdown, Typography
} from 'antd';

import DropdownIcon from 'components/Icons/DropdownIcon';

const { RangePicker } = DatePicker;
const { Text } = Typography;

const AffiliatesDetailsTable = ({
  selectedOption,
  filters,
  handleFilters,
  isFetchingAdminAffiliates,
  adminAffiliatesCount,
  limit,
  users,
  handleRowClick,
  handleAffiliateOption,
  refetch,
  debounceSearchHandler,
}) => {
  const affiliateDropdownMenu = (
    <Menu>
      <Menu.Item onClick={() => handleAffiliateOption('today')}>Today</Menu.Item>
      <Menu.Item onClick={() => handleAffiliateOption('all')}>All</Menu.Item>
      <Menu.Item onClick={() => handleAffiliateOption('custom')}>Custom</Menu.Item>
    </Menu>
  );

  const affiliateParentColumn = [
    {
      title: '#Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => moment(a.createdAt, "DD/MM/YYYY").unix() - moment(b.createdAt, "DD/MM/YYYY").unix(),
    },
    {
      title: 'Total Sons',
      dataIndex: 'totalSons',
      key: 'totalSons',
      sorter: (a, b) => a.totalSons - b.totalSons,
    },
    {
      title: 'Credit',
      dataIndex: 'credit',
      key: 'credit',
      sorter: (a, b) => a.credit - b.credit,
    },
    {
      dataIndex: 'userData',
      key: 'userData',
    },
  ];

  return (
    <div className="admin-table-wrapper">
      <div className="table-action">
        <h3>Total Affiliate Parents</h3>
        <div>

          <Space>
            <Input.Search
              width="200"
              placeholder="Email / Uid / Name"
              onChange={(e) => debounceSearchHandler(e.target.value)}
              onSearch={() => refetch()}
            />
            {selectedOption === 'custom' && (
              <RangePicker
                value={[moment(filters.startDate), moment(filters.endDate)]}
                onChange={(values) => {
                  let dataValue = values;
                  if (!values) {
                    dataValue = [moment(), moment()];
                  }
                  handleFilters(
                    'date',
                    dataValue.map((el) => el.toISOString())
                  );
                  refetch();
                }}
                ranges={{
                  Today: [moment(), moment()],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                }}
                renderExtraFooter={() => (
                  <>
                    <Tag
                      color="blue"
                      onClick={() => {
                        handleFilters('date', [moment().subtract(10, 'years'), moment()]);
                      }}>
                      All
                    </Tag>
                  </>
                )}
              />
            )}
            <Dropdown overlay={affiliateDropdownMenu} placement="bottomLeft" trigger={['click']}>
              <Button>
                {selectedOption.toUpperCase()}
                <DropdownIcon />
              </Button>
            </Dropdown>
          </Space>
        </div>
      </div>
      <Table
        loading={isFetchingAdminAffiliates}
        className="affiliate-parent"
        dataSource={users}
        onRow={(record) => {
          return {
            onClick: () => {
              handleRowClick(record);
            },
          };
        }}
        columns={affiliateParentColumn}
        rowKey={'id'}
        summary={pageData => {
          let totalParent = 0;
          let allSons = 0;
          let allCredit = 0;

          pageData.forEach(({ userData: { affiliates }, credit }) => {
            totalParent += 1;
            allSons += affiliates?.length ?? 0;
            allCredit += credit ?? 0;
          });

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <Text type="danger">{totalParent}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <Text>{allSons}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <Text>{allCredit}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
        pagination={{
          total: adminAffiliatesCount,
          defaultPageSize: limit,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30'],
        }}
      />
    </div>
  );
};

AffiliatesDetailsTable.defaultProps = {
  selectedOption: 'all',
  users: [],
  adminAffiliatesCount: 0,
  limit: 10,
  isFetchingAdminAffiliates: false,
};

AffiliatesDetailsTable.propTypes = {
  selectedOption: PropTypes.string.isRequired,
  filters: PropTypes.instanceOf(Object),
  handleFilters: PropTypes.func.isRequired,
  isFetchingAdminAffiliates: PropTypes.bool,
  limit: PropTypes.number,
  adminAffiliatesCount: PropTypes.number,
  users: PropTypes.arrayOf(PropTypes.shape({})),
  handleRowClick: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  debounceSearchHandler: PropTypes.func.isRequired,
};

export default AffiliatesDetailsTable;
