import { gql } from '@apollo/client';

export const FETCH_USERS_ACTIVITY = gql`
  query FetchActivitiesQuery(
    $limit: Float!
    $offset: Float!
    $search: String
    $selectedDate: [String!]
    $filterItems: [ActivityFilterItems!]
    $teamList: [ActivityTeamList!]
  ) {
    fetchActivities(
      limit: $limit
      offset: $offset
      search: $search
      selectedDate: $selectedDate
      filterItems: $filterItems
      teamList: $teamList
    ) {
      activityCount
      activities {
        _id
        proposal {
          _id
          name
          proposalInfo
        }
        user {
          _id
          name
          image
          userInfo
        }
        teamId
        type
        createdAt
      }
    }
  }
`;
