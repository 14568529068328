import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="110" height="88" viewBox="0 0 110 88" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="58" cy="80.5" rx="52" ry="7.5" fill="url(#paint0_radial)" fillOpacity="0.8" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.9137 46.1094L40.7481 66.8233C40.6519 67.2091 40.8867 67.5999 41.2725 67.6961C41.4664 67.7445 41.6716 67.7101 41.8392 67.6012L61.4847 54.83L45.9137 46.1094Z"
      fill="#DEEEFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.9137 46.1094L40.7481 66.8233C40.6519 67.2091 40.8867 67.5999 41.2725 67.6961C41.4664 67.7445 41.6716 67.7101 41.8392 67.6012L61.4847 54.83L45.9137 46.1094Z"
      fill="url(#paint1_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M109.066 2.10618L76.6203 64.3222C76.2525 65.0274 75.3828 65.3009 74.6776 64.9332C74.6378 64.9124 74.5989 64.8897 74.5612 64.8653L38.4193 41.4804L0.955538 28.0839C0.206685 27.8162 -0.183304 26.992 0.0844734 26.2432C0.248501 25.7845 0.633333 25.4398 1.10728 25.3271L107.456 0.0393872C108.23 -0.144588 109.006 0.333493 109.19 1.10721C109.27 1.44429 109.226 1.79897 109.066 2.10618Z"
      fill="#C7BCE4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.2983 14.8854L30.874 38.8467L38.5884 67.2295C38.797 67.997 39.5882 68.45 40.3557 68.2414C40.8742 68.1005 41.2711 67.6826 41.3852 67.1575L46.0402 45.7275L87.0057 16.1298C87.328 15.897 87.4005 15.4469 87.1677 15.1246C86.9699 14.8508 86.6084 14.7513 86.2983 14.8854Z"
      fill="#DEEEFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.2983 14.8854L30.874 38.8467L38.5884 67.2295C38.797 67.997 39.5882 68.45 40.3557 68.2414C40.8742 68.1005 41.2711 67.6826 41.3852 67.1575L46.0402 45.7275L87.0057 16.1298C87.328 15.897 87.4005 15.4469 87.1677 15.1246C86.9699 14.8508 86.6084 14.7513 86.2983 14.8854Z"
      fill="url(#paint2_linear)"
    />
    <defs>
      <radialGradient
        id="paint0_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(58 80.5) rotate(90) scale(7.5 52)">
        <stop stopColor="#C7BCE4" />
        <stop offset="1" stopColor="#C7BCE4" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint1_linear"
        x1="49.3628"
        y1="49.2461"
        x2="53.4448"
        y2="58.5004"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#7876C1" />
        <stop offset="1" stopColor="#C7BCE4" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="53.5694"
        y1="45.3343"
        x2="47.8328"
        y2="34.8922"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#7876C1" />
        <stop offset="1" stopColor="#C7BCE4" />
      </linearGradient>
    </defs>
  </svg>
);

const ShareIcon = (props) => <Icon component={svg} {...props} />;

export default ShareIcon;
