/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.646447 0.14547C0.841709 -0.0497921 1.15829 -0.0497921 1.35355 0.14547L5.7066 4.50034L1.35355 8.85404C1.15829 9.0493 0.841709 9.0493 0.646447 8.85404C0.451184 8.65878 0.451184 8.3422 0.646447 8.14694L4.29238 4.50034L0.646447 0.852577C0.451184 0.657315 0.451184 0.340732 0.646447 0.14547Z"
      fill="#000624"
      fillOpacity="0.3"
    />
  </svg>
);

const AngleRightIcon = (props) => <Icon component={svg} {...props} />;

export default AngleRightIcon;
