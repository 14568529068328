import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <g clip-path="url(#clip0_14114_26680)">
    <path d="M14.6693 1.33325L7.33594 8.66659" stroke="white" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.6693 1.33325L10.0026 14.6666L7.33594 8.66659L1.33594 5.99992L14.6693 1.33325Z" stroke="white" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_14114_26680">
      <rect width="16" height="16" fill="white"/>
    </clipPath>
  </defs>
</svg>
);

const SendIcon2 = (props) => <Icon component={svg} {...props} />;

export default SendIcon2;
