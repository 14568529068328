import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import utils from 'utils/utils';
import Loader from 'components/Loader';
import NotFound from 'pages/NotFound';
import { DOWNLOAD_PROPOSAL_PDF } from 'graphql/queries/proposalQueries';

const ProposalDownloadPdf = () => {
  const [downloadProposalPDFStatus, setDownloadProposalPDFStatus] = useState(false);

  const [downloadProposalPDF] = useLazyQuery(DOWNLOAD_PROPOSAL_PDF, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        window.open(data.downloadProposalPDF, '_self');
        setDownloadProposalPDFStatus('Downloaded, you can close the window now.');
        window.close();
      }
    },
    onError: (error) => {
      setDownloadProposalPDFStatus(error);
    },
  });

  useEffect(() => {
    const id = utils.getQueryStringValue('id');

    downloadProposalPDF({
      variables: {
        proposalId: id,
      },
    });
  }, [downloadProposalPDF]);

  return !downloadProposalPDFStatus ? (
    <Loader />
  ) : (
    <NotFound description={<span>{downloadProposalPDFStatus}</span>} />
  );
};

export default ProposalDownloadPdf;
