import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { CachePersistor } from 'apollo3-cache-persist';
import { useApolloClient, useReactiveVar } from '@apollo/client';
import moment from 'moment';

import { isLoggedInVar, userVar, userProfileVar } from 'graphql/cache';
import Path from 'routes/path';
import Popconfirm from '../PopConfirm';

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    let id;
    if (!delay) {
      clearInterval(id);
    } else {
      id = setInterval(tick, delay);
    }
    return () => clearInterval(id);
  }, [delay]);
};

const SessionTimeout = (/*{ setPolling }*/) => {
  const [events] = useState(['click', 'load', 'scroll', 'mousemove']);
  const [isOpen, setOpen] = useState(false);
  const [warningInactiveInterval, setWarningInactiveInterval] = useState(true);

  const sessionExpiration = process.env.REACT_APP_SESSION_EXPIRATION;
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const history = useHistory();
  const maxTime = sessionExpiration * 2; // TODO: change to 10000 after QB test
  const popTime = sessionExpiration;

  let startTimerInterval = useRef();
  const client = useApolloClient();
  const persistor = new CachePersistor({
    cache: client.cache,
    storage: window.localStorage,
  });

  const logout = async () => {
    await client.clearStore();
    await persistor.purge();

    isLoggedInVar(false);
    userProfileVar('');
    userVar('');
    setWarningInactiveInterval(false);
    setOpen(false);
    clearTimeout(startTimerInterval.current);

    localStorage.clear();
    sessionStorage.removeItem('lastTimeStamp');
    history.push(Path.LOGIN);
  };

  // warning timer
  useInterval(
    () => {
      let timeString = sessionStorage.getItem('lastTimeStamp');
      if (!timeString || !isLoggedIn) {
        setWarningInactiveInterval(false);
      } else {
        const diff = moment.duration(moment().diff(moment(new Date(timeString).toISOString())));
        const minPast = diff.minutes();
        if (minPast === popTime) {
          // setPolling(false);
          setOpen(true);
        }

        if (minPast < popTime) {
          setOpen(false);
        }

        if (minPast === maxTime) {
          logout();
        }
      }
    },
    warningInactiveInterval ? 600000 : null //604800000
  );

  // handle close popup
  const handleClose = () => {
    // setPolling(true);
    setOpen(false);
  };

  useEffect(() => {
    if (isLoggedIn) {
      setWarningInactiveInterval(true);
      events.forEach((event) => {
        window.addEventListener(
          event,
          () => {
            let timeStamp = moment();
            sessionStorage.setItem('lastTimeStamp', timeStamp);
          },
          true
        );
      });
    }
  }, [events, isLoggedIn]);

  if (!isOpen) {
    return null;
  }

  if (!history.location.pathname.match(/\/d\//) && !history.location.pathname.match(/\/pd\//)) {
    logout();
    return null;
  }

  return (
    <Popconfirm
      visible={true}
      title={'Session Expired'}
      body={'You have been inactive for 30 minutes. Do you want to stay?'}
      cancelText={'Leave'}
      confirmText={'Stay'}
      onCancel={logout}
      onConfirm={handleClose}
    />
  );
};

export default SessionTimeout;
