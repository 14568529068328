import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { userVar } from 'graphql/cache';

import './ChatwayIntegrationModals.scss';
import AddChatwayIntegrationModal from './AddIntegrationScriptModal/AddChatwayIntegrationModal';
import UserSelectionModal from './UserSelectionModal';
import { CHATWAY_NEW_USER_REDIRECT_URL } from 'constants/index';

const ChatwayIntegrationModals = ({
  showAddModal,
  setShowAddModal,
  updateCodeIntegration,
  integrationData,
  loading,
}) => {
  const [isScriptModalOpen, setIsScriptModalOpen] = useState(false);
  const user = useReactiveVar(userVar);
  const onNewUser = () => {
    window.open(CHATWAY_NEW_USER_REDIRECT_URL);
  };
  const onExistingUser = () => {
    setIsScriptModalOpen(true);
  };
  return (
    <>
      {showAddModal && (
        <>
          {isScriptModalOpen && (
            <AddChatwayIntegrationModal
              visible={showAddModal}
              loading={loading}
              onCancel={() => {
                setIsScriptModalOpen(false);
              }}
              onConfirm={(script) => {
                const tempIntegrationData = [...integrationData];
                tempIntegrationData.push(script);
                updateCodeIntegration(tempIntegrationData, (data) => {
                  if (data?.updateChatwayScript) {
                    let userInfo = JSON.parse(JSON.stringify(user));
                    userInfo['chatwayScripts'] = data?.updateChatwayScript;
                    userVar({ ...user, ...userInfo });
                  }
                  setIsScriptModalOpen(false);
                  setShowAddModal(false);
                });
              }}
            />
          )}
          {!isScriptModalOpen && (
            <UserSelectionModal
              onExistingUser={onExistingUser}
              onNewUser={onNewUser}
              visible={showAddModal}
              onCancel={() => setShowAddModal(false)}
            />
          )}
        </>
      )}
    </>
  );
};

ChatwayIntegrationModals.defaultProps = {
  showAddModal: false,
};

ChatwayIntegrationModals.propTypes = {
  integrationData: PropTypes.instanceOf(Array),
  showAddModal: PropTypes.bool,
  setShowAddModal: PropTypes.func.isRequired,
  updateCodeIntegration: PropTypes.func.isRequired,
};

export default ChatwayIntegrationModals;
