import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="48" height="48" viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M4 6C4 3.79086 5.79086 2 8 2H40C42.2091 2 44 3.79086 44 6V38C44 40.2091 42.2091 42 40 42H8C5.79086 42 4 40.2091 4 38V6Z"
        fill="white"
      />
      <path
        d="M4 6C4 3.79086 5.79086 2 8 2H40C42.2091 2 44 3.79086 44 6V38C44 40.2091 42.2091 42 40 42H8C5.79086 42 4 40.2091 4 38V6Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M10 12C10 11.4477 10.4477 11 11 11H21C21.5523 11 22 11.4477 22 12V13H10V12Z"
        fill="#7876C1"
      />
      <rect x="24" y="15" width="14" height="2" rx="1" fill="#C7BCE4" />
      <rect x="24" y="11" width="14" height="2" rx="1" fill="#C7BCE4" />
      <rect x="24" y="19" width="14" height="2" rx="1" fill="#C7BCE4" />
      <rect x="24" y="23" width="14" height="2" rx="1" fill="#C7BCE4" />
      <rect x="10" y="27" width="28" height="2" rx="1" fill="#C7BCE4" />
      <rect x="10" y="31" width="28" height="2" rx="1" fill="#C7BCE4" />
      <path
        d="M10 13H12V14C12 14.5523 11.5523 15 11 15C10.4477 15 10 14.5523 10 14V13Z"
        fill="#7876C1"
      />
      <path
        d="M20 13H22V14C22 14.5523 21.5523 15 21 15C20.4477 15 20 14.5523 20 14V13Z"
        fill="#7876C1"
      />
      <rect x="15" y="11" width="2" height="13" fill="#7876C1" />
      <rect x="13" y="23" width="6" height="2" rx="1" fill="#7876C1" />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="48"
        height="48"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.72549 0 0 0 0 0.721569 0 0 0 0 0.839216 0 0 0 0.2 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="24"
        y1="2"
        x2="24"
        y2="42"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F5F6FA" />
      </linearGradient>
    </defs>
  </svg>
);

const TextIcon = (props) => <Icon component={svg} {...props} />;

export default TextIcon;
