import { useState } from 'react';
import { useMutation } from '@apollo/client';
import amazonS3Uri from 'amazon-s3-uri';

import { COPY_FILE } from '../../graphql/mutations/uploadMutation';

const FileCopier = () => {
  const [copying, setCopying] = useState(false);
  const [copyFile] = useMutation(COPY_FILE);

  const copy = async ({ fromUrl, from, to, prefix }) => {
    setCopying(true);
    const data = {};

    if (fromUrl) {
      let key = '';

      try {
        key = amazonS3Uri(fromUrl)?.key;
      } catch (error) {
        const urlToRemove = process.env.REACT_APP_CLOUD_FRONT_URL;
        let start = fromUrl.indexOf(urlToRemove);
        key = fromUrl.substring(0, start) + fromUrl.substring(start + urlToRemove.length);
      }
      data.from = key;
    } else if (from) {
      data.from = from;
    }

    data.to = `${prefix ? `${prefix}/` : ''}${to ? to : data.from}`;

    try {
      const result = await copyFile({
        variables: { copyFile: data },
      });

      return result.data && result.data.copyFile;
    } catch (error) {
      throw error;
    } finally {
      setCopying(false);
    }
  };

  return [copy, copying];
};

export default FileCopier;
