import React, { useState, useEffect } from 'react';
import { Table, Button, Row, message } from 'antd';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';

import './WixGeneralStats.scss';

const WixGeneralStats = ({ generalStats, startDate, endDate }) => {
  const [stats, setStats] = useState(generalStats);

  useEffect(() => {
    setStats(generalStats);
  }, [generalStats]);

  const generalStatTableColumns = [
    {
      title: 'Total Sum',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
    },
    {
      title: 'Net Sum',
      dataIndex: 'totalNetRevenue',
      key: 'totalNetRevenue',
    },
    {
      title: 'Rev Share Sum',
      dataIndex: 'totalRevShare',
      key: 'totalRevShare',
    },
  ];

  const handleStatsCopy = () => {
    copy(
      `The total sum generated between ${startDate} to ${endDate} is $${stats[0]?.totalAmount || 0
      }, the net sum is $${stats[0]?.totalNetRevenue || 0}, and the rev-share sum is $${stats[0]?.totalRevShare || 0
      }.`
    );
    message.success('Copied to Clipboard');
  };

  return (
    <div className="admin-table-wrapper">
      <Table dataSource={stats} columns={generalStatTableColumns} pagination={false} />
      <Row className="admin-table-wix-rev-copy">
        <p>
          The total sum generated between {startDate} to {endDate} is ${stats[0]?.totalAmount || 0},
          the net sum is ${stats[0]?.totalNetRevenue || 0}, and the rev-share sum is $
          {stats[0]?.totalRevShare || 0}.
        </p>
        <Button type="primary" onClick={handleStatsCopy}>
          Copy
        </Button>
      </Row>
    </div>
  );
};

WixGeneralStats.propTypes = {
  generalStats: PropTypes.instanceOf(Object),
};

export default WixGeneralStats;
