import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30 30" fill="none">
    <path
      d="M21.5625 22.5H26.25C26.6041 22.5 26.901 22.6198 27.1406 22.8594C27.3802 23.099 27.5 23.3958 27.5 23.75C27.5 24.1042 27.3802 24.401 27.1406 24.6406C26.901 24.8802 26.6041 25 26.25 25H19.0625L21.5625 22.5ZM6.46872 25C6.30205 25 6.14059 24.9688 5.98434 24.9062C5.82809 24.8438 5.68747 24.75 5.56247 24.625L3.28122 22.3438C2.80205 21.8646 2.55726 21.2708 2.54684 20.5625C2.53642 19.8542 2.7708 19.25 3.24997 18.75L17 4.5C17.4791 4 18.0677 3.75 18.7656 3.75C19.4635 3.75 20.0521 3.98958 20.5312 4.46875L26.75 10.6875C27.2291 11.1667 27.4687 11.7604 27.4687 12.4688C27.4687 13.1771 27.2291 13.7708 26.75 14.25L16.625 24.625C16.5 24.75 16.3593 24.8438 16.2031 24.9062C16.0468 24.9688 15.8854 25 15.7187 25H6.46872ZM15.1875 22.5L25 12.4375L18.8125 6.25L4.99997 20.5L6.99997 22.5H15.1875Z"
      fill="currentColor"
    />
  </svg>
);

const EraserIcon = (props) => <Icon component={svg} {...props} />;

export default EraserIcon;
