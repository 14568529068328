const DateFormats = [
  {
    text: 'MM/DD/YYYY',
    value: 'MM/DD/YYYY',
  },
  {
    text: 'DD/MM/YYYY',
    value: 'DD/MM/YYYY',
  },
  {
    text: 'DD/MM/YY',
    value: 'DD/MM/YY',
  },
  {
    text: 'MM/DD/YY',
    value: 'MM/DD/YY',
  },
];

export default DateFormats;
