/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.258064 7.69276C-0.0860215 8.07352 -0.0860215 8.65294 0.258065 9.0337L6.91024 16.3949C7.52405 17.0742 8.65217 16.6399 8.65217 15.7244V11.8685C14.4428 11.9911 18.0584 14.2312 19.903 16.1639C20.2673 16.5455 21.0895 16.2699 21.0235 15.7464C19.9763 7.44981 12.785 5.05277 8.65217 4.87426V1.00201C8.65217 0.0865236 7.52405 -0.347693 6.91024 0.33154L0.258064 7.69276Z"
      fill="#05034D"
    />
  </svg>
);

const UndoIcon = (props) => <Icon component={svg} {...props} />;

export default UndoIcon;
