/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="40" viewBox="0 0 48 40" fill="none">
    <rect x="7" y="12" width="14" height="5" rx="1" fill="#7876C1" />
    <rect x="23" y="12" width="18" height="3" rx="1" fill="#C7BCE4" />
    <rect x="23" y="16" width="18" height="3" rx="1" fill="#C7BCE4" />
    <rect x="23" y="20" width="18" height="3" rx="1" fill="#C7BCE4" />
  </svg>
);

const TitleSideNormal = (props) => <Icon component={svg} {...props} />;

export default TitleSideNormal;
