/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.34619 10.8291C9.34619 10.3587 9.18018 9.99902 8.84814 9.75C8.51611 9.49544 7.91846 9.22982 7.05518 8.95312C6.19189 8.6709 5.50846 8.39421 5.00488 8.12305C3.63249 7.38151 2.94629 6.38265 2.94629 5.12646C2.94629 4.47347 3.12891 3.89242 3.49414 3.3833C3.86491 2.86865 4.39339 2.46745 5.07959 2.17969C5.77132 1.89193 6.54606 1.74805 7.40381 1.74805C8.26709 1.74805 9.0363 1.90576 9.71143 2.22119C10.3866 2.53109 10.9095 2.97103 11.2803 3.54102C11.6566 4.111 11.8447 4.75846 11.8447 5.4834H9.35449C9.35449 4.93001 9.18018 4.50114 8.83154 4.19678C8.48291 3.88688 7.99316 3.73193 7.3623 3.73193C6.75358 3.73193 6.28044 3.86198 5.94287 4.12207C5.60531 4.37663 5.43652 4.71419 5.43652 5.13477C5.43652 5.52767 5.63298 5.85693 6.02588 6.12256C6.42432 6.38818 7.00814 6.63721 7.77734 6.86963C9.19401 7.29574 10.2261 7.82422 10.8735 8.45508C11.521 9.08594 11.8447 9.87174 11.8447 10.8125C11.8447 11.8584 11.4491 12.6802 10.6577 13.2778C9.86637 13.87 8.80111 14.166 7.46191 14.166C6.53223 14.166 5.68555 13.9972 4.92188 13.6597C4.1582 13.3166 3.57438 12.849 3.17041 12.2568C2.77197 11.6647 2.57275 10.9785 2.57275 10.1982H5.07129C5.07129 11.5319 5.86816 12.1987 7.46191 12.1987C8.05404 12.1987 8.51611 12.0798 8.84814 11.8418C9.18018 11.5983 9.34619 11.2607 9.34619 10.8291Z"
      fill="#9E9EB7"
    />
    <rect y="7" width="15" height="1.5" rx="0.75" fill="#9E9EB7" />
  </svg>
);

const StrikeThroughIcon = (props) => <Icon component={svg} {...props} />;

export default StrikeThroughIcon;
