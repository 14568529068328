import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="48" height="48" viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M4 6C4 3.79086 5.79086 2 8 2H40C42.2091 2 44 3.79086 44 6V38C44 40.2091 42.2091 42 40 42H8C5.79086 42 4 40.2091 4 38V6Z"
        fill="white"
      />
      <path
        d="M4 6C4 3.79086 5.79086 2 8 2H40C42.2091 2 44 3.79086 44 6V38C44 40.2091 42.2091 42 40 42H8C5.79086 42 4 40.2091 4 38V6Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M10 15C10 14.4477 10.4477 14 11 14H15C15.5523 14 16 14.4477 16 15V19C16 19.5523 15.5523 20 15 20H11C10.4477 20 10 19.5523 10 19V15Z"
        fill="#7876C1"
      />
      <path
        d="M18 15C18 14.4477 18.4477 14 19 14H23C23.5523 14 24 14.4477 24 15V19C24 19.5523 23.5523 20 23 20H19C18.4477 20 18 19.5523 18 19V15Z"
        fill="#7876C1"
      />
      <path
        d="M14 11C13 11.5 11 12 11 16"
        stroke="#7876C1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 11C21 11.5 19 12 19 16"
        stroke="#7876C1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="10" y="23" width="28" height="2" rx="1" fill="#C7BCE4" />
      <rect x="10" y="31" width="18" height="2" rx="1" fill="#C7BCE4" />
      <rect x="10" y="27" width="28" height="2" rx="1" fill="#C7BCE4" />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="48"
        height="48"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.72549 0 0 0 0 0.721569 0 0 0 0 0.839216 0 0 0 0.2 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="24"
        y1="2"
        x2="24"
        y2="42"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F5F6FA" />
      </linearGradient>
    </defs>
  </svg>
);

const QuoteIcon = (props) => <Icon component={svg} {...props} />;

export default QuoteIcon;
