import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.0105 9.31768L15.1821 6.48926L6.69679 14.9745L9.52521 17.803L18.0105 9.31768Z"
      fill="#7876C1"
    />
    <path
      d="M5.98969 15.6816L8.81811 18.5101L8.3251 19.0031C8.1855 19.1427 8.0077 19.2378 7.81411 19.2766L6.04634 19.6301C5.34662 19.7701 4.7297 19.1531 4.86965 18.4534L5.2232 16.6856C5.26192 16.4921 5.35707 16.3143 5.49667 16.1747L5.98969 15.6816Z"
      fill="#7876C1"
    />
    <path
      d="M17.3034 4.36824C17.6939 3.97772 18.3271 3.97772 18.7176 4.36824L20.1318 5.78245C20.5223 6.17298 20.5223 6.80614 20.1318 7.19667L18.7176 8.61088L15.8892 5.78245L17.3034 4.36824Z"
      fill="#7876C1"
    />
  </svg>
);

const EditIcon = (props) => <Icon component={svg} {...props} />;

export default EditIcon;
