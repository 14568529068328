import React from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { Row, Col, Slider } from 'antd';

import UploadCloudIcon from 'components/Icons/UploadCloudIcon';
import RightIcon from 'components/Icons/RightIcon';

import './SelectFiles.scss';

const SelectFiles = ({ dropImage, uploadStatus, showProgress, imageName, files }) => {
  return (
    <Col className="select-file">
      {showProgress ? (
        <div className="logo-upload-progress">
          <p className="progress-title">Uploading File...</p>
          <p className="upload-image-name" title={imageName}>
            {imageName}
          </p>
          <div className="spacer" />
          <Slider value={uploadStatus.percent} tooltipVisible />
        </div>
      ) : (
        <>
          <Dropzone onDrop={dropImage}>
            {/*accept="image/jpeg, image/png, image/svg+xml, image/bmp">*/}
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <div
                  {...getRootProps()}
                  className={`dropzone ${isDragActive && 'dropzone--isActive'}`}>
                  <input {...getInputProps()} />
                  <Col className="select-file-uploader">
                    <UploadCloudIcon />
                    <p className="select-file-title">
                      {files
                        ? `Total number of files ${files.length}`
                        : `Start by adding your files`}
                    </p>
                    <p className="select-file-info">
                      Drag & drop your files or upload from your computer
                    </p>
                    <p className="select-image-details">Maximum 3MB</p>
                    <Row className="select-file-upload-text">
                      <h4>Browse your computer</h4>
                      <RightIcon className="select-file-upload-icon" />
                    </Row>
                  </Col>
                </div>
              );
            }}
          </Dropzone>
        </>
      )}
    </Col>
  );
};

SelectFiles.defaultProps = {
  imageName: '',
  uploadStatus: {},
  files: [],
  visible: false,
};

SelectFiles.propTypes = {
  uploadStatus: PropTypes.instanceOf(Object),
  visible: PropTypes.bool,
  dropImage: PropTypes.func.isRequired,
  imageName: PropTypes.string,
  files: PropTypes.instanceOf(Array),
};

export default SelectFiles;
