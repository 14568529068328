import { useEffect, useState } from 'react';

// get with of a div/container
export const useWidth = (myRef, screen = 'desktop') => {
  const [dimensions, setDimensions] = useState(0);

  const handleResize = () => {
    setDimensions(myRef?.current?.offsetWidth || 300);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (myRef.current) {
      setTimeout(handleResize, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myRef, screen]);

  return dimensions;
};
