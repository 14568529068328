import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, AutoComplete } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import Button from 'components/Button';

import './InvoicingInfoModal.scss';

const InvoicingInfoModal = ({
  saveInvoicingInfo, CountriesCode, country,
  address, companyname, vatnumber,
}) => {
  const [options, setOptions] = useState([{ label: country, value: country }]);

  const onSearch = (searchText) => {
    let newOptions = [];

    !searchText
      ? []
      : Object.keys(CountriesCode).forEach((countryCode) => {
          if (CountriesCode[countryCode].name.search(new RegExp(searchText, 'i')) !== -1)
            newOptions.push({
              label: CountriesCode[countryCode].name,
              value: CountriesCode[countryCode].name,
            });
        });

    setOptions(newOptions);
  };

  return (
    <Modal
      className="simple-info-modal sign-up-info-modal invoicing-info-modal"
      centered
      visible
      footer={null}
      closeIcon={null}
      closable={false}
      maskClosable={false}>
      <div>
        <h3 className="title">Invoicing Details</h3>

        <div className="divider" />

        <p className="body">You can have a customized invoice with the following details:</p>

        <Form
          layout="vertical"
          onFinish={(values) => saveInvoicingInfo(values)}
          initialValues={{
            country,
            companyname,
            address,
            vatnumber,
          }}>
          <Form.Item
            label="Company Name"
            name="companyname"
            labelCol={false}
            rules={[{ required: true, message: 'Company Name Required!' }]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="ADDRESS"
            name="address"
            labelCol={false}
            rules={[{ required: true, message: 'Address Required!' }]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="Country"
            name="country"
            labelCol={false}
            rules={[{ required: true, message: 'Country Required!' }]}>
            <AutoComplete options={options} onSearch={onSearch} />
          </Form.Item>

          <Form.Item
            label="VAT / BUSINESS NUMBER"
            name="vatnumber"
            labelCol={false}
            rules={[{ message: 'Vat Number Required!' }]}>
            <Input type="text" name="vatnumber" autoComplete="vatnumber" />
          </Form.Item>

          <div className="button-wrapper">
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className=""
              text="Save"
              icon={
                <div className="button-icon-right">
                  <RightOutlined />
                </div>
              }
            />

            <Button
              type="primary"
              size="large"
              className="cancel"
              htmlType="button"
              onClick={() => saveInvoicingInfo({ companyname: '', address: '', vatnumber: '' })}
              text={'Cancel'}
            />
          </div>
        </Form>
      </div>
    </Modal>
  );
};

InvoicingInfoModal.propTypes = {
  saveInvoicingInfo: PropTypes.func.isRequired,
  country: PropTypes.string,
  companyname: PropTypes.string,
  address: PropTypes.string,
  vatnumber: PropTypes.string,
  CountriesCode: PropTypes.instanceOf(Object).isRequired,
};

export default InvoicingInfoModal;
