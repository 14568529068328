import React from 'react';
import DeleteIcon2 from 'components/Icons/DeleteIcon2';

const PhotoFrame = React.memo(
  React.forwardRef(function PhotoFrame(props, ref) {
    const {
      layoutOptions,
      imageProps,
      overlay,
      active,
      insertPosition,
      attributes,
      listeners,
      onRemoveImage,
      imageId,
      handleImageLoad,
    } = props;
    const { alt, style, ...restImageProps } = imageProps;
    const { borderRadius } = style;

    return (
      <div
        ref={ref}
        style={{
          width: overlay ? `calc(100% - ${2 * layoutOptions.padding}px)` : style.width,
          marginBottom: style.marginBottom,
          background: '#fff',
          borderRadius: borderRadius + borderRadius * 0.1,
        }}
        className={`photo-frame
        ${overlay ? 'overlay' : ''}
        ${active ? 'active' : ''}
        ${insertPosition === 'before' ? 'insertBefore' : ''}
        ${insertPosition === 'after' ? 'insertAfter' : ''}
        `}
        {...attributes}
        {...listeners}>
        <div className="delete" onClick={onRemoveImage}>
          <DeleteIcon2 />
        </div>
        <img
          alt={alt}
          // required to calculate the height and width
          {...(handleImageLoad ? { onLoad: handleImageLoad, id: imageId } : {})}
          style={{
            ...style,
            width: '100%',
            height: 'auto',
            marginBottom: 0,
          }}
          {...restImageProps}
        />
      </div>
    );
  })
);
export default PhotoFrame;
