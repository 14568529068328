import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.52543 5.37388C8.9363 3.80035 11.0005 2.80791 13.3002 2.80791C17.5527 2.80791 21 6.20134 21 10.3874C21 14.5734 17.5527 17.9668 13.3002 17.9668C11.0198 17.9668 8.97097 16.9909 7.56109 15.4403"
      stroke="#05348B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.97661 9.00661C4.49888 9.0158 4.10922 8.62614 4.11841 8.14841L4.20286 3.75874C4.21714 3.01638 5.11533 2.65441 5.64036 3.17944L9.94558 7.48467C10.4706 8.00969 10.1086 8.90788 9.36628 8.92217L4.97661 9.00661Z"
      fill="#05348B"
    />
  </svg>
);

const ResetIcon = (props) => <Icon component={svg} {...props} />;

export default ResetIcon;
