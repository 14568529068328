import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
    <svg width="13" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 0.00999999L2 0C0.9 0 0 0.9 0 2V20C0 21.1 0.9 22 2 22H12C13.1 22 14 21.1 14 20V2C14 0.9 13.1 0.00999999 12 0.00999999ZM12 18H2V4H12V18Z" fill="#05034D"/>
    </svg>
);

const MobileIcon = (props) => <Icon component={svg} {...props} />;

export default MobileIcon;
