import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg
    width="171"
    height="195"
    viewBox="0 0 171 195"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
      <mask
        id="mask0_1_581"
        style={{ masktype: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="-1"
        width="172"
        height="196">
        <rect
          x="0.651215"
          y="195"
          width="195.338"
          height="170.462"
          transform="rotate(-90 0.651215 195)"
          fill="#FFF4F2"
        />
      </mask>
      <g mask="url(#mask0_1_581)">
        <circle
          opacity="0.2"
          cx="147.832"
          cy="39.0851"
          r="106.148"
          transform="rotate(-90 147.832 39.0851)"
          stroke="#C7BCE4"
          strokeWidth="39.6583"
        />
        <circle
          opacity="0.2"
          cx="147.514"
          cy="35.6328"
          r="44.5522"
          transform="rotate(-90 147.514 35.6328)"
          stroke="#C7BCE4"
          strokeWidth="39.6583"
        />
      </g>
    </g>
  </svg>
);

const EclipseIcon = (props) => <Icon component={svg} {...props} />;

export default EclipseIcon;
