/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1371_37969)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9774 0C9.32231 0.00122324 7.98286 1.34434 7.98408 2.99939C7.98286 4.65443 9.32353 5.99755 10.9786 5.99878H13.9731V3.00061C13.9743 1.34557 12.6336 0.00244648 10.9774 0C10.9786 0 10.9786 0 10.9774 0V0ZM10.9774 8H2.99449C1.33945 8.00122 -0.0012199 9.34434 3.34296e-06 10.9994C-0.00244314 12.6544 1.33823 13.9976 2.99327 14H10.9774C12.6324 13.9988 13.9731 12.6557 13.9718 11.0006C13.9731 9.34434 12.6324 8.00122 10.9774 8V8Z"
        fill="#36C5F0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.9403 10.9994C29.9415 9.34434 28.6008 8.00122 26.9457 8C25.2907 8.00122 23.95 9.34434 23.9512 10.9994V14H26.9457C28.6008 13.9988 29.9415 12.6557 29.9403 10.9994ZM21.9561 10.9994V2.99939C21.9573 1.34557 20.6178 0.00244648 18.9628 0C17.3077 0.00122324 15.967 1.34434 15.9683 2.99939V10.9994C15.9658 12.6544 17.3065 13.9976 18.9616 14C20.6166 13.9988 21.9573 12.6557 21.9561 10.9994Z"
        fill="#2EB67D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9615 30C20.6166 29.9988 21.9573 28.6557 21.956 27.0006C21.9573 25.3456 20.6166 24.0024 18.9615 24.0012H15.967V27.0006C15.9658 28.6544 17.3065 29.9976 18.9615 30ZM18.9615 21.9988H26.9456C28.6007 21.9976 29.9414 20.6544 29.9401 18.9994C29.9426 17.3443 28.6019 16.0012 26.9469 15.9988H18.9628C17.3077 16 15.967 17.3431 15.9683 18.9982C15.967 20.6544 17.3065 21.9976 18.9615 21.9988V21.9988Z"
        fill="#ECB22E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.3641e-07 18.9994C-0.00122241 20.6545 1.33946 21.9976 2.99451 21.9988C4.64956 21.9976 5.99024 20.6545 5.98901 18.9994V16H2.99451C1.33946 16.0012 -0.00122241 17.3444 8.3641e-07 18.9994ZM7.98413 18.9994V26.9995C7.98168 28.6545 9.32236 29.9977 10.9774 30.0001C12.6325 29.9989 13.9731 28.6558 13.9719 27.0007V19.0019C13.9744 17.3468 12.6337 16.0037 10.9786 16.0012C9.32236 16.0012 7.98291 17.3444 7.98413 18.9994C7.98413 19.0006 7.98413 18.9994 7.98413 18.9994Z"
        fill="#E01E5A"
      />
    </g>
    <defs>
      <clipPath id="clip0_1371_37969">
        <rect width="29.9401" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const SlackIcon = (props) => <Icon component={svg} {...props} />;

export default SlackIcon;
