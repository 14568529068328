import { Button, Dropdown, Input, Menu, Space, Table, Modal } from 'antd';
import React, { useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';

import { FETCH_ADMIN_PROPOSALS } from 'graphql/queries/adminQueries';

const ProposalDetails = () => {
  const [adminProposals, setAdminProposals] = useState([]);
  const [adminProposalsCount, setAdminProposalsCount] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);

  const updateAdminProposals = (proposals) => {
    setAdminProposals(
      proposals.map((proposal) => ({
        createdAt: moment(proposal.date).format('DD/MM/YYYY'),
        lang: proposal.language,
        font: `${proposal?.draft?.titleFont?.family ?? 'default'} / ${
          proposal?.draft?.bodyFont?.family ?? 'default'
        }`,
        state: proposal.state || 'edit',
        uid: proposal.uid,
        ids: [proposal._id, proposal.pid, proposal.cid],
        actions: proposal,
        key: proposal._id,
      }))
    );
  };

  const {
    loading: isFetchingAdminProposals,
    refetch,
    fetchMore,
  } = useQuery(FETCH_ADMIN_PROPOSALS, {
    variables: {
      queryString: searchText,
      limit: limit,
      offset: offset,
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const {
        fetchAdminProposals: { proposals, proposalsCount },
      } = res;

      updateAdminProposals(proposals);

      if (offset === 0) {
        setAdminProposalsCount(proposalsCount);
      }
    },
  });

  const handleToggleFake = (prop) => {
    // Meteor.call('toggleFake', { pId: prop._id, fake: !prop.fake }, e => {
    //   if (e) {
    //     message.error('Failed to execute');
    //   } else {
    //     const propIndex = proposals.findIndex(el => el._id === prop._id);
    //     const newProp = prop;
    //     newProp.fake = !prop.fake;
    //     proposals[propIndex] = newProp;
    //     setProposals([...proposals]);
    //     message.success('Success');
    //   }
    // });
  };

  const menu = useCallback(
    (prop) => (
      <Menu>
        <Menu.Item key="fake" onClick={() => handleToggleFake(prop)}>
          {prop.fake ? 'Mark not fake' : 'Mark fake'}
        </Menu.Item>
        <Menu.Item
          key="download"
          onClick={() => {
            // Meteor.call(
            //   'downloadPDF',
            //   { origin: window.location.origin, propid: prop._id },
            //   (e, r) => {
            //     if (e) {
            //       message.error('Failed to download PDF');
            //     } else {
            //       window.open(r, '_blank');
            //     }
            //   }
            // );
          }}>
          Download PDF
        </Menu.Item>
        <Menu.Item
          key="unsign"
          onClick={() => {
            Modal.confirm({
              title: `Are you sure you want to unsign proposal`,
              onOk: () => {
                // Meteor.call('unsignProp', prop._id, e => {
                //   if (e) {
                //     message.error(`Failed to unsign prop`);
                //   } else {
                //     message.success(`Successfully unsigned prop`);
                //   }
                // });
              },
            });
          }}>
          Unsign prop
        </Menu.Item>
      </Menu>
    ),
    []
  );

  const propColumn = [
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Lang',
      dataIndex: 'lang',
      key: 'lang',
    },
    {
      title: 'Font',
      dataIndex: 'font',
      key: 'font',
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: 'User ID',
      dataIndex: 'uid',
      key: 'uid',
    },
    {
      title: 'ID/PID/CID',
      dataIndex: 'ids',
      key: 'ids',
      render: (ids) => (
        <div>
          {ids.map((el, i) => (
            <div key={i}>{el}</div>
          ))}
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (details) => (
        <Dropdown overlay={menu(details)} trigger="click" placement="bottomRight" arrow>
          <Button type="link">Action</Button>
        </Dropdown>
      ),
    },
  ];

  const handleReset = () => {
    setSearchText('');
  };

  return (
    <div>
      <div className="admin-table-wrapper">
        <div className="table-action">
          <h3>All Props</h3>
          <div>
            <Space>
              <Input.Search
                width="200"
                placeholder="Email/Uid"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onSearch={() => refetch()}
              />
              <Button type="primary" onClick={handleReset}>
                Reset
              </Button>
            </Space>
          </div>
        </div>
        <Table
          loading={isFetchingAdminProposals}
          dataSource={adminProposals}
          columns={propColumn}
          pagination={{
            total: adminProposalsCount,
            defaultPageSize: limit,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30'],
            onChange: async (page, size) => {
              setLimit(size);
              setOffset(page);

              let fetchMoreResult = await fetchMore({
                variables: {
                  queryString: searchText,
                  limit: size,
                  offset: page - 1,
                },
              });
              const newAdminProposals = fetchMoreResult?.fetchAdminProposals?.proposals || [];
              const updatedAdminProposals = [...newAdminProposals];
              updateAdminProposals(updatedAdminProposals);
            },
          }}
        />
      </div>
    </div>
  );
};

export default ProposalDetails;
