import React from 'react';
import { Redirect } from 'react-router-dom';
import { userVar } from 'graphql/cache';
import PropTypes from 'prop-types';
import PropsRoute from './PropsRoute';

// checks if the user is logged in using Meteor.userId() if it fails redirects to login
const isLogged = true;
const AdminRoute = ({ component, ...rest }) => {
  const isSuperAdmin =
    userVar()?._id === 'YMCMzBje3h42Tywjy' || userVar()?._id === 'qJ4XmuEkytRz4Dcwr';
  return (
    <>
      {isSuperAdmin && isLogged ? (
        <PropsRoute component={component} {...rest} />
      ) : (
        <Redirect to="/dashboard" />
      )}
    </>
  );
};
AdminRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default AdminRoute;
