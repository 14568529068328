import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { message } from 'antd';

import PageHeader from 'components/PageHeader';
import Loader from 'components/Loader';

import { getUserId, userVar } from 'graphql/cache';
import { USER_AFFILIATES_DETAILS } from 'graphql/queries/userQueries';
import { USER_UPDATE } from 'graphql/mutations/userMutations';
import { ReferralTable, TransferTable, InfoTable } from './components';

import './Affiliate.scss';

const Affiliate = ({ history }) => {
  const user = useReactiveVar(userVar);

  const [paypalId, setPaypalId] = useState();
  const [loadingPaypal, setLoadingPaypal] = useState();
  const [credits, setCredits] = useState(0);
  const [loadingRequestPayment, setLoadingRequestPayment] = useState(false);
  const [affiliatePayments, setAffiliatePayments] = useState([]);
  const [affiliateUrl, setAffiliateUrl] = useState(
    `https://www.goprospero.com/?affiliate=${user?.affiliateId}`
  );

  const { loading: isFetchingUser } = useQuery(USER_AFFILIATES_DETAILS, {
    variables: { id: getUserId() },
    skip: !getUserId() || user?.teamId,
    fetchPolicy: 'cache-and-network',
    onCompleted: (res) => {
      const { fetchUser } = res;
      userVar({ ...user, ...fetchUser });
    },
  });

  const [saveUser] = useMutation(USER_UPDATE, {
    async onCompleted(data) {
      userVar({ ...user, ...data.updateUser });
      if (loadingRequestPayment) {
        setLoadingRequestPayment(false);
        message.info('Payment request made');
      }
      if (loadingPaypal) {
        setLoadingPaypal(false);
        message.info('PayPal ID saved');
      }
    },
  });

  useEffect(() => {
    if (user?.teamId) {
      return history.push('/dashboard');
    }
    if (user?.paypalId) {
      setPaypalId(user.paypalId);
    }
    if (user) {
      setCredits(user.credits);
    }
    if (user?.affiliatePayments) {
      setAffiliatePayments([...user.affiliatePayments]);
    }
    if (user?.affiliateId) {
      setAffiliateUrl(`https://www.goprospero.com/?affiliate=${user?.affiliateId}`);
    }
  }, [user, history]);

  useEffect(() => {
    document.title = 'Prospero - Affiliate';
  });

  const savePaypal = (value) => {
    if (!value['PayPal Address']) return;

    if (value['PayPal Address'] === paypalId) return;

    setLoadingPaypal(true);

    saveUser({
      variables: {
        user: {
          id: user._id,
          paypalId: value['PayPal Address'],
        },
      },
    });
  };

  const getUsers = (type) => {
    const { affiliates } = user;
    if (affiliates) {
      const filteredAffiliates = affiliates.filter(
        (affiliate) =>
          affiliate.paid === true &&
          affiliate.affiliatedUser &&
          affiliate.affiliatedUser.type &&
          affiliate.affiliatedUser.type === type
      );
      return filteredAffiliates;
    }
    return [];
  };

  const requestPayment = () => {
    if (!user.paypalId) {
      return message.error('PayPal ID required');
    }

    setLoadingRequestPayment(true);

    const payment = {
      requestedCredits: credits,
      paided: false,
      createdAt: new Date().toISOString(),
      payedAt: null,
    };

    affiliatePayments.push(payment);
    setAffiliatePayments([...affiliatePayments]);

    saveUser({
      variables: {
        user: {
          id: user._id,
          credits: 0,
          affiliatePayments,
        },
      },
    });
  };

  if (!user || isFetchingUser) {
    return <Loader />;
  }

  return (
    <div className="container">
      <div className="affiliate">
        <PageHeader pageTitle="Affiliate Program" />
        <InfoTable
          user={user}
          credits={credits}
          paypalId={paypalId}
          setPaypalId={setPaypalId}
          loadingPaypal={loadingPaypal}
          affiliateUrl={affiliateUrl}
          savePaypal={savePaypal}
          requestPayment={requestPayment}
          loadingRequestPayment={loadingRequestPayment}
        />
        <ReferralTable
          monthlyAffiliates={getUsers('monthly')}
          yearlyAffiliates={getUsers('yearly')}
        />
        <TransferTable affiliatePayments={affiliatePayments} />
      </div>
    </div>
  );
};

export default Affiliate;
