/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="21" height="10" viewBox="0 0 21 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 5H20M1 5L4 1M1 5L4 9M20 5L17 1M20 5L17 9"
      stroke="#05034D"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const Expand = (props) => <Icon component={svg} {...props} />;

export default Expand;
