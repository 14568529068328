/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
  <g clip-path="url(#clip0_14109_25324)">
    <path d="M10.4987 22.0696C17.1642 22.0696 22.5676 16.6661 22.5676 10.0006C22.5676 3.3351 17.1642 -2.06836 10.4987 -2.06836C3.83315 -2.06836 -1.57031 3.3351 -1.57031 10.0006C-1.57031 16.6661 3.83315 22.0696 10.4987 22.0696Z" fill="#27D045"/>
    <path d="M10.5872 15.3462C13.5909 15.3462 16.0259 12.9112 16.0259 9.90746C16.0259 6.90374 13.5909 4.46875 10.5872 4.46875C7.58343 4.46875 5.14844 6.90374 5.14844 9.90746C5.14844 12.9112 7.58343 15.3462 10.5872 15.3462Z" stroke="white" stroke-width="0.937709"/>
    <path d="M5.6205 13.0022L4.75781 16.0591L7.92727 15.1777L8.09604 14.2024L6.03308 14.8588L6.57696 12.8896L5.6205 13.0022Z" fill="white"/>
    <path d="M6 14.8778C6 14.8591 6.6564 12.6836 6.6564 12.6836L8.23175 14.1652L6 14.8778Z" fill="#27D045"/>
    <path d="M8.24427 7.21403C8.05278 7.3469 7.88954 7.51642 7.76399 7.71278C7.63845 7.90915 7.55309 8.12846 7.51285 8.35803C7.41908 9.08945 7.86919 9.83961 7.86919 9.83961C8.32575 10.5189 8.86782 11.1366 9.48204 11.6775C10.0193 12.1027 10.6294 12.4268 11.2824 12.634C11.6634 12.7861 12.0801 12.8252 12.4827 12.7465C12.8395 12.6486 13.1552 12.4381 13.3829 12.1464C13.4363 12.0137 13.474 11.8752 13.4954 11.7338C13.5169 11.6097 13.5169 11.4828 13.4954 11.3587C13.4767 11.2274 12.1264 10.6085 11.9951 10.646C11.8638 10.6836 11.7513 10.9274 11.6013 11.1337C11.4512 11.3399 11.395 11.4337 11.2449 11.4337C11.0949 11.4337 10.4385 11.1712 9.8196 10.6085C9.46611 10.302 9.18985 9.91652 9.01319 9.48328C9.01319 9.48328 9.12572 9.352 9.27575 9.14571C9.39249 9.01779 9.47618 8.86327 9.51954 8.69561C9.51954 8.60184 8.99442 7.27029 8.86314 7.21403C8.65786 7.18517 8.44956 7.18517 8.24427 7.21403Z" fill="white"/>
  </g>
  <defs>
    <clipPath id="clip0_14109_25324">
      <rect x="0.5" width="20" height="20" rx="10" fill="white"/>
    </clipPath>
  </defs>
</svg>
);

const PublishedWhatsappIcon = (props) => <Icon component={svg} {...props} />;

export default PublishedWhatsappIcon;
