/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5 23V7L9 11.5"
      stroke="#7876C1"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 9L19 25L23.5 20.5"
      stroke="#7876C1"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const SortIcon = (props) => <Icon component={svg} {...props} />;

export default SortIcon;
