import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="78" height="84" viewBox="0 0 78 84" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="38.9994" cy="78.5834" rx="38.3333" ry="5.41667" fill="url(#paint0_radial)" />
    <path
      d="M9.50977 14H67.4668L64.4198 74.544C64.3229 76.4681 62.7346 77.9785 60.8081 77.9785H16.1684C14.2419 77.9785 12.6536 76.4681 12.5568 74.544L9.50977 14Z"
      fill="#FF9492"
    />
    <path
      d="M9.50977 14H67.4668L64.4198 74.544C64.3229 76.4681 62.7346 77.9785 60.8081 77.9785H16.1684C14.2419 77.9785 12.6536 76.4681 12.5568 74.544L9.50977 14Z"
      fill="url(#paint1_linear)"
    />
    <rect
      x="53.9727"
      y="24.7817"
      width="3.61624"
      height="40.6827"
      rx="1.80812"
      transform="rotate(3 53.9727 24.7817)"
      fill="#FFCBC6"
    />
    <rect
      x="42.7676"
      y="24.7979"
      width="3.61624"
      height="40.6827"
      rx="1.80812"
      transform="rotate(2 42.7676 24.7979)"
      fill="#FFCBC6"
    />
    <rect
      x="30.5"
      y="24.9243"
      width="3.61624"
      height="40.6827"
      rx="1.80812"
      transform="rotate(-2 30.5 24.9243)"
      fill="#FFCBC6"
    />
    <rect
      x="19.2949"
      y="24.9717"
      width="3.61624"
      height="40.6827"
      rx="1.80812"
      transform="rotate(-3 19.2949 24.9717)"
      fill="#FFCBC6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.0026 7.23248V3.61624L48.796 3.61624V7.23248H28.0026ZM24.3864 2.71218C24.3864 1.21428 25.6007 0 27.0986 0L49.7001 0C51.198 0 52.4123 1.21428 52.4123 2.71218V7.23248V7.23349H66.8789C68.3768 7.23349 69.5911 8.44778 69.5911 9.94567V13.5619C69.5911 14.0612 69.1863 14.466 68.687 14.466L8.115 14.466C7.6157 14.466 7.21094 14.0612 7.21094 13.5619V9.94567C7.21094 8.44777 8.42522 7.23349 9.92312 7.23349L24.3864 7.23349V7.23248V2.71218Z"
      fill="#FF9492"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.0026 7.23248V3.61624L48.796 3.61624V7.23248H28.0026ZM24.3864 2.71218C24.3864 1.21428 25.6007 0 27.0986 0L49.7001 0C51.198 0 52.4123 1.21428 52.4123 2.71218V7.23248V7.23349H66.8789C68.3768 7.23349 69.5911 8.44778 69.5911 9.94567V13.5619C69.5911 14.0612 69.1863 14.466 68.687 14.466L8.115 14.466C7.6157 14.466 7.21094 14.0612 7.21094 13.5619V9.94567C7.21094 8.44777 8.42522 7.23349 9.92312 7.23349L24.3864 7.23349V7.23248V2.71218Z"
      fill="url(#paint2_linear)"
    />
    <defs>
      <radialGradient
        id="paint0_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(38.9994 78.5834) rotate(90) scale(5.41667 38.3333)">
        <stop stopColor="#FF9492" />
        <stop offset="1" stopColor="#FF9492" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint1_linear"
        x1="57.7627"
        y1="70.6459"
        x2="16.5655"
        y2="12.4738"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#F06F6C" />
        <stop offset="1" stopColor="#FF9492" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="59.1464"
        y1="12.808"
        x2="56.2718"
        y2="-6.51422"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#F06F6C" />
        <stop offset="1" stopColor="#FF9492" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

const TrashIcon = (props) => <Icon component={svg} {...props} />;

export default TrashIcon;
