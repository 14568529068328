import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
<svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="2" y="17" width="16" height="3" rx="1.5" fill="#C7BCE4"/>
<path fillRule="evenodd" clipRule="evenodd" d="M10.6442 1.23466C10.272 0.921127 9.72805 0.921128 9.3558 1.23467L3.35931 6.28537C2.64595 6.88621 3.07084 8.05021 4.00352 8.05021L6.97882 8.05021C6.97549 8.08339 6.97379 8.11705 6.97379 8.15111L6.97379 13.9998C6.97379 14.5521 7.4215 14.9998 7.97379 14.9998H12.0263C12.5786 14.9998 13.0263 14.5521 13.0263 13.9998L13.0263 8.15111C13.0263 8.11705 13.0246 8.08339 13.0213 8.05021L15.9965 8.05022C16.9292 8.05022 17.3541 6.88622 16.6407 6.28537L10.6442 1.23466Z" fill="#7876C1"/>
</svg>
);

const UploadIcon = (props) => <Icon component={svg} {...props} />;

export default UploadIcon;