import getRaw from './getRaw';

const getWhatsNext = ({ configText }) => {
  const title = configText('section whatsnext title');
  const whatsnext = configText('whats next');

  return {
    title,
    rawtitle: getRaw(title),
    subrawtitle: getRaw(''),
    text: whatsnext,
    raw: getRaw(whatsnext)
  };
};

export default getWhatsNext;
