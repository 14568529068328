import React from 'react';

import { Spin } from 'antd';

import './Loader.scss';

const Loader = ({ className, overlay }) => (
  <div
    className={`loader ${className}`}
    style={overlay ? { position: 'absolute', zIndex: 1100, backgroundColor: '#2f2c8857' } : {}}>
    <Spin size="large" />
  </div>
);

export default Loader;
