import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Statistic,
  Table,
} from 'antd';
import React, { useState, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import _ from 'lodash';

import { FETCH_ADMIN_AFFILIATES } from 'graphql/queries/adminQueries';
import { UPDATE_COMMISSION_PAYMENT } from 'graphql/mutations/adminMutations';
import { AffiliatesDetailsTable } from './components';

const AffiliateDetails = () => {
  const initialFilters = {
    startDate: moment().subtract(8, 'y').toISOString(),
    endDate: moment().endOf('day').toISOString(),
    search: '',
  };

  const [affiliateSons, setAffiliateSons] = useState([]);
  const [adminAffiliatesCount, setAdminAffiliatesCount] = useState(null);
  const [showAffiliateSonModal, setShowAffiliateSonModal] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const [selectedOption, setSelectedOption] = useState('all');
  const [isPaymentRequest, setIsPaymentRequest] = useState(false);

  const [data, setData] = useState({
    users: [],
    sons: [],
    parents: [],
    beforePayment: 0,
    afterPayment: 0,
    transactions: [],
    totalCredits: 0,
  });

  const updateAdminAffiliates = (adminAffiliates) => {
    const filteredUsers = Array.from(new Set(adminAffiliates.map((user) => user._id))).map((id) =>
      adminAffiliates.find((user) => user._id === id)
    );

    let transactionsBeforePayment = [];
    let transactionsAfterPayment = [];
    let beforePayment = 0;
    let afterPayment = 0;
    let totalCredits = 0;
    const usersWithTransaction = filteredUsers.filter(
      (user) => user.affiliatePayments && user.affiliatePayments.length
    );

    let transactions = [];
    usersWithTransaction.forEach((user) => {
      user.affiliatePayments.forEach((payments) => {
        transactions.push({
          ...payments,
          userId: user._id,
          username: user.profile.name,
          email: user.emails[0].address,
          paypalId: user.paypalId,
        });
      });
    });

    transactions = transactions.sort(function (a, b) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    transactions = transactions.filter((transaction) => {
      if (
        transaction.paided &&
        moment(transaction.createdAt).isBetween(filters.startDate, filters.endDate)
      )
        return true;
      if (!transaction.paided) return true;
      return false;
    });

    transactionsBeforePayment = transactions.filter((transaction) => !transaction.paided);
    transactionsAfterPayment = transactions.filter((transaction) => transaction.paided);

    transactionsBeforePayment.forEach((transaction) => {
      beforePayment += transaction.requestedCredits;
    });

    transactionsAfterPayment = transactionsAfterPayment.filter((transaction) =>
      moment(transaction.createdAt).isBetween(filters.startDate, filters.endDate)
    );
    transactionsAfterPayment.forEach((transaction) => {
      afterPayment += transaction.requestedCredits;
    });

    const affiliateTransactions = [];
    const usersWithAffiliateTransactions = filteredUsers.filter(
      (user) => user.affiliateTransactions && user.affiliateTransactions.length
    );

    usersWithAffiliateTransactions.forEach((user) => {
      user.affiliateTransactions.forEach((transaction) => {
        if (moment(transaction.createdAt).isBetween(filters.startDate, filters.endDate))
          affiliateTransactions.push(transaction);
      });
    });

    affiliateTransactions.forEach((transaction) => {
      totalCredits += transaction.amount;
    });

    const affiliateDataSource = filteredUsers
      .filter((user) => user.affiliates && user.affiliates.length)
      .map((user, index) => ({
        id: user._id,
        name: user.profile.name,
        email: user.emails[0].address,
        createdAt: moment(user.createdAt).format('DD/MM/YYYY'),
        totalSons: user.affiliates.length,
        credit: user.credits ? user.credits : 0,
        userData: user,
      }));

    const transactionDataSource = transactions.map((el) => ({
      id: el.userId,
      // name: loading.username,
      email: el.email,
      paypalAccount: el.paypalId,
      commission: el.requestedCredits ? el.requestedCredits.toFixed(5) : 0,
      date: moment(el.createdAt).format('DD/MM/YYYY'),
      status: el.paided ? 'Have Paid' : 'Need to Pay',
      action: el,
    }));

    setData({
      users: affiliateDataSource,
      sons: new Set(affiliateTransactions.map((el) => el.sender)),
      parents: new Set(affiliateTransactions.map((el) => el.receiver)),
      transactions: transactionDataSource,
      totalCredits,
      beforePayment,
      afterPayment,
    });
  };

  const {
    loading: isFetchingAdminAffiliates,
    refetch,
  } = useQuery(FETCH_ADMIN_AFFILIATES, {
    variables: {
      userCondition: {
        queryString: filters.search,
        queryDates: {
          startDate: filters.startDate,
          endDate: filters.endDate,
        },
        generalStats: true,
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const {
        fetchAdminAffiliates: { affiliates, affiliatesCount },
      } = res;

      if (affiliatesCount) {
        setAdminAffiliatesCount(affiliatesCount);
      }
      updateAdminAffiliates(affiliates);
    },
  });

  const [updateUserCommission] = useMutation(UPDATE_COMMISSION_PAYMENT, {
    onCompleted: async ({ updateCommissionPayment }) => {
      if (updateCommissionPayment) {
        const { data: affiliatesData } = await refetch({
          variables: {
            queryDates: {
              startDate: filters.startDate,
              endDate: filters.endDate,
            },
            generalStats: true,
          },
        });

        if (affiliatesData?.fetchAdminAffiliates?.affiliates) {
          updateAdminAffiliates(affiliatesData.fetchAdminAffiliates.affiliates);
        }

        setIsPaymentRequest(false);
      }
    },
    onError: () => {
      setIsPaymentRequest(false);
    },
  });

  const handleRowClick = (record) => {
    const affiliateData = record?.userData?.affiliates.map((el, index) => ({
      id: index + 1,
      name: el.affiliatedUser.profile.name,
      email: el.affiliatedUser.emails[0].address,
      package: el.affiliatedUser.type,
    })) || [];
    setAffiliateSons(affiliateData);
    setShowAffiliateSonModal(true);
  };

  const affiliateSonColumn = [
    {
      title: '#Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Package',
      dataIndex: 'package',
      key: 'pacakge',
    },
  ];

  const completePayment = (userId, createdAt) => {
    setIsPaymentRequest(true);
    updateUserCommission({
      variables: {
        userId,
        createdAt,
      },
    });
  };

  const handlePay = (userId, createdAt) => {
    Modal.confirm({
      title: 'Transferring Payment',
      content: 'Are you sure?',
      onOk: () => {
        completePayment(userId, createdAt);
      },
      onCancel: () => {},
    });
  };

  const paymentColumn = [
    {
      title: '#Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Paypal Account',
      dataIndex: 'paypalAccount',
      key: 'paypalAccount',
    },
    {
      title: 'Commission',
      dataIndex: 'commission',
      key: 'commission',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (txn) => {
        return txn.paided ? (
          <span>Paid</span>
        ) : (
          <Button
            type="primary"
            onClick={() => {
              handlePay(txn.userId, txn.createdAt);
            }}
            loading={isPaymentRequest}>
            Pay
          </Button>
        );
      },
    },
  ];

  const handleFilters = useCallback(
    (type, value) => {
      if (type === 'date') {
        [filters.startDate, filters.endDate] = value;
      } else {
        filters[type] = value;
      }
      setFilters({ ...filters });
    },
    [setFilters, filters]
  );

  const handleAffiliateOption = (selection) => {
    let tempFilters = { ...filters };
    if (selection === 'today') {
      tempFilters.startDate = moment().startOf('day').toISOString();
      tempFilters.endDate = moment().endOf('day').toISOString();
    } else if (selection === 'all') {
      tempFilters.startDate = moment().subtract(8, 'y').toISOString();
      tempFilters.endDate = moment().endOf('day').toISOString();
    }
    setSelectedOption(selection);
    setFilters({ ...tempFilters });
    refetch();
  };

  const debounceSearchHandler = _.debounce((value = () => { }) => {
    handleFilters('search', value);
  }, 3000);

  return (
    <div>
      <Modal
        visible={showAffiliateSonModal}
        onCancel={() => setShowAffiliateSonModal(false)}
        onOk={() => setShowAffiliateSonModal(false)}>
        <Table dataSource={affiliateSons} columns={affiliateSonColumn} />
      </Modal>
      <div className="admin-table-wrapper">
        <Row justify="space-between" align="middle" gutter={16}>
          <Col span={4}>
            <Card hoverable>
              <Statistic
                title="Total Affiliate Credit"
                value={data.totalCredits}
                precision={2}
                valueStyle={{ color: '#E8AC61' }}
                suffix="$"
              />
            </Card>
          </Col>
          <Col span={4}>
            <Card>
              <Statistic
                title="Total Before Payment"
                value={data.beforePayment}
                precision={2}
                valueStyle={{ color: '#CC5C58' }}
                suffix="$"
              />
            </Card>
          </Col>
          <Col span={4}>
            <Card>
              <Statistic
                title="Total After Payment"
                value={data.afterPayment}
                precision={2}
                valueStyle={{ color: '#71B163' }}
                suffix="$"
              />
            </Card>
          </Col>
          <Col span={4}>
            <Card>
              <Statistic
                title="Total Affiliate Parents"
                value={data.parents.length}
                precision={0}
                valueStyle={{ color: '#3f8600' }}
              />
            </Card>
          </Col>
          <Col span={4}>
            <Card>
              <Statistic
                title="Total Affiliate Sons"
                value={data.sons.length}
                precision={0}
                valueStyle={{ color: '#cf1322' }}
              />
            </Card>
          </Col>
        </Row>
      </div>

      <AffiliatesDetailsTable
        users={data.users}
        selectedOption={selectedOption}
        filters={filters}
        isFetchingAdminAffiliates={isFetchingAdminAffiliates}
        adminAffiliatesCount={adminAffiliatesCount}
        handleFilters={handleFilters}
        handleRowClick={handleRowClick}
        handleAffiliateOption={handleAffiliateOption}
        refetch={refetch}
        debounceSearchHandler={debounceSearchHandler}
      />

      <div className="admin-table-wrapper">
        <div className="table-action">
          <h3>Request Payments</h3>
        </div>
        <Table dataSource={data.transactions} columns={paymentColumn} />
      </div>
    </div>
  );
};

export default AffiliateDetails;
