import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, message, Empty } from 'antd';
import { useMutation, useLazyQuery, useReactiveVar } from '@apollo/client';

import PropTypes from 'prop-types';

import { getFilteredSections } from 'helpers/contentlibrary';
import { GET_ALL_SECTIONS_LIBRARIES } from 'graphql/queries/contentLibraryQueries';
import {
  UPDATE_CONTENT_LIBRARY,
  CONTENT_LIBRARY_SECTION_DELETE,
} from 'graphql/mutations/contentLibraryMutations';
import { SECTIONS_LIMIT } from 'constants/index';
import { sectionsVar, sectionsCountVar } from 'graphql/cache';
import { ContentLoader } from '../ContentLoader/ContentLoader';

import ConfirmModal from 'components/ConfirmModal';
import ContentLibraryTree from '../ContentLibraryTree/ContentLibraryTree';
import SectionCard from '../SectionCard/SectionCard';
import PreviewProposalModal from '../PreviewProposalModal/PreviewProposalModal';

const SectionTab = ({ user, searchValue, config, setConfig, isLocked, onFolderChange, loadConfig }) => {
  const [previewModal, showPreviewModal] = useState(false);
  const [selectedFolderKey, changeSelectedFolderKey] = useState('');
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSectionId, setSelectedSectionId] = useState('');
  const [offset, setOffset] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [confirmModal, showConfirmModal] = useState(false);
  const [selectedSectionEditValue, setSelectedSectionEditValue] = useState('');

  const sections = useReactiveVar(sectionsVar || []);
  const sectionsCount = useReactiveVar(sectionsCountVar);

  const history = useHistory();

  const [fetchAllSections, { loading: isFetchingSections, refetch, fetchMore }] = useLazyQuery(
    GET_ALL_SECTIONS_LIBRARIES,
    {
      fetchPolicy: 'no-cache',
      skip: !!sections?.length,
      variables: {
        limit: SECTIONS_LIMIT * (window.innerHeight >= 1250 ? 2 : 1),
        offset: 0,
        search: searchValue,
        key: selectedFolderKey,
      },
      onCompleted: ({ fetchContentLibrarySectionsItems }) => {
        if (fetchContentLibrarySectionsItems?.sections) {
          sectionsVar(fetchContentLibrarySectionsItems?.sections?.slice());
          sectionsCountVar(fetchContentLibrarySectionsItems.sectionsCount);
        }
      },
    }
  );

  useEffect(() => {
    fetchAllSections();
  }, [fetchAllSections]);

  const refetchSections = useCallback(
    async ({ key, search }) => {
      if (user?.paymentStatus?.canLock) {
        return;
      }
      const refetchSectionData = await refetch?.({
        limit: SECTIONS_LIMIT * (window.innerHeight >= 1250 ? 2 : 1),
        offset: 0,
        search,
        key,
      });
      if (refetchSectionData?.data?.fetchContentLibrarySectionsItems) {
        sectionsVar(refetchSectionData?.data?.fetchContentLibrarySectionsItems.sections);
        sectionsCountVar(refetchSectionData.data.fetchContentLibrarySectionsItems.sectionsCount);
      }
    },
    [refetch, user?.paymentStatus?.canLock]
  );

  const fetchMoreSections = async () => {
    let page = offset;

    // reset offsets when loaded sections resets
    if (
      sections.length < sectionsCount &&
      Math.ceil(sectionsCount / SECTIONS_LIMIT) === page &&
      !isLoadingMore
    ) {
      sectionsCountVar(sections.length);
      setOffset(0);
    }

    if (
      (sectionsCount === null || Math.ceil(sectionsCount / SECTIONS_LIMIT) > page) &&
      fetchMore &&
      sections.length <= sectionsCount &&
      !isLoadingMore
    ) {
      setIsLoadingMore(true);

      const fetchMoreSectionData = await fetchMore({
        variables: {
          limit: SECTIONS_LIMIT * (window.innerHeight >= 1250 && !page ? 2 : 1),
          offset: page + 1,
          search: searchValue,
        },
      });

      sectionsVar([
        ...sections,
        ...fetchMoreSectionData?.data?.fetchContentLibrarySectionsItems.sections,
      ]);
      sectionsCountVar(fetchMoreSectionData.data.fetchContentLibrarySectionsItems.sectionsCount);
      setOffset(offset + 1);
      setIsLoadingMore(false);
    }
  };

  useEffect(() => {
    const mainContent = document.querySelector('.split-d2');
    async function onScroll() {
      if (mainContent.scrollTop + mainContent.offsetHeight + 1 > mainContent?.scrollHeight) {
        fetchMoreSections();
      }
    }

    mainContent.addEventListener('scroll', onScroll);

    return () => {
      mainContent.removeEventListener('scroll', onScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, searchValue, sections, isLoadingMore]);

  const [updateContentItem] = useMutation(UPDATE_CONTENT_LIBRARY, {
    onCompleted() {
      refetchSections({
        key: selectedFolderKey,
        search: searchValue,
      });
    },
  });

  const [deleteSection] = useMutation(CONTENT_LIBRARY_SECTION_DELETE, {
    onCompleted() {
      let tempSection = sections.filter((section) => section._id !== selectedSectionId);
      sectionsVar(tempSection);
      setSelectedSectionId('');
      message.success('Section deleted successfully');
      showConfirmModal(false);
    },
    onError() {
      message.error('Section deletion failed');
      showConfirmModal(false);
    },
  });

  const onSelect = (selectedKeys) => {
    changeSelectedFolderKey(selectedKeys[0]);
    onFolderChange(selectedKeys[0]);
  };

  useEffect(() => {
    if (searchValue || user.deletedTemplates) {
      refetchSections({
        key: selectedFolderKey,
        search: searchValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, selectedFolderKey]);

  const filteredSections = getFilteredSections({
    sections,
    searchValue,
  });

  const { starred: starredItems, all: allItems } = filteredSections;

  const starOrUnstar = ({ starred, sectionId }) => {
    updateContentItem({
      variables: {
        id: sectionId,
        type: 'sections',
        item: { starred },
      },
    });
  };

  const confirmDeleteSection = () => {
    deleteSection({
      variables: {
        _id: selectedSectionId,
        folderKey: selectedFolderKey,
      },
    });
  };

  const onClickMenuItem = async ({ actionType, sectionId, starred, sectionData, sectionName }) => {
    if (actionType === 'delete') {
      setSelectedSectionId(sectionId);
      showConfirmModal(true);
    } else if (actionType === 'starOrUnstar') {
      starOrUnstar({ starred, sectionId });
    } else if (actionType === 'preview') {
      setSelectedSection(sectionData);
      showPreviewModal(true);
    } else if (actionType === 'edit_section') {
      history.push(`/s/${sectionId}`);
    } else if (actionType === 'rename') {
      setSelectedSectionId(sectionId);
      setSelectedSectionEditValue(sectionName);
    } else if (actionType === 'save') {
      await updateContentItem({
        variables: {
          id: sectionId,
          type: 'sections',
          item: { libraryTitle: selectedSectionEditValue },
        },
      });
      setSelectedSectionId('');
      setSelectedSectionEditValue('');
    } else {
      message.warning('Coming soon 😊');
    }
  };

  const isSectionsEmpty = !(starredItems.length || allItems.length);

  // Filter out items with the key 'isHeader'
  const filteredStarredItems = starredItems.filter((item) => !item.isHeader);
  const filteredAllItems = allItems.filter((item) => !item.isHeader);

  return (
    <div className="content-library-item content-library-item-section">
      <ContentLibraryTree
        onSelect={onSelect}
        type="sections"
        onUpdate={() =>
          refetchSections({
            key: selectedFolderKey,
            search: searchValue,
          })
        }
        userId={user && user._id}
        userRole={user?.roles && user?.roles[0]}
        isLocked={isLocked}
      />
      <div className="split-d2 section-scroll-wrapper">
        {isFetchingSections ? (
          <ContentLoader />
        ) : (
          <>
            {filteredStarredItems.length ? (
              <div className="stared-header">
                MY STARRED SECTIONS
                <Badge className="star-badge" count={starredItems.length} />
              </div>
            ) : null}

              <React.Fragment>
                {isSectionsEmpty && (
                  <div className="item-row empty-wrapper">
                    <Empty
                      className="empty-view"
                      description="Looks like you have no section files yet"
                    />
                  </div>
                )}
                <div className="item-row covers-card">
                  {filteredStarredItems.map((item) => {
                    return (
                      <SectionCard
                        onDragStart={(ev) => {
                          ev.dataTransfer.setData('data', JSON.stringify(item));
                        }}
                        item={item}
                        title={item.libraryTitle}
                        key={item._id}
                        description={item.text || item.title}
                        onClickMenuItem={onClickMenuItem}
                        sectionId={item._id}
                        isStarred
                        userId={user._id}
                        userRole={user?.roles && user?.roles[0]}
                        sectionUserId={item.uid}
                        sectionAuid={item.auid}
                        itemEditable={selectedSectionId === item._id}
                        setEditValue={setSelectedSectionEditValue}
                      />
                    );
                  })}
                </div>

                {filteredStarredItems.length ? <div className="divider-line" /> : null}

                <div className="item-row covers-card">
                  {filteredAllItems.map((item) => {
                    return (
                      <SectionCard
                        onDragStart={(ev) => {
                          ev.dataTransfer.setData('data', JSON.stringify(item));
                        }}
                        item={item}
                        title={item.libraryTitle}
                        key={item._id}
                        description={item.text || item.title}
                        sectionId={item._id}
                        userId={user._id}
                        userRole={user?.roles && user?.roles[0]}
                        onClickMenuItem={onClickMenuItem}
                        sectionUserId={item.uid}
                        sectionAuid={item.auid}
                        itemEditable={selectedSectionId === item._id}
                        setEditValue={setSelectedSectionEditValue}
                      />
                    );
                  })}
                </div>

                {isLoadingMore && <ContentLoader length={4} />}
              </React.Fragment>
          </>
        )}
      </div>
      {previewModal && (
        <PreviewProposalModal
          onCancel={() => showPreviewModal(false)}
          section={selectedSection}
          config={config}
          loadConfig={loadConfig}
          user={user}
          title={selectedSection.libraryTitle}
        />
      )}
      {confirmModal && (
        <ConfirmModal
          title="Delete Section?"
          body="This action cannot be undone"
          visible={confirmModal}
          cancelText="CANCEL"
          confirmText="YES, DELETE"
          onCancel={() => showConfirmModal(false)}
          onConfirm={confirmDeleteSection}
        />
      )}
    </div>
  );
};

SectionTab.defaultProps = {
  sections: [],
  searchValue: '',
  config: {},
  isLocked: false,
};

SectionTab.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  searchValue: PropTypes.string,
  config: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object).isRequired,
  isLocked: PropTypes.bool,
};

export default SectionTab;
