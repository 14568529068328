import React from 'react';
import Icon from '@ant-design/icons';

const svg = (props) => {
  const left = '';
  const right = '';
  return (
    <svg className="direction-icon" width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="13"
        y="14"
        id="rect-left"
        width="11"
        height="2"
        rx="1"
        transform="rotate(-180 13 14)"
        fill={right ? '#05034D' : '#9E9EB7'}
      />
      <path
        d="M4 16L1 13L4 10"
        id="path-left"
        stroke={right ? '#05034D' : '#9E9EB7'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        y="3"
        id="rect-right"
        width="11"
        height="2"
        rx="0.999999"
        fill={left ? '#05034D' : '#9E9EB7'}
      />
      <path
        d="M9 0.999999L12 4L9 7"
        id="path-right"
        stroke={left ? '#05034D' : '#9E9EB7'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const DirectionalIcon = (props) => <Icon component={svg} {...props} />;

export default DirectionalIcon;
