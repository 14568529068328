import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="32" height="17" viewBox="0 0 32 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.08027 15.3983L7.27313 9.31856C7.67239 8.9266 7.67239 8.28334 7.27313 7.89138L1.08028 1.81162"
      stroke="#C7BCE4"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M9.08027 15.3983L15.2731 9.31856C15.6724 8.9266 15.6724 8.28334 15.2731 7.89138L9.0803 1.81162"
      stroke="#C7BCE4"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

const DoubleArrowIcon = (props) => <Icon component={svg} {...props} />;

export default DoubleArrowIcon;
