import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="4"
      y="11"
      width="10"
      height="0.999999"
      rx="0.499999"
      transform="rotate(-90 4 11)"
      fill="#CBCED8"
    />
    <path d="M0.999999 4L4.5 1L8 4" stroke="#CBCED8" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const ArrowUpDisabled = (props) => <Icon component={svg} {...props} />;

export default ArrowUpDisabled;
