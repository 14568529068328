import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="7" height="22" viewBox="0 0 7 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
      <circle cx="1" cy="5" r="1" fill="#4F4E7B" />
      <circle cx="1" cy="9" r="1" fill="#4F4E7B" />
      <circle cx="1" cy="1" r="1" fill="#4F4E7B" />
      <circle cx="1" cy="13" r="1" fill="#4F4E7B" />
      <circle cx="1" cy="17" r="1" fill="#4F4E7B" />
      <circle cx="1" cy="21" r="1" fill="#4F4E7B" />
      <circle cx="6" cy="5" r="1" fill="#4F4E7B" />
      <circle cx="6" cy="9" r="1" fill="#4F4E7B" />
      <circle cx="6" cy="1" r="1" fill="#4F4E7B" />
      <circle cx="6" cy="13" r="1" fill="#4F4E7B" />
      <circle cx="6" cy="17" r="1" fill="#4F4E7B" />
      <circle cx="6" cy="21" r="1" fill="#4F4E7B" />
    </g>
  </svg>
);

const DragIcon = (props) => <Icon component={svg} {...props} />;

export default DragIcon;
