import { EditorState, DefaultDraftBlockRenderMap } from 'draft-js';
import Immutable from 'immutable';

import {
  styleMap,
  fontListEnglish,
  fontWeights,
  fontSizes,
} from 'pages/Proposal/constants/constants';

const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(Immutable.Map({}));

const fontDefaultFamilies = {};
const fontDefaultWeights = {};
const fontDefaultSizes = {};

fontListEnglish.forEach((font) => {
  fontDefaultFamilies[font.family] = {
    fontFamily: font.family,
  };
});

fontWeights.forEach((weight) => {
  fontDefaultWeights[weight] = {
    fontWeight: weight,
  };
});

fontSizes.forEach((size) => {
  fontDefaultSizes[size] = {
    fontSize: size,
  };
});

export const initialState = {
  decorator: '',
  blockRenderMap: extendedBlockRenderMap,
  editorState: EditorState.createEmpty(),
  inlineToolbar: { show: false },
  simple: 0,
  liveTeXEdits: Immutable.Map(),
  styleMap: {
    ...styleMap,
    ...fontDefaultFamilies,
    ...fontDefaultWeights,
    ...fontDefaultSizes,
  },
  position: '',
  displayHyperlinkInput: false,
  currentCursorOffset: {
    position: {
      left: 0,
      top: 0,
    },
  },
  editorReadOnly: false,
  selectionState: '',
  htmlEdit: '',
  keyType: '',
  variableState: null,
  colorOpen: false,
  displayAIToolBar: '',
  lineHeightInput: false,
  displayLink: false,
  linkTextValue: '',
  linkUrlValue: '',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setEditorState':
      return { ...state, editorState: action.editorState };
    case 'setSelectionState':
      return { ...state, selectionState: action.selectionState };
    case 'setAISelectionState':
      return { ...state, aISelectionState: action.aISelectionState };
    case 'setDropEditorState':
      return { ...state, dropEditorState: action.dropEditorState };
    case 'setBlockRenderMap':
      return { ...state, blockRenderMap: action.blockRenderMap };
    case 'setDecorator':
      return { ...state, decorator: action.decorator };
    case 'setInlineToolbar':
      return { ...state, inlineToolbar: action.inlineToolbar };
    case 'setCurrentCursorOffset':
      return {
        ...state,
        currentCursorOffset: action.currentCursorOffset,
      };
    case 'setSimple':
      return { ...state, simple: action.simple };
    case 'setLiveTeXEdits':
      return { ...state, liveTeXEdits: action.liveTeXEdits };
    case 'setStyleMap':
      return { ...state, totalValue: action.totalValue };
    case 'setPosition':
      return { ...state, position: action.position };
    case 'setSelectedBlock':
      return { ...state, selectedBlock: action.selectedBlock };
    case 'setSelectionRange':
      return { ...state, selectionRange: action.selectionRange };

    case 'setDisplayColorPicker':
      return {
        ...state,
        displayColorPicker: action.displayColorPicker,
        updated: Date.now(),
      };
    case 'setVideoDialog':
      return { ...state, videoDialog: action.videoDialog };
    case 'setTimeEdit':
      return { ...state, timeEdit: action.timeEdit };
    case 'setPriceEdit':
      return { ...state, priceEdit: action.priceEdit };
    case 'setSuperEdit':
      return { ...state, superEdit: action.superEdit };
    case 'setTableEdit':
      return { ...state, tableEdit: action.tableEdit };
    case 'setGalleryEdit':
      return { ...state, galleryEdit: action.galleryEdit };
    case 'setTableAddModal':
      return { ...state, tableAddModal: action.tableAddModal };
    case 'setEditorReadOnly':
      return { ...state, editorReadOnly: action.editorReadOnly };
    case 'setHtmlEdit':
      return { ...state, htmlEdit: action.htmlEdit };
    case 'setKeyType':
      return { ...state, keyType: action.keyType };
    case 'setVariableState':
      return { ...state, variableState: action.variableState };
    case 'setDisplayVariableInput':
      return { ...state, displayVariableInput: action.displayVariableInput };
    case 'setColorOpen':
      return { ...state, colorOpen: action.colorOpen };
    case 'setDisplayAIToolBar':
      return { ...state, displayAIToolBar: action.displayAIToolBar };
    case 'setLineHeightInput':
      return { ...state, lineHeightInput: action.lineHeightInput };
    case 'setDisplayHyperlinkInput':
      return { ...state, displayHyperlinkInput: action.displayHyperlinkInput };
    case 'setDisplayLink':
      return { ...state, displayLink: action.displayLink };
    case 'setLinkTextValue':
      return { ...state, linkTextValue: action.linkTextValue };
    case 'setLinkUrlValue':
      return { ...state, linkUrlValue: action.linkUrlValue };
    default:
      throw new Error('Unexpected action ' + action.type);
  }
};

