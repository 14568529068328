import React, { useState } from 'react';
import { Menu, Dropdown, Button } from 'antd';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import IntegrationSmallIcon from 'components/Icons/IntegrationSmallIcon';
import StripeIntegration from 'pages/Integration/components/StripeIntegration';
import ZapierIntegration from 'pages/Integration/components/ZapierIntegration';
import QuickbooksIntegration from 'pages/Integration/components/QuickbooksIntegration/QuickbooksIntegration';
import XeroIntegration from 'pages/Integration/components/XeroIntegration/XeroIntegration';
import SlackIntegration from 'pages/Integration/components/SlackIntegration/SlackIntegration';
import IntegromatIntegration from 'pages/Integration/components/IntegromatIntegration';
import FreshbooksIntegration from 'pages/Integration/components/FreshbooksIntegration/FreshbooksIntegration';
import CodeIntegration from 'pages/Integration/components/CodeIntegration/CodeIntegration';
import UserwayIntegration from 'pages/Integration/components/UserwayIntegration/UserwayIntegration';
import { GET_STRIPE_SUPPORTED_CURRENCIES } from 'graphql/queries/integrationQueries';
import { getUserId } from 'graphql/cache';

import './IntegrationModal.scss';
import { ChatwayIntegration } from 'pages/Integration/components/ChatwayIntegration';

const IntegrationModal = ({ user, proposal, saveProposal, isSaving }) => {
  const [optionsVisible, setOptionVisibility] = useState(false);
  const [currencies, setCurrencies] = useState([{ value: 'usd', label: 'USD' }]);

  useQuery(GET_STRIPE_SUPPORTED_CURRENCIES, {
    skip: !getUserId() || !user || proposal?.stripe?.type !== 'stripe',
    fetchPolicy: 'cache-and-newtwork',
    onCompleted: (data) => {
      const { fetchStripeSupportedCurrencies } = data;
      const updatedCurrencies = fetchStripeSupportedCurrencies.map((currency) => {
        return { value: currency, label: currency };
      });

      setCurrencies(updatedCurrencies, fetchStripeSupportedCurrencies);
    },
  });

  const options = (
    <Menu className="integration-menu">
      <Menu.Item key="item">
        <div className="integration">
          <StripeIntegration
            isModalItem
            data={user.stripe}
            currencies={currencies}
            proposal={proposal}
            saveProposal={saveProposal}
          />
          <ChatwayIntegration
            data={proposal?.chatway !== false ? user?.chatwayScripts : []}
            saveProposal={saveProposal}
            isSaving={isSaving}
            isModalItem
          />
          <ZapierIntegration data={user.zaps} isModalItem />
          <IntegromatIntegration data={user.integromatHooks} isModalItem />
          <FreshbooksIntegration data={user.freshbooks} isModalItem />
          <QuickbooksIntegration data={user.quickbooks} isModalItem />
          <XeroIntegration data={user.xero} isModalItem />
          <SlackIntegration data={user.slack} isModalItem />
          <UserwayIntegration
            data={
              proposal.hasOwnProperty('userway') && proposal.userway !== null
                ? proposal.userway
                : user?.userway || false
            }
            saveProposal={saveProposal}
            isModalItem
          />
          <CodeIntegration
            data={proposal?.scripts || user?.scripts}
            saveProposal={saveProposal}
            isModalItem
          />
        </div>
      </Menu.Item>
    </Menu>
  );

  const onClickMenu = () => {
    setOptionVisibility(true);
    const menu = document.querySelector('.integration-menu');
    if (menu) menu.scrollTop = 0;
  };

  return (
    <Dropdown
      overlay={options}
      placement="bottomRight"
      overlayClassName="integrations-menu-wrapper"
      visible={optionsVisible}
      onVisibleChange={() => {
        setOptionVisibility(!optionsVisible);
      }}
      trigger="click">
      <Button
        className="settings-button left"
        icon={<IntegrationSmallIcon />}
        onClick={onClickMenu}
        aria-hidden="true">
        Integrations
      </Button>
    </Dropdown>
  );
};

IntegrationModal.defaultProps = {
  user: {
    stripe: null,
    zaps: null,
  },
  proposal: null,
  freshbooks: '',
};

IntegrationModal.propTypes = {
  proposal: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  saveProposal: PropTypes.func.isRequired,
  user: PropTypes.shape({
    stripe: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.instanceOf(null)]),
    zaps: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.instanceOf(null)]),
    quickbooks: PropTypes.oneOfType([
      PropTypes.shape({
        access_token: PropTypes.string,
        refresh_token: PropTypes.string,
      }),
      PropTypes.instanceOf(null),
    ]),
    integromatHooks: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.instanceOf(null)]),
    freshbooks: PropTypes.oneOfType([
      PropTypes.shape({
        ID: PropTypes.shape({
          id: PropTypes.number.isRequired,
        }),
      }),
      PropTypes.string,
    ]),
    scripts: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default IntegrationModal;
