import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { message } from 'antd';
import Dropzone from 'react-dropzone';

import Quote2Icon from 'components/Icons/Quote2Icon';
import Uploader from 'components/Uploader';

import { resizeImageForS3 } from 'utils/uploadToS3';

// ----------------------------

const TestimonialItem = ({
  avatar = '',
  name = '',
  text = '',
  index = 0,
  handleEdit,
  uniqueId,
  textColor = '#4A4A4A',
}) => {
  const [upload] = Uploader();
  const [uploadingAvatar, setUploadingAvatar] = useState(null);

  const handleNameChange = (e) => {
    if (!uniqueId) {
      return;
    }
    const { value } = e.target;
    handleEdit(value, index, 'name', true);
  };

  const handleTextChange = (e) => {
    if (!uniqueId) {
      return;
    }
    const { value } = e.target;
    handleEdit(value, index, 'text', true);
  };

  const dropImage = async (files) => {
    try {
      if (!files?.length || !uniqueId) {
        throw new Error('Please upload only image');
      }

      let [avatarFile] = files;

      const reSizedImage = await resizeImageForS3({
        file: avatarFile,
        path: 'testimonial',
        size: { width: 100, height: 100 },
      });

      // get base64 for preview while uploading
      const reader = new FileReader();
      reader.onload = function (e) {
        const base64Image = e.target.result;
        setUploadingAvatar(base64Image);
      };
      reader.readAsDataURL(reSizedImage);

      const uploadedFiles = await upload([reSizedImage], `props/${uniqueId}/testimonials`);
      // delay removing
      setUploadingAvatar(null);

      if (Array.isArray(uploadedFiles) && uploadedFiles.length && uploadedFiles[0]?.url) {
        handleEdit(uploadedFiles[0]?.url, index, 'avatar', false);
      } else {
        throw new Error('Image upload failed');
      }
    } catch (error) {
      return message.error(error.message);
    }
  };

  const avatarHtml = (
    <div
      className="avatar"
      style={
        avatar
          ? {
              backgroundImage: `url(${avatar})`,
              backgroundSize: 'cover',
            }
          : {}
      }
    />
  );

  const pseudoWord = (name) => {
    return name
      .replace(/\n/g, '\nI') // replace new line with \nI else 2nd line else line is not visible
      .split(' ')
      .map((word) => {
        if (word.length > 20) {
          // check if any large word exists then break it in 30 30 chars
          let chunks = '';
          for (let i = 0; i < word.length; i += 20) {
            chunks = `${chunks} ${word.substring(i, i + 20)}`;
          }
          return chunks;
        }
        return word;
      })
      .join(' ');
  };

  return (
    <div className="testimonial-item">
      <Quote2Icon className="quote-icon" />

      <div className="testimonial-content">
        <div className="avatar-wrapper">
          {uniqueId ? (
            <Dropzone onDrop={dropImage} accept="image/*">
              {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                return (
                  <div
                    {...getRootProps()}
                    className={`dropzone ${isDragActive && 'active'} ${isDragReject && 'reject'}`}>
                    <input {...getInputProps()} />
                    {avatarHtml}
                    {uploadingAvatar && (
                      <div
                        className="avatar uploading"
                        style={{
                          backgroundImage: `url(${uploadingAvatar})`,
                          backgroundSize: 'cover',
                          filter: 'brightness(60%)',
                        }}
                      />
                    )}
                  </div>
                );
              }}
            </Dropzone>
          ) : (
            avatarHtml
          )}
        </div>
        <div className="text-wrapper">
          <div className="name">
            <div className="pseudo">I{pseudoWord(name)}</div>
            <textarea
              rows="1"
              className="editable"
              onBlur={() => {
                uniqueId && handleEdit(text, index, 'text', false);
              }}
              onChange={handleNameChange}
              value={name}
              placeholder="Enter name"
              readOnly={!uniqueId}
            />
          </div>

          <div className="text">
            <div className="pseudo">I{pseudoWord(text)}</div>
            <textarea
              rows="1"
              className="editable"
              onBlur={() => {
                uniqueId && handleEdit(text, index, 'text', false);
              }}
              onChange={handleTextChange}
              value={text}
              placeholder="Enter testimonial text here..."
              readOnly={!uniqueId}
            />
          </div>
        </div>
      </div>
      <hr style={{ borderColor: textColor }} />
    </div>
  );
};

export default TestimonialItem;

TestimonialItem.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  index: PropTypes.number,
  handleEdit: PropTypes.func,
  uniqueId: PropTypes.string,
  textColor: PropTypes.string,
};
