import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse
      cx="12.5"
      cy="2.5"
      rx="2.5"
      ry="2.5"
      transform="rotate(-180 12.5 2.5)"
      fill="#C9C8E6"
    />
    <ellipse
      cx="12.5"
      cy="22.5"
      rx="2.5"
      ry="2.5"
      transform="rotate(-180 12.5 22.5)"
      fill="#C9C8E6"
    />
    <ellipse
      cx="22.5"
      cy="12.5"
      rx="2.5"
      ry="2.5"
      transform="rotate(-90 22.5 12.5)"
      fill="#C9C8E6"
    />
    <ellipse cx="2.5" cy="12.5" rx="2.5" ry="2.5" transform="rotate(-90 2.5 12.5)" fill="#C9C8E6" />
    <rect x="4" y="12" width="17" height="1" fill="#C9C8E6" />
    <ellipse
      cx="22.5"
      cy="12.5"
      rx="2.5"
      ry="2.5"
      transform="rotate(-90 22.5 12.5)"
      fill="#C9C8E6"
    />
    <ellipse cx="2.5" cy="12.5" rx="2.5" ry="2.5" transform="rotate(-90 2.5 12.5)" fill="#C9C8E6" />
    <rect x="4" y="12" width="17" height="1" fill="#C9C8E6" />
    <ellipse
      cx="18.5713"
      cy="6.84304"
      rx="1.5"
      ry="1.5"
      transform="rotate(-135 18.5713 6.84304)"
      fill="#C9C8E6"
    />
    <ellipse
      cx="6.4292"
      cy="17.9854"
      rx="1.5"
      ry="1.5"
      transform="rotate(-135 6.4292 17.9854)"
      fill="#C9C8E6"
    />
    <ellipse
      cx="18.1575"
      cy="18.571"
      rx="1.5"
      ry="1.5"
      transform="rotate(-45 18.1575 18.571)"
      fill="#C9C8E6"
    />
    <ellipse
      cx="7.01487"
      cy="6.42896"
      rx="1.5"
      ry="1.5"
      transform="rotate(-45 7.01487 6.42896)"
      fill="#C9C8E6"
    />
    <rect x="13" y="4" width="17" height="1" transform="rotate(90 13 4)" fill="#C9C8E6" />
    <circle cx="12.5" cy="12.5" r="4.5" fill="#7876C1" />
  </svg>
);

const IntegrationSmallIcon = (props) => <Icon component={svg} {...props} />;

export default IntegrationSmallIcon;
