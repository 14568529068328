import { gql } from '@apollo/client';

export const FETCH_WHITELISTED_LINKS = gql`
  query FetchWhitelistedLinksQuery(
    $search: String!
    $blocked: Boolean = null
    $selectedDate: [String!] = null
    $limit: Float!
    $offset: Float!
  ) {
    fetchWhitelistedLinks(
      search: $search
      blocked: $blocked
      selectedDate: $selectedDate
      limit: $limit
      offset: $offset
    ) {
      count
      links {
        _id
        url
        blocked
        createdAt
        updatedAt
      }
    }
  }
`;
