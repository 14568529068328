import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="5" height="13" viewBox="0 0 5 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.76416 13H0.332031L2.42383 0.914062H4.85596L2.76416 13Z" fill="#9E9EB7" />
  </svg>
);

const ItalicIcon = (props) => <Icon component={svg} {...props} />;

export default ItalicIcon;
