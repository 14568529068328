import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Empty, Pagination, Spin } from 'antd';
import { useQuery } from '@apollo/client';

import { RedirectIcon, CloseIcon } from 'components/Icons';
import { GET_SPAM_PROPOSALS } from 'graphql/queries/proposalQueries';
import utils from 'utils/utils';

const PAGE_SIZE = 20;

const SpamProposals = ({ spamUrl, onClose }) => {
  const [proposals, setProposals] = useState([]);
  const [proposalsCount, setProposalsCount] = useState(0);
  const [page, setPage] = useState(1);

  const { loading: isFetchingProposals, refetch } = useQuery(GET_SPAM_PROPOSALS, {
    variables: {
      spamUrl: spamUrl,
      limit: PAGE_SIZE,
      offset: (page - 1) * PAGE_SIZE,
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ fetchProposals }) => {
      parseAndSetData(fetchProposals);
    },
  });

  const fetchAgain = async () => {
    const { data } = await refetch({
      variables: {
        spamUrl: spamUrl,
        limit: PAGE_SIZE,
        offset: (page - 1) * PAGE_SIZE,
      },
    });
    parseAndSetData(data?.fetchProposals || {});
  };

  const parseAndSetData = (data) => {
    const _proposals = data?.proposals.map((l) => ({
      _id: l._id,
      pid: l.pid,
      pnum: l.pnum,
      uid: l.uid,
      email: l?.email,
      name: l?.project.name,
      state: l.state,
      spamUrls: l?.spamUrls,
    }));
    setProposals([..._proposals]);
    setProposalsCount(data?.proposalsCount || 0);
  };

  useEffect(() => {
    fetchAgain();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <Modal
      className="spam-proposals"
      visible={true}
      footer={
        <div className="button-wrapper">
          <Pagination
            current={page}
            pageSize={PAGE_SIZE}
            total={proposalsCount}
            onChange={(v) => setPage(v)}
            size="small"
          />

          <Button className="button cancel" type="primary" onClick={onClose}>
            Close
          </Button>
        </div>
      }
      width={800}
      closeIcon={<CloseIcon className="close-icon" />}
      onCancel={onClose}>
      <div>
        <h3 className="title">Proposals with spam proposals</h3>

        <div className="divider" />
        <div>
          {isFetchingProposals && <Spin />}

          {!isFetchingProposals && (
            <>
              {proposals.length ? (
                proposals.map((p) => (
                  <div key={p._id} className="proposal-card">
                    <div className="info-row">
                      <div className="name">{p.name}</div>
                      <div className="state">{p.state}</div>
                    </div>
                    <br />
                    <div className="info-row">
                      <div className="ant-col-12">
                        <strong>_id</strong> : {p._id}
                      </div>
                      <div className="ant-col-12">
                        <strong>pid</strong> : {p.pid}
                      </div>
                    </div>
                    <div className="info-row">
                      <div className="ant-col-12">
                        <strong>userId</strong> : {p.uid}
                      </div>
                      <div className="ant-col-12">
                        <strong>Email</strong> : {p.email}
                      </div>
                    </div>
                    <div className="info-row spam-links">
                      {p.spamUrls.map((p) => (
                        <span
                          key={p.url}
                          className={`url ${p.blocked ? 'blocked' : 'whitelisted'}`}>
                          <a href={p.url} target="_blank" rel="noreferrer">
                            {utils.cutLongText(p.url)}
                            &nbsp;
                            <RedirectIcon />
                          </a>
                          &nbsp;&nbsp;
                          <strong>
                            {'('}
                            {p.blocked ? 'Blocked' : 'Whitelisted'}
                            {')'}
                          </strong>
                        </span>
                      ))}
                    </div>
                  </div>
                ))
              ) : (
                <Empty />
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

SpamProposals.defaultProps = {
  spamUrl: '',
  onClose: () => {},
};

SpamProposals.propTypes = {
  spamUrl: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SpamProposals;
