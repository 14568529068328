import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg viewBox="0 0 18 18" fill="currentColor" width="18px" height="18px" aria-hidden="true">
    <path d="M4,8 L6,8 L6,10 L4,10 L4,8 Z M8,8 L10,8 L10,10 L8,10 L8,8 Z M12,8 L14,8 L14,10 L12,10 L12,8 Z"></path>
  </svg>
);

const WixDropdownIcon = (props) => <Icon component={svg} {...props} />;

export default WixDropdownIcon;
