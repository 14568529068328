import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.0791016 13V0.914062H4.3125C5.77897 0.914062 6.89128 1.19629 7.64941 1.76074C8.40755 2.31966 8.78662 3.14144 8.78662 4.22607C8.78662 4.8182 8.63444 5.34115 8.33008 5.79492C8.02572 6.24316 7.60238 6.57243 7.06006 6.78271C7.67985 6.93766 8.16683 7.25033 8.521 7.7207C8.8807 8.19108 9.06055 8.7666 9.06055 9.44727C9.06055 10.6094 8.68978 11.4893 7.94824 12.0869C7.20671 12.6846 6.14974 12.9889 4.77734 13H0.0791016ZM2.56934 7.7373V10.9995H4.70264C5.28923 10.9995 5.74577 10.8612 6.07227 10.5845C6.4043 10.3022 6.57031 9.91488 6.57031 9.42236C6.57031 8.31559 5.99756 7.75391 4.85205 7.7373H2.56934ZM2.56934 5.97754H4.41211C5.66829 5.9554 6.29639 5.45459 6.29639 4.4751C6.29639 3.92725 6.1359 3.53434 5.81494 3.29639C5.49951 3.0529 4.9987 2.93115 4.3125 2.93115H2.56934V5.97754Z"
      fill="#9E9EB7"
    />
  </svg>
);

const BoldIcon = (props) => <Icon component={svg} {...props} />;

export default BoldIcon;
