import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AppContext from '.';

const ContextProvider = ({ featureFlags, isOnline, children }) => {
  const [selectedAdminTab, setSelectedAdminTab] = useState('users');
  const context = {
    featureFlags,
    isOnline,
    selectedAdminTab,
    setSelectedAdminTab,
  };
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};

ContextProvider.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};
export default ContextProvider;
