import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.0278 8.4991C13.0278 8.89341 13.3475 9.21305 13.7418 9.21305C14.1361 9.21305 14.4557 8.89341 14.4557 8.4991C14.4557 8.1048 14.1361 7.78516 13.7418 7.78516C13.3475 7.78516 13.0278 8.1048 13.0278 8.4991Z"
      fill="#7876C1"
      stroke="#7876C1"
      strokeWidth="1.33"
      strokeLinejoin="round"
    />
    <path
      d="M7.79346 8.4991C7.79346 8.89341 8.1131 9.21305 8.5074 9.21305C8.90171 9.21305 9.22135 8.89341 9.22135 8.4991C9.22135 8.1048 8.90171 7.78516 8.5074 7.78516C8.1131 7.78516 7.79346 8.1048 7.79346 8.4991Z"
      fill="#7876C1"
      stroke="#7876C1"
      strokeWidth="1.33"
      strokeLinejoin="round"
    />
    <path
      d="M2.55127 8.4991C2.55127 8.89341 2.87093 9.21305 3.26521 9.21305C3.65951 9.21305 3.97916 8.89341 3.97916 8.4991C3.97916 8.1048 3.65951 7.78516 3.26521 7.78516C2.87093 7.78516 2.55127 8.1048 2.55127 8.4991Z"
      fill="#7876C1"
      stroke="#7876C1"
      strokeWidth="1.33"
      strokeLinejoin="round"
    />
  </svg>
);

const MoreIcon = (props) => <Icon component={svg} {...props} />;

export default MoreIcon;
