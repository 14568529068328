import { useCallback, useRef } from 'react';
import { useMutation } from '@apollo/client';
import html2canvas from 'html2canvas';

import { UPLOAD_AND_ATTACH_THUMBNAIL } from 'graphql/mutations/contentLibraryMutations';
import Uploader from 'components/Uploader';

const ThumbnailCreator = ({ title, elementId, type }) => {
  const ref = useRef(null);
  const [upload] = Uploader();
  const [uploadThumbnail] = useMutation(UPLOAD_AND_ATTACH_THUMBNAIL, {
    onError: (error) => {
      console.log(error, 'Failed to upload thumbnail');
    },
  });

  const setRef = useCallback(
    async (node) => {
      if (typeof node === 'object' && node) {
        const timer = setTimeout(async () => {
          if (document.getElementById(`${elementId}`))
            await html2canvas(document.getElementById(`${elementId}`), {
              proxy: `${process.env.REACT_APP_API_URL}/proxy`,
            })
              .then((canvas) => {
                canvas.toBlob(async (file) => {
                  if (file) {
                    file.lastModifiedDate = new Date();
                    file.name = `${title.toLowerCase().replace(/[^\w-.]+/g, '_')}.png`;

                    const { url } = await upload(
                      file,
                      `thumbnails/${type}/${elementId}/${file.name}`
                    );

                    uploadThumbnail({
                      variables: {
                        thumbnailUrl: url,
                        id: elementId,
                        type,
                      },
                    });
                  }
                });
              })
              .catch((error) => {
                console.log(error, 'Failed to convert to image');
              });
        }, 10000);

        return () => clearTimeout(timer);
      }

      // Save a reference to the node
      ref.current = node;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [elementId, type, title]
  );

  return [setRef];
};

export default ThumbnailCreator;
