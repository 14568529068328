import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropType from 'prop-types';

import { Dropdown, Menu, Row, Table } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import CircleTickIcon from 'components/Icons/CircleTickIcon';

const TransferTable = ({ affiliatePayments }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [selectedKey, setSelectedKey] = useState('all');
  const [filteredPayments, setFilteredPayments] = useState([]);

  useEffect(() => {
    setFilteredPayments(
      affiliatePayments
        .sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
        .map((payment, index) => ({
          key: index + 1,
          status: payment.paided ? 'paid' : 'pending',
          requestedOn: moment(payment.createdAt).format('DD/MM/YYYY'),
          paidOn: payment.payedAt ? moment(payment.payedAt).format('DD/MM/YYYY') : '-',
          commission: payment.requestedCredits,
        }))
    );
  }, [affiliatePayments]);

  const handleMenuClick = (el) => {
    setSelectedKey(el.key);
    setMenuVisible(!menuVisible);
    let toDate = moment().endOf('day');
    let fromDate = moment().startOf('day');

    switch (el.key) {
      case 'today':
        toDate = moment().endOf('day');
        fromDate = moment().startOf('day');
        break;
      case 'week':
        toDate = moment().endOf('day');
        fromDate = moment().subtract(moment().weekday(), 'days');
        break;
      case '7':
        toDate = moment().endOf('day');
        fromDate = moment().subtract('7', 'days');
        break;
      case 'month':
        toDate = moment().endOf('day');
        fromDate = moment().subtract(moment().date(), 'days');
        break;
      case '30':
        toDate = moment().endOf('day');
        fromDate = moment().subtract('30', 'days');
        break;
      case 'all':
        toDate = moment().endOf('day');
        fromDate = moment().subtract('60', 'years');
        break;
      default:
        toDate = moment().endOf('day');
        fromDate = moment().subtract('60', 'years');
        break;
    }

    const affiliatePaymentsRender = affiliatePayments
      .filter((payment) => moment(payment.createdAt).isBetween(fromDate, toDate))
      .sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      })
      .map((payment, index) => ({
        key: index + 1,
        status: payment.paided ? 'Paid' : 'Pending',
        requestedOn: moment(payment.createdAt).format('DD/MM/YYYY'),
        paidOn: payment.payedAt ? moment(payment.payedAt).format('DD/MM/YYYY') : '-',
        commission: payment.requestedCredits,
      }));

    setFilteredPayments(affiliatePaymentsRender);
  };

  const onClickDropDown = () => {
    setMenuVisible(!menuVisible);
  };

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        return text === 'paid' ? (
          <span className="transfer-status">
            Paid <CircleTickIcon />
          </span>
        ) : (
          'Unpaid'
        );
      },
    },
    {
      title: 'Requested On',
      dataIndex: 'requestedOn',
      key: 'requestedOn',
    },
    {
      title: 'Paid On',
      dataIndex: 'paidOn',
      key: 'paidOn',
    },
    {
      title: 'Commission',
      dataIndex: 'commission',
      key: 'commission',
      render: (text) => `$${text}`,
    },
  ];

  const dateDropdown = [
    {
      key: 'all',
      label: 'All time',
    },
    {
      key: 'today',
      label: moment().format('DD/MM/YYYY'),
    },
    {
      key: 'week',
      label: 'This week',
    },
    {
      key: '7',
      label: 'Last 7 days',
    },
    {
      key: 'month',
      label: 'This month',
    },
    {
      key: '30',
      label: 'Last 30 days',
    },
  ];

  const menu = () => {
    return (
      <Menu onClick={handleMenuClick}>
        {dateDropdown.map((el) => (
          <Menu.Item key={el.key}>{el.label}</Menu.Item>
        ))}
      </Menu>
    );
  };

  return (
    <div className="affiliate-main-content table-block">
      <Row className="heading" align="middle" justify="space-between">
        <h3>Payments transferred</h3>
        <Dropdown className="menu-icon" overlay={menu} trigger="click">
          <Row align="middle">
            <a aria-hidden="true" onClick={onClickDropDown}>
              <span className="user-details">
                <span className="user-name">
                  {dateDropdown.find((el) => el.key === selectedKey).label}
                </span>
                {menuVisible ? <CaretUpOutlined /> : <CaretDownOutlined />}
              </span>
            </a>
          </Row>
        </Dropdown>
      </Row>

      <Table
        className="affiliate-table"
        rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
        pagination={false}
        columns={columns}
        dataSource={filteredPayments}
      />
    </div>
  );
};

TransferTable.defaultProps = {
  affiliatePayments: [],
};

TransferTable.propTypes = {
  affiliatePayments: PropType.arrayOf(PropType.any),
};

export default TransferTable;
