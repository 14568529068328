import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
    <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.75939 4.81631C5.15625 4.37889 5.84375 4.37889 6.24061 4.81631L8.74626 7.57807C9.32927 8.22067 8.87331 9.25 8.00564 9.25H2.99436C2.12669 9.25 1.67073 8.22067 2.25375 7.57807L4.75939 4.81631Z" fill="#F03F3B"/>
    </svg>

);

const DropupIcon = (props) => <Icon component={svg} {...props} />;

export default DropupIcon;
