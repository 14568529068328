/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Divider } from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Tablist } from '../../../../modules';
import helpers from 'helpers/proposal';
import Popconfirm from 'components/PopConfirm';
import Button from 'components/Button';
import ResetIcon from 'components/Icons/ResetIcon';
import SavedIcon from 'components/Icons/SavedIcon';
import CloseIcon from 'components/Icons/CloseIcon';

const MilesEditModal = ({
  saveProposal,
  prop,
  user,
  saveUser,
  config,
  timeEdit,
  visible,
  onCancel,
  onConfirm,
  removeItem,
  savingRichEditorModal,
}) => {
  const [miles, setMiles] = useState([]);
  const [milesRegex, setMilesRegex] = useState('');
  const [chosenMiles, setChosenMiles] = useState('');
  const [tempProp, setTempProp] = useState([]);

  const [showPropModal, setShowPropModal] = useState(visible);
  const [showPromptModal, setShowPromptModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [showClearModal, setShowClearModal] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [action, setAction] = useState(null);

  const handleKeyCommand = (e) => {
    if (e.key === 'Escape') {
      setShowModal(true);
      const isNotCombinedKey = !(e.ctrlKey || e.altKey || e.shiftKey);
      if (isNotCombinedKey) {
        makeEscapeModal(true);
      }
    }
  };

  const makeEscapeModal = (showModal) => {
    setShowPromptModal(showModal);
    setShowModal(showModal);
  };

  useEffect(() => {
    let tempMiles = config.milestones ? _.compact(config.milestones.split('\n')) : [];
    tempMiles = tempMiles.map((mile) => {
      return { name: mile, editable: false };
    });

    const simpleclone = (x, doParse) => {
      if (x && doParse) return JSON.parse(JSON.stringify(x));
      else return x;
    };

    let tempprop = {};
    try {
      tempprop = { milestones: simpleclone(prop.milestones, true) };
    } catch (error) {
      tempprop = { milestones: simpleclone(prop.milestones) };
    }

    try {
      if (timeEdit.v && timeEdit.v !== -43) {
        tempprop = JSON.parse(timeEdit.v);
      }
    } catch (exx) {
      console.log(exx);
    }

    const tempChosenMiles = tempprop.milestones
      ? tempprop.milestones.chosen
      : helpers.getDefaultOptions({ prelist: tempMiles, config, prop });

    setTempProp(tempprop);
    setChosenMiles(tempChosenMiles);

    if (!showPromptModal) {
      window.addEventListener('keydown', handleKeyCommand, true);
    } else {
      window.removeEventListener('keydown', handleKeyCommand, true);
    }
    setShowPromptModal(!showPromptModal);
  }, []);

  useEffect(() => {
    const usersMiles = user?.config?.miles || '';

    let tempMiles = config.milestones ? _.compact(config.milestones.split('\n')) : [];
    tempMiles = tempMiles.map((mile) => {
      return { name: mile, editable: false };
    });

    if (usersMiles) {
      tempMiles = _.union(tempMiles, usersMiles || []);
    }

    if (milesRegex) {
      const searchRegex = new RegExp(milesRegex.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
      tempMiles = tempMiles.filter((mile) => searchRegex.test(mile.name));
    }

    setMiles(tempMiles);
  }, [prop, milesRegex, user?.config?.miles]);

  const resetChosen = () => {
    const chosen = {
      'OPTION 1': { total: '', list: [] },
    };
    setShowClearModal(false);

    setTempProp({ milestones: { chosen } });
    setChosenMiles(chosen);

    // will pass the action to tablist to empty the chosen list array
    setAction('reset-miles-choosen');
  };

  useEffect(() => {
    if (tempProp?.milestones) {
      shouldDisabled(tempProp?.milestones?.chosen || null);
    }
  }, [tempProp]);

  const shouldDisabled = (chosen) => {
    if (Object.keys(chosen)?.length > 0) {
      const [fkey] = Object.keys(chosen);
      if (chosen[fkey]?.list.length > 0) {
        return setDisableSaveButton(false);
      }
    }

    setDisableSaveButton(true);
  };

  const showClearButton = () => {
    setShowClearModal(true);
  };

  return (
    <>
      <Modal
        visible={showPropModal}
        wrapClassName={`rich-editor-price-edit rich-editor-price-edit-${prop?.language?.toLowerCase()}`}
        title={
          <div className="rich-editor-price-edit-header">
            <h3 className="title">Milestones</h3>
            <Divider />
          </div>
        }
        okText="OK"
        cancelText="CLOSE"
        onCancel={() => setShowModal(true)}
        cancelButtonProps={{ disabled: savingRichEditorModal }}
        maskClosable={false}
        closable={!savingRichEditorModal}
        onOk={() => onConfirm(tempProp)}
        closeIcon={<CloseIcon />}
        footer={[
          <Col className="rich-editor-modal-footer" key="rich-editor-modal-footer">
            <Button
              size="large"
              className="rich-editor-modal-reset"
              text={<span className="only-desktop">CLEAR</span>}
              icon={<ResetIcon />}
              onClick={showClearButton}
            />

            <Row>
              <Button
                type="default"
                size="large"
                className="rich-editor-modal-close"
                text="CLOSE"
                onClick={!savingRichEditorModal ? onCancel : () => {}}
              />

              <Button
                size="large"
                className="rich-editor-modal-continue"
                text={savingRichEditorModal ? 'SAVING' : 'SAVE'}
                onClick={() => onConfirm(tempProp)}
                disabled={disableSaveButton || savingRichEditorModal}
                loading={savingRichEditorModal}
              />
            </Row>
          </Col>,
        ]}>
        <Tablist
          action={action}
          tabType="miles"
          tabs={false}
          config={config}
          prop={tempProp}
          save={saveProposal}
          saveUser={saveUser}
          prelist={miles}
          handleState={() => {}}
          removeItem={removeItem}
          propsChosen={chosenMiles ? _.merge({}, chosenMiles) : chosenMiles}
          setMilesRegex={setMilesRegex}
          onChange={(chosen, orderSave) => {
            let tempprop = tempProp;
            if (!tempprop.milestones || !_.isEqual(tempprop.milestones.chosen, chosen)) {
              let milestones = { milestones: { chosen: chosen } };
              Object.assign(tempprop, milestones);
            } else if (orderSave) {
              tempprop.milestones.chosen = chosen;
            }
            // reset the action
            if (action === 'reset-miles-choosen') {
              setAction('');
            }
            setTempProp(tempprop);
            setChosenMiles(chosen);
            // disabled save button
            shouldDisabled(chosen);
          }}
        />
      </Modal>
      <>
        {showModal && (
          <Popconfirm
            visible={showModal}
            title={'Do you want to exit without saving?'}
            maskClosable={!savingRichEditorModal}
            closable={false}
            cancelText={'Cancel'}
            confirmText={savingRichEditorModal ? 'Saving...' : 'Save Changes'}
            onCancel={() => {
              setShowModal(false);
              setShowPropModal(true);
              onCancel();
            }}
            onConfirm={() => onConfirm(tempProp)}
            footer={null}
          />
        )}
        {showClearModal && (
          <Popconfirm
            visible={showClearModal}
            title={'Do you want to clear everything?'}
            maskClosable={!savingRichEditorModal}
            closable={false}
            cancelText={'Cancel'}
            confirmText={'Clear'}
            onCancel={() => {
              setShowClearModal(false);
              setShowPropModal(true);
            }}
            onConfirm={resetChosen}
            footer={null}
          />
        )}
      </>
    </>
  );
};

MilesEditModal.defaultProps = {
  savingRichEditorModal: false,
};

MilesEditModal.propTypes = {
  prop: PropTypes.instanceOf(Object).isRequired,
  config: PropTypes.instanceOf(Object).isRequired,
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  timeEdit: PropTypes.instanceOf(Object).isRequired,
  removeItem: PropTypes.func.isRequired,
  saveProposal: PropTypes.func.isRequired,
  saveUser: PropTypes.func.isRequired,
  savingRichEditorModal: PropTypes.bool,
};

export default MilesEditModal;
