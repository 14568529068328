import { useCallback } from 'react';
import _ from 'lodash';

// Hook to update draft and avoid rerendering when focus changes
export default function updateDraftHook(saveProposalDraft, setDisablePreview) {

  // eslint-disable-next-line react-hooks/exhaustive-deps, react-hooks/rules-of-hooks
  const saveDraftDebouce = useCallback(_.debounce((saveProposalDraftCurring, es, saveDraftSuccess) => saveProposalDraftCurring(
    es,
    (response) => {
      saveDraftSuccess?.(response);
      setDisablePreview(false);
    }
  ), 1000), []);

  const saveDraft = (name, raw) => {
    const saveProposalDraftCurring = saveProposalDraft(name, raw);
    return (es, saveDraftSuccess = () => { }) => {
      setDisablePreview(true);
      saveDraftDebouce(saveProposalDraftCurring, es, saveDraftSuccess);
    };
  };

  return saveDraft;
};