import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 0C10.5523 0 11 0.447715 11 1V2H10V1H1V14H10V13H11V14C11 14.5523 10.5523 15 10 15H1C0.447715 15 0 14.5523 0 14V1C0 0.447715 0.447715 0 1 0H10ZM11.7843 4.08859L11.8536 4.14645L14.8394 7.1323C15.0199 7.31281 15.04 7.59302 14.8996 7.79568L14.8394 7.8677L11.8536 10.8536C11.6583 11.0488 11.3417 11.0488 11.1464 10.8536C10.9729 10.68 10.9536 10.4106 11.0886 10.2157L11.1464 10.1464L13.293 8H5V7H13.293L11.1464 4.85355C10.9729 4.67999 10.9536 4.41056 11.0886 4.21569L11.1464 4.14645C11.32 3.97288 11.5894 3.9536 11.7843 4.08859Z"
      fill="#000624"
    />
  </svg>
);

const WixSaveCloseIcon = (props) => <Icon component={svg} {...props} />;

export default WixSaveCloseIcon;
