import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import Quote2Icon from 'components/Icons/Quote2Icon';
import './Testimonial.scss';
import utils from 'utils/utils';
import { testimonial } from 'constants/index';
import { defaultBodySettings, defaultHebrewBodySettings } from 'pages/Proposal/constants/constants';
import { loadProposalFont } from 'helpers/proposal';

// ----------------------------

const BACKGROUND = testimonial.background;
const COLOR = testimonial.color;

const TestimonialPublish = ({
  bodyFont,
  testimonials,
  config,
  language,
  fonts,
  setFonts,
  getFontDetail,
}) => {
  const textColor = useMemo(
    () => utils.getContrastColor(config?.backgroundColor || BACKGROUND, '#ffffff', COLOR),
    [config]
  );

  const bodySettings =
    bodyFont ||
    (language?.toLowerCase() === 'hebrew' || language?.toLowerCase() === 'arabic'
      ? defaultHebrewBodySettings
      : defaultBodySettings);

  if (config?.fontFamily && !fonts[config?.fontFamily]) {
    const testimonialFont = getFontDetail(config?.fontFamily);
    if (testimonialFont) {
      loadProposalFont([testimonialFont], language);
    }
    setFonts((prev) => ({ ...prev, [config?.fontFamily]: true }));
  }

  const color = config?.color || textColor || COLOR;

  return (
    <figure
      className={`testimonial-wrapper 
      ${textColor === '#ffffff' ? 'white-text' : ''} 
      ${config?.horizontal ? 'horizontal' : 'vertical'}
      ${parseInt(config?.fontSize || bodySettings.fontSize) > 36 ? 'large-font' : ''}
      `}
      style={{
        backgroundColor: config?.backgroundColor || BACKGROUND,
        color,
        fontFamily: config?.fontFamily || bodySettings.family,
        fontSize: config?.fontSize || bodySettings.fontSize,
      }}>
      {testimonials.slice(0, config?.count || 3).map(({ avatar, name, text }, i) =>
        !avatar && !name && !text ? null : (
          <div key={i} className="testimonial-item">
            <Quote2Icon className="quote-icon" />

            <div className="testimonial-content pdf-avoid-break">
              <div className="avatar-wrapper">
                <div
                  className="avatar"
                  style={
                    avatar
                      ? {
                          backgroundImage: `url(${avatar})`,
                          backgroundSize: 'cover',
                        }
                      : {}
                  }
                />
              </div>
              <div className="text-wrapper">
                <div className="name">
                  <HtmlText text={name} />
                </div>
                <div className="text">
                  <HtmlText text={text} />
                </div>
              </div>
            </div>
            <hr style={{ borderColor: color }} />
          </div>
        )
      )}
    </figure>
  );
};

export default TestimonialPublish;

TestimonialPublish.propTypes = {
  testimonials: PropTypes.array,
  config: PropTypes.object,
  language: PropTypes.string,
  fonts: PropTypes.object,
  setFonts: PropTypes.func,
  getFontDetail: PropTypes.func,
};

function HtmlText({ text }) {
  // display text as html
  text = text.replace(/</g, '&lt;').replace(/\n/g, '<br>');
  return <div dangerouslySetInnerHTML={{ __html: text }} />;
}
