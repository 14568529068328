import React from 'react';
import PropTypes from 'prop-types';

import PublishedProposalModal from './PublishedProposalModal/PublishedProposalModal';
import UnPublishedProposalModal from './UnPublishedProposalModal/UnPublishedProposalModal';
import ClientPaymentModal from './ClientPaymentModal/ClientPaymentModal';
import PaymentStatusModal from './PaymentStatusModal/PaymentStatusModal';
import DownloadingPDFModal from '../../../Modals/DownloadingPDFModal';
import DownloadPdfErrorModal from '../../../Modals/DownloadPdfErrorModal';
import { IsEditingModal } from 'pages/Proposal/components/Draft/DraftModals';

const PublishModals = ({
  proposalPublished,
  proposalUnPublished,
  makePayment,
  paymentStatus,
  downloadPDFError,
  setProposalUnPublished,
  setProposalPublished,
  setMakePayment,
  setPaymentStatus,
  setDownloadPDFError,
  configText,
  copyPropsalLink,
  publishedProposalLink,
  downloadPdf,
  clientMakesPayment,
  proposal,
  paymentError,
  setPaymentError,
  makingPayment,
  downloadingPDF,
  history,
  isEditingModal,
}) => {
  return (
    <>
      {proposalPublished && (
        <PublishedProposalModal
          copyPropsalLink={copyPropsalLink}
          publishedProposalLink={publishedProposalLink}
          onCancel={() => setProposalPublished(false)}
          configText={configText}
          downloadPdf={downloadPdf}
          proposal={proposal}
        />
      )}
      {proposalUnPublished && (
        <UnPublishedProposalModal
          onCancel={() => setProposalUnPublished(false)}
          configText={configText}
        />
      )}
      {makePayment && (
        <ClientPaymentModal
          makingPayment={makingPayment}
          clientPayableLabel={proposal?.clientPayableLabel || 'Make Payment'}
          amount={`${proposal?.clientPayableAmount} ${
            proposal?.clientPayableCurrency
              ? proposal?.clientPayableCurrency?.toUpperCase()
              : proposal?.stripe?.currency
              ? proposal?.stripe?.currency?.toUpperCase()
              : 'USD'
          }`}
          onConfirm={clientMakesPayment}
          onCancel={() => {
            setMakePayment(false);
            console.log('looger 67');
            setPaymentError('');
          }}
        />
      )}
      {paymentStatus && (
        <PaymentStatusModal
          paymentStatus={paymentStatus}
          onCancel={() => {
            setPaymentStatus('');
            history.push(`/cd/${proposal._id}`);
          }}
        />
      )}
      {downloadingPDF && <DownloadingPDFModal />}
      {downloadPDFError && (
        <DownloadPdfErrorModal
          downloadPDFError={downloadPDFError}
          onCancel={() => setDownloadPDFError('')}
        />
      )}

      {isEditingModal && proposal.state !== 'approved' && proposal.state !== 'declined' && (
        <IsEditingModal isEditingModal={isEditingModal} isClient={true} />
      )}
    </>
  );
};

PublishModals.defaultProps = {
  proposal: '',
  makingPayment: false,
  paymentError: '',
  proposalPublished: false,
  proposalUnPublished: false,
  makePayment: false,
  paymentStatus: false,
  downloadingPDF: false,
  downloadPDFError: '',
  setProposalUnPublished: () => {},
  setProposalPublished: () => {},
  setMakePayment: () => {},
  setPaymentStatus: () => {},
  setDownloadPDFError: () => {},
  configText: () => {},
  publishedProposalLink: () => {},
  copyPropsalLink: () => {},
  downloadPdf: () => {},
  clientMakesPayment: () => {},
};

PublishModals.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  proposal: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  paymentError: PropTypes.string,
  makingPayment: PropTypes.bool,
  proposalPublished: PropTypes.bool,
  proposalUnPublished: PropTypes.bool,
  makePayment: PropTypes.bool,
  downloadingPDF: PropTypes.bool,
  paymentStatus: PropTypes.string,
  downloadPDFError: PropTypes.string,
  setProposalUnPublished: PropTypes.func,
  setProposalPublished: PropTypes.func,
  setMakePayment: PropTypes.func,
  setPaymentStatus: PropTypes.func,
  setDownloadPDFError: PropTypes.func,
  publishedProposalLink: PropTypes.func,
  configText: PropTypes.func,
  copyPropsalLink: PropTypes.func,
  downloadPdf: PropTypes.func,
  clientMakesPayment: PropTypes.func,
};

export default PublishModals;
