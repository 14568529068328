import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="32" height="17" viewBox="-4 0 40 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.82598 1.81165L1.63312 7.8914C1.23387 8.28336 1.23387 8.92662 1.63312 9.31859L7.82598 15.3983"
      stroke="#C7BCE4"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M24.0803 15.3983L30.2731 9.31856C30.6724 8.9266 30.6724 8.28334 30.2731 7.89138L24.0803 1.81162"
      stroke="#C7BCE4"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <rect
      width="2.30415"
      height="14.8942"
      rx="1.15207"
      transform="matrix(0.914797 0.403915 -0.441801 0.897113 17.8555 0.931885)"
      fill="#7876C1"
    />
  </svg>
);

const HTMLIcon = (props) => <Icon component={svg} {...props} />;

export default HTMLIcon;
