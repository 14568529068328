import { gql } from '@apollo/client';

export const GET_EMAIL_SETTINGS = gql`
  query getEmailSettings {
    fetchEmailSettings {
      _id
      generalSettings {
        companyLogo
        buttonColor
        buttonTextColor
        replyTo
        senderName
        font
      }
      publishedProposalToProspect {
        subjectLine {
          value
          RTL
        }
        previewText {
          value
          RTL
        }
        emailBody {
          value
          RTL
        }
        reminder
        sent
        clicked
        open
        buttonText {
          value
          RTL
        }
      }
      reminderToProspect {
        subjectLine {
          value
          RTL
        }
        previewText {
          value
          RTL
        }
        emailBody {
          value
          RTL
        }
        reminder
        sent
        clicked
        open
        buttonText {
          value
          RTL
        }
      }
      secondReminderToProspect {
        subjectLine {
          value
          RTL
        }
        previewText {
          value
          RTL
        }
        emailBody {
          value
          RTL
        }
        reminder
        sent
        clicked
        open
        buttonText {
          value
          RTL
        }
      }
      signedPDFToProspect {
        subjectLine {
          value
          RTL
        }
        previewText {
          value
          RTL
        }
        emailBody {
          value
          RTL
        }
        reminder
        sent
        clicked
        open
        buttonText {
          value
          RTL
        }
      }
    }
  }
`;
