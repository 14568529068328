const blockStyler = (block) => {
  const data = block.getData();

  if (data && data.get('className')) {
    return `RichEditor-${data.get('className')}`;
  }

  if (data && data.get('class')) {
    return data.get('class');
  }

  if (block.getType() === 'unstyled') {
    return 'paragraph';
  }

  if (block.getType().match(/editor-align-/) || block.getType().match(/editor-direction-/)) {
    return block.getType();
  }

  return `RichEditor-${block.getType()}`;
};

export default blockStyler;
