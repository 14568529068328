/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.4615 6H7.53846C6.68879 6 6 6.68879 6 7.53846V9.27447C6 9.73389 6.20533 10.1693 6.55981 10.4615L12.2695 15.1687C12.6239 15.461 12.8293 15.8964 12.8293 16.3558V23.3831C12.8293 24.5566 14.0905 25.2981 15.1159 24.7273L18.3804 22.9104C18.8683 22.6389 19.1707 22.1244 19.1707 21.5661V15.9255C19.1707 15.4432 19.3969 14.9888 19.7818 14.698L25.3889 10.4617C25.7738 10.1709 26 9.71649 26 9.23416V7.53846C26 6.68879 25.3112 6 24.4615 6Z"
      fill="#5D5AB5"
    />
    <path d="M12.8406 16.5H19.1623V20H12.8406V16.5Z" fill="#C7BCE4" />
  </svg>
);

const FilterIcon = (props) => <Icon component={svg} {...props} />;

export default FilterIcon;
