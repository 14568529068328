import React, { useState, useEffect } from 'react';
import { Button, Table, Input, message, Switch, DatePicker, Dropdown, Menu, Tooltip } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import * as _ from 'lodash';

import { RedirectIcon, FilterIcon } from 'components/Icons';
import { FETCH_WHITELISTED_LINKS } from 'graphql/queries/whitelistedLinkQueries';
import { UPDATE_WHITELISTED_LINK } from 'graphql/mutations/whitelistedLinkMutations';
import utils from 'utils/utils';
import SpamLinksProposals from './SpamLinksProposals';

import './SpamLinks.scss';

const { RangePicker } = DatePicker;

const SpamLinks = () => {
  const endDate = moment().toISOString();
  const initialFilters = {
    search: '',
    blocked: 'all',
    startDate: moment(endDate).subtract(1, 'month'),
    endDate: moment(endDate),
  };

  const [links, setLinks] = useState([]);

  const [search, setSearch] = useState(initialFilters.search);
  const [blocked, setBlocked] = useState(initialFilters.blocked);
  const [selectedDate, setSelectedDate] = useState([
    initialFilters.startDate,
    initialFilters.endDate,
  ]);

  const [linksCount, setLinksCount] = useState(0);
  const [limit, setLimit] = useState(30);
  const [offset, setOffset] = useState(0);
  const [modal, setModal] = useState(null);

  const getBlocked = (blocked) => {
    if (blocked === 'blocked') {
      return { blocked: true };
    }
    if (blocked === 'whitelisted') {
      return { blocked: false };
    }
    return { blocked: null };
  };

  const getSelectedDate = (selectedDate) => {
    if (selectedDate.length === 2) {
      return [selectedDate[0].toISOString(), selectedDate[1].toISOString()];
    }
    return [initialFilters.startDate.toISOString(), initialFilters.endDate.toISOString()];
  };

  const { loading: isFetchingLinks, refetch } = useQuery(FETCH_WHITELISTED_LINKS, {
    variables: {
      search: search,
      ...getBlocked(blocked),
      selectedDate: getSelectedDate(selectedDate),
      limit: limit,
      offset: offset,
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ fetchWhitelistedLinks }) => {
      parseAndSetData(fetchWhitelistedLinks);
    },
  });

  const refetchLinks = async (options) => {
    let variables = {
      search: search,
      ...getBlocked(blocked),
      selectedDate: getSelectedDate(selectedDate),
      limit: limit,
      offset: offset,
    };

    if (options) {
      variables = {
        search: options.search || search,
        ...getBlocked(options.blocked ?? blocked),
        selectedDate: getSelectedDate(options.selectedDate || selectedDate),
        limit: options.limit || limit,
        offset: options.offset || offset,
      };
    }

    const { data } = await refetch({ variables });
    parseAndSetData(data?.fetchWhitelistedLinks || {});
  };

  const parseAndSetData = (data) => {
    const _links = data?.links.map((l) => ({
      _id: l._id,
      // userId: l.userId,
      // proposalId: l.proposalId,
      url: l.url,
      blocked: l.blocked,
      createdAt: l.createdAt,
      updatedAt: l.updatedAt,
    }));
    setLinks([..._links]);
    setLinksCount(data?.count);
  };

  const [updateWhitelistedLink] = useMutation(UPDATE_WHITELISTED_LINK, {
    //onCompleted: () => message.success('Job mail added to queue successfully'),
    onError: () => message.error(`Failed to update`),
  });

  const debounceRefetch = _.debounce((options) => {
    refetchLinks({ ...options });
  }, 2000);

  useEffect(() => {
    refetchLinks({
      search,
      blocked,
      selectedDate,
      limit,
      offset,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocked, selectedDate, limit, offset]);

  useEffect(() => {
    debounceRefetch({
      search,
      blocked,
      selectedDate,
      limit,
      offset,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleReset = () => {
    setSelectedDate([initialFilters.startDate, initialFilters.endDate]);
    setSearch(initialFilters.search);
    setBlocked(initialFilters.blocked);
  };

  const onChange = (id, key, value) => {
    setLinks((prev) => {
      const newData = [...prev];
      const index = newData.findIndex((d) => d._id === id);

      if (index > -1) {
        newData[index][key] = value;
      }

      updateWhitelistedLink({
        variables: {
          _id: id,
          [key]: value,
        },
      });

      return newData;
    });
  };

  const linksColumn = [
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (data) => <>{moment(data).format('YYYY-MM-DD')}</>,
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
      render: (url) => (
        <a href={url} target="_blank" rel="noreferrer">
          {utils.cutLongText(url)} &nbsp;
          <RedirectIcon />
        </a>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'blocked',
      key: 'blocked',
      render: (blocked, data) => (
        <>
          <Switch
            checkedChildren="Whitelisted"
            unCheckedChildren="Blocked"
            checked={!blocked}
            onChange={() => onChange(data._id, 'blocked', !blocked)}
          />
        </>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (data) => (
        <Button onClick={() => setModal({ spamUrl: data.url })}>View proposals</Button>
      ),
    },
  ];

  const menu = (
    <Menu
      onClick={({ key }) => setBlocked(key)}
      defaultSelectedKeys={[initialFilters.blocked]}
      selectedKeys={blocked}>
      <Menu.Item key="all">All</Menu.Item>
      <Menu.Item key="blocked">Blocked</Menu.Item>
      <Menu.Item key="whitelisted">White listed</Menu.Item>
    </Menu>
  );

  return (
    <div className="admin-wrapper">
      <div className="admin-table-wrapper">
        <div className="table-action">
          <h3>Spam links</h3>
          <div className="actions">
            <RangePicker
              value={[...selectedDate]}
              onChange={(values) => {
                if (values.length === 2) {
                  setSelectedDate([...values]);
                }
              }}
            />

            <Input
              placeholder="Search with url..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              // onSearch={() => refetch()}
              className="search-input"
            />

            <Dropdown overlay={menu}>
              <Tooltip title="Status Filter">
                <FilterIcon />
              </Tooltip>
            </Dropdown>

            <Button type="primary" onClick={handleReset}>
              Reset
            </Button>
          </div>
        </div>

        <Table
          loading={isFetchingLinks}
          dataSource={links}
          columns={linksColumn}
          rowKey={'_id'}
          pagination={{
            total: linksCount,
            defaultPageSize: limit,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30'],
            onChange: async (page, size) => {
              setLimit(size);
              setOffset(page - 1);
            },
          }}
        />
      </div>

      {modal?.spamUrl && (
        <SpamLinksProposals spamUrl={modal.spamUrl} onClose={() => setModal(null)} />
      )}
    </div>
  );
};

export default SpamLinks;
