import { useState } from 'react';
import { useMutation } from '@apollo/client';
import AWS from 'aws-sdk';
import imageCompression from 'browser-image-compression';

import { GENERATE_IDENTITY_TOKEN } from '../../graphql/mutations/uploadMutation';
import helpers from 'helpers';

let currentCredentials;

const Uploader = () => {
  const [status, setStatus] = useState({
    list: {},
    asArray: [],
    total: 0,
    loaded: 0,
    percent: 0,
    status: 'ready',
  });

  const [generateToken] = useMutation(GENERATE_IDENTITY_TOKEN);

  const _generateTokens = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        generateToken()
          .then(({ data: { generateIdentityToken } }) => {
            const params = {
              IdentityId: generateIdentityToken.IdentityId,
              Logins: {
                'cognito-identity.amazonaws.com': generateIdentityToken.Token,
              },
            };
            const cognitoidentity = new AWS.CognitoIdentity({
              region: process.env.REACT_APP_AWS_REGION,
            });
            cognitoidentity.getCredentialsForIdentity(params, (err, data) => {
              err ? reject(err) : resolve(data);
            });
          })
          .catch((error) => {
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  };

  const _isExpired = (date) => {
    if (!date) return true;
    return date.getTime() <= new Date().getTime();
  };

  const _getCredentials = async () => {
    if (
      !currentCredentials ||
      !currentCredentials.Credentials ||
      _isExpired(currentCredentials.Credentials.Expiration)
    ) {
      const result = await _generateTokens();
      currentCredentials = result;
    }

    return new AWS.Credentials(
      currentCredentials.Credentials.AccessKeyId,
      currentCredentials.Credentials.SecretKey,
      currentCredentials.Credentials.SessionToken
    );
  };

  const _getS3 = async () => {
    const credentials = await _getCredentials();
    const s3 = new AWS.S3({
      credentials,
      region: process.env.REACT_APP_AWS_REGION,
    });
    return s3;
  };

  const uploadFile = (file, s3, path, handleProgress, fileIndex) =>
    new Promise(async (resolve, reject) => {
      handleProgress(0, fileIndex);

      let compressedFile = file;
      let keyName = helpers.objectId();

      try {
        // dont compress gif file
        if (file.type !== 'image/gif') {
          compressedFile = await imageCompression(file, {
            useWebWorker: true,
          });
        }
      } catch (error) {
        console.log(`File can't be compressed`);
      }

      const params = {
        ACL: 'public-read',
        Key: `${path}/${keyName}.${compressedFile.name.split('.').pop() || 'jpg'}`,
        ContentType: compressedFile.type,
        Body: compressedFile,
        CacheControl: 'no-cache',
        Bucket: process.env.REACT_APP_AWS_BUCKET,
      };

      s3.upload(params)
        .on('httpUploadProgress', (progress) => {
          // that's how you can keep track of your upload progress
          let percentage = Math.round((progress.loaded / progress.total) * 100);
          handleProgress(percentage, fileIndex);
        })
        .on('success', (data) => {
          resolve({
            url: `${process.env.REACT_APP_CLOUD_FRONT_URL}${path}/${keyName}.${
              compressedFile.name.split('.').pop() || 'jpg'
            }`,
            filename: compressedFile.name,
            filetype: compressedFile.type || compressedFile.name.split('.').pop(),
          });
        })
        .send((error, data) => {
          if (error) {
            reject(error);
          } else if (data) {
            resolve({
              url: `${process.env.REACT_APP_CLOUD_FRONT_URL}${path}/${keyName}.${
                compressedFile.name.split('.').pop() || 'jpg'
              }`,
              filename: compressedFile.name,
              filetype: compressedFile.type || compressedFile.name.split('.').pop(),
            });
          }
        });
    });

  const upload = (files, path) => {
    if (status.status === 'uploading') return status;

    setStatus({
      percent: 0,
      status: 'uploading',
    });

    return new Promise(async (resolve, reject) => {
      try {
        const s3 = await _getS3();

        if (files.length) {
          const progresses = files.map((f) => 0);
          const progressHandler = (percentage, index) => {
            progresses[index] = percentage;
            let totalProgress = 0;

            progresses.forEach((prog) => {
              totalProgress += prog / progresses.length;
            });
            totalProgress = Math.round(totalProgress);
            setStatus({
              percent: totalProgress,
              status: 'uploading',
            });
          };

          const urls = await Promise.all(
            Object.values(files).map(
              async (file, index) => await uploadFile(file, s3, path, progressHandler, index)
            )
          );
          resolve(urls);
        } else {
          const progressHandler = (percentage) => {
            setStatus({
              percent: percentage,
              status: 'uploading',
            });
          };

          const url = await uploadFile(files, s3, path, progressHandler, 0);
          resolve(url);
        }

        setStatus({
          percent: 100,
          status: 'completed',
        });
      } catch (error) {
        const errorState = {
          error,
          status: 'error',
        };
        setStatus((prevState) => ({
          ...prevState,
          ...errorState,
        }));
        reject(error);
      }
    });
  };

  return [upload, status];
};

export default Uploader;
