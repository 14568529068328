import React, { useState } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';

import Input from '../Input';

const { Option } = Select;

const EditableSelect = ({
  x,
  label,
  items,
  handleOptionsDropdown,
  configText,
  selectClassName,
  placeholder,
  inputClassName,
  containerClass,
  dropdownClassName,
}) => {
  const [customValue, setCustomValue] = useState('');

  return (
    <div className={`select-container ${containerClass}`}>
      {label && <label>{configText(label) || label}</label>}
      <Select
        className={selectClassName}
        placeholder={configText(placeholder) || placeholder}
        value={x.type}
        onChange={(item) => {
          handleOptionsDropdown(customValue || item, x.id);
          setCustomValue('');
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleOptionsDropdown(e.target.value, x.id);
            setCustomValue('');
          }
        }}
        dropdownRender={(menu) => (
          <div className={dropdownClassName}>
            {menu}
            <Input
              containerclass={inputClassName}
              placeholder={configText('Custom Value') || 'Custom Value'}
            />
          </div>
        )}>
        {items.map((item) => (
          <Option key={item}>{configText(item) || item}</Option>
        ))}
      </Select>
    </div>
  );
};

EditableSelect.defaultProps = {
  selectClassName: '',
  inputClassName: '',
  placeholder: '',
  containerClass: '',
  label: '',
  dropdownClassName: '',
};

EditableSelect.propTypes = {
  x: PropTypes.instanceOf(Object).isRequired,
  items: PropTypes.instanceOf(Array).isRequired,
  handleOptionsDropdown: PropTypes.func.isRequired,
  configText: PropTypes.instanceOf(Object).isRequired,
  selectClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  containerClass: PropTypes.string,
  label: PropTypes.string,
  dropdownClassName: PropTypes.string,
};

export default EditableSelect;
