const uType = (u) => {
  const {
    paymentStatus: { payments },
  } = u;
  if (!payments || payments.length === 0) {
    return false;
  }
  let ut = null;
  payments?.forEach((p) => {
    if (p.trans.txn_type === 'web_accept' && p.trans.payment_status === 'Completed') {
      ut = ut || 'payg';
    }

    if (p.trans.txn_type === 'recurring_payment') {
      ut = 'sub';
    }

    if (
      p.trans.txn_type === 'recurring_payment_profile_created' &&
      p.trans.initial_payment_status === 'Completed' &&
      p.trans.initial_payment_txn_id &&
      (p.trans.initial_payment_amount === '1.00' || p.trans.initial_payment_amount === 1)
    ) {
      ut = 'sub';
    }
  });
  return ut;
};

const firstPayment = (u) => {
  const {
    paymentStatus: { payments },
  } = u;
  if (!payments || payments.length === 0) {
    return false;
  }
  let firstpay = null;
  payments.forEach((p) => {
    const ttime = new Date(p.time);
    if (p.trans.txn_type === 'web_accept' && p.trans.payment_status === 'Completed') {
      firstpay = firstpay === null || ttime < firstpay ? ttime : firstpay;
    }

    // trial
    if (
      p.trans.txn_type === 'recurring_payment_profile_created' &&
      p.trans.initial_payment_status === 'Completed' &&
      p.trans.initial_payment_txn_id &&
      p.trans.initial_payment_amount === '1.00'
    ) {
      firstpay = firstpay === null || ttime < firstpay ? ttime : firstpay;
    }

    if (p.trans.txn_type === 'recurring_payment') {
      firstpay = firstpay === null || ttime < firstpay ? ttime : firstpay;
    }
  });
  return firstpay;
};

const firstRealPayment = (u) => {
  const {
    paymentStatus: { payments },
  } = u;
  if (!payments || payments.length === 0) {
    return false;
  }
  let firstpay = null;
  payments.forEach((p) => {
    const ttime = new Date(p.time);
    if (p.trans.txn_type === 'web_accept' && p.trans.payment_status === 'Completed') {
      firstpay = firstpay === null || ttime < firstpay ? ttime : firstpay;
    }

    if (p.trans.txn_type === 'recurring_payment') {
      firstpay = firstpay === null || ttime < firstpay ? ttime : firstpay;
    }
  });
  return firstpay;
};

const trialPaytime = (u) => {
  const {
    paymentStatus: { payments },
  } = u;
  if (!payments || !payments.length) {
    return false;
  }
  let firstpay = null;
  payments.forEach((p) => {
    const ttime = new Date(p.time);

    // trial
    if (p.trans.txn_type === 'recurring_payment_profile_created') {
      if (
        p.trans.initial_payment_status === 'Completed' &&
        p.trans.initial_payment_txn_id &&
        p.trans.initial_payment_amount
      ) {
        firstpay = firstpay === null || ttime < firstpay ? ttime : firstpay;
      }
    }
  });
  return firstpay;
};

const everPayed = (u) => {
  return firstPayment(u);
};

const userPayments = (uid, context) => {
  let bought = null;

  if (!context.payments) {
    return [];
  }
  bought = [...context.payments];
  bought = bought.sort((a, b) => {
    return new Date(b.time) - new Date(a.time);
  });

  if (!bought) {
    return [];
  }

  bought = bought.sort((a, b) => {
    return new Date(b.time) - new Date(a.time);
  });

  return bought;
};

const canceledPlan = (uid, profileid, context) => {
  // out of create/cancel payments was last status canceled?
  const payments = userPayments(uid, context);
  let cancelTime = null;
  if (payments && payments.length > 0) {
    payments.forEach((p) => {
      if (
        p.trans &&
        ((p.trans.txn_type === 'recurr_cancel' && p.trans.data.PROFILEID === profileid) ||
          (p.trans.txn_type === 'recurring_payment_profile_cancel' &&
            p.trans.recurring_payment_id === profileid))
      ) {
        cancelTime = cancelTime || new Date(p.time);
      }
    });
  }

  return cancelTime;
};

const kipsHelper = {
  uType,
  firstPayment,
  firstRealPayment,
  trialPaytime,
  everPayed,
  canceledPlan,
};

export default kipsHelper;
