import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" stroke="#F5F6FA" />
    <path
      d="M16.4596 16.8V15.252H19.3516V24H17.6236V16.8H16.4596ZM22.4272 24.084C22.1232 24.084 21.8712 23.992 21.6712 23.808C21.4792 23.616 21.3832 23.38 21.3832 23.1C21.3832 22.82 21.4792 22.588 21.6712 22.404C21.8712 22.212 22.1232 22.116 22.4272 22.116C22.7232 22.116 22.9672 22.212 23.1592 22.404C23.3512 22.588 23.4472 22.82 23.4472 23.1C23.4472 23.38 23.3512 23.616 23.1592 23.808C22.9672 23.992 22.7232 24.084 22.4272 24.084Z"
      fill="#4F4E7B"
    />
  </svg>
);

const NumberedBlockIcon = (props) => <Icon component={svg} {...props} />;

export default NumberedBlockIcon;
