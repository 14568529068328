import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00001 15.1668C11.6819 15.1668 14.6667 12.1821 14.6667 8.50016C14.6667 4.81826 11.6819 1.8335 8.00001 1.8335C4.31811 1.8335 1.33334 4.81826 1.33334 8.50016C1.33334 12.1821 4.31811 15.1668 8.00001 15.1668Z"
      stroke="#4F4E7B"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33334 8.5L8.00001 11.1667L10.6667 8.5"
      stroke="#4F4E7B"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 5.8335V11.1668"
      stroke="#4F4E7B"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const DownloadCircleIcon = (props) => <Icon component={svg} {...props} />;

export default DownloadCircleIcon;
