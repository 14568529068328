/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_12221_21932)">
      <path
        d="M0.835938 10.1452C0.835938 9.22471 1.58213 8.47852 2.5026 8.47852H4.16927V13.4785H2.5026C1.58213 13.4785 0.835938 12.7323 0.835938 11.8118V10.1452Z"
        fill="#7876C1"
      />
      <path
        d="M19.1641 10.1452C19.1641 9.22471 18.4179 8.47852 17.4974 8.47852H15.8307V13.4785H17.4974C18.4179 13.4785 19.1641 12.7323 19.1641 11.8118V10.1452Z"
        fill="#7876C1"
      />
      <rect x="3.33594" y="5.97852" width="13.3333" height="11.6667" rx="3.33333" fill="#C7BCE4" />
      <circle cx="13.3333" cy="10.1439" r="0.833333" fill="#7876C1" />
      <circle cx="6.66927" cy="10.1439" r="0.833333" fill="#7876C1" />
      <path
        d="M7.91667 12.6445C7.68655 12.6445 7.49647 12.8323 7.53464 13.0593C7.56585 13.2448 7.61797 13.4266 7.6903 13.6012C7.81594 13.9046 8.00009 14.1802 8.23223 14.4123C8.46438 14.6444 8.73998 14.8286 9.04329 14.9542C9.34661 15.0799 9.6717 15.1445 10 15.1445C10.3283 15.1445 10.6534 15.0799 10.9567 14.9542C11.26 14.8286 11.5356 14.6444 11.7678 14.4123C11.9999 14.1802 12.1841 13.9046 12.3097 13.6012C12.382 13.4266 12.4341 13.2448 12.4654 13.0593C12.5035 12.8323 12.3135 12.6445 12.0833 12.6445L10 12.6445L7.91667 12.6445Z"
        fill="#7876C1"
      />
      <rect
        x="9.58594"
        y="6.81055"
        width="3.33333"
        height="0.833333"
        rx="0.416667"
        transform="rotate(-90 9.58594 6.81055)"
        fill="#7876C1"
      />
      <circle
        cx="9.9974"
        cy="2.64388"
        r="1.25"
        fill="#7876C1"
        stroke="#7876C1"
        strokeWidth="0.833333"
      />
    </g>
    <defs>
      <clipPath id="clip0_12221_21932">
        <rect width="20" height="20" fill="white" transform="translate(0 0.144531)" />
      </clipPath>
    </defs>
  </svg>
);

const AIBotIcon = (props) => <Icon component={svg} {...props} />;

export default AIBotIcon;
