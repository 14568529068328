import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 6.66732H3.33335C2.89133 6.66732 2.4674 6.84291 2.15484 7.15547C1.84228 7.46803 1.66669 7.89196 1.66669 8.33398V11.6673C1.66669 12.1093 1.84228 12.5333 2.15484 12.8458C2.4674 13.1584 2.89133 13.334 3.33335 13.334H4.16669V16.6673C4.16669 16.8883 4.25448 17.1003 4.41076 17.2566C4.56705 17.4129 4.77901 17.5007 5.00002 17.5007H6.66669C6.8877 17.5007 7.09966 17.4129 7.25594 17.2566C7.41222 17.1003 7.50002 16.8883 7.50002 16.6673V13.334H10L14.1667 16.6673V3.33398L10 6.66732Z"
      fill="#C7BCE4"
    />
    <path
      d="M15.8333 13.3327C17.1166 12.716 17.9166 11.4243 17.9166 9.99935C17.9166 8.58268 17.1083 7.29102 15.8333 6.66602V13.3327Z"
      fill="#7876C1"
    />
    <path
      d="M4.16669 13.334H7.50002V16.5007C7.50002 17.0529 7.0523 17.5007 6.50002 17.5007H5.16669C4.6144 17.5007 4.16669 17.0529 4.16669 16.5007V13.334Z"
      fill="#7876C1"
    />
  </svg>
);

const SpeakerIcon = (props) => <Icon component={svg} {...props} />;

export default SpeakerIcon;
