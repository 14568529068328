import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 5L13 7L11 9"
      stroke="#05034D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect width="13" height="2" rx="1" fill="#05034D" />
    <rect y="4" width="8" height="2" rx="1" fill="#05034D" />
    <rect y="12" width="13" height="2" rx="1" fill="#05034D" />
    <rect y="8" width="8" height="2" rx="1" fill="#05034D" />
  </svg>
);

const RightIntentIcon = (props) => <Icon component={svg} {...props} />;

export default RightIntentIcon;
