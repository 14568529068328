import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="48" height="48" viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M4 6C4 3.79086 5.79086 2 8 2H40C42.2091 2 44 3.79086 44 6V38C44 40.2091 42.2091 42 40 42H8C5.79086 42 4 40.2091 4 38V6Z"
        fill="white"
      />
      <path
        d="M4 6C4 3.79086 5.79086 2 8 2H40C42.2091 2 44 3.79086 44 6V38C44 40.2091 42.2091 42 40 42H8C5.79086 42 4 40.2091 4 38V6Z"
        fill="url(#paint0_linear)"
      />
      <rect x="10" y="12" width="28" height="20" rx="2" stroke="#C7BCE4" strokeWidth="2" />
      <path
        d="M19.2046 19.544C19.6048 19.0187 20.3952 19.0187 20.7954 19.544L26.7763 27.394C27.2779 28.0522 26.8085 29 25.9809 29H14.0191C13.1915 29 12.7221 28.0522 13.2237 27.394L19.2046 19.544Z"
        fill="#7876C1"
      />
      <path
        d="M29.0833 22.6777C28.6743 22.3039 28.0395 22.3338 27.6675 22.7444L23.5143 27.3286C22.9319 27.9714 23.388 29 24.2554 29H33.4238C34.3365 29 34.7722 27.8777 34.0985 27.2619L29.0833 22.6777Z"
        fill="#7876C1"
      />
      <circle cx="29" cy="18" r="2" fill="#7876C1" />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="48"
        height="48"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.72549 0 0 0 0 0.721569 0 0 0 0 0.839216 0 0 0 0.2 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="24"
        y1="2"
        x2="24"
        y2="42"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F5F6FA" />
      </linearGradient>
    </defs>
  </svg>
);

const GalleryIcon = (props) => <Icon component={svg} {...props} />;

export default GalleryIcon;
