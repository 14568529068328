import React, { useEffect } from 'react';
import { message } from 'antd';
import { useLazyQuery } from '@apollo/client';
import Loader from 'components/Loader/Loader';
import mondaySdk from 'monday-sdk-js';

import { CONNECT_MONDAY } from 'graphql/queries/integrationQueries';
import {
  fromAppVar,
  isLoggedInVar,
  userVar,
  mondayBoardIdVar,
  appSessionTokenVar,
} from 'graphql/cache';

const monday = mondaySdk();
const MondayWindow = ({ history }) => {
  const [connectMonday] = useLazyQuery(CONNECT_MONDAY, {
    variables: { url: window.location.href },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      monday.execute('valueCreatedForUser');
      fromAppVar('monday');
      mondayBoardIdVar(localStorage.getItem('boardId'));
      appSessionTokenVar(localStorage.getItem('sessionToken'));
      if (data.connectMonday.token) {
        // window.ga('send', 'event', 'registeration', 'sign up to trial', 'freetrial');
        // window.ga('send', 'pageview', '/event/sign-up');
        // // trackEvent('sign-up');
        // window.gtag('event', 'conversion', { send_to: 'AW-848665564/735ACKCqsJEDENy31pQD' });
        window.localStorage.setItem('prospero-token', data.connectMonday.token);
        window.localStorage.setItem('prospero-user-id', data.connectMonday.user._id);

        isLoggedInVar(true);
        userVar({ ...data.connectMonday.user });
        window.close();
      } else {
        message.success('monday Connected Successfully');
        history.push('/integration?monday=connected');
        window.close();
      }
    },
    onError: (err) => {
      console.log('error', err);
      message.error('monday Connect Failed');
      setTimeout(() => history.push('/integration?monday=failed'), 1000);
    },
  });

  useEffect(() => {
    const code = window?.location?.href?.split('?')[1]?.split('&')[0]; // eslint-disable-line

    if (!code) {
      window.open(
        `https://auth.monday.com/oauth2/authorize?client_id=${process.env.REACT_APP_MONDAY_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_MONDAY_REDIRECT_URI}`,
        '_blank'
      );
    } else {
      connectMonday();
    }
  }, [connectMonday]);

  return <Loader />;
};

export default MondayWindow;
