import { gql } from '@apollo/client';

export const FETCH_ADMIN_USERS = gql`
  query FetchAdminUsersQuery($userCondition: AdminUsersInput!, $limit: Float, $offset: Float) {
    fetchAdminUsers(userCondition: $userCondition, limit: $limit, offset: $offset) {
      generalStats {
        payAsYouGo
        subscribed
        canceled
        skipped
        failed
        proposalsCount
        usersCount
        approvedProposalsCount
      }
      adminUsers {
        _id
        profile {
          name
          where
          companyname
          referrerUrl
          landingUrl
          ip
        }
        emails {
          address
        }
        teamInfo {
          _id
          emails {
            address
          }
          profile {
            name
          }
        }
        props
        subscriptionId
        ptype
        otherptype
        createdAt
        paymentEmail
        deleted
      }
    }
  }
`;

export const FETCH_ADMIN_PROPOSALS = gql`
  query FetchAdminProposalsQuery($queryString: String, $limit: Float, $offset: Float) {
    fetchAdminProposals(offset: $offset, limit: $limit, queryString: $queryString) {
      proposalsCount
      proposals {
        _id
        pid
        uid
        state
        date
        fake
        draft
        language
      }
    }
  }
`;

export const FETCH_ADMIN_TRANSACTIONS = gql`
  query FetchAdminTransactionsQuery($queryString: String, $limit: Float, $offset: Float) {
    fetchAdminTransactions(offset: $offset, limit: $limit, queryString: $queryString) {
      transactionsCount
      transactions {
        _id
        uid
        trans
        type
        time
      }
    }
  }
`;

export const FETCH_ADMIN_AFFILIATES = gql`
  query FetchAdminAffiliatesQuery($userCondition: AdminUsersInput, $limit: Float, $offset: Float) {
    fetchAdminAffiliates(offset: $offset, limit: $limit, userCondition: $userCondition) {
      affiliatesCount
      affiliates {
        _id
        emails {
          address
        }
        profile {
          name
        }
        paypalId
        affiliateId
        credits
        affiliatePayments {
          requestedCredits
          paided
          createdAt
          payedAt
        }
        affiliateTransactions {
          amount
          createdAt
          sender
          receiver
        }
        affiliates {
          affiliateBy
          affiliatedUser
          status
          paid
          amount
        }
        createdAt
        requestedCredits
      }
    }
  }
`;

export const FETCH_ADMIN_COVERS = gql`
  query FetchAdminCoversQuery {
    fetchAdminCovers {
      _id
      name
      img
      alt
      type
    }
  }
`;

export const FETCH_ADMIN_CONFIGURATIONS = gql`
  query FetchAdminConfigurationsQuery($language: String!) {
    fetchAdminConfigurations(language: $language)
  }
`;

export const FETCH_ADMIN_KIPS = gql`
  query FetchAdminKipsQuery($userCondition: AdminUsersInput!) {
    fetchAdminKips(userCondition: $userCondition) {
      users {
        _id
        profile {
          name
        }
        emails {
          address
        }
        createdAt
        lastSeen
        props
        paymentStatus {
          trialTime
          activeProfileId
          # activeProfileStartDate
          # canLock
          # everPayed
          # lastStatus
          subType
          # fullSubType
          # didCancel
          # nextPaymentDate
          # validTill
          payments
        }
      }
      transactions {
        _id
        txid
        uid
        type
        trans
        time
        reason
      }
    }
  }
`;

export const FETCH_ADMIN_PAYPAL = gql`
  query FetchAdminPayPalQuery($userCondition: AdminUsersInput!) {
    fetchAdminPayPal(userCondition: $userCondition) {
      L_TIMESTAMP
      L_TIMEZONE
      L_TYPE
      L_EMAIL
      L_NAME
      L_TRANSACTIONID
      L_STATUS
      L_AMT
      L_CURRENCYCODE
      L_FEEAMT
      L_NETAMT
      details
      usdtoils
    }
  }
`;

export const FETCH_ADMIN_PROXY_SERVER_DETAILS = gql`
  query fetchAdminProxyServerDetailsQuery {
    fetchAdminProxyServerDetails
  }
`;

export const FETCH_ADMIN_MAIL_JOBS = gql`
  query FetchAdminMailJobsQuery($type: String, $limit: Float, $offset: Float) {
    fetchAdminMailJobs(type: $type, limit: $limit, offset: $offset) {
      mailJobs
      mailJobsCount
    }
  }
`;

export const FETCH_ADMIN_WIX_USERS_REV = gql`
  query FetchAdminWixUsersRevQuery($userCondition: AdminUsersInput!) {
    fetchAdminWixUsersRev(userCondition: $userCondition) {
      generalStats {
        totalAmount
        totalNetRevenue
        totalRevShare
        usersCount
      }
      wixUsers {
        _id
        profile {
          name
        }
        emails {
          address
        }
        paymentInfo {
          totalPayment
          rev
          owed
          firstPaymentDate
          paying
          productName
          transactionDate
        }
        signUpDate
        appId
        instanceId
      }
    }
  }
`;
