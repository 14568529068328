import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0833 3.75H7.5V7.08333L2.5 7.5V15H17.5V7.5L12.0833 7.08333V3.75Z" fill="#7876C1" />
    <path
      d="M8.33335 13.3333V12.5H2.50002V15.8333C2.50002 16.7583 3.24169 17.5 4.16669 17.5H15.8334C16.7584 17.5 17.5 16.7583 17.5 15.8333V12.5H11.6667V13.3333H8.33335ZM16.6667 5.83333H13.3334V4.16667L11.6667 2.5H8.33335L6.66669 4.16667V5.83333H3.33335C2.41669 5.83333 1.66669 6.58333 1.66669 7.5V10C1.66669 10.925 2.40835 11.6667 3.33335 11.6667H8.33335V10H11.6667V11.6667H16.6667C17.5834 11.6667 18.3334 10.9167 18.3334 10V7.5C18.3334 6.58333 17.5834 5.83333 16.6667 5.83333ZM11.6667 5.83333H8.33335V4.16667H11.6667V5.83333Z"
      fill="#C7BCE4"
    />
  </svg>
);

const BagIcon = (props) => <Icon component={svg} {...props} />;

export default BagIcon;
