import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';

const GeneralStats = ({ generalStats }) => {
  const [stats, setStats] = useState(generalStats);

  useEffect(() => {
    setStats(generalStats);
  }, [generalStats]);

  const generalStatTableColumns = [
    {
      title: 'Signups',
      dataIndex: 'usersCount',
      key: 'usersCount',
    },
    {
      title: 'Props Created',
      dataIndex: 'proposalsCount',
      key: 'proposalsCount',
    },
    {
      title: 'Approved',
      dataIndex: 'approvedProposalsCount',
      key: 'approvedProposalsCount',
    },
    {
      title: 'PayG',
      dataIndex: 'payAsYouGo',
      key: 'payAsYouGo',
    },
    {
      title: 'Subs',
      dataIndex: 'subscribed',
      key: 'subscribed',
    },
    {
      title: 'Cancel',
      dataIndex: 'canceled',
      key: 'canceled',
    },
    {
      title: 'Skipped',
      dataIndex: 'skipped',
      key: 'skipped',
    },
    {
      title: 'Failed',
      dataIndex: 'failed',
      key: 'failed',
    },
  ];

  return (
    <div className="admin-table-wrapper">
      <Table dataSource={stats} columns={generalStatTableColumns} pagination={false} />
    </div>
  );
};

GeneralStats.propTypes = {
  generalStats: PropTypes.instanceOf(Object),
};

export default GeneralStats;
