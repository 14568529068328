/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="9.75"
      y="6.25"
      width="0.5"
      height="6.5"
      rx="0.25"
      transform="rotate(90 9.75 6.25)"
      fill="#FF2929"
      stroke="#FF2929"
      strokeWidth="0.5"
    />
    <circle cx="6.5" cy="6.5" r="6" stroke="#FF1B1B" />
  </svg>
);

const RemoveCoverIcon = (props) => <Icon component={svg} {...props} />;

export default RemoveCoverIcon;
