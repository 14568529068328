import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 0C9.10457 0 10 0.89543 10 2V14C10 15.1046 9.10457 16 8 16H2C0.89543 16 0 15.1046 0 14V2C0 0.89543 0.89543 0 2 0H8ZM8 1H2C1.48716 1 1.06449 1.38604 1.00673 1.88338L1 2V14C1 14.5128 1.38604 14.9355 1.88338 14.9933L2 15H8C8.51284 15 8.93551 14.614 8.99327 14.1166L9 14V2C9 1.48716 8.61396 1.06449 8.11662 1.00673L8 1ZM6 12V13H4V12H6Z"
      fill="#000624"
    />
  </svg>
);

const WixMobileIcon = (props) => <Icon component={svg} {...props} />;

export default WixMobileIcon;
