/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="15" height="10" rx="0.5" stroke="#05034D" />
    <rect x="1" y="6" width="14" height="4" fill="#7876C1" />
    <line x1="1" y1="5.5" x2="16" y2="5.5" stroke="#05034D" />
    <line x1="5.5" y1="0.5" x2="5.5" y2="10.5" stroke="#05034D" />
    <line x1="10.5" y1="0.5" x2="10.5" y2="10.5" stroke="#05034D" />
  </svg>
);

const AddRowIcon = (props) => <Icon component={svg} {...props} />;

export default AddRowIcon;
