import getHeader from './getHeader';
import getOverview from './getOverview';
import getWhyMe from './getWhyMe';
import getPricing from './getPricing';
import getMilestones from './getMilestones';
import getTerms from './getTerms';
import getWhatsNext from './getWhatsNext';
import getSignature from './getSignature';
import getBlockRenderMap from './getBlockRenderMap';
import updateDraftHook from './updateDraftHook';
import getSectionElements from './getSectionElements';

const utils = {
  getHeader,
  getOverview,
  getWhyMe,
  getPricing,
  getMilestones,
  getTerms,
  getWhatsNext,
  getSignature,
  getBlockRenderMap,
  updateDraftHook,
  getSectionElements,
};

export default utils;
