/* eslint-disable */
import React, { useState, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Tablist } from '../../../modules';
import helpers from 'helpers/proposal';
import images from 'constants/images';

const YouInformation = React.forwardRef(
  ({ user, prop, config, save, saveUser, handleState, wixSiteProperties }, ref) => {
    const [superPowers, setSuperPowers] = useState({});
    const [superToText, setSuperToText] = useState({});
    const [superRegex, setSuperRegex] = useState('');

    useEffect(() => {
      setSuperPowers(prop.superPowers);
    }, [prop]);

    useEffect(() => {
      let defaultSuperToText = {};
      const usersSuperToText = user?.config?.super;

      if (wixSiteProperties?.description) {
        defaultSuperToText['Wix site description'] = {
          name: wixSiteProperties.description,
          editable: false,
          logo: images.WIX_ICON,
        };
      }

      _.each(config['superpowers simple']?.split('\n'), (l) => {
        defaultSuperToText[l.split(':')[0]] = {
          name: l.split(':')[1].trim(),
          editable: false,
        };
      });

      if (usersSuperToText) {
        defaultSuperToText = { ...defaultSuperToText, ...usersSuperToText };
      }

      if (superRegex) {
        const searchRegex = new RegExp(superRegex.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
        const superKeys = Object.keys(defaultSuperToText).filter((superkey) =>
          searchRegex.test(superkey)
        );

        const newSuperToText = {};
        superKeys.forEach((key) => {
          newSuperToText[key] = defaultSuperToText[key];
        });

        defaultSuperToText = newSuperToText;
      }

      setSuperToText(defaultSuperToText);
    }, [user, config, superRegex, wixSiteProperties]);

    useImperativeHandle(
      ref,
      () => ({
        resetChosen: () => {
          setSuperPowers({});
          setSuperToText(superToText);
          // save in db
          save({ superPowers: {} }, true);
        },
      }),
      [superToText, setSuperPowers]
    );

    return (
      <Tablist
        tabType="super"
        superVal
        tabs={false}
        config={config}
        prop={prop}
        setSuperRegex={setSuperRegex}
        saveUser={saveUser}
        prelist={[
          ...Object.keys(superToText).map((superTextKey) => {
            return {
              name: superTextKey,
              editable: superToText[superTextKey].editable,
              logo: superToText[superTextKey].logo,
            };
          }),
        ]}
        handleState={handleState}
        transform={(t) => {
          return superToText[t]?.name;
        }}
        onChange={(chosen) => {
          const newsupers = helpers.objectPairs(
            _.map(chosen['OPTION 1'].list, (x, i) => {
              if (x.super === true || !x.super) {
                x.super = { text: superToText[x.name].name };
              }
              x.super.order = i;
              x.super.description = x.description || '';
              return [x.name, x.super || true];
            })
          );
          if (!superPowers || !_.isEqual(superPowers, newsupers)) {
            console.log('will save superpowers as table change', newsupers);
            save({ superPowers: newsupers }, true);
          }
        }}
        propsChosen={{
          'OPTION 1': {
            list: _.map(superPowers, (v, k) => ({
              name: k,
              super: {
                text: v && typeof v.text === 'string' ? v.text : superToText[k].name,
                order: v.order || 0,
              },
              description: v.description,
            })).sort((a, b) => a.super.order - b.super.order),
          },
        }}
        isWizard
      />
    );
  }
);

YouInformation.defaultProps = {};

YouInformation.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  prop: PropTypes.instanceOf(Object).isRequired,
  config: PropTypes.instanceOf(Object).isRequired,
  save: PropTypes.func.isRequired,
  handleState: PropTypes.func.isRequired,
};

export default YouInformation;
