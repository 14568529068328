/* eslint-disable */
import {
  EditorState,
  SelectionState,
  ContentState,
  RichUtils,
  CharacterMetadata,
  ContentBlock,
  genKey,
  convertToRaw,
  Modifier,
} from 'draft-js';
import Immutable from 'immutable';

const { List, Repeat } = Immutable;

const handleKeyCommand = async ({
  command,
  editorState,
  editorChange,
  editorRef,
  props,
  dispatch,
  updateSelection,
  createCellBlock,
}) => {
  const selection = editorState?.getSelection();
  const content = editorState?.getCurrentContent();

  // Get current block
  const startKey = selection.getStartKey();
  const currentBlock = content.getBlockForKey(startKey);

  let data = currentBlock.getData();
  let bclass = data && data.get('class');
  let bclassName = data && data.get('className');
  const ckey = currentBlock.getKey();
  const blockMap = content.getBlockMap();
  const soffset = selection.getStartOffset();

  // remove first empty block on backspace
  if (command === 'backspace') {
    if (!currentBlock.getText() && soffset === 0) {
      const bbefore = content.getBlockBefore(currentBlock.getKey());
      if (!bbefore) {
        const blocksAfter = blockMap
          .toSeq()
          .skipUntil((v) => v === currentBlock)
          .rest();
        // ckey = currentBlock.getKey();
        const next = content.getBlockAfter(ckey);
        if (!next) return;
        const nkey = next.getKey();
        const newBlocks = blocksAfter.toOrderedMap();
        const emptySelection = SelectionState.createEmpty(next);

        const newcont = content.merge({
          blockMap: newBlocks,
          selectionBefore: emptySelection,
          selectionAfter: emptySelection.merge({
            anchorKey: nkey,
            anchorOffset: 0,
            focusKey: nkey,
            focusOffset: 0,
            isBackward: false,
          }),
        });

        editorChange({
          editorState: EditorState.push(editorState, newcont, 'merge-block'),
          props,
          dispatch,
          editorRef,
          updateSelection,
        });
        return true;
      }
    }
  }

  // dont remove tex block on back space, just remove empty block
  if (command === 'backspace') {
    if (soffset === 0) {
      const bbefore = content.getBlockBefore(currentBlock.getKey());
      if (bbefore) {
        try {
          const contentState = ContentState.createFromBlockArray([currentBlock]);
          const entityKey = contentState.getLastCreatedEntityKey();
          const entity = contentState.getEntity(entityKey);

          const { data } = entity;
          const texcontent = data?.texcontent;

          if (texcontent) {
            if (!currentBlock.getText()) {
              const blocksBefore = blockMap.toSeq().takeUntil((v) => v === currentBlock);
              const blocksAfter = blockMap
                .toSeq()
                .skipUntil((v) => v === currentBlock)
                .rest();

              const newBlocks = blocksBefore.concat(blocksAfter).toOrderedMap();

              currentBlock.getKey();
              bbefore.getKey();

              const newcont = content.merge({
                blockMap: newBlocks,
                selectionBefore: SelectionState.createEmpty('asdf'),
                selectionAfter: SelectionState.createEmpty('asdf'),
              });

              let newes = EditorState.push(editorState, newcont, 'no-del-block');
              newes = EditorState.forceSelection(newes, SelectionState.createEmpty('sdfa'));
              editorChange({
                editorState: newes,
                props,
                dispatch,
                editorRef,
                updateSelection,
              });
              return true;
            }

            // same state
            return true;
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  }

  // no split block on atomic texblock (or maybe do it right and add a good empty block after)
  if (
    command === 'split-block' &&
    currentBlock.getType() !== 'ordered-list-item' &&
    currentBlock.getType() !== 'unordered-list-item'
  ) {
    try {
      const contentState = ContentState.createFromBlockArray([currentBlock]);
      const entityKey = contentState.getLastCreatedEntityKey();
      const entity = contentState.getEntity(entityKey);

      const atomicData = entity?.data;
      const texcontent = atomicData?.texcontent;
      if (texcontent) {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  }

  if (
    currentBlock.getType() === 'ordered-list-item' &&
    bclass &&
    bclass.indexOf('tablecell') !== -1
  ) {
    if (command === 'split-block') {
      console.log('handle key command for table cell!');

      let next = currentBlock;

      let nkey = ckey;

      while (bclass.indexOf('right') === -1) {
        console.log('looking for next right');
        next = content.getBlockAfter(nkey);
        const nextdata = next.getData();
        bclass = nextdata && nextdata.get('class');
        nkey = next.getKey();
        if (bclass.indexOf('tablecell') === -1) {
          break;
        }
      }

      if (!next) {
        return null;
      }

      // next "right" block after current block split-block
      // after that we want two blocks
      if (next) {
        console.log('found next right', next);
        nkey = next.getKey();

        const b1 = createCellBlock(genKey(), '', 'tablecell left');
        const b2 = createCellBlock(genKey(), '', 'tablecell right');

        const blocksBefore = blockMap.toSeq().takeUntil((v) => v === next);
        const blocksAfter = blockMap
          .toSeq()
          .skipUntil((v) => v === next)
          .rest();

        console.log('before/after', blocksBefore.toOrderedMap(), blocksAfter.toOrderedMap());

        const newBlocks = blocksBefore
          .concat(
            [
              [nkey, next],
              [b1.getKey(), b1],
              [b2.getKey(), b2],
            ],
            blocksAfter
          )
          .toOrderedMap();

        const newcont = content.merge({
          blockMap: newBlocks,
          selectionBefore: selection,
          selectionAfter: selection.merge({
            anchorKey: ckey,
            anchorOffset: 0,
            focusKey: ckey,
            focusOffset: 0,
            isBackward: false,
          }),
        });

        editorChange({
          editorState: EditorState.push(editorState, newcont, 'new-row'),
          props,
          dispatch,
          updateSelection,
          editorRef,
        });
        return true;
      }
    } else if (command === 'backspace') {
      console.log('handling backspace command');

      let otherblock = null;
      data = currentBlock.getData();
      bclass = data && data.get('class');
      let first = null;
      let second = null;
      // const after = null;

      if (bclass.indexOf('right') === -1) {
        otherblock = content.getBlockAfter(ckey);
        first = currentBlock;
        second = otherblock;
      } else {
        otherblock = content.getBlockBefore(ckey);
        first = otherblock;
        second = currentBlock;
      }

      if (!otherblock) {
        return 'not-handled';
      }

      if (currentBlock.getText() === '' && otherblock.getText() !== '') {
        //	  editorChange(editorState);
        const newcont = content.merge({
          blockMap,
          selectionBefore: selection,
          selectionAfter: selection.merge({
            anchorKey: otherblock.getKey(),
            anchorOffset: otherblock.getText().length,
            focusKey: otherblock.getKey(),
            focusOffset: otherblock.getText().length,
            isBackward: false,
          }),
        });
        console.log('endKey');

        const newes = EditorState.push(editorState, newcont, 'change-cell');
        editorChange({ editorState: newes, props, dispatch, updateSelection, editorRef });
        return true;
      }
      if (currentBlock.getText() === '' && otherblock.getText() === '') {
        // remove both
        const blocksBefore = blockMap.toSeq().takeUntil((v) => v === first);
        const blocksAfter = blockMap
          .toSeq()
          .skipUntil((v) => v === second)
          .rest();

        console.log('before/after', blocksBefore.toOrderedMap(), blocksAfter.toOrderedMap());

        const before = content.getBlockBefore(first.getKey());
        const bkey = before && before.getKey();

        const after = content.getBlockAfter(second.getKey());
        const akey = after && after.getKey();

        let newBlocks = blocksBefore.concat(blocksAfter).toOrderedMap();

        let afterRemoveSelectionKey = bkey;
        if (!bkey) {
          if (akey) {
            afterRemoveSelectionKey = akey;
          } else {
            const thekey = genKey();
            const xblock = new ContentBlock({
              key: thekey,
              type: 'unstyled',
              text: '',
              characterList: List(Repeat(CharacterMetadata.create(), 0)),
            });

            afterRemoveSelectionKey = thekey;
            newBlocks = blocksBefore.concat([thekey, xblock], blocksAfter).toOrderedMap();
          }
        }

        const newcont = content.merge({
          blockMap: newBlocks,
          selectionBefore: selection,
          selectionAfter: selection.merge({
            anchorKey: afterRemoveSelectionKey,
            anchorOffset: 0,
            focusKey: afterRemoveSelectionKey,
            focusOffset: 0,
            isBackward: false,
          }),
        });

        editorChange({
          editorState: EditorState.push(editorState, newcont, 'remove-row'),
          props,
          dispatch,
          updateSelection,
          editorRef,
        });
        return true;
      }
    }
  }

  if (
    (currentBlock.getType() === 'ordered-list-item' ||
      currentBlock.getType() === 'unordered-list-item') &&
    bclassName &&
    command === 'split-block'
  ) {
    const contentState = editorState.getCurrentContent();

    let newContent = Modifier.splitBlock(contentState, selection);

    const originalBlock = contentState.blockMap.get(newContent.selectionBefore.getStartKey());
    const originalBlockData = originalBlock.getData();
    const preserveDataOnSplit = originalBlockData.size;
    /**
    /* If the user is trying to bump a block down a row that _has_ data (like
    /* attributions) we need to manually transfer that data along with the
    /* block split. This code can maybe be eventually deleted if draft decides to
    /* support this use case out of the box.
    */

    if (preserveDataOnSplit) {
      const newBlockKey = newContent.selectionAfter.getStartKey();

      // clear data out of the current block we're moving down
      newContent = Modifier.setBlockData(newContent, selection, originalBlockData);

      // create the new block and transfer data from the current block
      const newBlockMap = newContent.blockMap.update(newBlockKey, (contentBlock) =>
        contentBlock.set('data', originalBlockData)
      );
      newContent = newContent.set('blockMap', newBlockMap);

      let tempEditorState = EditorState.createWithContent(newContent);

      /**
      /* now that the block is split and the data has been transfered
      /* we need to force the cursor one block down. Unfortunately it looks
      /* as though there is a limitation in editorState where we have to do
      /* this as a subsequent change
      */
      const newSelection = new SelectionState({
        anchorKey: newBlockKey,
        anchorOffset: 0,
        focusKey: newBlockKey,
        focusOffset: 0,
      });
      setTimeout(() => {
        editorChange({
          editorState: EditorState.forceSelection(tempEditorState, newSelection),
          props,
          dispatch,
          updateSelection,
          editorRef,
        });
      }, 1);

      return 'handled';
    }
  }

  if (
    (currentBlock.getType() === 'unordered-list-item-hyphen' ||
      currentBlock.getType() === 'ordered-list-item-alpha' ||
      currentBlock.getType() === 'ordered-list-item-check') &&
    selection.isCollapsed() &&
    command === 'split-block'
  ) {
    const contentState = editorState.getCurrentContent();
    const endOffset = selection.getEndOffset();
    const atEndOfBlock = endOffset === currentBlock.getLength();
    const atStartOfBlock = endOffset === 0;

    // Check we’re at the start/end of the current block
    if ((atStartOfBlock || atEndOfBlock) && !currentBlock.getLength()) {
      const emptyBlockKey = genKey();
      const emptyBlock = new ContentBlock({
        key: emptyBlockKey,
        text: '',
        type: 'none',
        characterList: List(),
        depth: 0,
      });
      const blockMap = contentState.getBlockMap();

      // Split the blocks
      const blocksBefore = blockMap.toSeq().takeUntil((v) => v === currentBlock);

      const blocksAfter = blockMap
        .toSeq()
        .skipUntil((v) => v === currentBlock)
        .rest();

      let augmentedBlocks;
      let focusKey;

      // Choose which order to apply the augmented blocks in depending
      // on whether we’re at the start or the end
      if (atEndOfBlock) {
        // Discard Current as it was blank
        augmentedBlocks = [[emptyBlockKey, emptyBlock]];
        focusKey = emptyBlockKey;
      } else {
        // Empty first, current block afterwards
        augmentedBlocks = [
          [emptyBlockKey, emptyBlock],
          [currentBlock.getKey(), currentBlock],
        ];
        focusKey = currentBlock.getKey();
      }

      // Join back together with the current + new block
      const newBlocks = blocksBefore.concat(augmentedBlocks, blocksAfter).toOrderedMap();
      const newContentState = contentState.merge({
        blockMap: newBlocks,
        selectionBefore: selection,
        selectionAfter: selection.merge({
          anchorKey: focusKey,
          anchorOffset: 0,
          focusKey: focusKey,
          focusOffset: 0,
          isBackward: false,
        }),
      });

      // Set the state
      editorChange({
        editorState: await EditorState.push(editorState, newContentState, 'split-block'),
        props,
        dispatch,
        updateSelection,
        editorRef,
      });

      return true;
    }
  }

  if (
    (currentBlock.getType() === 'ordered-list-item' ||
      currentBlock.getType() === 'unordered-list-item') &&
    command === 'shift+enter'
  ) {
    let newEditorState = RichUtils.insertSoftNewline(editorState);

    const selectionState = newEditorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();

    const currentContent = newEditorState.getCurrentContent();
    const currentBlock = currentContent.getBlockForKey(anchorKey);

    const newSelectionState = new SelectionState({
      anchorKey: currentBlock.getKey(),
      anchorOffset: currentBlock.getLength(),
      focusKey: currentBlock.getKey(),
      focusOffset: currentBlock.getLength(),
      hasFocus: true,
    });

    const contentWithoutStyles = Modifier.replaceText(
      newEditorState.getCurrentContent(),
      newSelectionState,
      ' ',
      null
    );

    newEditorState = EditorState.push(newEditorState, contentWithoutStyles, 'change-inline-style');

    editorChange({
      editorState: newEditorState,
      props,
      dispatch,
      updateSelection,
      editorRef,
    });

    return true;
  }

  const newState = RichUtils.handleKeyCommand(editorState, command);

  if (newState) {
    editorChange({
      editorState: newState,
      props,
      dispatch,
      updateSelection,
      editorRef,
    });
    return true;
  }

  return false;
};

export default handleKeyCommand;
