import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="50" height="49" viewBox="0 0 50 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_14427_21533)">
      <path
        d="M12.7571 3.48731L2.35085 6.1824C0.586521 6.63934 -0.473326 8.44004 -0.0163851 10.2044L9.0855 45.3483C9.54244 47.1126 11.3431 48.1725 13.1075 47.7155L28.8868 43.6289C30.6511 43.1719 31.711 41.3712 31.254 39.6069L24.0924 11.9544C23.9194 11.2865 23.5414 10.6895 23.0115 10.2476L15.6982 4.1477C14.8815 3.46653 13.7866 3.22068 12.7571 3.48731Z"
        fill="#5D5AB5"
      />
      <path
        d="M27.5922 0.920898H10.0031C8.18059 0.920898 6.70312 2.39836 6.70312 4.2209V39.6209C6.70312 41.4434 8.18058 42.9209 10.0031 42.9209H35.8031C37.6257 42.9209 39.1031 41.4434 39.1031 39.6209V12.5945C39.1031 11.7305 38.7643 10.9009 38.1594 10.284L29.9484 1.91044C29.3278 1.27752 28.4787 0.920898 27.5922 0.920898Z"
        fill="#7876C1"
      />
      <rect x="10.9062" y="18.7207" width="19.2" height="2.4" rx="1.2" fill="#C7BCE4" />
      <rect x="10.9062" y="23.3203" width="9.6" height="2.4" rx="1.2" fill="#C7BCE4" />
      <circle cx="14.5063" cy="9.92031" r="3.6" fill="#C7BCE4" />
      <mask id="mask0_14427_21533" maskUnits="userSpaceOnUse" x="6" y="0" width="33" height="45">
        <path
          d="M27.3939 0.720703H9.8C7.97746 0.720703 6.5 2.19817 6.5 4.02071V41.6025C6.5 43.4251 7.97746 44.9025 9.8 44.9025H35.6C37.4225 44.9025 38.9 43.4251 38.9 41.6025V12.3483C38.9 11.4815 38.5589 10.6494 37.9505 10.032L29.7444 1.70445C29.1242 1.07507 28.2775 0.720703 27.3939 0.720703Z"
          fill="#7876C1"
        />
      </mask>
      <g mask="url(#mask0_14427_21533)">
        <path
          d="M28.7812 9.34415V-3.39062L44.9813 10.9942H30.4313C29.52 10.9942 28.7812 10.2554 28.7812 9.34415Z"
          fill="#C7BCE4"
        />
      </g>
    </g>
    <circle cx="37.0029" cy="33.8057" r="12.417" fill="#F03F3B" />
    <path
      d="M36.9975 35.3315C36.6307 35.3315 36.2782 35.1895 36.0138 34.9354C35.7493 34.6813 35.5935 34.3347 35.5788 33.9682L35.3917 29.2432C35.3842 29.0522 35.4153 28.8616 35.4833 28.683C35.5512 28.5043 35.6544 28.3412 35.7869 28.2034C35.9194 28.0656 36.0783 27.956 36.2542 27.8811C36.43 27.8062 36.6192 27.7676 36.8103 27.7676H37.1846C37.3757 27.7676 37.5649 27.8062 37.7408 27.8811C37.9166 27.956 38.0756 28.0656 38.208 28.2034C38.3405 28.3412 38.4438 28.5043 38.5117 28.683C38.5796 28.8616 38.6107 29.0522 38.6032 29.2432L38.4161 33.9682C38.4015 34.3347 38.2456 34.6813 37.9812 34.9354C37.7167 35.1895 37.3642 35.3315 36.9975 35.3315Z"
      fill="white"
    />
    <path
      d="M38.4286 38.4262C38.4286 37.6434 37.794 37.0088 37.0112 37.0088C36.2284 37.0088 35.5938 37.6434 35.5938 38.4262C35.5938 39.2091 36.2284 39.8437 37.0112 39.8437C37.794 39.8437 38.4286 39.2091 38.4286 38.4262Z"
      fill="white"
    />
    <defs>
      <clipPath id="clip0_14427_21533">
        <rect width="40" height="48" fill="white" transform="translate(0 0.0136719)" />
      </clipPath>
    </defs>
  </svg>
);

const EmailNotebookAlertIcon = (props) => <Icon component={svg} {...props} />;

export default EmailNotebookAlertIcon;
