/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="#CBCED8" />
    <circle cx="12" cy="12" r="2" fill="white" />
    <circle cx="5" cy="12" r="2" fill="white" />
    <circle cx="19" cy="12" r="2" fill="white" />
  </svg>
);

const SavingIcon = (props) => <Icon component={svg} {...props} />;

export default SavingIcon;
