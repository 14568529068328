/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 11.5C6.01625 11.5 2.56216 9.23482 0.581988 5.99999C2.56371 2.76241 6.01932 0.5 10 0.5C13.9837 0.5 17.4375 2.76516 19.418 6.00003C17.4363 9.2376 13.981 11.5 10 11.5Z"
      stroke="#7876C1"
    />
    <circle cx="10" cy="6" r="3" fill="#7876C1" />
  </svg>
);

const EyeIcon = (props) => <Icon component={svg} {...props} />;

export default EyeIcon;
