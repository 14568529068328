import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M11.2156 12.9661V8.70948M11.2156 4H13.5704C14.0205 4 14.3855 4.36493 14.3855 4.8151V12.9661M11.2156 4V8.70948M11.2156 4C11.2156 4 8.03906 4 8.03906 6.03808C8.03906 9.29817 11.2156 8.70948 11.2156 8.70948"
      stroke="#4F4E7B"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M17.9375 18.9888L20.243 16.6834L17.9375 14.3779"
      stroke="#4F4E7B"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.247 16.683H6.34676"
      stroke="#4F4E7B"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const LTRIcon = (props) => <Icon component={svg} {...props} />;

export default LTRIcon;
