import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#010032"
      d="M11,2.5c-4.7,0-8.5,3.8-8.5,8.5s3.8,8.5,8.5,8.5c4.7,0,8.5-3.8,8.5-8.5S15.7,2.5,11,2.5z M11,18.3 c-4,0-7.3-3.3-7.3-7.3C3.7,7,7,3.7,11,3.7S18.3,7,18.3,11C18.3,15,15,18.3,11,18.3z"
    />
    <polygon
      fill="#010032"
      points="11.6,7.8 10.4,7.8 10.4,10.4 7.8,10.4 7.8,11.6 10.4,11.6 10.4,14.2 11.6,14.2 11.6,11.6 14.2,11.6 14.2,10.4 11.6,10.4 "
    />
  </svg>
);

const PlusCircleIcon = (props) => <Icon component={svg} {...props} />;

export default PlusCircleIcon;
