import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

const WixRevTable = ({ isFetchingAdminUsers, limit, users, usersCount, setOffset, setLimit }) => {
  const userColumn = [
    {
      title: 'UID',
      dataIndex: 'uid',
      key: 'uid',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Total Payment',
      dataIndex: 'totalPayment',
      key: 'totalPayment',
    },

    {
      title: '20% Rev-Share',
      dataIndex: 'rev',
      key: 'rev',
    },
    {
      title: 'Sign Up Date',
      dataIndex: 'signUpDate',
      key: 'signUpDate',
    },
    {
      title: 'First Payment Date',
      dataIndex: 'firstPaymentDate',
      key: 'firstPaymentDate',
    },
    {
      title: 'Paying',
      dataIndex: 'paying',
      key: 'paying',
    },
  ];

  return (
    <Table
      loading={isFetchingAdminUsers}
      dataSource={users}
      columns={userColumn}
      rowKey={'uid'}
      pagination={{
        total: usersCount,
        defaultPageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30'],
      }}
    />
  );
};

WixRevTable.defaultProps = {
  isFetchingAdminUsers: false,
  users: [],
  usersCount: 0,
  limit: 10,
};

WixRevTable.propTypes = {
  isFetchingAdminUsers: PropTypes.bool,
  usersCount: PropTypes.number,
  users: PropTypes.arrayOf(PropTypes.shape({})),
};

export default WixRevTable;
