import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { notification } from 'antd';
import { useMutation } from '@apollo/client';

import { DISCONNECT_SLACK } from 'graphql/mutations/integrationMutation';
import { userVar } from 'graphql/cache';
import IntegrationItem from '../IntegrationItem';
import images from 'constants/images';
import utils from 'utils/utils';

const SlackIntegration = ({ data, isModalItem, userRole, refetchUser }) => {
  const [integrationData, setIntegrationData] = useState(data);
  const [loading, setLoading] = useState();
  const user = userVar();
  const slackConnectionStatus = utils.getQueryStringValue('slack');

  useEffect(() => {
    if (data) {
      setIntegrationData(data);
    }
  }, [data]);

  useEffect(() => {
    async function checkUser() {
      if (
        slackConnectionStatus === 'connected' &&
        (!user?.slack || Object.keys(user?.slack).length === 0)
      ) {
        const userData = await refetchUser();
        if (userData) {
          userVar({ ...user, ...userData.data.fetchUser });
          setIntegrationData(userData.data.fetchUser.slack);
          setLoading(Object.keys(userData?.data?.fetchUser?.slack).length ? 'connected' : '');
        } else {
          setIntegrationData(user.slack);
          setLoading(Object.keys(user.slack).length ? 'connected' : '');
        }
      } else if (slackConnectionStatus === 'failed') {
        setLoading('');
      }
    }
    checkUser();
  }, [slackConnectionStatus, refetchUser, user]);

  const handleConnect = async () => {
    const slackState = uuidv4();
    const scopes = 'incoming-webhook';
    sessionStorage.setItem('slack-state', slackState);
    const url = `https://slack.com/oauth/v2/authorize?scope=${scopes}&client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_SLACK_REDIRECT_URI}&state=${slackState}`;
    await utils.refetchUserOnClosingTab(url);
    const userData = await refetchUser();
    if (userData) {
      userVar({ ...user, ...userData.data.fetchUser });
      setIntegrationData(userData.data.fetchUser.slack);
      setLoading(Object.keys(userData?.data?.fetchUser?.slack).length ? 'connected' : '');
    } else {
      setIntegrationData(user.slack);
      setLoading(Object.keys(user.slack).length ? 'connected' : '');
    }
  };

  const handleClick = () => {
    if (!integrationData || !Object.keys(integrationData).length) {
      setLoading('connecting');
      return handleConnect();
    }
    setLoading('disconnecting');
    return disconnectSlack();
  };

  const [disconnectSlack] = useMutation(DISCONNECT_SLACK, {
    onCompleted: async () => {
      const userData = await refetchUser();
      if (userData) {
        userVar({ ...user, ...userData.data.fetchUser });
      }
      setIntegrationData('');
      setLoading('');
      notification.success({
        message: 'Slack Disconnected',
        description: 'Your account has been disconnected from Slack.',
      });
    },
    onError: () => {
      notification.error({
        message: 'Slack Disconnected Failed',
        description: 'Your account failed to disconnect try again.',
      });
    },
  });

  return (
    <IntegrationItem
      type="slack"
      name="slack"
      description="Get instant proposal updates in Slack"
      integrationLink="https://support.goprospero.com/article/show/152711-how-to-integrate-slack-with-prospero"
      status={
        loading ||
        (integrationData && Object.keys(integrationData).length ? 'connected' : 'disconnected')
      }
      image={images.SLACK_ICON}
      handleClick={handleClick}
      isModalItem={isModalItem}
      userRole={userRole}
      user={user}
    />
  );
};

SlackIntegration.defaultProps = {
  data: null,
  isModalItem: false,
  userRole: '',
  refetchUser: () => {},
};

SlackIntegration.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(null),
  ]),
  isModalItem: PropTypes.bool,
  userRole: PropTypes.string,
  refetchUser: PropTypes.func,
};

export default SlackIntegration;
