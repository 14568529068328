/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { Radio, Checkbox, Modal, Button, Input, Space, Tooltip, message } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { UploadOutlined } from '@ant-design/icons';
import { useIntercom } from 'react-use-intercom';
import PropTypes from 'prop-types';
import SignatureCanvas from 'react-signature-canvas';

import Popconfirm from 'components/PopConfirm';
import CloseIcon from 'components/Icons/CloseIcon';
import EraserIcon from 'components/Icons/EraserIcon';
import Loader from 'components/Loader';
import utils from 'utils/utils';
import Uploader from 'components/Uploader';
import { resizeImageForS3 } from 'utils/uploadToS3';

import './UploadSignature.scss';

const UploadSignature = ({ user, proposal, saveUser, saveProposal, onCancel, signame, config }) => {
  const { trackEvent } = useIntercom();
  const drawSignatureRef = useRef();
  const inputFileRef = useRef(null);
  const [signatureType, setSignatureType] = useState(
    proposal.sigtype ? proposal.sigtype : 'sigtext'
  );
  const [sigining, setSigining] = useState(false);
  const [sigName, setSigName] = useState(signame);
  const [sigClear, setSigClear] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [updateGlobal, setUpdateGlobal] = useState(false);
  const [upload, uploadStatus] = Uploader();

  const [showPropModal, setShowPropModal] = useState(true);
  const [showPromptModal, setShowPromptModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleKeyCommand = (e) => {
    if (e.key === 'Escape') {
      setShowModal(true);
      const isNotCombinedKey = !(e.ctrlKey || e.altKey || e.shiftKey);
      if (isNotCombinedKey) {
        makeEscapeModal(true);
      }
    }
  };

  const makeEscapeModal = (showModal) => {
    setShowPromptModal(showModal);
    setShowModal(showModal);
  };

  useEffect(() => {
    setSigClear(false);
    setSigName(signame);

    if (!showPromptModal) {
      window.addEventListener('keydown', handleKeyCommand, true);
    } else {
      window.removeEventListener('keydown', handleKeyCommand, true);
    }
    setShowPromptModal(!showPromptModal); // eslint-disable-next-line
  }, [signame]);

  const sigOptions = (proposal.sigOptions.length && proposal.sigOptions) || [
    'type',
    'upload',
    'draw',
  ];

  const onChange = (e) => {
    setSignatureType(e.target.value);
  };

  const uploadSignature = () => {
    inputFileRef.current.click();
  };

  const onClose = () => {
    setSigining(false);
    onCancel();
  };

  const signProposal = async ({ signature, updateSignature }) => {
    // TODO: GA
    window.ga('send', 'event', 'click-upload-sig', proposal._id, window.location.pathname);

    if (!proposal?.wixProposalId) {
      trackEvent('click-upload-sig');
    }

    if (updateSignature) {
      saveProposal({
        'draft.signature.sigpic': signature,
        'draft.signature.signature': signature,
      });
    }

    if (updateGlobal) {
      // update the signature in the user profile
      const _user = {
        id: user._id,
        signature,
        sigtype: signatureType,
      };

      const maps = {
        sigtext: 'type',
        sigimage: 'upload',
        sigdraw: 'draw',
      };
      const _signatureType = maps[signatureType];

      if (user?.sigOptions && !user.sigOptions.includes(_signatureType)) {
        _user.sigOptions = [...user.sigOptions, _signatureType];
      }

      await saveUser({
        variables: {
          user: _user,
        },
      });
    }
    onClose();
  };

  const handleSignatureUpload = async ({ file }) => {
    try {
      const size = { width: 300, height: 105 };

      const reSizedImage = await resizeImageForS3({
        file: file,
        path: 'signatures',
        size,
      });
      setShowLoader(true);
      const { url } = await upload(reSizedImage, `props/${proposal._id}/signatures`);
      setSigName(url);
      setShowLoader(false);
      return url;
    } catch (error) {
      console.log('error', error);
      message.error(error.message);
    }
  };

  const onSignProposalClick = async (e) => {
    e.preventDefault();
    let updateSignature = true;
    let _signatureType = signatureType;

    if (_signatureType !== 'sigdraw' && !sigName) {
      updateSignature = false;
    }

    if (sigName && /(http(s?)):\/\//gi.test(sigName) && _signatureType === 'sigtext') {
      updateSignature = false;
    }

    if (_signatureType === 'sigdraw' && !drawSignatureRef.current) {
      updateSignature = false;
      if (!drawSignatureRef.current) {
        _signatureType = 'sigtext';
      }
    }

    setSigining(true);
    let _sigName = sigName;

    if (_signatureType === 'sigdraw') {
      const img = drawSignatureRef.current.toDataURL('image/png');
      const file = utils.dataURItoBlob(img);
      file['name'] = `${uuidv4()}.png`;
      _sigName = await handleSignatureUpload({ file: file });
    }

    signProposal({ signature: _sigName, updateSignature });
  };

  const resetSignature = () => {
    if (drawSignatureRef?.current) {
      drawSignatureRef.current.clear();
    }
    setSigName('');
  };

  const resetToDefault = () => {
    if (drawSignatureRef?.current) {
      drawSignatureRef.current.clear();
    }
    // set signature from profile
    setSigName(user.signature);
  };

  return (
    <>
      <Modal
        className={`upload-signature proposal-builder-signature language-${
          proposal?.language?.toLowerCase() || 'english'
        }`}
        centered
        visible={showPropModal}
        onCancel={() => setShowModal(true)}
        closeIcon={<CloseIcon className="close-icon" />}
        footer={null}
        width={540}>
        <div>
          <h3 className="title">Signature</h3>
        </div>

        <div className="radio-wrapper">
          {sigOptions?.length > 1 && (
            <Radio.Group onChange={onChange} value={signatureType}>
              {sigOptions.map((item) => {
                if (item === 'type') {
                  return (
                    <Radio value="sigtext" key={item}>
                      {config['Type Signature'] || 'Type Signature'}
                    </Radio>
                  );
                }
                if (item === 'upload') {
                  return (
                    <Radio value="sigimage" key={item}>
                      {config['Upload Signature'] || 'Upload Signature'}
                    </Radio>
                  );
                }
                if (item === 'draw') {
                  return (
                    <Radio value="sigdraw" key={item}>
                      {config['Draw Signature'] || 'Draw Signature'}
                    </Radio>
                  );
                }
              })}
            </Radio.Group>
          )}
        </div>

        <div className="signature-container">
          {signatureType === 'sigdraw' && (
            <div>
              <h1 className="item-title">Draw your signature below</h1>
              <div className="signature-wrapper sig-draw">
                {/(http(s?)):\/\//gi.test(sigName) && !sigClear && sigName ? (
                  <div className="sigpic-wrap-draw" onClick={() => setSigClear(true)}>
                    <img src={sigName} alt="sigName" />
                  </div>
                ) : (
                  <SignatureCanvas
                    penColor="black"
                    backgroundColor="transparent"
                    ref={drawSignatureRef}
                    canvasProps={{
                      width: '385px',
                      height: '100%',
                    }}
                  />
                )}
              </div>
            </div>
          )}

          {signatureType === 'sigimage' && (
            <div>
              <h1 className="item-title">Upload your signature below</h1>
              {showLoader ? (
                <Loader className="signature-wrapper" />
              ) : (
                <div onClick={uploadSignature} className="signature-wrapper">
                  <div className="hoverlay">
                    <UploadOutlined />
                  </div>
                  {/(http(s?)):\/\//gi.test(sigName) ? (
                    <div className="sigpic-wrap">
                      <img src={sigName} alt={sigName} />
                    </div>
                  ) : sigName ? (
                    <div style={sigName ? { padding: '64px 16px 45px 19px' } : { display: 'none' }}>
                      <div>{sigName}</div>
                    </div>
                  ) : (
                    <UploadOutlined />
                  )}
                  <input
                    type="file"
                    id="file_profile_sig"
                    className="file_profile_sig"
                    ref={inputFileRef}
                    style={{ display: 'none' }}
                    onChange={(e) =>
                      handleSignatureUpload({ file: e.target.files && e.target.files[0] })
                    }
                    accept="image/x-png,image/bmp,image/jpeg,image/svg+xml"
                  />
                </div>
              )}
            </div>
          )}

          {signatureType === 'sigtext' && (
            <div>
              <h1 className="item-title">Type your Full Name</h1>
              <Input
                id="name"
                maxLength="30"
                name="name"
                placeholder="Enter Your Name"
                type="text"
                className="typed-signature"
                value={!/(http(s?)):\/\//gi.test(sigName) ? sigName : ''}
                onChange={(e) => setSigName(e.target.value)}
              />

              <div className="signature-wrapper signature-text">
                {sigName && !/(http(s?)):\/\//gi.test(sigName) ? sigName : '\u00A0'}
              </div>
            </div>
          )}

          <div className="btn-wrapper">
            <Tooltip title="Erase signature">
              <EraserIcon className="erase-btn" onClick={resetSignature} />
            </Tooltip>
            <Button className="button reset-btn" onClick={resetToDefault}>
              Reset to Default
            </Button>
          </div>
        </div>

        <div className="update-checkbox">
          <Checkbox onClick={() => setUpdateGlobal(!updateGlobal)} checked={updateGlobal}>
            Update as the default signature
          </Checkbox>
        </div>

        <div className="button-wrapper">
          <Button className="button confirm" onClick={onSignProposalClick} loading={sigining}>
            {sigining ? 'SIGNING' : ' SIGN PROPOSAL'}
          </Button>
          <Button className="button cancel" onClick={onCancel}>
            CANCEL
          </Button>
        </div>
      </Modal>
      <>
        {showModal && (
          <Popconfirm
            visible={showModal}
            title={'Do you want to exit?'}
            maskClosable={!sigining}
            closable={false}
            cancelText={'Continue'}
            confirmText={'Yes, Exit'}
            onCancel={() => {
              setShowModal(false);
              setShowPropModal(true);
            }}
            onConfirm={onCancel}
            footer={null}
          />
        )}
      </>
    </>
  );
};

UploadSignature.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  onCancel: PropTypes.func.isRequired,
  signame: PropTypes.string.isRequired,
  proposal: PropTypes.instanceOf(Object).isRequired,
  config: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]).isRequired,
};

export default UploadSignature;
