/* eslint-disable react/jsx-indent */
import React, { useEffect, useState, useRef } from 'react';
import { Layout, List } from 'antd';
import PropTypes from 'prop-types';

import RightIcon from 'components/Icons/RightIcon';
import LibraryItems from '../LibraryItems/LibraryItems';
import Covers from '../Covers/Covers';
import Sections from '../Sections/Sections';
import Medias from '../Media/Medias';
import Headers from '../Headers/Headers';

import './LibraryMenu.scss';

const LibraryMenu = ({
  libraryType,
  dragStart,
  dragEnd,
  addNewSectionFromLibrary,
  isSection,
  saveProposal,
  coverType,
  handleToolbarElements,
  sectionRef,
  wixEditor,
  templateWixEditor,
  sectionName,
}) => {
  const coversRef = useRef();
  const headersRef = useRef();
  const [selectedLib, setSelectedLib] = useState(null);

  useEffect(() => {
    if (libraryType === 'Covers') {
      setSelectedLib(libraryType);
    } else if (libraryType === 'Headers') {
      setSelectedLib(libraryType);
    }
  }, [libraryType]);

  let libraryMenuItems = isSection ? ['Media'] : ['Covers', 'Headers', 'Sections', 'Media'];

  if (wixEditor || templateWixEditor) {
    libraryMenuItems = ['Covers', 'Media'];
  }

  return (
    <Layout className="library-menu">
      {selectedLib ? (
        <>
          <LibraryItems
            type={selectedLib.toLowerCase()}
            onBackClick={() => setSelectedLib(null)}
            coversRef={coversRef}
            coverType={coverType === 'full' ? 'full-page' : 'top'}
            saveProposal={saveProposal}
            name={sectionName}
            ChildComponent={(props) => {
              if (selectedLib.toLowerCase() === 'covers') {
                return (
                  <Covers
                    {...props}
                    ref={coversRef}
                    dragStart={dragStart}
                    dragEnd={dragEnd}
                    saveProposal={saveProposal}
                    name={sectionName}
                    coverType={coverType}
                  />
                );
              } else if (selectedLib.toLowerCase() === 'headers') {
                return (
                  <Headers
                    {...props}
                    ref={headersRef}
                    dragStart={dragStart}
                    dragEnd={dragEnd}
                    saveProposal={saveProposal}
                    coverType={coverType}
                  />
                );
              } else if (selectedLib.toLowerCase() === 'media') {
                return (
                  <Medias
                    {...props}
                    ref={coversRef}
                    dragStart={dragStart}
                    dragEnd={dragEnd}
                    saveProposal={saveProposal}
                    coverType={coverType}
                    handleToolbarElements={handleToolbarElements}
                  />
                );
              } else if (
                selectedLib.toLowerCase() === 'sections' &&
                !wixEditor &&
                !templateWixEditor
              ) {
                return (
                  <Sections
                    {...props}
                    addNewSectionFromLibrary={addNewSectionFromLibrary}
                    dragStart={dragStart}
                    dragEnd={dragEnd}
                    ref={sectionRef}
                  />
                );
              }
            }}></LibraryItems>
        </>
      ) : (
        <List
          dataSource={libraryMenuItems}
          renderItem={(item) => (
            <List.Item onClick={() => setSelectedLib(item)} className="library-list-item">
              {item}
              <RightIcon />
            </List.Item>
          )}
        />
      )}
    </Layout>
  );
};

LibraryMenu.defaultProps = {
  isSection: false,
  wixEditor: false,
  templateWixEditor: false,
};

LibraryMenu.propTypes = {
  libraryType: PropTypes.string.isRequired,
  dragStart: PropTypes.func.isRequired,
  dragEnd: PropTypes.func.isRequired,
  saveProposal: PropTypes.func.isRequired,
  isSection: PropTypes.bool,
  coverType: PropTypes.string.isRequired,
  addNewSectionFromLibrary: PropTypes.func.isRequired,
  sectionRef: PropTypes.instanceOf(Object).isRequired,
  wixEditor: PropTypes.bool,
  templateWixEditor: PropTypes.bool,
};

export default LibraryMenu;
