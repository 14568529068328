import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.3" width="28" height="28" rx="4" fill="white" />
    <path
      d="M10.6925 7.17163C11.0748 6.8007 11.6947 6.8007 12.0771 7.17163L18.3076 13.2163C18.69 13.5872 18.69 14.1886 18.3076 14.5595C17.9253 14.9305 17.3054 14.9305 16.923 14.5595L10.6925 8.51489C10.3101 8.14396 10.3101 7.54256 10.6925 7.17163Z"
      fill="white"
    />
    <rect
      width="10.61"
      height="1.92909"
      rx="0.964543"
      transform="matrix(-0.717734 0.696318 0.717734 0.696318 17.6152 12.7689)"
      fill="white"
    />
  </svg>
);

const ArrowIcon = (props) => <Icon component={svg} {...props} />;

export default ArrowIcon;
