import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg
    className="quotes-icon"
    width="15"
    height="11"
    viewBox="0 0 15 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#9E9EB7"
      d="M4,5H2.051C2.287,2.887,3.287,2.43,4.11,2.053c0.12-0.055,0.233-0.107,0.337-0.159
		c0.494-0.247,0.694-0.848,0.447-1.342C4.647,0.059,4.048-0.143,3.553,0.105C3.469,0.147,3.376,0.189,3.279,0.234
		C2.133,0.758,0,1.733,0,6v3c0,1.104,0.896,2,2,2h2c1.104,0,2-0.896,2-2V7C6,5.896,5.104,5,4,5z"
    />
    <path
      fill="#9E9EB7"
      d="M13,5h-1.948c0.235-2.113,1.234-2.57,2.059-2.947c0.12-0.055,0.233-0.107,0.337-0.159
		c0.494-0.247,0.694-0.848,0.447-1.342c-0.247-0.495-0.848-0.695-1.342-0.447c-0.084,0.042-0.176,0.084-0.273,0.129
		C11.133,0.758,9,1.733,9,6v3c0,1.104,0.896,2,2,2h2c1.104,0,2-0.896,2-2V7C15,5.896,14.104,5,13,5z"
    />
  </svg>
);

const QuotesIcon = (props) => <Icon component={svg} {...props} />;

export default QuotesIcon;
