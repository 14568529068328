import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 2C0 0.89543 0.895431 0 2 0H7.5H10.3593C10.9262 0 11.4666 0.240643 11.8459 0.662071L13.5 2.5L14.6 3.96667C14.8596 4.31286 15 4.73393 15 5.16667V9V16C15 17.1046 14.1046 18 13 18H2C0.89543 18 0 17.1046 0 16V2Z"
      fill="white"
    />
    <path
      d="M12.9976 10.8008C12.9912 10.7282 12.9197 9.85168 11.2442 9.88795C9.5751 9.92422 9.16595 10.0209 9.16595 10.0209C9.16595 10.0209 7.91902 8.84215 7.46441 7.92935C7.46441 7.92935 8.01644 6.42413 7.99046 5.48109C7.96448 4.53806 7.72419 3.994 6.94486 4.00005C6.16553 4.00609 6.05513 4.64083 6.15254 5.58386C6.24346 6.43017 6.71756 7.42761 6.71756 7.42761C6.71756 7.42761 6.36036 8.46736 5.87978 9.50711C5.40569 10.5469 5.08096 11.0909 5.08096 11.0909C5.08096 11.0909 3.47035 11.5927 2.77545 12.1972C2.08055 12.8017 1.79479 13.2671 2.15848 13.7326C2.47671 14.1316 3.58076 14.2223 4.5744 13.0133C5.56155 11.8042 6.00967 11.0486 6.00967 11.0486C6.00967 11.0486 7.52286 10.6617 7.99046 10.559C8.45806 10.4562 9.02957 10.3716 9.02957 10.3716C9.02957 10.3716 10.4064 11.6652 11.7377 11.6168C13.0691 11.5685 13.0041 10.8733 12.9976 10.8008ZM2.50269 13.5271C1.6779 13.0677 4.23669 11.6471 4.70429 11.5987C4.70429 11.5987 3.37294 14.0107 2.50269 13.5271ZM6.4383 5.24533C6.4383 4.49575 6.69807 4.29626 6.8994 4.29626C7.10073 4.29626 7.32803 4.38693 7.33452 5.03376C7.34102 5.68058 6.8994 6.95005 6.8994 6.95005C6.75003 6.79892 6.4383 5.98888 6.4383 5.24533ZM7.50987 10.3655C6.67859 10.5529 6.26295 10.7524 6.26295 10.7524C6.26295 10.7524 6.26295 10.7524 6.60066 10.0451C6.93837 9.33785 7.28906 8.37064 7.28906 8.37064C7.75666 9.18672 8.69186 10.1479 8.69186 10.1479C8.69186 10.1479 8.34116 10.1721 7.50987 10.3655ZM9.50366 10.299C9.50366 10.299 12.2053 9.84564 12.2053 10.704C12.2053 11.5564 10.5298 11.2058 9.50366 10.299Z"
      fill="#005AFF"
    />
  </svg>
);

const PdfAdobeIcon = (props) => <Icon component={svg} {...props} />;

export default PdfAdobeIcon;
