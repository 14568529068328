import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="1" width="21" height="17" rx="3" fill="#C7BCE4" />
    <path d="M0 4C0 2.34315 1.34315 1 3 1H18C19.6569 1 21 2.34315 21 4V6H0V4Z" fill="#5D5AB5" />
    <rect x="4" width="2" height="4" rx="0.5" fill="#C7BCE4" />
    <rect x="15" width="2" height="4" rx="0.5" fill="#C7BCE4" />
    <rect x="13" y="11" width="4" height="4" rx="0.5" fill="#5D5AB5" />
  </svg>
);

const CalendarIcon = (props) => <Icon component={svg} {...props} />;

export default CalendarIcon;
